import react from 'react';

import Grid from "@material-ui/core/Grid";
import AddIcon from "@mui/icons-material/Add";

import { makeStyles } from "@material-ui/core/styles";

import notFoundImg from '../assets/woman-search.png';

import { useAuth } from "../context/provider";


const useStyles = makeStyles(theme => ({
	container: {
		padding:100,
		textAlign: 'center',
	},
	img: {
		color: theme.palette.primary.main,
		height: 300
	},
	text: {
		color: '#444',
		fontSize: 16,
	},
	textContainer: {
		padding: 50
	}
}));


const SearchNotFound = ( props) => {
			
	const classes = useStyles();			
			
    return (		
        <div className={classes.container}>
			<Grid container direction='column'>
			<Grid item>
				<img className={classes.img} src={ notFoundImg } alt="Not found" />		
			</Grid>
			<Grid item className={classes.textContainer}>			
				<span className={classes.text}>Für deine Suchkriterien haben wir aktuell leider keine Treffer gefunden.</span>
				<br/>
				<span className={classes.text}>Probiere bitte erneut!</span>
			</Grid>			
			</Grid>			
        </div>
    )
}

export default SearchNotFound;



