import React from "react";
import react, { useState, useEffect, useLayoutEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { useAuth } from "../context/provider";

const styles = (theme) => ({
	icon: {
		color: 'red',
	},
	select: {
		padding: 10
	}
});


const FacsSelectType = ( props) => {
			
	const { classes } = props;			
			
	const { gstate, setFacsSearchFilter } = useAuth();						
			
	const [facTypes, setFacTypes] = React.useState( [
		{'label':'Haus kaufen', 'value':'100'}, 
		{'label':'Wohnung kaufen', 'value':'200'}
	]);						
	const [checked, setChecked] = React.useState( gstate.facsSearchFilter.fac_types ? gstate.facsSearchFilter.fac_types : []);

	
	const makeText = () => {
		var txt;				
		for( var i=0; i<checked.length; i++) {
			if( !txt)
				txt = checked[i];
			else
				txt += ',' + checked[i];
		}
				
		console.log( 'typesText', txt);
		return txt; 				
	}	
	
	
	useEffect( () => {
		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			fac_types_text: makeText()
		});	
	}, [ JSON.stringify( checked)]);			
	
	
	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if( currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		console.log( 'checked', newChecked);
		setChecked( newChecked);
		
		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			fac_types: newChecked,
			fac_types_text: makeText()
		});		
	};	
	
	
	return (
		<Grid container 
			spacing={4} 
			direction="column" 
			justifyContent="center" 
			alignItems="center"
			style={{padding:0}}>			
			<Grid item xs={12}>
				<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
								
					{facTypes.map((option) => {
						const labelId = `checkbox-list-label-${option.value}`;

						return (
							<ListItem
								key={option.label}
							>
								<ListItemButton role={undefined} onClick={handleToggle( option.value)} dense>
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={checked.indexOf( option.value) !== -1}
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={`${option.label}`} />
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
			</Grid>
			
		</Grid>	
    );
	
}
export default withStyles(styles)(FacsSelectType);	