import React, { useState, useEffect } from 'react';
import { Typography, Box, useTheme, Stack, useMediaQuery } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import { makeStyles } from '@mui/styles';

/*
import { MuiChat } from 'chat-ui-react/dist/cjs/mui/MuiChat.js'; 
import { MuiMessage } from 'chat-ui-react/dist/cjs/mui/MuiMessage.js'; 
import { MuiTextInput } from 'chat-ui-react/dist/cjs/mui/MuiTextInput.js'; 
*/
import { MuiChat } from './chatUiReact/qeedist/cjs/mui/MuiChat.js'; 
import { MuiMessage } from './chatUiReact/qeedist/cjs/mui/MuiMessage.js'; 
import { MuiTextInput } from './chatUiReact/qeedist/cjs/mui/MuiTextInput.js'; 

import { themeV4, themeV5 } from '../core/theme.js';

const useStyles = makeStyles((theme) => ({
    waitingSymbol: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
    },
    dot: {
        width: 12,
        height: 12,
        backgroundColor: themeV4.palette ? themeV4.palette.primary.main : 'darkgrey', // Customize the color
        borderRadius: '50%',
        margin: '0 4px',
        animation: '$bounce 1s infinite alternate',
    },
    dot1: {
        animationDelay: '0.3s',
    },
    dot2: {
        animationDelay: '0.6s',
    },
    dot3: {
        animationDelay: '0.9s',
    },
    '@keyframes bounce': {
        '0%': {
            transform: 'translateY(0)',
        },
        '100%': {
            transform: 'translateY(-10px)',
        },
    },
    customTextInput: {
        borderRadius: '40px', // Adjust the radius value as needed
        color: '#f00'
    },  
    customMessage: {
        borderRadius: '0px', 
		backgroundColor: themeV4.palette ? themeV4.palette.primary.main : 'grey',
		color: 'white'
    },  
}));

function QeeMuiChat({ chatController, actReq, ...props }) {
	const [isInitializingChat, setIsInitializingChat] = useState(true);
    const [waitingForResponse, setWaitingForResponse] = useState(false);
	
	const theme = useTheme(); // This uses the theme from the context
    const classes = useStyles();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    const msgRef = React.useRef(null);

    const scroll = React.useCallback(() => {
        //alert( msgRef.current)
        if (msgRef.current) {
            msgRef.current.scrollTop = msgRef.current.scrollHeight; // msgRef.current.scrollIntoView(true);
        }
    }, [msgRef]);  

    useEffect(() => {
        var es = document.querySelectorAll('.MuiBox-root.css-18r6i10');
        es.forEach(e => {
            e.style.background = '#30b33e59';
        });
        es = document.querySelectorAll('.MuiBox-root.css-d0zsf2');
        es.forEach(e => {
            e.style['box-shadow'] = 'none';
			e.style['border'] = '1px solid lightgrey';
        });	
		es = document.querySelectorAll('.MuiBox-root.css-1v6vqto p');
        es.forEach(e => {
            e.style['box-shadow'] = 'none';
			e.style['color'] = 'white';
        });	
		es = document.querySelectorAll('.MuiBox-root.css-m3n9sn');
        es.forEach(e => {
            e.style['background-color'] = themeV4.palette ? themeV4.palette.primary.main : 'darkgrey';
        });			
		es = document.querySelectorAll('.MuiBox-root.css-m3n9sn p');
        es.forEach(e => {
			e.style['color'] = 'white';
        });

		es = document.querySelectorAll('.css-9l3uo3 p');
		console.log( 'es for font-size: ', es);
        es.forEach(e => {
			console.log( 'Change font-size for: ', e);
			e.style['font-size'] = 'none';
        });

		
		es = document.querySelectorAll('.MuiButtonBase-root.css-1p4aeya-MuiButtonBase-root-MuiButton-root');
		es.forEach(e => {
			const icon = e.querySelector('.icon');
			//if (icon) icon.remove();
			//e.textContent = 'Senden';
			//e.style.color = 'green';
		});
		
		// the container for select-questions
		//es = document.querySelectorAll('.MuiBox-root.css-1gyrids, .MuiBox-root.css-ix91r1');
		es = document.querySelectorAll('.qeeChatButtonContainer, .qeeQuickQuestionContainer');
			es.forEach(e => {	
			// Ensure the container allows for flex wrapping
			e.style.display = 'flex';
			e.style.flexWrap = 'wrap'; // Enable wrapping
			e.style.alignItems = 'flex-end'; // Adjust alignment as needed
			e.style.gap = '10px'; // Add some space between items

			// Adjust the styles for spans inside the button, making them invisible/transparent
			e.querySelectorAll('span').forEach(span => {
				span.style.opacity = 0;
			});

			// Apply styles to each button within the container
			e.querySelectorAll('button').forEach(button => {
				// Basic button styles
				button.style.textTransform = 'none';
				button.style.setProperty('background-color', themeV4.palette ? themeV4.palette.primary.main : 'darkgrey', 'important');
				button.style.setProperty('color', 'white', 'important'); // Ensuring text color is white with important flag
				button.style.width = 'max-content';
				button.style.borderRadius = '16px';
				//button.style.marginBottom = '20px'; // Adjust if needed for layout

				// Specific styles for buttons also marked as quick questions
				if (button.classList.contains('qeeQuickQuestion')) {
					button.style.setProperty('background-color', 'white', 'important'); // Setting background to white
					button.style.setProperty('color', themeV4.palette ? themeV4.palette.primary.main : 'darkgrey', 'important'); // Text color set to primary or dark grey
					button.style.borderColor = themeV4.palette ? themeV4.palette.primary.main : 'darkgrey'; // Border color matches text
					button.style.borderWidth = '2px';
					button.style.borderStyle = 'solid';
				}
			});			
		});					
					
    }, [chatController.getMessages().length, waitingForResponse, isInitializingChat]);

    useEffect(() => {
		const handleSelfMessage = (message) => {
			console.log('handleSelfMessage message', message);

			// for P2P chats just return
			if( chatController.chatConfig?.isP2P) {
				scroll();
				return;
			}

			if (message[message.length - 1] && message[message.length - 1].self) {
				chatController.setActionRequest({ type: null });
				setWaitingForResponse(true);
				scroll();
			} else {
				
				let quickQuestions = null;
				/*
				// Loop backwards to find the first message with quickQuestions
				for (let i = message.length - 1; i >= 0; i--) {
					if (message[i] && message[i].quickQuestions) {
						quickQuestions = message[i].quickQuestions;
						break; // Break the loop once we find the first element with quickQuestions
					}
				}
				*/
				quickQuestions = message[message.length-1].quickQuestions;
				if (quickQuestions) {
					chatController.setActionRequest({ type: 'text', always: true, self: true, quickQuestions: quickQuestions });
				} else {
					chatController.setActionRequest({ type: 'text', always: true, self: true });
				}

				setWaitingForResponse(false);
				scroll();
			}
		};

		// global set (set from the parent component)
		setIsInitializingChat(false);			
		
        // Add a listener for self messages
        chatController.addOnMessagesChanged(handleSelfMessage);

        // Cleanup the listener when the component unmounts
        return () => {
            chatController.removeOnMessagesChanged(handleSelfMessage);
        };  
    }, [chatController]); 
  
    function renderWelcomeHeader() {
        return (
            <Box textAlign="center" marginTop={2} marginBottom={2} mx="auto">
				{ !chatController.chatConfig?.isP2P &&
					<>
					<Stack
						direction="row"
						alignItems="center"
						spacing={2}
						justifyContent="center"
						alignContent="center">
						<HomeIcon style={{ fontSize: '72px', color: theme.palette.primary.main }} />
						<ChatIcon
							style={{
								fontSize: '42px',
								color: theme.palette.primary.main, 
								backgroundColor: 'white', 
								position: 'relative', 
								top: '20px', 
								right: '34px', 
								zIndex: 1, // Ensure the ChatIcon is above the HomeIcon
							}}
						/>
					</Stack>
					<Typography variant="h5" gutterBottom>
						Synapcus Chatbot
					</Typography>					
					<br />
					</>
				}
            </Box>
        );
    }

    function renderWaitingSymbol() {
        return waitingForResponse && (
            <div className={classes.waitingSymbol}>
                {/* Insert your animated waiting symbol here (e.g., three dots) */}
                <div className={`${classes.dot} ${classes.dot1}`}></div>
                <div className={`${classes.dot} ${classes.dot2}`}></div>
                <div className={`${classes.dot} ${classes.dot3}`}></div>
            </div>
        );
    }

    return (
        <div ref={msgRef}>
            {/*renderWelcomeHeader()*/}
            <MuiChat 
				chatController={chatController} 
				//actionRequest={waitingForResponse} 
				//messageClassName={classes.customMessage} 
				{...props} 
			/>    
            {/* Render the waiting symbol */}
            {renderWaitingSymbol()}  
			{!chatController.chatConfig?.isP2P && !isXs && 
				<div style={{textAlign:'center'}}>
					<Typography variant="body3"
						style={{ 
							color: 'grey', 
							fontSize: 'smaller' 
						}}
					>Der ChatBot kann Fehler machen. Achte darauf, wichtige Informationen zu kontrollieren.</Typography>	
				</div>
			}
        </div>
    );
}

export { QeeMuiChat };
