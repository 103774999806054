import React from 'react';
import { Avatar, Box, Grow, Typography, Link, Tooltip, Button, Card, CardActions, CardMedia } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QeeFileInput from '../../../../qeeFileInput';

export function MuiMessage({
	chatController,
	id,
	message,
	showDate,
	showTime,
}) {
	if (message.deletedAt) {
		return <div id={id} />;
	}

	const _parseLinksToJSX = (subText) => {
		const linkRegex = /\{Link\|(.*?)\|(.*?)\}/g;
		let elements = [];
		let lastIndex = 0;
		let match;

		while ((match = linkRegex.exec(subText)) !== null) {
			const [fullMatch, href, linkText] = match;
			const start = match.index;

			if (start > lastIndex) {
				elements.push(subText.substring(lastIndex, start));
			}

			elements.push(
				<Link href={href} target="_blank" rel="noopener noreferrer" key={href}>
					{linkText}<OpenInNewIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
				</Link>
			);

			lastIndex = start + fullMatch.length;
		}

		if (lastIndex < subText.length) {
			elements.push(subText.substring(lastIndex));
		}

		return elements;
	};

	const _parseImgGalleryToJSX = (subText) => {
		const galleryRegex = /\{ImgGallery\|([^|]+)\|([^|]+)\|([^}]+)\}/;
		const match = subText.match(galleryRegex);

		if (!match) return [];

		const [_, imgStr, linkStr, titleStr] = match;
		const imgs = imgStr.split(',');
		const links = linkStr.split(',');
		const titles = titleStr.split(',');

		const elements = imgs.map((img, index) => {
			const link = links[index] || '';
			const title = titles[index] || '';

			return (
				<Box 
					key={img} 
					style={{ 
						marginRight: '8px', 
						borderRadius: '8px', 
						overflow: 'hidden', 
						display: 'inline-block'
					}}
				>
					<Card 
						variant="outlined" 
						style={{ 
							width: '150px',
							borderRadius: '8px',
						}}
					>
						<CardMedia
							component="img"
							image={img}
							alt={title}
							title={title}
							style={{ 
								height: '150px', 
								objectFit: 'cover',
							}}
						/>
						<CardActions style={{ padding: 0, display: 'block' }}>
							<Button
								href={link}
								target="_blank"
								rel="noopener noreferrer"
								size="small"
								color="primary"
								style={{ 
									width: '100%', 
									borderRadius: 0,
								}}
							>
								{title || 'View'}
							</Button>
						</CardActions>
					</Card>
				</Box>
			);
		});

		return (
			<Box display="flex" overflowX="auto" padding="0px" marginY="8px">
				{elements}
			</Box>
		);
	};

	const _parseContentToJSX = (text) => {
		let content = [];
		const splitText = text.split('{br}');
		
		splitText.forEach((part, index) => {
			if (index > 0) {
				content.push(<br key={`br-${index}`} />);
			}
			let galleryMatch = part.match(/\{ImgGallery\|[^}]+\}/);
			if (galleryMatch) {
				const beforeGallery = part.substring(0, galleryMatch.index);
				const afterGallery = part.substring(galleryMatch.index + galleryMatch[0].length);

				content = content.concat(_parseLinksToJSX(beforeGallery), _parseImgGalleryToJSX(galleryMatch[0]), _parseLinksToJSX(afterGallery));
			} else {
				content = content.concat(_parseLinksToJSX(part));
			}
		});

		return content;
	};

	const dispDate = message.updatedAt ? message.updatedAt : message.createdAt;

	const ChatAvatar = (
		<Box minWidth={0} flexShrink={0} ml={message.self ? 1 : 0} mr={message.self ? 0 : 1}>
			<Avatar alt={message.perName} src={message.avatar} />
		</Box>
	);

	const ChatUsername = (
		<Box maxWidth="100%" mx={1}>
			<Typography variant="body2" align={message.self ? 'right' : 'left'}>
				{message.perName}
			</Typography>
		</Box>
	);

	const ChatDate = (
		<Box maxWidth="100%" mx={1}>
			<Typography variant="body2" align={message.self ? 'right' : 'left'} color="textSecondary">
				{dispDate?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
			</Typography>
		</Box>
	);

	const ChatUsernameDate = ({ message, dispDate }) => {
		return (
			<Box 
				maxWidth="100%" 
				mx={1} 
				style={{ 
					whiteSpace: 'nowrap', 
					display: 'flex', 
					alignItems: 'center', 
					justifyContent: message.self ? 'flex-end' : 'flex-start'
				}}
			>
				<Box>
					<Typography 
						variant="body2" 
						align={message.self ? 'right' : 'left'}
						color="textSecondary" 
						style={{ fontSize: '80%', fontWeight: 600 }}
					>
						{message.perName}
					</Typography>
				</Box>
				<Box mx={1}>
					<Typography 
						variant="body2" 
						align={message.self ? 'right' : 'left'}
						color="textSecondary" 
						style={{ fontSize: '80%' }}
					>
						{dispDate?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
					</Typography>
				</Box>
			</Box>
		);
	};

	return (
		<Grow in>
			<Box maxWidth="100%" display="flex" flexDirection="column" style={{ paddingTop: 10 }}>
				{showDate && (
					<Typography align="center" style={{ fontWeight: 'bold' }}>
						{dispDate?.toLocaleDateString()}
					</Typography>
				)}
				<Box
					id={id}
					maxWidth="100%"
					my={1}
					pl={message.self ? '20%' : 0}
					pr={message.self ? 0 : '20%'}
					display="flex"
					justifyContent={message.self ? 'flex-end' : 'flex-start'}
					style={{ overflowWrap: 'break-word' }}
				>
					{message.avatar && !message.self && ChatAvatar}
					<Box minWidth={0} display="flex" flexDirection="column">
						{showTime && <ChatUsernameDate message={message} dispDate={dispDate} />}
						<Box
							maxWidth="100%"
							py={1}
							px={2}
							bgcolor={message.self ? 'primary.main' : 'background.paper'}
							color={message.self ? 'primary.contrastText' : 'text.primary'}
							borderRadius={4}
							boxShadow={2}
							mt={0.1}
							className={message.self ? "MuiMessageSelf" : "MuiMessage"}
						>
							{message.type === 'text' && (
								<Typography variant="body1" style={{
									whiteSpace: 'pre-wrap',
									color: message.self ? '#fff' : '#222'
								}}>
									{_parseContentToJSX(message.content)}
								</Typography>
							)}
							{message.type === 'jsx' && _parseLinksToJSX(message.content)}
							{message.type === 'file' && (	
								<>
								{/*'message.uploadId: ' + message.uploadId*/}	
								<QeeFileInput
									facId={chatController.parentForm + '-' + chatController.parentId}
									username={message.username}
									buttonText=""
									keyItm={'upload_id'}
									keyVal={'"'+message.uploadId+'"'}
									hideUploadButton={true}
									showFileList={true}
								/>
								</>
							)}
						</Box>
					</Box>
					{message.avatar && message.self && ChatAvatar}
				</Box>
			</Box>
		</Grow>
	);
}
