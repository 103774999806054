// sshotFacDiagramValuation.js

import React, { Component } from 'react';
import react, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles }  from '@material-ui/core/styles';
import axios from 'axios';
import queryString from 'query-string';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import DiagramValuation from '../components/diagramValuation';


import { useAuth } from "../context/provider";


const styles = (theme) => ({

});


const SshotFacDiagramValuation = ( props) => {

	const { gstate, setObjEdit, setObjErrors, setIsLoading } = useAuth();				
			
	const [uiLoading, setUiLoading] = useState( true);
	const [showHint, setShowHint] = useState( false);	
			
			
	const useStyles = makeStyles({
		...props,	
	});	
	const classes = useStyles( props);		

	
	const loadFac = async ( id) => {
		
		console.log( '--> loadFac', id);
		
		const authToken = localStorage.getItem( 'AuthToken');
		console.log( 'SshotFacDiagramValuation authToken', authToken);
		if( authToken == null) {
			console.log( '<-- loadFac ERR: No AuthToken!');
			return;
		}
		
		setUiLoading( true);
						
		var options = {
			url: `/api/fac/${id}`,
			method: 'get',
		};		
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios(options)
			.then((data) => {
				var fac = data.data;
				console.log( 'data', fac);
				fac.step = 0;
				setObjEdit( fac);
				setUiLoading( false);
				console.log( '<-- loadFac');				
			})
			.catch((error) => {
				setObjErrors( error.response.data);
				console.log(error);
				setUiLoading( false);				
				console.log( '<-- loadFac ERR', error);								
			});
	};
	
	
	useEffect( () => {	
	
		// this page will be called from sshotMain.html with the syntax
		// http://localhost:3000/sshotFacDiagramValuation?id=qwSSfuYO3lrZZVjYUn8R&authToken=eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkMjNmMzc0MDI1ZWQzNTNmOTg0YjUxMWE3Y2NlNDlhMzFkMzFiZDIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdnJlZWRpbnNpZ2h0LTFkNTVhIiwiYXVkIjoidnJlZWRpbnNpZ2h0LTFkNTVhIiwiYXV0aF90aW1lIjoxNjYzNzgwNzYzLCJ1c2VyX2lkIjoiQnJHZXd4YVh6WlJEYnlhMkdWZlJSUnVORnU4MyIsInN1YiI6IkJyR2V3eGFYelpSRGJ5YTJHVmZSUlJ1TkZ1ODMiLCJpYXQiOjE2NjM3ODA3NjMsImV4cCI6MTY2Mzc4NDM2MywiZW1haWwiOiJhZGlAcWFsZ28uZGUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiYWRpQHFhbGdvLmRlIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.sZYOCm5naV71c--XYyMCmtwblsbZS9s_TT7uA2zp7o0BHxlmPYdjSN5-F4uV0HHBxmLS-5Fui5b37Jsbl2I48weRFUQurWFXuZP9L0w1E6DvONQ4xpT21PAdBq0W7bxDRdo3x4qSyISh2WzoRu2wMrq3Noc8TSti2MpIeaCeuCfWrGYLZqAc-rMhYATXa95aPkypK4xvmilgcOIRhSg2HmyycKUCzCwVqowIXz1NOm7KqjChjvqozuIJWE8KGAErFopj7SGwBQvwkmBnohh-miefFaUG3iAMD0AGgmIw4NzsIiuqMClNb-GaWXeMkQcuOtIu8MeRWJieSDWM4yCuaw
		const queryString = window.location.search;
		console.log( 'SshotFacDiagramValuation queryString', queryString);		
		const urlParams = new URLSearchParams( queryString);	
		localStorage.setItem( 'AuthToken', 'Bearer '+urlParams.get( 'authToken'));
		
		setShowHint( urlParams.get( 'p1')=='true' ? true : false);		
		
		loadFac( urlParams.get( 'id'));

	}, []);	
		
	
	return (	
		<>		
		{uiLoading == false ? 
			<div id="sshotContainer">
				<DiagramValuation showHint={showHint}/>
			</div> 
		: 
			'Loading...'
		}		
		</>
	);
	
}
export default withStyles(styles)(SshotFacDiagramValuation);

