// colorButton.js

import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { grey } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';

import { themeV4 } from '../core/theme';


const QeeButton = (props) => {
	
	return (
		<Button endIcon={<SendIcon />}>
			{props.children}
		</Button>
	)
}


export const QeeColorButtonPrimary = styled(Button)(({ theme }) => {
	
	var customTheme = useTheme() || themeV4;
	
	return {
		backgroundColor: customTheme.palette.primary.main,
		color: customTheme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: customTheme.palette.primary.dark,
		},
		textTransform: 'none',
		marginRight: 10,
		[customTheme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	  };	
});


export const QeeColorButtonSecondary = styled(Button)(({ theme }) => {
	const customTheme = useTheme() || themeV4;

	return {
		backgroundColor: theme.palette.primary.contrastText,
		border: `1px solid ${theme.palette.primary.main}`,				
		'&:hover': {
			backgroundColor: customTheme.palette.secondary.dark,
		},
		textTransform: 'none',
		marginRight: 10,
		//color: customTheme.palette.secondary.main,
		[customTheme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	};
});
QeeColorButtonSecondary.defaultProps = {
    color: 'secondary',
    classes: { text: 'MuiButton-textSecondary' },
};

