// facNewbuildEmpty.js
import react, { useRef, useEffect } from 'react';

import Grid from "@material-ui/core/Grid";
import AddIcon from "@mui/icons-material/Add";

import { makeStyles } from "@material-ui/core/styles";

import notFoundImg from '../assets/fac-newbuild-empty.png';

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';

import { useAuth } from "../context/provider";


const useStyles = makeStyles(theme => ({
	container: {
		padding:100,
		[theme.breakpoints.down('xs')]: {
			padding:10,
		},
		textAlign: 'center',
	},
	img: {
		color: theme.palette.primary.main,
		width:300,
		[theme.breakpoints.down('xs')]: {
			width:120,
		},		
	},
	text: {
		color: '#444',
		fontSize: 16,
		[theme.breakpoints.down('xs')]: {
			fontSize: 14,
		},		
	},
	textContainer: {
		padding: 50,
		[theme.breakpoints.down('xs')]: {
			padding:10,
		},		
	},
	
}));


const FacNewbuildEmpty = ( props) => {
						
	const classes = useStyles();			
			
    return (		
        <div className={classes.container}>
				
			<Grid container direction='column'>
			<Grid item>
				<img className={classes.img} src={ notFoundImg } alt="Not found" />		
			</Grid>
			<Grid item className={classes.textContainer}>
				<>
					<span className={classes.text}>Du hast noch nicht alle Daten deines Neubauprojektes erfasst.</span><br/><br/>
					<span className={classes.text}><a onClick={props.fctNewbuildOpen} style={{fontWeight:700}} href='#'>Hier</a> kannst du deinen Erfassungsassistent starten!</span><br/><br/>
					<QeeColorButtonPrimary disableElevation 
						className={classes.button} 
						onClick={props.fctNewbuildOpen}>
						Neubauassistent
					</QeeColorButtonPrimary>									
				</>			
			</Grid>			
			</Grid>			
				
        </div>
    )
}

export default FacNewbuildEmpty;



