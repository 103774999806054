import { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth } from "../context/provider";
import { useSnackbar } from 'notistack';
import imageMissing from '../assets/image-missing.jpg';
import { getDocs, getDocsNet, postStorageDocs } from "../api/service";
import { QeeColorButtonPrimary, QeeColorButtonSecondary } from './qeeColorButton';
import QeeTooltip from './qeeTooltip';
import { usePicturesGallery } from "../components/qeePicturesGalleryProvider";
import css from '../core/App.css';

const useStyles = makeStyles(theme => ({
	label: {
		// Aligns the content of the button vertically.
		flexDirection: 'column'
	},
	container: {
		//width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0px 0',
	},
	img: {
		width: props => props.width || 150,
		height: props => props.height || 150,
		objectFit: 'cover',
		objectPosition: 'center',
	},
	icoText: {
		color: theme.palette.primary.main,
		fontSize: 14
	},
	card: {
		display: 'flex',
		width: props => props.width || 150,
		height: props => props.height || 174,
		textAlign: 'center',
		position: 'relative',
		alignItems: 'center',
		justifyContent: 'center',
	},
	cardContent: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		padding: '0px !important',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.main,
	},
	cardTitle: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontWeight: 500,
		width: 150, // Adjusted width to fit the card width
		height: 24, // Full row height
		lineHeight: '24px', // Center text vertically
		[theme.breakpoints.down('xs')]: {
			width: 120,
		},
	},
	uploadButton: {
		width: 200,
		height: 60,
		textAlign: 'center',
		flexDirection: 'row',
		padding: 10,
	},
	listItem: {
		padding: 0,
		marginLeft: 5
	}
}));

const QeeFileInput = (props) => {
	
	const {
		facId,
		username,
		keyItm,
		keyVal
	} = props;
		
	const { setOpenPicturesGalleryDialog } = usePicturesGallery();
	const { enqueueSnackbar } = useSnackbar();
	const { gstate } = useAuth();
	const classes = useStyles(props);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedImage, setSelectedImage] = useState([]);
	const [imageUrl, setImageUrl] = useState([]);
	const [docs, setDocs] = useState([]);
	const [urls, setUrls] = useState([]);

	const fetchDocs = async () => {
		const dcs = await getDocsNet( facId, keyItm, keyVal, username);
		if (!dcs.err)
			setDocs(dcs);

		var us = [];
		for (let i = 0; i < dcs.length; i++) {
			var elem = {
				url: dcs[i].signedUrl,
				caption: dcs[i].name
			}
			us.push(elem);
		}
		setUrls(us);
	};

	const submitFiles = async () => {
		console.log('--> submitFiles');
		setIsLoading(true);

		var formData = new FormData();
		console.log('selectedImage', selectedImage);
		for (let i = 0; i < selectedImage.length; i++) {
			console.log('Set in FormData file:' + i, selectedImage[i])
			selectedImage[i].filename = selectedImage[i].name;
			var metadata = {};
			metadata.sdoc_name = selectedImage[i].name;
			metadata.sdoc_type = props.sdoc_type;
			metadata.sdoc_subtype = props.sdoc_subtype;
			if( props.upload_id)
				metadata.upload_id = props.upload_id;			
			selectedImage[i].metadata = JSON.stringify(metadata);

			formData.append(selectedImage[i].metadata, selectedImage[i]);
		}

		var ret = await postStorageDocs(formData, props.facId);

		setIsLoading(false);

		if (ret.error) {
			enqueueSnackbar('Der Upload konnte nicht ausgeführt werden! ' + ret.error, { variant: 'error' });
			console.log('<-- submitFiles ERR', ret.error);
			return false;
		} else {
			if (props.onSuccess)
				props.onSuccess(selectedImage);

			enqueueSnackbar('Der Upload wurde erfolgreich ausgeführt.', { variant: 'success' });
			console.log('<-- submitFiles');
			setSelectedImage([]); // Clear selected images after successful upload
			await fetchDocs(); // Fetch docs after successful upload
			return true;
		}
	};

	const handleSetSelectedImage = (ev) => {
		console.log('handleSetSelectedImage', ev);
		var files = ev.target.files;
		console.log('files', files);

		var files2 = Array.prototype.slice.call(files)

		var si = selectedImage;
		si = si.concat(files2);
		console.log('si', si);
		setSelectedImage(si);
	}

	useEffect(() => {
		const getDcs = async () => {
			if (selectedImage.length)
				await submitFiles();
			else
				await fetchDocs();
		}
		getDcs();
	}, [selectedImage]);

	const FileCard = (props) => {
		const isImage = (name) => {
			if (name.indexOf('.jpg') > 0 ||
				name.indexOf('.jpeg') > 0 ||
				name.indexOf('.png') > 0 ||
				name.indexOf('.gif') > 0 ||
				name.indexOf('.bmp') > 0 ||
				name.indexOf('.ico') > 0 ||
				name.indexOf('.webp') > 0 ||
				name.indexOf('.tif') > 0 ||
				name.indexOf('.svg') > 0)
				return true;
		}

		const onClickImage = (props) => {
			let nm = props.doc.name.toLowerCase();
			if (isImage(nm))
				setOpenPicturesGalleryDialog(props.urls, props.idx)
			else
				window.open(props.urls[props.idx].url);
		}

		return (
			<Card className={classes.card}>
				<ButtonBase onClick={() => onClickImage(props)} style={{ height: '100%' }}>
					<CardContent className={classes.cardContent}>
						{(() => {
							let nm = props.doc.name.toLowerCase();
							if (isImage(nm)) return <img className={classes.img} src={props.doc.signedUrl} alt={props.doc.name} />
							else if (nm.indexOf('.pdf') > 0) return <PictureAsPdfOutlinedIcon fontSize="large" color="green" />
							else if (nm.indexOf('.xls') > 0) return <TableChartOutlinedIcon fontSize="large" color="green" />
							else if (nm.indexOf('.doc') > 0) return <span className={classes.icoText}>Word</span>
							else if (nm.indexOf('.ppt') > 0) return <span className={classes.icoText}>PowerPoint</span>
							else return <span className={classes.icoText}>Datei</span>
						})()}
					</CardContent>
				</ButtonBase>
				<CardActionArea>
					<CardActions style={{padding:0}}>
						<QeeTooltip title={<>{props.doc.name}</>}>
							<div className={classes.cardTitle}>{props.doc.name}</div>
						</QeeTooltip>
					</CardActions>
				</CardActionArea>
			</Card>
		)
	}

	const FilesList = (props) => {
		const getWidth = () => {
			var w = 320;
			if (gstate.isXS) w = 320;
			else if (gstate.isSM) w = 600;
			else if (gstate.isMD) w = 900;
			else if (gstate.isLG) w = 1200 / 2;
			else if (gstate.isXL) w = 1536 / 2;

			var wData = docs.length * 160;
			if (wData < w)
				w = wData;

			return w;
		}

		// bgcolor: 'background.paper', 
		return (
			<>
				<Box sx={{ width: getWidth(), overflowX: 'auto' }}>
					<List component={Stack} direction="row" style={{ padding: '10px 0' }}>
						{docs.map((elem, idx) => (
							<ListItem disablePadding className={classes.listItem} key={idx}>
								<FileCard doc={docs[idx]} urls={urls} idx={idx} />
							</ListItem>
						))}
					</List>
				</Box>
			</>
		);
	}

	return (
		<div className={classes.container}>
			{!props.hideUploadButton &&
				<Grid container
					direction="row"
					alignItems="center"
					style={{ padding: '0px 0' }}>
					<Grid item>
						<input
							accept="image/*"
							type="file"
							multiple
							id={"select-image_" + props.compId}
							style={{ display: 'none' }}
							onChange={ev => handleSetSelectedImage(ev)}
						/>
						<label htmlFor={"select-image_" + props.compId} style={{ padding: 0 }}>
							{isLoading ?
								<QeeColorButtonSecondary className={classes.uploadButton}
									style={{ width: props.buttonWidth ? props.buttonWidth : '', height: props.buttonHeight ? props.buttonHeight : '' }}>
									<CircularProgress />
								</QeeColorButtonSecondary>
								:
								<QeeTooltip title={props.tooltipTitle ? props.tooltipTitle : 'Dateien hochladen'}>
									<QeeColorButtonSecondary component="span" className={classes.uploadButton}
										style={{ width: props.buttonWidth ? props.buttonWidth : '', height: props.buttonHeight ? props.buttonHeight : '' }}>
										<div><PhotoCameraIcon fontSize={props.iconFontSize ? props.iconFontSize : "large"} /></div>
										{props.hideButtonText ? '' :
											<div>{props.buttonText}</div>
										}
									</QeeColorButtonSecondary>
								</QeeTooltip>
							}
						</label>
					</Grid>
				</Grid>
			}
			{!props.hideFilesList &&
				<Grid container
					direction="row"
					alignItems="center"
					style={{ padding: '0px 0' }}>
					<Grid item>
						<Box textAlign="left">
							<FilesList buttonText={props.buttonText} />
						</Box>
					</Grid>
				</Grid>
			}
		</div>
	);
};

export default QeeFileInput;
