// QeePicturesGalleryProvider
import React, { createContext, useMemo, useReducer, useContext, useRef } from 'react';

import QeePicturesGalleryDialog from './qeePicturesGalleryDialog';
import { useAuth } from "../context/provider"; 


const PicturesGalleryContext = createContext();

const QeePicturesGalleryProvider = (props) => {
	
	const { gstate } = useAuth();					
	
	const refPicturesGalleryDialog = useRef(null);			
	
	const setOpenPicturesGalleryDialog = ( pics, activeStep) => {
		console.log( 'setOpenPicturesGalleryDialog', [pics, activeStep]);
		refPicturesGalleryDialog.current.handlePicturesGalleryOpen( pics, activeStep);
	};

	const value = useMemo(() => {   
		return { setOpenPicturesGalleryDialog };	  	  
	}, []);	
	
	return (
		<PicturesGalleryContext.Provider value={value}>
			<QeePicturesGalleryDialog 
				ref={refPicturesGalleryDialog} 
			/>	
			{props.children}	
		</PicturesGalleryContext.Provider>
	);

}

const usePicturesGallery = () => useContext( PicturesGalleryContext );
export { PicturesGalleryContext, usePicturesGallery }
export default QeePicturesGalleryProvider;
	

