// QeeChatProvider
import React, { createContext, useMemo, useContext, useRef } from 'react';

import ChatDialog from './qeeChatDialog';
import { getFacChat } from "../api/service";


const ChatContext = createContext();

const QeeChatProvider = (props) => {
		
	const refChatDialog = useRef(null);			
	
	const setOpenChatDialog = ( param) => {
		refChatDialog.current.handleChatOpen( param);
	};
	
	const handleClickOpenChat = async ( facId) => {

		console.log( 'handleClickOpenChat');
		
		var chat = null;
		var sChat = sessionStorage.getItem( 'chat');
		if( sChat) {
			chat = JSON.parse( sChat);
			console.log( 'openChatDialog chat.chat_fac_id !== facId', [chat.chat_fac_id, facId]);
			if( chat.chat_fac_id !== facId) {	
				chat = null;
				sessionStorage.setItem( 'chat', null);
			}		
		} 

		// first try here
		console.log( 'openChatDialog chat from sessionStorage', chat);
		chat = await getFacChat( facId, chat ? chat.id : null);
		if( chat) {
			sessionStorage.setItem( 'chat', JSON.stringify({
					id:chat.id, 
					chat_fac_id:chat.chat_fac_id
				})
			);			
		// second try, if the chatid in the sessionStorage is set, but it was not found on db / does not exist	
		} else {
			chat = await getFacChat( facId, null);
			if( chat) {
				sessionStorage.setItem( 'chat', JSON.stringify({
						id:chat.id, 
						chat_fac_id:chat.chat_fac_id
					})
				);			
			}	
		}
		//alert(chat.id)
		setOpenChatDialog( chat);	
	}		
	
	const value = useMemo(() => {   
		return { setOpenChatDialog, handleClickOpenChat };	  	  
	}, []);	
	
	return (
		<ChatContext.Provider value={value}>
			<ChatDialog 
				ref={refChatDialog} 
			/>	
			{props.children}	
		</ChatContext.Provider>
	);

}

const useChat = () => useContext( ChatContext );
export { ChatContext, useChat }
export default QeeChatProvider;
	

