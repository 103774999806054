// colors.js
const primaryColor = process.env.QEE_PRIMARY_COLOR || '#004c3f';
const secondaryColor = process.env.QEE_SECONDARY_COLOR || '#aaffff';
const tertiaryColor = process.env.QEE_TERTIARY_COLOR || '#aaa';
const licenseColor = process.env.QEE_LICENSE_COLOR || '#9c27b0';
const newFeatureColor = process.env.QEE_NEW_FEATURE_COLOR || '#c63843';
const facNewbuildColor = process.env.QEE_FAC_NEWBUILD_COLOR || '#c63843';
const facRenovationColor = process.env.QEE_FAC_RENOVATION_COLOR || '#c63843';

const styleElement = document.createElement('style');
styleElement.innerHTML = `
  :root {
    --primary-color: ${primaryColor};
    --secondary-color: ${secondaryColor};
    --tertiary-color: ${tertiaryColor};
    --license-color: ${licenseColor};
    --new-feature-color: ${newFeatureColor};
    --fac-newbuild-color: ${facNewbuildColor};	
    --fac-renovation-color: ${facRenovationColor};		
  }
`;
document.head.appendChild(styleElement);
