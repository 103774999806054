import React, { createContext, useContext, useState } from 'react';

const ObjChangeContext = createContext(null);

export const ObjChangeProvider = ({ children }) => {
  const [objChange, setObjChange] = useState({
    steps: []
  });

  return (
    <ObjChangeContext.Provider value={{ objChange, setObjChange }}>
      {children}
    </ObjChangeContext.Provider>
  );
};

export const useObjChange = () => useContext(ObjChangeContext);