// FacForm.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { Dimensions, Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@mui/material/Paper';
import Box from '@material-ui/core/Box';

import FacTitle from './facTitle';
import FacValuationEmpty from './facValuationEmpty';
import DiagramValuation from './diagramValuation';
import ChartPlanValuation from './chartPlanValuation';
import FacEnergyclassEmpty from './facEnergyclassEmpty';
import QeeHintBox from './qeeHintBox';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(0.5),
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0.5)
		}
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	button: {
		textTransform: 'none'
	},
	box: {
		backgroundColor:theme.palette.primary.contrastText,
		border: 'solid 1px #E4EAE6',
		padding:20,
	},	
	resultCard: {
		display: 'flex', 
		//width:'100%',
		padding:10,
		marginBottom: 10,
		borderRadius: 5,
		border: `1px solid ${theme.palette.primary.main}`,		
		padding: 20
	},	
});


// main fct
const FacInsuranceForm = ( props) => {
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();			
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
		isXL: false,
		width: window.innerWidth,		
	})
	const [iFrameTitle, setIFrameTitle] = useState( null);		
	const [iFrameUrl, setIFrameUrl] = useState( null);			
	const [iFrameMaxWidth, setIFrameMaxWidth] = useState( 1200);			
	const [iFrameHeight, setIFrameHeight] = useState( 800);				
	
	function handleWindowSizeChange() {
		setState({
			...state,
			width: window.innerWidth
		});
	}	
	
	
	useEffect( () => {		
		getIFrame();
	}, []);				
	
	
	useEffect( () => {	

		setState( {
			...state,
			isXL: state.width>=1536 ? true : false
		});
		console.log( 'state', state);				
		
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
												
	}, [state.width]);		

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);				
	
			
	const formatCurrency = ( vl) => {
		return new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR'}).format( vl).replace(/\D00(?=\D*$)/, '')
	}
	

	const getHintInsurance = () => {
		return `<span style="color:red"><strong>Diese Funktionalität ist in dieser Version nicht verfügbar!</strong></span><br /><br />Wende dich bitte an uns für weitere Informationen!`
	}	
	
	
	/**
	*	getIFrame() - checks if an iframe was configurated for this liccus
	*					liccus must have a field like: cfg_ui_schema: "vreed-interhyp"
	*					The col "cfg_ui_schema" must have a document like:
	*					FacInsuranceForm
	*						(map)
	*							has_iframe: true
	*							iframe_url: "https://www.interhyp.de/angular/lafpartner/interest-check/?LAF_PARTNER=NETSLAVE_RES&pid=1277&wmid=233&STYLE=CUSTOM&COLOR=EE7900&minimalMode=enabled#/"
	*							name: "vreed-interhyp"
	*/
	const getIFrame = () => {
		const usr = JSON.parse( sessionStorage.getItem('usr') || '{}');
		const cfgUiSchema = usr?.lic?.cfg_ui_schema;
		if (cfgUiSchema?.[FacInsuranceForm.displayName]?.has_iframe) {
			let fm = usr.lic.cfg_ui_schema[FacInsuranceForm.displayName]
			setIFrameTitle( fm.iframe_title);
			setIFrameUrl( fm.iframe_url);
			if( fm.iframe_max_width)
				setIFrameMaxWidth( fm.iframe_max_width);
			if( fm.iframe_height)			
				setIFrameHeight( fm.iframe_height);
		}
	};		
		
		
	const IFrame = ({ src }) => {

		return (
			<>
			<Typography className="content" dangerouslySetInnerHTML={{__html: iFrameTitle}}></Typography>		
			<iframe
				src={src}
				title="embedded content"
				scrolling="no"
				style={{
					width: '100%',
					height: '100%',
					border: 'none',
					display: 'block'
				}}
			/>
			</>
		);
	};	
		
	
	return (	
		gstate.isLoading ? (
			<div className={classes.root}>
				{gstate.isLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (			
			<main className={classes.content}>
				<FacTitle fac={gstate.objEdit} showPicture={true} />
				
				<Grid container 
					spacing={2} 
					direction="row" 
					justifyContent="left" 
					alignItems="left">	
					{iFrameUrl ?	
						<Grid item style={{width:'100%', maxWidth:{iFrameMaxWidth}, height:800, marginTop: 10}}>
							<h1>Versicherungen</h1>												
							<IFrame src={iFrameUrl} /> 
						</Grid>					
					:												
						<Grid item xs={12} style={{maxWidth:1200}}>						
							<h1>Versicherungen</h1>												
							<p>Wähle aus den benötigten und den optionalen Versicherungspakete den besten Schutz für deine Liebsten und deine Immobilie aus.</p>	
							<Box className={classes.resultCard}>
								<QeeHintBox text={<React.Fragment><strong>Einschränkung:</strong> <span dangerouslySetInnerHTML={{__html: getHintInsurance()}}></span></React.Fragment>} style={{marginTop:20}}/>															
							</Box>
						</Grid>
					}
					
					<Grid item xs={12}>						
					</Grid>
				</Grid>											
				
			</main>				
		)		
	)
}	
FacInsuranceForm.displayName = 'FacInsuranceForm';	
export default withStyles(styles)(FacInsuranceForm);