// QeeUsrLicenseProvider
import React, { createContext, useMemo, useReducer, useContext, useRef } from 'react';

import UsrLicenseDialog from './usrLicenseDialog';
import { useAuth } from "../context/provider"; 


const LicenseContext = createContext();

const QeeUsrLicenseProvider = (props) => {
	
	const { gstate } = useAuth();					
	
	const refLicenseDialog = useRef(null);			
	
	const setOpenLicenseDialog = ( bFlag) => {
		refLicenseDialog.current.handleLicenseOpen( bFlag);
	};

	const value = useMemo(() => {   
		return { setOpenLicenseDialog };	  	  
	}, []);	
	
	return (
		<LicenseContext.Provider value={value}>
			<UsrLicenseDialog 
				ref={refLicenseDialog} 
			/>	
			{props.children}	
		</LicenseContext.Provider>
	);

}

const useLicense = () => useContext( LicenseContext );
export { LicenseContext, useLicense }
export default QeeUsrLicenseProvider;
	

