import React from 'react';
import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/styles';
//import { ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderV4, StylesProvider } from '@material-ui/core/styles';
import { themeV4, themeV5 } from './core/theme';

import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from "material-ui-confirm";

import GUsrActionHandler from './pages/gUsrActionHandler';
import Login from './pages/login';
import Signup from './pages/signup';
import VerifyEmailEndConfirm from './pages/verifyEmailEndConfirm';
import PwdReset from './pages/pwdReset';
import PwdResetSent from './pages/pwdResetSent';
import PwdResetEnd from './pages/pwdResetEnd';
import PwdResetEndConfirm from './pages/pwdResetEndConfirm';
import Home from './pages/home';
import Admin from './pages/admin';
import Fac from './pages/fac';
import Chats from './pages/chats';
import Sacs from './pages/sacs';
import Sac from './pages/sac';
import FacsSearch from './pages/facssearch';
import Buy from './pages/buy';
import SshotFacDiagramEnergyClass from './pages/sshotFacDiagramEnergyClass';
import SshotFacDiagramValuation from './pages/sshotFacDiagramValuation';
import SshotFacChartPlanValuation from './pages/sshotFacChartPlanValuation';
import SshotFacChartNewbuildCosts from './pages/sshotFacChartNewbuildCosts';
//import QeeWidgets from './pages/qeeWidgets';
import withBackground from './components/withBackground';
import WaitProgressBar from './components/waitProgressBar';
import QeeUsrLicenseProvider from './components/qeeUsrLicenseProvider';			
import QeeFileUploaderProvider from './components/qeeFileUploaderProvider';			
import QeePicturesGalleryProvider from './components/qeePicturesGalleryProvider'
import QeePromotionProvider from './components/qeePromotionProvider';
import QeeChatProvider from './components/qeeChatProvider';
import QeeConfluenceProvider from './components/qeeConfluenceProvider';			
import QeeFacShareProvider from './components/qeeFacShareProvider';			
import QeeOrgProvider from './components/qeeOrgProvider';			
import QeeSacDialogProvider from './components/qeeSacDialogProvider';			
import QeeStepperDialogProvider from './components/qeeStepperDialogProvider';		
import QeeSlideDialog from './components/qeeSlideDialog';
import VersionInfo from './components/versionInfo';	

import AuthProvider from "./context/provider";
import packageJson from '../package.json';

import { resetVersion } from "./api/service";

function App() {
		
	useEffect(()=> { 	
		document.title = process.env.QEE_APP_NAME;    //'Vreed.INSIGHT';					
	}, []);					
	
	
	//<Route path="/qeeWidgets" element={<QeeWidgets/>}/>											
	return (	
		<AuthProvider>
		<ThemeProviderV4 theme={themeV4}>
		<ThemeProviderV5 theme={themeV5}>
			<WaitProgressBar />
			<SnackbarProvider maxSnack={3} autoHideDuration={10000}>
			<ConfirmProvider>
			<QeeUsrLicenseProvider>
			<QeeFileUploaderProvider>
			<QeePicturesGalleryProvider>
			<QeePromotionProvider>
			<QeeChatProvider>			
			<QeeConfluenceProvider>			
			<QeeFacShareProvider>						
			<QeeOrgProvider>									
			<QeeSacDialogProvider>												
			<QeeStepperDialogProvider>												
			<Router>
				<div>
					<Routes>
						<Route path="/" element={<Home/>}/>
						<Route path="/gUsrActionHandler" element={<GUsrActionHandler/>}/>												
						<Route path="/login" element={<Login/>}/>
						<Route path="/signup" element={<Signup/>}/>
						<Route path="/verifyEmailEndConfirm" element={<VerifyEmailEndConfirm/>}/>												
						<Route path="/pwdReset" element={<PwdReset/>}/>						
						<Route path="/pwdResetSent" element={<PwdResetSent/>}/>	
						<Route path="/pwdResetEnd" element={<PwdResetEnd/>}/>
						<Route path="/pwdResetEndConfirm" element={<PwdResetEndConfirm/>}/>						
						<Route path="/home" element={<Home location={JSON.parse( JSON.stringify( window.location))}/>}/>
						<Route path="/fac" element={<Fac/>}/>					
						<Route path="/facssearch" element={<FacsSearch/>}/>		
						<Route path="/chats" element={<Chats/>}/>																							
						<Route path="/sacs" element={<Sacs/>}/>																	
						<Route path="/sac" element={<Sac/>}/>											
						<Route path="/buy" element={<Buy/>}/>	
						<Route path="/admin" element={<Admin/>}/>												
						<Route path="/sshotFacDiagramEnergyClass" element={<SshotFacDiagramEnergyClass/>}/>					
						<Route path="/sshotFacDiagramValuation" element={<SshotFacDiagramValuation/>}/>					
						<Route path="/sshotFacChartPlanValuation" element={<SshotFacChartPlanValuation/>}/>	
						<Route path="/sshotFacChartNewbuildCosts" element={<SshotFacChartNewbuildCosts/>}/>							
					</Routes>
				</div>
			</Router>
			</QeeStepperDialogProvider>															
			</QeeSacDialogProvider>															
			</QeeOrgProvider>												
			</QeeFacShareProvider>									
			</QeeConfluenceProvider>
			</QeeChatProvider>	
			</QeePromotionProvider>			
			</QeePicturesGalleryProvider>				
			</QeeFileUploaderProvider>	
			</QeeUsrLicenseProvider>
			</ConfirmProvider>
			</SnackbarProvider>
			
			<QeeSlideDialog 
				id='dlgVersionInfo'
				title='Versionsinformationen' 
				formComponent={
					<VersionInfo />
				}	
				hideDialogAppBar={false}
				hideDialogActions={true}
				submitDisabled={true}
				paddingContent={0}
			>				
			</QeeSlideDialog>		
			
			
		</ThemeProviderV5>					
		</ThemeProviderV4>		
		</AuthProvider>
	);
}
export default withBackground(App);
