// facsSearch.js

import React, { Component } from 'react';
import react, { useState, useEffect, useLayoutEffect } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import SearchIcon from "@mui/icons-material/Search";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { authMiddleWare } from '../util/auth';
import { useNavigate } from "react-router-dom";

import useWindowDimensions from '../util/windowDimensions';
import { useAuth } from "../context/provider";

import FacsSelect from './facsSelect';
import FacsSelectLocality from './facsSelectLocality';
import FacsSelectType from './facsSelectType';
import FacsSelectPrice from './facsSelectPrice';
import FacsSelectRoomsCount from './facsSelectRoomsCount';
import FavsNotFound from './favsNotFound';
import FacCard from './facCard';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	gridContent: {
		maxWidth: 1200
	},
	dialog: {
		width: '100%',
		//maxWidth: 1200,		 
	},
	form: {
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	sortingToolbar: {
		marginTop: 20,
		maxWidth: 1200,
	},
	filterToolbar: {
		maxWidth: 1200,
	},	
	results: {
		maxWidth: 1200,
		marginTop: 20
	},	
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	dialogeStyle: {
		maxWidth: '50%'
	},
	viewRoot: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	selectButton: {
		boxShadow: 'none',
		border: `1px solid ${theme.palette.primary.main}`,				
	},
	icon: {
		color: 'red',
	},
	select: {
		padding: 20
	},
	label: {
		paddingRight: 10,
		margin: 'auto',
		marginRight: 10
	}
});


const FacsFav = ( props) => {
	
	const navigate = useNavigate();								
	
	const { classes, ...rest } = props;
		
	const { gstate, setFacsFav, setFacsSearching, setOpenNewFac } = useAuth();					
		
	const [uiLoading, setUiLoading] = useState( true);		
					
				
	useEffect( () => {
				
		handleSearch();
		
		setUiLoading( false);		
				
	}, [gstate.facsSearching]);	


	const handleSearch = async () => {	
	
		console.log( '>>> facsFav handleSearch', gstate);
		
		//authMiddleWare(this.props.history);
		const authToken = localStorage.getItem( 'AuthToken');
		if( authToken == null) navigate( '/home');					

		axios.defaults.headers.common = { Authorization: `${authToken}` };
		var ret = await axios.get( '/api/facsFav');
		console.log( 'ret', ret);
		setFacsFav( ret.data);
		
		setFacsSearching( false);		
	}	
				
			
	const hasResults = () => {
		
		console.log( 'hasResults', gstate);
		try {
				
			if( gstate.facsFav.entries) {
				console.log( 'gstate.facsFav.entries', gstate.facsFav.entries);
				let ents = gstate.facsFav.entries;
				
				if( ents.hits > 0) {
					console.log( 'hasResults: ', ents.hits);
					return true;
				}
			}
			
			return false;			
		
		} catch( err) {
			console.error( 'hasResults ERR', err);
			return false;	
		}
	}
				
	
	const toggleFavFacHandler = (event, data) => {	
		
		console.log( 'toggleFavFacHandler', data);	

		setFacsSearching( true);		
		
		// put it in the UI (optimistic)
		var facChanged;
		var facs = gstate.facsFav;		
		facs.entries.results.map((fac) => {
			if( fac.fac_extern_id === data.fac_extern_id) {
				if( !fac.fac_fav)					
					fac.fac_fav = true;
				else
					fac.fac_fav = false;					
				facChanged = JSON.parse( JSON.stringify( fac));
			}
			return fac;
		});	
		setFacsFav( facs);

		// save it on the server for this user
		const authToken = localStorage.getItem( 'AuthToken');
		if( authToken == null) navigate( '/home');
		axios.defaults.headers.common = { Authorization: `${authToken}` };	
		
		var opts;
		console.log( 'facChanged', facChanged);
		if( facChanged.fac_fav) {   // this is the value after change
			console.log( 'Post new fav');
			var opts = {
				url: '/api/facfav',
				method: 'post',
				data: facChanged
			};					
		} else {
			console.log( 'Delete fav');			
			var opts = {
				url: '/api/facfav/' + facChanged.fac_extern_id,
				method: 'delete'
			};											
		}
		console.log( 'Calling axios', opts);
		axios( opts);
		
		console.log( 'facsFav', gstate.facsFav);
	}	
	
	
	const makeGvalueText = (fac) => {						
		var ret = '';
		if( fac.addr_street_o)
			ret += fac.addr_street_o;
		if( fac.addr_street_zip_o)
			ret += ' ' + fac.addr_street_zip_o;
		if( fac.addr_locality_o)
			ret += ' ' + fac.addr_locality_o;		
		
		return ret;
	}
	
	
	const newFacHandler = (fac) => {			
		console.log( 'newFacHandler', fac);   // set the initial data for the obj creation
		fac.addr_gvalue_text_o = makeGvalueText( fac);
		fac.addr_gvalue_o = {};
		fac.addr_gvalue_o.label = makeGvalueText( fac);
		setOpenNewFac( fac);
		navigate( '/home');									
	}
	
	
	return (
			<main className={classes.content} style={{width:useWindowDimensions().width-320}}>
				<div className={classes.toolbar} /> 
				{uiLoading === true ? (
					<>
						<CircularProgress size={150} className={classes.uiProgess} />
					</>
				) : (	
					<div>
						{!hasResults() ? (
							<FavsNotFound fctSearch={() => navigate( '/facssearch')}/>
						) : (
							<>							
							<Grid 
								container 
								spacing={2}
								className={classes.results}
							>
								{gstate.facsFav.entries.results.map((fac) => ( 
									<Grid item xs={16} sm={16}>							
										<FacCard 
											fac={fac} 
											toggleFavFacHandler={toggleFavFacHandler}
											newFacHandler={newFacHandler}
										/>																																		
									</Grid>
								))}
							</Grid>
						</>
						)
						}
					</div>
				)
				}
			</main>		
	);
	
}
export default withStyles(styles)(FacsFav);	
