import React, { createContext, useMemo, useReducer, useContext } from 'react';
import useScrollBlock from '../util/useScrollBlock';
import moment from 'moment';

//IMPORT REDUCER, INITIAL STATE AND ACTION TYPES
import reducer, { initialGState } from "./reducer";


// CONTEXT ===================================
const AuthContext = React.createContext();

const AuthProvider = (props) => {

	const [gstate, dispatch] = useReducer( reducer, initialGState || {});
	
	const [ blockScroll, allowScroll ] = useScrollBlock();							

	const setIsBetaVer = (isBetaVer) => {
		console.log( 'provider setIsBetaVer', isBetaVer);	  
		dispatch({ type: 'SET_IS_BETAVER', isBetaVer: isBetaVer });
	};  			
	
	const setIsXS = (isXS) => {
		console.log( 'provider setIsXS', isXS);	  
		dispatch({ type: 'SET_IS_XS', isXS: isXS });
	};  		
	
	const setIsSM = (isSM) => {
		console.log( 'provider setIsSM', isSM);	  
		dispatch({ type: 'SET_IS_SM', isSM: isSM });
	};  	
	
	const setIsMD = (isMD) => {
		console.log( 'provider setIsMD', isMD);	  
		dispatch({ type: 'SET_IS_MD', isMD: isMD });
	};  

	const setIsLG = (isLG) => {
		console.log( 'provider setIsLG', isLG);	  
		dispatch({ type: 'SET_IS_LG', isLG: isLG });
	};  

	const setIsXL = (isXL) => {
		console.log( 'provider setIsXL', isXL);	  
		dispatch({ type: 'SET_IS_XL', isXL: isXL });
	};  		
	
	const setIsLoading = (isLoading) => {
		console.log( 'provider setIsLoading', isLoading);	  
		dispatch({ type: 'SET_IS_LOADING', isLoading: isLoading });
	};  
	
	const setIsFetching = (isFetching) => {
		console.log( 'provider setIsFetching', isFetching);	  
		dispatch({ type: 'SET_IS_FETCHING', isFetching: isFetching });
		
		if( isFetching)
			blockScroll();
		else	
			allowScroll();			
		
	};  	
	
	const setCurrPage = (currPage) => {
		console.log( 'provider setCurrPage', currPage);	  
		dispatch({ type: 'SET_CURR_PAGE', currPage: currPage });
	}; 
 	
	const setCurrSubpage = (currSubpage) => {
		console.log( 'provider setCurrSubpage', currSubpage);	  
		dispatch({ type: 'SET_CURR_SUBPAGE', currSubpage: currSubpage });
	}; 
	
	const setOpenNewFac = async (data) => {
		console.log( 'provider setOpenNewFac', data);					
		dispatch({ type: 'SET_OPEN_NEW_FAC', openNewFac: data });
	};  

	const setSubmitNewFac = async (data) => {
		console.log( 'provider setSubmitNewFac', data);					
		dispatch({ type: 'SET_SUBMIT_NEW_FAC', submitNewFac: data });
	};  	
		
	const setUsr = async (data) => {
		if( !data.lic) {
			data.lic = {type: 'free'};
		}	
		console.log( 'provider setUsr', data);					
		dispatch({ type: 'SET_USR', usr: data });
	};  				
		
	const setObjEdit = async (data) => {
		
		// only for fac
		if( data.form === 'fac') {
			// init the renos with subobjects	
			if( !data.fac_renovations || data.fac_renovations.length==0) {
				let y='2007';   // fixed because of the data
				data.fac_renovations = [
					{label:'Dach',    			  name:'has_renovation_roof',      cond:'fac.fac_type==100', value:false,  renovation:y},				
					{label:'Fassade', 			  name:'has_renovation_fassade',   cond:'fac.fac_type==100', value:false,  renovation:y},					
					{label:'Fenster', 			  name:'has_renovation_windows',  							 value:false,  renovation:y},					
					{label:'Keller/Bodenplatte',  name:'has_renovation_basement',  cond:'fac.fac_type==100', value:false,  renovation:y},					
					{label:'Heizung', 			  name:'has_renovation_heating',   cond:'fac.fac_type==100', value:false,  renovation:y},					
					{label:'PV-Dach', 			  name:'has_renovation_pvroof',    cond:'fac.fac_type==100', value:false,  renovation:y},																					
									
					{label:'Elektro', 	   	   name:'has_renovation_electric',  		value:false,  renovation:y},									
					{label:'Sanitär', 	   	   name:'has_renovation_sanitarie',  		value:false,  renovation:y},													
					{label:'Badezimmer', 	   name:'has_renovation_bath',  			value:false,  renovation:y},									
					{label:'Gäste-WC',    	   name:'has_renovation_restroom', 			value:false,  renovation:y},																	
					{label:'Fassadenanstrich', name:'has_renovation_exteriorpainting',  cond:'fac.fac_type==100', value:false,  renovation:y},
					{label:'Markise', 	   	   name:'has_renovation_sunblinde',  		value:false,  renovation:y},									
					{label:'Terrasse', 	   	   name:'has_renovation_terrace',  			value:false,  renovation:y},													
					{label:'Garten', 	   	   name:'has_renovation_landscaping', 		value:false,  renovation:y},																	
					{label:'Garage', 	   	   name:'has_renovation_garage',  			value:false,  renovation:y},													
					{label:'Carport', 	   	   name:'has_renovation_carport',  			value:false,  renovation:y}													
				]
			}	
			//
			// --- init the plan newbuilds with subobjects	
			//
			if( !data.fac_plan_newbuilds || data.fac_plan_newbuilds.length==0) {
				let y='2024';   // fixed because of the data
				data.fac_plan_newbuilds = [
					{label:'Rohbau', 			  name:'has_renovation_structure', cond:'fac.fac_type=="100"', 	value:'1',  renovation:y, type:"100", quality:"100"},														
					{label:'Dach',    			  name:'has_renovation_roof',      cond:'fac.fac_type=="100"', 	value:'1',  renovation:y, type:"100", quality:"100"},				
					{label:'Fassade', 			  name:'has_renovation_fassade',   cond:'fac.fac_type=="100"', 	value:'1',  renovation:y, type:"100", quality:"100"},					
					{label:'Fenster', 			  name:'has_renovation_windows',  								value:'1',  renovation:y, type:"100", quality:"100"},					
					{label:'Keller/Bodenplatte',  name:'has_renovation_basement',  cond:'fac.fac_type=="100"', 	value:'1',  renovation:y, type:"100", quality:"100"},					
					{label:'Heizung', 			  name:'has_renovation_heating',   cond:'fac.fac_type=="100"', 	value:'1',  renovation:y, type:"900", quality:"100"},			
					{label:'PV-Dach', 			  name:'has_renovation_pvroof',    cond:'fac.fac_type=="100"', 	value:false,  renovation:y, type:"100", quality:"100"},
				]
			}
			if( !data.fac_plan_newbuilds2 || data.fac_plan_newbuilds2.length==0) {		
				let y='2024';   // fixed because of the data		
				data.fac_plan_newbuilds2 = [
					{label:'Elektro',  			name:'has_renovation_electric',    		value:'1',  renovation:y, type:"100", quality:"100"},	 // assume type 100									
					{label:'Sanitär',  			name:'has_renovation_sanitarie',    	value:'1',  renovation:y, type:"100", quality:"100"},	 // assume type 100									
					{label:'Badezimmer',    	name:'has_renovation_bath',     		value:'1',  renovation:y, type:"100", quality:"100"},	 // assume type 100						
					{label:'Gäste-WC',     		name:'has_renovation_restroom',    		value:'1',  renovation:y, type:"100", quality:"100"},																	
					{label:'Fassadenanstrich',  name:'has_renovation_exteriorpainting', cond:'fac.fac_type=="100"', value:false,  renovation:y, type:"100", quality:"100"},							
					{label:'Markise', 	    	name:'has_renovation_sunblinde',     	value:false,  renovation:y, type:"100", quality:"100"},														
					{label:'Terasse', 	    	name:'has_renovation_terrace',     		value:false,  renovation:y, type:"100", quality:"100"},														
					{label:'Garten', 	    	name:'has_renovation_landscaping', 		value:false,  renovation:y, type:"100", quality:"100"},																						
					{label:'Garage', 	    	name:'has_renovation_garage',     		value:false,  renovation:y, type:"100", quality:"100"},										
					{label:'Carport', 	    	name:'has_renovation_carport',     		value:false,  renovation:y, type:"100", quality:"100"}													
				]
			}	
			//
			// -- init the plan renos with subobjects	
			//
			if( !data.fac_plan_renovations || data.fac_plan_renovations.length==0) {
				let y='2007';   // fixed because of the data
				data.fac_plan_renovations = [
					{label:'Rohbau', 			  name:'has_renovation_structure', cond:'fac.fac_type=="100" && fac.fac_prj_type!=="renovation"', 	value:false,  renovation:y, type:"100", quality:"100"},														
					{label:'Dach',    			  name:'has_renovation_roof',      cond:'fac.fac_type=="100"', 	value:false,  renovation:y, type:"100", quality:"100"},				
					{label:'Fassade', 			  name:'has_renovation_fassade',   cond:'fac.fac_type=="100"', 	value:false,  renovation:y, type:"100", quality:"100"},					
					{label:'Fenster', 			  name:'has_renovation_windows',  								value:false,  renovation:y, type:"100", quality:"100"},					
					{label:'Keller/Bodenplatte',  name:'has_renovation_basement',  cond:'fac.fac_type=="100"', 	value:false,  renovation:y, type:"100", quality:"100"},					
					{label:'Heizung', 			  name:'has_renovation_heating',   cond:'fac.fac_type=="100"', 	value:false,  renovation:y, type:"900", quality:"100"},			
					{label:'PV-Dach', 			  name:'has_renovation_pvroof',    cond:'fac.fac_type=="100"', 	value:false,  renovation:y, type:"100", quality:"100"},
				]
			}		
			if( !data.fac_plan_renovations2 || data.fac_plan_renovations2.length==0) {		
				let y='2007';   // fixed because of the data		
				data.fac_plan_renovations2 = [
					{label:'Elektro',  			name:'has_renovation_electric',    		value:false,  renovation:y, type:"100", quality:"100"},	 // assume type 100									
					{label:'Sanitär',  			name:'has_renovation_sanitarie',    	value:false,  renovation:y, type:"100", quality:"100"},	 // assume type 100									
					{label:'Badezimmer',    	name:'has_renovation_bath',     		value:false,  renovation:y, type:"100", quality:"100"},	 // assume type 100						
					{label:'Gäste-WC',     		name:'has_renovation_restroom',    		value:false,  renovation:y, type:"100", quality:"100"},																	
					{label:'Fassadenanstrich',  name:'has_renovation_exteriorpainting', cond:'fac.fac_type=="100"', value:false,  renovation:y, type:"100", quality:"100"},							
					{label:'Markise', 	    	name:'has_renovation_sunblinde',     	value:false,  renovation:y, type:"100", quality:"100"},														
					{label:'Terasse', 	    	name:'has_renovation_terrace',     		value:false,  renovation:y, type:"100", quality:"100"},														
					{label:'Garten', 	    	name:'has_renovation_landscaping', 		value:false,  renovation:y, type:"100", quality:"100"},																						
					{label:'Garage', 	    	name:'has_renovation_garage',     		value:false,  renovation:y, type:"100", quality:"100"},										
					{label:'Carport', 	    	name:'has_renovation_carport',     		value:false,  renovation:y, type:"100", quality:"100"}														
				]
			}
			if( !data.fac_energy) {
				data.fac_energy = {
					energyclass_plan: 'singles',
					energyclass_method: 'singles'
				};
			}	
			if( !data.fac_investment) {
				data.fac_investment = {};
			}
			if( !data.fac_valuation) {
				data.fac_valuation = {};
			}			
			if( !data.fac_financing) {
				data.fac_financing = {};
			}					
			if( !data.fac_taxonomy) {
				data.fac_taxonomy = {};
				data.fac_taxonomy.done = false;
				data.fac_taxonomy.activeStep = 0;
				data.fac_taxonomy.steps = [];
			}							
			if( !data.fac_insurance) {
				data.fac_insurance = {};
			}					
		}

		console.log( 'provider setObjEdit', data);			
		dispatch({ type: 'SET_OBJ_EDIT', objEdit: data });
	};  	
	
	const setObjErrors = async (data) => {
		console.log( 'provider setObjErrors', data);					
		dispatch({ type: 'SET_OBJ_ERRORS', objErrors: data });
	};  		
	  	  
	const setFacsSearching = async (data) => {
		console.log( 'provider setFacsSearching', data);					
		dispatch({ type: 'SET_FACS_SEARCHING', facsSearching: data });
	};  		  		  
		  
	const setFacsSearchFilter = async (data) => {
		console.log( 'provider setFacsSearchFilter', data);					
		dispatch({ type: 'SET_FACS_SEARCH_FILTER', facsSearchFilter: data });
	};  		  
	
	const setFacsExternSorting = async (data) => {
		console.log( 'provider setFacsExternSorting', data);					
		dispatch({ type: 'SET_FACS_EXTERN_SORTING', facsExternSorting: data });
	};  		  	
	
	const setFacs = async (data) => {
		console.log( 'provider setFacs', data);					
		dispatch({ type: 'SET_FACS', facs: data });
	};  			  	
	
	const setFacsType = async (data) => {
		console.log( 'provider setFacsType', data);					
		dispatch({ type: 'SET_FACS_TYPE', facsType: data });
	};  			  		
	
	const setFacsExtern = async (data) => {
		console.log( 'provider setFacsExtern', data);					
		dispatch({ type: 'SET_FACS_EXTERN', facsExtern: data });
	};  		

	const setSearchErrors = async (data) => {
		console.log( 'provider setSearchErrors', data);					
		dispatch({ type: 'SET_SEARCH_ERRORS', searchErrors: data });
	};  		  	
	
	const setFacsFav = async (data) => {
		console.log( 'provider setFacsFav', data);					
		dispatch({ type: 'SET_FACS_FAV', facsFav: data });
	};  		

	const setOpenNewbuildDialog = async (data) => {
		console.log( 'provider setOpenNewbuildDialog', data);					
		dispatch({ type: 'SET_OPEN_NEWBUILD_DIALOG', openNewbuildDialog: data });
	};
	
	const setOpenRenovationDialog = async (data) => {
		console.log( 'provider setOpenRenovationDialog', data);					
		dispatch({ type: 'SET_OPEN_RENOVATION_DIALOG', openRenovationDialog: data });
	};  					
	
	const setOpenValuationDialog = async (data) => {
		console.log( 'provider setOpenValuationDialog', data);					
		dispatch({ type: 'SET_OPEN_VALUATION_DIALOG', openValuationDialog: data });
	};  						
	
	const setOpenFinancingDialog = async (data) => {
		console.log( 'provider setOpenFinancingDialog', data);					
		dispatch({ type: 'SET_OPEN_FINANCING_DIALOG', openFinancingDialog: data });
	};  							
	
	const setOpenInsuranceDialog = async (data) => {
		console.log( 'provider setOpenInsuranceDialog', data);					
		dispatch({ type: 'SET_OPEN_INSURANCE_DIALOG', openInsuranceDialog: data });
	};  								
	
	const setOpenItemEditor = async (data) => {
		console.log( 'provider setOpenItemEditor', data);					
		dispatch({ type: 'SET_OPEN_ITEM_EDITOR', openItemEditor: data });
	};  			
	
	const setOpenQeeDialog = async (data) => {
		console.log( 'provider setOpenQeeDialog', data);					
		dispatch({ type: 'SET_OPEN_QEE_DIALOG', openQeeDialog: data });
	};  				
	
	const setQeeSnackbar = async (data) => {
		console.log( 'provider setQeeSnackbar', data);					
		dispatch({ type: 'SET_QEE_SNACKBAR', qeeSnackbar: data });
	};  					
	  
	const value = useMemo(() => {   
		return {gstate, setIsBetaVer, setIsXS, setIsSM, setIsMD, setIsLG, setIsXL, setIsLoading, setIsFetching, setCurrPage, setCurrSubpage, setOpenNewFac, setSubmitNewFac, setUsr, setObjEdit, setObjErrors, setFacsSearching, setFacsSearchFilter, setFacsExternSorting, setFacs, setFacsType, setFacsExtern, setSearchErrors, setFacsFav, setOpenNewbuildDialog, setOpenRenovationDialog, setOpenValuationDialog, setOpenFinancingDialog, setOpenInsuranceDialog, setOpenItemEditor, setOpenQeeDialog, setQeeSnackbar };	  	  
	}, [gstate]);
  
	return (
      <AuthContext.Provider value={value}>
          {props.children}
      </AuthContext.Provider>
	);

}

const useAuth = () => useContext( AuthContext );
export { AuthContext, useAuth }
export default AuthProvider;
