// waitProgressBar.js
import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useScrollBlock from '../util/useScrollBlock';
import ProgressWindMillGif from '../assets/progress-wind-mill-128.gif';

import { useAuth } from "../context/provider";

export default function WaitProgressBar() {
		
	const { gstate } = useAuth();					

	const handleClose = () => {
		console.log( 'handleClose!');
	};
	
	return (
		<Dialog 
			open={gstate.isFetching}  
			onClose={handleClose}
			scroll="body"
			disableScrollLock
			BackdropProps={{ style: { backgroundColor: "transparent" }} }>
			<DialogTitle sx={{ 
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'center', 
				transform: 'scale(0.8)', 
				transformOrigin: 'center',
				padding: 0,
				width: 100
			}}>
				<img src={ProgressWindMillGif} alt="Lade Daten..." style={{ width: '100%', height: 'auto' }} />
				{/*
				<CircularProgress
					sx={{
						color: (theme) => theme.palette.primary.main,
						'&.Mui-checked': {
							color: (theme) => theme.palette.primary.main,
						},
					}}
				/>
				*/}
			</DialogTitle>
		</Dialog>		
	);
}
