// service.js
import React from 'react';

import axios from 'axios';
import moment from 'moment';

import {deleteIndexedDb, initIndexedDb, getItem, getItemsByUrl, addItem, deleteItem, deleteItems} from '../util/qeeCliIndexedDb';

export const resetVersion = ( ) => {
	
	const REACT_VERSION = React.version;
	console.log( 'React version', REACT_VERSION);
	
	let url = '/api/synfire/version';
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;
	}
	axios.get( url)
		.then((response) => {
			console.log( 'Synfire version', response);
			if( response.data) {				
				try {
					// if another version, delete indexedDb 
					var serverVer = Number( response.data.version.replace(/\./g, '').replace(/\D/g,''));					
					var lv = localStorage.getItem('ver');
					if( lv) {
						var localVer = Number( localStorage.getItem('ver').replace( 'v', '').replace(/\./g, '').replace(/\D/g,''));	
						console.log( '>>> Compare versions - serverVer vs. localVer', [serverVer, localVer]);
						if( serverVer != localVer) {
							var nm = 'qeeDB';
							console.log( 'Deleting indexedDb', nm);
							deleteIndexedDb( nm); //localVer
						}
					}
				} catch( e) {
					console.log( 'ERR: No local version string!');
				}
				
				// set the server ver to local
				localStorage.setItem( 'ver', 'v'+response.data.version);				
			}
		});
}


export const getRoofType = ( tp) => {
	
	console.log( '--> getRoofType', tp);
	
	var oRet;
	
	var data=[
		{type:'100', name:'Aufsparren-Dämmung'},															
		{type:'200', name:'Zwischensparren-Dämmung'},
		{type:'300', name:'Flachdachdämmung'},								
	];
	
	data.map((d, i) => {
		if( d.type === tp)
			oRet = d;
	})
	
	console.log( '<-- getRoofType', oRet);
	
	return oRet;
}


export const getHeatingType = ( tp) => {
	
	console.log( '--> getHeatingType', tp);
	
	var oRet;
	
	var data=[
		{type:'100', name:'Heizöl'},															
		{type:'200', name:'Erdgas'},
		{type:'300', name:'Flüssiggas'},								
		{type:'400', name:'Holz-Pellets'},																
		{type:'500', name:'Hackschnitzel'},								
		{type:'600', name:'Brennholz'},																
		{type:'700', name:'Fernwärme'},																
		{type:'800', name:'Nahwärme'},																								
		{type:'900', name:'Wärmepumpe Luft'},																								
		{type:'1000', name:'Wärmepumpe Erde'},																																
		{type:'1100', name:'Elektro/Strom'},																								
		{type:'1200', name:'Braunkohle'},																																																							
	];
	
	data.map((d, i) => {
		if( d.type === tp)
			oRet = d;
	})
	
	console.log( '<-- getHeatingType', oRet);
	
	return oRet;
}


/**
*	getSdatas() - 
*/
export const getSdatas = async (colName, keyFld, keyVal, retType) => {

	//console.log( '--> getSdatas', {colName, keyFld, keyVal, retType});
	
	var ret;
	
	if( keyFld==='')
		keyFld='type';
	
	//const authToken = localStorage.getItem( 'AuthToken');
	//if( authToken == null) 
	//	return {err:401};	
	
	if( colName==='')
		return {err:500};
	
	let qsep = '!!!';
	if( Array.isArray( keyFld))
		keyFld = keyFld.join( qsep);
	if( Array.isArray( keyVal))
		keyVal = keyVal.join( qsep);	
	
	var url = `/api/sdatas?col=` + colName;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;
	}
	if( keyVal && keyVal !== '') {
		url += '&fld='+keyFld+'&op=eq&val='+keyVal;
	}			
		
	try {	

		var ver = Number( localStorage.getItem('ver').replace( 'v', '').replace(/\./g, '').replace(/\D/g,''));	
		await initIndexedDb( ver);

		var resp = await getItem( url);

		//console.log( 'getSdatas QeeCliIndexedDb item found!', resp.resp);
		ret = resp.resp;
						
		// prepare for consuming in the client
		ret = ret.entries.results;							
							
	} catch( e) {
		
		console.error( 'getSdatas indexDb ERR', e);

		try {
			var sdatas = await getSdatasNet( colName, keyFld, keyVal, retType);
			
			// prepare for consuming in the client
			ret = sdatas.data.entries.results;
			
			addItem( url, sdatas.data);										

		} catch( e) {
			console.error( 'Cannot get sdatasNet or the url cannot be added to store: ' + url + ' ERR', {e, ret});	
		}	
	}

	if( retType==='vallab')
		ret = convertToValLab( ret);						

	//console.log( '<-- getSdatas', ret);
	return ret;		
	
} 
/**
*	getSdatasNet() - 
*/
export const getSdatasNet = async (colName, keyFld, keyVal, retType) => {

	//console.log( '--> getSdatasNet', {colName, keyFld, keyVal, retType});
	
	var ret;
		
	if( keyFld==='')
		keyFld='type';
	
	//const authToken = localStorage.getItem( 'AuthToken');
	//if( authToken == null) 
	//	return {err:401};	
	
	if( colName==='')
		return {err:500};
	
	let qsep = '!!!';
	if( Array.isArray( keyFld))
		keyFld = keyFld.join( qsep);
	if( Array.isArray( keyVal))
		keyVal = keyVal.join( qsep);	
	
	var url = `/api/sdatas?col=` + colName;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);	
	if( keyVal && keyVal !== '') {
		url += '&fld='+keyFld+'&op=eq&val='+keyVal;
	}	
	let opts = {
		url: url,
		method: 'get'
	};
	console.log( 'axios', opts);
	//axios.defaults.headers.common = { Authorization: `${authToken}` };
	try {
		const sdatas = await axios( opts);
		console.log( 'sdatas', sdatas);
		ret = sdatas;
		/*
		ret = sdatas.data.entries.results;
		if( retType==='vallab')
			ret = convertToValLab( ret);
		*/
			
	} catch( error) {
		console.log( '<-- getSdatasNet ERR', error);
		return { err:error.response.data};		
	} finally {
		//console.log( '<-- getSdatasNet', ret);
		return ret;
	};			
}


/**
*	getCfgs() - 
*/
export const getCfgs = async (colName, keyFld, keyVal, retType) => {

	//console.log( '--> getCfgs', {colName, keyFld, keyVal, retType});
	
	var ret;
	
	if( keyFld==='')
		keyFld='type';
	
	//const authToken = localStorage.getItem( 'AuthToken');
	//if( authToken == null) 
	//	return {err:401};	
	
	if( colName==='')
		return {err:500};
	
	let qsep = '!!!';
	if( Array.isArray( keyFld))
		keyFld = keyFld.join( qsep);
	if( Array.isArray( keyVal))
		keyVal = keyVal.join( qsep);	
	
	var url = `/api/cfgs?col=` + colName;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;
	}
	if( keyVal && keyVal !== '') {
		url += '&fld='+keyFld+'&op=eq&val='+keyVal;
	}			
		
	try {	

		var ver = Number( localStorage.getItem('ver').replace( 'v', '').replace(/\./g, '').replace(/\D/g,''));	
		await initIndexedDb( ver);

		var resp = await getItem( url);

		//console.log( 'getCfgs QeeCliIndexedDb item found!', resp.resp);
		ret = resp.resp;
						
		// prepare for consuming in the client
		ret = ret.entries.results;							
							
	} catch( e) {
		
		console.error( 'getCfgs indexDb ERR', e);

		try {
			var cfgs = await getCfgsNet( colName, keyFld, keyVal, retType);
			
			// prepare for consuming in the client
			ret = cfgs.data.entries.results;
			
			addItem( url, cfgs.data);										

		} catch( e) {
			console.error( 'Cannot get cfgsNet or the url cannot be added to store: ' + url + ' ERR', {e, ret});	
		}	
	}

	if( retType==='vallab')
		ret = convertToValLab( ret);						

	//console.log( '<-- getCfgs', ret);
	return ret;		
	
} 
/**
*	getCfgsNet() - 
*/
export const getCfgsNet = async (colName, keyFld, keyVal, retType) => {

	//console.log( '--> getCfgsNet', {colName, keyFld, keyVal, retType});
	
	var ret;
		
	if( keyFld==='')
		keyFld='type';
	
	//const authToken = localStorage.getItem( 'AuthToken');
	//if( authToken == null) 
	//	return {err:401};	
	
	if( colName==='')
		return {err:500};
	
	let qsep = '!!!';
	if( Array.isArray( keyFld))
		keyFld = keyFld.join( qsep);
	if( Array.isArray( keyVal))
		keyVal = keyVal.join( qsep);	
	
	var url = `/api/cfgs?col=` + colName;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);	
	if( keyVal && keyVal !== '') {
		url += '&fld='+keyFld+'&op=eq&val='+keyVal;
	}	
	let opts = {
		url: url,
		method: 'get'
	};
	console.log( 'axios', opts);
	//axios.defaults.headers.common = { Authorization: `${authToken}` };
	try {
		const cfgs = await axios( opts);
		console.log( 'cfgs', cfgs);
		ret = cfgs;
		/*
		ret = cfgs.data.entries.results;
		if( retType==='vallab')
			ret = convertToValLab( ret);
		*/
			
	} catch( error) {
		console.log( '<-- getCfgsNet ERR', error);
		return { err:error.response.data};		
	} finally {
		//console.log( '<-- getCfgsNet', ret);
		return ret;
	};			
}


/**
*	getTpls() - 
*/
export const getTpls = async (tplName, data) => {

	//console.log( '--> getTpls', {tplName, data});
	
	var ret;
			
	if( tplName==='')
		return {err:500};
		
	var url = `/api/tpls?col=` + tplName;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;
	}
		
	try {	

		var ver = Number( localStorage.getItem('ver').replace( 'v', '').replace(/\./g, '').replace(/\D/g,''));	
		await initIndexedDb( ver);

		var resp = await getItem( url);

		//console.log( 'getTpls QeeCliIndexedDb item found!', resp.resp);
		ret = resp.resp;
													
	} catch( e) {
		
		console.error( 'getTpls indexDb ERR', e);

		try {
			var tpls = await getTplsNet( tplName, data);
			
			// prepare for consuming in the client
			ret = tpls;
			
			addItem( url, tpls);										

		} catch( e) {
			console.error( 'Cannot get tplsNet or the url cannot be added to store: ' + url + ' ERR', {e, ret});	
		}	
	}

	//console.log( '<-- getTpls', ret);
	return ret;		
	
} 
/**
*	getTplsNet() - 
*/
export const getTplsNet = async ( tplName, data) => {

	//console.log( '--> getTplsNet', {tplName});
	
	var ret;
			
	if( tplName==='')
		return {err:500};
		
	var url = `/api/tpl/coded/${tplName}`;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);	
	let opts = {
		url: url,
		method: 'post',
		data: data
	};
	console.log( 'axios', opts);
	try {
		const tpls = await axios( opts);
		console.log( 'tpls', tpls);
		ret = tpls.data;
		/*
		ret = tpls.data.entries.results;
		if( retType==='vallab')
			ret = convertToValLab( ret);
		*/
			
	} catch( error) {
		console.log( '<-- getTplsNet ERR', error);
		return { err:error.response.data};		
	} finally {
		//console.log( '<-- getTplsNet', ret);
		return ret;
	};			
}


export const getLic = async (invNr) => {

	var ret;
	
	var url = '/api/lic/'+invNr;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		
	
	try {	

		var ver = Number( localStorage.getItem('ver').replace( 'v', '').replace(/\./g, '').replace(/\D/g,''));	
		await initIndexedDb( ver);

		var resp = await getItem( url);

		//console.log( 'QeeCliIndexedDb item found!', resp.resp);
		ret = resp.resp;
													
	} catch( e) {

		try {
			var lic = await getLicNet( invNr);
			
			// prepare for consuming in the client
			ret = lic.data;
			
			addItem( url, lic.data);										

		} catch( e) {
			console.error( 'Cannot get licNet or the url cannot be added to store: ' + url + ' ERR', {e, ret});	
		}	
	}
	
	console.log( '<-- getLic', ret);
	return ret;			
}	
/**
*	getLicNet() - 
*/
export const getLicNet = async ( invNr) => {

	//console.log( '--> getLicNet', {invNr});
	
	var ret;
	
	let options = {};
	options = {
		url: `/api/lic/${invNr}`,
		method: 'get'
	};
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		options.url = window.QEE_API_URL + options.url;	
	}
	console.log( 'Using URL', options.url);		
	try {
		const data = await axios( options)
		console.log( 'Lic data', data);
		ret = data;
			
	} catch( error) {
		console.log( '<-- getLicNet ERR', error);
		return { err:error.response.data};		
	} finally {
		//console.log( '<-- getLicNet', ret);
		return ret;
	};				
}


export const getDocs = async (fld, keyItm, keyVal, unm) => {

	//console.log( '--> getDocs', [fld, keyItm, keyVal]);

	var ret;
	
	var username;
	var usr = JSON.parse( sessionStorage.getItem( 'usr'));			
	if( usr) {
		console.log( 'sessionStorage usr', usr);
		username = unm ? unm : usr.username;
	} else {
		return { err:'No user data!'};		
	}		
	
	let qsep = '!!!';
	if( Array.isArray( keyItm))
		keyItm = keyItm.join( qsep);
	if( Array.isArray( keyVal))
		keyVal = keyVal.join( qsep);
			
	var url = '/api/storage/'+fld;
	let fld2 = `usrsData/${username}/${fld}`;
	var url = `/api/storage/docs?fld=${fld2}`; 		
	if( keyVal && keyVal !== '') {
		url += '&itm='+keyItm+'&op=eq&val='+keyVal;
	}
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		
	
	try {	

		var ver = Number( localStorage.getItem('ver').replace( 'v', '').replace(/\./g, '').replace(/\D/g,''));	
		await initIndexedDb( ver);

		var resp = await getItem( url);
		//console.log( 'getDocs QeeCliIndexedDb item found!', resp.resp);		
		
		// compare the created with now
		const fctTimeToString = ( tmst) => {
			var date = new Date( tmst);
			var hours = date.getHours();
			var minutes = "0" + date.getMinutes();
			var seconds = "0" + date.getSeconds();
			// Will display time in 10:30:23 format
			var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);		
			return formattedTime;
		}
		
		let now = moment( new Date()); 
		var diffMins = moment.duration( now.diff( moment( resp.created)) ).asMinutes();		
		console.log( 'Local IndexedDB url time', [fctTimeToString( now), fctTimeToString( resp.created), diffMins]);
		let expires = 1440-1;   // must be in sync with functions:storage.makeSignedUrl(), one minute less
		if( diffMins >= expires)	{   // the url is expired, get it from server
			await deleteItem( url);
			
			let fls = await getDocsNet( fld, keyItm, keyVal);
			ret = fls;			
			addItem( url, fls);													
			
		} else {   // get it directly from store
			ret = resp.resp;
		}
													
	} catch( e) {

		console.log( '>>> getDocs not found in storage, calling getDocsNet...');	
		try {
			var fls = await getDocsNet( fld, keyItm, keyVal);
			ret = fls;
			
			addItem( url, fls);										

		} catch( e) {
			console.error( 'Cannot get docsNet or the url cannot be added to store: ' + url + ' ERR', {e, ret});	
		}	
	}
	
	//console.log( '<-- getDocs', ret);
	return ret;			
}	
/**
*	getDocsNet() - 
*/
export const getDocsNet = async ( fld, keyItm, keyVal, unm) => {

	console.log( '--> getDocsNet', fld, keyItm, keyVal, unm);
	
	var ret;
		
	var usr = JSON.parse( sessionStorage.getItem( 'usr'));			
	if( usr) {
		console.log( 'sessionStorage usr', usr);
		var username = unm ? unm : usr.username;	// if no forced username, take the current user		 
	
		let qsep = '!!!';
		if( Array.isArray( keyItm))
			keyItm = keyItm.join( qsep);
		if( Array.isArray( keyVal))
			keyVal = keyVal.join( qsep);
			
		var url = '/api/storage/'+fld;
		let fld2 = `usrsData/${username}/${fld}`;
		var url = `/api/storage/docs?fld=${fld2}`; 
		if( keyVal && keyVal !== '') {
			url += '&itm='+keyItm+'&op=eq&val='+keyVal;
		}
		if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
			console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
			url = window.QEE_API_URL + url;	
		}
		console.log( 'Using URL', url);			
		let options = {
			url: url,
			method: 'get'
		};
		try {	
			console.log( 'getDocsNet axios options', options);
			const data = await axios( options)
			console.log( 'Docs data', data);
			ret = data;
			
			var ret2 = [];
			for( var i=0; i<ret.data.length; i++) {
				ret2.push({
					name: ret.data[i].name.replace( fld2+'/', ''),
					signedUrl: ret.data[i].signedUrl,
					metadata: ret.data[i].metadata   // contains all the rest
				});
			}
			
			console.log( '<-- getDocsNet', ret2);
			return ret2;		
				
		} catch( error) {
			console.log( '<-- getDocsNet ERR', error);
			return { err:error};		
		}				
	} else {
		return { err:'No user data!'};		
	}
}


/**
*	postStorageDocs() - 
*/
export const postStorageDocs = async ( formData, fld) => {

	console.log( '--> postStorageDocs', fld);
	for (var [key, value] of formData.entries()) { 
		console.log( 'Using FormData ' + key, value);
	}

	var ret;
	
	var usr = JSON.parse( sessionStorage.getItem( 'usr'));			
	if( usr) {
		console.log( 'sessionStorage usr', usr);
		var username = usr.username;				
		let fld2 = `usrsData/${username}/${fld}`;
		let url = `/api/storage/docs?fld=${fld2}`;
		if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
			console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
			url = window.QEE_API_URL + url;	
		}
		console.log( 'Using URL', url);			
		try {
			await axios.post( url, formData, {
			}).then( async res => {
				console.log( 'postStorageDocs res.data', res.data);

				// IMPORTANT: delete the local storage regarding this fld
				await deleteItems( url);
				
				ret = res.data;
			})			
			console.log( '<-- postStorageDocs', ret);			
			return ret;
				
		} catch( error) {
			console.log( '<-- postStorageDocs ERR', error);
			return { err:error};		
		}				
	} else {
		return { err:'No user data!'};		
	}
}


export const getObj = async (objForm, id) => {
    console.log('--> getObj');

    const authToken = localStorage.getItem('AuthToken');

    let options = {
        url: `/api/${objForm}/${id}`,
        method: 'get'
    };

    if (window.QEE_API_URL) { // for use from external apps like qeewidgets
        console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
        options.url = window.QEE_API_URL + options.url;
    }

    console.log('Using URL', options.url);
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    try {
        const response = await axios(options);
        console.log('<-- getObj', response.data);
        return response.data;
    } catch (error) {
        console.log('<-- getObj ERR', error);
        return { err: error };
    }
};


/**
*	facSave() - saves the changes to the fac
*/
export const facSave = async ( fac, chg) => {
	
	console.log( '--> facSave');
	
	const authToken = localStorage.getItem( 'AuthToken');
	
	const newFac = {
		...fac,
		...chg
	};
	console.log( 'submit fac', newFac);		
	//setObjEdit( newFac);		
			
	let options = {};
	let id = fac.id;
	options = {
		url: `/api/fac/${id}`,
		method: 'put',
		data: newFac
	};
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		options.url = window.QEE_API_URL + options.url;	
	}
	console.log( 'Using URL', options.url);				
	axios.defaults.headers.common = { Authorization: `${authToken}` };	
	try {
		var data = await axios(options);
		console.log( '<-- facSave', data.data);
		return newFac;						
	} catch( error) {
		//setObjErrors( error.response.data);
		console.log( '<-- facSave ERR', error);
		return {err:error};						
	}			
}		


/**
*	getTaxonomyTpl() - 
*/
export const getTaxonomyTpl = async () => {

	console.log( '--> getTaxonomyTpl');
	
	var ret;
	
	const authToken = localStorage.getItem( 'AuthToken');
	if( authToken == null) 
		return {err:401};	
	
	// get the taxo synapcus unids from sdatas
	var arrRet = await getSdatas( 'sdata_taxonomies_types', 'type', '100');		
	if( !arrRet.length) 
		return {err:404};	
	
	// get the json tpl from synapcus
	var url = '/api/synapcus/taxonomyTpl?unid=' + arrRet[0].synapcus_tpl_unid;    //'C18EFA2E8E428451C125891D005699F4';
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		
	let opts = {
		url: url,
		method: 'get'
	};
	console.log( 'axios', opts);
	axios.defaults.headers.common = { Authorization: `${authToken}` };
	try {
		const jsonTpl = await axios( opts);
		console.log( 'jsonTpl', jsonTpl.data);

		var dta = jsonTpl.data;
		
		if( dta.obj.length) {
			var cleanedBdy = dta.obj[0].tpl_body.replace(/[\r\n\t]/g, '');   // aen-20220530
			var bdy = JSON.parse( cleanedBdy);  
			console.log( 'tpl_body', bdy);
			ret = bdy.steps.data;
		} else {
			ret = [{label:"No data!"}];
		}
			
	} catch( error) {
		console.log( '<-- getTaxonomyTpl ERR', error);
		return { err:error};		
	} finally {
		console.log( '<-- getTaxonomyTpl', ret);
		return ret;
	};			
}


/**
*	convertToValLab() - convert db-data (type, name) into combobox-data (value, label)
*/
export const convertToValLab = ( arr) => {
	
	var arrRet = [];
	arr.map((d, i)=>{
		var o = {'value':d.type, 'label':d.name, 'info':d.stat_info, 'desc':d.stat_desc};
		arrRet.push( o);
	})
	
	arrRet.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))	
	
	return arrRet;
}


/**
*	getAvailableHeatingsTypes() - 
*/
export const getAvailableHeatingsTypes = async (energyclass, retType) => {

	console.log( '--> getAvailableHeatingsTypes', {energyclass, retType});
	
	var ret;
	
	const authToken = localStorage.getItem( 'AuthToken');
	if( authToken == null) 
		return {err:401};	
	
	// correction
	if( energyclass === 'singles')
		energyclass = 'kfw100';	
		
	var url = `/api/availableHeatingsTypes?energyclass=` + energyclass;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		
	let opts = {
		url: url,
		method: 'get'
	};
	console.log( 'axios', opts);
	axios.defaults.headers.common = { Authorization: `${authToken}` };
	try {
		const sdatas = await axios( opts);
		console.log( 'sdatas', sdatas);
		ret = sdatas.data.entries.results;
		if( retType==='vallab')
			ret = convertToValLab( ret);
			
	} catch( error) {
		console.log( '<-- getAvailableHeatingsTypes ERR', error);
		return { err:error.response.data};		
	} finally {
		console.log( '<-- getAvailableHeatingsTypes', ret);
		return ret;
	};			
}


/**
*	postSjo() - creates a sjo and starts it
*/
export const postSjo = async (data) => {
	console.log('--> postSjo', data);
	for( var [key, value] of data.fileData.entries()) {
		console.log('Using FileData ' + key, value);
	}

	var ret;
	var usr = JSON.parse( sessionStorage.getItem( 'usr'));
	if( usr) {
		console.log('sessionStorage usr', usr);
		var username = usr.username;
		let url = `/api/sjo`;
		if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
			console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
			url = window.QEE_API_URL + url;	
		}
		console.log( 'Using URL', url);			

		var formData = new FormData();

		// serialize the JSON data and append it as a field in the FormData
		formData.append( 'jsonData', JSON.stringify(data.jsonData));
		// append files from the fileData FormData
		for( var [key, value] of data.fileData.entries()) {
			formData.append(key, value);
		}

		try {
			await axios.post(url, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				params: {
					username: username,
				},
			}).then(async (res) => {
				console.log('postSjo res.data', res.data);
				ret = res.data;
			});
			console.log('<-- postSjo', ret);
			return ret;
		} catch (error) {
			console.log('<-- postSjo ERR', error);
			return { err: error };
		}
	} else {
		return { err: 'No user data!' };
	}
};


/**
*	getOpenaiDesc() - generates an openai description
*/
export const getOpenaiDesc = async (facId, pmpt) => {
	console.log('--> getOpenaiDesc: ', {facId, pmpt});

	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `${authToken}` };
	var url = '/api/fac/openaiDesc/' + facId;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		

	try {
		const response = await axios.post(url, { "prompt": (pmpt ? pmpt : "") });
		console.log('response', response);

		if( response.data) {
			let vl = response.data.fac_desc_openai.replace('\n', '');
			console.log('<-- getOpenaiDesc', vl);
			return vl;
		}
	} catch (err) {
		console.error('<-- getOpenaiDesc ERR', err);
	}
}


/**
 * getFacChat() -
 */
export const getFacChat = async (facId, chatId) => {
	console.log('--> getFacChat', { facId, chatId });

	var ret = '';

	var usrId;
	var usr = JSON.parse(sessionStorage.getItem('usr'));
	if (usr) {
		console.log('sessionStorage usr', usr);
		usrId = usr.usrId;
	} else {
		return { err: 'No user data!' };
	}

	try {
		if (chatId) {
			// If chatId is provided, retrieve chat using the specified chatId
			let url = `/api/chat/` + chatId;
			if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
				console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
				url = window.QEE_API_URL + url;	
			}
			console.log( 'Using URL', url);				
			const chatOpts = {
				url: url,
				method: 'get',
			};
			const chatResponse = await axios(chatOpts);
			ret = chatResponse.data;
		} else {
			// If chatId is not provided, start a new chat
			var url = `/api/fac/openaiStartChat/` + usrId + '/' + facId;
			if (window.QEE_API_URL) {
				console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
				url = window.QEE_API_URL + url;
			}
			console.log('Using URL', url);
			const opts = {
				url: url,
				method: 'post',
				data: '',
			};
			console.log('axios', opts);
			const chatResponse = await axios(opts);
			ret = chatResponse.data;
		}
		console.log('>>> chat', ret);
		return ret;
	} catch (error) {
		console.error('<-- getFacChat ERR', error);
		return { err: error.response.data };
	} finally {
		console.log('<-- getFacChat', ret);
		return ret;
	}
};


/**
*	postOpenaiMessage() - generates an openai description
*/
export const postOpenaiMessage = async (chatId, msg) => {
	console.log('--> postOpenaiMessage: ', {chatId, msg});

	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `${authToken}` };
	var url = '/api/fac/openaiMessageChat/' + chatId;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		

	try {
		const response = await axios.post( url, { "prompt": (msg ? msg : "") });
		console.log('response', response);

		if( response.data) {
			let vl = response.data.replace('\n', '');
			console.log('<-- postOpenaiMessage', vl);
			return vl;
		}
	} catch (err) {
		console.error('<-- postOpenaiMessage ERR', err);
	}
}


/**
 * getAnonymousChat() -
 */
export const getAnonymousChat = async ( sessId, chatId, chatCfg) => {
	console.log('--> getAnonymousChat', { sessId, chatId, chatCfg });

	var ret = '';

	var usrId;
	var usr = JSON.parse(sessionStorage.getItem('usr'));
	if (usr) {
		console.log('sessionStorage usr', usr);
		usrId = usr.usrId;
	} else {
		return { err: 'No user data!' };
	}

	try {
		if (chatId) {
			// If chatId is provided, retrieve chat using the specified chatId
			let url = `/api/chat/anonymous/get/` + chatId;
			if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
				console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
				url = window.QEE_API_URL + url;	
			}
			console.log( 'Using URL', url);				
			const chatOpts = {
				url: url,
				method: 'get',
			};
			console.log('Using chat URL', url);
			const chatResponse = await axios(chatOpts);
			ret = chatResponse.data;
		} else {
			// detect and set the browser language
			const browserLang = navigator.language || navigator.userLanguage;
			chatCfg.chat_language = browserLang;			
			
			// If chatId is not provided, start a new chat
			var url = `/api/chat/anonymous/start/openaiStartChat/` + usrId + '/' + sessId;
			if (window.QEE_API_URL) {
				console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
				url = window.QEE_API_URL + url;
			}
			console.log('Using URL', url);
			console.log('chatCfg', chatCfg);			
			const opts = {
				url: url,
				method: 'post',
				data: chatCfg,
			};
			console.log('axios', opts);
			const chatResponse = await axios(opts);
			ret = chatResponse.data;
		}
		console.log('>>> chat', ret);
		return ret;
	} catch (error) {
		console.error('<-- getAnonymousChat ERR', error);
		return { err: error.response.data };
	} finally {
		console.log('<-- getAnonymousChat', ret);
		return ret;
	}
};


/**
 * getChatBySessId() -
 */
export const getChatBySessId = async ( sessId, chatCfg) => {
	console.log('--> getChatBySessId', { sessId, chatCfg });

	var ret = '';

	try {
		
		var usrId;
		var usr = JSON.parse(sessionStorage.getItem('usr'));
		if (usr) {
			console.log('sessionStorage usr', usr);
			usrId = usr.usrId;
		} else {
			return { err: 'No user data!' };
		}
		
		let url = `/api/chat/bySessionId/` + sessId + '/' + usrId;
		if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
			console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
			url = window.QEE_API_URL + url;	
		}
		console.log( 'Using URL', url);				
		const chatOpts = {
			url: url,
			method: 'get',
		};
		console.log('Using chat URL', url);
		const chatResponse = await axios(chatOpts);
		ret = chatResponse.data;
		
		console.log('<-- getChatBySessId', ret);
		return ret;
	} catch (error) {
		console.error('<-- getChatBySessId ERR', error);
		return { err: error.response.data };
	} 
};


/**
*	postOpenaiAnonymousMessage() - generates an openai description
*/
export const postOpenaiAnonymousMessage = async (chatId, msg) => {
	console.log('--> postOpenaiAnonymousMessage: ', {chatId, msg});

	var url = '/api/chat/anonymous/message/openaiMessageChat/' + chatId;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		

	try {
		const response = await axios.post( url, { "msg":msg });
		console.log('response', response);

		if( response.data) {
			let vl = response.data;   //.replace('\n', '');
			console.log('<-- postOpenaiAnonymousMessage', vl);
			return vl;
		}
	} catch (err) {
		console.error('<-- postOpenaiAnonymousMessage ERR', err);
	}
}


/**
*	postChatMessage() - posts a fieldname/value pair as a chat partial response
*/
export const postChatMessage = async (chatId, msg, usrId) => {
    console.log('--> postChatMessage: ', {chatId, msg, usrId});

    var url = '/api/chat/anonymous/message/messageChat/' + chatId;
    if (usrId) {
        url += '/' + usrId;
    }
    
    if (window.QEE_API_URL) {  // for use from external apps like qeewidgets
        console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
        url = window.QEE_API_URL + url;    
    }
    console.log('Using URL', url);        

    try {
        const response = await axios.post(url, { "msg": msg });
        console.log('response', response);

        if (response.data) {
            let vl = response.data;   // .content.replace('\n', '');
            console.log('<-- postChatMessage', vl);
            return vl;
        }
    } catch (err) {
        console.error('<-- postChatMessage ERR', err);
    }
}



/**
*	postChatFieldValue() - posts a fieldname/value pair as a chat partial response
*/
export const postChatFieldValue = async (chatId, fld, vl) => {
	console.log('--> postChatFieldValue: ', {chatId, fld, vl});

	var url = '/api/chat/anonymous/message/fieldValue/' + chatId;
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		

	try {
		const response = await axios.post( url, {"resp": { "field":fld, "value":vl }});
		console.log('response', response);

		if( response.data) {
			let vl = response.data.replace('\n', '');
			console.log('<-- postChatFieldValue', vl);
			return vl;
		}
	} catch (err) {
		console.error('<-- postChatFieldValue ERR', err);
	}
}


/**
 * getChatConfig() - Fetches chat configuration, optionally translated.
 */
export const getChatConfig = async ( name, translateTo = null) => {
    console.log('--> getChatConfig', { name, translateTo });

    // Use browser language as default if translateTo is not provided
    const languageToUse = translateTo || navigator.language || navigator.userLanguage;
    console.log('Language to use for translation:', languageToUse);

    try {
        let url = `/api/chat/anonymous/getCfg/${name}?translateTo=${languageToUse}`;
        if (window.QEE_API_URL) {  // Adjust for use from external apps like qeewidgets
            console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
            url = window.QEE_API_URL + url;    
        }
        console.log('Fetching chat configuration from URL:', url);

        const response = await axios.get(url);
        const chatCfg = response.data;
        console.log('Fetched chat configuration:', chatCfg);
        
        return chatCfg;
    } catch (error) {
        console.error('<-- getChatConfig ERR', error);
        if (error.response && error.response.data) {
            return { err: error.response.data };
        } else {
            return { err: 'An unknown error occurred' };
        }
    }
};


/**
 * facNewbuildPlanCalculate() - Fetches chat configuration, optionally translated.
 */
export const facNewbuildPlanCalculate = async (fac) => {
    console.log('--> facNewbuildPlanCalculate', fac);

	const id = fac.id;
    const newFac = {...fac};
    if (newFac.fac_type === '200') {
        newFac.fac_energy.energyclass_plan = 'singles';
        newFac.fac_energy.energyclass_method = 'singles';
    }
	for (const key in newFac) {
		if (key.endsWith("_text")) {
			delete newFac[key];
		}
	}	

    const options = {
        url: `/api/fac/newbuild/plan/${id}`,
        method: 'put',
        data: newFac
    };

    try {
        const response = await axios(options);
        console.log('<-- facNewbuildPlanCalculate', response.data);
        return { data: response.data };
    } catch (error) {
        console.error('<-- facNewbuildPlanCalculate ERR', error);
        if (error.response && error.response.data) {
            return { err: error.response.data };
        } else {
            return { err: 'An unknown error occurred' };
        }
    }
};


/**
*	getApplicationScopeNet() - 
*/
export const getApplicationScopeNet = async () => {

	console.log( '--> getApplicationScopeNet');
	
	var ret;
	
	let options = {
		url: `/api/applicationScope`,
		method: 'get'
	};
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		options.url = window.QEE_API_URL + options.url;	
	}
	console.log( 'Using URL', options.url);		
	try {
		const data = await axios( options)
		console.log( 'applicationScope', data.data);
		ret = data.data;
			
	} catch( error) {
		console.log( '<-- getApplicationScopeNet ERR', error);
		return { err:error.response.data};		
	} finally {
		console.log( '<-- getApplicationScopeNet', ret);
		return ret;
	};				
}


export const fetchOrgs = async (role, lat, lon, radius) => {
	
	console.log( '--> fetchOrgs', role, lat, lon, radius);
	
	// URL des Endpoints
	let url = '/api/org/orgs';
	if (window.QEE_API_URL) {  // für die Verwendung von externen Apps wie qeewidgets
		console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;
	}
	console.log('Using URL', url);

	// Optionen für die Anfrage
	let opts = {
		url: url,
		method: 'get',
		params: {
			classification: 'supplier',
			businessState: 'potential,active',
			roles: role,
			lat: lat,
			lon: lon,
			radius: radius
		}
	};

	console.log('axios', opts);

	// Variable für das Ergebnis
	let ret;

	try {
		// Anfrage mit Axios senden
		const response = await axios(opts);
		console.log('response', response);

		// Daten aus der Antwort extrahieren
		ret = response.data;

	} catch (error) {
		console.log('<-- fetchOrgs ERR', error);
		return { err: error.response.data };
	} finally {
		console.log('<-- fetchOrgs', ret);
		return ret;
	}
};


export const orgSave = async (org) => {
    console.log('--> orgSave', org);

    // Ensure the organization has an ID
    if (!org || !org.id) {
        throw new Error('Organization ID is missing');
    }

    // URL of the endpoint
    let url = `/api/org/${org.id}`;
    if (window.QEE_API_URL) { // For usage in external apps like qeewidgets
        console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
        url = window.QEE_API_URL + url;
    }
    console.log('Using URL', url);

    // Options for the request
    let opts = {
        url: url,
        method: 'put',
        data: org
    };
    console.log('axios', opts);
    let ret;
    try {
        const response = await axios(opts);
        console.log('response', response);
        ret = response.data

    } catch (error) {
        console.log('<-- orgSave ERR', error);
        return { err: error.response.data };
    } finally {
        console.log('<-- orgSave', ret);
        return ret;
    }
};


export const getUsrChats = async (filter) => {
    console.log('--> getUsrChats', { filter });

    try {
        var usrId;
        var usr = JSON.parse(sessionStorage.getItem('usr'));
        if (usr) {
            console.log('sessionStorage usr', usr);
            usrId = usr.usrId;
        } else {
            return { err: 'No user data!' };
        }        
        
        let url = `/api/usr/chats/${usrId}`;
        if (window.QEE_API_URL) {  // for use from external apps like qeewidgets
            console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
            url = window.QEE_API_URL + url;
        }

        // Include the filter in the URL as a query parameter
        const filterParam = encodeURIComponent(JSON.stringify(filter));
        url += `?filter=${filterParam}`;

        console.log('Using URL', url);
        const chatOpts = {
            url: url,
            method: 'get',
        };
        const chatResponse = await axios(chatOpts);
        console.log('<-- getUsrChats', chatResponse.data);
        return chatResponse.data;
        
    } catch (error) {
        console.error('<-- getUsrChats ERR', error);
        return { err: error.response.data };
    }
};


export const getUsrAsReceiverChats = async (filter) => {
    console.log('--> getUsrAsReceiverChats', { filter });

    try {
        var usrId;
        var usr = JSON.parse(sessionStorage.getItem('usr'));
        if (usr) {
            console.log('sessionStorage usr', usr);
            usrId = usr.usrId;
        } else {
            return { err: 'No user data!' };
        }        
        
        let url = `/api/usr/chats/asReceiver/${usrId}`;
        if (window.QEE_API_URL) {
            console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
            url = window.QEE_API_URL + url;
        }
		
		// remove the chat_usr_id element from filter
		delete filter.chat_usr_id;

        // Include the filter in the URL as a query parameter
        const filterParam = encodeURIComponent(JSON.stringify(filter));
        url += `?filter=${filterParam}`;

        console.log('Using URL', url);
        const chatOpts = {
            url: url,
            method: 'get',
        };
        const chatResponse = await axios(chatOpts);
        console.log('<-- getUsrAsReceiverChats', chatResponse.data);
        return chatResponse.data;
        
    } catch (error) {
        console.error('<-- getUsrAsReceiverChats ERR', error);
        return { err: error.response.data };
    }
};

/**
 * getUnreadChatMsgsCount - Fetches the count of unread chat messages for the current user.
 */
export const getUnreadChatMsgsCount = async () => {
    console.log('--> getUnreadChatMsgsCount');

    try {
        var usrId;
        var usr = JSON.parse(sessionStorage.getItem('usr'));
        if (usr) {
            console.log('sessionStorage usr', usr);
            usrId = usr.usrId;
        } else {
            return { err: 'No user data!' };
        }

        let url = `/api/usr/chats/unreadMsgsCount/${usrId}`;
        if (window.QEE_API_URL) {  // for use from external apps like qeewidgets
            console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
            url = window.QEE_API_URL + url;
        }

        console.log('Using URL', url);
        const chatOpts = {
            url: url,
            method: 'get',
        };
        const chatResponse = await axios(chatOpts);
        console.log('<-- getUnreadChatMsgsCount', chatResponse.data);
        return chatResponse.data;

    } catch (error) {
        console.error('<-- getUnreadChatMsgsCount ERR', error);
        return { err: error.response.data };
    }
};


export const markChatRead = async (usrId, chatId) => {
	
	console.log('--> markChatRead', usrId, chatId);
	
    try {
		
		if( !chatId)
			return { err: 'No chat found!' };
		
        const url = `/api/chat/${usrId}/${chatId}/markRead`;
        const payload = {};

        const response = await axios.post(url, payload);
        console.log('<-- markChatRead', response.data);
        return response.data;
    } catch (error) {
        console.error('<-- markChatRead ERR', error);
        return { err: error.response?.data || error.message };
    }
};


export const fetchSacs = async (filter) => {
    console.log('--> fetchSacs', filter);

    // URL of the endpoint
    let url = '/api/sac/sacs';
    if (window.QEE_API_URL) {  // For usage in external apps like qeewidgets
        console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
        url = window.QEE_API_URL + url;
    }
    console.log('Using URL', url);

    // Options for the request
    let opts = {
        url: url,
        method: 'get',
        params: filter
    };

    console.log('axios', opts);

    // Variable for the result
    let ret;

    try {
        // Send request with Axios
        const response = await axios(opts);
        console.log('response', response);

        // Extract data from the response
        ret = response.data;

    } catch (error) {
        console.log('<-- fetchSacs ERR', error);
        return { err: error.response.data };
    } finally {
        console.log('<-- fetchSacs', ret);
        return ret;
    }
};


export const sacSave = async (sac) => {
    console.log('--> sacSave', sac);

    // Ensure the SAC has an ID
    if (!sac || !sac.id) {
        throw new Error( '<-- sacSave ERR: sac.id is missing!');
    }

    // URL of the endpoint
    let url = `/api/sac/${sac.id}`;
    if (window.QEE_API_URL) { // For usage in external apps like qeewidgets
        console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
        url = window.QEE_API_URL + url;
    }
    console.log('Using URL', url);

    // Options for the request
    let opts = {
        url: url,
        method: 'put',
        data: sac
    };
    console.log('axios', opts);

    let ret;
    try {
        const response = await axios(opts);
        console.log('response', response);
        ret = response.data;

    } catch (error) {
        console.log('<-- sacSave ERR', error);
        return { err: error.response.data };
    } finally {
        console.log('<-- sacSave', ret);
        return ret;
    }
};


export const sacDelete = async (id) => {
    console.log('--> sacDelete', id);

    // Ensure the SAC has an ID
    if (!id) {
        throw new Error('SAC ID is missing');
    }

    // URL of the endpoint
    let url = `/api/sac/${id}`;
    if (window.QEE_API_URL) { // For usage in external apps like qeewidgets
        console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
        url = window.QEE_API_URL + url;
    }
    console.log('Using URL', url);

    // Options for the request
    let opts = {
        url: url,
        method: 'delete'
    };
    console.log('axios', opts);

    let ret;
    try {
        const response = await axios(opts);
        console.log('response', response);
        ret = response.data;

    } catch (error) {
        console.log('<-- sacDelete ERR', error);
        return { err: error.response.data };
    } finally {
        console.log('<-- sacDelete', ret);
        return ret;
    }
};


// Function to fetch comments with filtering
export const fetchCmts = async (filter) => {
    console.log('--> fetchCmts', filter);

    // URL of the endpoint
    let url = '/api/cmt/cmts';
    if (window.QEE_API_URL) {  // For usage in external apps like qeewidgets
        console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
        url = window.QEE_API_URL + url;
    }
    console.log('Using URL', url);
    let opts = {
        url: url,
        method: 'get',
        params: filter
    };
    console.log('axios', opts);

    let ret;
    try {
        const response = await axios(opts);
        console.log('response', response);
        ret = response.data;
		console.log('<-- fetchCmts', ret);
        return ret;		

    } catch (error) {
        console.log('<-- fetchCmts ERR', error);
        return { err: error.response.data };
    } 
};

// Function to create a new comment
export const cmtCreate = async (cmt, parentid = null) => {
    console.log('--> cmtCreate', cmt, parentid);

    // URL of the endpoint
    let url = '/api/cmt/cmt';
    if (parentid) {
        url += `/${parentid}`;
    }
    if (window.QEE_API_URL) {  // For usage in external apps like qeewidgets
        console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
        url = window.QEE_API_URL + url;
    }
    console.log('Using URL', url);

    let opts = {
        url: url,
        method: 'post',
        data: cmt
    };
    console.log('axios', opts);

    let ret;
    try {
        const response = await axios(opts);
        console.log('response', response);
        ret = response.data;
		console.log('<-- cmtCreate', ret);
        return ret;		

    } catch (error) {
        console.log('<-- cmtCreate ERR', error);
        return { err: error.response.data };
    } 
};

// Function to delete a comment
export const cmtDelete = async (id) => {
    console.log('--> cmtDelete', id);

    // Ensure the comment has an ID
    if (!id) {
        throw new Error('Comment ID is missing');
    }

    // URL of the endpoint
    let url = `/api/cmt/${id}`;
    if (window.QEE_API_URL) {  // For usage in external apps like qeewidgets
        console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
        url = window.QEE_API_URL + url;
    }
    console.log('Using URL', url);

    let opts = {
        url: url,
        method: 'delete'
    };
    console.log('axios', opts);

    let ret;
    try {
        const response = await axios(opts);
        console.log('response', response);
        ret = response.data;
        console.log('<-- cmtDelete', ret);
        return ret;		

    } catch (error) {
        console.log('<-- cmtDelete ERR', error);
        return { err: error.response.data };
    } 
};
