import { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ButtonBase from '@material-ui/core/ButtonBase';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useAuth } from "../context/provider";
import { useSnackbar } from 'notistack';
import imageMissing from '../assets/image-missing.jpg';
import { getDocs, getDocsNet, postStorageDocs } from "../api/service";

import { QeeColorButtonPrimary, QeeColorButtonSecondary } from './qeeColorButton';
import QeeTooltip from './qeeTooltip';
import { usePicturesGallery } from "../components/qeePicturesGalleryProvider"; 

import css from '../core/App.css';

const useStyles = makeStyles(theme => ({
	label: {
		// Aligns the content of the button vertically.
		flexDirection: 'column'
	},	
	container: {
		width: props => props.width || 150, 
		height: props => props.height || 150,		
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			width: '100%'
		},
	},
	img: {
		width: props => props.width || 150,
		height: props => props.height || 150,
		objectFit: 'cover',
	},
	icoText: {
		color: theme.palette.primary.main,
		fontSize: 14
	},
	card: {
		display: 'flex', 
		width: props => props.width || 150, 
		height: props => props.height || 150,
		textAlign: 'center',
		position: 'relative'
	},		
	cardContent: {
		width: '100%',
		height: '100%',
		display: 'flex', 
		flexDirection: 'column',
		flex: 1,
		padding: '0px !important',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.main,
	},
	cardAction: {
		position: "relative"
	},
	media: {
		width: props => props.width || 150, 
		height: props => props.height || 150,
	},
	overlay: {
		position: "absolute",
		top: 0,
		width: '100%', 
		height: '100%',
		textAlign: 'center',
		padding: 0,
		opacity: 0.7,		
	},
	cardTitle: {
		whiteSpace: 'nowrap', 
		overflow: 'hidden', 
		textOverflow: 'ellipsis', 
		fontWeight: 500,
		width: '100%', 
		height: 20, 
		[theme.breakpoints.down('xs')]: {
			width: '100%', 
		},
	},
	uploadButton: {
		width: '100%', 
		height: '100%', 
		textAlign: 'center',
		flexDirection: 'column',
		position: 'absolute',
		top: 0,
		left: 0
	},
	listItem: {
		padding: 0,
	}
}));

const QeeSingleImageInput = (props) => {
	const { setOpenPicturesGalleryDialog } = usePicturesGallery();															
	const { enqueueSnackbar } = useSnackbar();	
	const { gstate } = useAuth();					
	const classes = useStyles(props);				
	const [isLoading, setIsLoading] = useState(false);
	const [selectedImage, setSelectedImage] = useState([]);
	const [imageUrl, setImageUrl] = useState([]);
	const [docs, setDocs] = useState([]);
	const [urls, setUrls] = useState([]);
	const [showOverlay, setShowOverlay] = useState(false);	
	
	const submitFiles = async () => {
		console.log('--> submitFiles');
		setIsLoading(true);
			
		const makeFileName = (nm) => {
			if (nm.indexOf('.') > 0) {
				let fn = nm.split('.')[0];
				let ext = nm.split('.')[1];
				return props.username + '_picture.' + ext;
			}
		};	
			
		var formData = new FormData();
		console.log('selectedImage', selectedImage);
		for (let i = 0; i < selectedImage.length; i++) {
			console.log('Set in FormData file:' + i, selectedImage[i]);
			var nm = makeFileName(selectedImage[i].name);
			if (nm) {
				selectedImage[i].filename = nm;
				var metadata = {};
				metadata.sdoc_name = nm;
				metadata.sdoc_type = props.sdoc_type;
				metadata.sdoc_subtype = props.sdoc_subtype;
				selectedImage[i].metadata = JSON.stringify(metadata);					
				formData.append(selectedImage[i].metadata, selectedImage[i]);
			}
		}
		
		var ret = await postStorageDocs(formData, props.fldId);
		setIsLoading(false);
		if (ret.error) {
			enqueueSnackbar('Der Upload konnte nicht ausgeführt werden! ' + ret.error, {variant: 'error'});								
			console.log('<-- submitFiles ERR', ret.error);
			return false;
		} else {	
			if (props.onSuccess) {
				if (docs.length)
					props.onSuccess(docs[0].signedUrl);
			}
			setShowOverlay(false);
			enqueueSnackbar('Der Upload wurde erfolgreich ausgeführt.', {variant: 'success'});		
			console.log('<-- submitFiles');
			return true;
		}
	};		
	
	const handleSetSelectedImage = (ev) => {
		console.log('handleSetSelectedImage', ev);		
		var files = ev.target.files;
		console.log('files', files);		
		var files2 = Array.prototype.slice.call(files);
		var si = selectedImage;
		si = si.concat(files2);		
		console.log('si', si);
		setSelectedImage(si);
				
		var iu = imageUrl;
		for (let i = 0; i < si.length; i++) {
			iu[i] = URL.createObjectURL(si[i]);
		}
		console.log('iu', iu);
		setImageUrl(iu);				
	};	
	
	useEffect(() => {  	
		var uploadField = document.getElementById("input-image");
		if (uploadField) {
			uploadField.addEventListener("change", function () {  
				if (this.files[0].size > 20000) {
					alert("Dateigröße überschreitet Limit!");
					this.value = "";
				};
			});
		}
	}, []);				
	
	useEffect(() => {  
		const getDcs = async () => {			
			if (selectedImage.length && selectedImage[0].name) 
				await submitFiles();			
			const dcs = await getDocsNet(props.fldId, props.keyItm, props.keyVal);	
			if (!dcs.err)	
				setDocs(dcs);
			
			var us = [];
			for (let i = 0; i < dcs.length; i++) {
				var elem = {
					url: dcs[i].signedUrl,
					caption: dcs[i].name
				};
				us.push(elem);
			}
			setUrls(us);
		};	
		getDcs();	
	}, [selectedImage.length, selectedImage.length > 0 ? selectedImage[0].name : '']);			
		
	const FileCard = (props) => {
		const isImage = (name) => {
			if (name.indexOf('.jpg') > 0 || 
				name.indexOf('.jpeg') > 0 || 
				name.indexOf('.png') > 0 || 
				name.indexOf('.gif') > 0 || 	
				name.indexOf('.bmp') > 0 || 
				name.indexOf('.ico') > 0 || 	
				name.indexOf('.webp') > 0 || 								
				name.indexOf('.tif') > 0 || 								
				name.indexOf('.svg') > 0)
				return true;	
		};
				
		const handleMouseOver = () => {
			setTimeout(async () => {			
				setShowOverlay(true);
			}, 100);	
		};
		const handleMouseOut = () => {
			setTimeout(async () => {			
				setShowOverlay(false);
			}, 100);	
		};		
		
		return (
			<Card className={classes.card}>	
				<CardActionArea
					onMouseOver={handleMouseOver}
					onMouseOut={handleMouseOut} 
					className={classes.card} >
					<CardMedia className={classes.media}>
						{(() => {
							let nm = props.doc.name.toLowerCase();													
							if (isImage(nm)) {
								return <img className={classes.img} src={props.doc.signedUrl} alt={props.doc.name} />							
							} else {
								return <AccountCircleIcon fontSize="large" color="green" style={{fontSize:72, color:'green'}}/>
							}
						})()}
					</CardMedia>
					<CardContent className={classes.overlay} style={{ display: showOverlay ? 'block' : 'none', padding:0 }}>
						<input
							accept="image/jpeg"
							type="file"
							single
							id={"input-image"}
							style={{ display: 'none' }}
							onChange={ev => handleSetSelectedImage(ev)}
						/>
						<label htmlFor={'input-image'} style={{padding:0}}>
							{isLoading ? 
								<QeeColorButtonSecondary className={classes.uploadButton} 
									style={{width:props.buttonWidth ? props.buttonWidth : '', height:props.buttonHeight ? props.buttonHeight : ''}}>
									<CircularProgress />
								</QeeColorButtonSecondary>
							:	
								<QeeColorButtonSecondary component="span" className={classes.uploadButton}
									style={{width:props.buttonWidth ? props.buttonWidth : '', height:props.buttonHeight ? props.buttonHeight : ''}}>
									<div><PhotoCameraIcon fontSize={props.iconFontSize ? props.iconFontSize : "large"} /></div>
									<div><b>Hochladen</b></div>
								</QeeColorButtonSecondary>		
							}
						</label>				
					</CardContent>
				</CardActionArea>					
			</Card>			
		);		
	};
		
	const FilesList = (props) => {
		const getWidth = () => {
			var w = 160;			
			return w;
		};
		
		return (
			<>
				<Box sx={{ width:getWidth(), bgcolor: 'background.paper' }}>
					<List component={Stack} direction="row" style={{overflow:'auto'}}>
						{docs.length ?
							<ListItem disablePadding className={classes.listItem}>				
								<FileCard doc={docs[0]} urls={urls} idx={0} />				
							</ListItem>			
						:
							<ListItem disablePadding className={classes.listItem}>				
								<FileCard doc={{name:'undefined', empty:true}} />				
							</ListItem>							
						}
					</List>
				</Box>
			</>
		);
	};
	
	return (
		<div className={classes.container}>		
			<Box mt={2} textAlign="left">
				<FilesList buttonText={props.buttonText}/>
			</Box>				
		</div>
	);
};

export default QeeSingleImageInput;
