// diagramDonutchart.js
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatNumber } from '../util/helpers'; // Import your formatNumber function

const DiagramDonutChart = (props) => {
    const {
        title = '',
        data,
        showPercent = false // Default to false
    } = props;

    // Convert fields to chartData
    const chartData = data.map(f => ({
        name: f.label,
        y: parseFloat(f.value) // Parse value to float
    }));

    const options = {
        chart: {
            type: 'pie',
            plotBackgroundColor: null,
            plotBorderWidth: 0, // Remove border
            plotShadow: false,
			height: 700,
        },
        title: {
            text: title,
            y: 20
        },
        legend: {
            enabled: true,
            y: 0,
			layout: 'horizontal',
			verticalAlign: 'bottom',
			align: 'center',
            labelFormatter: function() {
                let label = this.name;
                if (showPercent) {
                    label += ' (' + this.percentage.toFixed(2) + '%)';
                }
                return label;
            }
        },
        tooltip: {
            formatter: function() {
                let tooltipContent = '<b>' + this.point.name + '</b>: ';
                if (showPercent) {
                    tooltipContent += this.point.percentage.toFixed(2) + '%'; // Show percentage if true
                }
                if (!showPercent || showPercent && this.point.percentage !== 100) {
                    tooltipContent += '<br>' + formatNumber(this.y, 'EUR', 0); // Show absolute value
                }
                return tooltipContent;
            }
        },
        plotOptions: {
            pie: {
				//innerSize: '50%', 
                //outerSize: '100%', 
				outerSize: 400,
                innerSize: 200,
                depth: 70,	
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                    formatter: function() {
                        if (showPercent) {
                            return '<b>' + this.point.name + '</b>: ' + this.point.percentage.toFixed(2) + '%'; // Show percentage if true
                        } else {
                            return '<b>' + this.point.name + '</b>: ' + formatNumber(this.y, 'EUR', 0); // Use formatNumber for data labels
                        }
                    }
                },
                showInLegend: true,
                colors: Highcharts.getOptions().colors // Use default colors
            }
        },
        series: [{
            name: 'Costs',
            data: chartData
        }]
    };

    return (
        <div style={{ height: '700px' }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default DiagramDonutChart;
