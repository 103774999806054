// qeeStepValidationProvider.js
import React, { createContext, useContext, useState } from 'react';

const QeeStepValidationContext = createContext(null);

export const useValidation = () => useContext(QeeStepValidationContext);

const ValidationProvider = ({ children }) => {
    const [validations, setValidations] = useState({});

    const registerValidation = (step, validateFunction) => {
		console.log( 'registerValidation', step);
        setValidations(prev => ({ ...prev, [step]: validateFunction }));
    };

    const validateStep = (step) => {
        if (validations[step]) {
            return validations[step]();
        }
        return true; // Consider a step valid if no validation function is registered
    };

    const value = {
        registerValidation,
        validateStep
    };

    return (
        <QeeStepValidationContext.Provider value={value}>
            {children}
        </QeeStepValidationContext.Provider>
    );
};

export default ValidationProvider;

