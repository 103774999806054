import React from "react";

import react, { useState, useEffect } from 'react';

import PaymentScreen from "./PaymentScreen";
import config from '../utils/config';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';


const stripePromise = loadStripe( config.stripePublishableKey);


// https://betterprogramming.pub/complex-payment-flows-using-stripe-payment-intents-a-reactjs-nodejs-guide-5835f4c004cf
function AppStripe( props) {
	
	return (	 
		<>			
			<Elements stripe={stripePromise}> 			
				<PaymentScreen />
			</Elements>
			
		</>	
	)
	

}

export default AppStripe;
