import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, Button, Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import QeeSlideDialog from './qeeSlideDialog';
import OrgForm from './orgForm';
import { QeeColorButtonPrimary, QeeColorButtonSecondary } from './qeeColorButton';
import { useAuth } from "../context/provider";

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: theme.palette.background.paper,
        position: 'fixed',
        width: '100%',
        bottom: 0,
        left: 0,
    },
    formContainer: {
        padding: 20,
        paddingTop: 70,
    },
}));

const styles = (theme) => ({});

const OrgDialog = forwardRef((props, forwardedRef) => {
    const classes = useStyles();
    const theme = useTheme();
    const { setOpenQeeDialog } = useAuth();
    const [state, setState] = useState({
        org: {},
        submitDisabled: true,
    });

    const handleOrgOpen = (org, isEditable, primaryAction, primaryActionLabel) => {
        if (!org) {
            console.log('No org specified!');
            return;
        }
        setState({
            ...state,
            org: org,
			isEditable,
            primaryAction,
			primaryActionLabel,
        });
        setOpenQeeDialog({ open: true, id: 'dlgOrg' });
    };

    const handleOrgClose = () => {
        setOpenQeeDialog(false);
    };

    useImperativeHandle(forwardedRef, () => ({
        handleOrgOpen: handleOrgOpen,
        handleOrgClose: handleOrgClose,
    }));

    const { org, isEditable, primaryAction, primaryActionLabel } = state;

    return (
        <>
            <div ref={forwardedRef}></div>
            <QeeSlideDialog
                id='dlgOrg'
                title='Propertec Partner'
                formComponent={
                    <>
                        <Box className={classes.formContainer}>
                            <OrgForm 
								org={org} 
								isEditable={isEditable} 
							/>
                        </Box>
						{primaryAction ? 						
							<div className={classes.buttonsContainer}>
								<QeeColorButtonPrimary disableElevation variant="contained" onClick={primaryAction}>
									{primaryActionLabel}
								</QeeColorButtonPrimary>

								<QeeColorButtonSecondary
									disableElevation
									variant="contained"
									style={{ color: theme.palette.primary.main }}
									onClick={handleOrgClose}
								>
									Schließen
								</QeeColorButtonSecondary>
							</div>
						:''}						
                    </>
                }
                hideDialogAppBar={false}
                hideDialogActions={true}
                submitDisabled={true}
                paddingContent={0}
            />
        </>
    );
});
export default withStyles(styles)(OrgDialog);
