import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";								
import EditIcon from "@mui/icons-material/Edit";								
import DeleteIcon from "@mui/icons-material/Delete";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Badge from '@mui/material/Badge';
import LabelEnergyClass from './labelEnergyClass';
import NumberFormat from 'react-number-format';
import {formatNumber, usrRight} from '../util/helpers';
							
import { makeStyles } from "@material-ui/core/styles";

import axios from 'axios';

import useWindowDimensions from '../util/windowDimensions';
import { useAuth } from "../context/provider";

import { getDocs, getDocsNet, getSdatas } from "../api/service";
import { checkLicense } from '../util/auth';

import QeeTooltip from './qeeTooltip';

import imageMissing from '../assets/image-missing.jpg';
import house from '../assets/villa-sample.jpg';
import apartment from '../assets/apartment-sample.jpg';


const useStyles = makeStyles(theme => ({
	container: {
		padding:100,
		textAlign: 'center',
	},
	imgGrid: {
		backgroundImage:`url(${imageMissing})`,
		width:'100%', 
		height:250,				
		
		[theme.breakpoints.up('lg')]: {
			//width:200,
		},				
		[theme.breakpoints.up('xl')]: {
			//width:300,
		},				
			
	},
	imgButton: {
		color: '#ddd',
		width: '100%',
		
		[theme.breakpoints.up('lg')]: {
			//width: 200
		},				
		[theme.breakpoints.up('xl')]: {
			//width:300 
		},						
	
	},
	img: {
		width: '100%',
		height: 250,

		[theme.breakpoints.up('lg')]: {
			//maxWidth: 200,
		},		
		[theme.breakpoints.up('xl')]: {
			//maxWidth:300,

		},

		color: theme.palette.primary.main,
		cursor:'pointer',
		objectFit: 'cover'
	},
	cardContent: {
		width: '100%',
		/*
		[theme.breakpoints.up('lg')]: {
			width: 300, 
		},
		*/	
		display: 'flex', 
		flexDirection: 'column',
		flex: 1
	},	
	cardTitle: {
		whiteSpace: 'nowrap', 
		overflow: 'hidden', 
		textOverflow: 'ellipsis', 
		fontWeight: 600,
		width:350, 
		[theme.breakpoints.down('xs')]: {
			width:320, 
		},
		[theme.breakpoints.up('sm')]: {
			width:500 
		},								
		[theme.breakpoints.up('md')]: {
			width:300 
		},												
		[theme.breakpoints.up('xl')]: {
			width:300 
		},						
	},
	cardSubtitle: {
		whiteSpace: 'nowrap', 
		overflow: 'hidden', 
		textOverflow: 'ellipsis', 
		fontWeight: 600,
		width:350, 
		[theme.breakpoints.down('xs')]: {
			width:320, 
		},		
		[theme.breakpoints.up('sm')]: {
			width:500 
		},								
		[theme.breakpoints.up('md')]: {
			width:300 
		},										
		[theme.breakpoints.up('xl')]: {
			width:300 
		},						
	},
	title: {
		color: '#444',
		fontSize: 18,
		fontWeight: 600,
		width: 400,
		maxWidth: 500
		
	},	
	label: {
		fontSize: 12,
		color: theme.palette.tertiary.dark
	},
	text: {
		color: '#444',
		fontSize: 16,
	},
	textContainer: {
		padding: 50
	},
	actionButton: {
		marginRight: 'auto',
		backgroundColor: theme.palette.primary.contrastText,
		color: theme.palette.primary.main
	}
}));


/**
*	Component for async showing the device type
*/
const TypeLabel = (props) => {
	
	console.log( 'TypeLabel props', props.fac);
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();	
	
	const classes = useStyles();
	const [tp, setTp] = useState( '...');
	useEffect(() => {
		async function getTypeLabel() {
			if( props.fac.fac_type == '100') {
				var tp = 'unbekannt';
				if( props.fac.fac_house_type) {
					var arrRet = await getSdatas( 'sdata_houses_types', 'type', props.fac.fac_house_type);
					if( arrRet && arrRet.length>0) {
						tp = arrRet[0].name;		
						tp += ' | ' + props.fac.addr_gvalue_o.label;
					}				
				} else {
					tp = props.fac.addr_gvalue_o.label;
				}
				setTp( tp);													
			}
			else if( props.fac.fac_type == '200') {
				var tp = 'unbekannt';
				if( props.fac.fac_apartment_type) {
					var arrRet = await getSdatas( 'sdata_apartments_types', 'type', props.fac.fac_apartment_type);
					if( arrRet && arrRet.length>0) {
						tp = arrRet[0].name;		
						tp += ' | ' + props.fac.addr_gvalue_o.label;
					}				
				} else {
					tp = props.fac.addr_gvalue_o.label;
				}
				setTp( tp);									
			}			
		}
		getTypeLabel();
	}, [gstate.objEdit.fac_house_type, gstate.objEdit.fac_apartment_type])

	return (
		<Typography className={classes.label} component="div">
			{tp}
		</Typography>	
	)
}


const FacCard = ( props) => {

	const { gstate } = useAuth();	

	const [picture, setPicture] = useState( house);
			
	const classes = useStyles();			

	
	useEffect(()=> {   
	
		async function _getPicture() {
			var pct = await getPicture( props.fac);
			setPicture( pct);
		}
		_getPicture();

	}, []);
	
	
	const isFileImage = async (imageUrl) => {
	
		console.log( '--> isFileImage', imageUrl);
	
		var ret = false;
		
		try {	
			const { data } = await axios.get( imageUrl, {
				responseType: "arraybuffer",
			});
			const file = Buffer.from(data);	
			console.log( '>>>>>> FILE', file);		
			ret = (file && file['type'].split('/')[0] === 'image');
		} catch( e) {
			console.error( 'isFileImage ERR', e);
		} finally {
			console.log( '<-- isFileImage', ret);				
			return ret;
		}
	}


	const getTypeText = ( fac) => {
		var txt = '';
		if( fac.fac_type+'' == '100') {
		
		} else {
			txt = 'Wohnung';
		}
	
		return txt;
	}	
	
		
	const getPicture = async ( fac) => {

		var u;
		
		// first try to get the first '100' picture files from storage
		const docs = await getDocs( fac.id, ['sdoc_type','sdoc_subtype'], [200, 100]);	
		console.log( 'FacImage getDocs', docs);			
		if( !docs.err && docs.length) {					
			u = docs[0].signedUrl;
			
		// if nothing found, try to show some internet pics	
		} else {								
				
			// has some internet pics
			if( props.fac.fac_pictures && props.fac.fac_pictures.length) {
				u = props.fac.fac_pictures[0].url;					
				
			// last choice - show the dummy pic	
			} else {
				if( props.fac.fac_type+'' == '100')
					u = house;
				else	
					u = apartment;
			}
		}						
		
		return u;	
			
	}


	const getEnergyLetter = ( fac) => {
		if( fac.fac_energy && fac.fac_energy.end_ist) {			
			return <LabelEnergyClass obj={fac} energy='end_ist' xs={true}/>
		}				
		return '';
	}

	
	const getPriceValue = ( fac) => {
		if( fac.fac_price && fac.fac_price.value) {	
			return formatNumber( fac.fac_price.value, 'EUR', 0);
		}				
		return '';
	}


	const licenseStatus = checkLicense(gstate.usr.lic, props.fac.id);
	const isNewbuild = props.fac.fac_prj_type === 'newbuild';
	const isRenovation = props.fac.fac_prj_type === 'renovation';

	const [renderedBadges, setRenderedBadges] = useState([]);
	
	const badgeColors = {
		premium: 'license',
		newbuild: 'facNewbuild',
		renovation: 'facRenovation',
		// Add more conditions as needed
	};		
	const getColor = ( type) => {
		return badgeColors[type] || 'defaultColor';
	}
	
	useEffect(() => {
		// Update the rendered badges after the component is mounted
		const badges = [];
		if (licenseStatus === 'premium') {
			badges.push({ type: 'premium', label:'PREMIUM', width: 70 }); // Width of premium badge
		}
		if (isNewbuild) {
			badges.push({ type: 'newbuild', label:'NEUBAU', width: 70 }); // Width of newbuild badge
		}
		if (isRenovation) {
			badges.push({ type: 'renovation', label:'SANIERUNG', width: 70 }); // Width of renovation badge
		}
		setRenderedBadges(badges);
	}, [licenseStatus, isNewbuild, isRenovation]);

	return (		
		<Card style={{ display: 'flex', width:'100%'}}>							
		<Grid container direction="row">									
			<Grid item md={5} lg={5} xl={5} className={classes.imgGrid}>
				{picture &&
					<ButtonBase className={classes.imgButton} style={{ position: 'relative' }}>
						<div style={{ position: 'absolute', left: 40, top: 10 }}>
							{renderedBadges.map((badge, index) => (
								<Badge
									key={index}
									badgeContent={badge.label.toUpperCase()}
									color={getColor(badge.type)}
									style={{ position: 'relative', left: index > 0 ? (index * 90) : 10 }} // 70px badge width + 10px margin
								/>
							))}
						</div>
						<img
							className={classes.img}
							src={picture}
							referrerPolicy='same-origin'
							alt="Immobilienbild"
							onClick={() => props.handleViewOpen(props.fac)}
						/>
					</ButtonBase>
				}
			</Grid>
	
			<Grid item md={7} lg={7} xl={7} item style={{ display: 'flex', flexDirection: 'column', width:'100%', maxWidth:500}}>
				<Box sx={{ display: 'flex', flexDirection: 'column', flex:1 }}>
					<CardContent className={classes.cardContent}>
						<a href='#'><div className={classes.cardTitle}
							onClick={() => props.handleViewOpen( props.fac)}>
							{props.fac.fac_name}
						</div></a>
						<div className={classes.cardSubtitle}>
							<TypeLabel fac={props.fac} />
						</div>
					
						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="center"																				
							spacing={16} 
							style={{ marginTop: 10}}>
							<Grid container
								direction="column"											
								item xs={4}>
								<Grid item xs={6} style={{fontWeight:700, fontSize: 18}}>{props.fac.fac_rooms_count}</Grid>
								<Grid item xs={6} className={classes.label}>Zimmer</Grid>
							</Grid>																					
							<Grid container
								direction="column"											
								item xs={4}>
								<Grid item xs={6} style={{fontWeight:700, fontSize: 18}}>{props.fac.fac_living_area}</Grid>
								<Grid item xs={6} className={classes.label}>Wohnfläche</Grid>
							</Grid>
							<Grid container
								direction="column"											
								item xs={4}>
								<Grid item xs={6} style={{fontWeight:700, fontSize: 18}}>{props.fac.fac_plot_area}</Grid>
								<Grid item xs={6} className={classes.label}>Grundstücksfläche</Grid>
							</Grid>
						</Grid>		  
						
						<Grid
							container
							direction="row"
							//justifyContent="center"
							//alignItems="center"																				
							//spacing={16} 
							style={{ marginTop: 10}}>						
							<Grid item xs={4}>
							{getEnergyLetter( props.fac) != '' ?  (	
								<Grid container
									direction="column"
									item xs={4}>
									<Grid item xs={3} style={{fontWeight:700, fontSize: 18}}>
										{getEnergyLetter( props.fac)}
									</Grid>
									<Grid item xs={3} className={classes.label}>
										Energieklasse
									</Grid>																		
								</Grid>							
							) : (
								<></>
							)
							}													
							</Grid>
							
							<Grid item xs={4}>
							</Grid>
							
							<Grid item xs={4}>
							{getPriceValue( props.fac) != '' ?  (

								<Grid container
									direction="column"
									item xs={4}>
									<Grid item xs={3} style={{fontWeight:700, fontSize: 18}}>
										{getPriceValue( props.fac)}
									</Grid>
									<Grid item xs={3} className={classes.label}>
										Kaufpreis
									</Grid>																		
								</Grid>							
							) : (
								<></>
							)
							}
							
							</Grid>							
						</Grid>	
												
						<Grid container direction="row"  style={{marginTop: 10}}>	
							{props.handleViewOpen ? (
								<Grid item>
									<QeeTooltip title="Zur Immobilienübersicht">
									<Button size="small" color="primary" justify="flex-end" className={classes.actionButton} onClick={() => props.handleViewOpen( props.fac)}>
										<OpenInNewIcon />
									</Button>
									</QeeTooltip>
								</Grid>	
							):('')}
							{props.handleEditClickOpen ? (
								<Grid item>
									<QeeTooltip title="Immobilie bearbeiten">
									<Button size="small" color="primary" justify="flex-end" className={classes.actionButton} onClick={() => props.handleEditClickOpen( props.fac)}>
										<EditIcon />
									</Button>
									</QeeTooltip>								
								</Grid>									
							):('')}
							{props.deleteFacHandler ? (
								<Grid item>	
									{usrRight(props.fac) === 'owner' ?
										<QeeTooltip title="Immobilie löschen">
										<Button size="small" color="primary" justify="flex-end" className={classes.actionButton} onClick={() => props.deleteFacHandler( props.fac)}>
											<DeleteIcon />
										</Button>
										</QeeTooltip>								
									:''}
								</Grid>									
							):('')}
							{props.toggleFavFacHandler ? (							
								<Grid item>
								<QeeTooltip title="Aus Favoritenliste entfernen">
								<Button size="small" color="primary" justify="flex-end" className={classes.actionButton} onClick={(ev) => props.toggleFavFacHandler(ev, props.fac)}>
									{props.fac.fac_fav === true ? (
											<FavoriteIcon /> 
										): (
											<FavoriteBorderIcon /> 
										)
									}
								</Button>
								</QeeTooltip>								
								</Grid>	
							):('')}
							{props.newFacHandler ? (
								<Grid item>
								<QeeTooltip title="Zur Favoritenliste hinzufügen">
								<Button size="small" color="primary" justify="flex-end" className={classes.actionButton} onClick={() => props.newFacHandler( props.fac)}>
									<DashboardCustomizeIcon /><span style={{marginLeft:5, paddingTop: 2}}>Hinzufügen...</span>
								</Button>
								</QeeTooltip>								
								</Grid>	
							):('')}
						</Grid>																				
						
					</CardContent>
				</Box>
			</Grid>	

		</Grid>								
		</Card>						
    )
}

export default FacCard;



