import React, { useState, useEffect, useRef } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { makeStyles }  from '@material-ui/core/styles';

import QeeRadioGroup from './qeeRadioGroup';

import { useAuth } from '../context/provider';
import { useValidation } from './qeeStepValidationProvider';


const styles = (theme) => ({
    // Define styles here if needed
});

const FacPlanRenovationComponents = withStyles(styles)((props) => {
	
    const { registerValidation } = useValidation();
	
	const errorRef = useRef(null);	
	const thisStep = props.step;			
	
    const { gstate, setObjEdit, setOpenRenovationDialog, setCurrSubpage } = useAuth();

    // Initialize from the specific part of the global state
    const [objChange, setObjChange] = useState({
        type: gstate.objEdit.fac_plan_renovations?.[4]?.type || ''
    });

    // Ref to hold the latest state for access in callbacks
    const objChangeRef = useRef(objChange);
    useEffect(() => {
        objChangeRef.current = objChange;
    }, [objChange]);
	
	
	// some consts
	const useStyles = makeStyles( theme => ({
		//...props
		textPlan: {
			color:theme.typography.success?.color,   //theme.palette.primary.main,
			fontWeight: 800,			
		},
		validationContainer: {
			borderRadius: 5,
			padding: 5,
			backgroundColor: '#fff',
			[theme.breakpoints.down('xs')]: {
			padding: 0,
			},    
		}		
	}));			
	const classes = useStyles( props);	
	

    // Error management
    const [objErrors, setObjErrors] = useState({});
    const showError = (fieldId) => {
        const errorElement = document.getElementById(fieldId);
        console.log('showError Element ' + fieldId + ': ' + errorElement.id);
        if (errorElement) {
            errorRef.current = errorElement;
        }
        return fieldId;
    };
    const resetErrors = () => {
        setObjErrors({});
    };
	useEffect(() => {
        const errorElements = document.querySelectorAll('.selError');
        if (errorElements.length > 0) {
            setTimeout(() => {
                errorElements.forEach(element => {
                    const parentElement = element.parentNode;
                    if (parentElement) {
                        parentElement.style.backgroundColor = '#fee';
                        parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }, 0);
        }
    }, [errorRef.current]);	
	useEffect(() => {
        registerValidation(thisStep, validate);

        return () => {
            registerValidation(thisStep, null);
        };
    }, []);


	/**
	* validate() - 
	*/
	const validate = async () => {
        console.log('--> validate for FacPlanRenovationEnergyclass in step: ', gstate.objEdit.step);
        let firstErrorShown = false;
        let errors = false;
        const objErrors = {};

        if (!objChangeRef.current.type && !firstErrorShown) {
            objErrors.type = 'Du musst eine Auswahl treffen!';
            showError('type');
            errors = true;
            firstErrorShown = true;
        }

		setObjErrors(objErrors);
		if (!errors) {
			let newPlanRenovations = [...gstate.objEdit.fac_plan_renovations];
			newPlanRenovations[4] = { ...newPlanRenovations[4], type: objChangeRef.current.type };
			let oe = {
				...gstate.objEdit, 
				fac_plan_renovations: newPlanRenovations
			};		
			setObjEdit( await props.fctSubmit( oe));			
			
			if( props.subpageEnergy) {
				setCurrSubpage( props.subpageEnergy);
			}
			setOpenRenovationDialog( false);												
		}

        console.log('<-- validate: ' + errors);
        return !errors;
	};


    return (
        <div className="form-group">
            <form noValidate>
                <Grid container spacing={2} direction="column" justifyContent="left" alignItems="left">
                    <Grid item xs={10}>
                        <h1>Geplante Heizung</h1>
                        <QeeRadioGroup
                            formLabel="Primärheizung"
                            formSublabel="Welches Heizungsystem soll eingesetzt werden?"
                            defaultValue={objChange.type}
                            fctOnChange={(ev) => {
                                setObjChange({
                                    type: ev.target.value
                                });
                                resetErrors();
                            }}
                            sdataName="availableHeatingsTypes"
                        />
                        {objErrors.type && (
                            <div style={{ textAlign: "center" }}>
                                <small className="selError form-text error">
                                    {objErrors.type}
                                </small>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </form>
        </div>
    );
});

export default FacPlanRenovationComponents;
