import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import step1 from '../assets/worker-discipline-arch1.png';
import step2 from '../assets/worker-discipline-arch2.png';
import step3 from '../assets/worker-discipline-arch3.png';
import step4 from '../assets/worker-discipline-arch4.png';
import step5 from '../assets/worker-discipline-arch5.png';


// Default steps array
const defaultSteps = [
    { text: 'Vorgang wird gestartet, die Daten werden geladen...', image: step1 },
    { text: 'Daten werden synchronisiert...', image: step2 },
    { text: 'Bereite die Benutzeroberfläche vor...', image: step3 },
    { text: 'Noch einen Moment, bitte...', image: step4 },
    { text: 'Fast geschafft – Der Dialog ist bereit!', image: step5 },
];

export default function WaitProgressLinear({ steps = defaultSteps }) {
    		
	const [progress, setProgress] = useState(10);
    const [step, setStep] = useState(0);

	
    useEffect(() => {
        const progressInterval = setInterval(() => {
            if (progress < 100) {
                setProgress(progress + 1);
            } else {
                if (step < steps.length - 1) {
                    setStep(step + 1);
                    setProgress(0); // Reset progress for the next step
                }
            }
        }, 50);

        return () => {
            clearInterval(progressInterval);
        };
    }, [progress, step, steps]);

    const currentStep = steps[step] || { text: 'Done!', image: '/loading-image-end.png' };

    let stepProgress = 0;

	/*	
    if (step === 0) {
        stepProgress = progress / 5;
    } else if (step === 1) {
        stepProgress = 20 + progress / 5;
    } else if (step === 2) {
        stepProgress = 40 + progress / 5;
    } else if (step === 3) {
        stepProgress = 60 + progress / 5;
    } else if (step === 4) {
        stepProgress = 80 + progress / 5;
    } else {
        stepProgress = 100;
    }
	*/	
	if (step < steps.length - 1) {
		const stepIncrement = 100 / (steps.length - 1);
		stepProgress = step * stepIncrement + (progress / 100) * stepIncrement;
	} else {
		stepProgress = 100;
	}
	
	
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<LinearProgress
				variant="determinate"
				value={stepProgress}
				sx={{
					width: '50%',
					//minWidth: 400,
					marginTop: 5,
					marginBottom: 5,
					height: 10, // Set the height to 5px
					borderRadius: 5, // Set borderRadius to half of the height for rounded corners
					backgroundColor: '#ddd', // Use the environment variable for background color
						'& .MuiLinearProgress-bar': {
							backgroundColor: process.env.QEE_PRIMARY_COLOR, // Use the environment variable for progress bar color
						},					
					}}
			/>			
            <Typography variant="body1" sx={{ marginBottom: 4 }}>
                {currentStep.text}
            </Typography>
            <img 
				src={currentStep.image} 
				alt="Loading Image"
				style={{ maxWidth: 200 }}
			/>
        </Box>
    );
}

