// facShareDialog.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { useImperativeHandle } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
//import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { Accordion, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from 'axios';

import QeeSlideDialog from './qeeSlideDialog';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import QeeSelectRecipients from './qeeSelectRecipients';
import FacAccessPersons from './facAccessPersons';
import QeeHintBox from './qeeHintBox';
import { useSnackbar } from 'notistack';

import css from '../core/App.css';

import { useAuth } from "../context/provider";
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';



const styles = (theme) => ({
});


// main fct
const FacShareDialog = React.forwardRef((props, forwardedRef) => {	

	const { enqueueSnackbar } = useSnackbar();	

	const [hasErrorsDlgShare, setHasErrorsDlgShare] = useState( true);				
	const [changesDlgShare, setChangesDlgShare] = useState( {});	

	const { gstate, setOpenQeeDialog, setIsFetching } = useAuth();				
	
	const [state, setState] = useState({
		url: '',
		submitDisabled: true,
	});

	
	// some consts
	const useStyles = makeStyles( theme => ({
		//...props
		accordionSummary: {
			backgroundColor: '#f00'
		},
		accordionBox: {
			fontWeight:'bold', 
			m:1, 
			lineHeight:1.2, 
			color:theme.palette.primary.contrastText,
		},
		icon:{
			color:theme.palette.primary.contrastText,
		}
	}));			
	const classes = useStyles( props);
	
	
	const handleFacShareOpen = ( fac) => {
		
		if( !fac)
			fac = {};
		
		setOpenQeeDialog( {open:true, id:'dlgFacShare'});
	}	
	
	
	const handleFacShareClose = () => {
		setOpenQeeDialog( false);	
	};

	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({ 
		ali: ()=>{alert(123)},
		handleFacShareOpen: handleFacShareOpen,						
		handleFacShareClose: handleFacShareClose
	}));	
	
	
	const usrIsOwner = () => {
		var usr = JSON.parse( sessionStorage.getItem( 'usr'));
		if( !usr)
			return false;
		
		if( usr.username == gstate.objEdit.username) 
			return true;
		
		return false;
	}
		
	
	/**
	*	addRights() - calls the server side
	*/
	const addRights = async ( changes) => {
		
		enqueueSnackbar( 'Der Auftrag wurde gestartet...', {variant: 'default'});		
		
		var rights = {
			"allowed_readers":changes.recRole=='reader' ? changes.recEmail : [],
			"allowed_writers":changes.recRole=='writer' ? changes.recEmail : []
		}
		console.log( "Send rights", rights);
		
		const authToken = localStorage.getItem( 'AuthToken');
		var url = `/api/fac/addRights/` + gstate.objEdit.id;
		let opts = {
			url: url,
			method: 'put',
			data: rights
		};
		console.log( 'axios', opts);
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		try {
			await axios( opts);			
			
			enqueueSnackbar( 'Die Rechte wurden gesetzt, Emails wurden gesendet!', {variant: 'success'});					
									
		} catch( error) {
			console.log( 'addRights ERR', error);
			enqueueSnackbar( 'Die Rechte konnten nicht gesetzt werden. Fehler vom Server: ' + error, {variant: 'error'});					
		} 				
	};		
	

	const handleHasErrorsDlgShare = ( hasErrors) => {
		console.log( 'handleHasErrorsDlgShare hasErrors', hasErrors);
		setHasErrorsDlgShare( hasErrors);
	}					
	const handleChangesDlgShare = ( changes) => {
		console.log( 'handleChangesDlgShare results', changes);
		setChangesDlgShare( changes);
	}							
	const handleSubmitDlgShare = () => {
		console.log( 'handleSubmitDlgShare SUBMITTING >>>>', changesDlgShare);
		addRights( changesDlgShare);
		
		setOpenQeeDialog( {open:false, id:'dlgFacShare'});
	}	
	

	const getHintShareInfo = () => {
		return `<Typography>Die Immobiliendaten wurden vom Eigentümer für dich freigegeben. Du hast nur einen eingeschränkten Zugriff auf die Daten.</Typography>`
	}	
				
	return (		
		<QeeSlideDialog 
		  id='dlgFacShare'
		  title='Zugriff verwalten' 
		  formComponent={
			<>
			{usrIsOwner() ? 
				<QeeSelectRecipients
					label="Den ausgewählten Personen wird Zugriff auf deine Immobiliendaten gewährt. Die Empfänger werden per E-Mail über diese Aktion informiert."
					fctOnHasErrors={handleHasErrorsDlgShare}
					fctOnChanges={handleChangesDlgShare}
				/>
			:
				<div style={{marginTop:80, marginBottom:20}}>					
					<QeeHintBox type="info" text={<React.Fragment><strong>Einschränkung:</strong> <span dangerouslySetInnerHTML={{__html: getHintShareInfo()}}></span></React.Fragment>} style={{marginTop:20}}/>								
				</div>
			}
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className={classes.icon}/>}
					aria-controls="panel1a-content"
					id="panel1a-header"
					sx={{
						backgroundColor: (theme) => theme.palette.primary.main,
						color: (theme) => theme.palette.primary.contrastText,
					}}					
				>
					<Typography><Box className={classes.accordionBox}>Personen mit Zugriffsrechte</Box></Typography>
				</AccordionSummary>
				<FacAccessPersons 
				  facId={gstate.objEdit.id} 
				  username={gstate.objEdit.username} 
				  usrIsOwner={usrIsOwner()}
				/>
			</Accordion>
			</>
		  }					
		  submitDisabled={hasErrorsDlgShare}
		  fctOnSubmit={handleSubmitDlgShare}
		>
		</QeeSlideDialog>
	)
})	
export default withStyles(styles)(FacShareDialog);