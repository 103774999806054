import react, { useState, useEffect, useRef } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles }  from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import QeeRadioGroup from './qeeRadioGroup';
import FacRenovationsCheckboxGroup from './facRenovationsCheckboxGroup';

import { useValidation } from './qeeStepValidationProvider';

import { useAuth } from "../context/provider";


const FacRenovationQuestion = ( props) => { 
	
	const { registerValidation } = useValidation();		
	const errorRef = useRef(null);	
	
	const thisStep = props.step;		
	
	const { gstate, setObjEdit, setCurrSubpage, setOpenRenovationDialog } = useAuth();				

	const [objChange, setObjChange] = useState({									
		fac_has_renovation: gstate.objEdit.fac_has_renovation,
		fac_renovations: gstate.objEdit.fac_renovations
	});						
	const objChangeRef = useRef( objChange);
	useEffect(() => {
		objChangeRef.current = objChange;  // Update ref on state change
	}, [objChange]);											
	const [objErrors, setObjErrors] = useState({});	
	
	
	// some consts
	const useStyles = makeStyles(theme => ({
		validationContainer: {
			borderRadius: 5,
			padding: 5,
			backgroundColor: '#fff',
			[theme.breakpoints.down('xs')]: {
				padding: 0,
			},    
		}
	}));	
	const classes = useStyles( props);		
		
					
	const showError = (fieldId) => {

		const errorElement = document.getElementById(fieldId);
		console.log('showError Element ' + fieldId + ': ' + errorElement.id);
		if (errorElement) {
			errorRef.current = errorElement;
		}
		return fieldId;		
	};
	
	
	const resetErrors = ( id) => {				
		setObjErrors({});
		document.getElementById( id).style.backgroundColor = '#fff';				
	}	
	
	
	useEffect(() => {
		const errorElements = document.querySelectorAll('.selError');
		if (errorElements.length > 0) {
			// Defer the background color change
			setTimeout(() => {
				errorElements.forEach(element => {
					const parentElement = element.parentNode;
					if (parentElement) {
						parentElement.style.backgroundColor = '#fee';
						parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
					}
				});
			}, 0);
		}
	}, [errorRef.current]);
	
	
	useEffect(() => {
		registerValidation( thisStep, validate);

		// Clean up: unregister validation when the component unmounts or the step changes
		return () => {
			registerValidation( thisStep, null);
		};
	}, []);	


	const validate = async () => {
		console.log('--> validate for FacRenovationQuestion in step: ', gstate.objEdit.step);
		let firstErrorShown = false; // Flag to track if the first error has been shown
		let errors = false;
		const objErrors = {};

		if (!objChangeRef.current.fac_has_renovation && !firstErrorShown) {
			objErrors.fac_has_renovation = 'Du musst eine Auswahl treffen!';
			showError('fac_has_renovation');
			errors = true;
			firstErrorShown = true; // Set the flag to true after showing the first error
		}

		setObjErrors(objErrors);
		if (!errors) {
			let oe = { 
				...gstate.objEdit, 
				...JSON.parse( JSON.stringify( objChangeRef.current)) 
			};
			console.log( '>>> SAVE RENOS OE: ', oe)
			oe = await props.fctRenovationCalculate( oe);
			setObjEdit( oe);

			// --- for fac_type appartment go out here
			if( gstate.objEdit.fac_type === '200') {  
				if( props.subpageEnergy) {
					setCurrSubpage( props.subpageEnergy);
				}
				setOpenRenovationDialog( false);									
			}				
		}

		console.log('<-- validate: ' + errors);
		return !errors;
	};
		
		
    return (
        <div class="form-group">
			<form  noValidate>					
				<Grid container 
					spacing={2} 
					direction="column" 
					justifyContent="left" 
					alignItems="left">
					<Grid item xs={10}>
						<h1>Wurde die Immobilie ganz oder zumindest teilweise saniert?</h1>		
					</Grid>	
	
					<Grid item xs={10}>
						<Grid container 
							spacing={2} 
							direction="column" 
							justifyContent="left" 
							alignItems="left">					
							<Grid item xs={12}>
								<div id='fac_has_renovation' className={classes.validationContainer}>
								<QeeRadioGroup
									formSublabel={<div style={{maxWidth:800}}>Unter eine Sanierung versteht man die baulich-technische Wiederherstellung oder Modernisierung einer oder mehrerer Etagen bzw. eines gesamten Bauwerks oder mehrerer Bauwerke, um Schäden zu beseitigen und/oder den Wohnstandard zu erhöhen. In erster Linie geht es um die Werterhaltung der Bausubstanz. Dies betrifft sowohl die Fassade, den Kern als auch die technischen Anlagen der Immobilie.</div>}
									defaultValue={objChange.fac_has_renovation}
									fctOnChange={( ev)=>{
										console.log( 'onChange fac_has_renovation', ev.target.value);
										var och = {
											...objChange,
											fac_has_renovation: ev.target.value
										};
										setObjChange( och);
										resetErrors( 'fac_has_renovation');
									}}
									sdata={[
										{value:'1', label:'Ja'},
										{value:'0', label:'Nein'}
									]}
								/>
								{ objErrors.fac_has_renovation ? ( <div style={{textAlign:"center"}}><small   className="selError form-text error">{objErrors.fac_has_renovation}</small></div> ) :( <></> ) }
								</div>	
							</Grid>
							<Grid item xs={12}>
							{		
							objChange.fac_has_renovation === '1' ?
								<FacRenovationsCheckboxGroup
									formSublabel={<div style={{maxWidth:800}}>Welche Bauteile wurden saniert?</div>}								
									defaultValue={gstate.objEdit.fac_renovations}
									fctOnChange={( oRenovations)=>{
										//setObjEdit({...gstate.objEdit, fac_renovations: oRenovations})										
										console.log( 'onChange fac_renovations', oRenovations);
										var och = {
											...objChange,
											fac_renovations: oRenovations.map(renovation => ({ ...renovation }))
										};
										setObjChange( och);
									}}								
									data={gstate.objEdit.fac_renovations}									
								/>								
							:
								<></>
							}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</div>
    )
}
export default FacRenovationQuestion;