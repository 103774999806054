// auth.js
import axios from 'axios';

export const authMiddleWare = (history) => {
    const authToken = localStorage.getItem( 'AuthToken');
    if( authToken === null){
        history.push( '/home')
    }
}


export const setAxiosAuthToken = () => {
	const authToken = localStorage.getItem( 'AuthToken');
	const refreshToken = localStorage.getItem( 'RefreshToken');
	axios.defaults.headers.common = { Authorization: `${authToken}`, RefreshToken: refreshToken};
}


export const setLocalStorageAuthToken = ( response) => {
	if( response.data.authToken)
		localStorage.setItem( 'AuthToken', response.data.authToken);
}


/**
*	checkLicense() - checks the usr license for a given fac_id. 
*		Must be called in a url context containing the fac_id.					
*/
export const checkLicense = ( lic, facId) => { 
	
	var tp = 'insufficient';

	if( lic.liccus_valid) {			
		
		if( lic.type == 'enterprise') {
			if( !lic.liccus_facs_premium_max) {  // unlimited
				return lic.type;
			} else {
				if( lic.liccus_facs.length+1<=lic.liccus_facs_premium_max)
					tp = 'enterprise'
			}
		}
		
		// for PREMIUM, if not specified, try to get the facId, if any in the url
		if( !facId) {
			const myURL = new URL( window.location);			
			facId = myURL.searchParams.get( 'id');		
		}
		var facs = lic.liccus_facs;
		if( facs) {
			for( var i=0;i<facs.length; i++) {
				if( facs[i].id == facId) {
					return 'premium';
				}
			}
		}
	}
			
	return tp;
}
