// sjos.js

import React, { Dimensions, Component } from 'react';
import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import TextSnippetIcon from "@mui/icons-material/TextSnippet"
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LensIcon from '@material-ui/icons/Lens';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import * as marked from 'marked';

import QeeSlideDialog from './qeeSlideDialog';
import SjoWizDlg from './sjoWizDlg';

import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from 'notistack';


import axios from 'axios';
import useWindowDimensions from '../util/windowDimensions';
import { useNavigate } from "react-router-dom";
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';

import QeeTooltip from './qeeTooltip';


import { useAuth } from "../context/provider";


const styles = (theme) => ({
	floatingButton: {
		position: 'fixed',
		bottom: 0,
		right: 0
	},	
	icon: {
		color: theme.palette.primary.main,
		position: 'relative',
		top: 5
	},	
});	


// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


// main fct for sjos
const Sjos = ( props) => {

	const confirm = useConfirm();
	const { enqueueSnackbar } = useSnackbar();				

	const refSjoWizDlg = useRef(null);			

	const { gstate, setOpenQeeDialog } = useAuth();		
	
	const [state, setState] = useState({	
		screenDims: {width:0, height:0},
		open: false,
		uiLoading: true,
		sjos: [],
		viewOpen: false,
		dlgOpen: false,
		bucketPath: JSON.parse(sessionStorage.getItem('scope')).application.bucketPath
	})	
	const offset = 100;
	const [height, setHeight] = useState( window.innerHeight>offset ? window.innerHeight-offset : offset);  			
	state.screenDims = useWindowDimensions();
	console.log( 'scr', state.screenDims);
	
	// some consts
	const useStyles = makeStyles({
	});			
	const classes = useStyles( props);					
	
	const navigate = useNavigate();

	const getSjos = async () => {
		setAxiosAuthToken();
		const url = '/api/sjos';
		setState({ ...state, uiLoading: true });
		axios
			.get(url)
			.then(( response) => {
				console.log( 'response', response);
				setLocalStorageAuthToken( response);
				setState({ 					
					...state, 
					sjos: response.data.entries.results,
					uiLoading: false	
				});
			})
			.catch((err) => {
				console.log(err);
				setState({ ...state, uiLoading: false });
			});		
	}
	
	useEffect(() => {
		getSjos();
	}, [state.dlgOpen]);


	const handleClickOpen = () => {
		
		console.log( 'handleClickOpen with tpl', gstate.openNewSjo);
				
		setState({...state, dlgOpen:true})		
		setOpenQeeDialog( {open:true, id:'dlgSjoWiz'});		
	};
	
	
	const handleStart = (jobId) => {
		// start the job with the given ID
	};

	const handleStop = (jobId) => {
		// stop the job with the given ID
	};

	const handleDelete = (jobId) => {
		confirm({ 
				title: "Server-Job löschen", 
				description: "Hierdurch werden alle Daten für diesen Server-Job, einschließlich generierter und verschachtelter Daten, endgültig gelöscht.",
				confirmationText: "Löschen starten",				
				cancellationText: "Abbrechen",				
				confirmationButtonProps: {color:"error", variant:"contained", style:{textTransform: 'none'}},
				cancellationButtonProps: {color:"success", style:{textTransform: 'none'}}								
			})
			.then(() => {
				const authToken = localStorage.getItem('AuthToken');
				const refreshToken = localStorage.getItem( 'RefreshToken');
				axios.defaults.headers.common = { Authorization: `${authToken}`, RefreshToken: refreshToken};		
				let id = jobId;
				axios
					.delete(`/api/sjo/${id}`)
					.then(() => {						
						getSjos();
						enqueueSnackbar( 'Der Server-Job und alle dazugehörigen Datensätze wurden gelöscht!', {variant: 'success'});
					})
					.catch((err) => {
						console.log(err);
					});			
			})
			.catch(() => {			
			});
	}	
	

	const Markdown = ({ text }) => {
		const rawMarkup = marked(text, { sanitize: true });
		return <span dangerouslySetInnerHTML={{ __html: rawMarkup }} />;
	};	
	
		
	const renderNameCell = (params) => {
					
		return (
			<div className="d-flex justify-content-between align-items-center" style={{width:'100%'}}>				
				<PopupState variant="popover" popupId="qeeToolsMenu">
				{(popupState) => (			
					<React.Fragment>	
					<table style={{width:'100%'}}>
						<tr>
						<td style={{width:40}}>
							<FlipCameraIosIcon style={{marginRight:5, fontSize:36, color:'#444'}}/>
						</td>
						<td>
							<a href={params.row.signedUrl} target='_blank'>
								{params.row.sjo_name && params.row.sjo_name.length > 40 ?  
									<QeeTooltip title={params.row.sjo_name}>
									<div style={{width:gstate.isXS ? 240 : 300, overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.sjo_name}</div>
									</QeeTooltip>
								:
									<div style={{width:gstate.isXS ? 240 : 300, overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.sjo_name}</div>								
								}
							</a>
							<div>{params.row.sjo_type}</div>					
						</td>
						<td style={{textAlign:'right'}}>
							<Button 
								className={classes.button} 
								{...bindTrigger(popupState)}>
								<MoreVertIcon style={{marginLeft:gstate.isXS ? 0 : 20, fontSize:36, color:'#444', borderLeft: 'solid 1px #ddd'}}/>					
							</Button>					
						</td>					
						</tr>
					</table>											
					<Menu {...bindMenu(popupState)}>
					  {params.row.sjo_state === 100 && (
						<MenuItem
						  onClick={() => {
							popupState.close();
						  }}
						>
						  Start
						</MenuItem>
					  )}
					  {params.row.sjo_state === 200 && (
						<MenuItem
						  onClick={() => {
							enqueueSnackbar( 'Es wird versucht den Server-Job zu beenden...', {variant: 'info'});
							popupState.close();
						  }}
						>
						  Stop
						</MenuItem>
					  )}
					  {params.row.sjo_state === 300 && (
						<MenuItem
							onClick={() => {
								window.open( state.bucketPath + params.row.fld_name+'/'+params.row.file_name_export, '_blank');
								popupState.close();
							}}
						>
							{'Ergebnis herunterladen'}
						</MenuItem>					  						
					  )}					  
					  <Divider />
					  <MenuItem
						onClick={() => {
						  handleDelete( params.row.id);
						  popupState.close();
						}}
					  >
						Löschen
					  </MenuItem>
					</Menu>
					</React.Fragment>
				)}
				</PopupState>				
							
			</div>				
		)
	}
	
	
	function renderStateIcon(state) {
		let stateColor = '';
		switch (state) {
			case 100:
				stateColor = 'orange';
				break;
			case 200:
				stateColor = 'green';
				break;
			case 300:
				stateColor = 'blue';
				break;
			case 10000:
				stateColor = 'grey';
				break;
			default:
				stateColor = 'black';
				break;
		}
		return <LensIcon style={{ color: stateColor, marginRight: 5 }} />;
	}

	function renderStateText(state) {
		let stateText = '';
		switch (state) {
			case 100:
				stateText = 'geplant';
				break;
			case 200:
				stateText = 'arbeitet';
				break;
			case 300:
				stateText = 'beendet';
				break;
			case 10000:
				stateText = 'storniert';
				break;
			default:
				stateText = 'unbekannt';
				break;
		}
		return stateText;
	}	
	
	
	const columns = gstate.isXS ? [
		{ field: 'sjo_name', headerName: 'Job Name', width: '100%', maxWidth: '100%', renderCell: renderNameCell },
    ]
		: 
	[
		{ field: 'sjo_name', headerName: 'Job Name', renderCell: renderNameCell, minWidth: 420, flex: 1 },
		{
			field: 'sjo_state',
			headerName: 'Status',
			width: 200,
			renderCell: (params) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{renderStateIcon(params.value)}
					<span>{renderStateText(params.value)}</span>
				</div>
			),
		},		
		{
			field: 'qee_creation_date',
			headerName: 'Erstellt',
			width: 250,
			flex: 1,
			valueFormatter: (params) => {
				const timestamp = params.value._seconds * 1000 + params.value._nanoseconds / 1e6;
				const date = new Date(timestamp);
				const options = {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
					hour12: false,
				};
				return date.toLocaleString('de-DE', options);			
			},
		},
    ];
	
	const MyCustomNoRowsOverlay = () => (		
		<Stack height="100%" alignItems="center" justifyContent="center" style={{padding:50}}>		
			<FlipCameraIosIcon style={{fontSize:gstate.isXS ? 60 : 180, color:'#ddd'}} />
			<div style={{textAlign:'center', maxWidth:600, marginTop:20}}>Noch keine Server-Jobs erfasst!<br/><br/>Ein Server-Job kannst du über den Button {<AddCircleIcon className={classes.icon}/>} erstellen.</div>
		</Stack>	
	);		
	
	
	const localizedTextsMap = {
		columnMenuUnsort: "Keine Sortierung",
		columnMenuSortAsc: "Sortierung aufsteigend",
		columnMenuSortDesc: "Sortierung absteigend",
		columnMenuFilter: "Filter",
		columnMenuHideColumn: "Spalte verbergen",
		columnMenuShowColumns: "Spalten verwalten"
	};	
	
	
	const reloadSjos = () => {
		setState({...state, dlgOpen:false})		
	}
	
		
	return (
		<>
		<Stack style={{ width: '100%', maxWidth:1200, marginTop:0 }} alignItems="flex-start" spacing={2}>
			<div style={{ height, width: '100%' }}>
				<DataGrid 
					autoPageSize 
					pagination 
					rows={state.sjos}
					columns={columns}
					components={{
						NoRowsOverlay: MyCustomNoRowsOverlay
					}}
					localeText={localizedTextsMap}	
					sx={{
						'& .MuiDataGrid-cell:focus': {
							color: 'primary.main',
							border: 'solid 0 #fff'
						},
					}}	
				/>
				
				<IconButton
					className={classes.floatingButton}
					color="primary"
					aria-label="Add Sjo"
					onClick={handleClickOpen}
				>
					<AddCircleIcon style={{ fontSize: 60 }} />
				</IconButton>
								
			</div>
		</Stack>

		<SjoWizDlg 
			ref={refSjoWizDlg}
			fctReloadData={reloadSjos}
		/>		
				
		</>
	)

}	
export default withStyles(styles)(Sjos);