// fileUploaderDialog.js

import react, { useState, useEffect, useLayoutEffect, useRef, createRef, useCallback } from 'react';
import React, { useImperativeHandle } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';

import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { customizeValidator } from '@rjsf/validator-ajv8';
import localizer from "ajv-i18n";
import Form from "@rjsf/mui";
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import axios from 'axios';

import QeeRadioGroup from './qeeRadioGroup';
import QeeSlideDialog from './qeeSlideDialog';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import QeeTooltip from './qeeTooltip';
import QeeFileInput from './qeeFileInput';

import css from '../core/App.css';

import { useAuth } from "../context/provider";
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';
import { getSdatas, getTaxonomyTpl } from '../api/service';


const styles = (theme) => ({
});


// main fct
const QeeTaxonomyDialog = React.forwardRef((props, forwardedRef) => {	

	const refStepper = useRef(null);	

	const { gstate, setObjEdit, setOpenQeeDialog, setIsFetching, setObjErrors } = useAuth();				
		
	const [state, setState] = useState({
		isLoading: false,
	});

		
	// some consts
	const useStyles = makeStyles({
		//...props
		uiProgess: {
			position: 'fixed',
			zIndex: '1000',
			height: '31px',
			width: '31px',
			left: '50%',
			top: '35%'
		},
		progess: {
			position: 'absolute'
		},
	});			
	const classes = useStyles( props);
	
	
	const handleTaxonomyWizOpen = () => {
		console.log( 'handleTaxonomyWizOpen')
	
		setState({
			submitDisabled: true,			
		});
	
		setOpenQeeDialog( {open:true, id:'dlgTaxonomyWiz'});
	}	
	
	
	const handleTaxonomyWizClose = () => {		
		if( gstate.isXS)		
			setTimeout(function(){
				window.scrollTo(0, 0);
			}, 0);				
		setOpenQeeDialog( false);	
	};
	

	const validate = () => {		
		return refStepper.current.validate();	
	};				
		
	
	const onSubmit = async ( och) => {

		setState({
			...state,
			isLoading: true
		})
	
		console.log( 'onsubmit', och);	
		
		let options = {};
		let id = och.id;
		options = {
			url: `/api/fac/taxonomy/${id}`,
			method: 'post',
			data: och,   
			//onUploadProgress : handleUploadProgress,			
		};		
		setAxiosAuthToken();
		try {
			const retFac = await axios( options);
			console.log( 'Calculated fac', retFac.data);
			setObjEdit( retFac.data);
			//setObjEdit( och);
			setState({
				...state,
				isLoading: false
			})						
			return true;
		} catch( error) {
			setObjErrors( error.response.data);
			console.log(error);
			setState({
				...state,
				isLoading: false
			})			
			return false;			
		}					
	};		
		

	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		ali: ()=>{alert(123)},
		handleTaxonomyWizOpen: handleTaxonomyWizOpen,						
		handleTaxonomyWizClose: handleTaxonomyWizClose
	}));	
		
	
	/****************************************************************
	*
	*	VerticalLinearStepper() - component for stepping the wizzard
	*
	****************************************************************/

	const VerticalLinearStepper = React.forwardRef((props, forwardedRef) => {	
	
	const formRef = createRef();
	const validator = customizeValidator({}, localizer.de);
	
	// some consts
	const useStyles = makeStyles( theme => ({
		validationContainer: {
			borderRadius:5,
			padding:5,
			[theme.breakpoints.down('xs')]: {
				padding:0,
			},				
			backgroundColor: '#fff'
		},
		validationContainerActive: {
			borderRadius:5,
			padding:5,
			[theme.breakpoints.down('xs')]: {
				padding:0,
			},				
			backgroundColor: '#fee'
		},
		uiProgess: {
			position: 'fixed',
			zIndex: '1000',
			height: '31px',
			width: '31px',
			left: '50%',
			top: '35%'
		},		
	}));			
	const classes = useStyles( props);	
	
	const [objChange, setObjChange] = useState( gstate.objEdit);	
	const [validNext, setValidNext] = useState( false);
	const [validPrev, setValidPrev] = useState( true);	
	const [isLoading, setIsLoading] = useState( false);		
	
	const handleSetState = ( st) => {
		console.log( 'VerticalLinearStepper.handleSetState', st);
		props.handleSetState( st);	
	}	
	
	
/**
Example steps to follow for creating a schema
1. Define the des_data in JS:
schema: {
	type: "object",
	properties: {
		"fac_name": {type: "string", title: "Bezeichnung"},
		"fac_type": {type: "string", title: "Gebäudetyp"},
		"addr_gvalue_text_o": {type: "string", title: "Adresse"},
		"fac_bgf": {type: "number", title: "Brutto Geschossfläche"},
	},
	required: 
		["fac_bgf"]
	},
ui_schema: {
	"fac_name": {"ui:disabled": true},	
	"fac_type": {"ui:disabled": true},
	"addr_gvalue_text_o": {"ui:disabled": true},	
}


2. Convert it to JSON in JSON definition
{
  "lbl": "Eckdaten",
  "type": "form",
  "des_data": "{\"schema\": {
		\"type\": \"object\",
		\"properties\": {
			\"fac_name\": {\"type\": \"string\", \"title\": \"Bezeichnung\"},
			\"fac_type_text\": {\"type\": \"string\", \"title\": \"Gebäudetyp\"},
			\"addr_gvalue_text_o\": {\"type\": \"string\", \"title\": \"Adresse\"},
			\"fac_bgf\": {\"type\": \"number\", \"title\": \"Brutto Geschossfläche\"}},
			\"required\": [\"fac_bgf\"]
		},
		\"ui_schema\": {
			  \"fac_name\": {\"ui:disabled\": true},
			  \"fac_type_text\": {\"ui:disabled\": true},
			  \"addr_gvalue_text_o\": {\"ui:disabled\": true}
		}"
}


3. Repair the newlines and tabs with https://jsoneditoronline.org/
It results:
{"lbl":"Eckdaten", "type":"form", "des_data":"{\"schema\": {\n\t\t\"type\": \"object\",\n\t\t\"properties\": {\n\t\t\t\"fac_name\": {\"type\": \"string\", \"title\": \"Bezeichnung\"},\n\t\t\t\"fac_type_text\": {\"type\": \"string\", \"title\": \"Gebäudetyp\"},\n\t\t\t\"addr_gvalue_text_o\": {\"type\": \"string\", \"title\": \"Adresse\"},\n\t\t\t\"fac_bgf\": {\"type\": \"number\", \"title\": \"Brutto Geschossfläche\"}\n\t\t},\n\t\t\"required\": \n\t\t\t[\"fac_bgf\"]\n\t},\n\t\"ui_schema\": {\n\t\t\"fac_name\": {\"ui:disabled\": true},\t\n\t\t\"fac_type_text\": {\"ui:disabled\": true},\n\t\t\"addr_gvalue_text_o\": {\"ui:disabled\": true}\t\n\t}\n\t}"}


4. Copy this string into Synapcus tpl rectangle
*/	
	

/**
Ready example from Synapcus tpl	
let wkf = {
  "type": "DEVELOP Taxonomy-Wizzard",
  "name": "<p>Taxo Wizzard from vreed</p>\n",
  "isTemplate": true,
  "steps": {
    "data": [
      {
        "id": "u1671464625095",
        "type": "start",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "{'lbl':'Start'}",
        "done": false,
        "next": [
          "u1671464625097"
        ],
        "prev": [

        ]
      },
      {
        "id": "u1671464625096",
        "type": "end",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "{'lbl':'End'}",
        "done": false,
        "next": [

        ],
        "prev": [
          "u1671464825488",
          "u1671464845274",
          "u1671464845248"
        ]
      },
      {
        "id": "u1671464625097",
        "type": "rectangle",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "{\"lbl\":\"Eckdaten\", \"type\":\"form\", \"des_data\":\"{\\\"schema\\\": {\\n\\t\\t\\\"type\\\": \\\"object\\\",\\n\\t\\t\\\"properties\\\": {\\n\\t\\t\\t\\\"fac_name\\\": {\\\"type\\\": \\\"string\\\", \\\"title\\\": \\\"Bezeichnung\\\"},\\n\\t\\t\\t\\\"fac_type\\\": {\\\"type\\\": \\\"string\\\", \\\"title\\\": \\\"Gebäudetyp\\\"},\\n\\t\\t\\t\\\"addr_gvalue_text_o\\\": {\\\"type\\\": \\\"string\\\", \\\"title\\\": \\\"Adresse\\\"},\\n\\t\\t\\t\\\"fac_bgf\\\": {\\\"type\\\": \\\"number\\\", \\\"title\\\": \\\"Brutto Geschossfläche\\\"}\\n\\t\\t},\\n\\t\\t\\\"required\\\": \\n\\t\\t\\t[\\\"fac_bgf\\\"]\\n\\t},\\n\\t\\\"ui_schema\\\": {\\n\\t\\t\\\"fac_name\\\": {\\\"ui:disabled\\\": true},\\t\\n\\t\\t\\\"fac_type\\\": {\\\"ui:disabled\\\": true},\\n\\t\\t\\\"addr_gvalue_text_o\\\": {\\\"ui:disabled\\\": true}\\t\\n\\t}\\n\\t}\"\n}",
        "done": false,
        "next": [
          "u1671464626643"
        ],
        "prev": [
          "u1671464625095"
        ]
      },
      {
        "id": "u1671464626643",
        "type": "decision",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "{'lbl':'Nutzung', 'text':'Ist das Gebäude für die Gewinnung, Lagerung, Beförderung oder Herstellung fossiler Brennstoffe bestimmt?', 'has_sdoc':true, 'sdoc_type':300, 'sdoc_subtype':2000,'desc':'Tooltip.....','notes':''}",
        "done": false,
        "next": [
          "u1671464627284",
          "u1671464627310",
          "u1671533030883"
        ],
        "prev": [
          "u1671464625097"
        ]
      },
      {
        "id": "u1671464627284",
        "type": "yes",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "yes",
        "done": false,
        "next": [
          "u1671464627336"
        ],
        "prev": [
          "u1671464626643"
        ]
      },
      {
        "id": "u1671464627310",
        "type": "no",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "no",
        "done": false,
        "next": [
          "u1671464627336"
        ],
        "prev": [
          "u1671464626643"
        ]
      },
      {
        "id": "u1671464627336",
        "type": "decision",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "{'lbl':'OECD-Leitsätze', 'text':'Sind die OECD-Leitsätze für multinationale Unternehmen zu Wirtschaft und Menschenrechten im Rahmen der Renovierung eingehalten?', 'has_sdoc':true, 'sdoc_type':300, 'sdoc_subtype':2000,'desc':'Tooltip.....','notes':''}",
        "done": false,
        "next": [
          "u1671464627352",
          "u1671464627363",
          "u1671539970490"
        ],
        "prev": [
          "u1671464627284",
          "u1671464627310",
          "u1671533030883"
        ]
      },
      {
        "id": "u1671464627352",
        "type": "yes",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "yes",
        "done": false,
        "next": [
          "u1671464822007"
        ],
        "prev": [
          "u1671464627336"
        ]
      },
      {
        "id": "u1671464627363",
        "type": "no",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "no",
        "done": false,
        "next": [
          "u1671464822007"
        ],
        "prev": [
          "u1671464627336"
        ]
      },
      {
        "id": "u1671464822007",
        "type": "decision",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "{'lbl':'Jahres-Primärenergiebedarf', 'text':'Ist der Jahres-Primärenergiebedarf in kWh / (m2*a) bezogen auf den geregelten Energieverbrauch während der Betriebsphase (B6 gemäß EN 15978) verfügbar und gemäß den nationalen Methoden zur Bewertung der Anlagengestaltung oder gemäß der Definition ISO52000 berechnet?', 'has_sdoc':true, 'sdoc_type':300, 'sdoc_subtype':2000,'desc':'Tooltip.....','notes':''}",
        "done": false,
        "next": [
          "u1671464825488",
          "u1671464829005"
        ],
        "prev": [
          "u1671464627352",
          "u1671464627363",
          "u1671539970490"
        ]
      },
      {
        "id": "u1671464825488",
        "type": "no",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "no",
        "done": false,
        "next": [
          "u1671464625096"
        ],
        "prev": [
          "u1671464822007"
        ]
      },
      {
        "id": "u1671464829005",
        "type": "yes",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "yes",
        "done": false,
        "next": [
          "u1671464845232"
        ],
        "prev": [
          "u1671464822007"
        ]
      },
      {
        "id": "u1671464845232",
        "type": "decision",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "{'lbl':'Bedingungen', 'text':'Ist eine der beiden Bedingungen erfüllt?\n\t(a) Größere Renovierung: Die Renovierung entspricht den Anforderungen der geltenden Bauverordnung für eine „größere Renovierung“, die die Gesamtenergieeffizienz umsetzt Gebäuderichtlinie (EPBD)\n\t(b) Relative Verbesserung: Die Renovierung erzielt Einsparungen beim Netto-Primärenergiebedarf von mind. 30 % im Vergleich zur Basisleistung des Gebäudes vor der Renovierung. Die Grundleistung und prognostizierte Verbesserung basieren auf einer fachmännischen Baugutachtung und wurden durch einen akkreditierten Energiegutachter validiert.', 'has_sdoc':true, 'sdoc_type':300, 'sdoc_subtype':2000,'desc':'Tooltip.....','notes':''}",
        "done": false,
        "next": [
          "u1671464845274",
          "u1671464845248"
        ],
        "prev": [
          "u1671464829005"
        ]
      },
      {
        "id": "u1671464845248",
        "type": "yes",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "yes",
        "done": false,
        "next": [
          "u1671464625096"
        ],
        "prev": [
          "u1671464845232"
        ]
      },
      {
        "id": "u1671464845274",
        "type": "no",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "no",
        "done": false,
        "next": [
          "u1671464625096"
        ],
        "prev": [
          "u1671464845232"
        ]
      },
      {
        "id": "u1671533030883",
        "type": "rectangle",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "{'lbl':'Weiß nicht','type':'notknown'}",
        "done": false,
        "next": [
          "u1671464627336"
        ],
        "prev": [
          "u1671464626643"
        ]
      },
      {
        "id": "u1671539970490",
        "type": "rectangle",
        "usr": "47584DE78D9EF519C125821B00537AC1",
        "text": "{'lbl':'Weiß nicht','type':'notknown'}",
        "done": false,
        "next": [
          "u1671464822007"
        ],
        "prev": [
          "u1671464627336"
        ]
      }
    ]
  },
  "links": {
    "data": [

    ]
  }
}
*/

	
/**
Example simple steps	
let wkf1 = [
	{
		label: 'One',
		description: ``,
	},
	{
		label: 'Two',
		description: '',
	},
	{
		label: 'Three',
		description: ``
	},
];
*/

	const [activeStep, setActiveStep] = React.useState( objChange.fac_taxonomy.activeStep ? objChange.fac_taxonomy.activeStep : 0);					
	
	useEffect( async () => {		
		const _dhtmlxToPlainJson = async ( stps) => {

			// complete the objChange	
			var facTypeText = '';	
			var arrRet = await getSdatas( 'sdata_facs_types', 'type', objChange.fac_type);
			if( arrRet.length) 
				facTypeText = arrRet[0].name;
				
		
			// get it from service --> synforge --> synapcus
			var stps = await getTaxonomyTpl();
			console.log( 'stps', stps);
								
			var stps2 = [];
			for( var i=0; i<stps.length; i++) {
				let st = stps[i];
				console.log( 'st', st);
				if( st) {
					var st2 = st;
					if( st.type==='start') {
						
					} else if( st.type==='decision') {
						try {
							var txt = st.text.replace(/'/g, '"').replace(/\n/g, "<br/>").replace(/\t/g, "   ");
							var json = JSON.parse( txt);											
							
							st2.yes = stps.find(({ id}) => id === st.next[0])
							st2.no = stps.find(({ id}) => id === st.next[1])
							st2.title = json.lbl;
							st2.question = json.text;
							st2.desc = json.desc;
							st2.has_sdoc = json.has_sdoc;
							st2.sdoc_type = json.sdoc_type;
							st2.sdoc_subtype = json.sdoc_subtype;
							//st2.fld = json.fld;
							stps2.push( st2);
						} catch( e) {
							console.error( 'Cannot add decision element ERR', e);		
						}						
						
					} else if( st.type==='yes') {
						
					} else if( st.type==='no') {
						
					} else if( st.type==='rectangle') {	
						try {
							var txt = st.text.replace(/'/g, '"').replace(/[\n\r\t]/g,"");
							console.log( 'Json txt', txt);
							var json = JSON.parse( txt);	
							console.log( 'Json obj', json);	
							if( json.type==='form') {
								st2.text = {};
								st2.text.title = json.lbl;
								var odd = JSON.parse( json.des_data.replace(/[\n\r\t]/g,""));
								console.log( 'Json des_data odd', odd);
								st2.text.des_data = odd;
								stps2.push( st2);
							}
						} catch( e) {
							console.error( 'Cannot add rectangle element ERR', e);		
						}
					
					} else if( st.type==='end') {
					}
				}
				
			}
			console.log( 'Normalized stps', stps2);
			var och = objChange;
			och.fac_type_text = facTypeText;
			och.fac_taxonomy.steps = JSON.parse( JSON.stringify( stps2));
			setObjChange( och);
		}
		
		if( !objChange.fac_taxonomy.steps.length) {   // take it from design
		
			setIsLoading( true);
		
			await _dhtmlxToPlainJson();
			// for test from code example
			//_dhtmlxToPlainJson( wkf.steps.data);
			
			setIsLoading( false);
			
		} else {
			setObjChange( gstate.objEdit);	// take it from data
		}

		// reset the scroll to the activeStep	
		setTimeout(function(){
			document.getElementById( 'step_'+activeStep).scrollIntoView( { behavior: 'smooth', block: 'center' });						
		}, 1000);
		
	}, []);	
	
		
	useEffect(() => {			
		console.log( '>>> OBJ CHANGE', objChange);		
		if( objChange.fac_taxonomy.steps[activeStep])
			validateNextButton( activeStep, objChange.fac_taxonomy.steps[activeStep].result);		
	}, [activeStep, JSON.stringify( objChange.fac_taxonomy.steps)]);

	
	const saveTaxo = async ( objChange) => {
		if( props.fctOnSubmit) {
			//var och = objChange;
			//och.fac_taxonomy.done = true;
			//setObjChange( och);
			
			return await props.fctOnSubmit( objChange);
		}
	}				

	
	const validateStep = ( st) => {
		console.log( 'validateStep', [st, objChange.fac_taxonomy.steps[st].result]);
				
		if( st===0 || objChange.fac_taxonomy.steps[st].result != undefined)
			return true;
		
		return false;
	}	
	
	
	const saveStep = async ( st) => {
		var och = JSON.parse( JSON.stringify( objChange));
		och.fac_taxonomy.done = false;
		och.fac_taxonomy.activeStep = st;
		setObjChange( och);
		//setObjEdit( och);				
		await saveTaxo( och)
		
		document.getElementById( 'step_'+activeStep).scrollIntoView( { behavior: 'smooth', block: 'center' });
	}	
	
	
	const handleNext = () => {
		saveStep( activeStep+1);
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		saveStep( activeStep-1);
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};
		
	
	const validate = () => {				
		var ret = true;		
		return ret;
	};			
	
			
	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		validate: validate
	}));	
	
	
	const validateNextButton = ( st, result) => {
		
		console.log( 'validateNextButton', [st, result]);
		
		var ret = false;

		if( st===0) {
			if( formRef && formRef.current)
				ret = formRef.current.validateForm();
		} else if( result!==undefined) {
			ret = true;
		}
		
		setValidNext( ret);
	}
		
	
	const getStepSchema = ( txt) =>{		
		var oSch = {type: "object"};
		try {		
			if( txt.des_data)
				oSch = txt.des_data.schema ? txt.des_data.schema : oSch;			
			
		} catch( e) {
			console.error( 'getStepSchema ERR', e);	
		}
		console.log( 'step text schema', oSch);
		return oSch;
	}
	
	
	const getStepUiSchema = ( txt) =>{		
		var oUiSch = {};		
		try {
			if( txt.des_data)
				oUiSch = txt.des_data.ui_schema ? txt.des_data.ui_schema : oUiSch;			
		} catch( e) {
			console.error( 'getStepUiSchema ERR', e);	
		}		
		console.log( 'step text ui_schema', oUiSch);
		return oUiSch;				
	}	
	
	
	function transformErrors(errors) {
		return errors.map(error => {
			if (error.name === "required") {
				error.message = "Du must einen Wert eingeben!"
			}
			return error;
		});
	}	
	
	
	function customValidate(formData, errors) {
		if (!formData.fac_bgf) {
			errors.fac_bgf.addError( "Die Eingabe muss positiv sein!");
		}
		return errors;
	}	
	
	
	function ErrorListTemplate(props: ErrorListProps) {
		const { errors } = props;
		return (
			<div style={{color:'#d00'}}>
				Deine Eingabe ist nicht vollständig oder ist fehlerhaft!
			</div>
		);
	}	
	
	
	return (
		<>
		<Box sx={{ marginTop:70, padding:10 }}>
			{isLoading ?
				<CircularProgress className={classes.uiProgess} />
			:	
			<Stepper activeStep={activeStep} orientation="vertical">
				{objChange.fac_taxonomy.steps.map((step, index) => (
					<Step key={step.title}>	
					<div id={'step_'+index}>
					<ButtonBase onClick={()=>{
						if( index <= activeStep)
							setActiveStep( index);
					}}>
						<StepLabel>
							{step.title}
							{step.desc ?
								<QeeTooltip title={step.desc}>
									<IconButton>
										<InfoOutlinedIcon style={{color:'#a1bda1'}}/>
									</IconButton>
								</QeeTooltip>										
							:''}						
						</StepLabel>
					</ButtonBase>
					</div>
					<StepContent>
						{(() => {					
							if( step.type==='decision')
								return (
									<>
									<Box sx={{ mb: 3, mt: 3 }}>
										<Typography className="content" dangerouslySetInnerHTML={{__html: step.question}}></Typography>
									</Box>
									<div id={'step'+index} className={classes.validationContainer}>
										<QeeRadioGroup  style={{paddingTop:0}}
											defaultValue={objChange.fac_taxonomy.steps[index].result}
											fctOnChange={( ev)=>{
												console.log( 'onChange '+index, ev.target.value);
												
												var och = JSON.parse( JSON.stringify( objChange));								
												och.fac_taxonomy.steps[ index].result = ev.target.value;
												och.fac_taxonomy.steps[ index].done = true;
												console.log( 'SETTED OBJ CHANGE', och);
												setObjChange( JSON.parse( JSON.stringify( och)));												
											}}																																			
											hasDesc={false}
											sdata={[
												{value:'1', label:'Ja'},
												{value:'0', label:'Nein'},
												{value:'-1', label:'Weiß nicht'}
											]}
										/>
										{ gstate.objErrors['fac_taxonomy_step_'+index] ? ( <div style={{textAlign:"center"}}><small id="selError" className="form-text error">{gstate.objErrors['fac_taxonomy_step_'+index]}</small></div> ) :( <></> ) }							
										{step.has_sdoc ?	
											<div style={{marginLeft:10}}>
											<QeeFileInput 
												compId={''+step.sdoc_subtype}
												facId={gstate.objEdit.id}
												buttonText="Dateien hochladen"
												keyItm={['sdoc_type','sdoc_subtype']}
												keyVal={[step.sdoc_type,step.sdoc_subtype]}
												sdoc_type={step.sdoc_type}
												sdoc_subtype={step.sdoc_subtype}
											/>
											</div>
										:''}
									</div>
									</>
								)
							
							else if( step.type==='rectangle')
								return (
									<>
									<Form ref={formRef}
										formData={objChange}
										schema={getStepSchema( step.text)}
										uiSchema={getStepUiSchema( step.text)}
										validator={validator}
										transformErrors={transformErrors}
										customValidate={customValidate}	
										templates={{ ErrorListTemplate }}										
										liveValidate={false}
										noHtml5Validate={true}
										children={true}
										onChange={( ev)=>{
											console.log("changed", ev);
											var och = JSON.parse( JSON.stringify( ev.formData));								
											och.fac_taxonomy.steps[ index].done = true;
											console.log( 'SETTED OBJ CHANGE', och);
											setObjChange( JSON.parse( JSON.stringify( och)));
											
											setValidNext( formRef.current.validateForm());
										}}
										onSubmit={handleNext}
										onError={console.log("errors")} />
									</>
								)						
						})()}
						<Box sx={{ mb: 2 }}>
							<div>
								<QeeColorButtonPrimary
									disableElevation 
									disabled={!validNext}
									onClick={index != objChange.fac_taxonomy.steps.length-1 ? handleNext : async () => {
										var ret = await saveTaxo( objChange); 
										if( ret)
											setOpenQeeDialog( false);
									}}
									sx={{ mt: 1, mr: 1 }}
								>
								{index === objChange.fac_taxonomy.steps.length - 1 ? 'Berechnung starten' : 'Weiter'}
								</QeeColorButtonPrimary>
								<QeeColorButtonSecondary
									disableElevation 								
									disabled={index === 0}
									onClick={handleBack}
									sx={{ mt: 1, mr: 1 }}
								>
								Zurück
								</QeeColorButtonSecondary>
							</div>
						</Box>
					</StepContent>
					</Step>
				))}
			</Stepper>
			}

		</Box>		
		</>
		);
		
	});
	/******* End VerticalLinearStepper */	
	
	
	return (		
		<>
		<div ref={forwardedRef}></div>			
			<QeeSlideDialog 
				id='dlgTaxonomyWiz'
				title='Taxonomie-Assistent' 
				formComponent={
					<div>
					{state.isLoading ? 
						<div className={classes.root}>
							<CircularProgress size={50} className={classes.uiProgess} />
						</div>		
					: 
					<>
						<VerticalLinearStepper 
							ref={refStepper} 
							fctOnSubmit={onSubmit}
						/>	
					</>
					
					
					}
					</div>
				}	
				hideDialogActions={true}				
				submitDisabled={true}
				paddingContent={0}
			>
			</QeeSlideDialog>		
		</>	
	)
})	
export default withStyles(styles)(QeeTaxonomyDialog);