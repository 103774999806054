// facs.js

import React, { Dimensions, Component } from 'react';
import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Box from '@mui/material/Box';
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from 'notistack';

import FacWiz from './facWiz';
import FacForm from './facForm';
import FacsEmpty from './facsEmpty';
import FacsSharedEmpty from './facsSharedEmpty';
import FacCard from './facCard';

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';
import useWindowDimensions from '../util/windowDimensions';
import { useNavigate, useLocation } from "react-router-dom";

import { facDef, facInit } from '../common/models/fac'; 

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0),
			//width:'90%',
		}
	},
	appBar: {
		position: 'relative'
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	submitButton: {
		display: 'block',
		color: 'white',
		textAlign: 'center',
		position: 'absolute',
		top: 14,
		right: 10
	},
	floatingButton: {
		position: 'fixed',
		bottom: 0,
		right: 0
	},
	/*
	dialog: {
		width: '50%',
		position: 'absolute',
		left: 10,
		top: 50,			
		//maxWidth: 1200,		 
	},
	*/
	form: {
		//width: '98%',
		marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	//root: {
	//	minWidth: 470
	//},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)'
	},
	pos: {
		marginBottom: 12
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	/*
	dialogeStyle: {
		maxWidth: '50%'
	},
	*/
	viewRoot: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	
	newPosOfDialog: {		
		[theme.breakpoints.up('lg')]: {
			transform: "translate(100%, 0%)",
		},			
	},
	dialog: {
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},			
		position: 'absolute',
		left: 10,
		top: 50,			
	},
	dialogStyle: {
		maxWidth: '50%',
		[theme.breakpoints.down('md')]: {
			maxWidth: '100%'
		},						
	},				
});


// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


// main fct for facs
const Facs = ( props) => {
	
	const confirm = useConfirm();	
	const { enqueueSnackbar } = useSnackbar();
	
	const { gstate, setFacs, setOpenNewFac, setSubmitNewFac, setObjEdit, setObjErrors, setIsFetching } = useAuth();		
		
	const [state, setState] = useState({	
		screenDims: {width:0, height:0},
		//facs: '',
		open: false,
		uiLoading: true,
		buttonType: '',
		viewOpen: false,
		reloadFacs: false	
	})	
	state.screenDims = useWindowDimensions();
	console.log( 'scr', state.screenDims);
			
	const navigate = useNavigate();
	const location = useLocation();	
		
	useEffect(() => {
		const _fetchData = async () => {
			setAxiosAuthToken();
			console.log('>>> Axios header', axios.defaults.headers.common);

			let url = '/api/facs';
			if (gstate.facsType === 'sharedFacsAll') {
				url = '/api/facsSharedAll';
			}

			setState({
				...state,
				uiLoading: true
			});
			//setIsFetching( true)

			try {
				const response = await axios.get(url);
				console.log('response', response);
				setLocalStorageAuthToken(response);
				setFacs(response.data);
				
				if (gstate.openNewFac) {
					handleClickOpen();
				}

				setState({
					...state,
					uiLoading: false,
					reloadFacs: false 
				});
				//setIsFetching( false);
			} catch (err) {
				console.log(err);
				setState({
					...state,
					uiLoading: false,
					reloadFacs: false 
				});
				//setIsFetching( false);				
			}
		};

		_fetchData();
		
	}, [gstate.openNewFac, gstate.facsType, state.reloadFacs]); // used for open new fac from fav

	
	// some consts
	dayjs.extend( relativeTime);
	// reposition the dialog (must not be fullScreen)
	const useStyles = makeStyles({
		...props,	
	});	
	//const { classes } = props;
	const classes = useStyles( props);
	const { open, errors, viewOpen } = state;
	
	
	const hasResults = () => {		
		console.log( 'hasResults', gstate.facs);
		try {		
			if( gstate.facs.entries.results.length) {
				return true;			
			}			
			console.log( 'hasResults: false');
			return false;					
		} catch( err) {
			console.error( 'hasResults ERR', err);
			return false;	
		}
	}
	
	
	const handleChange = (event) => {		
		console.log( 'change: ' + event.target.name, event.target.value);		
		var fac = gstate.objEdit;
		fac[event.target.name] = event.target.value;
		
		setObjEdit( fac);
	};

	
	const deleteFacHandler = (data) => {
		//authMiddleWare(this.props.history);
		confirm({ 
				title: "Immobilienprojekt löschen", 
				description: "Hierdurch werden alle Daten für dieses Immobilienprojekt, einschließlich verschachtelter Daten, endgültig gelöscht.",
				confirmationText: "Löschen starten",				
				cancellationText: "Abbrechen",				
				confirmationButtonProps: {color:"error", variant:"contained", style:{textTransform: 'none'}},
				cancellationButtonProps: {color:"success", style:{textTransform: 'none'}}								
			})
			.then(() => {
				const authToken = localStorage.getItem('AuthToken');
				const refreshToken = localStorage.getItem( 'RefreshToken');
				axios.defaults.headers.common = { Authorization: `${authToken}`, RefreshToken: refreshToken};		
				let id = data.id;
				axios
					.delete(`/api/fac/${id}`)
					.then(() => {
						window.location.reload();
					})
					.catch((err) => {
						console.log(err);
					});			
			})
			.catch(() => {			
			});
	}

	
	const handleEditClickOpen = (data) => {		
		console.log( 'handleEditClickOpen', data);		
		var fac = JSON.parse( JSON.stringify( data));   // deep copy		
		setState({
			...state,
			buttonType: 'Edit',
			open: true
		});		
		setObjEdit( fac);
	}

	
	const handleViewOpen = (data) => {
		setObjEdit( data);	
		navigate( '/fac?id='+data.id+'#loadFacDashboard');
	}	


	// dialog operations
	const DialogTitle = withStyles(styles)((props) => {
		const { children, classes, onClose, ...other } = props;
		return (
			<MuiDialogTitle disableTypography className={classes.root} {...other}>
				<Typography variant="h6">{children}</Typography>
				{onClose ? (
					<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
		);
	});

	const DialogContent = withStyles((theme) => ({
		viewRoot: {
			padding: theme.spacing(2)
		}
	}))(MuiDialogContent);


	const handleClickOpen = () => {
		
		console.log( 'handleClickOpen with tpl', gstate.openNewFac);
		
		const fac = {...facInit(), ...gstate.openNewFac};
		
		setState({
			...state,
			buttonType: '',
			open: true
		});

		setObjEdit( fac);
		setOpenNewFac( null);		
	};
	
	
	/**
	*	Submits the new or exiting fac
	*/
	const handleSubmit = async (prjType) => {
		console.log('handleSubmit', prjType);

		const authToken = localStorage.getItem('AuthToken');
		const refreshToken = localStorage.getItem('RefreshToken');
		// if (authToken == null) navigate('/login');  

		let newFac = gstate.objEdit;
		console.log('submit obj', newFac);

		axios.defaults.headers.common = { Authorization: `${authToken}`, RefreshToken: refreshToken };
		let options = {};
		// --- for editing fac
		if (state.buttonType === 'Edit') {
			let id = gstate.objEdit.id;
			options = {
				url: `/api/fac/${id}`,
				method: 'put',
				data: newFac
			};
			try {
				let ret = await axios(options);
				let fac = ret.data.data;
				console.log('>>> RET FAC', fac)
				setObjEdit(fac);

				setState({
					...state,
					open: false
				});
			} catch (error) {
				setState({
					...state,
					open: true,
					errors: error.response.data
				});
				setObjErrors(error.response.data);
				console.log(error);
			}

		// --- for creating fac	
		} else {
			options = {
				url: '/api/fac',
				method: 'post',
				data: newFac
			};
			try {
				let ret = await axios(options);
				let fac = ret.data.data;
				console.log('>>> RET FAC', fac)
				setObjEdit( fac);

				setState({
					...state,
					open: false,
					reloadFacs: true,
					openNextDialog: fac.fac_prj_type ? true : false 
				});
				
				setSubmitNewFac( fac);

			} catch (error) {
				setState({
					...state,
					open: true,
					errors: error.response.data
				});
				setObjErrors(error.response.data);
				console.log(error);
			}
		}
	};

	useEffect(() => {
		if (gstate.submitNewFac) {
			navigate( '/fac?id=' + gstate.objEdit.id + '#loadFacDashboard');			
		}
	}, [gstate.submitNewFac]);


/*	
	useEffect(() => {
		if (state.buttonType !== 'Edit' && state.openNextDialog && gstate.objEdit.id) {
			//navigate( '/fac?id='+gstate.objEdit.id+'#loadFacDashboard');
			let prjType = gstate.objEdit.fac_prj_type;
			setTimeout(() => {
				if (prjType === 'newbuild' && refNewbuildDialog.current) {
					refNewbuildDialog.current.handleNewbuildOpen(0);
				} else if (prjType === 'renovation' && refRenovationDialog.current) {
					refRenovationDialog.current.handleRenovationOpen(0);
				} else {
					//window.location.reload();
				}

				// Reset openNextDialog to avoid repeated triggers
				setState((prevState) => ({ ...prevState, openNextDialog: false }));
			}, 0);
		}
	}, [state.buttonType, state.openNextDialog, gstate.objEdit.id, gstate.objEdit.fac_prj_type]);
*/	
	
	const handleViewClose = () => {
		setState({ 
			...state,
			viewOpen: false 
		});
	};

	
	const handleClose = (event) => {
		setState({ 
			...state,
			open: false 
		});
	};
		
		
	return (
		<>
			{state.uiLoading ? (
				<div className={classes.root}>
					{state.uiLoading && <CircularProgress size={gstate.isLG ? 100 : 50} className={classes.uiProgess} />}
				</div>
			) : (			
				<main className={classes.content}>
					<div className={classes.toolbarxxx} />
					{!hasResults() ? (
						gstate.facsType === 'myFacs' ? (
							<FacsEmpty fctNew={handleClickOpen} fctSearch={() => navigate('/facssearch')} />
						) : (
							<FacsSharedEmpty />
						)
					) : (
						<Grid container spacing={2} style={{ maxWidth: 1780 }}>
							{gstate.facs.entries.results.map((fac) => (
								<Grid item xs={12} sm={12} md={12} lg={9} xl={6} key={fac.id}>
									<FacCard
										fac={fac}
										handleViewOpen={handleViewOpen}
										deleteFacHandler={deleteFacHandler}
									/>
								</Grid>
							))}
						</Grid>
					)}

					{gstate.facsType === 'myFacs' && (
						<IconButton
							className={classes.floatingButton}
							color="primary"
							aria-label="Add Fac"
							onClick={handleClickOpen}
						>
							<AddCircleIcon style={{ fontSize: 60 }} />
						</IconButton>
					)}

					<Dialog
						fullScreen
						classes={{
							paper: classes.newPosOfDialog,
							paperFullWidth: classes.dialogStyle
						}}
						className={classes.dialog}
						open={open}
						onClose={handleClose}
						TransitionComponent={Transition}
					>
						<AppBar className={classes.appBar}>
							<Toolbar>
								<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
									<CloseIcon />
								</IconButton>
								<Typography variant="h6" className={classes.fac_name}>
									{state.buttonType === 'Edit' ? 'Immobilie bearbeiten' : 'Immobilie erfassen'}
								</Typography>
							</Toolbar>
						</AppBar>
						{state.buttonType === 'Edit' ? (
							<FacForm fctSubmit={handleSubmit} fctClose={handleClose} />
						) : (
							<FacWiz fctSubmit={(prjType) => handleSubmit(prjType)} />
						)}
					</Dialog>

					<Dialog
						onClose={handleViewClose}
						aria-labelledby="customized-dialog-title"
						classes={{
							paper: classes.newPosOfDialog
						}}
						className={classes.dialog}
						open={viewOpen}
						fullWidth
					>
						<DialogTitle id="customized-dialog-title" onClose={handleViewClose}>
							{gstate.objEdit.fac_name}
						</DialogTitle>
						<DialogContent dividers>
							<TextField
								fullWidth
								id="facDetails"
								name="fac_desc"
								multiline
								readOnly
								rows={1}
								rowsMax={25}
								value={gstate.objEdit.fac_desc}
								InputProps={{
									disableUnderline: true
								}}
							/>
						</DialogContent>
					</Dialog>
				</main>
			)}
		</>
	);

}	
export default withStyles(styles)(Facs);