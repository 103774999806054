import React from 'react';
import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Link, Button, Switch, Typography, FormControl, FormGroup, FormHelperText, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import QeeTooltip from './qeeTooltip'; // Adjust this import based on your project structure
import QeeHintBox from './qeeHintBox';
import DiagramEnergyClass from './diagramEnergyClass';
import DiagramDonutChart from './diagramDonutChart';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import QeeRadioGroupEditor from './qeeRadioGroupEditor';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckIcon from '@mui/icons-material/Check';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import FormLabel from '@mui/material/FormLabel';
import { formatNumber } from '../util/helpers'; // Import your formatNumber function

import structureIcon from '../assets/structure.svg';
import roofIcon from '../assets/roof.svg';
import fassadeIcon from '../assets/fassade.svg';
import windowIcon from '../assets/window.svg';
import basementIcon from '../assets/basement.svg';
import heatingIcon from '../assets/heating.svg';
import pvroofIcon from '../assets/pvroof.svg';
import electricIcon from '../assets/electric.svg';
import sanitarieIcon from '../assets/sanitarie.svg';
import bathIcon from '../assets/bath.svg';
import restroomIcon from '../assets/restroom.svg';
import exteriorpaintingIcon from '../assets/exteriorpainting.svg';
import sunblindeIcon from '../assets/sunblinde.svg';
import terraceIcon from '../assets/terrace.svg';
import landscapingIcon from '../assets/landscaping.svg';
import garageIcon from '../assets/garage.svg';
import carportIcon from '../assets/carport.svg';

import structureIconDisabled from '../assets/structure_disabled.svg';
import roofIconDisabled from '../assets/roof_disabled.svg';
import fassadeIconDisabled from '../assets/fassade_disabled.svg';
import windowIconDisabled from '../assets/window_disabled.svg';
import basementIconDisabled from '../assets/basement_disabled.svg';
import heatingIconDisabled from '../assets/heating_disabled.svg';
import pvroofIconDisabled from '../assets/pvroof_disabled.svg';
import electricIconDisabled from '../assets/electric_disabled.svg';
import sanitarieIconDisabled from '../assets/sanitarie_disabled.svg';
import bathIconDisabled from '../assets/bath_disabled.svg';
import restroomIconDisabled from '../assets/restroom_disabled.svg';
import exteriorpaintingIconDisabled from '../assets/exteriorpainting_disabled.svg';
import sunblindeIconDisabled from '../assets/sunblinde_disabled.svg';
import terraceIconDisabled from '../assets/terrace_disabled.svg';
import landscapingIconDisabled from '../assets/landscaping_disabled.svg';
import garageIconDisabled from '../assets/garage_disabled.svg';
import carportIconDisabled from '../assets/carport_disabled.svg';

import { useConfluence } from "../components/qeeConfluenceProvider"; 
import { useAuth } from "../context/provider"; 
import {usrRight} from '../util/helpers';

import { 
	getRoofType, 
	getHeatingType, 
	getSdatas
} from "../api/service";


const FacPlanNewbuildsSelect = (props) => {
	
    const { setOpenConfluenceDialog } = useConfluence();
    const { gstate, setObjEdit, setOpenItemEditor } = useAuth();
	
	const [itemEditorData, setItemEditorData] = useState({
		first:{
			title:'', 
			formSublabel:'',
			defaultValue: '',
			fctOnChange: null,
			sdataName: ''
		}
	});		
	
	const [state, setState] = useState({	
		screenDims: {width:0, height:0},
		openEditor: false,
	})		


	useEffect(()=> {
		console.log( 'useEffect for itemEditorSdata', itemEditorData);
		if( itemEditorData.first.sdataName != '')
			setOpenItemEditor( true);	
	}, [itemEditorData.first.sdataName]);
		
		
	const handleEditorClose = (event) => {
		setState({ 
			...state,
			openEditor: false 
		});

		setItemEditorData({
			first:{
				sdataName:''	
			},
			second:{
				sdataName:''	
			},			
		});
	};		


	// some consts
	const useStyles = makeStyles( theme => ({
		//...props
		accordionSummary: {
			backgroundColor: '#f00'
		},
		accordionBox: {
			fontWeight:'bold', 
			m:1, 
			lineHeight:1.2, 
			color:theme.palette.primary.contrastText,
		},
		icon:{
			color:theme.palette.primary.contrastText,
		},
		iconDark:{
			color:theme.palette.primary.main,
		},
		iconGreen:{
			color:theme.palette.primary.main,
		},		
		iconRed:{
			color:'red',
		},	
		iconGrey:{
			color:'grey',
		},		
		textOutput:{
			margin:20,
			minWidth: 80
		},
		resize:{
			fontWeight:800,
		},		
		subTitle: {
			color: '#444',
			fontWeight: 'bold'
		},
		miniLabelRed: {
			whiteSpace: 'nowrap',
			fontSize: 12,			
			color: 'red',
		},		
		miniLabelGreen: {
			whiteSpace: 'nowrap',
			fontSize: 12,						
			color: 'green',
		},				
		box: {
			//width:500, 
			//height:300,
			backgroundColor:theme.palette.primary.contrastText,
			border: 'solid 1px #E4EAE6',
			padding:20,
			marginTop:20,
			[theme.breakpoints.down('xs')]: {
				padding:5,
			},
		},
		boxPercentage: {
			backgroundColor:theme.palette.secondary.main,
			color:theme.palette.secondary.dark,
			border: 'solid 1px ' + theme.palette.secondary.dark,
			padding:5,
			margin:10,
			width:72,
			display: 'inlineBlock',
			textAlign: 'center',
			fontWeight: 900
		},				
		boxPercentageGreen: {
			backgroundColor:'green',
			color:theme.palette.primary.contrastText,
			//border: 'solid 1px ' + theme.palette.secondary.main,
			padding:5,
			margin:10,
			width:72,
			display: 'inlineBlock',
			textAlign: 'center',
			fontWeight: 900,
			borderRadius: 5,
		},		
		boxPercentageRed: {
			backgroundColor:'red',
			color:theme.palette.primary.contrastText,
			//border: 'solid 1px ' + theme.palette.secondary.main,
			padding:5,
			margin:10,
			width:72,
			display: 'inlineBlock',
			textAlign: 'center',
			fontWeight: 900,
			borderRadius: 5,			
		},				
		switchTrack: theme.components.Switch.switchTrack,
		switchBase: theme.components.Switch.switchBase,
		switchPrimary: theme.components.Switch.switchPrimary,
		tableContainer: {
			[theme.breakpoints.down('xs')]: {
				width:380,
			},			
		},
		resultCard: {
			display: 'flex', 
			width:'100%',
			[theme.breakpoints.down('xs')]: {
				width:340,
			},
			height:120,
			padding:10,
			marginBottom: 10,
			borderRadius: 5,
			border: `1px solid ${theme.palette.primary.main}`,					
		},
		resultBox: {
			padding:25,
			[theme.breakpoints.down('xs')]: {
				padding:10,
			},
			marginBottom: 10,			
			borderRadius: 5,
			border: `1px solid ${theme.palette.primary.main}`,		
		},
		donutChartContainer: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%'
		},		
		textIst: {
			color:theme.typography.error.color,
			fontWeight: 800,
		},
		textPlan: {
			color:theme.typography.success?.color,
			fontWeight: 800,			
		},
		appBar: {
			position: 'relative'
		},
		editButton: {
			color:theme.palette.secondary.contrastText,			
		},
		containerUpgrade: {
			position: 'relative'
		},
		boxUpgrade: {
			position: 'absolute',
			top: 0,
			left: 0,
			opacity: 0.8	
		},	
		overlayUpgrade: {
			zIndex: 9,
			//margin: 20			
		},
		floatingButton: {
			position: 'fixed',
			bottom: 0,
			right: 0,
			zIndex: 2
		},		
	}));			
	const classes = useStyles( props);

	const sxTableCell = gstate.isXS ? 
	{
		fontSize: 16,
		fontWeight: 700,
		color: '#444'
	} 
		:
	{
		fontSize: 16,
		fontWeight: 800,
		color: '#444'
	}		
	
	
	/**
	*	isNewbuildCond() - checks the configurated condition, if any
	*/
	const isNewbuildCond = ( r) => {		
		var fac = gstate.objEdit;
		if( r.cond) {   // if some cond specified as string
			var code = "(function(){console.log( 'r.cond', '"+r.cond+"'); return " + r.cond + "})";
			//console.log( 'r.cond code string', code);				
			var f = eval( code);
			//console.log( 'r.cond evaluated code', f.call( r));
			return f.call( r);		
		} else {
			return true;   // if no cond specified
		}
	}	
	
	
	const handleOnChangePlanNewbuilds = (ev) => { 
		console.log( 'handleOnChangePlanNewbuilds: ', ev);
		
		var prs = JSON.parse( JSON.stringify( gstate.objEdit.fac_plan_newbuilds));
		
		prs.map((r, i) => {
			//console.log( 'elem', r.name + '-' + r.value);
			if( r.name === ev.target.name) {
				console.log( 'name', r.name);
				console.log( 'value', ev.target.value);
				console.log( 'checked', ev.target.checked);
				r.value = (ev.target.checked == true ? '1' : '0');
				if( r.value == '0') {
				}
			} 
		})		
		
		setObjEdit({
			...gstate.objEdit,
			fac_plan_newbuilds: prs
		});
	}		
	

	const handleOnChangePlanNewbuildsBlock = (ev) => { 
		// do nothing
		alert( 'Dieser Bauteil wurde durch eine Regel/Gesetz (z.B. durch die Aktivierung eines anderes Bauteiles) automatisch aktiviert und kann nicht manuell geändert werden.');
	}	
	
	
	const handleOnChangePlanNewbuilds2 = (ev) => { 
		console.log( 'handleOnChangePlanNewbuilds2: ', ev);
		
		var prs2 = JSON.parse( JSON.stringify( gstate.objEdit.fac_plan_newbuilds2));
		
		prs2.map((r, i) => {
			//console.log( 'elem', r.name + '-' + r.value);
			if( r.name === ev.target.name) {
				console.log( 'name', r.name);
				console.log( 'value', ev.target.value);
				console.log( 'checked', ev.target.checked);
				r.value = (ev.target.checked == true ? '1' : '0');
			} 
		})		
		
		setObjEdit({
			...gstate.objEdit,
			fac_plan_newbuilds2: prs2
		});
	}	


	/**
	*	handlePlanNewbuildChange() - save the changes in the state
	*/
	const handlePlanNewbuildChange = (sdataName, vl, idx) => { 
		console.log( '--> handlePlanNewbuildChange', {'sdataName':sdataName, 'vl':(vl), 'idx':idx});

		var planNewbuilds = gstate.objEdit.fac_plan_newbuilds;
		var planNewbuilds2 = gstate.objEdit.fac_plan_newbuilds2;
		
		var sdn = sdataName.first;
		var val = vl.first;		
		if( sdn === 'sdata_structures_types') {
			planNewbuilds[0].type = val;								
		} else if( sdn === 'sdata_roofs_types') {
			planNewbuilds[1].type = val;
		} else if( sdn === 'sdata_fassades_types') {
			planNewbuilds[2].type = val;			
		} else if( sdn === 'sdata_windows_types') {
			planNewbuilds[3].type = val;						
		} else if( sdn === 'sdata_basements_types') {
			planNewbuilds[4].type = val;									
		} else if( sdn === 'sdata_heatings_types' || sdn === 'availableHeatingsTypes') {
			planNewbuilds[5].type = val;			
		} else if( sdn === 'sdata_pvroofs_types') {
			planNewbuilds[6].type = val;			
		} else {
			planNewbuilds2[idx].type = val;			
		}
		
		if( sdataName.second && vl.second) {
			var sdn = sdataName.second;
			var val = vl.second;		
			if( sdn === 'sdata_structures_qualities') {
				planNewbuilds[0].quality = val;														
			} else if( sdn === 'sdata_roofs_qualities') {
				planNewbuilds[1].quality = val;
			} else if( sdn === 'sdata_fassades_qualities') {
				planNewbuilds[2].quality = val;			
			} else if( sdn === 'sdata_windows_qualities') {
				planNewbuilds[3].quality = val;						
			} else if( sdn === 'sdata_basements_qualities') {
				planNewbuilds[4].quality = val;									
			} else if( sdn === 'sdata_heatings_qualities') {
				planNewbuilds[5].quality = val;			
			} else if( sdn === 'sdata_pvroofs_qualities') {
				planNewbuilds[6].quality = val;							

			} else {
				planNewbuilds2[idx].quality = val;			
			}		
		}
			
		setObjEdit({
			...gstate.objEdit, 
			fac_plan_newbuilds: planNewbuilds,
			fac_plan_newbuilds2: planNewbuilds2
		})
	}	
	
		
	/**
	*	LabelTableCell() - general label comp, with separate render for fac_plan_newbuilds and fac_plan_newbuilds2
	*/	
	const LabelTableCell = withStyles()(
		({ reno, idx, showEditButton, classes }) => {
			//console.log('LabelTableCell', reno, idx, showEditButton);
			let ret = <div>{reno.label}</div>;

			const discComponent = (
				<div style={{ display: 'inline' }}>{getDiscIcon(reno)}</div>
			);

			const conditionalContent = (typeLabel, itemIndex) => (
				<Grid container direction="row" spacing={1}>
					<Grid item xs={4}>{discComponent}</Grid>
					<Grid item xs={8}>
						<div className={classes.boldText}>{ret}</div>
						<div className={classes.smallText}>
							<Grid container direction="row">
								<Grid item xs={10}>
									<NewbuildTypeLabel reno={gstate.objEdit[`fac_plan_newbuilds${itemIndex ? '2' : ''}`][idx]} disc={reno.name.slice(15)} />
								</Grid>
								<Grid item xs={2}>
									{showEditButton && (usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer') &&
										<Button variant="text" className={classes.button}
											onClick={() => setItemEditorData({
												first: {
													title: `${typeLabel} Typ`, 
													formSublabel: `Welcher ${typeLabel} Typ soll eingesetzt werden?`,
													defaultValue: gstate.objEdit[`fac_plan_newbuilds${itemIndex ? '2' : ''}`][idx].type,
													fctOnChange: handlePlanNewbuildChange,
													sdataName: getSdataName(reno.name, 'types')
												},
												second: {
													title: "Qualitäten", 
													formSublabel: 'Welche Qualität soll eingesetzt werden?',
													defaultValue: gstate.objEdit[`fac_plan_newbuilds${itemIndex ? '2' : ''}`][idx].quality,
													fctOnChange: handlePlanNewbuildChange,
													sdataName: getSdataName(reno.name, 'qualities')
												}
											})
										}>
											<EditIcon />
										</Button>
									}
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
			);
			// threats the exception by *_windows whixh already is in plural!
			const getSdataName = (renoName, suffix) => {
				// Checking if the renovation name ends with 'windows', in which case we don't add an 's'
				const baseName = renoName.replace('has_renovation_', '');
				return `sdata_${baseName}${baseName.endsWith('windows') ? '' : 's'}_${suffix}`;
			};

			// Handling other renovation types that are not predefined
			if (['has_renovation_roof', 
				'has_renovation_fassade', 
				'has_renovation_windows', 
				'has_renovation_basement', 
				'has_renovation_heating', 
				'has_renovation_pvroof',
				'has_renovation_structure'
				].includes(reno.name)) {
				ret = conditionalContent(reno.label, 0);
			} else {
				// Handling everything else for reno2
				ret = <div className={classes.root}>
					<Grid container direction="row" spacing={1}>
						<Grid item xs={4}>
							{discComponent}
						</Grid>
						<Grid item xs={8}>
							<div className={classes.boldText}>{ret}</div>
							<div className={classes.smallText}>
								<Grid container direction="row">
									<Grid item xs={10}>
										<NewbuildTypeLabel reno={gstate.objEdit.fac_plan_newbuilds2[idx]} />
									</Grid>
									<Grid item xs={2}>
										{showEditButton && (usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer') &&
											<Button variant="text" className={classes.button}
												onClick={() => setItemEditorData({
													first: {
														idx: idx,
														title: reno.label, 
														formSublabel: `Welches ${reno.label}-System soll eingesetzt werden?`,
														defaultValue: gstate.objEdit.fac_plan_newbuilds2[idx].type,
														fctOnChange: handlePlanNewbuildChange,                                
														sdataName: 'sdata_' + reno.name.replace('has_renovation_', '') + 's_types'
													},
													second: {
														idx: idx,
														title: "Qualitäten", 
														formSublabel: 'Welche Qualität soll eingesetzt werden?',
														defaultValue: gstate.objEdit.fac_plan_newbuilds2[idx].quality,
														fctOnChange: handlePlanNewbuildChange,
														sdataName: 'sdata_' + reno.name.replace('has_renovation_', '') + 's_qualities'
													}
												})
											}>
												<EditIcon />
											</Button>
										}
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Grid>
				</div>;
			}

			return <div className={classes.root}>{ret}</div>;
		}
	);	
	
	
	const getDiscIcon = (reno) => {
		
		const icos = {
			structure: structureIcon,			
			roof: roofIcon,
			fassade: fassadeIcon,
			windows: windowIcon,
			basement: basementIcon,
			heating: heatingIcon,						
			pvroof: pvroofIcon,			
			electric: electricIcon,
			sanitarie: sanitarieIcon,
			bath: bathIcon,
			restroom: restroomIcon,
			exteriorpainting: exteriorpaintingIcon,
			sunblinde: sunblindeIcon,			
			terrace: terraceIcon,
			landscaping: landscapingIcon,			
			garage: garageIcon,			
			carport: carportIcon,						
		};		
		
		const icosDisabled = {
			structure: structureIconDisabled,			
			roof: roofIconDisabled,
			fassade: fassadeIconDisabled,
			windows: windowIconDisabled,
			basement: basementIconDisabled,
			heating: heatingIconDisabled,						
			pvroof: pvroofIconDisabled,			
			electric: electricIconDisabled,
			sanitarie: sanitarieIconDisabled,
			bath: bathIconDisabled,
			restroom: restroomIconDisabled,
			exteriorpainting: exteriorpaintingIconDisabled,
			sunblinde: sunblindeIconDisabled,			
			terrace: terraceIconDisabled,
			landscaping: landscapingIconDisabled,			
			garage: garageIconDisabled,			
			carport: carportIconDisabled,						
		};				
		
		let disc = reno.name.replace( 'has_renovation_', '');
		
		//console.log( '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> discIcon value' + reno.value, disc);
		if( reno.value == '1')							
			return <img src={icos[disc]} style={{width:50}}></img>
		else
			return <img src={icosDisabled[disc]} style={{width:50}}></img>
	}
	
	
	/**
	*	Component for async showing the device type
	*/
	const NewbuildTypeLabel = (props) => {
		const classes = useStyles();
		const [tp, setTp] = useState( '');
		const [qual, setQual] = useState( ''); 		
		
		var disc = props.disc;
		//alert( 'disc ' + disc);
		if( !disc)
			disc = props.reno.name.replace( 'has_renovation_', '');		
		
		// Important (!!) correction for the exception where window-s (which already is plural)
		if( disc==='windows')
			disc = 'window'
		
		useEffect(() => {
			let isMounted = true;
			
			async function getNewbuild() {
				if( props.reno.type) {														
					var arrRet = await getSdatas( 'sdata_'+disc+'s_types', 'type', props.reno.type);
					var tp = 'unbekannt';
					if( arrRet && arrRet.length>0) {
						tp = arrRet[0].name;		
					}
					if( isMounted) {
						setTp( tp);	
					}

					console.log( '>>> Get quality', props.reno.quality);					
					if( props.reno.quality) {
						arrRet = await getSdatas( 'sdata_'+disc+'s_qualities', ['parent_type', 'type'], [props.reno.type, props.reno.quality]);
						console.log( '>>> RET quality', arrRet);
						var q = '';
						if( arrRet && arrRet.length>0) {
							q = arrRet[0].name;						
						}
						if( isMounted) {
							setQual( q);
						}
					}				
				}
			}
			getNewbuild();
			
			return () => {
				isMounted = false; // Set flag to false when component unmounts
			};
		}, [])

		return (
			<div style={{width:200}}>
			{props.reno.value != '1' ? '' :
				<Grid container direction="column">
					<Grid item style={{fontSize:12}}>
						{tp}
					</Grid>						
					<Grid item style={{fontSize:12}}>
						{qual}
					</Grid>		
				</Grid>
			}	
			</div>
		)
	}
	
	
	const getTooltipOverdueOldness = ( r) => {
		return r.label+": Ist " + r.oldness + "Jahre alt. Eine Sanierung wird nach "+r.lifetime+" Jahren empfohlen!";
	}
	const getTooltipOverdueHtc = ( r) => {
		return r.label+": Um den selektierten KfW-Standard zu erreichen, muss diese Sanierung ausgeführt werden!";
	}		
	const getHintSupport = () => {
		return 'Details zu Förderangeboten, wie z.B. zinsgünstigen Darlehen, kannst du unter dem folgenden Link finden:'
	}	

		
	/**
	*	SwitchRighted - used for user rights purposes
	*/
	const SwitchRighted = withStyles()(
		({ classes, checked, ...props }) => {
			const right = usrRight( gstate.objEdit);
			const isOwner = right === 'owner';
			const isWriter = right === 'writer';
			const isEnabled = isWriter || isOwner;

			return (
				isEnabled && (
					<Switch
						{...props}
						checked={checked}
						classes={{
							track: classes.switchTrack,
							switchBase: classes.switchBase,
							colorPrimary: classes.switchPrimary,
						}}
						color={"default"}			  
					/>
				)
			);
		}
	);		
	
	
	/**
	*	TableFoundingDiscs - - dynamic table with the founding disciplines
	*/
	const TableFoundingDiscs = withStyles()(
		({ classes, ...props }) => {

		// local reno row/switch cell
		const SwitchTableCell = withStyles()(
			({ r, idx, classes, ...props }) => {
				
				return (				
					<>
					{gstate.objEdit.fac_energy.energyclass_plan === 'singles' ?
					<FormControlLabel style={{width:390}}
						checked={r.value === '1' ? true : false} 
						name={r.name}													
						control={
							r.disabled ?
							<>		
							<QeeTooltip title={'Dieser Bauteil wurde durch eine Regel/Gesetz (z.B. durch die Aktivierung eines anderes Bauteiles) automatisch aktiviert.'}> 
							<SwitchRighted 
								checked={r.value === '1' ? true : false}
								onChange={handleOnChangePlanNewbuildsBlock}																
								classes={{
									track: classes.switchTrack,
									switchBase: classes.switchBase,
									colorPrimary: classes.switchPrimary,
								}}
								color={"default"}																
							/>
							</QeeTooltip>
							</>																
							:
							<SwitchRighted
								checked={r.value === '1' ? true : false}
								onChange={handleOnChangePlanNewbuilds}																
								classes={{
									track: classes.switchTrack,
									switchBase: classes.switchBase,
									colorPrimary: classes.switchPrimary,
								}}
								color={"default"}																
							/>
						}
						label={<LabelTableCell 
                            reno={r} 
                            idx={idx} 
                            showEditButton={r.value === '1'} 
							{...props}
							classes={classes}
                        />}
					/>
					:
					<Typography sx={{paddingLeft:1}}>{
						<LabelTableCell 
                            reno={r} 
                            idx={idx} 
                            showEditButton={r.value === '1'} 
							{...props}
							classes={classes}							
						/>}
					</Typography>
					}
					</>
				)			
				
			}
		)	


		// local reno row/oldness cell
		const OldnessTableCell = withStyles()(
			({ r, classes, ...props }) => {
				
				return (				
					<>
					{gstate.objEdit.fac_energy.energyclass_plan === 'singles' ? 
						<div style={{display: 'flex', alignItems: 'center'}}>
							{r.overdue==='1' ? 
								<QeeTooltip title={getTooltipOverdueOldness(r)}> 
								<div style={{display:'block', textAlign:'center'}}>
									<FmdBadIcon sx={{fontSize:32, fontWeight:800, color:'red'}}/>
									<div className={classes.miniLabelRed}>Sanierung</div>
									<div className={classes.miniLabelRed}>Alter {r.oldness} Jahre</div>
								</div>
								</QeeTooltip>
								:
								<QeeTooltip title={getTooltipOverdueOldness(r)}> 
									<div style={{display:'block', textAlign:'center'}}><CheckIcon sx={{fontSize:32, fontWeight:800, color:'green'}}/><div className={classes.miniLabelGreen}>In Ordnung</div></div>
								</QeeTooltip>
							}
						</div>
						:
						<div style={{display: 'flex', alignItems: 'center'}}>
							{r.overdue==='1' ? 
								<QeeTooltip title={getTooltipOverdueHtc(r)}> 
								<div style={{display:'block', textAlign:'center'}}>
									<FmdBadIcon sx={{fontSize:32, fontWeight:800, color:'red'}}/>
									<div className={classes.miniLabelRed}>Sanierung</div>
								</div>
								</QeeTooltip>
								:
								<div style={{display:'block', textAlign:'center'}}><CheckIcon sx={{fontSize:32, fontWeight:800, color:'green'}}/><div className={classes.miniLabelGreen}>In Ordnung</div></div>																							
							}
						</div>
					}
					</>
				)			
			}
		)


		return (			
			<FormControl component="fieldset" variant="standard" sx={{width:'100%'}}>
				
				<FormHelperText><Typography variant='subtitle2'>Die geplanten Neubaumaßnahmen spiegeln sich in der Energieeffizeinzklasse, den CO2-Austoß und in den Kosten wider.</Typography></FormHelperText>
				
				<FormGroup>						
					<TableContainer className={classes.tableContainer}>
						<Table sx={{ minWidth: 150 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{...sxTableCell}}></TableCell>
									{!gstate.isXS ?
									<>
									{/*<TableCell sx={{...sxTableCell, width:42}} align="center"><Typography variant='h5'>{gstate.objEdit.fac_energy.energyclass_plan === 'singles' ? 'Lebensdauer' : 'Zielwert'}</Typography></TableCell>*/}													
									<TableCell sx={sxTableCell} align="right"><Typography variant='h5'>Kosten</Typography></TableCell>
									{/*<TableCell sx={sxTableCell} align="right"><Typography variant='h5'>Förderung</Typography></TableCell>*/}
									</>
									:''}
								</TableRow>
							</TableHead>
							<TableBody>
								{gstate.objEdit.fac_plan_newbuilds ? gstate.objEdit.fac_plan_newbuilds.map((r, idx) => (
								isNewbuildCond( r) ?
								<TableRow
									key={r.name}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row" sx={{padding:1}}>
										<SwitchTableCell idx={idx} r={r} {...props} classes={classes} />																														
									</TableCell>
									{!gstate.isXS ?
									<>
									{/*
									<TableCell className={classes.tableColumnHeader} align="center">
										<OldnessTableCell r={r} {...props} classes={classes} />
									</TableCell>													
									*/}
									<TableCell className={classes.tableColumnHeader} align="right">
										<Typography variant='body2'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( r.cost).replace(/\D00(?=\D*$)/, '')}</Typography>
									</TableCell>
									{/*
									<TableCell className={classes.tableColumnHeader} align="right">
										{gstate.objEdit.fac_energy.energyclass_plan === 'singles' ?
											<>
											<Typography variant='body2'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( r.support).replace(/\D00(?=\D*$)/, '')}</Typography>
											<>
											{r.value==='1' && r.support_label && r.support_desc ?
												<>
												<QeeTooltip title={r.support_desc}> 
												<IconButton>
													<InfoOutlinedIcon style={{color:'#a1bda1'}}/>
												</IconButton>
												</QeeTooltip>																
												</>
											:
											''}
											</>
											</>				
										:<Typography>siehe Gesamt</Typography>}
									</TableCell>
									*/}
									</>
									:''}
								</TableRow>
								:''
							))											  
							:<></>}	
								<TableRow
									key="total"
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									{!gstate.isXS ?
									<>
									<TableCell sx={sxTableCell}><Typography variant='h5'>Zwischensumme energetische Gewerke</Typography></TableCell>												
									{/*<TableCell sx={sxTableCell}></TableCell>*/}																									
									<TableCell sx={{...sxTableCell}} align="right">
										<Typography variant='h5'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_newbuilds_cost).replace(/\D00(?=\D*$)/, '')}</Typography>
									</TableCell>
									{/*
									<TableCell sx={{...sxTableCell, fontWeight:800}} align="right">
										<Typography variant='h5'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_newbuilds_support).replace(/\D00(?=\D*$)/, '')}</Typography>
									</TableCell>
									*/}									
									</>
									:
									<TableCell sx={sxTableCell}>
										<div><Typography sx={sxTableCell} variant='body2'>Kosten: {Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_newbuilds_cost).replace(/\D00(?=\D*$)/, '')}</Typography></div>
										{/*<div><Typography sx={sxTableCell} variant='body2'>Förderungen: {Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_newbuilds_support).replace(/\D00(?=\D*$)/, '')}</Typography></div>*/}														
									</TableCell>												
									}
								</TableRow>												
							</TableBody>
						</Table>
					</TableContainer>
					<QeeHintBox type="help" 
						text={
							<React.Fragment>
								<Typography variant='subtitle2'><strong>Hinweis:</strong> <span dangerouslySetInnerHTML={{__html: getHintSupport()}}></span></Typography>
								<strong><a href='#' onClick={()=>setOpenConfluenceDialog('/forderungen')}>
									Fördermöglichkeiten für den Hausneubau
								</a></strong>
							</React.Fragment>} 
					/>								
				</FormGroup>
				
			</FormControl>																																									
		)}
	);			
	
	
	/**
	*	TableNotFoundingDiscs - dynamic table with the non founding disciplines
	*/	
	const TableNotFoundingDiscs = withStyles()(
		({ classes, ...props }) => {
			
		// local reno row/switch cell
		const SwitchTableCell = withStyles()(
			({ r, idx, classes, ...props }) => {
				
				return (				
					<>	
					<FormControlLabel
						checked={r.value === '1' ? true : false} 
						name={r.name}													
						control={
							<SwitchRighted 
								checked={r.value === '1' ? true : false}
								onChange={handleOnChangePlanNewbuilds2}																
								classes={{
									track: classes.switchTrack,
									switchBase: classes.switchBase,
									colorPrimary: classes.switchPrimary,
								}}
								color={"default"}																
							/>
						}
						label={<LabelTableCell 
                            reno={r} 
                            idx={idx} 
                            showEditButton={r.value === '1'} 
							{...props}
							classes={classes}
                        />}						
					/>					
					</>
				)
			}
		)							
			
		// local reno row/oldness cell
		const OldnessTableCell = withStyles()(
			({ r, classes, ...props }) => {
				
				return (				
					<>
					<div style={{display: 'flex', alignItems: 'center'}}>
						{r.overdue==='1' ? 
							<QeeTooltip title={getTooltipOverdueOldness(r)}> 
							<div style={{display:'block', textAlign:'center'}}>
								<FmdBadIcon sx={{fontSize:32, fontWeight:800, color:'red'}}/>
								<div className={classes.miniLabelRed}>Sanierung</div>
								<div className={classes.miniLabelRed}>Alter {r.oldness} Jahre</div>
							</div>
							</QeeTooltip>
							:
							<QeeTooltip title={getTooltipOverdueOldness(r)}> 
								<div style={{display:'block', textAlign:'center'}}><CheckIcon sx={{fontSize:32, fontWeight:800, color:'green'}}/><div className={classes.miniLabelGreen}>In Ordnung</div></div>																							
							</QeeTooltip>		
						}
					</div>				
					</>
				)
			}
		)		

		return (
			<FormControl component="fieldset" variant="standard" sx={{width:'100%'}}>
				<FormGroup  style={{borderTop:`1px solid green`, marginTop:40, paddingTop:20}}>
					<FormHelperText><Typography variant='subtitle2'>Die geplanten, nicht energetisch relevanten Bauteile und Gewerke spiegeln sich in den Kosten wider.</Typography></FormHelperText>								
					<TableContainer className={classes.tableContainer}>
						<Table sx={{ minWidth: 150 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									{!gstate.isXS ?
									<>
									{/*<TableCell sx={{...sxTableCell, width:42}} align="center"><Typography variant='h5'>Lebensdauer</Typography></TableCell>*/}													
									<TableCell sx={sxTableCell} align="right"><Typography variant='h5'>Kosten</Typography></TableCell>
									{/*<TableCell sx={sxTableCell} align="right"><Typography variant='h5'>Förderung</Typography></TableCell>*/}
									</>
									:''}
								</TableRow>
							</TableHead>
							<TableBody>
								{gstate.objEdit.fac_plan_newbuilds2 ? gstate.objEdit.fac_plan_newbuilds2.map((r, idx) => (
								isNewbuildCond( r) ?
								<TableRow
									key={r.name}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row" sx={{padding:1}}>	
										<SwitchTableCell idx={idx} r={r} {...props} classes={classes} />
									</TableCell>
									{!gstate.isXS ?
									<>
									{/*
									<TableCell className={classes.tableColumnHeader} align="right">
										<OldnessTableCell r={r} {...props} classes={classes} />
									</TableCell>													
									*/}
									<TableCell className={classes.tableColumnHeader} align="right">
										<Typography variant='body2'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( r.cost).replace(/\D00(?=\D*$)/, '')}</Typography>
									</TableCell>
									{/*
									<TableCell className={classes.tableColumnHeader} align="right">	
										<Typography variant='body2'>keine</Typography>			
									</TableCell>
									*/}
									</>
									:''}
								</TableRow>
								:''
							))											  
							:<></>}	
								<TableRow
									key="total"
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									{!gstate.isXS ?
									<>
									<TableCell sx={sxTableCell}><Typography variant='h5'>Zwischensumme nicht-energetische Gewerke</Typography></TableCell>												
									{/*<TableCell sx={sxTableCell}></TableCell>*/}																									
									<TableCell sx={{...sxTableCell}} align="right">
										<Typography variant='h5'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_newbuilds2_cost).replace(/\D00(?=\D*$)/, '')}</Typography>
									</TableCell>
									{/*<TableCell sx={{...sxTableCell, fontWeight:800}} align="right"><Typography></Typography></TableCell>*/}											
									</>
									:
									<TableCell>
										<div><Typography sx={sxTableCell} variant='body2'>Kosten: {Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_newbuilds2_cost).replace(/\D00(?=\D*$)/, '')}</Typography></div>
									</TableCell>																									
									}
								</TableRow>												
							</TableBody>
						</Table>
					</TableContainer>																
				</FormGroup>
				
			</FormControl>
		)
		}
	);				
	
	
	/**
	*	InvestInvoice() - card for showing results (deleted for TableCntainer component={Paper})
	*/	
	const InvestInvoice = withStyles()(
		({ classes, ...props }) => {
	
		return(
			<TableContainer>
				<Table sx={{ minWidth: 150, width: gstate.isXS ? '90%' : '100%' }} aria-label="invest table">
					<TableBody>
						{gstate.objEdit.fac_has_demolition === '1' && (
							<TableRow key="plan_demolition_cost">
								<TableCell component="th" scope="row" sx={{padding:2, "& td": { border: 0 }}}><Typography>Abrisskosten</Typography></TableCell>
								<TableCell align="right">
									<Typography variant='body2'>{formatNumber( gstate.objEdit.fac_investment.plan_demolition_cost, 'EUR', 0)}</Typography>
								</TableCell>
							</TableRow>
						)}
					
						<TableRow key="plan_newbuilds_cost">
							<TableCell component="th" scope="row" sx={{padding:2, "& td": { border: 0 }}}><Typography>Gebäudekosten</Typography></TableCell>
							<TableCell align="right">
								<Typography variant='body2'>{formatNumber( gstate.objEdit.fac_investment.plan_newbuilds_cost, 'EUR', 0)}</Typography>
							</TableCell>
						</TableRow>
						
						{gstate.objEdit.fac_investment.plan_newbuilds_cost_surcharge_site !== null && gstate.objEdit.fac_investment.plan_newbuilds_cost_surcharge_site !== 0 && (
							<TableRow key="plan_newbuilds_cost_surcharge_site">
								<TableCell component="th" scope="row" sx={{padding: 2, "& td": { border: 0 }}}>
									<Typography>{"Zu-/Abschläge für Baustellebedingungen"}</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant='body2'>
										{gstate.objEdit.fac_investment.plan_newbuilds_cost_surcharge_site < 0 ? '- ' : ''}
										{formatNumber(Math.abs(gstate.objEdit.fac_investment.plan_newbuilds_cost_surcharge_site), 'EUR', 0)}
									</Typography>
								</TableCell>
							</TableRow>
						)}							
						
						{gstate.objEdit.fac_investment.plan_newbuilds_cost_surcharge_market !== null && gstate.objEdit.fac_investment.plan_newbuilds_cost_surcharge_market !== 0 && (
							<TableRow key="plan_newbuilds_cost_surcharge_market">
								<TableCell component="th" scope="row" sx={{padding: 2, "& td": { border: 0 }}}>
									<Typography>{"Kostenschwankung wg. Marktsituation"}</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant='body2'>
										{gstate.objEdit.fac_investment.plan_newbuilds_cost_surcharge_market < 0 ? '- ' : ''}
										{formatNumber(Math.abs(gstate.objEdit.fac_investment.plan_newbuilds_cost_surcharge_market), 'EUR', 0)}
									</Typography>
								</TableCell>
							</TableRow>
						)}	
						
						{gstate.objEdit.fac_ownwork_percent > 0 && (
							<TableRow key="fac_ownwork_percent">
								<TableCell component="th" scope="row" sx={{padding:2}}><Typography>Eigenleistungen</Typography></TableCell>
								<TableCell align="right">
									<Typography variant='body2'>- {formatNumber( gstate.objEdit.fac_investment.plan_newbuilds_ownwork_cost, 'EUR', 0)}</Typography>
								</TableCell>													
							</TableRow>
						)}
						
						{/*
						<TableRow key="plan_newbuilds_support">
							<TableCell component="th" scope="row" sx={{padding:2}}><Typography>Förderungen</Typography></TableCell>
							<TableCell align="right">
								<Typography variant='body2'>- {formatNumber( gstate.objEdit.fac_investment.plan_newbuilds_support, 'EUR', 0)}</Typography>
							</TableCell>													
						</TableRow>
						*/}
						
						<TableRow key="plan_newbuilds_cost2">
							<TableCell sx={sxTableCell}><Typography variant='h5'>Zwischensumme</Typography></TableCell>												
							<TableCell align="right">
								<Typography variant='h5'>{
									formatNumber( 
										gstate.objEdit.fac_investment.plan_newbuilds_cost2 -
										gstate.objEdit.fac_investment.plan_newbuilds_support, 'EUR', 0)
								}</Typography>  
							</TableCell>													
						</TableRow>
						
						<TableRow key="plot">
							<TableCell component="th" scope="row" sx={sxTableCell}><Typography variant='h5'>Grundstück</Typography></TableCell>
							<TableCell align="right">
							</TableCell>													
						</TableRow>	
						
						<TableRow key="fac_plot_price">
							<TableCell scope="row" sx={{padding:2}}><Typography>Grundstückspreis</Typography></TableCell>
							<TableCell align="right">
								<Typography variant='body2'>{formatNumber( gstate.objEdit.fac_plot_price, 'EUR', 0)}</Typography>
							</TableCell>													
						</TableRow>	
						
						<TableRow key="fac_transfer_tax">
							<TableCell component="th" scope="row" sx={{padding:2}}><Typography>Steuer</Typography></TableCell>
							<TableCell align="right">
								<Typography variant='body2'>{formatNumber( gstate.objEdit.fac_transfer_tax, 'EUR', 0)}</Typography>
							</TableCell>													
						</TableRow>	
						
						<TableRow key="fac_broker_fee" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{padding:2}}><Typography>Maklergebühr</Typography></TableCell>
							<TableCell align="right">
								<Typography variant='body2'>{formatNumber( gstate.objEdit.fac_broker_fee, 'EUR', 0)}</Typography>
							</TableCell>													
						</TableRow>
						
                        <TableRow key="fac_notary_fee" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{padding:2}}><Typography>Notargebühr</Typography></TableCell>
							<TableCell align="right">
								<Typography variant='body2'>{formatNumber( gstate.objEdit.fac_notary_fee, 'EUR', 0)}</Typography>
							</TableCell>													
						</TableRow>
						
						<TableRow key="fac_development_cost" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{padding:2}}><Typography>Erschließungskosten</Typography></TableCell>
							<TableCell align="right">
								<Typography variant='body2'>{formatNumber( gstate.objEdit.fac_development_cost, 'EUR', 0)}</Typography>
							</TableCell>													
						</TableRow>	
						
						<TableRow key="value" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell sx={sxTableCell}><Typography variant='h5'>Gesamtinvestition</Typography></TableCell>												
							<TableCell align="right">
								<Typography variant='h5'>{formatNumber( gstate.objEdit.fac_investment.value, 'EUR', 0)}</Typography>
							</TableCell>
						</TableRow>		
						
					</TableBody>
				</Table>
			</TableContainer>		
		)
	})  

	
	const FundingPrograms = withStyles()(
		({ classes, ...props }) => {
		
		const fundingProgramsData = [
			{
				title: "Neubau kaufen",
				creditNumber: "124",
				program: "Wohn­eigentums­programm",
				description: "Für den Kauf oder Bau eines Eigenheims",
				details: "Förderkredit bis zu 100.000 Euro für alle, die Wohnraum kaufen oder bauen und selbst darin wohnen wollen\ngut kombinierbar mit anderen KfW-Förderprodukten",
				url: "https://www.kfw.de/inlandsfoerderung/Privatpersonen/Neubau/F%C3%B6rderprodukte/Wohneigentumsprogramm-(124)/"
			},
			{
				title: "Klimafreundlicher Neubau",
				creditNumber: "297, 298",
				program: "Klimafreundlicher Neubau – Wohngebäude",
				description: "Haus und Wohnung energieeffizient und nachhaltig bauen",
				details: "Förderkredit bis zu 150.000 Euro je Wohnung für Neubau und Erstkauf bis zu 35 Jahre Laufzeit und bis zu 10 Jahre Zinsbindung",
				url: "https://www.kfw.de/inlandsfoerderung/Privatpersonen/Neubau/F%C3%B6rderprodukte/Klimafreundlicher-Neubau-Wohngeb%C3%A4ude-(297-298)/"
			},
			{
				title: "Wohneigentum für Familien",
				creditNumber: "300",
				program: "Wohneigentum für Familien",
				description: "Für Familien mit Kindern, die klimafreundlich bauen",
				details: "Förderkredit für den Bau und Erstkauf von Haus und Eigentumswohnung für Familien mit Kindern und Allein­erziehende mit niedrigem bis mittlerem Einkommen Kredithöchstbeträge von 170.000 bis 270.000 Euro",
				url: "https://www.kfw.de/inlandsfoerderung/Privatpersonen/Neubau/F%C3%B6rderprodukte/Wohneigentum-f%C3%BCr-Familien-(300)/"
			},
			{
				title: "Erneuerbare Energien nutzen",
				creditNumber: "270",
				program: "Erneuerbare Energien – Standard",
				description: "Der Förderkredit für Strom und Wärme",
				details: "für Photovoltaik, Wasser, Wind, Biogas und vieles mehr für Anlagen zur Erzeugung von Strom und Wärme, für Netze und Speicher",
				url: "https://www.kfw.de/inlandsfoerderung/Privatpersonen/Neubau/F%C3%B6rderprodukte/Erneuerbare-Energien-(270)/"
			}
		];

		return (
			<Box className={classes.container}>
				<TableContainer>
					<Table sx={{ minWidth: 150, width: gstate.isXS ? '90%' : '100%' }} aria-label="invest table">					
						<TableRow key="plan_newbuilds_cost">
							<TableCell component="th" scope="row" sx={{padding:2, "& td": { border: 0 }}}><Typography>Die möglichen Förderungen beziehen sich i.d.R. nur auf reine Baukosten. Diese sind in diesem Fall</Typography></TableCell>
							<TableCell align="right">
								<Typography variant='h5'>{formatNumber( gstate.objEdit.fac_investment.plan_funding_cost, 'EUR', 0)}</Typography>
							</TableCell>
						</TableRow>			
					</Table>
				</TableContainer>	
			

				{fundingProgramsData.map((item, index) => (
					<Box key={index} className={classes.box} style={{backgroundColor:'#eee'}}>
						<Typography variant="h6">{item.title}</Typography>
						<Typography variant="body2">KfW Kredit Nr. {item.creditNumber}</Typography>
						<Typography variant="body1" className={classes.title}>{item.program}</Typography>
						<Typography variant="body2">{item.description}</Typography>
						<Typography variant="body2" className={classes.details}>{item.details}</Typography>
						<Link href={item.url} target="_blank" rel="noopener" variant="body2" sx={{ fontWeight: 'bold' }}>
							Zu den Details
						</Link>						
					</Box>
				))}
			</Box>
		);
	});
	
	
	const prepareDonutFields = () => {
		const fac = { ...gstate.objEdit };

		// Filter objects based on the structure where name starts with 'has_renovation_' and value is '1'
		const filteredNewBuilds = fac.fac_plan_newbuilds.filter(obj => {
			return obj.name.startsWith('has_renovation_') && obj.value === '1';
		});
		const filteredNewBuilds2 = fac.fac_plan_newbuilds2.filter(obj => {
			return obj.name.startsWith('has_renovation_') && obj.value === '1';
		});

		// Extracting labels and costs from the filtered arrays
		const newBuildsCostData = filteredNewBuilds.map(obj => ({
			label: obj.label,
			value: obj.support ? Math.max(obj.cost-obj.support, 0) : obj.cost
		}));
		const newBuilds2CostData = filteredNewBuilds2.map(obj => ({
			label: obj.label,
			value: obj.support ? Math.max(obj.cost-obj.support, 0) : obj.cost
		}));

		// Manually defined list of fields with label and field name
		const otherFields = [
			{ label: 'Maklerkosten', field: 'fac_broker_fee' },
			{ label: 'Notarkosten', field: 'fac_notary_fee' },
		];
		//fac.fac_broker_fee = 1200;
		//fac.fac_notary_fee = 2000;
		// Create otherCostData array based on the manually defined list of fields
		const otherCostData = otherFields.reduce((acc, field) => {
			if (fac.hasOwnProperty(field.field)) {
				acc.push({
					label: field.label, // Use label from otherFields
					value: fac[field.field] // Get value from fac object
				});
			}
			return acc;
		}, []);
		
		let ret = newBuildsCostData.concat(newBuilds2CostData, otherCostData);
		console.log('getDonutFields: ', ret);

		return ret;
	}

	
    return (
		<>
		
        <Grid
            container
            direction="column"
            justifyContent="flex-begin"
            alignItems="left"
            spacing={gstate.isXS ? 0 : 1}
        >
            <Grid item>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-begin"
                    alignItems="left"
                    spacing={gstate.isXS ? 0 : 1}
                >
                    <Grid item xs={10} md={12} lg={7}>
                        <Typography variant='h2'>Auswahl Maßnahmen</Typography>
                        <Box className={classes.box}>
							<TableFoundingDiscs {...props} classes={classes} />							
							<TableNotFoundingDiscs {...props} classes={classes} />																												
                        </Box>
                    </Grid>
					
					<Grid item xs={10} md={12} lg={5}>
						<Typography variant='h2'>Ergebnisse</Typography>
						<Box className={classes.box}>
							<Grid item>
								<Box className={classes.resultBox}>
									<div className={classes.donutChartContainer}>
									<DiagramDonutChart
										title='Kostenverteilung'
										data = {prepareDonutFields()}
										showPercent={true}
									/>	
									</div>
								</Box>							
							</Grid>												
							<Grid container direction="column" spacing={2}>
								{gstate.objEdit.fac_type == '100' ? 
								<Grid item>
									<Box className={classes.resultBox} style={{width:'auto'}}>
										<Typography variant='h3' color="text.primary" gutterBottom style={{whiteSpace:'nowrap'}}>
											Investitionsrechnung
										</Typography>	
										<InvestInvoice {...props} classes={classes} />
									</Box>
								</Grid>
								:''}
							</Grid>								
							<Grid container direction="column" spacing={2}>
								{gstate.objEdit.fac_type == '100' ? 
								<Grid item>
									<Box className={classes.resultBox} style={{ width: 'auto' }}>
										<Typography variant='h3' color="text.primary" gutterBottom style={{ whiteSpace: 'nowrap' }}>
											Förderungen
										</Typography>
										<FundingPrograms {...props} classes={classes} />
									</Box>
								</Grid>
								: ''}
							</Grid>
																				
						</Box>		
					</Grid>								
					
					
                </Grid>
            </Grid>
        </Grid>
		
		{/* Dialog editor for the qualities and details */}
		<QeeRadioGroupEditor 
			data={()=>{return itemEditorData}}				
			fctOnChange	={itemEditorData.fctOnChange}				
			fctOnClose	={handleEditorClose}
		/>	

		</>	
    );
			
};

export default FacPlanNewbuildsSelect;
