// fileUploaderDialog.js

import react, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import React, { useImperativeHandle } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';

import { FilePond, registerPlugin } from "react-filepond"
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';

import axios from 'axios';

import QeeSlideDialog from './qeeSlideDialog';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import { useSnackbar } from 'notistack';

import css from '../core/App.css';

import { useAuth } from "../context/provider";
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';
import { postStorageDocs } from "../api/service";
import { getSdatas } from "../api/service";


const styles = (theme) => ({
});


// main fct
const FileUploaderDialog = React.forwardRef((props, forwardedRef) => {	

	// Register the plugins
	registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
	
	const { enqueueSnackbar } = useSnackbar();				

	const refStepper = useRef(null);	
	const refUploaderForm = useRef(null);	
	const refMetaInfos = useRef(null);	
	
	const [fctReloadData, setFctReloadData] = useState( null)

	const { gstate, setOpenQeeDialog, setIsFetching } = useAuth();				
		
	const [state, setState] = useState({
		uiLoading: false, 
		activeStep: 0,
		imgCollection: [],
		rws: [],
		submitDisabled: true,		
	});
	const [valids, setValids] = React.useState( [false, false, true]);
	const handleSetValids = ( vlds) => {
		console.log( 'FileUploaderDialog.handleSetValids', vlds);
		setValids( JSON.parse( JSON.stringify( vlds)));	
	}		
	
	
	const handleSetState = ( st) => {
		console.log( 'FileUploaderDialog.handleSetState', st);
		setState( {
			...state,
			imgCollection: st.imgCollection,
			submitDisabled: !validate()
		});	
	}

	
	// some consts
	const useStyles = makeStyles({
		//...props
		uiProgess: {
			position: 'fixed',
			zIndex: '1000',
			height: '31px',
			width: '31px',
			left: '50%',
			top: '35%'
		},
		progess: {
			position: 'absolute'
		},		
	});			
	const classes = useStyles( props);
	
	
	const handleFileUploaderOpen = ( fctReloadData1) => {
				
		setOpenQeeDialog( {open:true, id:'dlgFileUploader'});
		setState({
			activeStep: 0,
			imgCollection: [],
			rws: [],
			submitDisabled: true,			
		});
		setValids( [false, false, true]);		
			
		// set it as a function with arg	
		setFctReloadData( (vl) => vl => fctReloadData1( vl));
	}	
	
	
	const handleFileUploaderClose = () => {
		setOpenQeeDialog( false);	
	};
	
	
	const validate = () => {
		
		return refStepper.current.validate();
	
	};			
	
	
	const onSubmit = async () => {

		setState({
			...state,
			isLoading: true
		})
			
		var formData = new FormData();
		console.log( 'imgCollection', state.imgCollection);
		for( let i=0; i<state.imgCollection.length; i++) {
			console.log( 'Set in FormData file:'+i, state.imgCollection[i])
			
			// extend the meta infos
			for( let j=0; j<state.rws.length; j++) {
				if( state.rws[j].sdoc_name === state.imgCollection[i].name) {
					console.log( 'rws name', state.rws[j].sdoc_name);
					state.imgCollection[i].filename = state.rws[j].sdoc_name;
					var metadata = {};
					for( var nm in state.rws[j]) {
						metadata[nm] = state.rws[j][nm];
					}	
					state.imgCollection[i].metadata = JSON.stringify( metadata);					
					//state.imgCollection[i].metadata = state.rws[j];
					console.log( 'rws content', state.imgCollection[i]);
				}
			}		
			console.log( 'imgCollection content', state.imgCollection[i]);
			formData.append( state.imgCollection[i].metadata, state.imgCollection[i]);
		}
		
		var ret = await postStorageDocs( formData, gstate.objEdit.id);
		
		setState({
			...state,
			isLoading: false
		})
		
		if( ret.error) {
			enqueueSnackbar( 'Der Upload konnte nicht ausgeführt werden! ' + ret.error, {variant: 'error'});								
			return false;
		} else {		
			enqueueSnackbar( 'Der Upload wurde erfolgreich ausgeführt.', {variant: 'success'});		
			if( fctReloadData)
				fctReloadData( true);	
			return true;
		}
	};	
	

	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		ali: ()=>{alert(123)},
		handleFileUploaderOpen: handleFileUploaderOpen,						
		handleFileUploaderClose: handleFileUploaderClose
	}));	
	

	useEffect(() => {

		console.log( 'FileUploaderDialog.useEffect');
								
	}, [])	



	/****************************************************************
	*
	*	VerticalLinearStepper() - component for stepping the upload
	*
	****************************************************************/

	const VerticalLinearStepper = React.forwardRef((props, forwardedRef) => {	
		
	const handleSetState = ( st) => {
		console.log( 'VerticalLinearStepper.handleSetState', st);
		props.handleSetState( st);	
	}	
	
	// intermediary valids between the comps and the dialog
	const [valids, setValids] = React.useState( props.validsDlg);	
	const handleSetValids = ( vlds) => {
		console.log( 'VerticalLinearStepper.handleSetValids', vlds);
		setValids( JSON.parse( JSON.stringify( vlds)));	
	}	
	useEffect(() => {		
		if( JSON.stringify( valids) != JSON.stringify( props.validsDlg))
			props.handleSetValids( JSON.parse( JSON.stringify( valids)));
	}, [JSON.stringify(valids)]);	
	
	
	// define the steps		
	const [steps, setSteps] = React.useState([
		{
			label: 'Selektiere die Dateien',
			description: ``,
			component: <UploaderForm 
				ref={refUploaderForm} 
				validsDlg={props.validsDlg}
				handleSetValids={handleSetValids}
				stateDlg={props.stateDlg}
				handleSetState={handleSetState}	
			/>
		},
		{
			label: 'Ergänze zusätzliche Informationen zu den Dateien.',
			description: '',
			component: <MetaInfos 
				ref={refMetaInfos} 			
				validsDlg={props.validsDlg}
				handleSetValids={handleSetValids}
				stateDlg={props.stateDlg}
				handleSetState={handleSetState}				
			/>
		},
		{
			label: 'Starte den Upload',
			description: ``
		},
	]);	
	
	
	const [activeStep, setActiveStep] = React.useState( props.stateDlg.activeStep);			
	// propagate it to the parent
	const handleSetActiveStep = ( ast) => {
		console.log( 'VerticalLinearStepper.handleSetActiveStep', [ast, props.stateDlg]);
		var st = props.stateDlg;
		st.activeStep = ast;
		props.handleSetState( st);
	}
	useEffect(() => {		
		if( activeStep != props.stateDlg.activeStep)
			handleSetActiveStep( activeStep);
	}, [activeStep]);
	
		
	const handleNext = () => {
		
		if( activeStep==0 && refUploaderForm.current) {
			refUploaderForm.current.saveStep();				
		} else if( activeStep==1 && refMetaInfos.current) {
			refMetaInfos.current.saveStep(+1);
		}
		
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		
	};

	const handleBack = () => {
		
		if( activeStep==1 && refMetaInfos.current) {
			refMetaInfos.current.saveStep(-1);
		}		
		
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};
		
	
	const validate = () => {
				
		console.log( 'VALIDS', state.valids);
		var ret = true;
		for( let i=0; i<valids.length; i++) {   // if one of them is false, break
			if( !valids[i])	
				return false;
		}
		
		return ret;
	};			
	
			
	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		validate: validate
	}));	
	
	
	/* 		<div>{'VALIDS: ' + JSON.stringify(valids)}</div> */
	return (
		<>
		<Box sx={{ marginTop:70, padding:10 }}>
			<Stepper activeStep={activeStep} orientation="vertical">
				{steps.map((step, index) => (
					<Step key={step.label}>
					<StepLabel
						optional={
							index === 2 ? (
								<Typography variant="caption">Die Dateien werden hochgeladen. Dies kann einige Zeit in Anspruch nehmen.</Typography>
							) : null
						}
					>
						{step.label}
					</StepLabel>
					<StepContent>
						<Typography>{step.description}</Typography>
						{step.component}
						<Box sx={{ mb: 2 }}>
							<div>
								<QeeColorButtonPrimary
									disableElevation 
									disabled={!props.validsDlg[activeStep]}
									onClick={index != steps.length - 1 ? handleNext : async () => {
										var ret = await props.fctOnSubmit(); 
										if( ret)
											setOpenQeeDialog( false);
									}}
									sx={{ mt: 1, mr: 1 }}
								>
								{index === steps.length - 1 ? 'Upload starten' : 'Weiter'}
								</QeeColorButtonPrimary>
								<QeeColorButtonSecondary
									disableElevation 								
									disabled={index === 0}
									onClick={handleBack}
									sx={{ mt: 1, mr: 1 }}
								>
								Zurück
								</QeeColorButtonSecondary>
							</div>
						</Box>
					</StepContent>
					</Step>
				))}
			</Stepper>
			{activeStep === steps.length && (
				<Paper square elevation={0} sx={{ p: 3 }}>
					<Typography>All steps completed - you&apos;re finished</Typography>
					<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
					Reset
					</Button>
				</Paper>
			)}
		</Box>		
		</>
		);
		
	});
	/******* End VerticalLinearStepper */
	
	
	/*************************************************************
	*
	*	UploaderForm() - component for browser framing
	*
	*************************************************************/
	const UploaderForm = React.forwardRef((props, forwardedRef) => {		
	//const UploaderForm = (props) => {
		
		const offset = 80;
		const [isLoading, setIsLoading] = useState( false);  
		const [height, setHeight] = useState( window.innerHeight>offset ? window.innerHeight-offset : offset);  
		
		const [imgCollection, setImgCollection] = useState( structuredClone( props.stateDlg.imgCollection));		
		const [rws, setRws] = useState( JSON.parse( JSON.stringify( props.stateDlg.rws)));		
		
	
		function handleWindowSizeChange() {
			setHeight( window.innerHeight>offset ? window.innerHeight-offset : offset);
		}	
	
	
		useEffect( () => {	
			
			window.addEventListener( 'resize', handleWindowSizeChange);
			return () => {
				window.removeEventListener( 'resize', handleWindowSizeChange);
			}
													
		}, [height]);		
		
		
		// some consts
		const useStyles = makeStyles( theme => ({
			uploaderForm: {
				//padding: 10,				
			},
			locationText: {
				color: '#444'
			},

		}));			
		const classes = useStyles( props);
		console.log( 'UploaderForm classes', classes);


		const validate = () => {
			console.log( '--> UploaderForm.validate');
			
			var ret = false;
			
			if( rws.length > 0)
				ret = true;
			
			console.log( '<-- UploaderForm.validate', ret);									
			return ret;
		}						

		
		const saveStep = () => {
			console.log( 'UploaderForm.saveStep', rws);
			
			var st = props.stateDlg;
			st.activeStep += 1;
			console.log( 'SAVE STEP RWS', rws);
			st.imgCollection = imgCollection;
			st.rws = rws;
			console.log( 'SAVE STEP st', st);
			props.handleSetState( st);	
		}		
							
			
		const onFileChange = (files) => {
			let items = files.map(fileItem => fileItem.file)			
			console.log( 'onFileChange.items', items);
			console.log( 'onFileChange.imgCollection', imgCollection);
			console.log( 'onFileChange.rws', rws);
			var ic = imgCollection;			
			var imgs = ic.concat( items);
			imgs = items.filter((a, i) => imgs.findIndex((s) => a.name === s.name) === i);

			
			var rws2 = [];
			if( imgs.length >= rws.length) {	
				console.log( 'More images: imgs.length > rws.length', true)
				rws2 = rws;	
				for( let i=0; i<imgs.length; i++) {
					var rw = {};
					var bFound = false;
					for( let j=0; j<rws.length; j++) {
						if( rws[j].sdoc_name === imgs[i].name) {
							bFound = true;
						}
					}
					if( !bFound) {
						rw.sdoc_name = imgs[i].name;
						rw.sdoc_type_opts = [];
						rw.sdoc_subtype_opts = [];				
						rw.sdoc_type = '';
						rw.sdoc_subtype = '';
						rws2.push( rw);
					}	
				}	
				
			} else if( imgs.length < rws.length) {	
				console.log( 'More rws: imgs.length < rws.length', true)
				for( let i=0; i<rws.length; i++) {
					var bFound = false;
					for( let j=0; j<imgs.length; j++) {
						if( imgs[j].name === rws[i].sdoc_name) {
							bFound = true;
						}
					}
					if( bFound) {
						rws2.push( rws[i]);
					}	
				}						
			}
			console.log( 'onFileChange before set rws', rws2);					
			setRws( rws2);			
			
			console.log( 'onFileChange before set imgs', imgs);					
			setImgCollection( imgs);			
						
		}
		
		
		useEffect( () => {	
		
			if( rws.length != props.stateDlg.rws.length) {
				var vlds = props.validsDlg; 
				vlds[0] = validate();
				props.handleSetValids( vlds);
			}
				
		}, [rws.length]);				
		
		
		/**
		*	expose it outside
		*/
		useImperativeHandle( forwardedRef, () => ({	
			ali: ()=>{alert(123)},
			validate: validate,
			saveStep: saveStep,
		}))
		
		
			
		//onupdatefiles={(fileItems) => onFileChange(fileItems)}>								
		//onupdatefiles={setImgCollection}
		// <form onSubmit={onSubmit}>
		return (
			<div>
				<form>
				<div className="filepond-wrapper">
					<FilePond 
						files={imgCollection}
						allowMultiple={true}
						imagePreviewHeight={150}
						server={null}
						instantUpload={false}
						onupdatefiles={(fileItems) => onFileChange(fileItems)}
						labelIdle={
							`<div style="width:100%;height:100%">
								<p>
								<span style="font-size:13px"><span class="filepond--label-action" tabindex="0"><a>Dateien hochladen</a></span> oder per Drag&Drop hierher ziehen.</span><br/>
								<span style="font-size:11px">(Maximalgröße 30 MB pro Datei)</span>
								</p>
							</div>`
						}	
					>
					</FilePond>
				</div>
				</form>		
			</div>
		)
		
	});
	/********* End UploaderForm */


	/*************************************************************
	*
	*	MetaInfos() - component for meta infos
	*
	*************************************************************/

	const MetaInfos = React.forwardRef((props, forwardedRef) => {	

		const [rws, setRws] = useState( JSON.parse( JSON.stringify( props.stateDlg.rws)));		
		const [optsDone, setOptsDone] = useState( false);		
									
		console.log( '!!!rws', rws);
									
		useEffect( async () => {	
		
			if( optsDone)
				return;
		
			var rws2 = rws;		
			console.log( 'rws2', rws2);
		
			var tps = await getSdatas( 'sdata_sdocs_types');				
			for( let i=0; i<rws2.length; i++) {
				rws2[i].sdoc_type_opts = tps;
			}
			console.log( 'EXTENDED rws2', rws2);

			setOptsDone( true);
			setRws( rws2);	

			var vlds = props.validsDlg; 
			vlds[1] = validate();
			props.handleSetValids( vlds);

		}, []);		
				
				
		const handleChange = async (ev, obj) => {	

			console.log( 'handleChange', [ev.target, obj]);
			console.log( 'rws', rws);
		
			var rws2 = rws;
			rws2[obj.idx][ev.target.name] = ev.target.value;			
						
			// get for related
			if( ev.target.name === 'sdoc_type') {
				var sbtps = await getSdatas( 'sdata_sdocs_subtypes', 'parent_type', ev.target.value);					
				rws2[obj.idx].sdoc_subtype_opts = sbtps;
				rws2[obj.idx].sdoc_subtype = 100;
			}
			console.log( 'setRws', rws2);
			setRws( JSON.parse( JSON.stringify( rws2)));
									
			var vlds = props.validsDlg; 
			vlds[1] = validate();
			props.handleSetValids( vlds);
			
		};	
		
		
		const validate = () => {
			console.log( '--> MetaInfos.validate');
			
			var ret = false;
						
			if( rws.length > 0) {
				for( let i=0; i<rws.length; i++) {
					if( !rws[i].sdoc_type || !rws[i].sdoc_subtype)
						return ret;
				}					
			}
			ret = true;
			
			console.log( '<-- MetaInfos.validate', ret);									
			return ret;
		}	


		const saveStep = ( delta) => {
			console.log( 'MetaInfos.saveStep', delta);
			
			var st = props.stateDlg;
			st.activeStep += delta;
			//st.imgCollection = JSON.parse( JSON.stringify( rws));
			st.rws = JSON.parse( JSON.stringify( rws));
			props.handleSetState( st);				
		}
								
			
		/**
		*	expose it outside
		*/
		useImperativeHandle( forwardedRef, () => ({	
			ali: ()=>{alert(123)},
			validate: validate,
			saveStep: saveStep
		}))			
			
			
		/* <div>{'MetaInfos ROWS:' + JSON.stringify(rws)}</div>	*/							  
		return (
			<div style={{height:500, marginTop:10, marginBottom:20}}>
				<>
				<TableContainer component={Paper} sx={{height:500}}>
					<Table sx={{ minWidth: 320 }} aria-label="simple table">
						<TableBody>
							{rws.map((row, idx) => (
								<TableRow
									key={row.name}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row" style={{ width: 100 }}>									
										<div>{row.sdoc_name}</div>
										{gstate.isXS ?
										<>
										<FormControl fullWidth size="small" style={{marginTop:8, marginBottom:8}}>
											<InputLabel id="demo-simple-select-label">Typ</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={row.sdoc_type}
												label="Typ"
												name="sdoc_type"										
												onChange={(ev)=>handleChange( ev, {idx:idx})}
											>
											{rws[idx].sdoc_type_opts.map((tp, idx) => (
												<MenuItem value={tp.type}>{tp.name}</MenuItem>										
											))}
											</Select>
										</FormControl>
										<FormControl fullWidth size="small">
											<InputLabel id="demo-simple-select-label">Untertyp</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={row.sdoc_subtype}
												label="Untertyp"
												name="sdoc_subtype"
												disabled={!rws[idx].sdoc_type}
												onChange={(ev)=>handleChange( ev, {idx:idx})}
											>
											{rws[idx].sdoc_subtype_opts.map((sbtp, idx) => (
												<MenuItem value={sbtp.type}>{sbtp.name}</MenuItem>										
											))}									  
											</Select>
										</FormControl>
										<FormControl fullWidth size="small">
										<TextField
											id="sdoc_desc"
											label="Beschreibung"
											margin="dense"
											name="sdoc_desc"
											value={rws[idx].sdoc_desc}									
											className={classes.textOutput}	
											onChange={(ev)=>handleChange( ev, {idx:idx})}											
											variant="outlined"
										/>						
										</FormControl>												
										</>
										:''}
									</TableCell>
									
									{!gstate.isXS ?
									<>
									<TableCell style={{ width: 100 }}>
										<FormControl fullWidth size="small">
											<InputLabel id="demo-simple-select-label">Typ</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={row.sdoc_type}
												label="Typ"
												name="sdoc_type"										
												onChange={(ev)=>handleChange( ev, {idx:idx})}
											>
											{rws[idx].sdoc_type_opts.map((tp, idx) => (
												<MenuItem value={tp.type}>{tp.name}</MenuItem>										
											))}
											</Select>
										</FormControl>							  
									</TableCell>							  
									<TableCell style={{ width: 100 }}>
										<FormControl fullWidth size="small">
											<InputLabel id="demo-simple-select-label">Untertyp</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={row.sdoc_subtype}
												label="Untertyp"
												name="sdoc_subtype"
												disabled={!rws[idx].sdoc_type}
												onChange={(ev)=>handleChange( ev, {idx:idx})}
											>
											{rws[idx].sdoc_subtype_opts.map((sbtp, idx) => (
												<MenuItem value={sbtp.type}>{sbtp.name}</MenuItem>										
											))}									  
											</Select>
										</FormControl>							  							  
									</TableCell>									
									<TableCell>
										<FormControl fullWidth size="small">
										<TextField
											id="sdoc_desc"
											label="Beschreibung"
											margin="dense"
											name="sdoc_desc"
											value={rws[idx].sdoc_desc}									
											className={classes.textOutput}	
											onChange={(ev)=>handleChange( ev, {idx:idx})}											
											variant="outlined"
										/>						
										</FormControl>												
									</TableCell>
									</>
									:''}		
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>						
				</>
			</div>		
		)
		
	});
	/******* End MetaInfos */

	/*<div style={{marginTop: 80}}>{'STATE-DLG:' + JSON.stringify( state)}</div>*/
	return (		
		<>
		<div ref={forwardedRef}></div>				
			<QeeSlideDialog 
				id='dlgFileUploader'
				title='Dateien hochladen' 
				formComponent={
					<>
					{state.isLoading ? 
						<div className={classes.root}>
							<CircularProgress size={50} className={classes.uiProgess} />
						</div>		
					: 					
						<VerticalLinearStepper 
							ref={refStepper} 
							stateDlg={state}
							handleSetState={handleSetState}
							validsDlg={valids}
							handleSetValids={handleSetValids}
							fctOnSubmit={onSubmit}
						/>	
					}
					</>
				}	
				hideDialogActions={true}				
				submitDisabled={state.submitDisabled}
				fctOnSubmit={onSubmit}
				paddingContent={0}
			>
			</QeeSlideDialog>		
		</>	
	)
})	
export default withStyles(styles)(FileUploaderDialog);