// QeeCliIndexedDb
	
var indexedDb;

/**
* initIndexedDb() - inits the indexedDb
*/            
export const initIndexedDb = ( ver) => {
	
	//console.log( 'initIndexedDb', ver);

	return new Promise( function( resolve, reject) {

		//check for support
		if (!('indexedDB' in window)) {  			
			console.error( 'This browser does not support IndexedDB');
			reject();
		}
		var idb = window.indexedDB;  	
		//console.log( '>>> QEE App Version', ver);
		var orq = idb.open( 'qeeDB', ver);
		orq.onupgradeneeded = function( e) {
			indexedDb = e.target.result;
			console.log( 'running onupgradeneeded...');
			if( !indexedDb.objectStoreNames.contains( 'store')) {
				var store = indexedDb.createObjectStore( 'store', {keyPath: 'url'});
				store.createIndex( 'url', 'url');
				var tx = e.target.transaction;
				tx.oncomplete = function( e) {    
					resolve( indexedDb);
				}   
				
			// for newer version delete the store content	
			} else {  				
				if( ver > e.oldVersion) {
					console.log( 'Version greater then old version: ' + ver + '>' + e.oldVersion);
					var tx = e.target.transaction;
					tx.oncomplete = function( e) {
						var tx = indexedDb.transaction(["store"], "readwrite");
						var store = tx.objectStore( 'store');  							
						store.clear();
						store.createIndex( 'url', 'url');
						resolve( indexedDb);
					}    			

				}
			}
		};
		orq.onsuccess = function( e) {
			//console.log( 'running onsuccess...');
			indexedDb = e.target.result;
			resolve( indexedDb);
		};
		orq.onerror = function( e) {
			console.log( 'QeeCliIndexedDb.prototype.init onerror: ', e);
			console.dir( e);
			reject();
		};
		
	});   // end promise  	
 
}
 
 
/**
* deleteIndexedDb() - deletes the indexedDb
*/            
export const deleteIndexedDb = ( ver) => {
	
	console.log( '--> deleteIndexedDb', ver); 
	
	var idb = window.indexedDB;  		
	if( !idb) {
		console.log( '<-- deleteIndexedDb ERR', 'No indexedDb!'); 		
		return;
	}
	
	var req = idb.deleteDatabase( ver);
	req.onsuccess = function () {
		console.log( "deleteIndexedDb successfull!");
	};
	req.onerror = function () {
		console.error( "ERR: Couldn't delete indexedDb!");
	};
	req.onblocked = function () {
		console.error( "ERR: Couldn't delete database due to the operation being blocked!");
	};
	
	console.log( '<-- deleteIndexedDb'); 	
}
 

/**
* getItem() - gets a specified item from the store
*/
export const getItem = ( url) => {
	
	//console.log( '--> QeeCliIndexedDb.getItem', url);
	
	return new Promise( function( resolve, reject) {

		if( !indexedDb) {
			console.log( '<-- getItem ERR', 'No indexedDb!'); 		
			reject( 'No indexedDb!');	
		}
	
		var tx = indexedDb.transaction( 'store', 'readonly');
		var store = tx.objectStore( 'store');
		var tx = store.get( url);  	
		tx.onsuccess = function( e) {
			if( tx && tx.result) {
				//console.log( '<-- QeeCliIndexedDb.getItem', tx.result);
				resolve( tx.result);
			} else {
				console.error( '<-- QeeCliIndexedDb.getItem ERR');
				reject({err:true});
			}	
		};
		tx.onerror = function( err) {
			console.error( '<-- QeeCliIndexedDb.getItem ERR', err);
			reject( {err:err});
		};
	
	});   // end promise  	
  
}


/**
* getItemsByUrl() - gets a set of items
*/
export const getItemsByUrl = ( url) => {
	
	//console.log( '--> QeeCliIndexedDb.getItemsByUrl', url);
	
	return new Promise( function( resolve, reject) {

		if( !indexedDb) {
			console.log( '<-- getItem ERR', 'No indexedDb!'); 		
			reject( 'No indexedDb!');	
		}
	
		var ret = [];
	
		var tx = indexedDb.transaction( 'store', 'readonly');
		var store = tx.objectStore( 'store');
		const index = store.index( 'url');
		const keyRangeValue = IDBKeyRange.upperBound( '&', true);
		var crs = index.openCursor( keyRangeValue);
		crs.onsuccess = (event) => {
			const cursor = event.target.result;
			if( cursor) {
				// cursor.key is a url, like "/api/storage/a5Kdfdg667", and cursor.value is the whole object.
				//console.log(`Geted Name: ${cursor.key}, url: ${cursor.value.url}`);
				ret.push( cursor.value);
				cursor.continue();
			}
			//console.log( '<-- QeeCliIndexedDb.getItemsByUrl', ret.length);
			resolve( ret);
		};		
		crs.onerror = function( err) {
			console.error( '<-- QeeCliIndexedDb.getItemsByUrl ERR', err);
			reject( {err:err});
		};		
	
	});   // end promise  	
  
}


/**
* addItem() - adds an item to indexedDB
*/            
export const addItem = ( url, resp) => {
	
	//console.log( '--> QeeCliIndexedDb.addItem', url);		
	
	return new Promise( function( resolve, reject) {

		if( !indexedDb) {
			console.log( '<-- addItem ERR', 'No indexedDb!'); 		
			reject( 'No indexedDb!');	
		}
	
		var tx = indexedDb.transaction(['store'], 'readwrite');
		var store = tx.objectStore( 'store');
		var item = {
			url: url,
			resp: resp,
			created: new Date().getTime()
		};
		var request = store.add( item);
		request.onerror = function(e) {
			console.error( '<-- QeeCliIndexedDb.getItem ERR', e.target.error.name);
			reject( e.target.error.name);
		};
		request.onsuccess = function( e) {
			//console.log( '<-- QeeCliIndexedDb.getItem: Item added to store');
			resolve( resp);
		};  
	
	});   // end promise
}


/**
* deleteItem() - deletes the specified item from indexedDB
*/            
export const deleteItem = ( url, resp) => {
	
	//console.log( '--> QeeCliIndexedDb.deleteItem', url);		
	
	return new Promise( function( resolve, reject) {

		if( !indexedDb) {
			console.log( '<-- deleteItem ERR', 'No indexedDb!'); 		
			reject( 'No indexedDb!');	
		}
	
		var tx = indexedDb.transaction(['store'], 'readwrite');
		var store = tx.objectStore( 'store');
		var request = store.delete( url);				
		request.onerror = function(e) {
			console.error( '<-- QeeCliIndexedDb.getItem ERR', e.target.error.name);
			reject( e.target.error.name);
		};
		request.onsuccess = function( e) {
			//console.log( '<-- QeeCliIndexedDb.getItem: Item deleted from store');
			resolve( resp);
		};
	
	});   // end promise
}


/**
* deleteItems() - deletes all item matching the url-substring from indexedDB
*/            
export const deleteItems = ( url, resp) => {
	
	//console.log( '--> QeeCliIndexedDb.deleteItems', url);		
	
	return new Promise( function( resolve, reject) {

		if( !indexedDb) {
			console.log( '<-- deleteItem ERR', 'No indexedDb!'); 		
			reject( 'No indexedDb!');	
		}
	
		var tx = indexedDb.transaction(['store'], 'readwrite');
		var store = tx.objectStore( 'store');

		store.openCursor().onsuccess = (event) => {
			const cursor = event.target.result;
			if( cursor) {
				var vl = cursor.value
				//console.log( 'Cursor by', vl);				
				if( vl.url && vl.url.indexOf( url) > -1) {
					const request = cursor.delete();
					request.onsuccess = () => {
						//console.log('Item Deleted', url);
					};
				}
				
				cursor.continue();
			}
			resolve();
		}
		store.openCursor().onerror = function( e) {
			console.error( '<-- QeeCliIndexedDb.getItem ERR', e.target.error.name);
			reject( e.target.error.name);
		};
			
	});   // end promise
}		