// facRenovationWiz.js
import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import FacRenovationGeneral from './facRenovationGeneral';
import FacRenovationComponents from './facRenovationComponents';
import FacRenovationQuestion from './facRenovationQuestion';
import FacRenovationResultIst from './facRenovationResultIst';
import FacPlanRenovationQuestion from './facPlanRenovationQuestion';
import FacPlanRenovationEnergyclass from './facPlanRenovationEnergyclass';
import FacPlanRenovationComponents from './facPlanRenovationComponents';
import FacRenovationReport from './facRenovationReport';

import { useValidation } from './qeeStepValidationProvider';

import { useAuth } from "../context/provider";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
		padding: 20,
		marginTop: 40
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));



const StepEnd = (props) => {
    return (
        <div className="form-group">
            Speichere Daten...
        </div>
    );
};


const HorizontalLabelPositionBelowStepper = withStyles({
    root: {
        background: 'none',
        border: 'none'
    },
})(Stepper);


const FacRenovationWiz = (props) => {
	
	const { gstate, setObjEdit } = useAuth();				
	
	const { validateStep } = useValidation();
	
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps( gstate.objEdit.fac_type);

	function getSteps( facType) {
		if( facType === '100') {   // house	
			return [
				'Allgemein',
				'Bauteile',
				'Renovierungen',
				'Ist-Zustand',
				'Soll-Analyse',
				'Energergieziel',
				'Geplante Heizung',
				'Kurzbericht',
				'Ende'
			];
		} else if( facType === '200') {   // appartment
			return [
				'Allgemein',
				'Bauteile',
				'Renovierungen',
				'Ist-Zustand',
				'Ende'
			];	
		}
	}

	function getStepContent(facType, stepIndex) {
		if (facType === '100') { // house
			switch (stepIndex) {
				case 0:
					return <FacRenovationGeneral step={0} {...props} />;
				case 1:
					return <FacRenovationComponents step={1} {...props} />;
				case 2:
					return <FacRenovationQuestion step={2} {...props} />;
				case 3:
					return <FacRenovationResultIst step={3} {...props} gstate={gstate} />;
				case 4:
					return <FacPlanRenovationQuestion step={4} {...props} />;
				case 5:
					return <FacPlanRenovationEnergyclass step={5} {...props} />;
				case 6:
					return <FacPlanRenovationComponents step={6} {...props} />;
				case 7:
					return <FacRenovationReport step={7} />;
				case 8:
					return <StepEnd step={8} />;
				default:
					return 'Unknown stepIndex';
			}
			
		} else if (facType === '200') { // apartment
			switch (stepIndex) {
				case 0:
					return <FacRenovationGeneral step={0} {...props} />;
				case 1:
					return <FacRenovationComponents step={1} {...props} />;
				case 2:
					return <FacRenovationQuestion step={2}{...props} />;
				case 3:
					return <FacRenovationResultIst step={3} {...props} />;
				case 4:
					return <StepEnd step={4} />;
				default:
					return 'Unknown stepIndex';
			}
		}
	}

	const handleNext = async () => {
		console.log('--> Advancing from step', activeStep);

		// Before moving next, validate the current step
		var ret = await validateStep( activeStep)
		if (!ret) {
			console.log("Validation failed for current step", activeStep);
			return; // Stop moving to next step if validation fails
		}

		/*
		// Execute any specific logic required before moving to the next step
		try {
			alert(activeStep)
			if (activeStep > 0 && props.fctSubmit) {
				console.log('Submitting...');
				await props.fctSubmit();
			}
			if (activeStep === 2 && gstate.objEdit.fac_type === '100') {
				if (props.fctRenovationCalculate) {
					console.log('Calculating the renovation IST...');
					await props.fctRenovationCalculate();
				}
			} else if (activeStep === 2 && gstate.objEdit.fac_type !== '100') {
				if (props.fctSubmitEnd) {
					console.log('Submit and exit...');
					await props.fctSubmitEnd();
				}
			}
			if (activeStep === 3 && gstate.objEdit.fac_use_plan_renovation === '0') {
				if (props.fctSubmitEnd) {
					console.log('Submit and exit...');
					await props.fctSubmitEnd();
				}
			}
			if (activeStep >= 4 && gstate.objEdit.fac_energy.energyclass_method === 'singles') {
				console.log('Calculating the renovation for singles...');
				await props.fctRenovationCalculate();
				if (props.fctSubmitEnd) {
					console.log('End submitting...');
					await props.fctSubmitEnd();
				}
			}
			if (activeStep === 5) {
				console.log('Calculating the renovation for KfW...');
				await props.fctRenovationCalculate();
				if (props.fctSubmitEnd) {
					console.log('End submitting...');
					await props.fctSubmitEnd();
				}
			}
		} catch (error) {
			console.error('Error processing step action:', error);
			return; // Optionally handle errors and do not advance to next step
		}
		*/

		// If all operations are successful, move to the next step
		setActiveStep(prevStep => prevStep + 1);
		
		/*
		alert(activeStep)
		var oe = gstate.objEdit;
		oe.step = activeStep+1;		
		setObjEdit( oe);		
		*/		
		
		console.log('<-- Completed step', activeStep);
	};


    const handleBack = () => {
        setActiveStep(prevStep => prevStep - 1);		
		
		/*
		alert(activeStep)
		var oe = gstate.objEdit;
		oe.step = activeStep>1 ? activeStep-1 : activeStep;
		setObjEdit( oe);		
		*/		
    };

    const handleReset = () => {
        setActiveStep(0);
    };
	
	
	useEffect(() => {
        setActiveStep( gstate.objEdit.step);
    }, [gstate.objEdit.step]);		
    
	
    return (
        <div className={classes.root}>
            <HorizontalLabelPositionBelowStepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </HorizontalLabelPositionBelowStepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>All steps completed</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <div>
                        <Typography className={classes.instructions}>
							{
								getStepContent( gstate.objEdit.fac_type, activeStep)
							}
						</Typography>
                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                            >
                                Zurück
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                            >
                                {activeStep === steps.length - 1 ? 'Ende' : 'Weiter'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FacRenovationWiz;







/*
import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';

import FacRenovationGeneral from './facRenovationGeneral';
import FacRenovationComponents from './facRenovationComponents';
import FacRenovationQuestion from './facRenovationQuestion';
import FacRenovationResultIst from './facRenovationResultIst';
import FacPlanRenovationQuestion from './facPlanRenovationQuestion';
import FacPlanRenovationEnergyclass from './facPlanRenovationEnergyclass';
import FacPlanRenovationComponents from './facPlanRenovationComponents';
import FacRenovationReport from './facRenovationReport';

import StepZilla from "react-stepzilla";

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {		
			padding: theme.spacing(0.5),
		}
	},
	form: {
		//width: '98%',
		marginLeft: 13,
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {		
			marginLeft: 0,
			padding: theme.spacing(0.5),
		}		
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
});


const StepEnd = ( props) => {
	return (
        <div class="form-group">
		Speichere Daten...
		</div>
	);	
}


// main fct
const FacRenovationWiz = ( props) => {

	const { gstate, setObjEdit, setObjErrors } = useAuth();			
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
	})

	var steps = [];
	if( gstate.objEdit.fac_type == '100') {   // house
		steps = [
			{ name: 'Step0', component: <FacRenovationGeneral	 		step={0} /> },	
			{ name: 'Step1', component: <FacRenovationComponents    	step={1} /> },			
			{ name: 'Step2', component: <FacRenovationQuestion  		step={2} /> },
			{ name: 'Step3', component: <FacRenovationResultIst	  		step={3} /> },		
			{ name: 'Step4', component: <FacPlanRenovationQuestion		step={4} /> },				
			{ name: 'Step5', component: <FacPlanRenovationEnergyclass	step={5} /> },								
			{ name: 'Step6', component: <FacPlanRenovationComponents	step={6} /> },						
			{ name: 'Step7', component: <FacRenovationReport			step={7} /> },		
			{ name: 'StepEnd', component: <StepEnd    step={8} /> },						
		]
	} else if( gstate.objEdit.fac_type == '200') {   // apartment
		steps = [
			{ name: 'Step0', component: <FacRenovationGeneral	 		step={0} /> },	
			{ name: 'Step1', component: <FacRenovationComponents    	step={1} /> },			
			{ name: 'Step2', component: <FacRenovationQuestion  		step={2} /> },
			{ name: 'Step3', component: <FacRenovationResultIst	  		step={3} /> },		
			{ name: 'StepEnd', component: <StepEnd    step={4} /> },						
		]		
	}

		
	useEffect( () => {	

		console.log( 'facRenovationWiz useEffect for obj', gstate.objEdit);
		
		// evtl. correct the obj step
		if( !gstate.objEdit.step)
			gstate.objEdit.step	= 0;		
						
	}, []);		

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);
	
	// create a ref
	const stepzilla = useRef(null);					
	
	const setStep = async ( step) => {
		console.log( '--> FacWiz.setStep', step);	
						
		gstate.objEdit.step = step;
						
		//props.jumpToStep( 0);		
				
		if( step >= 2) {	// == 4		
			if( props.fctSubmit) {
				console.log( 'submitting...');				
				props.fctSubmit();
			}
		}
		
		if( step == 3) {	// calculate the intermediate ist result	
			if( gstate.objEdit.fac_type == '100') {								
				if( props.fctRenovationCalculate) {
					console.log( 'Calculating the renovation IST...');
					await props.fctRenovationCalculate();								
				}		
			} else {
				if( props.fctSubmitEnd) {   // for apartment
					console.log( 'Submit and exit...');
					await props.fctSubmitEnd();								
				}				
			}
		}
		
		if( step == 4) {				
			if( gstate.objEdit.fac_use_plan_renovation === '0') {   // go out without plan renovation					
				if( props.fctSubmitEnd) {
					console.log( 'Submit and exit...');
					await props.fctSubmitEnd();								
				}
			}
		}		
		
		if( step >= 5) {		
			//alert(gstate.objEdit.fac_energy.energyclass_method);
			if( gstate.objEdit.fac_energy.energyclass_method === 'singles') {   // go out here for singles
				if( props.fctRenovationCalculate) {
					console.log( 'Calculating the renovation for singles...');				
					await props.fctRenovationCalculate();				
					if( props.fctSubmitEnd) {
						console.log( 'End submitting...');				
						props.fctSubmitEnd();					
					}				
				}				
			}
		}
		
		if( step == 7) {		
			if( props.fctRenovationCalculate) {
				console.log( 'Calculating the renovation for kfw...');				
				await props.fctRenovationCalculate();				
				if( props.fctSubmitEnd) {
					console.log( 'End submitting...');				
					props.fctSubmitEnd();					
				}				
			}
		}				
		
		if( step == 8) {		
			if( props.fctSubmitEnd) {
				console.log( 'End submitting...');				
				props.fctSubmitEnd();
			}
		}		
		
		console.log( '<-- FacWiz.setStep');				
	};
	
					
	return (	
		state.uiLoading ? (
			<div className={classes.root}>
				{state.uiLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (
			<main className={classes.content}>								
				<form className={classes.form} noValidate>					
					<StepZilla 
						steps={steps} 
						stepsNavigation={false}
						preventEnterSubmission={true}
						nextButtonText="Weiter"
						nextButtonCls="btn btn-prev btn-primary btn-lg pull-right"
						backButtonText="Zurück"				
						backButtonCls="btn btn-next btn-secondary btn-lg pull-left"	
						onStepChange={(step) => {
							setStep( step)
						}}
						ref={stepzilla}						
					/>
				</form>				
			</main>	
		)		
	)
}	
export default withStyles(styles)(FacRenovationWiz);
*/