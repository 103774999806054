// qeeSliderEditable.js
import React, { useState } from 'react';
import { Typography, Grid, Slider, Input, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const QeeSliderEditable = ({ label, value, fixedValues, onChange, width }) => {
	
	const theme = useTheme();
	
    const [inputValue, setInputValue] = useState(value);

    const handleSliderChange = (_, newValue) => {
        setInputValue(newValue); // Update the input value when the slider changes
        onChange(newValue);
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value === '' ? '' : Number(event.target.value);
        // Check if the newValue is within the fixedValues array
        if (fixedValues.includes(newValue)) {
            setInputValue(newValue); // Update the input value
            onChange(newValue); // Update the slider value
        }
    };

    const handleBlur = () => {
        // Ensure the value is within the fixed values range
        if (!fixedValues.includes(inputValue)) {
            const closestValue = fixedValues.reduce((prev, curr) => {
                return (Math.abs(curr - inputValue) < Math.abs(prev - inputValue) ? curr : prev);
            });
            setInputValue(closestValue);
            onChange(closestValue);
        }
    };

    const safeValue = typeof value === 'number' ? value : (fixedValues.length ? fixedValues[0] : 0);

    return (
        <Box sx={{ width, padding: 2 }}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Typography id="input-slider" component="span" gutterBottom>
                        {label}
                    </Typography>
                </Grid>
                <Grid item>
                    <Input
                        value={safeValue}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: null,
                            min: fixedValues[0],
                            max: fixedValues[fixedValues.length - 1],
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
						sx={{
							ml: 2,  // Adds some left margin to separate from the label
							'& .MuiInputBase-input': {
								borderBottom: '2px solid lightgrey'  // Changes the bottom border color to light green
							}
						}}
                    />
                </Grid>
            </Grid>
            <Slider
				value={safeValue}
				onChange={handleSliderChange}
				aria-labelledby="input-slider"
				min={fixedValues[0]}  // Minimum value from fixedValues
				max={fixedValues[fixedValues.length - 1]}  // Maximum value from fixedValues
				marks={fixedValues.map((val) => ({ value: val, label: val.toString() }))}  // Marks for each fixed value
				step={null}  // Remove steps between marks
				sx={{
					mt: 2, // Adds top margin to separate from the input and label row
					width: '100%',  // Ensure slider takes full width of its container
					[theme.breakpoints.down('sm')]: {
						width: '60%',  // Adjust width for small screens
					},
					'& .MuiSlider-rail': {
						height: 8,
						borderRadius: 4,
						backgroundColor: '#ccc',
					},
					'& .MuiSlider-track': {
						height: 8,
						borderRadius: 4,
						backgroundColor: theme.palette.primary.main,
					},
					'& .MuiSlider-thumb': {
						width: 24,
						height: 24,
						marginTop: 0,
						marginLeft: 0,
						backgroundColor: '#ffa500',  // Orange center
						border: '5px solid',  // Thick border
						borderColor: theme.palette.primary.main,
						[theme.breakpoints.down('sm')]: {
							width: 20,  // Adjust thumb size for small screens
							height: 20,
						},
					},
					'& .MuiSlider-markLabel': {
						color: '#bbb',  // Light grey labels
						fontSize: theme.typography.pxToRem(12),
						[theme.breakpoints.down('sm')]: {
							fontSize: theme.typography.pxToRem(10),  // Adjust mark label size for small screens
						},
					},
				}}
			/>
        </Box>
    );
};

export default QeeSliderEditable;



/*
import React, { useState, useEffect, useRef } from 'react';
import { Typography, Grid, Slider, Input, Box } from '@mui/material';

const QeeSliderEditable = ({ label, value, fixedValues, onChange, width, height }) => {
    const [inputValue, setInputValue] = useState(value);
    const [inputPosition, setInputPosition] = useState(0);
    const sliderRef = useRef(null); // Reference to the slider for size calculations

    const handleSliderChange = (_, newValue) => {
        setInputValue(newValue);
        onChange(newValue);
        updateInputPosition(newValue);
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value === '' ? '' : Number(event.target.value);
        setInputValue(newValue);
        onChange(newValue);
        updateInputPosition(newValue);
    };

    const handleBlur = () => {
        if (inputValue < fixedValues[0]) {
            setInputValue(fixedValues[0]);
            onChange(fixedValues[0]);
        } else if (inputValue > fixedValues[fixedValues.length - 1]) {
            setInputValue(fixedValues[fixedValues.length - 1]);
            onChange(fixedValues[fixedValues.length - 1]);
        }
        updateInputPosition(inputValue);
    };

    const updateInputPosition = (value) => {
        if (sliderRef.current) {
            const sliderWidth = sliderRef.current.offsetWidth;
            const min = fixedValues[0];
            const max = fixedValues[fixedValues.length - 1];
            const newPosition = ((value - min) / (max - min)) * sliderWidth;
            setInputPosition(newPosition);
        }
    };

    useEffect(() => {
        updateInputPosition(value);
    }, [value]);

    const getAlignmentStyle = () => {
        if (!sliderRef.current) return {};
        const sliderWidth = sliderRef.current.offsetWidth;
        const thumbPosition = ((inputValue - fixedValues[0]) / (fixedValues[fixedValues.length - 1] - fixedValues[0])) * sliderWidth;

        let alignmentTransform;
        if (thumbPosition <= 10) {
            alignmentTransform = 'translate(0%, -50%)';  // Stick to the left edge
        } else if (thumbPosition >= sliderWidth - 10) {
            alignmentTransform = 'translate(-100%, -50%)';  // Stick to the right edge
        } else {
            alignmentTransform = 'translate(-50%, -50%)';  // Center on the thumb
        }

        return {
            position: 'absolute',
            left: `${thumbPosition}px`,
            transform: alignmentTransform,
            transition: 'left 0.3s'
        };
    };

    const safeValue = typeof value === 'number' ? value : (fixedValues.length ? fixedValues[0] : 0);

    return (
        <Box sx={{ width, position: 'relative' }}>
            <Typography id="input-slider" gutterBottom>
                {label}
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Slider
                        ref={sliderRef}
                        value={safeValue}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        min={fixedValues[0]}
                        max={fixedValues[fixedValues.length - 1]}
                        marks={fixedValues.map((val) => ({ value: val, label: val.toString() }))}
                        step={null}
                    />
                </Grid>
                <Grid item style={getAlignmentStyle()}>
                    <Input
                        value={safeValue}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: null,
                            min: fixedValues[0],
                            max: fixedValues[fixedValues.length - 1],
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default QeeSliderEditable;

*/
