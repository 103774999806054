import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-free-solid';
import QeeGmap from './qeeGmap';

import { useAuth } from "../context/provider";


const FacGAddress = ( props) => {
		
	const thisStep = props.step;
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();		

	console.log( 'THIS STEP', thisStep);	
	console.log( 'objEdit.step', gstate.objEdit.step);		
	
	const [validationErrors, setValidationErrors] = useState( []);								
	const [gvalue, setGvalue] 			= useState( gstate.objEdit.addr_gvalue_o ? gstate.objEdit.addr_gvalue_o : '');	
	const [zip, setZip]       			= useState( gstate.objEdit.addr_street_zip_o ? gstate.objEdit.addr_street_zip_o : '');
	const [locality, setLocality] 		= useState( gstate.objEdit.addr_locality_o ? gstate.objEdit.addr_locality_o : '');	
	const [street, setStreet] 			= useState( gstate.objEdit.addr_street_o ? gstate.objEdit.addr_street_o : '');	
	const [houseNumber, setHouseNumber] = useState( gstate.objEdit.addr_housenumber_o ? gstate.objEdit.addr_housenumber_o : '');		
	const [lat, setLat] 				= useState( gstate.objEdit.addr_geo_lat_o ? gstate.objEdit.addr_geo_lat_o : 0);	
	const [lon, setLon] 				= useState( gstate.objEdit.addr_geo_lon_o ? gstate.objEdit.addr_geo_lon_o : 0);	
	const [showGmap, setShowGmap] 		= useState( true);		


	useEffect(()=> {  
		console.log( 'useEffect for step', thisStep);
		console.log( 'FacGAddress objEdit', gstate.objEdit);
		
		console.log( 'gvalue', gvalue);
								
		// set the validation for the very begin 
		const nextBtn = document.getElementById( 'next-button');
		if( thisStep == gstate.objEdit.step+1) {
			setNextClickHandler( gvalue);
		} 
		
		const prevBtn = document.getElementById( 'prev-button')
		if( thisStep == gstate.objEdit.step) {
			prevBtn.onclick=null;
			prevBtn.onclick=(e) => {
				if( props.fctGotoPrevStep) {
					e.stopPropagation();
					props.jumpToStep( props.fctGotoPrevStep( thisStep));
				}
				console.log( 'objEdit: ', gstate.objEdit)				
			}    
		} 						
		
	}, [JSON.stringify( gvalue)]); 
	
		
			
	/**
	* setNextClickHandler() - 
	*/
	const setNextClickHandler = (gval) => {  
		const nextBtn = document.getElementById( 'next-button');
		nextBtn.onclick=null;
		console.log( 'Setting onclick next for FacGAddress...');
		nextBtn.onclick=(e) => {				
			console.log( 'Click next gvalue', gval);				
			console.log( 'Set validation in FacGAddress');				
			var valid = ()=>{return validate( gval)};
			if( valid() > 0) {
				e.stopPropagation();
			}
		}    	
	}
	
	
	/**
	* validate() - 
	*/
	const validate = ( gval) => {  
		
		console.log( )
		console.log( '--> validate for facGAddress: ', gval)

		var errs = 0;	

		const validationErrors= [];
		if( !gval || gval === '') 
			validationErrors.push( 'Du musst eine Adresse eingeben!');

		if( validationErrors.length) {
			alert( validationErrors[0]);			
			errs = validationErrors.length;
			setValidationErrors( validationErrors); 
		} 

		console.log( '<-- validate: ' + errs)		
		return errs;
	}
	
	
	const handleAddressChange = ( oAddr) => {
		console.log( 'handleAddressChange', oAddr);
		setObjEdit({
			...gstate.objEdit,
			...oAddr
		})
		setGvalue( oAddr.addr_gvalue_o);
		setNextClickHandler( oAddr.addr_gvalue_o)
	}
	
	
	const handleCloseResult = () => {
		setGvalue( null);
		setNextClickHandler( null)
	}		
	
	
    return (
        <div>
			<h1>Wo ist das Objekt?</h1>
            <form>
                <div className="form-group">
					<label htmlFor="gvalue" style={{marginBottom: 30}}>Je genauer Du uns <b>Straße, Hausnummer und Ort</b> nennst, desto besser können wir die Immobilie bewerten.</label>
					<QeeGmap
						showSearchBar={true}
						showResultBar={true}
						gvalue={gstate.objEdit.addr_gvalue_o} 
						lat={gstate.objEdit.addr_geo_lat_o} 
						lon={gstate.objEdit.addr_geo_lon_o}
						fctOnAddressChange={handleAddressChange}
						fctOnCloseResult={handleCloseResult}						
						mapHeight={500}
						marginTop={5}
					/>
                </div>
            </form>
        </div>
    )
}
export default FacGAddress;