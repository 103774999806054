import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import * as React from 'react';
import { useNavigate } from "react-router-dom";

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckIcon from '@mui/icons-material/Check';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NumberFormat from 'react-number-format';
import Button from '@mui/material/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Badge from '@mui/material/Badge';
import ChatIcon from '@mui/icons-material/Chat';

import { green } from '@mui/material/colors';

import axios from 'axios';
import moment from 'moment';

import FacRenovationIst from './facRenovationIst';
import QeeRadioGroup from './qeeRadioGroup';
import LabelEnergyClass from './labelEnergyClass';
import DiagramEnergyClass from './diagramEnergyClass';
import QeeRadioGroupEditor from './qeeRadioGroupEditor';
import useScrollBlock from '../util/useScrollBlock';
import FacTitle from './facTitle';
import FacEnergyclassEmpty from './facEnergyclassEmpty';
import QeeTooltip from './qeeTooltip';
import QeeHintBox from './qeeHintBox';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import FacCompEnergyResultDiffCard from './facCompEnergyResultDiffCard'
import { useLicense } from "./qeeUsrLicenseProvider"; 
import {formatNumber} from '../util/helpers';
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';
import {usrRight} from '../util/helpers';

import { ReactComponent as FacKfw100Icon } from "../assets/fac_kfw100.svg";
import { ReactComponent as FacKfw85Icon } from "../assets/fac_kfw85.svg";
import { ReactComponent as FacKfw70Icon } from "../assets/fac_kfw70.svg";
import { ReactComponent as FacKfw55Icon } from "../assets/fac_kfw55.svg";
import { ReactComponent as FacKfw40Icon } from "../assets/fac_kfw40.svg";
import { ReactComponent as FacKfwSinglesIcon } from "../assets/fac_kfw_singles.svg";

import roofIcon from '../assets/roof.svg';
import fassadeIcon from '../assets/fassade.svg';
import windowIcon from '../assets/window.svg';
import basementIcon from '../assets/basement.svg';
import heatingIcon from '../assets/heating.svg';
import pvroofIcon from '../assets/pvroof.svg';
import electricIcon from '../assets/electric.svg';
import sanitarieIcon from '../assets/sanitarie.svg';
import bathIcon from '../assets/bath.svg';
import restroomIcon from '../assets/restroom.svg';
import exteriorpaintingIcon from '../assets/exteriorpainting.svg';
import sunblindeIcon from '../assets/sunblinde.svg';
import terraceIcon from '../assets/terrace.svg';
import landscapingIcon from '../assets/landscaping.svg';
import garageIcon from '../assets/garage.svg';
import carportIcon from '../assets/carport.svg';

import roofIconDisabled from '../assets/roof_disabled.svg';
import fassadeIconDisabled from '../assets/fassade_disabled.svg';
import windowIconDisabled from '../assets/window_disabled.svg';
import basementIconDisabled from '../assets/basement_disabled.svg';
import heatingIconDisabled from '../assets/heating_disabled.svg';
import pvroofIconDisabled from '../assets/pvroof_disabled.svg';
import electricIconDisabled from '../assets/electric_disabled.svg';
import sanitarieIconDisabled from '../assets/sanitarie_disabled.svg';
import bathIconDisabled from '../assets/bath_disabled.svg';
import restroomIconDisabled from '../assets/restroom_disabled.svg';
import exteriorpaintingIconDisabled from '../assets/exteriorpainting_disabled.svg';
import sunblindeIconDisabled from '../assets/sunblinde_disabled.svg';
import terraceIconDisabled from '../assets/terrace_disabled.svg';
import landscapingIconDisabled from '../assets/landscaping_disabled.svg';
import garageIconDisabled from '../assets/garage_disabled.svg';
import carportIconDisabled from '../assets/carport_disabled.svg';

import { getRoofType, getHeatingType, getSdatas } from "../api/service";
import { checkLicense } from '../util/auth';

import { useAuth } from "../context/provider";
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import { useChat } from "./qeeChatProvider";


const FacRenovationReport = ( props) => { 
	
	const thisStep = props.step;		
	
	const [ blockScroll, allowScroll ] = useScrollBlock();					
	
	const { gstate, setObjEdit, setObjErrors, setIsFetching, setOpenItemEditor } = useAuth();				
						
	const [itemEditorData, setItemEditorData] = useState({
		first:{
			title:'', 
			formSublabel:'',
			defaultValue: '',
			fctOnChange: null,
			sdataName: ''
		}
	});	
						
	const [validationErrors, setValidationErrors] = useState( []);	
	
	const [state, setState] = useState({	
		screenDims: {width:0, height:0},
		openEditor: false,
	})		
	
	const { handleClickOpenChat } = useChat();		

	const navigate = useNavigate();								
		
	// some consts
	const useStyles = makeStyles( theme => ({
		//...props
		accordionSummary: {
			backgroundColor: '#f00'
		},
		accordionBox: {
			fontWeight:'bold', 
			m:1, 
			lineHeight:1.2, 
			color:theme.palette.primary.contrastText,
		},
		icon:{
			color:theme.palette.primary.contrastText,
		},
		iconDark:{
			color:theme.palette.primary.main,
		},
		iconGreen:{
			color:theme.palette.primary.main,
		},		
		iconRed:{
			color:'red',
		},	
		iconGrey:{
			color:'grey',
		},		
		textOutput:{
			margin:20,
			minWidth: 80
		},
		resize:{
			fontWeight:800,
		},		
		subTitle: {
			color: '#444',
			fontWeight: 'bold'
		},
		miniLabelRed: {
			whiteSpace: 'nowrap',
			fontSize: 12,			
			color: 'red',
		},		
		miniLabelGreen: {
			whiteSpace: 'nowrap',
			fontSize: 12,						
			color: 'green',
		},				
		box: {
			//width:500, 
			//height:300,
			backgroundColor:theme.palette.primary.contrastText,
			border: 'solid 1px #E4EAE6',
			padding:20,
			//margin:20,
			[theme.breakpoints.down('xs')]: {
				padding:5,
			},
		},
		boxPercentage: {
			backgroundColor:theme.palette.secondary.main,
			color:theme.palette.secondary.dark,
			border: 'solid 1px ' + theme.palette.secondary.dark,
			padding:5,
			margin:10,
			width:72,
			display: 'inlineBlock',
			textAlign: 'center',
			fontWeight: 900
		},				
		boxPercentageGreen: {
			backgroundColor:'green',
			color:theme.palette.primary.contrastText,
			//border: 'solid 1px ' + theme.palette.secondary.main,
			padding:5,
			margin:10,
			width:72,
			display: 'inlineBlock',
			textAlign: 'center',
			fontWeight: 900,
			borderRadius: 5,
		},		
		boxPercentageRed: {
			backgroundColor:'red',
			color:theme.palette.primary.contrastText,
			//border: 'solid 1px ' + theme.palette.secondary.main,
			padding:5,
			margin:10,
			width:72,
			display: 'inlineBlock',
			textAlign: 'center',
			fontWeight: 900,
			borderRadius: 5,			
		},				
		switchTrack: theme.components.Switch.switchTrack,
		switchBase: theme.components.Switch.switchBase,
		switchPrimary: theme.components.Switch.switchPrimary,
		tableContainer: {
			[theme.breakpoints.down('xs')]: {
				width:380,
			},			
		},
		resultCard: {
			display: 'flex', 
			width:'100%',
			[theme.breakpoints.down('xs')]: {
				width:340,
			},
			height:120,
			padding:10,
			marginBottom: 10,
			borderRadius: 5,
			border: `1px solid ${theme.palette.primary.main}`,					
		},
		resultBox: {
			padding:25,
			[theme.breakpoints.down('xs')]: {
				padding:10,
			},
			marginBottom: 10,			
			borderRadius: 5,
			border: `1px solid ${theme.palette.primary.main}`,		
		},
		textIst: {
			color:theme.typography.error.color,
			fontWeight: 800,
		},
		textPlan: {
			color:theme.typography.success?.color,
			fontWeight: 800,			
		},
		appBar: {
			position: 'relative'
		},
		editButton: {
			color:theme.palette.secondary.contrastText,			
		},
		containerUpgrade: {
			position: 'relative'
		},
		boxUpgrade: {
			position: 'absolute',
			top: 0,
			left: 0,
			opacity: 0.8	
		},	
		overlayUpgrade: {
			zIndex: 9,
			//margin: 20			
		},
		floatingButton: {
			position: 'fixed',
			bottom: 0,
			right: 0,
			zIndex: 2
		},		
	}));			
	const classes = useStyles( props);
	

	const sxTableCell = gstate.isXS ? 
	{
		fontSize: 16,
		fontWeight: 700,
		color: '#444'
	} 
		:
	{
		fontSize: 16,
		fontWeight: 800,
		color: '#444'
	}		
	
	
	// init the objEdit with subobjects	
	/*
	if( !gstate.objEdit.fac_plan_renovations || gstate.objEdit.fac_plan_renovations.length==0) {
		var y = moment( new Date()).format("YYYY");
		y = parseInt( y);
		y = ''+y;
		y='2007';   // fixed because of the data
		setObjEdit({
			...gstate.objEdit,
			fac_plan_renovations:[
				{label:'Dach',    name:'has_renovation_roof',     type:"100",   quality:"100", 		value:false,  renovation:y},				
				{label:'Fassade', name:'has_renovation_fassade',  type:"100", 	quality:"100", 		value:false,  renovation:y},					
				{label:'Fenster', name:'has_renovation_windows',  type:"100", 	quality:"100", 		value:false,  renovation:y},					
				{label:'Keller',  name:'has_renovation_basement', type:"100", 	quality:"100", 		value:false,  renovation:y},					
				{label:'Heizung', name:'has_renovation_heating',  type:"100", 	quality:"100", 		value:false,  renovation:y},					
				{label:'PV-Dach', name:'has_renovation_pvroof',   type:"100", 	quality:"100", 		value:false,  renovation:y},									
			]
		});			
	}
	*/
	
	/*	
	useEffect(()=> {  
	
		console.log( 'FacRenovationReport useEffect for step', thisStep);
				
		// no effect here, puted in facRenovationQuestion.js
		if( !gstate.objEdit.fac_energy) {
			setObjEdit({
				...gstate.objEdit,
				fac_energy:{}
			});					
		}
				
		const nextBtn = document.getElementById( 'next-button')
		if( thisStep == gstate.objEdit.step) {
			nextBtn.onclick=null;
			nextBtn.onclick=(e) => {
				console.log( 'Set validation in FacRenovationReport');
				if( validate( e) > 0) {
					e.stopPropagation();
				} 
				console.log( 'objEdit: ', gstate.objEdit)				
			}    
		} 		
								
	}, [JSON.stringify( gstate.objEdit)]);		
	*/
	
	
	/**
	*	getSelectedRenovationsCount() - observer function for changes only in the renos selection
	*/
	const getSelectedRenovationsCount = ( renos) => {	

		var cnt = 0;	
		if( gstate.objEdit.fac_energy.energyclass_plan === 'singles') {	
			renos.map( (r, i)=>{
				if( r.value === '1')
					cnt++;
			})
		}
	
		console.log( 'getSelectedRenovationsCount', cnt);
	
		return cnt;
	}
	

	useEffect(async ()=> {  
		
		if( !gstate.isFetching)	{		
			setTimeout( async () => {			
				await handlePlanRenovationCalculate();
			}, 100);					
		}
		//allowScroll();			
		
	}, [gstate.objEdit.fac_energy.energyclass_plan, 
		gstate.objEdit.fac_energy.energyclass_method,
		JSON.stringify( gstate.objEdit.fac_plan_renovations),
		JSON.stringify( gstate.objEdit.fac_plan_renovations2)]);					
	//getSelectedRenovationsCount( gstate.objEdit.fac_plan_renovations)]);					

		
	/**
	* validate() - 
	*/
	const validate = (e) => { 
		
		console.log( '--> validate for FacRenovationReport in step: ', gstate.objEdit.step)
					
		var errs = false;		
		const validationErrors= [null];

		if( validationErrors[0]) {
			//alert( validationErrors[0]);			
			errs = true;
			setValidationErrors( validationErrors); 			
		} 		
		
		console.log( '<-- validate: ' + errs)		
		return errs;
	}


	const handleEnergySubmit = (event) => {
		
		console.log( 'handleEnergySubmit', event);
		
		//const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login');	
		if( event && event.preventDefault)		
			event.preventDefault();
		
		const newFac = gstate.objEdit;
		console.log( 'submit fac', newFac);		
		let options = {};
		let id = gstate.objEdit.id;
		options = {
			url: `/api/fac/${id}`,
			method: 'put',
			data: newFac
		};
		
		//axios.defaults.headers.common = { Authorization: `${authToken}` };
		setAxiosAuthToken();
		
		axios(options)
			.then((data) => {
				console.log( 'Submited data', data);
				setLocalStorageAuthToken( data);
			})
			.catch((error) => {
				setObjErrors( error.response.data);
				console.log(error);
			});
	};	
	
	const handleUploadProgress = (event) => {
		console.log( 'handleUploadProgress', event);	
	}
	
	
	/**
	*	handlePlanRenovationCalculate() -. starts the plan calculation on the server, saves the energy result
	*/
	const handlePlanRenovationCalculate = async (event) => {
		
		console.log( 'handlePlanRenovationCalculate', event);
		
		//const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login');	
		if( event && event.preventDefault)		
			event.preventDefault();
				
		setIsFetching( true);				
				
		const newFac = gstate.objEdit;
		if( newFac.fac_type == '200') {
			newFac.fac_energy.energyclass_plan = 'singles';
			newFac.fac_energy.energyclass_method = 'singles';
		}
		console.log( 'Calculate fac plan', newFac);		
		let options = {};
		let id = gstate.objEdit.id;
		options = {
			url: `/api/fac/renovation/plan/${id}`,
			method: 'put',
			data: newFac,
			onUploadProgress : handleUploadProgress,			
		};		
		//axios.defaults.headers.common = { Authorization: `${authToken}` };
		setAxiosAuthToken();
		try {
			const retFac = await axios( options);
			console.log( 'Calculated fac', retFac.data);
			setObjEdit( retFac.data);
		} catch( error) {
			setObjErrors( error.response.data);
			console.error( 'Cannot calculate fac', error);
		} finally {
			setIsFetching( false);
			document.body.style.overflow = "auto";	
			document.body.style.overflowY = "auto";
		};		
	};		
	
	
	const handleChangeEnergyclassPlan = (ev) => {				
		console.log( 'handleChangeEnergyclassPlan', ev.target.value);		
		setObjEdit({
			...gstate.objEdit,
			fac_energy: {
				...gstate.objEdit.fac_energy, 
				energyclass_plan: ev.target.value,
				energyclass_method: ev.target.value === 'singles' ? ev.target.value : 'kfw',
			}
		});
	}


	const handleOnChangePlanRenovations = (ev) => { 
		console.log( 'handleOnChangePlanRenovations: ', ev);
		
		var prs = JSON.parse( JSON.stringify( gstate.objEdit.fac_plan_renovations));
		
		prs.map((r, i) => {
			//console.log( 'elem', r.name + '-' + r.value);
			if( r.name === ev.target.name) {
				console.log( 'name', r.name);
				console.log( 'value', ev.target.value);
				console.log( 'checked', ev.target.checked);
				r.value = (ev.target.checked == true ? '1' : '0');
				if( r.value == '0') {
				}
			} 
		})		
		
		setObjEdit({
			...gstate.objEdit,
			fac_plan_renovations: prs
		});
	}		
	

	const handleOnChangePlanRenovationsBlock = (ev) => { 
		// do nothing
		alert( 'Dieser Bauteil wurde durch eine Regel/Gesetz (z.B. durch die Aktivierung eines anderes Bauteiles) automatisch aktiviert und kann nicht manuell geändert werden.');
	}	
	
	
	const handleOnChangePlanRenovations2 = (ev) => { 
		console.log( 'handleOnChangePlanRenovations2: ', ev);
		
		var prs2 = JSON.parse( JSON.stringify( gstate.objEdit.fac_plan_renovations2));
		
		prs2.map((r, i) => {
			//console.log( 'elem', r.name + '-' + r.value);
			if( r.name === ev.target.name) {
				console.log( 'name', r.name);
				console.log( 'value', ev.target.value);
				console.log( 'checked', ev.target.checked);
				r.value = (ev.target.checked == true ? '1' : '0');
			} 
		})		
		
		setObjEdit({
			...gstate.objEdit,
			fac_plan_renovations2: prs2
		});
	}			
	
	
	const getDiscIcon = (reno) => {
		
		const icos = {
			roof: roofIcon,
			fassade: fassadeIcon,
			windows: windowIcon,
			basement: basementIcon,
			heating: heatingIcon,						
			pvroof: pvroofIcon,			
			electric: electricIcon,
			sanitarie: sanitarieIcon,
			bath: bathIcon,
			restroom: restroomIcon,
			exteriorpainting: exteriorpaintingIcon,
			sunblinde: sunblindeIcon,			
			terrace: terraceIcon,
			landscaping: landscapingIcon,			
			garage: garageIcon,			
			carport: carportIcon,						
		};		
		
		const icosDisabled = {
			roof: roofIconDisabled,
			fassade: fassadeIconDisabled,
			windows: windowIconDisabled,
			basement: basementIconDisabled,
			heating: heatingIconDisabled,						
			pvroof: pvroofIconDisabled,			
			electric: electricIconDisabled,
			sanitarie: sanitarieIconDisabled,
			bath: bathIconDisabled,
			restroom: restroomIconDisabled,
			exteriorpainting: exteriorpaintingIconDisabled,
			sunblinde: sunblindeIconDisabled,			
			terrace: terraceIconDisabled,
			landscaping: landscapingIconDisabled,			
			garage: garageIconDisabled,			
			carport: carportIconDisabled,						
		};				
		
		let disc = reno.name.replace( 'has_renovation_', '');
		
		//console.log( '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> discIcon value' + reno.value, disc);
		if( reno.value == '1')							
			return <img src={icos[disc]} style={{width:50}}></img>
		else
			return <img src={icosDisabled[disc]} style={{width:50}}></img>
	}
	
	
	/**
	*	Component for async showing the device type
	*/
	const RenoTypeLabel = (props) => {
		const classes = useStyles();
		const [tp, setTp] = useState( '');
		const [qual, setQual] = useState( '');		
		
		var disc = props.disc;
		//alert( 'disc ' + disc);
		if( !disc)
			disc = props.reno.name.replace( 'has_renovation_', '');		
		
		useEffect(() => {
			
			let isMounted = true;
			
			async function getReno() {
				if( props.reno.type) {														
					var arrRet = await getSdatas( 'sdata_'+disc+'s_types', 'type', props.reno.type);
					var tp = 'unbekannt';
					if( arrRet && arrRet.length>0) {
						tp = arrRet[0].name;		
					}
					if( isMounted) {
						setTp( tp);	
					}								

					console.log( '>>> Get quality', props.reno.quality);					
					if( props.reno.quality) {
						arrRet = await getSdatas( 'sdata_'+disc+'s_qualities', ['parent_type', 'type'], [props.reno.type, props.reno.quality]);
						console.log( '>>> RET quality', arrRet);
						var q = '';
						if( arrRet && arrRet.length>0) {
							q = arrRet[0].name;						
						}
						if( isMounted) {
							setQual( q);
						}					
					}				
				}
			}
			getReno();
			
			return () => {
				isMounted = false; // Set flag to false when component unmounts
			};			
		}, [])

		return (
			<div style={{width:200}}>
			{props.reno.value != '1' ? '' :
				<Grid container direction="column">
					<Grid item style={{fontSize:12}}>
						{tp}
					</Grid>						
					<Grid item style={{fontSize:12}}>
						{qual}
					</Grid>		
				</Grid>
			}	
			</div>
		)
	}
	
	
	/**
	*	getPlanRenovationLabel() -
	*/
	const getPlanRenovationLabel = ( reno, idx, showEditButton) => { 	

		//console.log( 'getPlanRenovationLabel', reno, idx, showEditButton);	
		
		var ret = <div>{reno.label}</div>;
				
		if( reno.name === 'has_renovation_roof') {   // && reno.value === '1') {			
		
			ret = <div style={{width:270}}>
					<Grid container direction="row" spacing="1">
					<Grid item xs={4}>
						<div style={{display:'inline'}}>{getDiscIcon(reno)}</div>
					</Grid>
					<Grid item item xs={8}>
						<div style={{fontWeight:600}}>{ret}</div>
						<div style={{fontSize:12}}>
							<Grid container direction="row">
							<Grid item xs={10}>
								<RenoTypeLabel reno={gstate.objEdit.fac_plan_renovations[1]} disc="roof" />
							</Grid>
							<Grid item item xs={2}>
								{showEditButton ? 
								(usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?										
								<Button variant="text"
									onClick={() => { setItemEditorData({
											first:{
												title: "Dachtyp", 
												formSublabel: 'Welcher Dachtyp soll eingesetzt werden?',
												defaultValue: gstate.objEdit.fac_plan_renovations[1].type,
												fctOnChange: handlePlanRenovationChange,
												sdataName: 'sdata_roofs_types'
											},
											second:{
												title: "Qualitäten", 
												formSublabel: 'Welche Qualität soll eingesetzt werden?',
												defaultValue: gstate.objEdit.fac_plan_renovations[1].quality,
												fctOnChange: handlePlanRenovationChange,
												sdataName: 'sdata_roofs_qualities'
											}
										}) 
									}}>																		
									<EditIcon />
								</Button>
								: '') 
								: ''}
							</Grid>
							</Grid>
						</div>	
					</Grid>
					</Grid>	
			</div>	
		}

		else if( reno.name === 'has_renovation_fassade') {   // && reno.value === '1') {			
			ret = <div style={{width:270}}>
					<Grid container direction="row" spacing="1">
					<Grid item xs={4}>
						<div style={{display:'inline'}}>{getDiscIcon(reno)}</div>
					</Grid>
					<Grid item item xs={8}>
						<div style={{display:'inline', fontWeight:600}}>{ret}</div>
						<div style={{fontSize:12}}>
							<Grid container direction="row">
							<Grid item xs={10}>
								<RenoTypeLabel reno={gstate.objEdit.fac_plan_renovations[2]} disc="fassade" />
							</Grid>
							<Grid item item xs={2}>
							{showEditButton ? 
							(usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?										
							<Button variant="text"
								onClick={() => { setItemEditorData({
										first:{
											title: "Fassadentyp", 
											formSublabel: 'Welcher Fassadentyp soll eingesetzt werden?',
											defaultValue: gstate.objEdit.fac_plan_renovations[2].type,
											fctOnChange: handlePlanRenovationChange,
											sdataName: 'sdata_fassades_types'
										},
										second:{
											title: "Qualitäten", 
											formSublabel: 'Welche Qualität soll eingesetzt werden?',
											defaultValue: gstate.objEdit.fac_plan_renovations[2].quality,
											fctOnChange: handlePlanRenovationChange,
											sdataName: 'sdata_fassades_qualities'
										}
									}) 
								}}>																		
								<EditIcon />
							</Button> 
							: '') 
							: ''}							
						</Grid>
						</Grid>							
						</div>								
					</Grid>
					</Grid>	
			</div>	
		}
		
		else if( reno.name === 'has_renovation_windows') {   // && reno.value === '1') {			
			ret = <div style={{width:270}}>
					<Grid container direction="row" spacing="1">
					<Grid item xs={4}>
						<div style={{display:'inline'}}>{getDiscIcon(reno)}</div>						
					</Grid>
					<Grid item xs={8}>
						<div style={{display:'inline', fontWeight:600}}>{ret}</div>				
						<div style={{fontSize:12}}>
							<Grid container direction="row">
							<Grid item xs={10}>
								<RenoTypeLabel reno={gstate.objEdit.fac_plan_renovations[3]} disc="window" />
							</Grid>
							<Grid item item xs={2}>
							{showEditButton ? 
							(usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?																	
							<Button variant="text" 
								onClick={() => { setItemEditorData({
										first:{
											title: "Fenstertyp", 
											formSublabel: 'Welcher Fenstertyp soll eingesetzt werden?',
											defaultValue: gstate.objEdit.fac_plan_renovations[3].type,
											fctOnChange: handlePlanRenovationChange,
											sdataName: 'sdata_windows_types'
										},
										second:{
											title: "Qualitäten", 
											formSublabel: 'Welche Qualität soll eingesetzt werden?',
											defaultValue: gstate.objEdit.fac_plan_renovations[3].quality,
											fctOnChange: handlePlanRenovationChange,
											sdataName: 'sdata_windows_qualities'
										}								
									}) 
								}}>																		
								<EditIcon />
							</Button>
							: '') 
							: ''}														
							</Grid>
						</Grid>														
						</div>								
					</Grid>
					</Grid>	
			</div>	
		}		
		
		else if( reno.name === 'has_renovation_basement') {   // && reno.value === '1') {			
			ret = <div style={{width:270}}>
					<Grid container direction="row" spacing="1">
					<Grid item xs={4}>
						<div style={{display:'inline'}}>{getDiscIcon(reno)}</div>
					</Grid>
					<Grid item xs={8}>
						<div style={{display:'inline', fontWeight:600}}>{ret}</div>				
						<div style={{fontSize:12}}>
							<Grid container direction="row">
							<Grid item xs={10}>
								<RenoTypeLabel reno={gstate.objEdit.fac_plan_renovations[4]} disc="basement" />
							</Grid>
							<Grid item item xs={2}>
							{showEditButton ? 
							(usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?																								
							<Button variant="text"
								onClick={() => { setItemEditorData({
										first:{
											title: "Kellertyp", 
											formSublabel: 'Welcher Kellertyp soll eingesetzt werden?',
											defaultValue: gstate.objEdit.fac_plan_renovations[4].type,
											fctOnChange: handlePlanRenovationChange,
											sdataName: 'sdata_basements_types'
										},
										second:{
											title: "Qualitäten", 
											formSublabel: 'Welche Qualität soll eingesetzt werden?',
											defaultValue: gstate.objEdit.fac_plan_renovations[4].quality,
											fctOnChange: handlePlanRenovationChange,
											sdataName: 'sdata_basements_qualities'
										}
									}) 
								}}>																		
								<EditIcon />
							</Button>
							: '') 
							: ''}																					
							</Grid>
							</Grid>																					
						</div>								
					</Grid>
				</Grid>			
			</div>	
		}				
		
		else if( reno.name === 'has_renovation_heating') {   // && reno.value === '1') {			
			ret = <div style={{width:270}}>
				<Grid container direction="row" spacing="1">
				<Grid item xs={4}>
					<div style={{display:'inline'}}>{getDiscIcon(reno)}</div>
				</Grid>
				<Grid item xs={8}>
					<div style={{display:'inline', fontWeight:600}}>{ret}</div>
					<div style={{fontSize:12}}>
						<Grid container direction="row">
						<Grid item xs={10}>
							<RenoTypeLabel reno={gstate.objEdit.fac_plan_renovations[5]} disc="heating" />
						</Grid>
						<Grid item item xs={2}>
							{showEditButton ? 
							(usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?							
							<Button variant="text"
								onClick={() => { setItemEditorData({
										first:{
											title: "Primärheizung", 
											formSublabel: 'Welches Heizungsystem soll eingesetzt werden?',
											defaultValue: gstate.objEdit.fac_plan_renovations[5].type,
											fctOnChange: handlePlanRenovationChange,								
											sdataName: 'availableHeatingsTypes'
										},
										second:{
											title: "Qualitäten", 
											formSublabel: 'Welche Qualität soll eingesetzt werden?',
											defaultValue: gstate.objEdit.fac_plan_renovations[5].quality,
											fctOnChange: handlePlanRenovationChange,
											sdataName: 'sdata_heatings_qualities'
										}
									}) 
								}}>																		
								<EditIcon />
							</Button>
							: '') 
							: ''}																												
							</Grid>
							</Grid>																					
						</div>								
					</Grid>
				</Grid>			
			</div>										
		}
		
		else if( reno.name === 'has_renovation_pvroof') {   // && reno.value === '1') {			
		
			ret = <div style={{width:270}}>
					<Grid container direction="row" spacing="1">
					<Grid item xs={4}>
						<div style={{display:'inline'}}>{getDiscIcon(reno)}</div>
					</Grid>
					<Grid item item xs={8}>
						<div style={{fontWeight:600}}>{ret}</div>
						<div style={{fontSize:12}}>
							<Grid container direction="row">
							<Grid item xs={10}>
								<RenoTypeLabel reno={gstate.objEdit.fac_plan_renovations[6]} disc="pvroof" />
							</Grid>
							<Grid item item xs={2}>
								{showEditButton ? 
								(usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?															
								<Button variant="text"
									onClick={() => { setItemEditorData({
											first:{
												title: "PV-Anlage Typ", 
												formSublabel: 'Welche PV-Anlage Typ soll eingesetzt werden?',
												defaultValue: gstate.objEdit.fac_plan_renovations[6].type,
												fctOnChange: handlePlanRenovationChange,
												sdataName: 'sdata_pvroofs_types'
											},
											second:{
												title: "Qualitäten", 
												formSublabel: 'Welche Qualität soll eingesetzt werden?',
												defaultValue: gstate.objEdit.fac_plan_renovations[6].quality,
												fctOnChange: handlePlanRenovationChange,
												sdataName: 'sdata_pvroofs_qualities'
											}
										}) 
									}}>																		
									<EditIcon />
								</Button>
								: '') 
								: ''}																																				
							</Grid>
							</Grid>
						</div>	
					</Grid>
					</Grid>	
			</div>	
		}		
		
		// --- everything else for reno2 ---
		else {
			ret = <div style={{width:270}}>
				<Grid container direction="row" spacing="1">
				<Grid item xs={4}>
					<div style={{display:'inline'}}>{getDiscIcon(reno)}</div>						
				</Grid>
				<Grid item xs={8}>
				<div style={{display:'inline', fontWeight:600}}>{ret}</div>
				<div style={{fontSize:12}}>					
					<Grid container direction="row">
						<Grid item xs={10}>
							<RenoTypeLabel reno={gstate.objEdit.fac_plan_renovations2[idx]} />
						</Grid>
						<Grid item item xs={2}>										
						{showEditButton ? 
							(usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?
							<Button variant="text"
								onClick={() => { setItemEditorData({
										first:{
											idx: idx,
											title: reno.label, 
											formSublabel: 'Welches ' + reno.label + '-System soll eingesetzt werden?',
											defaultValue: gstate.objEdit.fac_plan_renovations2[idx].type,
											fctOnChange: handlePlanRenovationChange,								
											sdataName: 'sdata_'+reno.name.replace( 'has_renovation_', '')+'s_types'
										},
										second:{
											idx: idx,
											title: "Qualitäten", 
											formSublabel: 'Welche Qualität soll eingesetzt werden?',
											defaultValue: gstate.objEdit.fac_plan_renovations2[idx].quality,
											fctOnChange: handlePlanRenovationChange,
											sdataName: 'sdata_'+reno.name.replace( 'has_renovation_', '')+'s_qualities'
										}
									}) 
								}}>																		
								<EditIcon />
							</Button> 
							: '') 
							: ''}							
							</Grid>
							</Grid>																					
						</div>								
					</Grid>
				</Grid>			
			</div>										
		}
		
		return ret;
	}
	
	
	useEffect(()=> {
		console.log( 'useEffect for itemEditorSdata', itemEditorData);
		if( itemEditorData.first.sdataName != '')
			setOpenItemEditor( true);

		
	}, [itemEditorData.first.sdataName]);
		
		
	const handleEditorClose = (event) => {
		setState({ 
			...state,
			openEditor: false 
		});

		setItemEditorData({
			first:{
				sdataName:''	
			},
			second:{
				sdataName:''	
			},			
		});
	};		
	
	
	/**
	*	handlePlanRenovationChange() - save the changes in the state
	*/
	const handlePlanRenovationChange = (sdataName, vl, idx) => { 
		console.log( '--> handlePlanRenovationChange', {'sdataName':sdataName, 'vl':(vl), 'idx':idx});

		var planRenovations = gstate.objEdit.fac_plan_renovations;
		var planRenovations2 = gstate.objEdit.fac_plan_renovations2;
		
		var sdn = sdataName.first;
		var val = vl.first;		
		if( sdn === 'sdata_roofs_types') {
			planRenovations[1].type = val;    // 0 - is the _structure_ which its missing here for renovation
		} else if( sdn === 'sdata_fassades_types') {
			planRenovations[2].type = val;			
		} else if( sdn === 'sdata_windows_types') {
			planRenovations[3].type = val;						
		} else if( sdn === 'sdata_basements_types') {
			planRenovations[4].type = val;									
		} else if( sdn === 'sdata_heatings_types' || sdn === 'availableHeatingsTypes') {
			planRenovations[5].type = val;			
		} else if( sdn === 'sdata_pvroofs_types') {
			planRenovations[6].type = val;			
		} else {
			planRenovations2[idx].type = val;			
		}
		
		if( sdataName.second && vl.second) {
			var sdn = sdataName.second;
			var val = vl.second;		
			if( sdn === 'sdata_roofs_qualities') {
				planRenovations[1].quality = val;
			} else if( sdn === 'sdata_fassades_qualities') {
				planRenovations[2].quality = val;			
			} else if( sdn === 'sdata_windows_qualities') {
				planRenovations[3].quality = val;						
			} else if( sdn === 'sdata_basements_qualities') {
				planRenovations[4].quality = val;									
			} else if( sdn === 'sdata_heatings_qualities') {
				planRenovations[5].quality = val;			
			} else if( sdn === 'sdata_pvroofs_qualities') {
				planRenovations[6].quality = val;							
			} else {
				planRenovations2[idx].quality = val;			
			}		
		}
			
		setObjEdit({
			...gstate.objEdit, 
			fac_plan_renovations: planRenovations,
			fac_plan_renovations2: planRenovations2
		})
	}		


	const getIstPlanPercentage = ( ist, plan) => { 
		var ret = 0;
		if( ist)
			ret = (1 - plan/ist); 
		
		if( plan===0 && ist>0)
			ret = 1;
		
		ret = formatNumber( ret, '%', 1) + '%';
		
		return ret; //ret.toFixed(1) + '%'
	}	
	
	
	const evalItem = ( field, unit) =>  {
		var vl = 0;
		try {
			vl = eval( 'gstate.objEdit' + field);
			vl = formatNumber( vl, unit);
		} catch( e) {
			console.error( e);
		}
		return vl;
	}	
	
		
	/**
	*	CompAccordionDetails() - local component for the accordion table
	*/
	const CompAccordionDetails = ( props) => { 
				
		const { setOpenLicenseDialog } = useLicense();							
						
		return (
			<>		
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className={classes.icon}/>}
					aria-controls="panel1a-content"
					id="panel1a-header"	
					sx={{
						backgroundColor: (theme) => theme.palette.primary.main,
						color: (theme) => theme.palette.primary.contrastText,
					}}
				>
					<Typography><Box className={classes.accordionBox}>Detaillierte Berechnung</Box></Typography>
				</AccordionSummary>
				
				<AccordionDetails>				
					{checkLicense(gstate.usr.lic)=='insufficient' ?
					<div style={{margin:20}}>
						<Badge badgeContent={'UPGRADE'} color="license">
							<QeeColorButtonPrimary disableElevation 
								className={classes.button} 
								onClick={()=>setOpenLicenseDialog( true)}>
								Werte anzeigen
							</QeeColorButtonPrimary>										
						</Badge>				
					</div>
					:
					<>	
					<Grid xs={12}>
						<Box className={classes.subTitle}><Typography variant='h2'>Aktueller energetischer Zustand</Typography></Box>
					</Grid>						
					<Grid container
						direction='row'>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="floor_area"
								label="Geschossfläche"
								name="floor_area"
								value={gstate.objEdit.fac_energy.floor_area}	
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="building_length"
								label="Gebäudelänge"
								name="building_length"
								value={gstate.objEdit.fac_energy.building_length}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="building_width"
								label="Gebäudebreite"
								name="building_width"
								value={gstate.objEdit.fac_energy.building_width}
								className={classes.textOutput}											
							/>
						</Grid>									
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="building_height"
								label="Gebäudehöhe"
								name="building_height"
								value={gstate.objEdit.fac_energy.building_height}
								className={classes.textOutput}											
							/>
						</Grid>																		
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="building_fassade_area"
								label="Fassadenfläche"
								name="building_fassade_area"
								value={gstate.objEdit.fac_energy.building_fassade_area}									
								className={classes.textOutput}											
							/>
						</Grid>									
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="building_roof_area"
								label="Dachfläche"
								name="building_roof_area"
								value={gstate.objEdit.fac_energy.building_roof_area}									
								className={classes.textOutput}
							/>
						</Grid>									
					</Grid>
					
					<Grid container
						direction='row'>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">W/K</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="transmission_lost"
								label="Transmissionswärmeverlust"
								name="transmission_lost"
								value={gstate.objEdit.fac_energy.transmission_lost}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">W/K</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="ventilation_lost"
								label="Lüftungswärmeverlust"
								name="ventilation_lost"
								value={gstate.objEdit.fac_energy.ventilation_lost}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">KWh/a*m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="internal_gain"
								label="Interne Wärmegewinne"
								name="internal_gain"
								value={gstate.objEdit.fac_energy.internal_gain}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">KWh/a*m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="solar_gain"
								label="Solare Wärmegewinne"
								name="solar_gain"
								value={gstate.objEdit.fac_energy.solar_gain}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">KWh/a*m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="annual_heating_demand"
								label="Jahres Heizwärmebedarf"
								name="annual_heating_demand"
								value={gstate.objEdit.fac_energy.annual_heating_demand}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">KWh/a*m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="warm_water_demand"
								label="Warmwasserleistung"
								name="warm_water_demand"
								value={gstate.objEdit.fac_energy.warm_water_demand}									
								className={classes.textOutput}											
							/>
						</Grid>									
					</Grid>	
					</>
					}
				</AccordionDetails>

			</Accordion>
			</>	
		)
	}

	
	const getTooltipOverdueOldness = ( r) => {
		return r.label+": Ist " + r.oldness + "Jahre alt. Eine Sanierung wird nach "+r.lifetime+" Jahren empfohlen!";
	}
	const getTooltipOverdueHtc = ( r) => {
		return r.label+": Um den selektierten KfW-Standard zu erreichen, muss diese Sanierung ausgeführt werden!";
	}	
	
	
	/**
	*	isRenoCond() - checks the configurated condition, if any
	*/
	const isRenoCond = ( r) => {		
		var fac = gstate.objEdit;
		if( r.cond) {   // if some cond specified as string
			var code = "(function(){console.log( 'r.cond', '"+r.cond+"'); return " + r.cond + "})";
			//console.log( 'r.cond code string', code);				
			var f = eval( code);
			//console.log( 'r.cond evaluated code', f.call( r));
			return f.call( r);		
		} else {
			return true;   // if no cond specified
		}
	}
	
	
	const getHintSupport = () => {
		return 'Neben den hier ausgewiesenen Zuschüssen gibt es noch weitere Förderangebote, z.B. zinsgünstige Darlehen, die du unter dem folgenden Link findest:'
	}
	

	/**
	*	SwitchRighted - used for user rights purposes
	*/
	const SwitchRighted = withStyles()(
		({ classes, checked, ...props }) => {
			const right = usrRight( gstate.objEdit);
			const isOwner = right === 'owner';
			const isWriter = right === 'writer';
			const isEnabled = isWriter || isOwner;

			return (
				isEnabled && (
					<Switch
						{...props}
						checked={checked}
						classes={{
							track: classes.switchTrack,
							switchBase: classes.switchBase,
							colorPrimary: classes.switchPrimary,
						}}
						color={"default"}			  
					/>
				)
			);
		}
	);
	
	
	/**
	*	CompPlanRenovationsSelect() - local component for the planned renovation selection
	*/	
	/*
		<Grid item>
			<FacCompEnergyResultDiffCard
				objEdit={gstate.objEdit}
				title={'Verbesserung der Endenergieeffizienz (kWh/m² Jahr)'} 
				fieldIst={'end_ist'} 
				fieldPlan={'end_plan'} 
				bShowEnergyclass={true} 
				{...props}
			/>
		</Grid>	
	*/
	const CompPlanRenovationsSelect = () => { 
	
		const { setOpenConfluenceDialog } = useConfluence();												
	
		return (
			<Grid
				container
				direction="column"
				justifyContent="flex-begin"
				alignItems="left"
				spacing={gstate.isXS ? 0 : 1}
			>
			<Grid item>
				<Grid
					container
					direction="row"
					justifyContent="flex-begin"
					alignItems="left"
					spacing={gstate.isXS ? 0 : 1}
				>
					<Grid item xs={10} md={12} lg={7}>
						<Typography variant='h2'>Auswahl Maßnahmen</Typography>
						<Box className={classes.box}>
							<FormControl component="fieldset" variant="standard" sx={{width:'100%'}}>
								<FormHelperText><Typography variant='subtitle2'>Die geplanten Sanierungmaßnahmen spiegeln sich in der Energieeffizeinzklasse, den CO2-Austoß und in den Kosten wider.</Typography></FormHelperText>
								<FormGroup>						

									<TableContainer className={classes.tableContainer}>
										<Table sx={{ minWidth: 150 }} aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell sx={{...sxTableCell}}></TableCell>
													{!gstate.isXS ?
													<>
													<TableCell sx={{...sxTableCell, width:42}} align="center"><Typography variant='h5'>{gstate.objEdit.fac_energy.energyclass_plan === 'singles' ? 'Lebensdauer' : 'Zielwert'}</Typography></TableCell>													
													<TableCell sx={sxTableCell} align="right"><Typography variant='h5'>Kosten</Typography></TableCell>
													<TableCell sx={sxTableCell} align="right"><Typography variant='h5'>Förderung</Typography></TableCell>
													</>
													:''}
												</TableRow>
											</TableHead>
											<TableBody>
												{gstate.objEdit.fac_plan_renovations ? gstate.objEdit.fac_plan_renovations.map((r, idx) => (
												isRenoCond( r) ?
												<TableRow
													key={r.name}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row" sx={{padding:1}}>
														{gstate.objEdit.fac_energy.energyclass_plan === 'singles' ?
														<FormControlLabel style={{width:390}}
															checked={r.value === '1' ? true : false} 
															name={r.name}													
															control={
																r.disabled ?
																<>		
																<QeeTooltip title={'Dieser Bauteil wurde durch eine Regel/Gesetz (z.B. durch die Aktivierung eines anderes Bauteiles) automatisch aktiviert.'}> 
																<SwitchRighted 
																	checked={r.value === '1' ? true : false}
																	onChange={handleOnChangePlanRenovationsBlock}																
																	classes={{
																		track: classes.switchTrack,
																		switchBase: classes.switchBase,
																		colorPrimary: classes.switchPrimary,
																	}}
																	color={"default"}																
																/>
																</QeeTooltip>
																</>																
																:
																<SwitchRighted
																	checked={r.value === '1' ? true : false}
																	onChange={handleOnChangePlanRenovations}																
																	classes={{
																		track: classes.switchTrack,
																		switchBase: classes.switchBase,
																		colorPrimary: classes.switchPrimary,
																	}}
																	color={"default"}																
																/>
															}
															label={getPlanRenovationLabel( r, idx, r.value=='1' ? true : false)}
														/>
														:
														<Typography sx={{paddingLeft:1}}>{getPlanRenovationLabel( r, idx, false)}</Typography>
														}
													</TableCell>
													{!gstate.isXS ?
													<>
													<TableCell className={classes.tableColumnHeader} align="center">
														{gstate.objEdit.fac_energy.energyclass_plan === 'singles' ? 
															<div style={{display: 'flex', alignItems: 'center'}}>
																{r.overdue==='1' ? 
																	<QeeTooltip title={getTooltipOverdueOldness(r)}> 
																	<div style={{display:'block', textAlign:'center'}}>
																		<FmdBadIcon sx={{fontSize:32, fontWeight:800, color:'red'}}/>
																		<div className={classes.miniLabelRed}>Sanierung</div>
																		<div className={classes.miniLabelRed}>Alter {r.oldness} Jahre</div>
																	</div>
																	</QeeTooltip>
																	:
																	<QeeTooltip title={getTooltipOverdueOldness(r)}> 
																		<div style={{display:'block', textAlign:'center'}}><CheckIcon sx={{fontSize:32, fontWeight:800, color:'green'}}/><div className={classes.miniLabelGreen}>In Ordnung</div></div>
																	</QeeTooltip>
																}
															</div>
															:
															<div style={{display: 'flex', alignItems: 'center'}}>
																{r.overdue==='1' ? 
																	<QeeTooltip title={getTooltipOverdueHtc(r)}> 
																	<div style={{display:'block', textAlign:'center'}}>
																		<FmdBadIcon sx={{fontSize:32, fontWeight:800, color:'red'}}/>
																		<div className={classes.miniLabelRed}>Sanierung</div>
																	</div>
																	</QeeTooltip>
																	:
																	<div style={{display:'block', textAlign:'center'}}><CheckIcon sx={{fontSize:32, fontWeight:800, color:'green'}}/><div className={classes.miniLabelGreen}>In Ordnung</div></div>																							
																}
															</div>
														}
													</TableCell>													
													<TableCell className={classes.tableColumnHeader} align="right">
														<Typography variant='body2'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( r.cost).replace(/\D00(?=\D*$)/, '')}</Typography>
													</TableCell>
													<TableCell className={classes.tableColumnHeader} align="right">
														{gstate.objEdit.fac_energy.energyclass_plan === 'singles' ?
															<>
															<Typography variant='body2'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( r.support).replace(/\D00(?=\D*$)/, '')}</Typography>
															<>
															{r.value==='1' && r.support_label && r.support_desc ?
																<>
																<QeeTooltip title={r.support_desc}> 
																<IconButton>
																	<InfoOutlinedIcon style={{color:'#a1bda1'}}/>
																</IconButton>
																</QeeTooltip>																
																</>
															:
															''}
															</>
															</>				
														:<Typography>siehe Gesamt</Typography>}
													</TableCell>
													</>
													:''}
												</TableRow>
												:''
											))											  
											:<></>}	
												<TableRow
													key="total"
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													{!gstate.isXS ?
													<>
													<TableCell sx={sxTableCell}><Typography variant='h5'>Zwischensumme energetische Sanierungen</Typography></TableCell>												
													<TableCell sx={sxTableCell}></TableCell>																									
													<TableCell sx={{...sxTableCell}} align="right">
														<Typography variant='h5'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_renovations_cost).replace(/\D00(?=\D*$)/, '')}</Typography>
													</TableCell>
													<TableCell sx={{...sxTableCell, fontWeight:800}} align="right">
														<Typography variant='h5'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_renovations_support).replace(/\D00(?=\D*$)/, '')}</Typography>
													</TableCell>												
													</>
													:
													<TableCell sx={sxTableCell}>
														<div><Typography sx={sxTableCell} variant='body2'>Kosten: {Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_renovations_cost).replace(/\D00(?=\D*$)/, '')}</Typography></div>
														<div><Typography sx={sxTableCell} variant='body2'>Förderungen: {Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_renovations_support).replace(/\D00(?=\D*$)/, '')}</Typography></div>														
													</TableCell>												
													}
												</TableRow>												
											</TableBody>
										</Table>
									</TableContainer>
									<QeeHintBox type="help" 
										text={
											<React.Fragment>
												<Typography variant='subtitle2'><strong>Hinweis:</strong> <span dangerouslySetInnerHTML={{__html: getHintSupport()}}></span></Typography>
												<strong><a href='#' onClick={()=>setOpenConfluenceDialog('/forderungen')}>
													Förderungen für Haus Sanierungen
												</a></strong>
											</React.Fragment>} 
									/>
								
								</FormGroup>
								
							</FormControl>																																						
								
							
							<FormControl component="fieldset" variant="standard" sx={{width:'100%'}}>
								<FormGroup  style={{borderTop:`1px solid green`, marginTop:40, paddingTop:20}}>
									<FormHelperText><Typography variant='subtitle2'>Die geplanten nicht förderungsfähigen Sanierungmaßnahmen spiegeln sich nur in den Kosten wieder.</Typography></FormHelperText>								
									<TableContainer className={classes.tableContainer}>
										<Table sx={{ minWidth: 150 }} aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell></TableCell>
													{!gstate.isXS ?
													<>
													<TableCell sx={{...sxTableCell, width:42}} align="center"><Typography variant='h5'>Lebensdauer</Typography></TableCell>													
													<TableCell sx={sxTableCell} align="right"><Typography variant='h5'>Kosten</Typography></TableCell>
													<TableCell sx={sxTableCell} align="right"><Typography variant='h5'>Förderung</Typography></TableCell>
													</>
													:''}
												</TableRow>
											</TableHead>
											<TableBody>
												{gstate.objEdit.fac_plan_renovations2 ? gstate.objEdit.fac_plan_renovations2.map((r, idx) => (
												isRenoCond( r) ?
												<TableRow
													key={r.name}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row" sx={{padding:1}}>	
														<FormControlLabel
															checked={r.value === '1' ? true : false} 
															name={r.name}													
															control={
																<SwitchRighted 
																	checked={r.value === '1' ? true : false}
																	onChange={handleOnChangePlanRenovations2}																
																	classes={{
																		track: classes.switchTrack,
																		switchBase: classes.switchBase,
																		colorPrimary: classes.switchPrimary,
																	}}
																	color={"default"}																
																/>
															}
															label={getPlanRenovationLabel( r, idx, r.value=='1' ? true : false)}
														/>
													</TableCell>
													{!gstate.isXS ?
													<>
													<TableCell className={classes.tableColumnHeader} align="right">
														<div style={{display: 'flex', alignItems: 'center'}}>
															{r.overdue==='1' ? 
																<QeeTooltip title={getTooltipOverdueOldness(r)}> 
																<div style={{display:'block', textAlign:'center'}}>
																	<FmdBadIcon sx={{fontSize:32, fontWeight:800, color:'red'}}/>
																	<div className={classes.miniLabelRed}>Sanierung</div>
																	<div className={classes.miniLabelRed}>Alter {r.oldness} Jahre</div>
																</div>
																</QeeTooltip>
																:
																<QeeTooltip title={getTooltipOverdueOldness(r)}> 
																<div style={{display:'block', textAlign:'center'}}><CheckIcon sx={{fontSize:32, fontWeight:800, color:'green'}}/><div className={classes.miniLabelGreen}>In Ordnung</div></div>																							
																</QeeTooltip>		
															}
														</div>
													</TableCell>													
													<TableCell className={classes.tableColumnHeader} align="right">
														<Typography variant='body2'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( r.cost).replace(/\D00(?=\D*$)/, '')}</Typography>
													</TableCell>
													<TableCell className={classes.tableColumnHeader} align="right">	
														<Typography variant='body2'>keine</Typography>			
													</TableCell>
													</>
													:''}
												</TableRow>
												:''
											))											  
											:<></>}	
												<TableRow
													key="total"
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													{!gstate.isXS ?
													<>
													<TableCell sx={sxTableCell}><Typography variant='h5'>Zwischensumme nicht-energetische Sanierungen</Typography></TableCell>												
													<TableCell sx={sxTableCell}></TableCell>																									
													<TableCell sx={{...sxTableCell}} align="right"><Typography variant='h5' sx={{fontWeight:800}}>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_renovations2_cost).replace(/\D00(?=\D*$)/, '')}</Typography></TableCell>
													<TableCell sx={{...sxTableCell, fontWeight:800}} align="right"><Typography></Typography></TableCell>												
													</>
													:
													<TableCell>
														<div><Typography sx={sxTableCell} variant='body2'>Kosten: {Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_plan_renovations2_cost).replace(/\D00(?=\D*$)/, '')}</Typography></div>
													</TableCell>																									
													}
												</TableRow>												
											</TableBody>
										</Table>
									</TableContainer>								
								
								</FormGroup>
								
							</FormControl>																														
						</Box>
					</Grid>
					<Grid item xs={10} md={12} lg={5}>
						<Typography variant='h2'>Bewertungen</Typography>
						<Box className={classes.box}>
							<Grid item>
								<Box className={classes.resultBox}>
									<DiagramEnergyClass 
										title='Verbesserung der Endenergieeffizienz' 
										showEnergyPlan={true}
										energyIst='end_ist' 
										energyPlan='end_plan' 
										energyIstLabel='vorher' 
										energyPlanLabel='nacher' 								
									/>		
								</Box>							
							</Grid>						
							<Grid container direction="column" spacing={2}>
								{gstate.objEdit.fac_type == '100' ? 
								<>									
								<Grid item>{compEnergyResultDiffCard( 'Verbesserung der Endenergieeffizienz (kWh/m² Jahr)', 'end_ist', 'end_plan', true)}</Grid>								
								<Grid item>{compEnergyResultDiffCard( 'Verbesserung des Primärenergieeffizienz (kWh/m² Jahr)', 'primary_ist', 'primary_plan', true)}</Grid>
								<Grid item>{compEnergyResultDiffCard( 'Reduzierung des CO2 Austoßes (to/Jahr)', 'co2_ist', 'co2_plan', false)}</Grid>
								<Grid item>{compEnergyResultDiffCard( 'Reduzierung der monatlichen Heizkosten (Euro)', 'monthlycost_ist', 'monthlycost_plan', false)}</Grid>
								</>
								:''}
								<Grid item>
									<Box className={classes.resultBox} style={{width:'auto'}}>
										<Typography variant='h3' color="text.primary" gutterBottom style={{whiteSpace:'nowrap'}}>
											Investitionsrechnung
										</Typography>	
										{compInvestInvoice()}
									</Box>
								</Grid>
							</Grid>	
							{gstate.objEdit.fac_type == '100' ? 					
							<>
							<Grid container direction="row" spacing={2}>							
								<Grid item xs={12} sm={6}>{compEnergyResultInfoCard( 'Amortisationszeit', '["fac_investment"]["amortization_years"]', 'Jahre')}</Grid>							
								<Grid item xs={12} sm={6}>{compEnergyResultInfoCard( 'Einsparpotenzial', '["fac_investment"]["savings_ratio"]', '%')}</Grid>														
							</Grid>
							</>
							:''}
						</Box>		
					</Grid>								
				</Grid>	
			</Grid>
		</Grid>	
		)
	}
	
	
	/**
	*	compEnergyResultDiffCard() - card for showing results
	*/	
	const compEnergyResultDiffCard = ( title, fieldIst, fieldPlan, bShowEnergyclass) => { 
		
		return (
			<Card 
				variant="outlined"
				className={classes.resultCard}>
			<React.Fragment>
				<CardContent style={{padding:gstate.isXS ? 0 : '', paddingBottom:20}}>
					<Typography sx={{ fontSize: 16, fontWeight:600 }} color="text.primary" gutterBottom style={{whiteSpace:!gstate.isXS ? 'nowrap' : ''}}>
						{title}
					</Typography>
					<Grid container
						direction="row"
						style={{width:gstate.isXS ? 350 : ''}}>
						<Grid item xs={3} xl={4}>
							<Typography sx={{ fontSize: 12 }} className={classes.textIst} gutterBottom>
								vorher
							</Typography>
							<Grid container direction="row">
								<Grid item>							
									<Typography style={{fontSize:20}} sx={{ mb: 1.5, fontWeight: 800 }} className={classes.textIst}>
										{gstate.objEdit.fac_energy[fieldIst]}
									</Typography>
								</Grid>
								<Grid item style={{paddingLeft:10, marginLeft:5 }}>
									{bShowEnergyclass ?
										<LabelEnergyClass xs={true}  obj={gstate.objEdit} energy={fieldIst} />	
										:
										<></>
									}
								</Grid>									
							</Grid>
						</Grid>
						{!gstate.isXS ?
						<Grid item xs={2} xl={2}>
							<Box>
								{gstate.objEdit.fac_energy[fieldIst] > gstate.objEdit.fac_energy[fieldPlan] ?
									<TrendingDownIcon sx={{fontSize:48}} className={classes.iconGreen}/>
									:
									gstate.objEdit.fac_energy[fieldIst] < gstate.objEdit.fac_energy[fieldPlan] ?
										<TrendingUpIcon sx={{fontSize:48}} className={classes.iconRed}/>
										:
										<TrendingFlatIcon sx={{fontSize:48}} className={classes.iconGrey}/>
								}
							</Box>
						</Grid>
						:
						<div style={{width:20}}></div>
						}
						<Grid item xs={3} xl={4}>
							<Typography sx={{ fontSize: 12 }} className={classes.textPlan} gutterBottom>
								nachher
							</Typography>
							<Grid container direction="row">
								<Grid item>							
									<Typography style={{fontSize:20}} sx={{ mb: 1.5, fontWeight: 800  }} className={classes.textPlan}>
										{gstate.objEdit.fac_energy[fieldPlan]}
									</Typography>
								</Grid>
								<Grid item style={{paddingLeft:10, marginLeft:5}}>
									{bShowEnergyclass ?
										<LabelEnergyClass xs={true} obj={gstate.objEdit} energy={fieldPlan} />	
										:
										<></>
									}
								</Grid>									
							</Grid>							
						</Grid>
						<Grid item xs={3} xl={2}>
							<Box>
								{gstate.objEdit.fac_energy[fieldIst] > gstate.objEdit.fac_energy[fieldPlan] ?
									<Box className={classes.boxPercentageGreen}>{getIstPlanPercentage( gstate.objEdit.fac_energy[fieldIst], gstate.objEdit.fac_energy[fieldPlan])}</Box>
									:
									gstate.objEdit.fac_energy[fieldIst] < gstate.objEdit.fac_energy[fieldPlan] ?
										<Box className={classes.boxPercentageRed}>{getIstPlanPercentage( gstate.objEdit.fac_energy[fieldIst], gstate.objEdit.fac_energy[fieldPlan])}</Box>
										:
										<Box className={classes.boxPercentage}>{getIstPlanPercentage( gstate.objEdit.fac_energy[fieldIst], gstate.objEdit.fac_energy[fieldPlan])}</Box>
								}
							</Box>
						</Grid>						
					</Grid>
				</CardContent>
				<CardActions>
				</CardActions>
			</React.Fragment>
			</Card>
		)
	}
		
				
	/**
	*	compEnergyResultInfoCard() - card for showing info results
	*/	
	const compEnergyResultInfoCard = ( title, field, unit) => { 
	
		//console.log( 'compEnergyResultInfoCard', {title, fieldIst, fieldPlan, bShowEnergyclass});
		
		return (
			<Card 
				variant="outlined"
				className={classes.resultCard}
			>
			<React.Fragment>
				<CardContent>
					<Typography sx={{ fontSize: 16, fontWeight:600 }} color="text.primary" gutterBottom style={{whiteSpace:'nowrap'}}>
						{title}
					</Typography>					
					<Grid container
						direction="row">
						<Grid item xs={12} style={{width:300}}>
							<Grid container direction="row">
								<Grid item>							
									<Typography style={{fontSize:20}} sx={{ mb: 1.5, fontWeight: 800 }} className={classes.textPlan}>
										{evalItem( field, unit)} {unit}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

					</Grid>
				</CardContent>
				<CardActions>
				</CardActions>
			</React.Fragment>
			</Card>
		)
	}
	
	
	/**
	*	compInvestInvoice() - card for showing results (deleted for TableCntainer component={Paper})
	*/	
	const compInvestInvoice = () => { 
	
		return(
			<TableContainer>
				<Table sx={{ minWidth: 150, width: gstate.isXS ? '90%' : '100%' }} aria-label="invest table">
					<TableBody>
						<TableRow key="first" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{padding:2, "& td": { border: 0 }}}><Typography>Sanierungskosten</Typography></TableCell>
							<TableCell align="right">
								<Typography sx={{...sxTableCell}} variant='body2'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_investment.plan_renovations_cost).replace(/\D00(?=\D*$)/, '')}</Typography>
							</TableCell>
						</TableRow>
						
						<TableRow key="second" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{padding:2}}><Typography>Förderungen</Typography></TableCell>
							<TableCell align="right">
								<Typography sx={{...sxTableCell}} variant='body2'>- {Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_investment.plan_renovations_support).replace(/\D00(?=\D*$)/, '')}</Typography>
							</TableCell>													
						</TableRow>
						
						{gstate.objEdit.fac_ownership==='buyer' ? 
							<>
							<TableRow key="third" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell><Typography variant="h5">Zwischensumme</Typography></TableCell>												
								<TableCell align="right">
									<Typography variant='h5'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( 
										gstate.objEdit.fac_investment.plan_renovations_cost - gstate.objEdit.fac_investment.plan_renovations_support
									).replace(/\D00(?=\D*$)/, '')}</Typography>
								</TableCell>													
							</TableRow>				

							<TableRow key="fourth" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component="th" scope="row" sx={{padding:2}}><Typography>Kaufpreis</Typography></TableCell>
								<TableCell align="right">
									<Typography sx={{...sxTableCell}} variant='body2'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_investment.price).replace(/\D00(?=\D*$)/, '')}</Typography>
								</TableCell>													
							</TableRow>	

							<TableRow key="fifth" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component="th" scope="row" sx={{padding:2}}><Typography>Kaufnebenkosten</Typography></TableCell>
								<TableCell align="right">
									<Typography sx={{...sxTableCell}} variant='body2'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_investment.additional).replace(/\D00(?=\D*$)/, '')}</Typography>
								</TableCell>													
							</TableRow>						
							</>
						:
							<TableRow/>
						}		
						<TableRow key="total" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell sx={sxTableCell}><Typography variant='h5'>Gesamtinvestition</Typography></TableCell>												
							<TableCell align="right">
								<Typography variant='h5'>{Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format( gstate.objEdit.fac_investment.value).replace(/\D00(?=\D*$)/, '')}</Typography>
							</TableCell>
						</TableRow>												
					</TableBody>
				</Table>
			</TableContainer>		
		)
	
	}
	
	
	const startRenovationWiz = () => {
		alert( 'start')
	}
	
	
	const rgIconStyle = () => {
		if( !gstate.isXS)
			return { width:100, height:100 }
		else
			return { width:36, height:36 }
	}			
	
	
	// general render
    return (
        <div class="form-group">
			<form  noValidate>			
				<FacTitle fac={gstate.objEdit} showPicture={true} />

				<IconButton
					className={classes.floatingButton}
					color="primary"
					aria-label="Open Chat"
					onClick={()=>handleClickOpenChat( gstate.objEdit.id)}
				>
					<ChatIcon style={{ fontSize: 60 }} />
				</IconButton>								
				
				{gstate.objEdit.fac_type == '100' ? 					
					gstate.objEdit.fac_energy.end_ist ? 
						<Grid container 
							spacing={2} 
							direction="column" 
							justifyContent="left" 
							alignItems="left">
							{/*
							<Grid item xs={12} style={{paddingBottom: 0}}>
								<Typography variant='h1'>Aktueller energetischer Zustand</Typography>	
								<Typography>Der aktuelle Zustand der Immobilie wurde aufgrund der eingegeben Daten berechnet.</Typography>	
							</Grid>	
							
							<Grid item xs={12}>
								<FacRenovationIst />
							</Grid>
							
							<Grid item xs={12}>
								{CompAccordionDetails()}														
							</Grid>
							*/}

							{gstate.objEdit.fac_energy.end_plan /* && checkLicense(gstate.usr.lic)!='insufficient' */ ? 					
								<Grid item xs={12}>
									<Typography variant='h1'>Geplanter energetischer Zustand</Typography>
									<Grid container 
										spacing={2} 
										direction="row" 
										justifyContent="left" 
										alignItems="left">
										
										{gstate.objEdit.fac_type == "100" ?
										<Grid item md={12} xs={12}>
											<Typography>Möchtest du einen bestimmten KfW-Standard erreichen oder Einzelmaßnahmen planen?</Typography>
											<QeeRadioGroup
												defaultValue={gstate.objEdit.fac_energy.energyclass_plan}
												fctOnChange={handleChangeEnergyclassPlan}
												sdata={[
													{value:'kfw40', label:'kfW40', icon:<FacKfw40Icon style={rgIconStyle()}/>},
													{value:'kfw55', label:'kfW55', icon:<FacKfw55Icon style={rgIconStyle()}/>},								
													{value:'kfw70', label:'kfW70', icon:<FacKfw70Icon style={rgIconStyle()}/>},								
													{value:'kfw85', label:'kfW85', icon:<FacKfw85Icon style={rgIconStyle()}/>},								
													{value:'singles', label:'Einzelmaßnahmen', icon:<FacKfwSinglesIcon style={rgIconStyle()}/>},																											
												]}
											/>
											{ validationErrors[0] ? ( <div style={{textAlign:"center"}}><small id="selError" className="form-text error">{validationErrors[0]}</small></div> ) :( <></> ) }							
										</Grid>	
										: ''}	
										<Grid item md={12} xs={12}>
											<Typography variant='h1'>Sanierungsplaner</Typography>
											<div style={{maxWidth:1000}}>
											<Typography>Basierend auf den <strong>ausgewählten Sanierungsmaßnahmen</strong>, siehst du gleich die Verbesserungen des energetischen Zustands,		
											der monatlichen Heizkosten und der CO2 Emissionen auf einen Blick <strong>(unter Bewertungen)</strong>.
											Und das alles im direketen Zusammenhang mit möglichen Förderungen und notwendigen Investitionen.
											</Typography>
											</div>
											{CompPlanRenovationsSelect()}								
										</Grid>
										}							
									</Grid>
								</Grid>											
							: 	
								<FacEnergyclassEmpty energyPlanEmpty={true} fctOpenRenovationPlan={props.fctOpenRenovationPlan}/>
							}																	
						</Grid>
					:
						<FacEnergyclassEmpty energyIstEmpty={true} fctOpenRenovationIst={props.fctOpenRenovationIst}/>
				:
					<Grid item md={12} xs={12}>
						<Typography variant='h1'>Sanierungsplaner</Typography>
						<div style={{maxWidth:1000}}>
						<Typography>Basierend auf den <strong>ausgewählten Sanierungsmaßnahmen</strong>, siehst du gleich die Höhe der möglichen Förderungen und notwendigen Investitionen <strong>(unter Bewertungen)</strong>.</Typography>
						</div>
						{CompPlanRenovationsSelect()}								
					</Grid>														
				}	
			</form>
						
			<QeeRadioGroupEditor 
				data={()=>{return itemEditorData}}				
				fctOnChange	={itemEditorData.fctOnChange}				
				fctOnClose	={handleEditorClose}
			/>				
						
		</div>
    )
}
export default FacRenovationReport;