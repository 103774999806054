import axios from "axios";
import { setAxiosAuthToken } from '../../../util/auth';

//const _BASE_URL = "http://localhost:5000";

export const postRequest = (path, data) => {
	
	console.log( 'postRequest', [path, data]);
	
	setAxiosAuthToken();
	return axios.post(`${path}`, data, {
		headers: {
			"Content-Type": "application/json",
		},
	});
};

export const getRequest = (path) => {
	
	console.log( 'postRequest', path);
	
	setAxiosAuthToken();
	return axios.get(`${path}`, {
		headers: {
			"Content-Type": "application/json",
		},
	});
};
