import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { useAuth } from "../context/provider";
import QeeSlideDialog from './qeeSlideDialog';
import SwipeablePictures from './swipeablePictures';
import docSample from '../assets/doc-sample.png';

const styles = (theme) => ({
    // Define your styles here if needed
});

const QeePicturesGalleryDialog = React.forwardRef((props, forwardedRef) => {
    const [pics, setPics] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const { gstate, setOpenQeeDialog } = useAuth();    

    const useStyles = makeStyles({
        // Define your styles here if needed
    });            
    const classes = useStyles();

    const [state, setState] = useState({
        isLoading: false,
    });

    const handlePicturesGalleryOpen = (pcs, activeStep) => {
        if (pcs.length) {
            pcs.forEach(picture => {
                picture.urlImg = removeDimensionsFromUrl(picture.url);
            });
            setPics(pcs);
            setActiveStep(activeStep);    
            setOpenQeeDialog({ open: true, id: 'dlgPicturesGallery' });
        }
        if (gstate.isXS) {
            setTimeout(() => {
                window.scrollTo(0, 1);
            }, 0);
        }
    }   

    const handlePicturesGalleryClose = () => {
        if (gstate.isXS) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 0);
        }   
        setOpenQeeDialog(false);    
    };

    useImperativeHandle(forwardedRef, () => ({    
        handlePicturesGalleryOpen,
        handlePicturesGalleryClose
    })); 

    useEffect(() => {
        console.log('QeePicturesGalleryDialog.useEffect', [pics, activeStep]);
    }, [pics.length, activeStep])   

    const getWidth = () => {
        if (gstate.isXS) return 320;
        else if (gstate.isSM) return 600;
        else if (gstate.isMD) return 900;
        else if (gstate.isLG) return 1200;
        else if (gstate.isXL) return 1536;
    }

    const isImage = (name) => {
        // Implement your logic to check if it's an image file
    }   

    function removeDimensionsFromUrl(url) {
        const dimensionRegex = /\/[wh]=\d+(&h=\d+)?$/;
        const xDimensionRegex = /\/\d+x\d+$/;
        let cleanedUrl = url.replace(dimensionRegex, '');
        cleanedUrl = cleanedUrl.replace(xDimensionRegex, '');
        return cleanedUrl;
    }   

    const FileCard = () => (
        <SwipeablePictures images={pics} activeStep={activeStep} width={getWidth()} />                                                                    
    );  

    return (        
        <>
            <div ref={forwardedRef}></div>                
            <QeeSlideDialog 
                id='dlgPicturesGallery'
                title='Bildergalerie' 
				hideDialogAppBar={true}
				darkMode={true}
                formComponent={
                    <div style={{backgroundColor:'#222 !important'}}>
                        {state.isLoading ? 
                            <div className={classes.root}>
                                <CircularProgress size={50} className={classes.uiProgess} />
                            </div>      
                        : 
                        (() => {
                            if (gstate.isXL) {
                                return (
                                    <div style={{
                                        //position: 'absolute', left: '35%', top: '30%',
                                        //transform: 'translate(-20%, -20%)',
                                        //width: '100%'
                                    }}>
                                        <FileCard />
                                    </div>
                                );
                            } else if (gstate.isXS) {
                                return (
                                    <div style={{ marginTop: 70, marginLeft: 30, height: 350 }}>
                                        <FileCard />
                                    </div>
                                );
                            } else {
                                return (
                                    <div style={{ marginTop: 70, marginLeft: 50, width: '100%' }}>
                                        <FileCard />
                                    </div>
                                );
                            }
                        })()}
                    </div>
                }   
                hideDialogActions={true}                
                submitDisabled={true}
                paddingContent={0}
                darkMode={true}
                forceFullWidth={true}
            />
        </> 
    )
});

export default withStyles(styles)(QeePicturesGalleryDialog);
