// login.js

// Material UI components
import React, { Component } from 'react';
import react, { useState, useEffect, useLayoutEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from '../components/footer';
import imageMissing from '../assets/image-missing.jpg';
import withBackground from '../components/withBackground';
import LoginSocialButtons from '../components/qeeLoginSocialButtons';
import logo from '../assets/app-logo.png';
import backgroundImg from "../assets/house-landscape.jpg";
import { resetVersion } from "../api/service";
import { useAuth } from "../context/provider";

import { useNavigate } from "react-router-dom";

import axios from 'axios';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		borderRadius: theme.spacing(1),
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progess: {
		position: 'absolute',
		marginLeft: theme.spacing(1), 
	}
});


const Login = ( props) => {

	const { gstate } = useAuth();		
	
	const [state, setState] = useState( {
		//email: '',
		//password: '',
		errors: {},
		loading: false,
		isMDUpper: false,
		width: window.innerWidth,				
	});		
	const [email, setEmail] = useState( '');
	const [password, setPassword] = useState( '');	
	
	const { classes } = props;
	//const [errors, setErrors] = useState( state.errors);
	//const [loading, setLoading] = useState( state.loading);		
	const navigate = useNavigate();					

	function handleWindowSizeChange() {
		setState({
			...state,
			width: window.innerWidth
		});
	}		
	useEffect( () => {	
	
		resetVersion();	
	
		document.title = process.env.QEE_APP_NAME;    //'Vreed.INSIGHT';
	
		setState( {
			...state,
			isMDUpper: state.width>=900 ? true : false
		});
		console.log( 'state', state);				
		
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
												
	}, [state.width]);		
	
	
	const handleChange = (event) => {
		console.log( 'handleChange', [event.target.name, event.target.value]);					
		setState({
			...state,
			loading: false,
			errors: {},
			[event.target.name]: event.target.value
		});
		console.log( 'State', state);
		//setErrors( []);
	};

	
	const handleChangeEmail = (event) => {
		
		/*
		var st = state;
		st.loading = false;
		st.errors = {};
		st.email = event.target.value;
		setState( st);
		*/
		setEmail( event.target.value);
		/*
		setState({
			...state,
			loading: false,
			errors: {},
			email: event.target.value
		});
		*/
	};	
	const handleChangePassword = (event) => {
		/*
		var st = state;
		st.loading = false;
		st.errors = {};
		st.password = event.target.value;
		setState( st);
		*/
		setPassword( event.target.value);
		/*
		setState({
			...state,
			loading: false,
			errors: {},
			password: event.target.value
		});
		*/
	};		
	
	
	const handleSubmit = (event) => {
		event.preventDefault();
		setState({ 
			...state, 
			loading: true 
		});
		const userData = {
			email: email,
			password: password
		};
		console.log( 'POST /api/usr/login', userData);
		axios
			.post('/api/usr/login', userData)
			.then((response) => {
								
				console.log( 'Login response', response);
				localStorage.setItem( 'AuthToken', `Bearer ${response.data.token}`);
				localStorage.setItem( 'RefreshToken', `${response.data.refreshToken}`);
				setState({ 
					...state,
					errors: {},
					loading: false,
				});	
				navigate( '/home');
			})
			.catch((error) => {	
				console.log( 'Login error', error);
				setState({
					...state,
					loading: false,					
					errors: error.response.data
				});
				//setErrors( error.response.data);				
			});
	};	
		
			
	return (
		<Grid container direction="row">

		<Grid item style={{width: state.isMDUpper ? '70%' : '0%'}}>
		
			<div style={{
				backgroundColor:'#FBFDFC',				
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				width: '100%',
				height: '100vh',												
				backgroundImage:`url(${backgroundImg})`
			}} />
		
		</Grid>
		
		<Grid item style={{width: state.isMDUpper ? '30%' : '100%'}}>		
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>					
					
					<img src={logo} alt='App Logo' style={{maxHeight:36,marginBottom:8}}/>										
					<Typography component="h3" variant="h3">
						{process.env.QEE_APP_NAME}
					</Typography>										
					<Typography component="h4" variant="h4">
						Login
					</Typography>
					
					<form className={classes.form} noValidate>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Addresse"
							name="email"
							autoComplete="email"
							autoFocus
							helperText={state.errors.email}
							error={state.errors.email ? true : false}
							onChange={handleChangeEmail}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Passwort"
							type="password"
							id="password"
							autoComplete="current-password"
							helperText={state.errors.password}
							error={state.errors.password ? true : false}
							onChange={handleChangePassword}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={handleSubmit}
							disabled={state.loading || 
								!email || 
								!password}
						>
							Einloggen
							{state.loading && <CircularProgress size={30} className={classes.progress} />}
						</Button>

						<Grid container direction="column" spacing={5}>
							<Grid item>
								<LoginSocialButtons />							
							</Grid>
							<Grid item>
								<Link href="pwdReset" variant="body2">
									{"Passwort vergessen?"}
								</Link>
							</Grid>						
							<Grid item>
								<Link href="signup" variant="body2">
									{"Noch nicht registriert? Registrieren"}
								</Link>
							</Grid>
							<Grid item>
								<Link href="https://qalgo.shop/pages/private-policy" target="blank" variant="body2">
									{"Datenschutzerklärung"}
								</Link>
							</Grid>							
						</Grid>
						{state.errors.general && (
							<Typography variant="body2" className={classes.customError}>
								{state.errors.general}
							</Typography>
						)}
					</form>
					<Footer left={state.isMDUpper ? '70%' : '0'} width={state.isMDUpper ? '30%' : '100%'}/>													
				</div>
			</Container>
		</Grid>
		</Grid>
	);
}
export default withStyles(styles)(Login);
