import React, { useEffect, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import style from "./ListPaymentMethods.module.scss";

import AddPayMethod from "./AddPayMethod";
import { format } from "date-fns";
import { getRequest } from "../utils/api";
import { getCardImage } from "../utils/helpers";

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from '../../qeeColorButton';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});


const styles = (theme) => ({

	newPosOfDialog: {		
		[theme.breakpoints.up('lg')]: {
			transform: "translate(100%, 0%)",
		},			
	},
	dialog: {
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},			
		position: 'absolute',
		left: 10,
		top: 50,			
	},	
	dialogStyle: {
		maxWidth: '50%',
		[theme.breakpoints.down('md')]: {
			maxWidth: '100%'
		},						
	},
	button: {
		width: 280,
		height: 48
	}			
});
const useStyles =  makeStyles(theme => (styles(theme)));

export default function ListPaymentMethods({ handleSelectCard }) {
	
	const [isLoading, setIsLoading] = useState( false);			
	const [paymentMethods, setPaymentMethods] = useState(null);
	const [openAddPayMethod, setOpenAddPayMethod] = useState( false);	


	// some consts	
	/*
	const useStyles = makeStyles({
		//...props,	
		newPosOfDialog: {
			minWidth: "800",
			transform: "translate(100%, 0%)",
		},
		dialog: {
			width: '50%',
			position: 'absolute',
			left: 10,
			top: 50,			
			//maxWidth: 1200,		 
		},	
		dialogStyle: {
			maxWidth: '50%'
		},
		button: {
			width: 280,
			height: 48
		}		
	});	
	const classes = useStyles();		
	*/
	
	const classes = useStyles();	
		
	const addPayMethod = () => {
		setOpenAddPayMethod( true);	
	}

	const handleClose = (event) => {
		setOpenAddPayMethod( false);
	};			
	
	function getPaymentMethods() {
		
		let usr = JSON.parse( sessionStorage.getItem( 'usr'));	
		
		getRequest(`/api/payment/methods/`+usr.usrId).then((resp) => {
			console.log( 'Payment methods', resp.data.data);
			setPaymentMethods(resp.data.data);
		})
		.catch((err) => {
			console.log(err);
		});
	}

	useEffect( getPaymentMethods, [openAddPayMethod]);

	return (
    <div className={style.wrapper}>
	
		<p>Um mit der Zahlung fortzufahren, erstelle eine Zahlungsmethode</p>
		
		<QeeColorButtonPrimary disableElevation 
			className={classes.button} 
			onClick={addPayMethod}>			
			{isLoading ?
				<CircularProgress style={{color:'#fff'}}/>
				:	
				<>Zahlungsmethode hinzufügen</>
			}									
		</QeeColorButtonPrimary>		
	
	
		{paymentMethods && paymentMethods.length ?
			<>
			<p>oder wähle eine vorhandene Zahlungsmethode aus der Liste aus:</p>
			{paymentMethods &&
				paymentMethods.map((method) => (
					<div className={style.card} onClick={handleSelectCard.bind(this, method)}>
						<div className={style.cardLogo}>
							<img src={getCardImage(method.card.brand)} alt="" />
						</div>

						<div className={style.details}>
							<p>
								{method.card.brand} **** {method.card.last4}
							</p>
							<p>{method.billing_details.name}</p>
						</div>

						<div className={style.expire}>
							Expires{" "}
								{format(new Date(`${method.card.exp_year}/${method.card.exp_month}/01`), "MM/yyyy")}
						</div>
					</div>
			))}
			</>
		: ''}
				
		<Dialog 
			fullScreen
			classes={{
				paper: classes.newPosOfDialog
			}}
			className={classes.dialog}
			open={openAddPayMethod} 
			onClose={handleClose} 
			TransitionComponent={Transition}
		>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6">
						{'Zahlungsmethode hinzufügen'}
					</Typography>
				</Toolbar>
			</AppBar>
		
			<DialogContent>	
				<AddPayMethod handleClose={handleClose} />
			</DialogContent>
			
		</Dialog>		
		
    </div>
		
	);
}
