// FacForm.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { Dimensions, Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import ConstructionIcon from '@mui/icons-material/Construction';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CalculateIcon from '@mui/icons-material/Calculate';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import FacDocsForm from './facDocsForm';
import QeeToolsMenu from './qeeToolsMenu';
import QeeTooltip from './qeeTooltip';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import FacRightInfo from './facRightInfo';
import { useFileUploader } from "../components/qeeFileUploaderProvider"; 
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import {usrRight} from '../util/helpers';

import axios from 'axios'; 

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0.5)
		}		
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(0.2)
		}		
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	button: {
		textTransform: 'none',
		width: 250,
		height: 60	
	},
	buttonLabel: {
		whiteSpace: 'nowrap', 
		width:240, 
		overflow: 'hidden', 
		textOverflow: 'ellipsis'
	},	
});


// main fct
const FacDocs = ( props) => {
	
	const { setOpenConfluenceDialog } = useConfluence();													
	const { setOpenFileUploaderDialog } = useFileUploader();														
		
	const { gstate, setCurrSubpage, setObjEdit, setObjErrors, setIsFetching } = useAuth();				
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
		reloadData: false
	})

	
	const handleSetReloadData = ( vl) => {
		console.log( 'FacDocs.handleSetReloadData', vl);
		setState({
			...state,
			reloadData: vl
		})
	}
	
	useEffect( () => {	

		console.log( 'use effect for', gstate.objEdit);
												
	}, []);		

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);				
	
						
	return (	
		state.uiLoading ? (
			<div className={classes.root}>
				{state.uiLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (
			<main className={classes.content}>								
				<form className={classes.form} noValidate>
					<Box
					  margin={gstate.isXS ? 1 : 0}
					  display="flex"
					  justifyContent="flex-start"
					  alignItems="flex-start"
					  sx={12}
					>		

						<FacRightInfo fac={gstate.objEdit} isXS={gstate.isXS} />				
					
						{usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?																													
							<QeeTooltip title="Datei-Uploader starten">
							<QeeColorButtonPrimary disableElevation 
								className={classes.button} 
								onClick={()=>setOpenFileUploaderDialog( 
									handleSetReloadData)}>
								<div className={classes.buttonLabel}>{gstate.isXS ? <CloudUploadIcon fontSize="large" style={{paddingTop:5}}/> : 'Datei-Uploader'}</div>
							</QeeColorButtonPrimary>												
							</QeeTooltip>	
						:''}
					
						<QeeToolsMenu />	

						<QeeTooltip title="Wissensassistent starten">
						<QeeColorButtonSecondary disableElevation 
							className={classes.button} 
							style={{width:50}}
							onClick={()=>setOpenConfluenceDialog()}>
							<HelpOutlinedIcon />
						</QeeColorButtonSecondary>												
						</QeeTooltip>	
					</Box>	
					
					<FacDocsForm 
						reloadData={state.reloadData} 
						handleSetReloadData={handleSetReloadData} 
					/>
				</form>				
					
			</main>	
		)		
	)
}	
export default withStyles(styles)(FacDocs);