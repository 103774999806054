// FacForm.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { Dimensions, Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ConstructionIcon from '@mui/icons-material/Construction';
import CalculateIcon from '@mui/icons-material/Calculate';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InfoIcon from '@mui/icons-material/Info';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import FacOwnership from './facOwnership';
import FacType from './facType';
import FacAreas from './facAreas';
import FacNameDesc from './facNameDesc';
import FacRenovationWizDialog from './facRenovationWizDialog';
import FacNewbuildWizDialog from './facNewbuildWizDialog';
import FacValuationWizDialog from './facValuationWizDialog';
import FacForm from './facForm';
import QeeToolsMenu from './qeeToolsMenu';
import QeeTooltip from './qeeTooltip';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import FacRightInfo from './facRightInfo';
import { useFacShare } from "../components/qeeFacShareProvider"; 
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import { useSnackbar } from 'notistack';

import StepZilla from "react-stepzilla";

import axios from 'axios';

import css from '../core/App.css';
import {usrRight} from '../util/helpers';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0.5)
		}
	},
	form: {
		//width: '98%',
		marginLeft: 13,
		marginTop: theme.spacing(3),		
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
			marginTop: theme.spacing(0.2)					
		}
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	button: {
		textTransform: 'none',
		width: 250,
		height: 60	
	},
	buttonLabel: {
		whiteSpace: 'nowrap', 
		width:240, 
		overflow: 'hidden', 
		textOverflow: 'ellipsis'
	},	
	dialog: {
		width: '50%',
		position: 'absolute',
		left: 10,
		top: 50,			
		//maxWidth: 1200,		 
	},	
	dialogStyle: {
		maxWidth: '50%'
	},		
});


// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


// main fct
const FacDashboard = ( props) => {
	
	const { setOpenFacShareDialog } = useFacShare();													
	const { setOpenConfluenceDialog } = useConfluence();
	const { enqueueSnackbar } = useSnackbar();	
		
	const refRenovationDialog = useRef(null);
	const refNewbuildDialog = useRef(null);	
	const refValuationDialog = useRef(null);	
	
	const { gstate, setCurrSubpage, setSubmitNewFac, setOpenRenovationDialog, setOpenValuationDialog, setIsFetching, setObjEdit, setObjErrors } = useAuth();			
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
		screenDims: {width:0, height:0},
		openRenovation: false,
		openValuation: false,		
	})

	
	useEffect( () => {	

		console.log( 'use effect for', gstate.objEdit);
		
		//refRenovationDialog.current.ali();
												
	}, []);		

	
	// reposition the dialog (must not be fullScreen)
	const useStyles = makeStyles({
		...props,	
	});	
	const classes = useStyles( props);	
	
	
	const handleRenovationOpen = () => {
		console.log( 'handleRenovationOpen');		
		refRenovationDialog.current.handleRenovationOpen();		
	}	
	
		
	const handleValuationOpen = ( step) => {		
		refValuationDialog.current.handleValuationOpen( step);		
	}	
	
	
	/**
	*	Effect for creation fac with fac_prj_type set where the next dlg opens
	*/
	useEffect(() => {
		if ( gstate.submitNewFac) {
			let prjType = gstate.submitNewFac.fac_prj_type;
			let msg = 'Die Immobilie wurde gespeichert. ';
            if( gstate.submitNewFac.fac_prj_type === 'renovation') {
                msg += 'Ergänze nun die Baudaten für das Renovierungsprojekt!';
            } else if( gstate.submitNewFac.fac_prj_type === 'newbuild') {
                msg += 'Ergänze nun die Baudaten für das Neubauprojekt!';
            } else {
                msg += 'Für Renovierung oder Neubau kannst du die Assistenten von "Tools" starten.';
            }
			enqueueSnackbar(msg, { variant: 'success' });
			
			setTimeout(() => {
				if (prjType === 'newbuild' && refNewbuildDialog.current) {
					refNewbuildDialog.current.handleNewbuildOpen(0);
				} else if (prjType === 'renovation' && refRenovationDialog.current) {
					refRenovationDialog.current.handleRenovationOpen(0);
				} 
			}, 300);
		}
		
		setSubmitNewFac( null);
		
	}, [ gstate.submitNewFac]);	
	
	
	return (	
		state.uiLoading ? (
			<div className={classes.root}>
				{state.uiLoading && <CircularProgress size={gstate.isLG ? 100 : 50} className={classes.uiProgess} />}
			</div>		
		) : (
			<main className={classes.content}>								
				<form className={classes.form} noValidate>
					<Box
					  margin={gstate.isXS ? 1 : 0}
					  display="flex"
					  justifyContent="flex-start"
					  alignItems="flex-start"
					  sx={12}
					>	
						<FacRightInfo fac={gstate.objEdit} isXS={gstate.isXS} />
					
						{/*
						{usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?
							<QeeTooltip title="Sanierungsassistent starten">
							<QeeColorButtonPrimary disableElevation 
								className={classes.button} 
								onClick={handleRenovationOpen}>							
								<Typography variant='button'><div className={classes.buttonLabel}>{gstate.isXS ? <ConstructionIcon fontSize="large" style={{paddingTop:5}}/> : 'Sanierungsassistent'}</div></Typography>
							</QeeColorButtonPrimary>				
							</QeeTooltip>
						:''}
						
						{usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?
							<QeeTooltip title="Wertermittlungsassistent starten">
							<QeeColorButtonSecondary disableElevation 
								className={classes.button} 
								onClick={handleValuationOpen}>
								<div className={classes.buttonLabel}>{gstate.isXS ? <CalculateIcon fontSize='large' style={{paddingTop:5}}/> : 'Wertermittlungsassistent'}</div>
							</QeeColorButtonSecondary>
							</QeeTooltip>	
						:''}	
						*/}

						<QeeTooltip title="Immobilienzugang freigeben. Hier kannst du anderen Personen Zugriff auf diese Immobiliendaten gewähren, z. B. Handwerkern, Bankberatern, Kunden usw.">
						<QeeColorButtonSecondary disableElevation 
							className={classes.button} 
							style={{width:50}}
							onClick={()=>setOpenFacShareDialog( gstate.objEdit)}>
							<Typography variant='button'><div className={classes.buttonLabel}><PersonAddIcon fontSize='large' style={{paddingTop:5}}/></div></Typography>
						</QeeColorButtonSecondary>
						</QeeTooltip>						
						
						<QeeToolsMenu />
						
						<QeeTooltip title="Wissensassistent starten">
						<QeeColorButtonSecondary disableElevation 
							className={classes.button} 
							style={{width:50}}
							onClick={()=>setOpenConfluenceDialog('/allgemein')}>
							<Typography variant='button'><HelpOutlinedIcon /></Typography>
						</QeeColorButtonSecondary>												
						</QeeTooltip>
						
					</Box>					
					<FacForm />	
				</form>	

				<FacRenovationWizDialog 
					{...props}
					ref={refRenovationDialog} 
					subpageEnergy="loadFacEnergy"
					subpageValuation="loadFacValuation"
				/>	

				<FacNewbuildWizDialog
					{...props}
					ref={refNewbuildDialog}
					subpageNewbuild="loadFacNewbuild"
					subpageEnergy="loadFacEnergy"
				/>				

				<FacValuationWizDialog 
					{...props}
					ref={refValuationDialog} 
					subpageEnergy="loadFacEnergy"
					subpageValuation="loadFacValuation"
				/>									
				
			</main>	
		)		
	)
}	
export default withStyles(styles)(FacDashboard);