import React, { useState } from 'react';
import { makeStyles, useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useConfirm } from 'material-ui-confirm';
import { formatDate } from '../util/helpers';
import { QeeColorButtonPrimary, QeeColorButtonSecondary } from './qeeColorButton';
import { SAC_STATE_COLORS, SAC_TYPE_SYMBOLS } from '../models/sacConfig';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer',
        flexDirection: 'column', // Ensures the content stacks vertically on small screens
        maxWidth: 800,
        flex: '0 1 auto', 
    },
    avatarContainer: {
        position: 'relative',
        marginRight: theme.spacing(2),
        alignSelf: 'flex-start', // Align the avatar container to the top
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    typeSymbol: {
        position: 'absolute',
        bottom: 0,
        right: -10,
        transform: 'translate(50%, 50%)', // Adjust the position
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        borderRadius: '50%',
        padding: theme.spacing(0),
        transform: 'scale(1.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatarContainerMobile: {
        position: 'relative',
        marginRight: theme.spacing(0),
        alignSelf: 'flex-start', // Align the avatar container to the top
    },
    avatarMobile: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    typeSymbolMobile: {
        position: 'absolute',
        bottom: 0,
        right: -10,
        transform: 'translate(50%, 50%)', // Adjust the position
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        borderRadius: '50%',
        padding: theme.spacing(0),
        transform: 'scale(0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        //flex: '1 0 auto',
        overflow: 'hidden', // Ensure content doesn't overflow      
        padding: theme.spacing(2), // Add padding to match the root padding
        paddingTop: theme.spacing(0),
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        whiteSpace: 'nowrap', // Ensure text doesn't wrap
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Add ellipsis for overflowing text
    },
    subTitle: {
        fontSize: 14,
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
        whiteSpace: 'nowrap', // Ensure text doesn't wrap
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Add ellipsis for overflowing text
    },
    receiver: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap', // Ensure text doesn't wrap
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Add ellipsis for overflowing text
    },
    dateAndState: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        fontSize: 14,
        color: theme.palette.text.secondary,
        flexShrink: 0,
        padding: theme.spacing(2), // Add padding to match the root padding
        paddingTop: theme.spacing(0),
        whiteSpace: 'nowrap', // Ensure text doesn't wrap
        marginTop: 15,
    },
    dateAndTime: {
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
    stateText: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0.3, 1),
        marginTop: theme.spacing(0.5),
        color: theme.palette.common.white,
    },
    overdue: {
        color: theme.typography.error.color,
    },
    buttonContainer: {
        marginLeft: 'auto',
    },
    actionButton: {
        marginRight: theme.spacing(1),
        height: '36px', // Ensure consistent height
        color: theme.palette.primary.main, // Use the theme's primary color
        display: 'flex',
        alignItems: 'center', // Ensure vertical alignment
    },
    icon: {
        color: theme.palette.primary.main,
        width: 32,
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
    },
    menuIcon: {
        minWidth: '40px', // Ensure space for icons
    },
    sacBody: {
        marginTop: theme.spacing(1),
        whiteSpace: 'pre-line', // Ensure newlines are respected
        lineHeight: 1.5, // Adjust line height for better readability
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    expanded: {
        WebkitLineClamp: 'unset', // Remove line clamp when expanded
    },
    expandButton: {
        marginLeft: 'auto',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main, // Use the theme's primary color
    },
}));

const isOverdue = (sac, dateString) => {
	return sac.sac_state < 300 && new Date() > new Date(dateString);
};

const SacCard = ({ sac, onClick, onStateChange, onDelete }) => {
    const classes = useStyles();
    const theme = useTheme();
    const confirm = useConfirm();
    const [currentSac, setCurrentSac] = useState(sac); // Use local state for the current SAC
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingState, setLoadingState] = useState(null);
    const [expanded, setExpanded] = useState(false); // State to manage expand/collapse
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const getStateValue = (state) => {
        const stateObj = currentSac.sac_state_all.find((s) => s.key === state);
        return stateObj ? stateObj.value : '';
    };

    const getStateStyle = (state) => {
        const backgroundColor = SAC_STATE_COLORS[state] || '#000';
        const textColor = '#fff';

        if (isMobileScreen) {
            return {
                backgroundColor,
                color: textColor,
                alignSelf: 'flex-end',
            };
        }

        return { backgroundColor, color: textColor };
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        setIsLoading(true);
        setLoadingState('delete');
        confirm({
            title: 'Anfrage löschen',
            description:
                'Hierdurch werden alle Daten für diese Anfrage, einschließlich verschachtelter Daten, endgültig gelöscht.',
            confirmationText: 'Löschen starten',
            cancellationText: 'Abbrechen',
            confirmationButtonProps: { color: 'error', variant: 'contained', style: { textTransform: 'none' } },
            cancellationButtonProps: { color: 'success', style: { textTransform: 'none' } },
        })
            .then(() => {
                onDelete(currentSac);
            })
            .finally(() => {
                setIsLoading(false);
                setLoadingState(null);
            })
            .catch(() => {});
    };

    const handleStateChange = (state) => {
        if (state === 10000) {
            confirm({
                title: 'Aktivität stornieren',
                description: 'Möchtest du diese Aktivität wirklich stornieren?',
                confirmationText: 'Ja',
                cancellationText: 'Nein',
                confirmationButtonProps: { color: 'error', variant: 'contained', style: { textTransform: 'none' } },
                cancellationButtonProps: { color: 'success', style: { textTransform: 'none' } },
            })
                .then(() => {
                    performStateChange(state);
                })
                .catch(() => {});
        } else {
            performStateChange(state);
        }
    };

    const performStateChange = (state) => {
        setIsLoading(true);
        setLoadingState(state);
        onStateChange(currentSac, state)
            .then((updatedSac) => setCurrentSac(updatedSac)) // Update local state with the returned updated SAC object
            .finally(() => {
                setIsLoading(false);
                setLoadingState(null);
            })
            .catch((error) => console.error('Failed to update SAC state', error));
    };

    const handleMenuClick = (event) => {
        event.stopPropagation(); // Prevent the card click from triggering
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleActionSelect = (stateKey) => {
        setIsLoading(true);
        setLoadingState(stateKey);
        handleCloseMenu();
        handleStateChange(stateKey);
    };

    const sacTypeIcon = SAC_TYPE_SYMBOLS[currentSac.sac_type] || null;
    const sacTitle = `${currentSac.sac_type_text}: ${currentSac.sac_name}`;

    return (
        <Card className={classes.root}>
            <Grid container wrap="nowrap">
                <Grid item xs={12} sm={8} md={9} container alignItems="flex-start" wrap="nowrap">
                    {!isMobileScreen ? (
                        <Tooltip title={'Initiator: ' + currentSac.sac_sender_name}>
                            <Box className={classes.avatarContainer}>
                                <Avatar className={classes.avatar} src={`/path/to/profile/images/${currentSac.sac_sender_id}.jpg`} />
                                {sacTypeIcon && <Box className={classes.typeSymbol}>{sacTypeIcon}</Box>}
                            </Box>
                        </Tooltip>
                    ) : (
                        <Box className={classes.avatarContainerMobile}>
                            <Avatar className={classes.avatarMobile} src={`/path/to/profile/images/${currentSac.sac_sender_id}.jpg`} />
                            {sacTypeIcon && <Box className={classes.typeSymbolMobile}>{sacTypeIcon}</Box>}
                        </Box>
                    )}
                    <Box className={classes.content}>
                        <CardContent className={classes.details}>
                            <Typography
                                variant="h6"
                                className={classes.title}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClick();
                                }}
                            >
                                {sacTitle}
                            </Typography>
                            <Typography className={classes.subTitle}>{currentSac.sac_fac_name}</Typography>
                            <Box className={classes.receiver}>
                                <ArrowRightAltIcon fontSize="small" className={classes.icon} style={{ transform: 'scale(2)' }} />
                                <Typography style={{ marginLeft: theme.spacing(1) }}>{currentSac.sac_receiver_org_name}</Typography>
                            </Box>
                            {isMobileScreen ? (
                                <>
                                    <Box style={{ alignSelf: 'flex-end', textAlign: 'right' }}>{formatDate(currentSac.sac_date_limit, 'synapcusDate')}</Box>
                                    <Box className={classes.stateText} style={getStateStyle(currentSac.sac_state)}>
                                        {getStateValue(currentSac.sac_state)}
                                    </Box>
                                </>
                            ) : (
                                ''
                            )}
                            <Box>
                                <Typography
                                    className={`${classes.sacBody} ${expanded ? classes.expanded : ''}`}
                                    onClick={() => setExpanded(!expanded)}
                                    dangerouslySetInnerHTML={{ __html: currentSac.sac_body }}
                                />
                                <Box className={classes.expandButton} 
                                    onClick={() => setExpanded(!expanded)}>
                                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Box>
                            </Box>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3} className={classes.dateAndState}>
                    <Typography className={classes.dateAndTime}>{formatDate(currentSac.sac_date_limit, 'synapcusDate')}</Typography>
                    <Typography className={classes.dateAndTime}>{formatDate(currentSac.sac_date_limit, 'synapcusTime')}</Typography>
                    <Box className={classes.stateText} style={getStateStyle(currentSac.sac_state)}>
                        {getStateValue(currentSac.sac_state)}
                    </Box>
                    {isOverdue(sac, currentSac.sac_date_limit) && 
						<Tooltip title={'Diese Aktivität ist überfällig'}>
							<AccessTimeIcon className={`${classes.overdue}`} />
						</Tooltip>
					}
                </Grid>
            </Grid>
            {isLargeScreen ? (
                <CardActions className={classes.buttonContainer}>
                    {currentSac.sac_state_available.map((state) => (
                        <Tooltip title="Status setzen" key={state.key}>
                            <QeeColorButtonSecondary
                                className={classes.actionButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleStateChange(state.key);
                                }}
                                disabled={isLoading}
                            >
                                {isLoading && loadingState === state.key ? <CircularProgress size={24} /> : state.value}
                            </QeeColorButtonSecondary>
                        </Tooltip>
                    ))}
                    <Tooltip title="Aktivität löschen">
                        <QeeColorButtonSecondary
                            className={classes.actionButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(e);
                            }}
                            disabled={isLoading}
                        >
                            {isLoading && loadingState === 'delete' ? <CircularProgress size={24} /> : <DeleteIcon style={{ fontSize: '1.5rem' }} className={classes.icon} />}
                        </QeeColorButtonSecondary>
                    </Tooltip>
                    <Tooltip title="Aktivität Öffnen">
                        <QeeColorButtonSecondary
                            className={classes.actionButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick();
                            }}
                            disabled={isLoading}
                        >
                            <OpenInNewIcon style={{ fontSize: '1.5rem' }} className={classes.icon} />
                        </QeeColorButtonSecondary>
                    </Tooltip>
                </CardActions>
            ) : (
                currentSac.sac_state_available.length > 0 && (
                    <>
                        <Box className={classes.buttonContainer}>
                            <IconButton onClick={handleMenuClick} disabled={isLoading}>
                                {isLoading && loadingState === 'menu' ? <CircularProgress size={24} /> : <MoreVertIcon className={classes.icon} />}
                            </IconButton>
                        </Box>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                            {currentSac.sac_state_available.map((state) => (
                                <MenuItem key={state.key} onClick={(e) => { e.stopPropagation(); handleActionSelect(state.key); }} className={classes.menuItem}>
                                    <Box className={classes.menuIcon}></Box>
                                    {state.value}
                                </MenuItem>
                            ))}
                            <MenuItem onClick={(e) => { e.stopPropagation(); handleDelete(e); }} className={classes.menuItem}>
                                <DeleteIcon className={classes.icon} style={{ marginRight: theme.spacing(1) }} />
                                Löschen
                            </MenuItem>
                            <MenuItem onClick={(e) => { e.stopPropagation(); onClick(); }} className={classes.menuItem}>
                                <OpenInNewIcon className={classes.icon} style={{ marginRight: theme.spacing(1) }} />
                                Öffnen
                            </MenuItem>
                        </Menu>
                    </>
                )
            )}
        </Card>
    );
};

export default SacCard;
