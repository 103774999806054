// FacForm.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { Dimensions, Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@mui/material/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextSnippetIcon from "@mui/icons-material/TextSnippet"
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';


import FacTitle from './facTitle';
import FacValuationEmpty from './facValuationEmpty';
import DiagramValuation from './diagramValuation';
import ChartPlanValuation from './chartPlanValuation';
import FacEnergyclassEmpty from './facEnergyclassEmpty';
import QeeHintBox from './qeeHintBox';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import QeeTooltip from './qeeTooltip';
import { usePicturesGallery } from "../components/qeePicturesGalleryProvider"; 

import css from '../core/App.css';

import { useAuth } from "../context/provider";
import { getDocs, getDocsNet, getSdatas } from "../api/service";

import moment from 'moment';


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(0.5),
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0.5)
		}
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	button: {
		textTransform: 'none'
	},
	box: {
		backgroundColor:theme.palette.primary.contrastText,
		border: 'solid 1px #E4EAE6',
		padding:20,
	},	
	resultCard: {
		display: 'flex', 
		//width:'100%',
		padding:10,
		marginBottom: 10,
		borderRadius: 5,
		border: `1px solid ${theme.palette.primary.main}`,		
		padding: 20
	},	
});


// main fct
const FacDocsForm = ( props) => {
	
	const { setOpenPicturesGalleryDialog } = usePicturesGallery();																		
	
	const ref = useRef(null);
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();			
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
	})
	
	const [rows, setRows] = useState( []);		

	const offset = 300;
	const [height, setHeight] = useState( window.innerHeight>offset ? window.innerHeight-offset : offset);  		
	//const [height, setHeight] = useState(0);  		
	
			
	const renderNameCell = (params) => {
		console.log( 'PARS:', params);
		
		/**
		* 	showGallery() -
		*/
		const showGallery = async (popupState, url) => {
			
			popupState.close();	
			
			//setIsLoading( true);
			
			var pics = [];
			
			var activeStep = 0;
						
			// get all the picture files from storage
			for( var i=0; i<rows.length; i++) {
				var pic = {};
				pic.url = rows[i].signedUrl;
				pic.caption = rows[i].sdoc_name;	
				
				pics.push( pic);		
				
				if( pic.url == url)
					activeStep = i;
			}			
			
			//setIsLoading( false);							
			
			setOpenPicturesGalleryDialog( pics, activeStep);
		}		
		
		
		
		/**
		*	downloadDoc() - 
		*/
		const downloadDoc = ( popupState, url) => {								
			popupState.close();				
			window.open( url);
		}			
		
		return (
			<div className="d-flex justify-content-between align-items-center" style={{width:'100%'}}>				
				<PopupState variant="popover" popupId="qeeToolsMenu">
				{(popupState) => (			
					<React.Fragment>	
					<table style={{width:'100%'}}>
						<tr>
						<td style={{width:40}}>
							<TextSnippetIcon style={{marginRight:5, fontSize:36, color:'#444'}}/>
						</td>
						<td>
							<a href={params.row.signedUrl} target='_blank'>
								{params.row.sdoc_name && params.row.sdoc_name.length > 40 ?  
									<QeeTooltip title={params.row.sdoc_name}>
									<div style={{width:gstate.isXS ? 240 : 300, overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.sdoc_name}</div>
									</QeeTooltip>
								:
									<div style={{width:gstate.isXS ? 240 : 300, overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.sdoc_name}</div>								
								}
							</a>
							<div>{params.row.sdoc_type} | {params.row.sdoc_subtype}</div>					
						</td>
						<td style={{textAlign:'right'}}>
							<Button 
								className={classes.button} 
								{...bindTrigger(popupState)}>
								<MoreVertIcon style={{marginLeft:gstate.isXS ? 0 : 20, fontSize:36, color:'#444', borderLeft: 'solid 1px #ddd'}}/>					
							</Button>					
						</td>					
						</tr>
					</table>
									
					<Menu {...bindMenu(popupState)}>
						<MenuItem  onClick={() => showGallery( popupState, params.row.signedUrl)} >							
							Anzeigen
						</MenuItem>							
						<MenuItem  onClick={() => downloadDoc( popupState, params.row.signedUrl)} >							
							Herunterladen
						</MenuItem>		
					</Menu>
					</React.Fragment>
				)}
				</PopupState>				
			</div>				
		)
	}
	
	
	const renderDescCell = (params) => {	
		return (
			<>
			{params.row.sdoc_desc.length > 24 ?
				<QeeTooltip title={params.row.sdoc_desc}>
					<div style={{width:250, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.sdoc_desc}</div>
				</QeeTooltip>
			:
				<div style={{width:250, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.sdoc_desc}</div>
			}
			</>
		)
	}
	
	
	const columns = gstate.isXS ? [
		{ field: 'sdoc_name', 	headerName: 'Name', width:'100%', maxWidth:'100%', renderCell: renderNameCell},
	]
		:
	[
		{ field: 'sdoc_name', 	headerName: 'Name', renderCell:renderNameCell, minWidth: 420, flex: 1},
		{ field: 'created', 	headerName: 'Erstellt', width:200, flex: 1},
		{ field: 'updated', 	headerName: 'Zulezt geändert', width:200, flex: 1},
		{ field: 'sdoc_desc', 	headerName: 'Beschreibung', renderCell:renderDescCell, flex: 1},
	];			
		
	const localizedTextsMap = {
		columnMenuUnsort: "Keine Sortierung",
		columnMenuSortAsc: "Sortierung aufsteigend",
		columnMenuSortDesc: "Sortierung absteigend",
		columnMenuFilter: "Filter",
		columnMenuHideColumn: "Spalte verbergen",
		columnMenuShowColumns: "Spalten verwalten"
	};	
			
	function handleWindowSizeChange() {
		setHeight( window.innerHeight>offset ? window.innerHeight-offset : offset);
	}		
	useEffect( () => {						
		window.addEventListener( 'resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener( 'resize', handleWindowSizeChange);
		}		
	}, [height]);	
	
			
	useEffect(() => {	
	
		const _getDocs = async () => {
			var docs = await getDocs( gstate.objEdit.id);
			console.log( 'facDocsForm getDocs', docs);			
			var rws = [];
			for( var i=0; i<docs.length; i++) {
				
				let tp = docs[i].metadata.metadata ? docs[i].metadata.metadata.sdoc_type : 100;
				var arrType = await getSdatas( 'sdata_sdocs_types', 'type', tp);		
				let sbtp = docs[i].metadata.metadata ? docs[i].metadata.metadata.sdoc_subtype : 100;
				var arrSubtype = await getSdatas( 'sdata_sdocs_subtypes', ['parent_type', 'type'], [tp, sbtp]);	
				let nm = docs[i].metadata.metadata ? docs[i].metadata.metadata.sdoc_name : docs[i].name;	
				let desc = docs[i].metadata.metadata ? docs[i].metadata.metadata.sdoc_desc : '';	
				rws.push({
					id:i,
					sdoc_name: nm,     //docs[i].name,
					sdoc_type: arrType[0] ? arrType[0].name : '',  
					sdoc_subtype: arrSubtype[0] ? arrSubtype[0].name : '',    
					created: moment( docs[i].metadata.timeCreated).format( 'DD.MM.YYYY HH:mm:ss'),
					updated: moment( docs[i].metadata.updated).format( 'DD.MM.YYYY HH:mm:ss'),
					signedUrl: docs[i].signedUrl,
					sdoc_desc: desc
				})
			}
			setRows( rws);
			props.handleSetReloadData( false);
		}
		
		_getDocs();
			
	}, [height, props.reloadData]);		

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);				
	
	
	const MyCustomNoRowsOverlay = () => (		
		<Stack height="100%" alignItems="center" justifyContent="center" style={{padding:50}}>		
			<TextSnippetIcon style={{fontSize:gstate.isXS ? 60 : 120, color:'#ddd'}} />
			<div style={{textAlign:'center', maxWidth:600, marginTop:20}}>Noch keine Dokumente hier!<br/><br/>Dokumente, wie z.B. Berichte kannst du über <b>"Tools/Bericht generieren"</b> erstellen.</div>
		</Stack>	
	);
	
			
	return (
		<div ref={ref} stsle={{height:'100%'}}>
			{gstate.isLoading ? (
			<div className={classes.root}>
				{gstate.isLoading && <CircularProgress size={gstate.isXL ? 150 : 50} className={classes.uiProgess} />}
			</div>		
			) : (			
			<main className={classes.content}>
				<FacTitle fac={gstate.objEdit} showPicture={true} />

				<Stack style={{ width: '100%', maxWidth:1200, marginTop:20 }} alignItems="flex-start" spacing={2}>
					<div style={{ height, width: '100%' }}>
						<DataGrid 
							autoPageSize 
							pagination 
							rows={rows}
							columns={columns}
							components={{
								NoRowsOverlay: MyCustomNoRowsOverlay
							}}
							localeText={localizedTextsMap}	
							sx={{
								'& .MuiDataGrid-cell:focus': {
									color: 'primary.main',
									border: 'solid 0 #fff'
								},
							}}	
						/>
					</div>
				</Stack>
 				
				
			</main>				
			)}
		</div>	
	)
}	
export default withStyles(styles)(FacDocsForm);