// fileUploaderDialog.js

import react, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import React, { useImperativeHandle } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';

import { FilePond, registerPlugin } from "react-filepond"
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';

import axios from 'axios';

import QeeSlideDialog from './qeeSlideDialog';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import { useSnackbar } from 'notistack';

import css from '../core/App.css';

import { useAuth } from "../context/provider";
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';
import { postSjo } from "../api/service";


const styles = (theme) => ({
});


// main fct
const SjoWizDlg = React.forwardRef((props, forwardedRef) => {	

	// Register the plugins
	registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);
	const acceptedFileTypes = ['.csv', 'application/vnd.ms-excel', 'text/csv'];
	
	const { enqueueSnackbar } = useSnackbar();				

	const refStepper = useRef(null);	
	const refGeneralForm = useRef(null);		
	const refUploaderForm = useRef(null);	
	
	const [fctReloadData, setFctReloadData] = useState( null)

	const { gstate, setOpenQeeDialog, setIsFetching } = useAuth();				
		
	const [state, setState] = useState({
		uiLoading: false, 
		activeStep: 0,
		formData: {},
		imgCollection: [],
		rws: [],
		submitDisabled: true,		
	});
	const [valids, setValids] = React.useState( [false, false, true]);
	const handleSetValids = ( vlds) => {
		console.log( 'SjoWizDlg.handleSetValids', vlds);
		setValids( JSON.parse( JSON.stringify( vlds)));	
	}		
	
	
	const handleSetState = ( st) => {
		console.log( 'SjoWizDlg.handleSetState', st);
		setState( {
			...state,
			formData: st.formData,
			imgCollection: st.imgCollection,
			submitDisabled: !validate()
		});	
	}

	
	// some consts
	const useStyles = makeStyles({
		//...props
		uiProgess: {
			position: 'fixed',
			zIndex: '1000',
			height: '31px',
			width: '31px',
			left: '50%',
			top: '35%'
		},
		progess: {
			position: 'absolute'
		},		
	});			
	const classes = useStyles( props);
	
	
	const handleFileUploaderOpen = ( fctReloadData1) => {
				
		setOpenQeeDialog( {open:true, id:'dlgFileUploader'});
		setState({
			activeStep: 0,
			imgCollection: [],
			rws: [],
			submitDisabled: true,			
		});
		setValids( [false, false, true]);		
			
		// set it as a function with arg	
		setFctReloadData( (vl) => vl => fctReloadData1( vl));
	}	
	
	
	const handleFileUploaderClose = () => {
		setOpenQeeDialog( false);	
	};
	
	
	const validate = () => {
		
		return refStepper.current.validate();
	
	};			
	
	
	const onSubmit = async () => {

		setState({
			...state,
			isLoading: true
		})
			
		var formData = new FormData();
		console.log( 'imgCollection', state.imgCollection);
		for( let i=0; i<state.imgCollection.length; i++) {
			console.log( 'Set in FormData file:'+i, state.imgCollection[i])
			
			// extend the meta infos
			for( let j=0; j<state.rws.length; j++) {
				if( state.rws[j].sdoc_name === state.imgCollection[i].name) {
					console.log( 'rws name', state.rws[j].sdoc_name);
					state.imgCollection[i].filename = state.rws[j].sdoc_name;
					var metadata = {};
					for( var nm in state.rws[j]) {
						metadata[nm] = state.rws[j][nm];
					}	
					state.imgCollection[i].metadata = JSON.stringify( metadata);					
					//state.imgCollection[i].metadata = state.rws[j];
					console.log( 'rws content', state.imgCollection[i]);
				}
			}		
			console.log( 'imgCollection content', state.imgCollection[i]);
			formData.append( state.imgCollection[i].metadata, state.imgCollection[i]);
		}
		
		var sendData = {};
		sendData.jsonData = state.formData;
		sendData.fileData = formData;
		var ret = await postSjo( sendData);
		
		setState({
			...state,
			isLoading: false
		})
		
		if( ret.error) {
			enqueueSnackbar( 'Der Upload konnte nicht ausgeführt werden! ' + ret.error, {variant: 'error'});								
			return false;
		} else {		
			enqueueSnackbar( 'Der Server-Job wurde gestartet...', {variant: 'success'});		
			if( props.fctReloadData)
				props.fctReloadData( true);	
			return true;
		}
	};	
	

	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		ali: ()=>{alert(123)},
		handleFileUploaderOpen: handleFileUploaderOpen,						
		handleFileUploaderClose: handleFileUploaderClose
	}));	
	

	useEffect(() => {

		console.log( 'SjoWizDlg.useEffect');
								
	}, [])	



	/****************************************************************
	*
	*	VerticalLinearStepper() - component for stepping the upload
	*
	****************************************************************/

	const VerticalLinearStepper = React.forwardRef((props, forwardedRef) => {	
		
	const handleSetState = ( st) => {
		console.log( 'VerticalLinearStepper.handleSetState', st);
		props.handleSetState( st);	
	}	
	
	// intermediary valids between the comps and the dialog
	const [valids, setValids] = React.useState( props.validsDlg);	
	const handleSetValids = ( vlds) => {
		console.log( 'VerticalLinearStepper.handleSetValids', vlds);
		setValids( JSON.parse( JSON.stringify( vlds)));	
	}	
	useEffect(() => {		
		if( JSON.stringify( valids) != JSON.stringify( props.validsDlg))
			props.handleSetValids( JSON.parse( JSON.stringify( valids)));
	}, [JSON.stringify(valids)]);	
	
	
	// define the steps		
	const [steps, setSteps] = React.useState([
		{
			label: 'Allgemeine Angaben',
			description: ``,
			component: <GeneralForm 
				ref={refGeneralForm} 
				validsDlg={props.validsDlg}
				handleSetValids={handleSetValids}
				stateDlg={props.stateDlg}
				handleSetState={handleSetState}				
			/>
		},	
		{
			label: 'Selektiere die Input Dateien',
			description: ``,
			component: <UploaderForm 
				ref={refUploaderForm} 
				validsDlg={props.validsDlg}
				handleSetValids={handleSetValids}
				stateDlg={props.stateDlg}
				handleSetState={handleSetState}	
			/>
		},
		{
			label: 'Starte dein Server-Job',
			description: ``
		},
	]);	
	
	
	const [activeStep, setActiveStep] = React.useState( props.stateDlg.activeStep);			
	// propagate it to the parent
	const handleSetActiveStep = ( ast) => {
		console.log( 'VerticalLinearStepper.handleSetActiveStep', [ast, props.stateDlg]);
		var st = props.stateDlg;
		st.activeStep = ast;
		props.handleSetState( st);
	}
	useEffect(() => {		
		if( activeStep != props.stateDlg.activeStep)
			handleSetActiveStep( activeStep);
	}, [activeStep]);
	
		
	const handleNext = () => {
		
		if( activeStep==0 && refGeneralForm.current) {
			refGeneralForm.current.saveStep(+1);						
		} else if( activeStep==1 && refUploaderForm.current) {
			refUploaderForm.current.saveStep(+1);				
		}
		
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		
	};

	const handleBack = () => {
		
		//if( activeStep==2 && refMetaInfos.current) {
		//	refMetaInfos.current.saveStep(-1);
		//}		
		
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};
		
	
	const validate = () => {
				
		console.log( 'VALIDS', state.valids);
		var ret = true;
		for( let i=0; i<valids.length; i++) {   // if one of them is false, break
			if( !valids[i])	
				return false;
		}
		
		return ret;
	};			
	
			
	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		validate: validate
	}));	
	
	
	/* 		<div>{'VALIDS: ' + JSON.stringify(valids)}</div> */
	return (
		<>
		<Box sx={{ marginTop:70, padding:10 }}>
			<Stepper activeStep={activeStep} orientation="vertical">
				{steps.map((step, index) => (
					<Step key={step.label}>
					<StepLabel
						optional={
							index === 2 ? (
								<Typography variant="caption">Die Datei wird hochgeladen und der Server-Job gestartet. Dies kann einige Zeit in Anspruch nehmen.</Typography>
							) : null
						}
					>
						{step.label}
					</StepLabel>
					<StepContent>
						<Typography>{step.description}</Typography>
						{step.component}
						<Box sx={{ mb: 2 }}>
							<div>
								<QeeColorButtonPrimary
									disableElevation 
									disabled={!props.validsDlg[activeStep]}
									onClick={index != steps.length - 1 ? handleNext : async () => {
										var ret = await props.fctOnSubmit(); 
										if( ret)
											setOpenQeeDialog( false);
									}}
									sx={{ mt: 1, mr: 1 }}
								>
								{index === steps.length - 1 ? 'Server-Job starten' : 'Weiter'}
								</QeeColorButtonPrimary>
								<QeeColorButtonSecondary
									disableElevation 								
									disabled={index === 0}
									onClick={handleBack}
									sx={{ mt: 1, mr: 1 }}
								>
								Zurück
								</QeeColorButtonSecondary>
							</div>
						</Box>
					</StepContent>
					</Step>
				))}
			</Stepper>
			{activeStep === steps.length && (
				<Paper square elevation={0} sx={{ p: 3 }}>
					<Typography>All steps completed - you&apos;re finished</Typography>
					<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
					Reset
					</Button>
				</Paper>
			)}
		</Box>		
		</>
		);
		
	});
	/******* End VerticalLinearStepper */
	
	
	/*************************************************************
	*
	*	GeneralForm() - component for general data
	*
	*************************************************************/
	const GeneralForm = React.forwardRef((props, forwardedRef) => {	
		const [formData, setFormData] = useState({
			sjo_name: '',
			sjo_type: '',
		});

		const handleInputChange = (event) => {
			setFormData({ ...formData, [event.target.name]: event.target.value });
		};
		

		const validate = () => {
			console.log('--> GeneralForm.validate');
			
			const { sjo_name, sjo_type } = formData;
			const isSjoNameValid = sjo_name && sjo_name.length >= 3;
			const isSjoTypeValid = !!sjo_type;
			
			const isValid = isSjoNameValid && isSjoTypeValid;
			
			console.log('<-- GeneralForm.validate', isValid);    
			return isValid;
		}
		
		
		const saveStep = ( delta) => {
			console.log( 'GeneralForm.saveStep', delta);
			
			var st = props.stateDlg;
			st.activeStep += delta;
			st.formData = formData;
						
			props.handleSetState( st);				
		}		

		
		useImperativeHandle( forwardedRef, () => ({	
			validate: validate,
			saveStep: saveStep			
		}));

		useEffect( () => {			
			var vlds = props.validsDlg; 
			vlds[0] = validate();
			props.handleSetValids( vlds);							
		}, [formData.sjo_name, formData.sjo_type]);



		const handleSubmit = (event) => {
			event.preventDefault();
			// Perform any form submission logic here
			console.log(formData);
		};

		return (
		<form onSubmit={handleSubmit}>
			<Box display="flex" flexDirection="column" gap={2}>
			<Box marginBottom={2} sx={{ width: '100%' }}>
			<TextField
				label="Server-Job Name"
				name="sjo_name"
				value={formData.sjo_name}
				onChange={handleInputChange}
				required
				minLength={3}
				variant="outlined"
				fullWidth
			/>
			</Box>

			<Box marginBottom={2} sx={{ width: '100%' }}>
			<FormControl required fullWidth>
				<InputLabel>Server-Job Schablone</InputLabel>
				<Select
					label="Wähle die gewünschte Schablone aus"
					name="sjo_type"
					value={formData.sjo_type}
					onChange={handleInputChange}
					sx={{ width: '100%' }}
				>
					<MenuItem value="calculateIstRenovationFac">Berechnung aktueller energetischer Zustand</MenuItem>
					<MenuItem value="postFac">Immobilien Import</MenuItem>
				</Select>
			</FormControl>
			</Box>
			</Box>
		</form>
		);	
	})	
	
	
	/*************************************************************
	*
	*	UploaderForm() - component for browser framing
	*
	*************************************************************/
	const UploaderForm = React.forwardRef((props, forwardedRef) => {		
	//const UploaderForm = (props) => {
		
		const offset = 80;
		const [isLoading, setIsLoading] = useState( false);  
		const [height, setHeight] = useState( window.innerHeight>offset ? window.innerHeight-offset : offset);  
		const [tplsPath, setTplsPath] = useState( '');  
		
		const [imgCollection, setImgCollection] = useState( structuredClone( props.stateDlg.imgCollection));		
		const [rws, setRws] = useState( JSON.parse( JSON.stringify( props.stateDlg.rws)));		
		
	
		function handleWindowSizeChange() {
			setHeight( window.innerHeight>offset ? window.innerHeight-offset : offset);
		}	
	
	
		useEffect( () => {	

			setTplsPath( JSON.parse( sessionStorage.getItem( 'scope')).application.publicTplsPath)
		
			window.addEventListener( 'resize', handleWindowSizeChange);
			return () => {
				window.removeEventListener( 'resize', handleWindowSizeChange);
			}

													
		}, [height]);		
		
		
		// some consts
		const useStyles = makeStyles( theme => ({
			uploaderForm: {
				//padding: 10,				
			},
			locationText: {
				color: '#444'
			},

		}));			
		const classes = useStyles( props);
		console.log( 'UploaderForm classes', classes);


		const validate = () => {
			console.log( '--> UploaderForm.validate');
			
			var ret = false;
			
			if( rws.length > 0)
				ret = true;
			
			console.log( '<-- UploaderForm.validate', ret);									
			return ret;
		}						

		
		const saveStep = () => {
			console.log( 'UploaderForm.saveStep', rws);
			
			var st = props.stateDlg;
			st.activeStep += 1;
			console.log( 'SAVE STEP RWS', rws);
			st.imgCollection = imgCollection;
			st.rws = rws;
			console.log( 'SAVE STEP st', st);
			props.handleSetState( st);	
		}		
							
			
		const onFileChange = (files) => {
			let items = files.map(fileItem => fileItem.file)			
			console.log( 'onFileChange.items', items);
			console.log( 'onFileChange.imgCollection', imgCollection);
			console.log( 'onFileChange.rws', rws);
			var ic = imgCollection;			
			var imgs = ic.concat( items);
			imgs = items.filter((a, i) => imgs.findIndex((s) => a.name === s.name) === i);

			
			var rws2 = [];
			if( imgs.length >= rws.length) {	
				console.log( 'More images: imgs.length > rws.length', true)
				rws2 = rws;	
				for( let i=0; i<imgs.length; i++) {
					var rw = {};
					var bFound = false;
					for( let j=0; j<rws.length; j++) {
						if( rws[j].sdoc_name === imgs[i].name) {
							bFound = true;
						}
					}
					if( !bFound) {
						rw.sdoc_name = imgs[i].name;
						rw.sdoc_type_opts = [];
						rw.sdoc_subtype_opts = [];				
						rw.sdoc_type = '';
						rw.sdoc_subtype = '';
						rws2.push( rw);
					}	
				}	
				
			} else if( imgs.length < rws.length) {	
				console.log( 'More rws: imgs.length < rws.length', true)
				for( let i=0; i<rws.length; i++) {
					var bFound = false;
					for( let j=0; j<imgs.length; j++) {
						if( imgs[j].name === rws[i].sdoc_name) {
							bFound = true;
						}
					}
					if( bFound) {
						rws2.push( rws[i]);
					}	
				}						
			}
			console.log( 'onFileChange before set rws', rws2);					
			setRws( rws2);			
			
			console.log( 'onFileChange before set imgs', imgs);					
			setImgCollection( imgs);			
						
		}
		
		
		useEffect( () => {	
		
			if( rws.length != props.stateDlg.rws.length) {
				var vlds = props.validsDlg; 
				vlds[1] = validate();
				props.handleSetValids( vlds);
			}
				
		}, [rws.length]);				
		
		
		/**
		*	expose it outside
		*/
		useImperativeHandle( forwardedRef, () => ({	
			ali: ()=>{alert(123)},
			validate: validate,
			saveStep: saveStep,
		}))
		
		
		const labelIdleHtml = `<div style="width: 100%; height: 100%;">
		  <div style="font-size: 13px;">
			<span class="filepond--label-action" tabindex="0">
			  <a>Datei (*.csv) hochladen</a>
			</span> oder per Drag&Drop hierher ziehen.
		  </div>
		  <div style="font-size: 11px;">(Maximalgröße 30 MB pro Datei)</div>
		  <div style="font-size: 11px; margin-top: 10px;">
			Lade hier eine Beispieldatei herunter
			<a href="${tplsPath}import-facs.csv">CSV Importdatei</a>
		  </div>
		</div>`;		
		
		//onupdatefiles={(fileItems) => onFileChange(fileItems)}>								
		//onupdatefiles={setImgCollection}
		// <form onSubmit={onSubmit}>
		return (
			<div>
				<form>
					<div className="filepond-wrapper">
					  <FilePond
						files={imgCollection}
						allowMultiple={false}
						imagePreviewHeight={150}
						server={null}
						instantUpload={false}
						acceptedFileTypes={acceptedFileTypes}
						onupdatefiles={(fileItems) => onFileChange(fileItems)}
						labelIdle={labelIdleHtml}
					  >
					  </FilePond>
					</div>
				</form>		
			</div>
		)
		
	});
	/********* End UploaderForm */


	/*<div style={{marginTop: 80}}>{'STATE-DLG:' + JSON.stringify( state)}</div>*/
	return (		
		<>
		<div ref={forwardedRef}></div>				
			<QeeSlideDialog 
				id='dlgSjoWiz'
				title='Server-Job' 
				formComponent={
					<>
					{state.isLoading ? 
						<div className={classes.root}>
							<CircularProgress size={50} className={classes.uiProgess} />
						</div>		
					: 					
						<VerticalLinearStepper 
							ref={refStepper} 
							stateDlg={state}
							handleSetState={handleSetState}
							validsDlg={valids}
							handleSetValids={handleSetValids}
							fctOnSubmit={onSubmit}
						/>	
					}
					</>
				}	
				hideDialogActions={true}				
				submitDisabled={state.submitDisabled}
				fctOnSubmit={onSubmit}
				paddingContent={0}
			>
			</QeeSlideDialog>		
		</>	
	)
})	
export default withStyles(styles)(SjoWizDlg);