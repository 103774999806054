// facNewbuildWizDialog.js

import react, { useState, useEffect, useLayoutEffect, useRef, useCallback, createRef } from 'react';
import React, { useImperativeHandle } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { useSnackbar } from 'notistack';
import FacRenovationWiz from './facRenovationWiz';
import { ObjChangeProvider } from "./qeeObjChangeProvider";
import QeeStepper from './qeeStepper';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import axios from 'axios';

import { 
	facNewbuildPlanCalculate 
} from "../api/service";

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	button: {
		textTransform: 'none'
	},
	/*
	newPosOfDialog: {		
		[theme.breakpoints.up('lg')]: {
			transform: "translate(100%, 0%)",
		},			
	},
	dialog: {
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},			
		position: 'absolute',
		left: 10,
		top: 50,	
	},	
	dialogStyle: {
		maxWidth: '50%',
		[theme.breakpoints.down('md')]: {
			maxWidth: '100%'
		},						
	},
	*/	
	appBarTitle: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
});



// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


// main fct
//const FacNewbuildWizDialog = ( props) => {
const FacNewbuildWizDialog = React.forwardRef((props, forwardedRef) => {	
	
	const dialogRef = useRef( null);
	const { enqueueSnackbar } = useSnackbar();

	const { gstate, setObjEdit, setObjErrors, setOpenNewbuildDialog, setIsFetching, setCurrSubpage } = useAuth();				
	
	const handleNewbuildOpen = ( step) => {
		console.log( 'handleNewbuildOpen', step);
		
		setOpenNewbuildDialog( true);

		if( step == undefined)
			step = 0;	
		var oe = gstate.objEdit;
		oe.step = step;		
		if( step >= 4)
			oe.fac_use_plan_renovation = '1';
		setObjEdit( oe);
	}	
	
	const handleNewbuildClose = () => {
		setObjErrors({});
		setOpenNewbuildDialog( false);
	};
		
		
	const handleNewbuildSubmit = async (newFac) => {
		
		console.log( 'handleNewbuildSubmit', newFac);
		
		const authToken = localStorage.getItem( 'AuthToken');
		
		console.log( 'submit fac', newFac);		
		//setObjEdit( newFac);		
				
		let options = {};
		let id = newFac.id;
		options = {
			url: `/api/fac/${id}`,
			method: 'put',
			data: newFac
		};
		if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
			console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
			options.url = window.QEE_API_URL + options.url;	
		}
		console.log( 'Using URL', options.url);				
		axios.defaults.headers.common = { Authorization: `${authToken}` };	
		try {
			var data = await axios(options);
			console.log( '<-- facSave', data.data);
			return newFac;						
		} catch( error) {
			//setObjErrors( error.response.data);
			console.log( '<-- facSave ERR', error);
			return {err:error};						
		}		
	};
	
	
	const handleNewbuildCalculate = async (event) => {
		
		console.log( 'handleNewbuildCalculate', event);
		
		//authMiddleWare(this.props.history);
		const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login');	
		
		setIsFetching( true);						
		
		const newFac = gstate.objEdit;
		console.log( 'calculate obj', newFac);		
		let options = {};
		let id = gstate.objEdit.id;
		options = {
			url: `/api/fac/renovation/ist/${id}`,
			method: 'put',
			data: newFac
		};		
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		try {
			const retFac = await axios( options);
			console.log( 'Calculated fac', retFac.data);
			setObjEdit( JSON.parse( JSON.stringify( retFac.data)));
		} catch( error) {
			setState({ 
				...state,
				open: true, 
				errors: error.response.data 
			});
			setObjErrors( error.response.data);
			console.log(error);
		} finally {
			setIsFetching( false);									
		}
	};	
	
		
	/**
	*	handleNewbuildSubmitEnd() - 
	*/
	const handleNewbuildSubmitEnd = async ( newFac) => {
		
		console.log( '--> handleNewbuildSubmitEnd', newFac);
		
		//let newFac = JSON.parse( JSON.stringify( gstate.objEdit))
		
		 // append the key for checking the license (enterprise)
		let usr = JSON.parse( sessionStorage.getItem( 'usr'));				
		if( usr.lic)
			newFac.liccus_key = usr.lic.liccus_key;  

		setIsFetching( true);

		let retFac = newFac;

		try {
			newFac.fac_newbuild_wiz_ready = true;    // set the wiz as ready
			console.log( 'Calculate fac newbuild', newFac);	
			await handleNewbuildSubmit( newFac)
			let ret = await facNewbuildPlanCalculate( newFac);
			if( ret.data) {
				retFac = ret.data;
				enqueueSnackbar( 'Berechnung abgeschlossen!', {variant: 'success'});
				
				if( props.subpageNewbuild)
					setCurrSubpage( props.subpageNewbuild)					
			} else {
				let msg = 'Die Operation konnte nicht ausgeführt werden! ' + ret.err;
				console.error( 'handleNewbuildSubmitEnd ERR', msg);
				enqueueSnackbar( msg, {variant: 'error'});
			}
			
		} catch( e) {
			let msg = 'Die Operation konnte nicht ausgeführt werden! ' + e;
			console.error( 'handleNewbuildSubmitEnd ERR', msg);
			enqueueSnackbar( msg, {variant: 'error'});
		} 		
				
		setIsFetching( false);
		
		setOpenNewbuildDialog( false);	
		
		console.log( '<-- handleNewbuildSubmitEnd', retFac);

		return retFac;		
	}

	
	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		ali: ()=>{alert(123)},
		handleNewbuildOpen: (step)=>{handleNewbuildOpen(step)},						
		handleNewbuildClose: handleNewbuildClose,	
		handleNewbuildSubmit: handleNewbuildSubmit,
		handleNewbuildCalculate: handleNewbuildCalculate,
		handleNewbuildSubmitEnd: handleNewbuildSubmitEnd				
	 }));
	 
	//const ref = useRef();	 
	
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
		screenDims: {width:0, height:0},
		openNewbuild: false		
	})
		
	useEffect( () => {	

						
	}, []);		

	
	// some consts	
	const useStyles = makeStyles(theme => ({
		//...props,	
		newPosOfDialog: {		
			[theme.breakpoints.up('lg')]: {
				transform: !props.forceFullWidth ? "translate(100%, 0%)" : "",
			},			
		},
		dialog: {
			width: props.forceFullWidth ? '100%' : '50%',
			[theme.breakpoints.down('md')]: {
				width: '100%'
			},			
			position: 'absolute',
			left: 10,
			top: 50,	
		},	
		dialogStyle: {
			maxWidth: props.forceFullWidth ? '100%' : '50%',
			[theme.breakpoints.down('md')]: {
				maxWidth: '100%'
			},						
		},			
	}));	
	const classes = useStyles( props);	

		
	return (	
	
		<>
		<div ref={forwardedRef}></div>
		<Dialog 			
			fullScreen
			ref={dialogRef}
			classes={{
				paper: classes.newPosOfDialog,
				paperFullWidth: classes.dialogStyle						
			}}
			className={classes.dialog}
			open={gstate.openNewbuildDialog} 
			onClose={handleNewbuildClose} 
			TransitionComponent={Transition}
		>
		
			<AppBar className={classes.appBarTitle}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleNewbuildClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{'Neubauasssistent für ' + gstate.objEdit.fac_name}
					</Typography>
				</Toolbar>
			</AppBar>
			<DialogContent style={{padding:10}}>
			<ObjChangeProvider>
				<Box style={{padding: gstate.objEdit.isXS ? 10 : 30, marginTop:60}}>					
					<QeeStepper 
						instanceName="qeeleaapp-newbuild" 
						parentWindowClass=".MuiDialog-paper"
						objChange={gstate.objEdit}
						wizReadyFieldName="fac_newbuild_wiz_ready"
						fctSubmitEnd={(fac)=>handleNewbuildSubmitEnd(fac)}
						stepSubmitEnd={7}
					/>
				</Box>
			</ObjChangeProvider>
			</DialogContent>	
		</Dialog>	
		</>
	
	)
})	
export default withStyles(styles)(FacNewbuildWizDialog);