import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { DatePicker, DateTimePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import ButtonCounter from './buttonCounter';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(4),
    },
    form: {
        marginTop: theme.spacing(4),
		padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    root: {
        minWidth: 470,
    },
}));

const QeeSingleFieldEditor = (props) => {
    const classes = useStyles();
    const [hasErrors, setHasErrors] = useState(true);
    const [errors, setErrors] = useState({});
    const [text, setText] = useState(props.vl);
    const [date, setDate] = useState(new Date(props.vl));
    const [obj, setObj] = useState({ error: false, msg: '', value: props.objData ? props.objData : {} }); // init to empty obj, for any other case

    const handleChange = (event) => {
        setText(event.target.value);
        validateText(event.target.value);
        if (props.fctOnChanges) {
            props.fctOnChanges({ text: event.target.value });
        }
    };
    const handleBlur = () => {
        validateText(text);
    };

    const handleDateChange = (date) => {
        setDate( date);
		validateDate( date);
        if (props.fctOnChanges) {
            props.fctOnChanges( {date});
        }
    };

	const handleObjChange = (event) => {
		let value = event.target.value;
		let newObj = { ...obj.value, [props.fieldName]: value };
		setObj({ value: newObj, error: false, msg: '' });
		validateObj(newObj);
		setHasErrors(!isValidObj(newObj));
		if (props.fctOnChanges) {
			props.fctOnChanges( newObj[props.fieldName]);
		}
	};

    useEffect(() => {
        if (!date) {
            setDate(new Date());
        }
    }, [date]);

    const validateText = (value) => {
        console.log('--> validateText for QeeSingleFieldEditor');

        if (props.fctOnHasErrors) {
            if (!isValidText(value)) {
                props.fctOnHasErrors(true);
                setHasErrors(true);
            } else {
                props.fctOnHasErrors(false);
                setHasErrors(false);
            }
        }

        console.log('<-- validateText');
    };

	const validateDate = (value) => {
        console.log('--> validateDate for QeeSingleFieldEditor');

        if (props.fctOnHasErrors) {
            if (!isValidDate(value)) {
                props.fctOnHasErrors(true);
                setHasErrors(true);
            } else {
                props.fctOnHasErrors(false);
                setHasErrors(false);
            }
        }

        console.log('<-- validateText');
    };

    const validateObj = (value) => {
        console.log('--> validateObj for QeeSingleFieldEditor');

        if (props.fctOnHasErrors) {
            if (!isValidObj(value)) {
                props.fctOnHasErrors(true);
                setHasErrors(true);
            } else {
                props.fctOnHasErrors(false);
                setHasErrors(false);
            }
        }

        console.log('<-- validateObj');
    };

    const isValidText = (vl) => {
        if (!vl) vl = text;
        if (!vl) vl = '';
        return vl.length > 12;
    };

    const isValidDate = (vl) => {
		return true;
    };

    const isValidObj = (vl) => {
        if (!vl) vl = obj.value;
        if (!vl) vl = {};
        return true;
    };
	

    return (
        <div className={classes.form}>
            <form noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h1>{props.title}</h1>
                    </Grid>
                    <Grid item xs={12}>
                        {(() => {
                            if (props.fieldType === 'TextField' || props.fieldType === 'TextFieldMultiline') {
                                return (
                                    <TextField
                                        name="textField"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label={props.label}
                                        placeholder={props.placeholder}
                                        multiline={props.fieldType === 'TextFieldMultiline'}
                                        rows={props.fieldType === 'TextFieldMultiline' ? 14 : 1}
                                        value={text}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                );
                            } else if (props.fieldType === 'DatePicker') {
                                return (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            variant="outlined"
                                            label={props.label}
                                            format="dd.MM.yyyy"
                                            value={date}
                                            onChange={handleDateChange}
                                            fullWidth
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                );
                            } else if (props.fieldType === 'DateTimePicker') {
                                return (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            variant="outlined"
                                            label={props.label}
                                            format="dd.MM.yyyy HH:mm"
                                            value={date}
                                            onChange={handleDateChange}
                                            fullWidth
                                            inputVariant="outlined"
                                            ampm={false}
                                        />
                                    </MuiPickersUtilsProvider>
                                );
                            } else if (props.fieldType === 'TimePicker') {
                                return (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            variant="outlined"
                                            label={props.label}
                                            format="HH:mm"
                                            value={date}
                                            onChange={handleDateChange}
                                            fullWidth
                                            inputVariant="outlined"
                                            ampm={false}
                                        />
                                    </MuiPickersUtilsProvider>
                                );
                            } else if (props.fieldType === 'ButtonCounter') {
                                return (
                                    <ButtonCounter
                                        objData={props.objData}
                                        setObjData={(obj) => {
                                            var evt = {};
                                            evt.target = {};
                                            evt.target.name = 'buttonCounter';
                                            evt.target.value = obj;
                                            handleObjChange(evt);
                                        }}
                                        field={props.fieldName}
                                        defaultValue={props.defaultValue}
                                        min={props.min}
                                        max={props.max}
                                        step={props.step}
                                        label={props.label}
                                    />
                                );
                            } else {
                                return <div>Invalid field type</div>;
                            }
                        })()}
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default QeeSingleFieldEditor;
