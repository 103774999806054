import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import { getSdatas } from '../api/service'; 
import { QeeColorButtonPrimary } from './qeeColorButton';

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 275,
		cursor: 'pointer',
		marginBottom: 16,
		borderRadius: 10,
		padding: 10,
		height: 200,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	title: {
		marginTop: 0,
		fontSize: 14,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	pos: {
		marginBottom: 6,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	role: {
		marginBottom: 6,
		color: theme.palette.primary.main,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	button: {
		marginTop: 12,
	},
	cardContent: {
		flex: '1 1 auto',
	},
	cardActions: {
		paddingLeft: 10,
	},
}));

const truncateText = (text, length) => {
	if (text.length <= length) return text;
	return text.substring(0, length) + '...';
};

const OrgCard = ({ org, onClick }) => {
	const classes = useStyles();
	const [roleText, setRoleText] = useState('');

	const fullAddress = `${org.addr_street_o} ${org.addr_housenumber_o}, ${org.addr_locality_o}`;

	useEffect(() => {
		const fetchRoleText = async () => {
			try {
				if (Array.isArray(org.org_perprj_role)) {
					const roleNames = await Promise.all(
						org.org_perprj_role.map(async (role) => {
							const data = await getSdatas('sdata_perprj_roles_types', 'type', role);
							return data.length > 0 ? data[0].name : 'Keine Angaben';
						})
					);
					setRoleText(roleNames.join(', '));
				} else {
					const data = await getSdatas('sdata_perprj_roles_types', 'type', org.org_perprj_role);
					const roleName = data.length > 0 ? data[0].name : 'Keine Angaben';
					setRoleText(roleName);
				}
			} catch (error) {
				console.error('Error fetching role text:', error);
				setRoleText('Abfragefehler für Gewerke');
			}
		};

		fetchRoleText();
	}, [org.org_perprj_role]);

	return (
		<Card className={classes.root} onClick={onClick}>
			<CardContent className={classes.cardContent}>
				<Typography variant="h5" component="h2" className={classes.title}>
					{org.org_name}
				</Typography>
				<Typography className={classes.pos} color="textSecondary">
					{fullAddress}
				</Typography>
				<Typography variant="body2" component="p" className={classes.role}>
					{'Gewerke: ' + roleText}
				</Typography>
			</CardContent>
			<CardActions className={classes.cardActions}>
				<QeeColorButtonPrimary
					disableElevation
					className={classes.button}
					onClick={onClick}
				>
					<HelpOutlinedIcon style={{ marginRight: 10 }} />
					Details und Kontakt
				</QeeColorButtonPrimary>
			</CardActions>
		</Card>
	);
};

export default OrgCard;
