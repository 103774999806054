import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import MobileStepper from '@mui/material/MobileStepper';
import ButtonBase from '@material-ui/core/ButtonBase';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { bindKeyboard } from 'react-swipeable-views-utils';
import { useAuth } from "../context/provider";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		position: 'relative',
		margin: 0,
		padding: 40,
		backgroundColor: '#222 !important',
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '80vh',
		backgroundColor: '#222 !important',		
	},
	image: {
		maxWidth: '100%',
		maxHeight: '100%',
		objectFit: 'contain',
	},
});

function SwipeablePictures(props) {
	const { gstate } = useAuth();
	const classes = useStyles();
	const refBox = useRef(null);
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(props.activeStep || 0);
	const maxSteps = props.images.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	useEffect(() => {}, [activeStep]);

	return (
		<Box className={classes.root}>
			<BindKeyboardSwipeableViews
				autoplay={false}
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
				animateHeight={true}
			>
				{props.images.map((step, idx) => (
					<div key={step.caption} className={classes.imageContainer}>
						{Math.abs(activeStep - idx) <= 1 ? (
							<img
								className={classes.image}
								src={step.urlImg}
								alt={step.caption}
							/>
						) : null}
					</div>
				))}
			</BindKeyboardSwipeableViews>

			<MobileStepper
				steps={maxSteps}
				position="static"
				activeStep={activeStep}
				variant="text"
				sx={{
					bgcolor: '#222',
					color: '#ddd',
				}}
				nextButton={
					<Button
						size="small"
						onClick={handleNext}
						disabled={activeStep === maxSteps - 1}
						style={{ textTransform: 'none', color: '#ddd', visibility: gstate.isXS ? 'hidden' : 'visible' }} // Hide button on small screens
					>
						Nächstes
						{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
					</Button>
				}
				backButton={
					<Button
						size="small"
						onClick={handleBack}
						disabled={activeStep === 0}
						style={{ textTransform: 'none', color: '#ddd', visibility: gstate.isXS ? 'hidden' : 'visible' }} // Hide button on small screens
					>
						Vorheriges
						{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
					</Button>
				}
			/>
		</Box>
	);
}

export default SwipeablePictures;
