import { useState } from "react";
import { makeStyles }  from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import style from "./PaymentForm.module.scss";

import clsx from "clsx";
import { format } from "date-fns";
import { useStripe, CardCvcElement, useElements } from "@stripe/react-stripe-js";
import { useSnackbar } from 'notistack';

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from '../../qeeColorButton';

import { postRequest } from "../utils/api";

export default function PaymentForm({ paymentMethod, paymentIntent }) {
	const stripe = useStripe();
	const elements = useElements();

	const [isLoading, setIsLoading] = useState( false);		
	
	const [cvcError, setCvcError] = useState(null);

	const { card, billing_details } = paymentMethod;
	
	const { enqueueSnackbar } = useSnackbar();			
	
	// some consts	
	const useStyles = makeStyles({
		//...props,	
		button: {
			width: 200,
			height: 48
		}
	});	
	const classes = useStyles();				

	const handleSubmit = async (e) => {
		e.preventDefault();
		
		setIsLoading( true);		
		
		let usr = JSON.parse( sessionStorage.getItem( 'usr'));			
		
		// try to get the fac_id, if any in the url
		const myURL = new URL( window.location);
		const facId = myURL.searchParams.get( 'id');
		
		stripe
			.createToken("cvc_update", elements.getElement( CardCvcElement))
			.then((result) => {
				if (result.error) {
					setIsLoading( false);
					setCvcError(result.error.message);
				} else {
					postRequest(`/api/payment/confirm/`+usr.usrId+'/'+facId, {
						paymentMethod: paymentMethod.id,
						paymentIntent: paymentIntent.id,
					})
					.then((resp) => {
						console.log(resp.data);
						//setIsLoading( false);
						handleServerResponse(resp.data);
					})
            .catch((err) => {
				console.log(err);
				setIsLoading( false);
            });
			}
      })
      .catch((err) => {
			console.log(err);
			setIsLoading( false);
			/* Handle error*/
      });
  };

  
  function handleServerResponse( response) {
		if (response.error) {
			/* Handle Error */
			enqueueSnackbar( 'Die Zahlung konnte nicht ausgeführt werden! Error: ' + response.error, {variant: 'error'});
		} else if (response.next_action) {
			handleAction(response);
		} else {
			/* Handle Success */			
			//alert("Payment Success");
			enqueueSnackbar( 'Die Zahlung wurde erfolgreich ausgeführt.', {variant: 'success'});											
			setTimeout(() => {			
				enqueueSnackbar( 'Um alle lizenzierten Features zu aktivieren wird das Browserfenster neu geladen...', {variant: 'default'});											
				setTimeout(() => {			
					window.location.reload();
				}, 3000);				
			}, 1000);						
		}
	}

	
	function handleAction(response) {
		
		let usr = JSON.parse( sessionStorage.getItem( 'usr'));	

		// try to get the fac_id, if any in the url
		const myURL = new URL( window.location);
		const facId = myURL.searchParams.get( 'id');		
		
		setIsLoading( true);
		
		stripe.handleCardAction(response.client_secret).then( function (result) {
			if (result.error) {
				console.log(result.error);
				setIsLoading( false);
				/* Handle error */
			} else {
				postRequest(`/api/payment/confirm/`+usr.usrId+'/'+facId, {
					paymentIntent: paymentIntent.id,
					paymentMethod: paymentMethod.id,
				})
				.then((resp) => {
					setIsLoading( false);
					handleServerResponse(resp.data);
				})
				.catch((err) => {
					setIsLoading( false);					
					console.log(err);					
					/* Handle Error */
				});
			}
		});
	}

	
	return (
		card && (
			<div className={style.wrapper}>
				<form>
					<div className={style.card}>
						<div className={style.icon}>
							<img src={card.icon} alt="" />
						</div>
						<div className={style.row}>
							<label>Karteninhaber</label>
							<p>{billing_details.name}</p>
						</div>
						<div className={clsx(style.row, style.col)}>
							<div className={style.cardNumber}>
								<label>Kartennummer</label>
								<p>{`**** **** **** ${card.last4}`}</p>
							</div>
							<div className={style.expiry}>
								<label>Ablaufdatum der Karte</label>
								<p>{format(new Date(`${card.exp_year}/${card.exp_month}/01`), "mm/yyyy")}</p>
							</div>
						</div>
						<div className={style.row}>
							<label>Eingabe Cvc/Cvv </label>
							<div className={style.cvcInput}>
								<CardCvcElement
									onChange={() => {
										setCvcError(null);
									}}
								/>
							</div>
							<p className={style.cvcError}>{cvcError}</p>
						</div>						
					</div>

					<QeeColorButtonPrimary disableElevation 
						className={classes.button} 
						onClick={handleSubmit}>
						{isLoading ?
							<CircularProgress style={{color:'#fff'}}/>
							:	
							<>Zahlungsauftrag starten</>
						}						
					</QeeColorButtonPrimary>													
				</form>
			</div>
		)
	);
}
