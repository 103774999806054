// facTitle

import react, { useState, useEffect, useLayoutEffect } from 'react';
import React, { Dimensions, Component } from 'react';

import { makeStyles }  from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MarkerMapIconIcon from '@mui/icons-material/Room';

import axios from 'axios';

import house from '../assets/villa-sample.jpg';
import apartment from '../assets/apartment-sample.jpg';
import pinpointmap from '../assets/pinpointmap.png';
import QeeSlideDialog from './qeeSlideDialog';
import QeeGmap from './qeeGmap';
import FacImage from './facImage';

import { getDocs, getDocsNet, getSdatas } from "../api/service";

import { useAuth } from "../context/provider";


const FacTitle = (props) => {
	
	console.log( 'FacTitle props', props.fac);
	
	const navigate = useNavigate();
	
	const { gstate, setObjEdit, setObjErrors, setOpenQeeDialog } = useAuth();					

	const [facTypeName, setFacTypeName] = useState( '');	
	const [url, setUrl] = useState( '');		
	const [addressLabel, setAddressLabel] = useState( '');

	const [addr, setAddr] = useState({addr_gvalue_o: gstate.objEdit.addr_gvalue_o,
										addr_gvalue_text_o: gstate.objEdit.addr_gvalue_o ? gstate.objEdit.addr_gvalue_o.label : '',
										addr_locality_o: gstate.objEdit.addr_locality_zip_o, 										
										addr_street_zip_o: gstate.objEdit.addr_street_zip_o, 
										addr_geo_lat_o: gstate.objEdit.addr_lat_o, 
										addr_geo_lon_o: gstate.objEdit.addr_lon_o});		
	
	// some consts
	const useStyles = makeStyles( theme => ({
		container: {
			whiteSpace: 'nowrap', 
			//backgroundColor: '#dfd',
			borderBottom: `1px solid ${theme.palette.primary.main}`,					
			//borderRadius: 5,
			padding: 20,
			paddingLeft: 0,
			[theme.breakpoints.down('xs')]: {			
				padding: 5,
			}
		},
		pictureDiv: {
			width:gstate.isXS ? 100 : 120, 
			height:80, 
			borderRadius: 5, 
			backgroundColor: '#eee',
			marginRight: 10,			
		},		
		picture: {
			width:gstate.isXS ? 100 : 120, 
			height: 80,
			borderRadius: 5, 
			marginRight: 10,
			objectFit: 'cover'			
		},
		cardTitle: {
			whiteSpace: 'nowrap', 
			overflow: 'hidden', 
			textOverflow: 'ellipsis', 
			fontWeight: 600,
			color:'#444',
			[theme.breakpoints.down('xs')]: {	
				fontSize: 14,			
				fontWeight: 500,
			},						
			width:450,
			[theme.breakpoints.down('xs')]: {
				width:300 
			},															
			[theme.breakpoints.up('md')]: {
				width:600 
			},												
			[theme.breakpoints.up('lg')]: {
				width:700 
			},									
			[theme.breakpoints.up('xl')]: {
				width:1000 
			},
				
		},		

	}));			
	const classes = useStyles( props);	
		

	const getFacTypeName = async ( fac) => {			
		var nm = '';
		var arrRet = [];
		if( fac.fac_type == '100') {
			arrRet = await getSdatas( 'sdata_houses_types', 'type', fac.fac_house_type);	
		} else if( fac.fac_type == '200') {
			arrRet = await getSdatas( 'sdata_apartments_types', 'type', fac.fac_apartment_type);	
		}
		if( arrRet.length)
			setFacTypeName( arrRet[0].name);
	}					
		
		
	const getPicture = async ( fac) => {
		
		var u;
				
		// first try to get the first '100' picture files from storage
		const docs = await getDocs( fac.id, ['sdoc_type','sdoc_subtype'], [200, 100]);	
		console.log( 'FacTitle getDocs', docs);			
		if( !docs.err && docs.length) {					
			u = docs[0].signedUrl;
			
		// if nothing found, try to show some internet pics	
		} else {								
				
			// has some internet pics
			if( props.fac.fac_pictures && props.fac.fac_pictures.length) {
				u = props.fac.fac_pictures[0].url;					
				
			// last choice - show the dummy pic	
			} else {
				if( props.fac.fac_type+'' == '100')
					u = house;
				else	
					u = apartment;
			}
		}								
				
		setUrl( u);
	}		
		

	useEffect(() => {
		const getTitle = () => {	
				
			getFacTypeName( gstate.objEdit);
			getPicture( gstate.objEdit);
			var txt = 'unbekannt';
			if( gstate.objEdit.addr_gvalue_o)
				txt = gstate.objEdit.addr_gvalue_o.label;			
			setAddressLabel( txt);
						
		}
		getTitle();
	}, [JSON.stringify( gstate.objEdit.addr_gvalue_o)])

	
	const handleSubmit = async () => {
		console.log( 'handleSubmit', addr);
		const authToken = localStorage.getItem( 'AuthToken');
		if( authToken == null) navigate( '/home');	
		
		const newFac = {
			...gstate.objEdit,
			...addr
		};
		console.log( 'submit obj', newFac);		
		//setObjEdit( newFac);		
				
		let options = {};
		let id = gstate.objEdit.id;
		options = {
			url: `/api/fac/${id}`,
			method: 'put',
			data: newFac
		};
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		try {
			var data = await axios(options);
			console.log( 'data', data.data);
			
			setObjEdit( data.data);
			
			return true;						
		} catch( error) {
			setObjErrors( error.response.data);
			console.log(error);			
			return false;						
		}			
	}
	
	
	const handleAddressChange = ( oAddr) => {
		setAddr( oAddr);
	}
	
	
	return (
		<>
		<Box className={classes.container}>			
			<Grid container direction="row">
				<Grid item>		
					{props.showPinPoint ? <a href='#' onClick={()=>setOpenQeeDialog({open:true, id:'dlgGmap1'})}><img src={pinpointmap} className={classes.picture} /></a> : ''}
					{props.showPicture ? <div className={classes.pictureDiv}>
						<FacImage fac={gstate.objEdit} 
							pictureDivStyle={{
								width:gstate.isXS ? 100 : 120, 
								height:80, 
								borderRadius: 5, 
								backgroundColor: '#eee',
								//marginRight: 10,											
							}}
							pictureStyle={{width:gstate.isXS ? 100 : 120, 
								height: 80,
								borderRadius: 5, 
								//marginRight: 10,
								objectFit: 'cover'
							}} 
						/>
						</div> : ''}
				</Grid>
				<Grid item>	
					<div className={classes.cardTitle}><Typography variant='body4'>{gstate.objEdit.fac_name}</Typography></div>				
					<div className={classes.cardTitle} style={{marginTop:0}}><Typography variant='body2'>{facTypeName}</Typography></div>																		
					<div className={classes.cardTitle}><Typography variant='body2'>{addressLabel}</Typography></div>							
				</Grid>	
			</Grid>	
		</Box>		
		<QeeSlideDialog 
			id='dlgGmap1'
			title='Geographische Position' 
			formComponent={
				<QeeGmap 
					showSearchBar={true}
					showResultBar={true}					
					gvalue={addr.addr_gvalue_o} 
					lat={addr.addr_geo_lat_o} 
					lon={addr.addr_geo_lon_o}
					fctOnAddressChange={handleAddressChange}
					marginTop={70}
				/>
			}	
			fctOnSubmit={handleSubmit}>
		</QeeSlideDialog>			
		</>
	)
}
export default FacTitle;


