// QeeFileUploaderProvider
import React, { createContext, useMemo, useReducer, useContext, useRef } from 'react';

import FileUploaderDialog from './fileUploaderDialog';
import { useAuth } from "../context/provider"; 


const FileUploaderContext = createContext();

const QeeFileUploaderProvider = (props) => {
	
	const { gstate } = useAuth();					
	
	const refFileUploaderDialog = useRef(null);			
	
	const setOpenFileUploaderDialog = ( fctReload) => {
		refFileUploaderDialog.current.handleFileUploaderOpen( fctReload);
	};

	const value = useMemo(() => {   
		return { setOpenFileUploaderDialog };	  	  
	}, []);	
	
	return (
		<FileUploaderContext.Provider value={value}>
			<FileUploaderDialog 
				ref={refFileUploaderDialog} 
			/>	
			{props.children}	
		</FileUploaderContext.Provider>
	);

}

const useFileUploader = () => useContext( FileUploaderContext );
export { FileUploaderContext, useFileUploader }
export default QeeFileUploaderProvider;
	

