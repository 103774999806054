export const  initialGState = {
	isBetaVer: false,
	xs: 0,
	isXS: false,
	isSM: false,
	isMD: false,
	isLG: false,
	isXL: false,	
    isLoading: false,
    isFetching: false,	
	currPage: '',
	currSubpage: '',
	openNewFac: null,
	submitNewFac: null,	
	usr: {
		lic: {type:'free',liccus_id:'',liccus_key:''}
	},	
	objEdit: {},
	objErrors: [],
	facsType: 'myFacs',				
	facsSearching: false,			
	facsSearchFilter: {},		
	facsExternSorting: 'fac_data_creation@desc',		
	facs: {entries:{hits:0,results:[]}},			
	facsExtern: {entries:{hits:0,results:[]}},			
	searchErrors: [],		
	facsFav: {entries:{hits:0,results:[]}},			
	openItemEditor: false,	
	openQeeDialog: {
		open: false,
		id: null	
	},
	qeeSnackbar: {
		open: false,
		severity: '',
		msg: '',
		fctOnClose: null
	}		
};

//REDUCER
const authReducer = (gstate = initialGState, action) => {
	switch (action.type) {
	case 'SET_IS_BETAVER':
		return {
			...gstate,
			isBetaVer: action.isBetaVer
		};							
	case 'SET_IS_XS':
		return {
			...gstate,
			isXS: action.isXS
		};					
	case 'SET_IS_SM':
		return {
			...gstate,
			isSM: action.isSM
		};			
	case 'SET_IS_MD':
		return {
			...gstate,
			isMD: action.isMD
		};					
	case 'SET_IS_LG':
		return {
			...gstate,
			isLG: action.isLG
		};		
	case 'SET_IS_XL':
		return {
			...gstate,
			isXL: action.isXL
		};					
	case 'SET_IS_LOADING':
		return {
			...gstate,
			isLoading: action.isLoading
		};	
	case 'SET_IS_FETCHING':
		return {
			...gstate,
			isFetching: action.isFetching
		};			
	case 'SET_CURR_PAGE':
		return {
			...gstate,
			currPage: action.currPage
		};			
	case 'SET_CURR_SUBPAGE':
		return {
			...gstate,
			currSubpage: action.currSubpage
		};							
    case 'SET_OPEN_NEW_FAC':
		return {
			...gstate,
			openNewFac: action.openNewFac
		};	
   case 'SET_SUBMIT_NEW_FAC':
		return {
			...gstate,
			submitNewFac: action.submitNewFac
		};		
    case 'SET_USR':
		return {
			...gstate,
			usr: action.usr
		};			
    case 'SET_OBJ_EDIT':
		return {
			...gstate,
			objEdit: action.objEdit
		};	
    case 'SET_OBJ_ERRORS':
		return {
			...gstate,
			objErrors: action.objErrors
		};	
    case 'SET_FACS_SEARCHING':
		return {
			...gstate,
			facsSearching: action.facsSearching
		};			
    case 'SET_FACS_SEARCH_FILTER':
		return {
			...gstate,
			facsSearchFilter: action.facsSearchFilter
		};	
    case 'SET_FACS_EXTERN_SORTING':
		return {
			...gstate,
			facsExternSorting: action.facsExternSorting
		};					
    case 'SET_FACS':
		return {
			...gstate,
			facs: action.facs
		};			
    case 'SET_FACS_TYPE':
		return {
			...gstate,
			facsType: action.facsType
		};					
    case 'SET_FACS_EXTERN':
		return {
			...gstate,
			facsExtern: action.facsExtern
		};	
    case 'SET_SEARCH_ERRORS':
		return {
			...gstate,
			searchErrors: action.searchErrors
		};	
    case 'SET_FACS_FAV':
		return {
			...gstate,
			facsFav: action.facsFav
		};			

   case 'SET_OPEN_NEWBUILD_DIALOG':
		return {
			...gstate,
			openNewbuildDialog: action.openNewbuildDialog
		};	

    case 'SET_OPEN_RENOVATION_DIALOG':
		return {
			...gstate,
			openRenovationDialog: action.openRenovationDialog
		};	
		
    case 'SET_OPEN_VALUATION_DIALOG':
		return {
			...gstate,
			openValuationDialog: action.openValuationDialog
		};									
		
    case 'SET_OPEN_FINANCING_DIALOG':
		return {
			...gstate,
			openFinancingDialog: action.openFinancingDialog
		};									

    case 'SET_OPEN_INSURANCE_DIALOG':
		return {
			...gstate,
			openInsuranceDialog: action.openInsuranceDialog
		};											
		
    case 'SET_OPEN_ITEM_EDITOR':
		return {
			...gstate,
			openItemEditor: action.openItemEditor
		};					
    case 'SET_OPEN_QEE_DIALOG':
		return {
			...gstate,
			openQeeDialog: action.openQeeDialog
		};							
    case 'SET_QEE_SNACKBAR':
		return {
			...gstate,
			qeeSnackbar: action.qeeSnackbar
		};									
	default:
		console.log( 'Reducer default!');
	}
};

export default authReducer;
