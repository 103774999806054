// footer.js

import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { makeStyles }  from '@material-ui/core/styles';
var qeeAppName = 'QEE Widgets'
if (typeof process !== 'undefined')
	qeeAppName = process.env.QEE_APP_NAME;


function Footer( props ) {
		
	// some consts
	const useStyles = makeStyles( theme => ({
		phantom : {
			display: 'block',
			padding: '20px',
			height: '60px',
			width: '100%',
		},	
		footer : {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			borderTop: "1px solid #E7E7E7",
			textAlign: "center",
			padding: "20px",
			position: "fixed",
			left: props.left,
			bottom: "0",
			height: "60px",
			width: props.width,
			fontSize: 12
		}	
	}));			
	const classes = useStyles( props);  				
		
    return (
        <div>
            <div className={classes.phantom} />
            <div className={`${classes.footer} qeeFooter`} >
                © 2024 {qeeAppName}. Powered by Qalgo GmbH.
            </div>
        </div>
    )
}

export default Footer;