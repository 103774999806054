import React from "react";
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SacsSkeleton = () => {
	
	const theme = useTheme();	
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));	
	
    return (
        <SkeletonTheme color="#aaa" highlightColor="#ccc">
            <section style={{ padding: "0px", color: "#888" }}>
                {/* Toolbar Skeleton */}
                <div style={{ height: "70px", paddingLeft: '8px'}}>
                    <Skeleton height={70} />
                </div>
                {/* Main Content Skeleton */}
                <div style={{ display: "flex", flexDirection: "row" }}>
				
                    {/* Drawer Skeleton */}
					{isLargeScreen &&
						<div style={{ width: "250px", padding: "8px" }}>
							<Skeleton height={"100vh"} />
						</div>
					}
                    {/* SACs List Skeleton */}
                    <div style={{ flex: 1 }}>
                        <div style={{ padding: "8px" }}>
                            {/* Filter Bar Skeleton */}
                            <div>
                                <Skeleton height={50} width={250} />
                            </div>
                            {/* SAC Cards Skeleton */}
                            <div style={{ marginTop: "16px" }}>
                                <Skeleton height={200} count={5} width={isLargeScreen ? 800 : '90%'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </SkeletonTheme>
    );
};

export default SacsSkeleton;
