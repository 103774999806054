// home.js

import React from 'react';
import react, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles }  from '@material-ui/core/styles';
import axios from 'axios';

import Account from '../components/account';
import FacsSearch from '../components/facsSearch';
import Facs from '../components/facs';
import Docs from '../components/docs';
import Lea from '../components/lea';
import ResponsiveAppBar from '../components/appbar';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchIcon from '@material-ui/icons/Search';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import MapsHomeIcon from '@material-ui/icons/Home';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import StorageIcon from '@mui/icons-material/Storage';
import HomeIcon from '@material-ui/icons/Home';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import Sjos from '../components/sjos';
import QeeSlideDialog from '../components/qeeSlideDialog';
import VersionInfo from '../components/versionInfo';
import { useConfluence } from "../components/qeeConfluenceProvider"; 

import { resetVersion } from "../api/service";
import { useNavigate } from "react-router-dom";

import { usrIsAdmin } from "../util/helpers";

import { authMiddleWare } from '../util/auth';
import { useAuth } from "../context/provider";


const styles = (theme) => ({
	root: {
		display: 'flex'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	toolbar: theme.mixins.toolbar
});


const Admin = ( props) => {
				
	const { setOpenConfluenceDialog } = useConfluence();											
				
	const { gstate, setOpenQeeDialog } = useAuth();								
		
	const [state, setState] = useState({
		uiLoading: true
	});	
	const [windowDimension, detectHW] = useState({
		winWidth: window.innerWidth,
		winHeight: window.innerHeight,
	})	
	const navigate = useNavigate();
	
	const useStyles = makeStyles({
		...props,	
		listItem: {
			color: '#788',
			fontWeight: 600,
			fontSize: 16
		}
	});	
	const classes = useStyles( props);		
		
	
	const detectSize = () => {
		detectHW({
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		})
	}	

	
	const logoutHandler = (event) => {
		localStorage.removeItem( 'AuthToken');
		navigate( '/login');
	};
	
	
	// pages
	const loadHome = (event) => {
		navigate( '/home');
	};				

	// subpages
	const switchSubpage = () => {
		console.log('switchSubpage render', state.render);
		switch (state.render) {
			case 'Sjos':
				return <Sjos />;
			default:
				return <Sjos />;
		}
	};
	
	const loadSjos = (event) => {
		setState({ render: 'Sjos' });
	};	

	const showConfluenceHome = (event) => {
		setOpenConfluenceDialog();
	};

	const showVersionInfo = (event) => {
		setOpenQeeDialog({ open: true, id: 'dlgVersionInfo' });
	};
	
		
    useEffect( () => {	
		setState({
			...state, 
			uiLoading: false
		})
    }, []);	
	
	
	const drawer = () => {	
	
		return(
			<>
				<div className={classes.toolbarxxx} />

				<List>
						
					<ListItem button key="Home" onClick={loadHome}>
						<ListItemIcon>
							{' '}
							<HomeIcon />{' '}
						</ListItemIcon>
						<ListItemText 
							disableTypography
							primary={<Typography variant="body2" className={classes.listItem}>Home</Typography>} />
					</ListItem>			

					<Divider />		
						
					<ListItem button key="Facs" onClick={loadSjos}>
						<ListItemIcon>
							{' '}
							<FlipCameraIosIcon />{' '}
						</ListItemIcon>
						<ListItemText 
							disableTypography
							primary={<Typography variant="body2" className={classes.listItem}>Meine Server Jobs</Typography>} />						
					</ListItem>						
					
				</List>		
			</>
		)
	
	}
	
	
	const settings = [
		{ nm: 'Administration', fct: loadSjos, ico: <AdminPanelSettingsIcon /> },
		{ nm: 'Wissensassistent', fct: showConfluenceHome, ico: <HelpOutlinedIcon /> },
		{ nm: 'Versionsinfo', fct: showVersionInfo, ico: <PrivacyTipIcon /> },
	];
	// Modify the settings array based on the admin status
	if (!usrIsAdmin()) {
		const adminIndex = settings.findIndex((item) => item.nm === 'Administration');
		if (adminIndex !== -1) {
			settings.splice(adminIndex, 1);
		}
	}	
	
	
	return (	
		state.uiLoading ? (
			<div className={classes.root}>				
				{state.uiLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (
			<>
			<div>
				<ResponsiveAppBar 
					className={classes.appBar} 
					drawer={drawer}
					switchSubpage={switchSubpage}
					pages={[
						/*
						{ nm:'Kaufen',    fct:loadBuyPage},
						{ nm:'Wohnen',    fct:loadOwnPage},
						{ nm:'Verkaufen', fct:loadSellPage}
						*/
					]}
					settings={settings}				
				/>
			</div>

			<QeeSlideDialog 
				id='dlgVersionInfo'
				title='Versionsinformationen' 
				formComponent={
					<VersionInfo />
				}	
				hideDialogAppBar={false}
				hideDialogActions={true}
				submitDisabled={true}
				paddingContent={0}
			>				
			</QeeSlideDialog>			
			</>
		)	
	);
	
}
export default withStyles(styles)(Admin);

