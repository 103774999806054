// qeeChatEmbedded.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react'; 
import React, { useImperativeHandle } from 'react';

import withStyles from '@material-ui/core/styles/withStyles'; 
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; 

import {QeeChatController} from './qeeChatController.js';
import {QeeMuiChat} from './qeeMuiChat.js';
import QeeSlideDialog from './qeeSlideDialog';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';

import { markChatRead }  from '../api/service';

import WaitProgressLinear from "./waitProgressLinear";

import { useSnackbar } from 'notistack';

import { 
	getCfgsNet, 
	getOpenaiDesc, 
	postOpenaiAnonymousMessage, 
	postChatMessage, 	
	postChatFieldValue, 	
	getAnonymousChat,
	getChatBySessId,
	getFacChat,
	getChatConfig	
} from "../api/service";
import { 
	generateRandomNumber, 
	getUserIP, 
	convertObjToDateTime	
} from '../util/helpers';

//import css from '../core/App.css?inline';

const styles = (theme) => ({
});


// main fct
const QeeChatEmbedded = React.forwardRef((props, forwardedRef) => {	

	const theme = useTheme();
	
	const {height, parentForm, parentId, isP2P, showDateTime, showDate, showTime } = props;

	const [chatCtl, setChatCtl] = React.useState(new QeeChatController({
		showDateTime,
		showDate,
		showTime,
		parentForm,  // Add this line
		parentId     // Add this line
	}));	

	const { enqueueSnackbar } = useSnackbar();	

	const isInitializingChatRef = useRef(false);
	const [isError, setIsError] = useState( null);			
	const [chatCfg, setChatCfg] = useState( null);			
	const savedChat = sessionStorage.getItem('chat');
	const [chat, setChat] = useState(savedChat !== null ? JSON.parse( savedChat) : {});
	const [hasErrorsDlgChat, setHasErrorsDlgChat] = useState( true);				
	const [changesDlgChat, setChangesDlgChat] = useState( {});	
	const isProcessingStepsRef = useRef(false);
				
	const [state, setState] = useState({
		url: '',
		submitDisabled: true,
	});
	
	const [isReady, setIsReady] = useState(false); 	
	
	const chatRef = useRef(chat);
	
	const isMounted = useRef(false);	


	// kill the body overflow
	useEffect(() => {
		// Save the original overflow value
		const originalOverflow = document.body.style.overflow;

		// Set the overflow to hidden when the component mounts
		document.body.style.overflow = 'hidden';

		// Reset the overflow to its original value when the component unmounts
		return () => {
		  document.body.style.overflow = originalOverflow;
		};
	}, []);

	
	useEffect(() => {				
		if (chat && chat.id) {
			chatRef.current = chat;
		}
	}, [chat?.id]);

	
	useEffect(() => {
        isMounted.current = true;

        // Async function to handle chat initialization
        const initializeChat = async () => {

            // Initialize the chat controller if it hasn't been initialized yet
            if (!chatCtl) {
				const newChatCtl = new QeeChatController({
													showDateTime,
													showDate,
													showTime,
													parentForm,  // Add this line
													parentId     // Add this line
				});				
                if (isMounted.current) {
                    setChatCtl(newChatCtl);
                }
            }
			
			// Attempt to fetch chat configuration
			try {
				const chatConfig = await getChatConfig( props.instanceName);
				if (isMounted.current && chatConfig) {
					setChatCfg( chatConfig);
					
					// Fetch chat data here 
					await handleChatOpen( chatConfig);
				}
			} catch (error) {
				if (isMounted.current) {
					console.error(error);
					setIsError(error.message);
				}
			}



            // Set ready state if everything is initialized correctly
            if (isMounted.current) {
                setIsReady(true);
            }
        };

        // Call the async function
        initializeChat();

        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            isMounted.current = false;
        };
    }, []); 	
	
	
	// some consts
	const useStyles = makeStyles( theme => ({
		//...props
		accordionSummary: {
			backgroundColor: '#f00'
		},
		accordionBox: {
			fontWeight:'bold', 
			m:1, 
			lineHeight:1.2, 
			color:theme.palette.primary.contrastText,
		},
		icon:{
			color:theme.palette.primary.contrastText,
		}
	}));			
	const classes = useStyles( props); 


	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		ali: ()=>{alert(123)},
		handleChatOpen: handleChatOpen,						
		handleChatClose: handleChatClose
	}));	
	
		
	const convertQAtoOptions = ( qaArray) => {
		
		console.log( '--> convertQAtoOptions', qaArray);
		
		var arrRet =  qaArray.map((item, index) => ({
			value: index.toString(), // Convert index to string to use as value
			text: item.question, // Use the question as the option text
			answerType: item.answerType, // Retain the original answerType			
			answer: item.answer, // Retain the original answer
			quickQuestions: item.quickQuestions // Retain the original quickQuestions
		}));
		
		console.log( '<-- convertQAtoOptions', arrRet);
		
		return arrRet;
	};	
	
	
	/**
	*	handleChatOpen() - starts the chat
	*/
	const handleChatOpen = async ( chatConfig) => {
	
		console.log( 'handleChatOpen', chatConfig);
		
		let oChatConfig = JSON.parse( chatConfig.json);
		console.log( 'Parsed oChatConfig', oChatConfig);
	
		var sessId = 'SESS-'+generateRandomNumber( 7);
		if( parentForm && parentId) {
			sessId = parentForm+'-'+parentId;
		}
				
		// try to get the local usr
		let anonymSess;
		let usrId;
		var usr = sessionStorage.getItem( 'usr');
		if( usr) {
			usr = JSON.parse( usr);
			usrId = usr.usrId;
			anonymSess = false;
		}
		// if not found, for anonymous sessions get its IP
		if( !usrId) {
			var usr = {usrId:await getUserIP()};
			sessionStorage.setItem( 'usr', JSON.stringify( usr))
			anonymSess = true;
		}
		
		var chat = null;
		var sChat = sessionStorage.getItem( 'chat');
		if( sChat) {
			chat = JSON.parse( sChat);
			console.log( 'openChatDialog chat.chat_fac_id !== sessId', [chat.chat_fac_id, sessId]);
			if( chat.chat_fac_id !== sessId) {	
				chat = null;
				sessionStorage.setItem( 'chat', null);
			}		
		} 

		// --- for anonymous
		if( anonymSess) {
			// first try here
			console.log( 'openChatDialog chat from sessionStorage', chat);
			chat = await getAnonymousChat( sessId, chat ? chat.id : null, chatConfig);
			if( chat) {
				sessionStorage.setItem( 'chat', JSON.stringify({
						id:chat.id, 
						chat_fac_id:chat.chat_fac_id
					})
				);			
			// second try, if the chatid in the sessionStorage is set, but it was not found on db / does not exist	
			} else {
				chat = await getAnonymousChat( sessId, null, chatConfig);
				if( chat) {
					sessionStorage.setItem( 'chat', JSON.stringify({
							id:chat.id, 
							chat_fac_id:chat.chat_fac_id
						})
					);			
				}	
			}
			
		// --- for authorized chats
		} else {
			console.log( 'openChatDialog chat from sessionStorage', chat);
			chat = await getChatBySessId( sessId, null, chatConfig);
			if( !chat.err) {
				sessionStorage.setItem( 'chat', JSON.stringify({
						id:chat.id, 
						chat_fac_id:chat.chat_fac_id
					})
				);
			} else {
				chat = await getAnonymousChat( sessId, null, chatConfig);
				if( chat) {
					sessionStorage.setItem( 'chat', JSON.stringify({
							id:chat.id, 
							chat_fac_id:chat.chat_fac_id
						})
					);			
				}	
			}
		}
		var newChat = chat;		
		console.log('handleChatOpen chat', newChat);
				
		chatRef.current = newChat;
		isInitializingChatRef.current = true;
		isProcessingStepsRef.current = false;
		
		// evtl. remove the chat which is empty
		if( !newChat) {
			sessionStorage.removeItem( 'chat');
		}
		
		// Clear all existing messages in the ChatController
		if( chatCtl) {
			chatCtl.clearMessages();
		}
		
		chatCtl.chatConfig = JSON.parse( chatConfig.json);
		console.log( 'Parsed chatConfig', chatCtl.chatConfig);		
		chatCtl.removeOnMessagesChanged(onMessagesChanged);
		chatCtl.addOnMessagesChanged(onMessagesChanged);		

		// Add messages from newChat.msgs array to the ChatController
		if( newChat) {
			var msgs = [];
			for (const msg of newChat.chat_msgs) {
				msgs.push({
					type: msg.type,
					content: msg.content,
					uploadId: msg.type==='file' ? msg.uploadId : null,
					username: msg.username,					
					self: msg.self,
					avatar: msg.self ? '' : <TipsAndUpdatesIcon />,
					usrId: msg.usrId,
					createdAt: convertObjToDateTime( msg.createdAt),
					perName: msg.self ? '' : msg.per_name
					//username: msg.self ? 'eu' : '',
				});
			}
			console.log( 'setMessages', msgs);			
			await chatCtl.setMessages( msgs);			

			// Set action request			
			if( msgs > 1) {			
				const name = await chatCtl.setActionRequest({ type:'text', always:true, self:true });							
			} else {
				var oJsonChat = chatCtl.chatConfig; 
				if( oJsonChat.chat_initial_qa) {
					console.log( 'oJsonChat.chat_initial_qa', oJsonChat.chat_initial_qa);
					await chatCtl.setActionRequest( { type:'select', always:true, self:true, options:convertQAtoOptions( oJsonChat.chat_initial_qa) })  
				} else {	 
					await chatCtl.setActionRequest({ type:'text', always:true, self:true });							
				}
			}
		}

		setChat((prevChat) => ({
			...prevChat,
			...newChat,
		}));

		if( isP2P) {
			const result = await markChatRead(usrId, newChat.id);
			if (result.err) {
				console.error('ERR marking chat as read:', result.err);
			} else {
				console.log('Chat marked as read successfully:', result);
			}
		}		
		
		isInitializingChatRef.current = false;
	};

		
	const handleChatClose = () => {
	};
	
	
	/**
	*	_getIntentAnswer() - get answer for intent
	*/
	const _getIntentAnswer = (intent, oJsonChatCfg) => {

		console.log('--> _getIntentAnswer', intent);

		const { dynamicHandlers } = oJsonChatCfg.chat_dynamic_conversation;

		// Find the first handler that matches the provided message intent
		const handler = dynamicHandlers.find(handler => handler.intent === intent);

		let oAnsw = handler;
		
		console.log('<-- _getIntentAnswer', oAnsw);	
		return oAnsw;
	}	
	
	
	/**
	*	onMessagesChanged - callback when msgs changes
	*/
	const onMessagesChanged = async (msgs) => {
		console.log('--> onMessagesChanged', msgs);

		// Skip processing messages during initialization
		if (isInitializingChatRef.current) {
			console.log('<-- onMessagesChanged do nothing in init!');
			return;
		}

		const currentChat = chatRef.current;

		if (msgs.length === 0) {
			console.log('onMessagesChanged msgs.length==0');
			console.log('<-- onMessagesChanged no msgs!');
			return;
		}

		const lastMessage = msgs[msgs.length - 1];

		// Ensure the message isn't already an OpenAI response
		if (lastMessage.self && (lastMessage.type === 'text' || lastMessage.type === 'file')) {
			if (!isP2P) {
				// try to guess the answerType for intents
				var answ = _getIntentAnswer(lastMessage.content, chatCtl.chatConfig);
				if (answ) {
					lastMessage.answer = answ.answer;
					lastMessage.answerType = answ.answerType;
					lastMessage.confluencePage = answ.confluencePage;
				}

				if (lastMessage.answerType === 'predefined-steps') {
					processSteps(msgs, lastMessage.answer.steps);
				} else if (isProcessingStepsRef.current === false) {
					// goto server, he will decide about it
					const respMsg = await postOpenaiAnonymousMessage(currentChat.id, lastMessage);
					console.log('onMessagesChanged respMsg', respMsg);
					var openAIResponse = {
						content: respMsg.content,
						quickQuestions: respMsg.quickQuestions,
						answerType: respMsg.answerType,
						type: 'text',
						createdAt: new Date(),
						self: false,
						avatar: 'AVATAR',
					};

					// Update chat messages and add the OpenAI response
					setChat((prevChat) => ({
						...prevChat,
						chat_msgs: Array.isArray(prevChat.chat_msgs) ? [...prevChat.chat_msgs, openAIResponse] : [openAIResponse],
					}));
					// Add OpenAI response to the chat controller
					chatCtl.addMessage(openAIResponse);
				}
			} else {
				console.log('lastMessage', lastMessage);
				const currentChat = chatRef.current;

				//await chatCtl.addMessage(lastMessage);
				let usrId;
				var usr = sessionStorage.getItem('usr');
				if (usr) {
					usr = JSON.parse(usr);
					usrId = usr.usrId;
				}
				await postChatMessage(currentChat.id, lastMessage, usrId);
			}
		} else {
			setChat((prevChat) => ({
				...prevChat,
				chat_msgs: msgs,
			}));
		}

		console.log('<-- onMessagesChanged');
	};

	
	function _replacePlaceholders( txt, response) {
		const pattern = /\{(\w+)\}/g; // Matches placeholders like {anything}
		return txt.replace(pattern, (match, key) => {
			// Adjusting to check for and use the `content` property within each key's object
			if (response.hasOwnProperty(key) && response[key].hasOwnProperty('content')) {
				return response[key].content;
			}
			return match; // or `""` for default empty if you want to remove unmatched placeholders
		});
	}

		
	/**
	*	processSteps() - processes a steps section in the chat
	*/
	async function processSteps(msgs, steps) {
		isProcessingStepsRef.current = true;

		const currentChat = chatRef.current;

		for (let i = 0; i < steps.length; i++) {
			const step = steps[i];
			let isValid = false;
			let userInput = '';
			let attemptFailed = false; // Variable to track if a validation attempt has been made

			while (!isValid) {
				// Display the original question only on the first attempt
				if (!attemptFailed) {
					let msg = {
						content: _replacePlaceholders( step.question, chatCtl.getResponse()),
						type: 'text',
						answerType: 'predefined',
						createdAt: new Date(),
						self: false,
						avatar: 'AVATAR',
					};
					await chatCtl.addMessage(msg);
					await postChatMessage(currentChat.id, msg);
				}

				// Wait for user input. This uses a placeholder function to simulate waiting for input.
				userInput = await waitForUserInput();

				// Validate the user input according to the step's validation pattern.
				isValid = validateResponse(userInput.content, step.validationPattern); 
				if (!isValid) {
					// Inform the user that the input is invalid
					let errorMsg = {
						content: step.errorMessage,
						type: 'text',
						answerType: 'predefined',
						createdAt: new Date(),
						self: false,
						avatar: 'AVATAR',
					};
					await chatCtl.addMessage(errorMsg);
					await postChatMessage(currentChat.id, errorMsg);

					attemptFailed = true; // Mark the attempt as failed
				} else {
					// Post the field/value pair to the server
					if (step.fieldName) {
						chatCtl.addResponseField(step.fieldName, userInput); 
						await postChatFieldValue(currentChat.id, step.fieldName, userInput);
						await postChatMessage(currentChat.id, userInput);                 
					}
				}
			}
			console.log(`Step ${i + 1} completed with input: ${userInput.content}`);
		}
		isProcessingStepsRef.current = false;
		
		onMessagesChanged( chatCtl.getMessages());   // added because of delay in the promises, it forces the recall (?)
	}
	function validateResponse( response, validationPattern) {
		
		//if( response.length >= 4 || validationPattern==='none')
		if( validationPattern==='none')			
			return true;
		
		const regex = new RegExp( validationPattern);
		return regex.test( response);
	}
	async function waitForUserInput() {
		return new Promise((resolve) => {
			const initialMessageCount = chatCtl.getMessages().length;

			const checkForNewInput = () => {
				const messages = chatCtl.getMessages();
				if (messages.length > initialMessageCount) {
					const newMessage = messages[messages.length - 1];

					// Additional checks can be added here to ensure the message
					// is indeed a user input relevant to the current step
					if (newMessage.self === true) { 
						resolve( newMessage);
					}
				} else {
					// Re-check after a delay if no new message is detected
					setTimeout( checkForNewInput, 500); // Adjust delay as needed
				}
			};

			// Start checking for new input
			checkForNewInput();		
		});
	}

						
	const handleSubmitDlgChat = () => {
		console.log( 'handleSubmitDlgChat SUBMITTING >>>>', changesDlgChat);
	}
					
	return (		
		<div style={{ marginTop: 0 }}>
            {isReady && chatCtl ? (
				<>
				{isError ?			
					<>CONFIGURATION ERROR: {isError}</>
				:
					<QeeMuiChat 
						height={height}
						chatController={chatCtl} 
					/>
				}
				</>
            ) : (
				<WaitProgressLinear />
            )}
        </div>		
	)
})	
export default withStyles(styles)(QeeChatEmbedded);