import validator from "@rjsf/validator-ajv8";
import { customizeValidator } from '@rjsf/validator-ajv8';
import localizer from "ajv-i18n";
import Form from "@rjsf/mui";
//import { Form, TitleField } from "@rjsf/mui"; 
import Handlebars from 'handlebars';
import { debounce } from 'lodash';

import * as React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import react, { useState, useEffect, useCallback, createRef, useRef } from 'react';
import { useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import StepContent from '@mui/material/StepContent';
//import { Stepper, Step, StepLabel, StepContent } from "@mui/material";   // mui-v5
//import { Stepper, Step, StepLabel, StepContent } from "@material-ui/core";   // mui-v4

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';   // mui-v5
//import Typography from '@material-ui/core/Typography';   // mui-v4
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import { InputAdornment } from '@mui/material';
import { IconButton } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import Autocomplete from '@mui/material/Autocomplete';
import { Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import anime from 'animejs/lib/anime.es.js';

// MUI5 used in qeewidgets, works
import { DatePicker, DateTimePicker, MobileDatePicker } from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
// MUI4 old
//import DateFnsUtils from '@date-io/date-fns';
//import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import moment from 'moment';

import { useObjChange } from './qeeObjChangeProvider.js';
import { QeeSuccessMessage, QeeWarningMessage, QeeErrorMessage } from './qeeMessages.js';
import QeeTooltip from './qeeTooltip.js';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import QeeRadioGroup from './qeeRadioGroup';
import QeeCheckboxGroup from './qeeCheckboxGroup';
import ButtonCounter from './buttonCounter';
import QeeSliderEditable from './qeeSliderEditable';
import QeeGmap from './qeeGmap';
import Footer from './footer';
import QeeHintBox from './qeeHintBox';
import WaitProgressLinear from "./waitProgressLinear";
import QeeInternationalPhone from './qeeInternationalPhone';
import FacCompEnergyResultDiffCard from './facCompEnergyResultDiffCard';
import DiagramBenchNewbuildCost from './diagramBenchNewbuildCost';
import { 
	getSdatas, 
	getSdatasNet, 
	getCfgsNet, 
	facSave,
	getObj
} from '../api/service';
import { 
	usrSignUp,
	usrRegisterAndOrder,	
	resolveQuizAnswers,
	approximateFacPlanRenovation,
	approximateFacValuation,
	approximateFacNewbuildCost,	
	insuranceCalcMonthlyFee,
	getCompanyPublicData,
	supplierSignUp,
	supplierCreateSacRfi
} from '../custom/helperFunctions';
import { useAuth } from "../context/provider";
import {formatNumber} from '../util/helpers';


const useStyles = makeStyles((theme) => ({
	buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(0),
        paddingTop: theme.spacing(0),
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: theme.palette.background.paper,
        position: 'fixed',
        width: '100%',
        bottom: 0,
        left: 0,
        zIndex: 9000,
    },
    formContainer: {
        padding: 20,
        paddingTop: 70,
    },
	formControlCompact: {
        margin: theme.spacing(0, 0), // Reduce vertical spacing
        '& .MuiFormLabel-root': {
            marginBottom: theme.spacing(0), // Reduce label margin
        },
        '& .MuiInputBase-root': {
            margin: theme.spacing(0, 0), // Reduce input margin
            padding: theme.spacing(0), // Reduce input padding
        },
    },	
}));
const styles = (theme) => ({});


//const QeeStepper = ( props) => {
const QeeStepper = React.forwardRef((props, forwardedRef) => {		

    const classes = useStyles();

	const containerRef = useRef(null);
	const formRef = useRef(null);
	const validator = customizeValidator({}, localizer.de);
	const errorRef = useRef(null);
	
	console.log( 'QeeStepper props.parentWindowClass', props.parentWindowClass);

	/**
	* 	Workaround to be able to use this comp from another app. 
	*	The ext app must provide initiliazed elems gstate and setObjEdit as props (aen-20230331).
	*/
	/*
	function useAuthData() {
		
		var auth = useAuth();
		
		if( props.gstate) {
			const gstate = props.gstate;
			const setObjEdit = props.setObjEdit;		
			return { gstate, setObjEdit };			
		} else {
			const { gstate, setObjEdit } = auth;			
			return { gstate, setObjEdit };
		}
	}
	const { gstate, setObjEdit } = useAuthData();   // avoid hook limitations
	*/	
	var auth = useAuth();
	const { gstate, setObjEdit } = auth;

	const [isLoading, setIsLoading] = useState( true);		
	const [isError, setIsError] = useState( null);		
	const [activeStep, setActiveStep] = React.useState(0);
	const [activeStepNr, setActiveStepNr] = React.useState(0);	
	const [dhtmlxSteps, setDhtmlxSteps] = useState( []);			
	const [visited, setVisited] = useState( []);				
	const [clickBack, setClickBack] = useState( false);					
	//const [objChange, setObjChange] = useState( {
	//	steps:[]		
	//});
	const { objChange, setObjChange } = useObjChange();	
	
	const [formData, setFormData] = useState({});
    const [touched, setTouched] = useState(false);


/* Initialize the start like this	
// Start
{"lbl":"Start",
"type":"start",
"des_data":{
	"form":"fac",
	"fac_ownership":"owner",
	"fac_name":"Meine Immobilie",
	"fac_type":"100", 
	"fac_house_type":"100", 
	"fac_apartment_type":"100", 
	"fac_construction_year":"2001", 
	"fac_living_area":120
}}
*/
		
	const [validNext, setValidNext] = useState( true);   // always set to tru, no use anymore
	const [validPrev, setValidPrev] = useState( true);

		
	/**
	*	extractUniqueValues() - helper for extracting the unique stepsNrs
	*/
	function extractUniqueValues( arr) {
		const uniqueIndices = new Set();
	  
		for (let i = 0; i < arr.length; i++) {
			const item = arr[i];
			if( item.hasOwnProperty( 'nr')) {
				uniqueIndices.add( item.nr);
			}
		}
	  
		// Convert the set to an array, sort it in ascending order, and return it
		return Array.from(uniqueIndices).sort((a, b) => a - b);
	}

	function animateStepper(activeStepNr) {
		// Query all step labels
		const stepLabels = document.querySelectorAll('#stepper .MuiStepLabel-root .MuiStepLabel-label');
		
		// Reset all previous animations if necessary
		stepLabels.forEach(label => anime.remove(label));

		// Animate only the current step label, if that's the intention
		if (stepLabels[activeStepNr - 1]) {  // activeStepNr is 1-based index
			anime({
				targets: stepLabels[activeStepNr - 1],
				translateY: ['-10px', 0],
				opacity: [0, 1],
				easing: 'easeOutQuad',
				duration: 500,
				delay: anime.stagger(100), // This may not be necessary if only one element is animated
			});
		}
	}
		
		
	useEffect(() => {				
		let w = window;
		if( props.parentWindowClass) {
			w = document.querySelector( props.parentWindowClass);	
		}
		w.scrollTo({ top: 0, left: 0, behavior: 'smooth'});
	
		setTouched(true);
		animateStepper(activeStep+1);
		//if( formRef.current)
		//	formRef.current.validateForm()		
	}, [activeStep]);	
  		

	useEffect(() => {	
		if( clickBack) {
			if( formRef.current) {
				formRef.current.validateForm()		
				setClickBack( false)		
			}

		}
	}, [clickBack]);		

	
	// handleNext button	
	const handleNext = async () => {
		console.log( '>>> objChange', objChange);
		console.log( '>>> dhtmlxSteps', dhtmlxSteps);	
		console.log( '>>> visited', visited);					
		
		var astp = objChange.steps[activeStep];	
	
		var isValid = false;
		if( astp.text.des_data.comp==='form') {
			if( astp.text.des_data.forceValidateNext)   // for show-only without edit-widgets steps
				isValid = true;
			if( formRef && formRef.current) {
				isValid = formRef.current.validateForm();				
			}
		} else if( astp.text.des_data.comp==='QeeRadioGroup') {
			if( astp.text.des_data.required && objChange[ astp.text.des_data.field] === undefined) 
				isValid = false;
			else	
				isValid = true;
		} else {   // for othe comps like 'html'
			isValid = true;
		}
		if( !isValid)
			return false;
		
		if( isValid) {		
			console.log( '>>> Submit condition activeStep, props.stepSubmit: ', activeStep, props.stepSubmitEnd );
			if( props.fctSubmitEnd && activeStep === props.stepSubmitEnd) {
				let oe = {
					...gstate.objEdit, 
					...objChange
				};				
				setObjEdit( await props.fctSubmitEnd( oe))
			}
		}
		
		
		const getNextStepFromDhtmlx = ( id) => {
			
			console.log( 'activeStep id', id);
			
			var stpDhtmlx = dhtmlxSteps.find( elem => elem.id === id);			
			console.log( 'stpDhtmlx found', stpDhtmlx);
			var ix = 0;
			while( stpDhtmlx && ix < stpDhtmlx.next.length && ix < 7) {				
				console.log( '>>> ix', ix);
				if( stpDhtmlx.type === 'decision') {
					var txt = stpDhtmlx.text.replace(/'/g, '"').replace(/\n/g, "<br/>").replace(/\t/g, "   ");
					var json = JSON.parse( txt);
					console.log( 'eval string', '(' + 'objChange.'+json.op+')')
					var ret = eval( '('+'objChange.'+json.op+')');
					console.log( 'ret eval', ret);
					if( !ret) {
						stpDhtmlx = dhtmlxSteps.find( elem => elem.id === stpDhtmlx.prev[ix]);
						ix++;
						continue;
					} 
				}
								
				if( stpDhtmlx.type === 'rectangle' && stpDhtmlx.id !== id) {	// also exclude himself						
					const stpReal = objChange.steps.find( elem => elem.id === stpDhtmlx.id);
					console.log( 'stpReal found', stpReal);
					const index = objChange.steps.findIndex( elem => elem.id === stpReal.id);					
					console.log( 'Real index', index);
					visited.push( stpReal);
					return index;
				}
				console.log( 'Go next with ix:' + ix, stpDhtmlx.next[ix])
				stpDhtmlx = dhtmlxSteps.find( elem => elem.id === stpDhtmlx.next[ix]);
				if( ix) ix--;	
			}

			return -1;
		}
		
		var index = getNextStepFromDhtmlx( astp.id);
		console.log( 'setActiveStep', index)
		setActiveStep( index);		
		setActiveStepNr((prevActiveStepNr) => prevActiveStepNr + 1);
		
		// last step, do the processing
		//if( activeStepNr === objChange.stepsNrs.length - 1) {
				
			let dd = objChange.steps[activeStep].text.des_data;
			if( dd) {	
				if( dd.action) {
					console.log( 'Executing action dd.action', dd.action)
					// create the send object (context)	
					var fm = objChange.form;
					var objSend = {};
					objSend = JSON.parse( JSON.stringify( objChange));
					objSend[fm] = JSON.parse( JSON.stringify( objChange));
					delete objSend[fm].steps;
					delete objSend[fm].stepsNrs;
					for (const key in objSend) {
						if (key.startsWith( fm+'_') || key.startsWith( 'addr_')) {
							delete objSend[key];
						}
					}				
					for (const key in objSend[fm]) {
						if (!key.startsWith( fm+'_') && !key.startsWith( 'addr_')) {
							delete objSend[fm][key];
						}
					}	
					if (objSend.steps) {
						delete objSend.steps;
					}
					if (objSend.stepsNrs) {
						delete objSend.stepsNrs;
					}
					if (objSend.end) {
						delete objSend.end;
					}					
					objSend[fm].form = fm;   // correction, the form is needed in server for decision
					for (const key in objSend) {   // copy all the org, emp, sop and prj fields into the objSend
						if( key.startsWith( 'qee_') || 						
							key.startsWith( 'org_') || 
							key.startsWith( 'emp_') || 
							key.startsWith( 'sop_') || 
							key.startsWith( 'prj_') ||
							key.startsWith( 'sac_')) {
							objSend[fm][key] = objSend[key];
						}
					}						
					console.log( '>>> objSend', objSend);
					
					// do the send action
					setIsLoading( true);
					//var ret = await eval( dd.action);
					var ret = {};
					console.log( 'Starting action...', dd.action)
					
					if( dd.action === 'usrSignUp') {
						ret = await usrSignUp( objSend.per_firstname, 
							objSend.per_lastname, 
							objSend.cch_email_o,   //objSend.username, aen-20231128 substituted through email address
							objSend.cch_email_o, 
							objSend.pass, 
							objSend[fm]);
						var och = objChange;
						console.log( 'Return from SignUp', ret);	
						if( !ret.respError) {
							och.end.status = 201;  //ret.status;
							delete och.end.error;
							setObjChange( och);
						} else {
							och.end.error = 500;
							if( ret.respError) {
								och.end.error = ret.respError.code;
								setObjChange( och);
							}
						}						
						console.log( 'objChange END', och)
										
					} else if( dd.action === 'usrRegisterAndOrder') {
						ret = await usrRegisterAndOrder( 
							objSend.per_firstname, 
							objSend.per_lastname, 
							objSend.cch_email_o,   //objSend.username, aen-20231128 substituted through email address
							objSend.cch_email_o, 
							objSend.cch_phone_o, 
							objSend[fm]);
						var och = objChange;
						console.log( 'Return from Register', ret);	
						if( !ret.error) {
							och.end.status = 201;  //ret.status;
							delete och.end.error;
							setObjChange( och);
						} else {
							och.end.error = 400;
							if( ret.error) {
								och.end.error = ret.message;
								setObjChange( och);
							}
						}						
						console.log( 'objChange END', och)	
						
					} else if( dd.action === 'approximateFacPlanRenovation') {						
						ret = await approximateFacPlanRenovation( objSend.fac);		
						var och = objChange;				
						if( ret) {
							och.fac_energy_end_savings = ret;
							setObjChange( och);
						} else {
							alert( 'Error')
							ret.respError = {};
							ret.respError.code = 501;							
						}						
						
					} else if( dd.action === 'approximateFacValuation') {						
						ret = await approximateFacValuation( objSend.fac);		
						var och = objChange;				
						if( ret) {
							try {
								och.fac_valuation = ret.fac_valuation[0];
								och.fac_valuation_currency = och.fac_valuation.currency;
								och.fac_valuation_lower = formatNumber( och.fac_valuation.valueRange.lower, och.fac_valuation_currency, 0);
								och.fac_valuation_upper = formatNumber( och.fac_valuation.valueRange.upper, och.fac_valuation_currency, 0);
								och.fac_valuation_value = formatNumber( och.fac_valuation.valueRange.value, och.fac_valuation_currency, 0);;
								setObjChange( och);
							} catch( e) {
								console.error( e);
							}
						} else {
							alert( 'Error')
							ret.respError = {};
							ret.respError.code = 501;							
						}												

					} else if( dd.action === 'approximateFacNewbuildCost') {	
						// if called from the app, set the facId, if not anonymous
						objSend[objSend.form].id = gstate.objEdit.id;
						
						ret = await approximateFacNewbuildCost( objSend[objSend.form]);		// objSend.fac
						var och = objChange;				
						if( ret) {
							try {
								function _transformAndCopyFields(source, target, currency) {
									// Spread all fields from source to target
									Object.assign(target, source);

									// List of fields to format as currency
									const fieldsToFormat = [
										'fac_newbuild_cost',
										'fac_broker_fee',
										'fac_notary_fee',
										'fac_transfer_tax',
										'fac_newbuild_total_cost'
									];

									// Fields that require text suffix and formatting
									const fieldsToFormatText = [
										'fac_plot_price', 
										'fac_development_cost' 
									];

									// Format currency values if the relevant fields exist
									if (source.fac_newbuild_cost_estimation?.valueRange) {
										// Format fields within the valueRange object
										target.fac_valuation_lower = formatNumber(source.fac_newbuild_cost_estimation.valueRange.lower, currency, 0);
										target.fac_valuation_upper = formatNumber(source.fac_newbuild_cost_estimation.valueRange.upper, currency, 0);
										target.fac_valuation_value = formatNumber(source.fac_newbuild_cost_estimation.valueRange.value, currency, 0);
									}

									// Loop through each currency field and format it
									fieldsToFormat.forEach(field => {
										if (source[field]) {
											target[field+'_text'] = formatNumber(source[field], currency, 0);
										}
									});

									// Loop through each text field and create or modify it
									fieldsToFormatText.forEach(field => {
										if (source[field]) {
											// Construct the target field name with '_text' suffix
											const textFieldName = `${field}_text`;
											// Perform the desired text manipulation/formatting
											target[textFieldName] = formatNumber(source[field], currency, 0); 
										}
									});
								}								
								_transformAndCopyFields( ret, och, 'EUR')
								
								setObjChange( och);
								//setObjEdit( och);
							} catch( e) {
								console.error( e);
							}
						} else {
							alert( 'Error')
							ret.respError = {};
							ret.respError.code = 501;							
						}																		
					
					} else if( dd.action === 'resolveQuizAnswers') {						
						ret = await resolveQuizAnswers( objSend);		
						var och = objChange;	
						if (!ret.respError) {
							try {
								// Merge 'ret' into 'och.end'
								och.end = { ...och.end, ...ret, status: 201	};
								delete och.end.error;
								setObjChange(och);
							} catch (e) {
								console.error(e);
							}
						} else {
							alert( 'Error')
							ret.respError = {};
							ret.respError.code = 501;							
						}	

					} else if( dd.action === 'insuranceCalcMonthlyFee') {						
						ret = await insuranceCalcMonthlyFee( objSend);		
						var och = objChange;	
						if (!ret.respError) {														
							try {
								och = { ...och,  ...ret, status: 201	};
								setObjChange(och);
							} catch (e) {
								console.error(e);
							}
						} else {
							alert( 'Error')
							ret.respError = {};
							ret.respError.code = 501;							
						}	
						
					} else if( dd.action === 'getCompanyPublicData') {	
						
						ret = await getCompanyPublicData( objSend);		// objSend.org
						var och = objChange;				
						if( !ret.err) {
							och = { 
								...och,  
								org_name: ret.org_name ? ret.org_name : och.org_name,
								org_commercial_register: ret.org_commercial_register,
								org_description: ret.org_description,
								org_legal_form: ret.org_legal_form,
								org_foundation_year: ret.org_foundation_year ? ret.org_foundation_year : och.org_foundation_year
							};
							setObjChange(och);
						}						
						
					} else if( dd.action === 'supplierSignUp') {	
						
						ret = await supplierSignUp( objSend);		// objSend.org
						console.log( 'Return from supplierSignUp', ret);	
						
						var och = objChange;
						if( !ret.respError) {
							och.end.status = 201;  //ret.status;
							delete och.end.error;
							setObjChange( och);
						} else {
							och.end.error = 500;
							if( ret.respError) {
								och.end.error = ret.respError.code;
								setObjChange( och);
							}
						}						
						console.log( 'objChange END', och)												
					
					} else if( dd.action === 'supplierCreateSacRfi') {						
					
						ret = await supplierCreateSacRfi( objSend, props.context);		// objSend.sac
						console.log( 'Return from supplierCreateSacRfi', ret);	
						
						var och = objChange;
						if( !ret.respError) {
							och.end.status = 201;  //ret.status;
							delete och.end.error;
							setObjChange( och);
						} else {
							och.end.error = 500;
							if( ret.respError) {
								och.end.error = ret.respError.code;
								setObjChange( och);
							}
						}						
						console.log( 'objChange END', och)																	
					
					} else {
						ret.respError = {};
						ret.respError.code = 501;
					}					

					setIsLoading( false);				
				}						
			}
		//}
		
		return true;
	};

	
	// handleBack button	
	const handleBack = () => {
		console.log( '>>> objChange', objChange);
		console.log( '>>> dhtmlxSteps', dhtmlxSteps);	
		console.log( '>>> visited', visited);			
		
		var astp = objChange.steps[activeStep-1];

		const getPrevStep = ( id) => {
			
			console.log( 'activeStep id', id);
			
			var stpVisited = visited[visited.length-1];		
			var stpVisitedPrev = visited[visited.length-2];		
			
			var vis = visited.filter( elem => elem.id !== stpVisited.id);
			console.log( '>>> vis!!!', vis);			
			setVisited( vis);
						
			const stpReal = objChange.steps.find( elem => elem.id === stpVisitedPrev.id);
			console.log( 'stpReal found', stpReal);
			const index = objChange.steps.findIndex( elem => elem.id === stpReal.id);					
			console.log( 'Real index', index);
			return index;			
		}
		
		var index = getPrevStep( astp.id);
		console.log( 'setActiveStep', index)
		setActiveStep( index);		
		setActiveStepNr((prevActiveStepNr) => prevActiveStepNr - 1);

		setClickBack( true)	
	};

	
	// handleReset button	
	const handleReset = () => {
		setActiveStep(0);
		setActiveStepNr(0);
	};
	
	const handleStep = (step) => () => {
		setActiveStep(step);
	};	
	
	
	// load the script and start
	useEffect( () => {		
		const _dhtmlxToPlainJson = async ( stps) => { 
		
			var ochInit = {}		
			var ochEnd = {}			
				
			var ret = await getCfgsNet( 'cfg_qeewidgets', 'name', props.instanceName);   // 'vreed-bhw'
			let msg = 'No cfg with that key found in the database: ' + props.instanceName + '!'
			console.log( 'RET', ret);
			if( !ret) {
				console.error( 'QEE ERR', msg);
				setIsError( msg)
				return;
			}				
			if( !ret.data.entries.results.length) {
				console.error( 'QEE ERR', msg);
				setIsError( msg)
				return;	
			}
			
			try {
			
				var sd = ret.data.entries.results[0];
				var facTypeText = sd.name;						
				var json = sd.json;
				console.log( 'json', json);			
				var o = JSON.parse( json);
				var stps = o.steps.data;
				
				setDhtmlxSteps( stps);
																								
				var stps2 = [];
				for( var i=0; i<stps.length; i++) {
					let st = stps[i];
					console.log( 'st', st);
					if( st) {
						var st2 = st;
						if( st.type==='start') {
							console.log( '>>> CONTEXT', props.context)
							// init the object
							try {
								var txt = st.text.replace(/'/g, '"').replace(/[\n\r\t]/g,"");
								console.log( 'Json txt', txt);
								var json = JSON.parse( txt);	
								console.log( 'Start Json obj', json);
								if( json.type==='start') {
									// init from outside, if seted (app use)									
									if( props.objChange && props.wizReadyFieldName) {
										let och = props.objChange;										
										if( och[props.wizReadyFieldName]===true)  // the wiz was ready
											ochInit = och;
										else {
											// do not ovewrite with empty strings
											ochInit = {
											  ...json.des_data,
											  ...props.context,   // aen-20240624, extended for org/sac
											  ...Object.fromEntries( Object.entries(och).filter(([key, value]) => value !== ''))
											};
											ochInit.fac_house_type = String( ochInit.fac_house_type)
										}
									} else {
										ochInit = {
											...json.des_data,  
											...props.context   // aen-20240624, extended for org/sac;
										}   
									}
									
									// TODO: corrections
									if (ochInit.fac_living_area !== undefined) ochInit.fac_living_area = parseFloat( ochInit.fac_living_area);
									if (ochInit.fac_plot_area !== undefined) ochInit.fac_plot_area = parseFloat( ochInit.fac_plot_area);									
									if (ochInit.fac_rooms_count !== undefined) ochInit.fac_rooms_count = parseFloat( ochInit.fac_rooms_count);																		
									if (!ochInit.fac_energy || Object.keys(ochInit.fac_energy).length === 0) {
										ochInit.fac_energy = {};
									}
									if (!ochInit.fac_energy.energyclass_plan) {
										ochInit.fac_energy.energyclass_plan = "singles";
									}
									if (!ochInit.fac_energy.energyclass_method) {
										ochInit.fac_energy.energyclass_method = "singles";
									}
									console.log( '>>> OCH', props.objChange);
									console.log( '>>> OCH DES', json.des_data);										
									console.log( '>>> OCH INIT', ochInit);
								}
							} catch( e) {
								console.error( 'Cannot add rectangle element ERR', e);		
							}
							
						} else if( st.type==='junctionxxx') {							
							
						} else if( st.type==='decisionxxx') {
							try {
								var txt = st.text.replace(/'/g, '"').replace(/\n/g, "<br/>").replace(/\t/g, "   ");
								var json = JSON.parse( txt);											
								
								st2.title = json.lbl;
								st2.question = json.text;
								st2.desc = json.desc;

								stps2.push( st2);
							} catch( e) {
								console.error( 'Cannot add decision element ERR', e);		
							}													
							
						} else if( st.type==='rectangle') {	
							try {
								var txt = st.text.replace(/'/g, '"').replace(/[\n\r\t]/g,"");
								console.log( 'Json txt', txt);
								var json = JSON.parse( txt);	
								console.log( 'Json obj', json);	
								st2.nr = json.nr;								
								if( json.type==='form' || json.type==='selection' || json.type==='display') {
									st2.text = {};
									st2.text.title = json.lbl;
									st2.text.des_data = json.des_data;
									stps2.push( st2);
								}
							} catch( e) {
								console.error( 'Cannot add rectangle element ERR', e);		
							}
						
						} else if( st.type==='end') {
							// set the end screen
							try {
								var txt = st.text.replace(/'/g, '"').replace(/[\n\r\t]/g,"");
								console.log( 'Json txt', txt);
								var json = JSON.parse( txt);	
								console.log( 'end Json obj', json);
								if( json.type==='end') {
									ochEnd = json.des_data;
									console.log( 'OCH END', ochEnd);
								}
							} catch( e) {
								console.error( 'Cannot add rectangle element ERR', e);		
							}
						}
					}
					
				}
			
			} catch( e) {
				console.error( 'Json data ERR', e);				
			}
			console.log( 'Normalized stps', stps2);
			
			// init the real steps
			var och = ochInit;   //objChange;
			och.steps = JSON.parse( JSON.stringify( stps2));
			och.steps = och.steps.sort(function(a, b) {return a.nr - b.nr;});
			och.stepsNrs = extractUniqueValues( stps2);	
			och.end = ochEnd;
			setObjChange( och);
			console.log( '>>> OCH SET', och);
			
			// init the visited
			var vis = visited;
			vis.push( och.steps[activeStep]);
			setVisited( vis);			
		}
		
		
		const fetchData = async() => {
			setIsLoading( true);		
			await _dhtmlxToPlainJson();
			// for test from code example
			//_dhtmlxToPlainJson( wkf.steps.data);		
			setIsLoading( false);
		}
		fetchData();
					
	}, []);


	/*
	useEffect(() => {			
		console.log( '>>> OBJ CHANGE', objChange);		
		if( objChange.steps[activeStep])
			validateNextButton( activeStep, objChange.steps[activeStep]);		
	}, [activeStep, JSON.stringify( objChange.steps)]);	
	*/
	
	
	const getStepSchema = ( txt) =>{		
		var oSch = {type: "object"};
		try {		
			if( txt.des_data)
				oSch = txt.des_data.schema ? txt.des_data.schema : oSch;			
			
		} catch( e) {
			console.error( 'getStepSchema ERR', e);	
		}
		console.log( 'step text schema', oSch);
		return oSch;
	}
	

	const getStepUiSchema = ( txt) =>{	
	
		var oUiSch = {};		
		try {
			if( txt.des_data) {
				oUiSch = txt.des_data.ui_schema ? txt.des_data.ui_schema : oUiSch;
				//oUiSch['fac_house_type']['ui:options'].parentWindowClass = props.parentWindowClass;
				// complete with parentWindowClass for scroll control from qeeRadioGroup
				for (let fieldName in oUiSch) {
					//console.log( '>>> FIELDNAME', fieldName);
					if (oUiSch.hasOwnProperty(fieldName)) {
						const field = oUiSch[fieldName];
						//console.log( '>>> FIELD', field);
						if (field['ui:widget']==='qeeRadioGroup') {
							if (field['ui:options']) {
								field['ui:options'].parentWindowClass = props.parentWindowClass;
							} 
						}
					}
				}
				
			}				
		} catch( e) {
			console.error( 'getStepUiSchema ERR', e);	
		}		
		console.log( 'step text ui_schema', oUiSch);
		return oUiSch;				
	}
	
	
	const transformErrors = (errors) => {
		//alert(''+touched )
		if (!touched) return [];  // If not touched, suppress all errors

		return errors.map(error => {
			const fieldName = error.property.replace(/^\.+/, "");
			// Assuming getFieldTitle is a function you've defined to get the title from the schema
			const fieldTitle = getFieldTitle(fieldName, getStepSchema(objChange.steps[activeStep].text));

			if (error.name === "required") {
				error.message = `Bitte gib einen Wert für das Feld "${fieldTitle}" ein.`;
			}
			return error;
		});
	};
    function getFieldTitle(path, schema) {
        const parts = path.split('.');
        let currentSchema = schema;
        for (const part of parts) {
            if (currentSchema.properties && currentSchema.properties[part]) {
                currentSchema = currentSchema.properties[part];
            } else {
                return part; // Fallback to the field name if title not found
            }
        }
        return currentSchema.title || path; // Use the title if available, otherwise fallback to path
    }


	const validate = ( stp) => {				
		var ret = true;		
		alert( 'validate')
		return ret;
	};
	
						
	function customValidate( formData, errors, schema) {

		console.log( '--> customValidate', formData);
	
		//if (!formData.fac_living_area) {
		//	errors.fac_living_area.addError( "Die Eingabe muss positiv sein!");
		//}
		
		// Check if 'accept_terms' is true, only in the last step
		if( formData.accept_terms !== undefined) {		
		
			const isLastStep = activeStep === objChange.steps.length-1;
			if( formData.accept_terms === false && isLastStep) {
				errors.accept_terms.addError( 'Die Nutzungsbedingungen müssen akzeptiert werden!');
			}		
		}
		
		console.log( '<-- customValidate', errors);		
		
		return errors;
	}	

	
	function CustomFieldTemplate(props) {
		const {id, classNames, label, help, required, description, errors, children} = props;
		return (
			<div className={classNames}>
				{description}
				{children}
				{errors}
				{help}
			</div>
		);
	}	

	
	function ErrorListTemplate({ errors }) {
				
		useEffect(() => {

			if (errors.length > 0) {
									
				// Find all elements with the class 'Mui-error'
				const errorElements = document.querySelectorAll('.Mui-error.MuiFormHelperText-root');
				errorElements.forEach(element => {	
					element.style.backgroundColor = 'red';
					element.style.color = 'white';
					element.style.padding = '10px';
					element.style.borderRadius = '5px';
					element.style.margin = '5px 0';
				});				
				
				// Find the first element with the class 'Mui-error'				
				const firstErrorElement = document.querySelector('.Mui-error');
				if (firstErrorElement) {					
					// Navigate up the DOM tree to find the closest parent with the class 'MuiFormControl-root'
					let parentFormControl = firstErrorElement.closest('.MuiFormControl-root');
					if (parentFormControl) {
						// Calculate the position to scroll to, subtracting 120 pixels to give some space above the element
						const formControlPosition = parentFormControl.getBoundingClientRect().top + window.pageYOffset;
						let w = window;
						if( props.parentWindowClass) {
							w = document.querySelector( props.parentWindowClass);										
						}
						w.scrollTo({	
							top: formControlPosition - 120, // Adjust the offset as needed
							left: 0,
							behavior: 'smooth'
						});
						/*
							if( parentWindow) {
								parentWindow.scrollTo({	
									top: formControlPosition - 120, // Adjust the offset as needed
									left: 0,
									behavior: 'smooth'
								});
							}
						*/
					}
				}
			}
		}, [errors]);
		
		return null;  // Since this component doesn't need to render anything
	}		
	/*
	function ErrorListTemplate({ errors }) {
		const errorRef = useRef(null);

		useEffect(() => {
			if (errors.length > 0 && errorRef.current) {
				errorRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}, [errors]);
		
		return (
			<div ref={errorRef} style={{ color: '#d00' }}>
				<p>Deine Eingabe ist unvollständig oder fehlerhaft. Bitte korrigiere die folgenden Felder:</p>
				<ul>
					{errors.map((error, index) => (
						<li key={`${error.property}-${error.message}-${index}`}>{error.message}</li>
					))}
				</ul>
			</div>
		);
	}
	*/	


	const QeeResponsiveFieldset = (props) => {
		console.log('>>> QeeResponsiveFieldset PROPS', props);					
		const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

		const updateMedia = () => {
			setIsMobile(window.innerWidth < 768);
		};

		useEffect(() => {
			window.addEventListener('resize', updateMedia);
			return () => window.removeEventListener('resize', updateMedia);
		});

		const style = {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-between',
			flexDirection: isMobile ? 'column' : 'row'
		};

		return (
			<div style={style}>
				{props.children} 
			</div>
		);
	};
	
	
	const ConditionalFieldTemplate = (props) => {

		const { id, children } = props;

		// Condition to determine if the field should use the QeeResponsiveFieldset
		const useResponsiveFieldset = id.includes("percent"); // Example: Apply only to fields with "percent" in their ID

		if (useResponsiveFieldset) {
			return <QeeResponsiveFieldset {...props}>{children}</QeeResponsiveFieldset>;
		}

		// Default layout for other fields
		return <div>{children}</div>;
	};
	

	
	const validateNextButton = ( st, oStp) => {	
	
		console.log( 'validateNextButton', [st, oStp]);
		
		// always set to true, no use anymore
		return true;
		//----------
		
		var ret = false;

		if( oStp.text.des_data.comp==='form') {
			if( oStp.text.des_data.forceValidateNext)   // for show-only without edit-widgets steps
				ret = true;
			//if( formRef && formRef.current) {
			//	ret = formRef.current.validateForm();								
			//}
		} else if( oStp.text.des_data.comp==='QeeRadioGroup') {
			console.log( '>>> oStp.text.des_data.field', oStp.text.des_data.field);
			console.log( '>>> objChange[oStp.text.des_data.required]', objChange[ oStp.text.des_data.required]);
			console.log( '>>> objChange[oStp.text.des_data.field]', objChange[ oStp.text.des_data.field]);
			if( oStp.text.des_data.required && objChange[ oStp.text.des_data.field] === undefined) 
				ret = false;
			else	
				ret = true;
		} else {   // for othe comps like 'html'
			ret = true;
		}

		setValidNext( ret);	
	}	
	
	
	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		validate: validate
	}));		
	
	
	/**
	*	Custom Rjsf Widgets
	*/
	const RjsfDateWidget = props => {
		const { id, label, value, required, onChange } = props;

		const handleChange = event => {
			onChange( event.target.value);
		};

		return (
			<TextField
				id={id}
				label={label}
				type="date"
				value={value || ''}
				required={required}
				onChange={handleChange}
				InputLabelProps={{
					shrink: true,
				}}
			/>
		);
	};	

	
	const RjsfButtonCounter = props => {
		const { id, label, value, required, onChange } = props;
		console.log( '>>> RjsfButtonCounter PROPS', props);
		
		let field = id.replace( 'root_', '');

		const handleChange = event => {
			onChange( event);
			/*
			var och = {
				...objChange,
				[field]: event
			};
			setObjChange(och);	
			*/			
		};

		return (
			<ButtonCounter
				id={id}
				label={label}
				type="number"
				value={value || 0}
				required={required}
				onChange={handleChange}
				InputLabelProps={{
					shrink: true,
				}}
				gstate={gstate} 				
				setObjEdit={setObjChange} 								
				objData={objChange} 
				setObjData={setObjChange} 
				field={field} 
				defaultValue={1} min={1} max={80} step={1}  
			/>
		);
	};


	const RjsfYearPicker = props => {
		const { id, label, value, required, onChange, options } = props;
		console.log( '>>> RjsfYearPicker PROPS', props);
		
		const { minYear = '1900', maxYear } = options;
		
		let field = id.replace( 'root_', '');

		const handleChange = date => {			
			const year = moment(date).format("YYYY"); // add this
			var och = {
				...objChange,
				[field]: year
			};		
			setObjChange( och);						
		};

		return (
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<MobileDatePicker
					views={['year']}
					label={label || "Baujahr"}
					required={required}
					fullWidth
					minDate={new Date(`${minYear}`)} // Use minYear dynamically
					disableFuture
					value={new Date(objChange[field])} // Use the dynamic field to fetch the value
					onChange={handleChange}
					renderInput={(params) => (
					  <TextField
						{...params}
						InputProps={{
						  endAdornment: (
							<InputAdornment position="end">
							  <IconButton edge="end" onClick={params.inputProps.onClick}>
								<EventIcon />
							  </IconButton>
							</InputAdornment>
						  ),
						}}
					  />
					)}
				/>
			</LocalizationProvider>								
		)
	}	

	
	const RjsfDatePicker = (props) => {	
		const { id, label, value, required, onChange, options } = props;
		console.log( '>>> RjsfDatePicker PROPS', props);				

		let field = id.replace( 'root_', '');

		// Handling disableFuture and disablePast
		const today = moment().format('YYYY-MM-DD');
		const disableFuture = options.disableFuture || false;
		const disablePast = options.disablePast || false;
		
		// Calculate minDate and maxDate based on disableFuture and disablePast
		const minDate = disablePast ? today : options.minDate || '1900-01-01';
		const maxDate = disableFuture ? today : options.maxDate || '9999-12-31';
		console.log( field + ' minDate', minDate);
		console.log( field + ' maxDate', maxDate);		

		// Convert the incoming value or set to current date if not provided, in the desired display format
		const [dateValue, setDateValue] = useState(value ? moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY') : moment().format('DD.MM.YYYY'));
		// init your global stepper data!
		var och = {   
			...objChange,
			[field]: objChange[field] ? objChange[field] : moment().format('YYYY-MM-DD')
		};		
		setObjChange( och);		

		useEffect(() => {
			// Reflect external changes to 'value'
			if (value) {
				setDateValue(moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY'));
			}
		}, [value]);

		const handleChange = (newValue) => {
			const formattedDateForDisplay = moment(newValue).format('DD.MM.YYYY');
			const formattedDateForModel = moment(newValue).format('YYYY-MM-DD');

			setDateValue(formattedDateForDisplay);

			var och = {
				...objChange,
				[field]: formattedDateForModel
			};
			setObjChange(och);

			//if (onChange) {
			//	onChange({ [field]: formattedDateForModel });
			//}
		};

		return (
			<LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
				<MobileDatePicker
					views={['year', 'month', 'day']}
					label={label || "Datum"}
					required={required}
					fullWidth
					minDate={new Date(minDate)}
					maxDate={new Date(maxDate)}
					value={dateValue ? moment(dateValue, 'DD.MM.YYYY').toDate() : null}
					onChange={handleChange}
					inputFormat='dd.MM.yyyy'
					renderInput={(params) => (
						<TextField
							{...params}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton edge="end" onClick={params.inputProps.onClick}>
											<EventIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
			</LocalizationProvider>
		);
	};
	
	
	const RjsfParagraph = ({ id, value }) => (
		<p id={id}>para: {value}</p>
	);		
		
		
	const RjsfH5Title = props => {
		const { id, label, value, required, onChange } = props;
		return(
			<>
				<h5 style="color:'red'">h5: {value}</h5>
			</>	
		)
	};				
		
	
	const RjsfHtml = props => {	
		console.log( '>>> RjsfHtml PROPS', props);	
		const { id, label, required, schema } = props;
		const value = schema.value;

		let transformedValue = value;

		const linkRegex = /\[link:(.*?)\|(.*?)\]/g;
		transformedValue = transformedValue.replace( linkRegex, (match, url, displayText) => {
			return `<a href='${url}' target='_blank'>${displayText}</a>`
		});

		return (
			<Typography variant='body1'>
				<span dangerouslySetInnerHTML={{ __html: transformedValue }} />				
			</Typography>	
		)
		/*
		return (
			<div>
				<div dangerouslySetInnerHTML={{ __html: value || '' }} />
			</div>
		);
		*/
	};		


	const RjsfHtmlHtmlCheckbox = (props) => {
		console.log('>>> RjsfHtmlHtmlCheckbox PROPS', props);
		const { id, schema } = props;
		const value = schema.title;

		let transformedValue = value;

		const linkRegex = /\[link:(.*?)\|(.*?)\]/g;
		transformedValue = transformedValue.replace(linkRegex, (match, url, displayText) => {
			return `<a href='${url}' target='_blank'>${displayText}</a>`;
		});

		return (
			<div className="custom-checkbox-container"> {/* Add a custom class for styling */}
				<input type="checkbox" id={id} checked={props.formData} onChange={(e) => props.onChange(e.target.checked)} />
				<Typography variant='body1'><label htmlFor={id} dangerouslySetInnerHTML={{ __html: transformedValue }} /></Typography>
			</div>
		);
	};

		
	const RjsfColorButtonPrimary = props => {
		const { id, label, value, required, onChange } = props;
		return(
			<QeeColorButtonPrimary 
				disableElevation 
				onClick={()=>alert(111)}>
				<>aaa</>
			</QeeColorButtonPrimary>
		)
	};				


	const RjsfQeeGmap = props => {
		console.log('>>> RjsfQeeGmap PROPS', props);
		const { 
			id, 
			label, 
			value, 
			required, 
			onChange, 
			options = {}  // Extract options from props, assuming RJSF standard `ui:options`
		} = props;
		
		const {
			establishmentOnly,
			parentForm
		} = options;
		
		const [loading, setLoading] = useState(true); // State to track loading status

		// Initialize from the parent doc, if any
		useEffect(() => {
			const initFromParent = async () => {
				if (parentForm) {
					const parentObj = await getObj(parentForm, objChange[parentForm + '_id']);
					if (parentObj && !parentObj.err) {
						objChange[parentForm] = parentObj;
						setObjChange({ ...objChange });
					}
				}
				setLoading(false); // Set loading to false after the data is fetched
			};

			if (parentForm && objChange[parentForm + '_id']) {
				initFromParent();
			} else {
				setLoading(false); // No parent form, so no need to fetch, set loading to false
			}
		}, [parentForm, objChange]); 
		
		const handleAddressChange = (oAddr) => {
			console.log('handleAddressChange SUBMITTING >>>>', oAddr);
			var och;
			if (parentForm) {
				och = { ...objChange };
				och[parentForm] = { ...objChange[parentForm], ...oAddr };
			} else {
				och = { ...objChange, ...oAddr };
			}
			console.log('>>>>>>>> OCH', och);
			setObjChange(och);            
			setValidNext(true);
		};     

		if (loading) {
			return <div>Lade Daten...</div>; // Show a loading indicator while waiting for data
		}
		
		const lat = parentForm ? objChange[parentForm]?.addr_geo_lat_o : objChange.addr_geo_lat_o;
		const lon = parentForm ? objChange[parentForm]?.addr_geo_lon_o : objChange.addr_geo_lon_o;
		const gvalue = parentForm ? objChange[parentForm]?.addr_gvalue_o : objChange.addr_gvalue_o;
		const gstate2 = {   // prepare fake obj
			objEdit: parentForm ? objChange[parentForm] : objChange,
			setObjEdit: setObjChange
		};
		
		return (
			<>
				<QeeGmap
					gstate={gstate2}                 
					setObjEdit={setObjChange}            
					showSearchBar={true}
					showResultBar={false}
					establishmentOnly={establishmentOnly}
					gvalue={gvalue}
					lat={lat}
					lon={lon}
					fctOnAddressChange={handleAddressChange}
					mapHeight={400}
					marginTop={5}
				/>
			</>
		);
	};

	
	const RjsfQeeRadioGroup = props => {
		console.log( '>>> RjsfQeeRadioGroup PROPS', props);		
	
		const {
			id, 
			label = '', 
			value, 
			required, 
			onChange,
			options = {}  // Extract options from props, assuming RJSF standard `ui:options`
		} = props;

		// Destructure custom properties from options
		const {
			sdataName,
			sdata,
			hasDesc,
			hasAutoIcon,
			iconSetName,
			forceXSBehavior,
			width,
			height,
			data
			//parentWindowClass   // from the QeeStepper props
		} = options;
		
		console.log( '>>> props.uiSchema', props.uiSchema)
		const parentWindowClass = props.uiSchema['ui:options'].parentWindowClass;
		
		let field = id.replace( 'root_', '');
		
		const scrollToNext = () => {
			const formControls = document.querySelectorAll('.MuiFormControl-root');
			const activeElement = document.activeElement;
			let currentFormControlIndex = -1;

			// Identify the index of the currently focused form control
			formControls.forEach((control, index) => {
				if (control.contains(activeElement)) {
					currentFormControlIndex = index;
				}
			});

			// Calculate next index; loop back to the start if necessary
			let nextIndex = currentFormControlIndex + 1;
			
			// If nextIndex is out of bounds, stay at the current index (prevent wrapping around)
			if (nextIndex >= formControls.length) {
				nextIndex = currentFormControlIndex;  // Do not increment index beyond the last element
			}

			// Scroll to the next form control if it exists and is further down
			if (formControls[nextIndex] && nextIndex > currentFormControlIndex) {
				const topPosition = formControls[nextIndex].getBoundingClientRect().top + window.pageYOffset - 20; // 20px space above for some breathing room
				// Only scroll down if the next control is lower on the page
				if (topPosition > window.pageYOffset) {
					//window.scrollTo({
					let w = window;
					if( parentWindowClass) {
						w = document.querySelector( parentWindowClass);	
					}						
					w.scrollTo({
						top: topPosition,
						behavior: 'smooth'
					});
				}
			}
		};

		// Define onChange handler to integrate with RJSF
		/*
		const handleChange = event => {
			console.log('handleChange', { [id]: event.target.value });			
			onChange(event.target.value);
		};
		*/
		const handleChange = (_, newValue) => {

			const valueToSend = newValue ? newValue : '';	
			
			/*
			let updatedObjChange = {
				...objChange,
				[field]: valueToSend
			};
			console.log( 'updatedObjChange', updatedObjChange);
			setObjChange(updatedObjChange);
			*/
			onChange(valueToSend);	

			scrollToNext()			
		};		

		return (
			<QeeRadioGroup
				formLabel={label}
				defaultValue={value}
				fctOnChange={handleChange}
				sdataName={sdataName}
				sdata={sdata}
				hasDesc={hasDesc}
				hasAutoIcon={hasAutoIcon}
				iconSetName={iconSetName}				
				forceXSBehavior={forceXSBehavior}
				width={width}
				height={height}
				data={data}
			/>
		);
	};
	
	
	const RjsfQeeCheckboxGroup = props => {
		console.log( '>>> RjsfQeeCheckboxGroup PROPS', props);	
		
		const {
			id, 
			label = '', 
			value, 
			required, 
			onChange,
			options = {}  // Extract options from props, assuming RJSF standard `ui:options`
		} = props;

		// Destructure custom properties from options
		const {
			sdataName,
			sdata,
			hasDesc,
			hasAutoIcon,
			forceXSBehavior,
			iconSetName,			
			width,
			height,
			data
		} = options;

		let field = id.replace( 'root_', '');		
		
		// Define onChange handler to integrate with RJSF
		/*
		const handleChange = event => {
			console.log( 'handleChange', event);
			console.log('onChange', { [id]: event});
			onChange(event);
		};
		*/
		const handleChange = (_, newValue) => {

			const valueToSend = newValue ? newValue : '';	
			/*
			let updatedObjChange = {
				...objChange,
				[field]: valueToSend
			};
			console.log( 'updatedObjChange', updatedObjChange);
			setObjChange(updatedObjChange);
			*/
			onChange(valueToSend);			
		};				

		return (
			<QeeCheckboxGroup
				gstate={gstate} 				
				setObjEdit={setObjChange} 
				formLabel={label}
				defaultValue={value}
				fctOnChange={handleChange}
				sdataName={sdataName}
				sdata={sdata}
				hasDesc={hasDesc}
				hasAutoIcon={hasAutoIcon}
				iconSetName={iconSetName}								
				forceXSBehavior={forceXSBehavior}
				width={width}
				height={height}
				data={data}
			/>
		);
	};	

	
	/**
	*	RjsfHintBox
	*/
	const RjsfHintBox = props => {
		console.log( '>>> RjsfHintBox PROPS', props);			
		const { id, label, value, required, schema } = props;
		
		const {
			options = {}  // Extract options from props, assuming RJSF standard `ui:options`
		} = props;

		// Destructure custom properties from options
		const {
			showShortText,
			plainText
		} = options;	
				
		const getHintText = (schema, objChange) => {
			let hintText = schema.hintText;
			/*
			const regex = /\{([^}]+)\}/g;
			const matches = hintText.match(regex);
			if (matches) {
				matches.forEach(match => {
					const fieldName = match.slice(1, -1);
					const fieldValue = objChange[fieldName];
					if (fieldValue) {
						hintText = hintText.replace(match, fieldValue);
					}
			  });		
			}
			*/	
			if( showShortText) {
				const regex = /\{([^}]+)\}/g;
				const matches = hintText.match(regex);
				if (matches) {
					matches.forEach(match => {
						const fieldName = match.slice(1, -1);
						const fieldValue = objChange[fieldName];
						if (fieldValue) {
							hintText = hintText.replace(match, fieldValue);
						}
				  });
				}
				
			} else {
				const regex = /\{([^}]+)\}/g;
				const matches = hintText.match(regex);
				if (matches) {
					matches.forEach(match => {
						const fieldName = match.slice(1, -1);
						const fieldValue = objChange[fieldName];
						if (fieldValue) {
							hintText = hintText.replace(match, fieldValue);
						}
				  });
				}			
				
				hintText = (
					<React.Fragment>
						{schema.hintLabel && <strong>{schema.hintLabel}:</strong>}
						<span dangerouslySetInnerHTML={{ __html: hintText }}></span>
					</React.Fragment>
				);
			}
			return hintText;
		};
		
		//text={<React.Fragment><strong>{schema.hintLabel}:</strong> <span dangerouslySetInnerHTML={{__html: getHintText( schema, objChange)}}></span></React.Fragment>} 
		return(
			<QeeHintBox 
				isXS={gstate.isXS}				
				type="info" 
				showShortText={showShortText}
				plainText={getHintText( schema, objChange)}
			/>		
		)
	};	


	/**
	*	RjsfAutocomplete
	*/
	/*,
                    "sdata": [
                        {"value": 100, "label": "Abbruchunternehmer"},
                        {"value": 400, "label": "Architektur"},
                        {"value": 2910, "label": "Gipser und Stukkateure"},
                        {"value": 3610, "label": "Tiefbau-Kabelverleger"}
                    ]
	*/				
	const RjsfAutocomplete = (props) => {
		const {
			formData,
			label,
			schema,
			id,
			onChange,
			uiSchema,
			required,
			options = {} // Extract options from props, assuming RJSF standard `ui:options`
		} = props;
		
		console.log('>>>> RjsfAutocomplete PROPS', props);

		const { hintLabel, hintText, disabled } = uiSchema["ui:options"] || {};
		const [isLoading, setIsLoading] = useState(true);
		const [autocompleteOptions, setAutocompleteOptions] = useState([]);

		let field = id.replace('root_', '');

		// Check for multiple selection
		const multiple = uiSchema["ui:options"] && uiSchema["ui:options"].multiple;
		const value = props.value !== undefined ? props.value : (multiple ? [] : '');

		// Create a list of options with labels for the Autocomplete component
		useEffect(() => {
			const setOptions = (options) => {
				const formattedOptions = options.map(option => ({
					value: option.value,
					label: option.label,
				}));
				setAutocompleteOptions(formattedOptions);
			};

			if (schema.sdata) {
				// If "sdata" is provided, use it directly
				console.log('Using provided "sdata" for autocomplete options');
				setOptions(schema.sdata);
				setIsLoading(false);
			} else {
				// If "sdata" is not provided, proceed with fetching the data
				const fetchData = async () => {
					const sdataName = schema.sdataName; // "sdata_perprj_roles_types"
					const response = await getSdatasNet(sdataName, '', '', '');

					if (response && response.data && response.data.entries.results.length > 0) {
						console.log('Fetching data for autocomplete options');
						const fetchedOptions = response.data.entries.results.map(entry => ({
							value: entry.type, // Assuming 'type' is the value to be sent on change
							label: entry.name, // 'name' is what you display
						}));
						setOptions(fetchedOptions);
					} else {
						console.error('Error fetching data for autocomplete:', response.err || 'Unknown error');
					}
					setIsLoading(false);
				};

				fetchData();
			}

		}, [schema.sdata, schema.sdataName]); // Depend on schema.sdata and schema.sdataName to refetch if they change

		const handleChange = (_, newValues) => {
			if (multiple) {
				const valuesToSend = newValues ? newValues.map(selection => selection.value) : [];
				console.log('valuesToSend', valuesToSend);
				onChange(valuesToSend);
			} else {
				const valueToSend = newValues ? newValues.value : '';
				console.log('valueToSend', valueToSend);
				onChange(valueToSend);
			}
		};

		const getSelectedOptions = (autocompleteOptions, value) => {
			console.log('Autocomplete Options:', autocompleteOptions);
			console.log('Value:', value);
			if (multiple) {
				const valueArray = Array.isArray(value) ? value : [value];
				return autocompleteOptions.filter(option => valueArray.includes(option.value));
			} else {
				return autocompleteOptions.find(option => option.value === value) || null;
			}
		};
		const selectedOptions = getSelectedOptions(autocompleteOptions, value);

		return (
			<div>
				<Autocomplete
					multiple={multiple}
					id={id}
					options={autocompleteOptions}
					getOptionLabel={(option) => option.label}
					isOptionEqualToValue={(option, value) => option.value === value.value}
					onChange={handleChange}
					value={selectedOptions}
					disabled={disabled}
					renderInput={(params) => (
						<TextField
							{...params}
							label={label || 'Wähle deine Tätigkeit'}
							required={required}
							disabled={disabled}
						/>
					)}
				/>
				{hintText && (
					<p style={{ marginTop: '10px' }}>
						{hintLabel ? `${hintLabel}: ` : ''}{hintText}
					</p>
				)}
			</div>
		);
	};


	/**
	*	RjsfInternationalPhone
	*/
	const RjsfInternationalPhone = ({ id, required, onChange, value, options, rawErrors }) => {

		const [inputValue, setInputValue] = useState(value);
		
		const defaultCountry = options.defaultCountry || 'de'; // Fallback to 'de'
		
		let field = id.replace('root_', '');
				
		// Debounced function for updating the global state and form data
		const debouncedUpdate = useCallback(
			debounce((phoneValue) => {
				console.log('Debounced phone value:', phoneValue);

				// Update RJSF form data
				onChange(phoneValue);
			}, 2000),
			[onChange]
		);

		useEffect(() => {
			setInputValue(value);
		}, [value]);

		const handleChange = (phoneValue) => {
			console.log('phoneValue', phoneValue);
			setInputValue(phoneValue); // Immediate feedback in UI
			debouncedUpdate(phoneValue); // Delayed global state update
		};		
		
		return (
			<QeeInternationalPhone
				id={id}
				required={required}
				onChange={handleChange}
				value={inputValue}
				defaultCountry={defaultCountry}
				error={rawErrors && rawErrors.length > 0}
				{...options} // Pass along other options
			/>
		);
	};	

	
	/**
	*	RjsfSimplePhone
	*/
	const RjsfSimplePhone = ({ id, required, label, placeholder, onChange }) => {
		
		const [inputValue, setInputValue] = useState('');
		
		let field = id.replace('root_', '');

		// Initialize the local state with the current value
		useEffect(() => {
			setInputValue(objChange[field] || '');
		}, [objChange, field]);

		// Debounced function for updating the parent component's state and form data
		const debouncedUpdate = useCallback(debounce((cleanedValue) => {
			console.log('Debounced phone value:', cleanedValue);

			/*
			// Prepare and update the global state with the debounced value
			const updatedObjChange = {
				...objChange,
				[field]: cleanedValue, // Update the field with the cleaned value
			};
			setObjChange(updatedObjChange);
			*/
			onChange(cleanedValue);
		}, 2000), [objChange, field, setObjChange, onChange]);

		const handleChange = (event) => {
			const inputValue = event.target.value;
			const cleanedValue = inputValue.replace(/[^\d+()-\s]/g, '');
			console.log('Input phone value:', cleanedValue);

			// Set local state for immediate feedback
			setInputValue(cleanedValue);

			// Call the debounced function to update the global state and RJSF form data
			debouncedUpdate(cleanedValue);
		};

		return (
			<TextField
				id={id}
				variant="outlined"
				label={label || "Telefon"}
				color="primary"
				placeholder={placeholder || "Telefonnummer"}
				value={inputValue}
				onChange={handleChange}
				type="tel"
				required={required}
				fullWidth
				margin="normal"
			/>
		);
	};

	
	const RjsfTableComparison = ({ schema }) => {
		const comparisonData = schema.default || [];

		const getCellStyleByTextContent = (text) => {
			if (text) {
				if (text.includes("Inklusive")) {
					return { borderLeft: '4px solid green' };
				} else if (text.includes("Optional")) {
					return { backgroundColor: 'yellow' };
				}
			}
			return {}; // Default style
		};

		// Dynamically calculate column widths
		const getColumnWidths = (numColumns) => {
			if (numColumns > 1) {
				const widthForOthers = (100 - 50) / (numColumns - 1);
				return {
					firstColWidth: '50%',
					otherColsWidth: `${widthForOthers}%`
				};
			}
			return {
				firstColWidth: '100%',
				otherColsWidth: '0%'
			};
		};

		return (
			<div>
			  {comparisonData.map((category, index) => (
				<Accordion key={index} defaultExpanded={false}>
				  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6" component="h2">{category.kategorie}</Typography>
				  </AccordionSummary>
				  <AccordionDetails>
					<Table size="small">
					  <TableHead>
						<TableRow>
						  {/* Calculate column widths based on the number of leistungen properties */}
						  {Object.keys(category.leistungen[0]).map((key, idx, arr) => (
							<TableCell 
							  key={idx}
							  style={{ 
								width: idx === 0 ? getColumnWidths(arr.length).firstColWidth : getColumnWidths(arr.length).otherColsWidth,
								fontWeight: 'bold'
							  }}
							>
							  {idx === 0 ? 'Leistung' : key.charAt(0).toUpperCase() + key.slice(1)}
							</TableCell>
						  ))}
						</TableRow>
					  </TableHead>
					  <TableBody>
						{category.leistungen.map((item, itemIndex) => (
						  <TableRow key={itemIndex}>
							{Object.entries(item).map(([key, value], idx) => (
							  <TableCell key={idx} style={getCellStyleByTextContent(value)}>
								{value}
							  </TableCell>
							))}
						  </TableRow>
						))}
					  </TableBody>
					</Table>
				  </AccordionDetails>
				</Accordion>
			  ))}
			</div>
		);
	};

	
	const RjsfFacCompEnergyResultDiffCard = props => {
		console.log( '>>> RjsfFacCompEnergyResultDiffCard PROPS', props);			
		const { id, label, value, required, schema } = props;
		if( !objChange.fac_energy)
			objChange.fac_energy = {};
		return(
			<FacCompEnergyResultDiffCard
				gstate={gstate} 							
				objEdit={objChange}
				title={schema.label} 
				fieldIst={schema.fieldIst} 
				fieldPlan={schema.fieldPlan} 
				bShowEnergyclass={schema.bShowEnergyclass} 
			/>
		)
	};	


	const RjsfFacNewbuildBenchEstimation = props => {
		const { id, label, value, required, schema, options } = props;
		console.log( '>>> RjsfFacNewbuildBenchEstimation props', props);
		
		return (
			<DiagramBenchNewbuildCost
				//gstate={gstate}
				//setObjEdit={setObjEdit}
				objEdit={objChange}
				{...options} 
				{...props} 
			/>
		);
	};
	
	
	const RjsfCostCalculationTable = ({ formData, schema }) => {
		const calculationData = schema.default || [];
		console.log( '>>> RjsfCostCalculationTable calculationData', calculationData);

		// Splitting data into regular items and total items
		const regularItems = calculationData.filter(item => !item.isTotal);
		const totalItems = calculationData.filter(item => item.isTotal);

		return (
			<Table size="small" style={{ borderCollapse: 'collapse', fontSize: '1.1rem' }}>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography variant="subtitle1" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
								Position
							</Typography>
						</TableCell>
						<TableCell align="right">
							<Typography variant="subtitle1" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
								Kosten (€)
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{regularItems.map((item, index) => (
						<TableRow key={index} style={{ borderBottom: '1px dotted black' }}>
							<TableCell>
								<Typography variant="body1" style={{ fontSize: '1.1rem' }}>
									{item.label}
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography variant="body1" style={{ fontSize: '1.1rem' }}>
									{objChange[item.field]}
								</Typography>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter style={{ borderTop: '2px solid black' }}>
					{totalItems.map((item, index) => (
						<TableRow key={index}>
							<TableCell>
								<Typography variant="h6" component="div">
									{item.label}
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
									{objChange[item.field]}
								</Typography>
							</TableCell>
						</TableRow>
					))}
				</TableFooter>
			</Table>
		);
	};


	const RjsfReadOnlyTextDisplay = ({ id, value, disabled, readonly }) => {
		const handleCopy = (event) => {
			event.preventDefault();  // Prevent copying content
		};

		return (
			<div
				id={id}
				onCopy={handleCopy}
				style={{
					padding: '10px',
					backgroundColor: '#1976d20a',
					border: '1px solid #ccc',
					borderRadius: '4px',
					whiteSpace: 'pre-wrap',  // Maintains whitespace formatting
					overflowWrap: 'break-word',  // Ensures text breaks to avoid overflow
					userSelect: 'none'  // Prevents text selection
				}}
				aria-readonly={readonly}
				tabIndex={0}  // Make it focusable for accessibility reasons
				onContextMenu={(e) => e.preventDefault()}  // Optional: prevent right-click context menu on the element
			>
				{value || 'Der Bericht ist in der App verfügbar.'}
			</div>
		);
	};

		
	const RjsfSliderEditable = (props) => {
		console.log('>>> RjsfSliderEditable PROPS', props);

		const {
			formData,
			schema,
			id,
			value,
			onChange,
			uiSchema,
			options = {} // Extract options from props, assuming RJSF standard `ui:options`
		} = props;

		const fixedValues = schema.fixedValues;

		// Destructure custom properties from options
		//let { width, height } = options;
		const uiOptions = uiSchema[id]?.['ui:options'] || {};
		console.log( 'uiOptions', uiOptions);
		const { width, height, label } = { ...options, ...uiOptions }		

		let field = id.replace('root_', '');


		const handleChange = event => {
			onChange( event);
			
			//var och = {
			//	...objChange,
			//	[field]: event
			//};
			//setObjChange(och);			
		};		

		return (
			<QeeSliderEditable
				label={label}
				value={value}
				fixedValues={fixedValues}
				onChange={handleChange}
				width={width}
				height={height}
			/>
		);
	};
	
		
	// register the custom widgets
	const rjsfWidgets = {
		customDate: RjsfDateWidget,
		buttonCounter: RjsfButtonCounter,
		qeeHtml: RjsfHtml, 
		qeeHtmlCheckbox: RjsfHtmlHtmlCheckbox,		
		paragraph: RjsfParagraph,
		h5Title: RjsfH5Title,		
		yearPicker: RjsfYearPicker,
		datePicker: RjsfDatePicker,		
		colorButtonPrimary: RjsfColorButtonPrimary,
		facGAddress: RjsfQeeGmap,
		qeeRadioGroup: RjsfQeeRadioGroup,
		qeeCheckboxGroup: RjsfQeeCheckboxGroup,		
		hintBox: RjsfHintBox,
		autocomplete: RjsfAutocomplete,	
		internationalPhone: RjsfInternationalPhone,
		simplePhone: RjsfSimplePhone,		
		tableComparison: RjsfTableComparison,		
		facCompEnergyResultDiffCard: RjsfFacCompEnergyResultDiffCard,
		facNewbuildBenchEstimation: RjsfFacNewbuildBenchEstimation,
		costCalculationTable: RjsfCostCalculationTable,
		readOnlyTextDisplay: RjsfReadOnlyTextDisplay,
		sliderEditable: RjsfSliderEditable
	};
	
	
	const LocalFooter = props => {	
		return(
			<Footer left={0} />
		)
	}
	
	
	const getKeyVal = ( keyFld) => {
		console.log( '--> getKeyVal', keyFld);
		var keyVal = objChange[ keyFld];
		console.log( '<-- getKeyVal', keyVal);
		return keyVal;
	}
		
		
	const renderString = ( tpl, data) => {	
		const compiledTemplate = Handlebars.compile( tpl);
		const renderedString = compiledTemplate( data);				
		return renderedString;
	}		


	const showRightColumn = () => {	
		return (gstate.isXL || gstate.isLG) && objChange.steps[activeStep]?.text?.des_data?.urlRightImage;
	}
	const flexValue = () => {
		if (showRightColumn()) {
			return gstate.isXL ? '1 1 60%' : '1 1 70%';
		}
		return '1 1 100%';
	};
	const rightColumnFlexValue = () => {
		return gstate.isXL ? '1 1 40%' : '1 1 30%';
	};	


	// , FieldTemplate: CustomFieldTemplate 
	// <StepLabel {...labelProps}></StepLabel>
	return (
				
		<Box ref={containerRef} sx={{ width: '100%', display: 'flex', flexDirection: showRightColumn() ? 'row' : 'column'  }}>
			{isLoading ?
				<WaitProgressLinear />
			:	
				<>
				{isError ?			
					<>CONFIGURATION ERROR: {isError}</>
				:
					<>
					<Box sx={{ flex: flexValue(), padding: 2 }}>
						{activeStepNr !== objChange.stepsNrs.length ? (
							<Stepper activeStep={activeStepNr}>
								{objChange.stepsNrs.map((step, index) => {
									const stepProps = {};
									const labelProps = {};
									return (
										<Step key={index+1} {...stepProps}>	
											<QeeTooltip title={objChange.steps[index].text.title}>								
												<StepButton color="inherit" onClick={handleStep(index)}>											
												</StepButton>
											</QeeTooltip>
										</Step>
									);
								})}
							</Stepper>
						) : (
							''
						)}

						{activeStepNr === objChange.stepsNrs.length ? (
							<>
								{isLoading ? (
									<>Loading...</>
								) : (
									<React.Fragment>
										<Typography sx={{ mt: 4, mb: 2 }}>
											{objChange.end.status === 201 ? (
												<QeeSuccessMessage showIcon={false} msg={<span dangerouslySetInnerHTML={{ __html: renderString(objChange.end.success, objChange.end) }} />} />
											) : objChange.end.error === 501 ? (
												<QeeErrorMessage msg={<span dangerouslySetInnerHTML={{ __html: renderString(objChange.end.failure, objChange.end) }} />} />
											) : (
												<QeeWarningMessage msg={<span dangerouslySetInnerHTML={{ __html: renderString(objChange.end.failure, objChange.end) }} />} />
											)}
										</Typography>
									</React.Fragment>
								)}
							</>
						) : (
							<React.Fragment>
								{objChange.steps[activeStep]?.text?.des_data?.comp === 'QeeRadioGroup' && (
									<QeeRadioGroup
										gstate={gstate}
										setObjEdit={setObjEdit}
										formLabel={objChange.steps[activeStep].text.des_data.title}
										formSublabel={objChange.steps[activeStep].text.des_data.description}
										defaultValue={objChange[objChange.steps[activeStep].text.des_data.field]}
										fctOnChange={(ev) => {
											const dd = objChange.steps[activeStep].text.des_data;
											console.log('onChange for ' + dd.field, ev.target.value);
											var och = objChange;
											och[dd.field] = ev.target.value;
											setObjChange(och);
											setObjEdit(och);
											handleNext();
										}}
										sdataName={objChange.steps[activeStep].text.des_data.sdataName}
										sdataKeyFld={objChange.steps[activeStep].text.des_data.sdataKeyFld}
										sdataKeyVal={getKeyVal(objChange.steps[activeStep].text.des_data.sdataKeyVal)}
										sdata={objChange.steps[activeStep].text.des_data.sdata}
										hasDesc={objChange.steps[activeStep].text.des_data.hasDesc}
										hasAutoIcon={objChange.steps[activeStep].text.des_data.hasAutoIcon}
										height={!objChange.is_quiz && !gstate.isXS ? 100 : null}
										isQuiz={objChange.is_quiz}
										forceXSBehavior={objChange.is_quiz}
									/>
								)}

								{objChange.steps[activeStep]?.text?.des_data?.comp === 'QeeCheckboxGroup' && (
									<QeeCheckboxGroup
										gstate={gstate}
										setObjEdit={setObjEdit}
										formLabel={objChange.steps[activeStep].text.des_data.title}
										formSublabel={objChange.steps[activeStep].text.des_data.description}
										defaultValue={objChange[objChange.steps[activeStep].text.des_data.field]}
										fctOnClick={() => {
											handleNext();
										}}
										fctOnChange={(checkes) => {
											const dd = objChange.steps[activeStep].text.des_data;
											console.log('onChange for ' + dd.field, checkes);
											var och = objChange;
											och[dd.field] = checkes;
											setObjChange(och);
											setObjEdit(och);
										}}
										sdataName={objChange.steps[activeStep].text.des_data.sdataName}
										sdata={objChange.steps[activeStep].text.des_data.sdata}
										hasDesc={objChange.steps[activeStep].text.des_data.hasDesc}
										hasAutoIcon={objChange.steps[activeStep].text.des_data.hasAutoIcon}
									/>
								)}

								{objChange.steps[activeStep]?.text?.des_data?.comp === 'form' && (
									<Box sx={{ mt: 4 }}>
										<Form
											ref={formRef}
											formData={objChange}
											classes={{ root: classes.formControlCompact }}
											schema={getStepSchema(objChange.steps[activeStep].text)}
											uiSchema={getStepUiSchema(objChange.steps[activeStep].text)}
											validator={validator}
											transformErrors={transformErrors}
											customValidate={customValidate}
											templates={{ ErrorListTemplate }}
											liveValidate={false}
											noHtml5Validate={true}
											children={true}
											widgets={rjsfWidgets}
											parentWindowClass={props.parentWindowClass}
											onChange={(ev) => {
												console.log("changed", ev);
												if (!touched) {
													setTouched(true);
												}
												var och = JSON.parse(JSON.stringify(ev.formData));
												console.log('SETTED OBJ CHANGE', och);
												setObjChange(och);
											}}
											onSubmit={handleNext}
											onError={console.log("errors")}
										/>
									</Box>
								)}

								{objChange.steps[activeStep]?.text?.des_data?.comp === 'html' && (
									<Box sx={{ mt: 4 }}>
										<h2>{objChange.steps[activeStep].text.des_data.title}</h2>
										<p>{objChange.steps[activeStep].text.des_data.description}</p>
										<div dangerouslySetInnerHTML={{ __html: objChange.steps[activeStep].text.des_data.body }} />
									</Box>
								)}
								
							<div className={classes.buttonsContainer}>
								<Box
									sx={{
										maxWidth: 1100,
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										pt: 0.5,
										pb: 0.5,
										px: { xs: 2, sm: 3, md: 3 }, // padding left and right for smaller screens
									}}
								>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											gap: 1, // space between buttons
											width: '100%',
											maxWidth: 500, // limit max width for the buttons together
										}}
									>
										<QeeColorButtonSecondary
											disableElevation
											disabled={activeStep === 0}
											onClick={handleBack}
											sx={{ flex: 1 }}
										>
											<Typography variant='button'>Zurück</Typography>
										</QeeColorButtonSecondary>
										<QeeColorButtonPrimary
											disableElevation
											disabled={!validNext}
											onClick={handleNext}
											sx={{ flex: 2 }}
										>
											<Typography variant='button'>
												{activeStepNr === objChange.stepsNrs.length - 1 ? 'Ausführen' : 'Weiter'}
											</Typography>
										</QeeColorButtonPrimary>
									</Box>
								</Box>
							</div>
																
							</React.Fragment>
						)}
						{props.showFooter && <LocalFooter />}
					</Box>
					{showRightColumn() && (
						<Box sx={{ flex: rightColumnFlexValue(), position: 'relative' }}>
							<Box
								sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									maxHeight: '1000px',
									borderRadius: '16px',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'top',
									backgroundImage: `url(${objChange.steps[activeStep]?.text?.des_data?.urlRightImage})`
								}}
							/>
						</Box>
					)}
					</>
				}
				</>	
			}
		</Box>
	);
});

//export default QeeStepper;
export default withStyles(styles)(QeeStepper);