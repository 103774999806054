// helpers.js
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/de';   // Import German locale
moment.locale('de');   // Set the locale to German

// Define formats for different configurations
export const DATE_FORMATS = {
    synapcusDate: 'MMM. DD, YY (dd)',  // e.g., Apr. 15, 23 (Sa)
    synapcusTime: 'HH:mm',
    shortTimeDate: 'DD.MM.YYYY', // e.g., 24.12.2024
    longTimeDate: 'dddd, MMMM Do YYYY, HH:mm', // e.g., Montag, Dezember 24. 2024, 15:45
    timeOnly: 'HH:mm', // e.g., 15:45 (24-hour format for German locale)
    dateOnly: 'DD.MM.YYYY', // e.g., 24.12.2024
    shortWeekday: 'ddd', // e.g., Mo (short for Montag)
    longWeekday: 'dddd', // e.g., Montag
    dateWithShortWeekday: 'ddd, DD.MM.YYYY', // e.g., Mo, 24.12.2024
    dateWithLongWeekday: 'dddd, DD.MM.YYYY', // e.g., Montag, 24.12.2024
    dateTimeWithShortWeekday: 'ddd, DD.MM.YYYY, HH:mm', // e.g., Mo, 24.12.2024, 15:45
    dateTimeWithLongWeekday: 'dddd, DD.MM.YYYY, HH:mm', // e.g., Montag, 24.12.2024, 15:45
    shortMonth: 'MMM', // e.g., Dez (short for Dezember)
    longMonth: 'MMMM', // e.g., Dezember
    dateWithShortMonth: 'DD MMM YYYY', // e.g., 24 Dez 2024
    dateWithLongMonth: 'DD MMMM YYYY', // e.g., 24 Dezember 2024
    dateTimeWithShortMonth: 'DD MMM YYYY, HH:mm', // e.g., 24 Dez 2024, 15:45
    dateTimeWithLongMonth: 'DD MMMM YYYY, HH:mm', // e.g., 24 Dezember 2024, 15:45
};

/**
 * formatDate - Format a date based on the provided format type
 * @param {string} isoDate - The ISO date string received from the server
 * @param {string} formatType - The format type (shortTimeDate, longTimeDate, etc.)
 * @returns {string} - The formatted date string
 */
export const formatDate = (isoDate, formatType) => {
    const date = moment(isoDate);

    if (formatType === 'synapcusDate' && date.isSame(moment(), 'day')) {
        return 'Heute';
    }

    if (formatType === 'synapcusTime' && date.isSame(moment(), 'day')) {
        return date.format(DATE_FORMATS.synapcusTime);
    }

    // Return the formatted date based on the provided format type
    return date.format(DATE_FORMATS[formatType] || DATE_FORMATS.shortTimeDate);
};
// Example usage:
// const formattedDate = formatDate('2024-12-24T15:45:50.000Z', 'longTimeDate');
// console.log(formattedDate); // Outputs the date in German format


/**
*	formatNumber
*/
export const formatNumber = (vl, unit, decs) => {
    try {
        if (!decs) decs = 0;

        if (unit === '%') {
            vl = (vl * 100).toFixed(decs);
            vl = new Intl.NumberFormat('de-DE').format(vl);
        } else if (unit === 'EUR') {
            vl = parseFloat(vl).toFixed(decs);
            vl = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                minimumFractionDigits: decs,
                maximumFractionDigits: decs,
                currency: 'EUR'
            }).format(vl);
        } else {
            vl = parseFloat(vl).toFixed(decs);
            vl = new Intl.NumberFormat('de-DE').format(vl);
        }
    } catch (e) {
        console.error(e);
    }
    return vl;
};


export const convertObjToDateTime = ( obj) => {
	if (obj && obj._seconds !== undefined) {
		const milliseconds = obj._seconds * 1000;
		const nanosecondsToMilliseconds = obj._nanoseconds ? obj._nanoseconds / 1000000 : 0;
		return new Date(milliseconds + nanosecondsToMilliseconds);
	} else {
		return null; // or handle the default value as needed
	}
}


export const usrRight = ( fac) => {

	//console.log( '--> usrRight', fac);

	var rgt = 'unknown';

	try {		
		var usr = JSON.parse( sessionStorage.getItem( 'usr'));
		if( usr.username == fac.username) 
			rgt = 'owner';
	
		if( fac.allowed_readers) {
			if( fac.allowed_readers.includes( usr.cch_email_o)) {
				rgt = 'reader';
			}	
		}

		if( fac.allowed_writers) {
			if( fac.allowed_writers.includes( usr.cch_email_o)) {
				rgt = 'writer';
			}	
		}
		
	} catch( e) {
		console.error( 'usrRight ERR', e);
	}		
	
	//console.log( '<-- usrRight', rgt);
	
	return rgt;
}	


export const usrIsAdmin = () => {
	var usr = JSON.parse( sessionStorage.getItem( 'usr'));
	if( !usr)
		return false;
	
	if( usr.admin == true) 
		return true;
	
	return false;
}


export const generateRandomNumber = (digits) => {
	if (digits < 1 || digits > 15) {
		throw new Error("Digits must be between 1 and 15.");
	}
	const min = Math.pow(10, digits - 1);
	const max = Math.pow(10, digits) - 1;
	const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
	return randomNumber.toString();
};

	
export const getUserIP = async () => {
	try {
		const response = await axios.get('https://api.ipify.org?format=json');
		// Check if the response contains an IP address; if not, generate a mock IP
		if (response.data && response.data.ip) {
			console.log('My public IP address is:', response.data.ip);
			return response.data.ip;
		} else {
			// If the response does not contain a valid IP, return a mock IP
			const mockIP = `999.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}`;
			console.log('Returning mock IP:', mockIP);
			return mockIP;
		}
	} catch (error) {
		console.error('Error fetching IP:', error);
		// If an error occurs, return a mock IP
		const mockIP = `999.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}`;
		console.log('Returning mock IP due to error:', mockIP);
		return mockIP;
	}
};


export const getFacExternUrl = ( portal, externId) => {
	if( portal === 'scrapfly.is24')
		return {name:'Immobilienscout24', url:'https://www.immoscout24.de/expose/' + externId};
	else if( portal === 'scrapfly.immowelt')
		return {name:'Immowelt', url:'https://www.immowelt.de/expose/' + externId};	
	if( portal === 'scrapfly.immonet')
		return {name:'Immonet DE', url:'https://www.immonet.de/expose/' + externId};	
}
