// qeeMessages.js

import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';
import { Error } from '@mui/icons-material';
import { CheckCircle } from '@mui/icons-material';


//<Error sx={{ color: 'red', fontSize: '4rem' }} />
export const QeeErrorMessage = (props) => {
	return (
	<Alert severity="error" sx={{ borderRadius: '10px', py: 2 }} icon={false}>
		<Typography component="span" sx={{ fontWeight: 'bold', pr: 1 }}>
			Error:
		</Typography>
		<Typography component="span">
			{props.msg}
		</Typography>
	</Alert>
	);
}
export const QeeWarningMessage = (props) => {
	return (
	<Alert severity="warning" sx={{ borderRadius: '10px', py: 2 }} icon={false}>
		<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
			<Box sx={{ pb: 2 }}>
				<Typography component="span">
					{props.msg}
				</Typography>
			</Box>
		</Box>
	</Alert>
	);
}	
export const QeeSuccessMessage = (props) => {
  return (
    <Alert severity="success" sx={{ borderRadius: '10px', py: 2 }} icon={false} >
		<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width:'100%'}} >
			<Box style={{width:'100% !important'}}>
				<Typography component="span">
					{props.msg}
				</Typography>
			</Box>
				<Box sx={{ mt: 2, mb: 1 }}>
					<CheckCircle sx={{ color: 'green', fontSize: '4rem' }} />
				</Box>		
			</Box>
    </Alert>
  );
};

