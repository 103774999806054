import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import QeeChatEmbedded from './qeeChatEmbedded';

const SacChat = ({ sac }) => {
    const theme = useTheme(); 
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));	

    return (
        <QeeChatEmbedded 
            instanceName="qeeleaapp-chat-sac" 
            parentForm="sac"
            parentId={sac.id} 
            isP2P={true}
            showDateTime={true}
            showDate={true}
            showTime={true}
            height={isXs ? "calc(100svh - 60px)" : "90vh"}
            style={{ height: isXs ? "calc(100svh - 60px)" : "90vh" }}
        />
    );
};

export default SacChat;
