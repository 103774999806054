import React, { useState, useCallback } from 'react';
import { Box, Button, Icon } from '@mui/material';

export function MuiMultiSelectInput({
  chatController,
  actionRequest,
}) {
  const [values, setValues] = useState([]);

  const handleSelect = useCallback((value) => {
    if (!values.includes(value)) {
      setValues([...values, value]);
    } else {
      setValues(values.filter((v) => v !== value));
    }
  }, [values]);

  const setResponse = useCallback(() => {
    const options = actionRequest.options.filter((o) => values.includes(o.value));
    const res = {
      type: 'multi-select',
      value: options.map((o) => o.text).toString(),
      options,
    };
    chatController.setActionResponse(actionRequest, res);
    setValues([]);
  }, [actionRequest, chatController, values]);

  const sendButtonText = actionRequest.sendButtonText ? actionRequest.sendButtonText : 'Send';

  return (
    <Box
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
          flex: '0 0 auto',
          maxWidth: '100%',
        },
        '& > * + *': {
          mt: 1,
        },
      }}
    >
      {actionRequest.options.map((o) => (
        <Button
          key={actionRequest.options.indexOf(o)}
          type="button"
          value={o.value}
          onClick={(e) => handleSelect(e.currentTarget.value)}
          variant={!values.includes(o.value) ? 'outlined' : 'contained'}
          color="primary"
        >
          {o.text}
        </Button>
      ))}
      <Button
        type="button"
        onClick={setResponse}
        disabled={values.length === 0}
        variant="contained"
        color="primary"
        startIcon={<Icon>send</Icon>}
      >
        {sendButtonText}
      </Button>
    </Box>
  );
}
