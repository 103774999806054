import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';

const withBackground = WrappedComponent => {
  const WithBackground = ({ classes }) => {
    return (
      <div className={classes.backgroundColor}>
        <WrappedComponent />
      </div>
    );
  };

  return withStyles(styles)(WithBackground);
};

const styles = () =>
  createStyles({
    backgroundColor: {
      backgroundColor: '#FBFDFC'
    }
  });

export default withBackground;