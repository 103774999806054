import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CardMedia from '@material-ui/core/CardMedia';
import { getSdatas, orgSave } from '../api/service';
import defaultLogo from '../assets/app-ico.png';
import StarRating from './starRating';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		boxSizing: 'border-box',
	},
	content: {
		flex: 1,
		overflowY: 'auto',
		padding: theme.spacing(2),
		//paddingBottom: `calc(${theme.spacing(2)}px + 56px)`, // Ensure the content does not overlap with the button bar
	},
	media: {
		height: 140,
		backgroundSize: 'contain',
		marginBottom: theme.spacing(2),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: {
		marginBottom: theme.spacing(2),
		textAlign: 'center',
	},
	textField: {
		marginBottom: theme.spacing(2)
	},
	textFieldLabel: {
		marginBottom: theme.spacing(0.5),
		fontWeight: 700
	},
	descriptionText: {
		whiteSpace: 'pre-wrap', // This will preserve the newlines and spaces
	},
}));

const OrgForm = ({ org, onClose, isEditable = false }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [roleText, setRoleText] = useState('');
	const [description, setDescription] = useState(org.org_description || '');
	
	const { enqueueSnackbar } = useSnackbar();		

	useEffect(() => {
		const fetchRoleText = async () => {
			try {
				if (Array.isArray(org.org_perprj_role)) {
					const roleNames = await Promise.all(
						org.org_perprj_role.map(async (role) => {
							const data = await getSdatas('sdata_perprj_roles_types', 'type', role);
							return data.length > 0 ? data[0].name : 'Keine Angaben';
						})
					);
					setRoleText(roleNames.join(', '));
				} else if (org.org_perprj_role) {
					const data = await getSdatas('sdata_perprj_roles_types', 'type', org.org_perprj_role);
					const roleName = data.length > 0 ? data[0].name : 'Keine Angaben';
					setRoleText(roleName);
				}
			} catch (error) {
				console.error('Error fetching role text:', error);
				setRoleText('Abfragefehler für Gewerke');
			}
		};

		fetchRoleText();
	}, [org.org_perprj_role]);

	const handleSave = async (newDescription) => {
		console.log('Saving description:', newDescription);
		await orgSave({
			...org, 
			org_description: newDescription
		})
		enqueueSnackbar( 'Gespeichert!', { variant: 'success', preventDuplicate: true });
	};

	// Debounce the handleSave function to prevent excessive calls
	const debouncedSave = useCallback(debounce(handleSave, 1000), []);

	const handleDescriptionChange = (e) => {
		const newDescription = e.target.value;
		setDescription(newDescription);
		debouncedSave(newDescription);
	};

	const logoUrl = org.org_logo_url || defaultLogo;

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<CardMedia className={classes.media} image={logoUrl} title={org.org_name || ''} />
				<Typography variant="h4" className={classes.title}>
					{org.org_name || ''}
				</Typography>
				<StarRating rating={org.org_rating || 0} totalRatings={org.org_rating ? org.org_rating : 0} />

				{isEditable ? (
					<>
						<TextField
							label="Name"
							fullWidth
							variant="outlined"
							className={classes.textField}
							value={org.org_name || ''}
							disabled
						/>
						<TextField
							label="Adresse"
							fullWidth
							variant="outlined"
							className={classes.textField}
							value={`${org.addr_street_o || ''} ${org.addr_housenumber_o || ''}, ${org.addr_locality_o || ''}`}
							disabled
						/>
						<TextField
							label="Gewerke"
							fullWidth
							variant="outlined"
							className={classes.textField}
							value={roleText || ''}
							disabled
						/>
						<TextField
							label="Beschreibung"
							fullWidth
							multiline
							minRows={3}
							variant="outlined"
							className={classes.textField}
							value={description || ''}
							onChange={handleDescriptionChange}
						/>
					</>
				) : (
					<>
						<div className={classes.textField}>
							<Typography variant="caption" className={classes.textFieldLabel}>
								Name
							</Typography>
							<Typography variant="body1">{org.org_name || ''}</Typography>
						</div>
						<div className={classes.textField}>
							<Typography variant="caption" className={classes.textFieldLabel}>
								Adresse
							</Typography>
							<Typography variant="body1">{`${org.addr_street_o || ''} ${org.addr_housenumber_o || ''}, ${org.addr_locality_o || ''}`}</Typography>
						</div>
						<div className={classes.textField}>
							<Typography variant="caption" className={classes.textFieldLabel}>
								Gewerke
							</Typography>
							<Typography variant="body1">{roleText || ''}</Typography>
						</div>
						<div className={classes.textField}>
							<Typography variant="caption" className={classes.textFieldLabel}>
								Beschreibung
							</Typography>
							<Typography variant="body1" className={classes.descriptionText}>
								{description || ''}
							</Typography>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default OrgForm;
