import React from "react";
import react, { useState, useEffect, useLayoutEffect } from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';


const QeeTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} 
		TransitionComponent={Zoom} 
		enterDelay={50} 
		enterNextDelay={500}
		leaveDelay={500} 
		enterTouchDelay={200}
		leaveTouchDelay={3000}		
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
		fontSize: 12
	},
}));
export default QeeTooltip;


