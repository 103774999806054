// QeeSnackbar
import * as React from 'react';
import ReactDOM from 'react-dom';
import react, { useState, useEffect, useLayoutEffect } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { makeStyles }  from '@material-ui/core/styles';

import { useAuth } from "../context/provider"; 


const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const QeeSnackbar = (props) => {
	
	console.log( 'QeeSnackbar props', props);
		
	const { gstate, setQeeSnackbar } = useAuth();						
	
	// some consts	
	const useStyles = makeStyles({
		//...props,	
		button: {
			textTransform: 'none'
		},	
	});	
	const classes = useStyles( props);	

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setQeeSnackbar({
			open: false,
			severity: '',
			msg: '',
			fctOnClose: null
		});
		
		if( props.fctOnClose)
			props.fctOnclose();
	};

	return (
		<Stack spacing={2} sx={{ width: '100%' }}>
			<Snackbar open={gstate.qeeSnackbar.open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
				This is a success message!
				</Alert>
			</Snackbar>
		</Stack>
	);	
}
export default QeeSnackbar;


