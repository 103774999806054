import defineProperty from "@babel/runtime/helpers/defineProperty";
import AudioRecorderPolyfill from "audio-recorder-polyfill";

class AudioMediaRecorder {
  static instance;

  md;
  recordChunks = [];

  constructor() {
    if (!window.MediaRecorder) {
      window.MediaRecorder = AudioRecorderPolyfill;
    }
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new AudioMediaRecorder();
    }
    return this.instance;
  }

  async initialize() {
    if (this.md) {
      return this;
    }

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    this.md = new MediaRecorder(stream);
    this.recordChunks = [];
    return this;
  }

  async startRecord() {
    return new Promise((resolve, reject) => {
      if (!this.md) {
        reject(new Error("Must be initialized."));
      }

      this.recordChunks = [];
      this.md.addEventListener("start", () => resolve());
      this.md.addEventListener("dataavailable", (e) => {
        if (e.data.size > 0) {
          this.recordChunks.push(e.data);
        }
      });
      this.md.start();
    });
  }

  async stopRecord() {
    return new Promise((resolve, reject) => {
      if (!this.md) {
        reject(new Error("Must be initialized."));
      }

      this.md.addEventListener("stop", () => {
        resolve(new Blob(this.recordChunks));
      });
      this.md.stop();
    });
  }
}

export { AudioMediaRecorder };
