// QeeOrgProvider
import React, { createContext, useMemo, useReducer, useContext, useRef } from 'react';

import OrgDialog from './orgDialog';
import { useAuth } from "../context/provider"; 


const OrgContext = createContext();

const QeeOrgProvider = (props) => {
	
	const { gstate } = useAuth();					
	
	const refOrgDialog = useRef(null);			
	
	const setOpenOrgDialog = ( org, isEditable, primaryAction, primaryActionLabel) => {
		refOrgDialog.current.handleOrgOpen( org, isEditable, primaryAction, primaryActionLabel);
	};

	const value = useMemo(() => {   
		return { setOpenOrgDialog };	  	  
	}, []);	
	
	return (
		<OrgContext.Provider value={value}>
			<OrgDialog 
				ref={refOrgDialog} 
			/>	
			{props.children}	
		</OrgContext.Provider>
	);

}

const useOrg = () => useContext( OrgContext );
export { OrgContext, useOrg }
export default QeeOrgProvider;
	

