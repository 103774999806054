// facAccessPersons.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Menu, MenuItem, Divider, Box, Button, TextField, Typography } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import PopupState from 'material-ui-popup-state';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@mui/material/Avatar';


const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
	},
	button: {
		margin: theme.spacing(1),
	},
	popupMenu: {
		paddingTop: 0,
		paddingBottom: 0,
	},
}));

const FacAccessPersons = (props) => {
	const classes = useStyles();
  
	const [isLoading, setIsLoading] = useState( true);  
  
	const popupState = usePopupState({ variant: "popover", popupId: "access-persons-popup" });

	const [usr, setUsr] = useState({});	
	const [readers, setReaders] = useState([]);
	const [writers, setWriters] = useState([]);  
	const [rightsList, setRightsList] = useState([]);

	
	useEffect(() => {
		console.log( '>>> readers', readers)
		console.log( '>>> writers', writers)
		getUsr();
		getRights();
		setIsLoading( false);
	}, []);

	
	const getUsr = async () => {
		try {
			const response = await axios.get(`/api/usrByField/username/${props.username}`);			
			console.log( 'FACUSR', response.data)
			setUsr( response.data)
			
		} catch (error) {
			console.error(error);
		}
	};	
	
	
	const getRights = async () => {
		try {
			setIsLoading( true);			
			const response = await axios.get(`/api/fac/rights/${props.facId}`);			
			const { allowed_readers, allowed_writers } = response.data;
			var transformed = allowed_readers.map((email) => ({ email, right: 'reader' })).concat(allowed_writers.map((email) => ({ email, right: 'writer' })));
			transformed.sort((a, b) => a.email.toLowerCase() < b.email.toLowerCase() ? -1 : a.email.toLowerCase() > b.email.toLowerCase() ? 1 : 0);
			setRightsList( transformed);
			setReaders( allowed_readers)
			setWriters( allowed_writers)
			setIsLoading( false);			
		} catch (error) {
			console.error(error);
			setIsLoading( false);			
		}
	};
  
  
	const handleDelete = async (email) => {
		console.log('handleDelete', { email });				
							
		/*	
		const allowed_readers = readers.filter(reader => reader !== email)
		const allowed_writers = writers.filter(writer => writer !== email)		
		setReaders( allowed_readers);
		setWriters( allowed_writers);			
		const transformed = allowed_readers.map((email) => ({ email, right: 'reader' })).concat(allowed_writers.map((email) => ({ email, right: 'writer' })));
		setRightsList( transformed);		
		*/

		try {
			setIsLoading( true);						
			var ret = await axios.put(`/api/fac/removeRights/${props.facId}`, { emails: [email] });
			console.log( 'After delete', ret.data)
			const { allowed_readers, allowed_writers } = ret.data;
			setReaders( allowed_readers);
			setWriters( allowed_writers);			
			var transformed = allowed_readers.map((email) => ({ email, right: 'reader' })).concat(allowed_writers.map((email) => ({ email, right: 'writer' })));
			transformed.sort((a, b) => a.email.toLowerCase() < b.email.toLowerCase() ? -1 : a.email.toLowerCase() > b.email.toLowerCase() ? 1 : 0);
			setRightsList( transformed);
			setIsLoading( false);			
			
		} catch (error) {
			console.error(error);
			setIsLoading( false);			
		}		
	}; 

	
	const handleSubmit = async (email, type) => {
		console.log('handleSubmit', { email, type });

		const isInReaders = readers.includes(email);
		const isInWriters = writers.includes(email);
		var allowed_readers = readers;
		var allowed_writers = writers;
		if (isInReaders) {allowed_readers = readers.filter(reader => reader !== email);}
		if (isInWriters) {allowed_writers = writers.filter(writer => writer !== email);}

		const newRights = type === 'reader' ? [...allowed_readers, email] : [...allowed_readers, email];

		allowed_readers = type === 'reader' ? newRights : allowed_readers
		allowed_writers = type === 'writer' ? newRights : allowed_writers
		/*
		setReaders( allowed_readers);
		setWriters( allowed_writers);			
		const transformed = allowed_readers.map((email) => ({ email, right: 'reader' })).concat(allowed_writers.map((email) => ({ email, right: 'writer' })));
		setRightsList( transformed);		
		*/
		const dta = {
			allowed_readers: allowed_readers,
			allowed_writers: allowed_writers
		};
		console.log('handleSubmit dta', dta);

		try {
			setIsLoading( true);									
			var ret = await axios.put(`/api/fac/addRights/${props.facId}`, dta);
			console.log( 'After submit', ret.data)
			const { allowed_readers, allowed_writers } = ret.data;
			setReaders( allowed_readers);
			setWriters( allowed_writers);			
			var transformed = allowed_readers.map((email) => ({ email, right: 'reader' })).concat(allowed_writers.map((email) => ({ email, right: 'writer' })));
			transformed.sort((a, b) => a.email.toLowerCase() < b.email.toLowerCase() ? -1 : a.email.toLowerCase() > b.email.toLowerCase() ? 1 : 0);
			setRightsList( transformed);
			setIsLoading( false);						
			
		} catch (error) {
			console.error(error);
			setIsLoading( false);						
		}
	};
	
	
	const handleChange = async (email, type) => {
		await handleDelete( email);
		await handleSubmit( email, type);
	}
	
	
	function getInitials( lastName, firstName) {
		console.log( 'getInitials', [lastName, firstName]);
		var initials = "NN";		
		try {
			if( lastName && firstName) {
				initials = lastName.charAt(0) + firstName.charAt(0);
			} else if( lastName) {
				initials = lastName.substring(0, 2);
			} else if( firstName) {
				initials = firstName.substring(0, 2);
			}		
			initials = initials.toUpperCase();
		} catch( e) {
			console.error( 'getInitials ERR ' + lastName + ',' + firstName, e);
		}
		
		return initials;
	}
	
		
	return (
		<Box style={{ border: '1px solid lightgrey', padding: 10 }}>
		  {isLoading ? (
			<CircularProgress size={30} />
		  ) : (
			<>
			<TableContainer>
			  <Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableBody>
				  <TableRow key={0}>
					<TableCell component="th" scope="row">
					  <Box display="flex" alignItems="center">
						<Avatar>{getInitials(usr.per_lastname, usr.per_firstname)}</Avatar>
						<Box marginLeft={2}>
						  <Typography variant="h6">{usr.per_lastname} {usr.per_firstname}</Typography>
						  <Typography variant="subtitle1">{usr.cch_email_o}</Typography>
						  <Typography variant="subtitle1">Eigentümer</Typography>
						</Box>
					  </Box>
					</TableCell>
					<TableCell align="left"></TableCell>
				  </TableRow>
				</TableBody>
			  </Table>
			</TableContainer>

			<TableContainer>
			  <Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableBody>
				  {rightsList.map((row, index) => (
					<TableRow key={index}>
					  <TableCell component="th" scope="row">
						<Box display="flex" alignItems="center">
						  <Avatar>{getInitials(row.name ? row.name : row.email)}</Avatar>
						  <Box marginLeft={2}>
							<Typography variant="h6">{row.name}</Typography>
							<Typography variant="subtitle1">{row.email}</Typography>
							<Typography variant="subtitle1">{row.right === 'reader' ? 'Betrachter' : 'Mitbearbeiter'}</Typography>
						  </Box>
						</Box>
					  </TableCell>

					  <TableCell align="left">
						{props.usrIsOwner ?
						<PopupState variant="popover" popupId={`popup-${index}`}>
						  {(popupState) => (
							<React.Fragment>
							  <IconButton aria-label="edit" size="large" {...bindTrigger(popupState)}>
								<EditIcon fontSize="inherit" />
							  </IconButton>
							  <Menu {...bindMenu(popupState)}>
								<MenuItem
								  onClick={() => {
									handleChange(row.email, 'reader');
									popupState.close();
								  }}
								>
								  Betracher
								</MenuItem>
								<MenuItem
								  onClick={() => {
									handleChange(row.email, 'writer');
									popupState.close();
								  }}
								>
								  Mitbearbeiter
								</MenuItem>
								<Divider />
								<MenuItem
								  onClick={() => {
									handleDelete(row.email);
									popupState.close();
								  }}
								>
								  Löschen
								</MenuItem>
							  </Menu>
							</React.Fragment>
						  )}
						</PopupState>
						:''}
					  </TableCell>
					</TableRow>
				  ))}
				</TableBody>
			  </Table>
			</TableContainer>
			</>
			)}
		</Box>
	);
}
export default FacAccessPersons;
