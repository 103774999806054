// sacDocs.js

import React from 'react';

const SacDocs = () => {
    return (
        <div>
            <h1>Dokumente</h1>
            {/* Add your documents content here */}
        </div>
    );
}

export default SacDocs;
