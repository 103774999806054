import react, { useRef, useEffect } from 'react';

import Grid from "@material-ui/core/Grid";
import AddIcon from "@mui/icons-material/Add";
import Badge from '@mui/material/Badge';

import { makeStyles } from "@material-ui/core/styles";
import UpgradeIcon from '@mui/icons-material/Upgrade';

import notFoundImg from '../assets/fac-renovation-empty.png';
import useWindowDimensions from '../util/windowDimensions';

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import { useLicense } from "./qeeUsrLicenseProvider"; 

import { useAuth } from "../context/provider";
import { checkLicense } from '../util/auth';


const useStyles = makeStyles(theme => ({
	container: {
		padding:100,
		[theme.breakpoints.down('xs')]: {
			padding:10,
		},
		textAlign: 'center',
	},
	img: {
		color: theme.palette.primary.main,
		width:300,
		[theme.breakpoints.down('xs')]: {
			width:120,
		},		
	},
	text: {
		color: '#444',
		fontSize: 16,
		[theme.breakpoints.down('xs')]: {
			fontSize: 14,
		},		
	},
	textContainer: {
		padding: 50,
		[theme.breakpoints.down('xs')]: {
			padding:10,
		},		
	},
	
}));


const FacEnergyclassEmpty = ( props) => {

	const { setOpenLicenseDialog } = useLicense();					
				
	const handleLicensedFeature = ( fct) => { 							
		var tp = checkLicense( gstate.usr.lic);
		if( tp != 'insufficient') {
			fct();  // YES!
		} else {
			setOpenLicenseDialog( true);
		}	
	}
	
	const { gstate } = useAuth();							
			
	const classes = useStyles();			
	
		
    return (		
        <div className={classes.container}>
				
			<Grid container direction='column'>
			<Grid item>
				<img className={classes.img} src={ notFoundImg } alt="Not found" />		
			</Grid>
			<Grid item className={classes.textContainer}>
				<>
				{props.energyIstEmpty ? 
					<>
					<span className={classes.text}>Du hast noch keine Ermittlung des aktuellen Zustandes ausgeführt.</span><br/><br/>
					<span className={classes.text}><a onClick={props.fctOpenRenovationIst} style={{fontWeight:700}} href='#'>Hier</a> kannst du deinen Erfassungsassistent starten!</span><br/><br/>
					<QeeColorButtonPrimary disableElevation 
						className={classes.button} 
						onClick={props.fctOpenRenovationIst}>
						Erfassungsassistent
					</QeeColorButtonPrimary>									
					</> : ''}
				{props.energyPlanEmpty ? 
					<>
					<div className={classes.text} style={{marginBottom:20}}>
						Du hast noch keine Sanierungsplanung ausgeführt.<br/>
						Für die Erstellung der Sanierungsplanung benötigen wir noch weitere Informationen.<br/>					
						<a onClick={()=>handleLicensedFeature( props.fctOpenRenovationPlan)} style={{fontWeight:700, marginRight:5}} href='#'>Hier</a> kannst du deinen Sanierungsassistent starten!<br/>
					</div>	
					{/*
					{checkLicense( gstate.usr.lic)=='insufficient' ?
					<Badge badgeContent={'UPGRADE'} color="license">
						<QeeColorButtonPrimary disableElevation 
							className={classes.button} 
							onClick={()=>handleLicensedFeature( props.fctOpenRenovationPlan)}>
							Sanierungsassistent
						</QeeColorButtonPrimary>										
					</Badge>
					:*/}
					<QeeColorButtonPrimary disableElevation 
						className={classes.button} 
						onClick={()=>handleLicensedFeature( props.fctOpenRenovationPlan)}>
						Sanierungsassistent
					</QeeColorButtonPrimary>															
					</> : ''}				
				</>
			</Grid>			
			</Grid>			
				
        </div>
    )
}

export default FacEnergyclassEmpty;



