// facWiz.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { useImperativeHandle } from 'react';


import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import FacFinancingWiz from './facFinancingWiz';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	button: {
		textTransform: 'none'
	},
	/*
	dialog: {
		width: '50%',
		position: 'absolute',
		left: 10,
		top: 50,			
		//maxWidth: 1200,		 
	},
	newPosOfDialog: {
		minWidth: "800",
		transform: "translate(100%, 0%)",
	},
	*/
	newPosOfDialog: {		
		[theme.breakpoints.up('lg')]: {
			transform: "translate(100%, 0%)",
		},			
	},
	dialog: {
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},			
		position: 'absolute',
		left: 10,
		top: 50,			
	},	
	dialogStyle: {
		maxWidth: '50%',
		[theme.breakpoints.down('md')]: {
			maxWidth: '100%'
		},						
	},			
	appBarTitle: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},	
});



// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


// main fct
const FacFinancingWizDialog = React.forwardRef((props, forwardedRef) => {	

	const { gstate, setObjEdit, setObjErrors, setOpenFinancingDialog, setIsFetching, setCurrSubpage } = useAuth();				
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
		screenDims: {width:0, height:0},
		openFinancing: false		
	})
		
	useEffect( () => {	

						
	}, []);		

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);
	
	//useImperativeHandle(ref, () => ({
	const handleFinancingOpen = () => {
		setObjEdit({
			...gstate.objEdit,
			step: 0
		})		
		setOpenFinancingDialog( true);	
	}	
	
	const handleFinancingClose = () => {
		setObjEdit({
			...gstate.objEdit,
			step: 0
		})
		setOpenFinancingDialog( false);	
	};
		
	const handleFinancingSubmit = (event) => {
		
		console.log( 'handleFinancingSubmit', event);
		
		//authMiddleWare(this.props.history);
		const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login');	
		if( event && event.preventDefault)		
			event.preventDefault();
		
		const newFac = gstate.objEdit;
		console.log( 'submit obj', newFac);		
		let options = {};
		let id = gstate.objEdit.id;
		options = {
			url: `/api/fac/${id}`,
			method: 'put',
			data: newFac
		};
		
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios(options)
			.then((data) => {
				console.log( 'Submited data', data);
			})
			.catch((error) => {
				setObjErrors( error.response.data);
				console.log(error);
			});
	};
	
	
	const handleFinancingCalculate = async (event) => {
		
		console.log( 'handleFinancingCalculate', event);
		
		//authMiddleWare(this.props.history);
		const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login');	
		if( event && event.preventDefault)		
			event.preventDefault();
		
		const newFac = gstate.objEdit;
		console.log( 'calculate obj', newFac);		
		let options = {};
		let id = gstate.objEdit.id;
		options = {
			url: `/api/fac/valuation/${id}`,
			method: 'post',
			data: newFac
		};		
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		try {
			const retFac = await axios( options);
			console.log( 'Calculated fac', retFac.data);
			setObjEdit( retFac.data);
		} catch( error) {
			setObjErrors( error.response.data);
			console.log(error);
		};		
	};	
	

	const handleFinancingSubmitEnd = (event) => {
		setOpenFinancingDialog( false);	

		if( props.subpageFinancing)
			setCurrSubpage( props.subpageFinancing)				
	}
	
	
	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		ali: ()=>{alert(123)},
		handleFinancingOpen: (step)=>{handleFinancingOpen(step)},						
		handleFinancingClose: handleFinancingClose,	
		handleFinancingSubmit: handleFinancingSubmit,
		handleFinancingCalculate: handleFinancingCalculate,
		handleFinancingSubmitEnd: handleFinancingSubmitEnd				
	 }));
	

	
	return (	
	
		<>
		<div ref={forwardedRef}></div>	
		<Dialog 	
			fullScreen
			classes={{
				paper: classes.newPosOfDialog,
				paperFullWidth: classes.dialogStyle						
			}}
			className={classes.dialog}
			open={gstate.openFinancingDialog} 
			onClose={handleFinancingClose} 
			TransitionComponent={Transition}
		>
			<AppBar className={classes.appBarTitle}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleFinancingClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6">
						{'Finanzierungsassistent für ' + gstate.objEdit.fac_name}
					</Typography>
				</Toolbar>
			</AppBar>	
			<FacFinancingWiz 
				fctSubmit={handleFinancingSubmit} 
				fctFinancingCalculate={handleFinancingCalculate} 						
				fctSubmitEnd={handleFinancingSubmitEnd} />
		</Dialog>
		</>
	
	)
})	
export default withStyles(styles)(FacFinancingWizDialog);