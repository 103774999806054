import react, { useState, useEffect } from 'react';
import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { grey } from '@mui/material/colors';

import Paper from '@mui/material/Paper';
import NumberFormat from 'react-number-format';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Typography from '@mui/material/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import QeeRadioGroup from './qeeRadioGroup';
import WaitProgressCircular from './waitProgressCircular';

import { useAuth } from "../context/provider";

import { getSdatas, getAvailableHeatingsTypes } from "../api/service";


// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


const QeeRadioGroupEditor = ( props) => {
	
	console.log( 'QeeRadioGroupEditor props', props);
	
	const { gstate, setOpenItemEditor } = useAuth();	
		
	const [state, setState] = useState({	
		screenDims: {width:0, height:0},
		isLoadingFirst: false,
		sdatasFirst: [],
		vlFirst: props.data().first.defaultValue,
		isLoadingSecond: false,
		sdatasSecond: [],
		vlSecond: (props.data().second ? props.data().second.defaultValue : null),
		infoSecond: {}	
	});
		
	const [validationErrors, setValidationErrors] = useState( []);		
	
		
	const setValueFirst = async ( ev) => {
		console.log( 'setValueFirst', ev.target.value);
		
		if( ev.target.value) {
			
			setState({
				...state,
				vlFirst: ev.target.value					
			});				
			
			if( !props.data().second) {
				setState({
					...state,
					vlFirst: ev.target.value					
				});				
			} else {			
				// reload the second
				setState({
					...state,
					isLoadingSecond: true,
				});					
				var sdatas = await getSdatas( props.data().second.sdataName, 'parent_type', ev.target.value, 'vallab');
				console.log( 'sdatas-vallab second', sdatas);		
				setState({
					...state,
					sdatasSecond: sdatas,
					isLoadingSecond: false,
					vlFirst: ev.target.value,
					//vlSecond: '100'
				});						
			}
		}
		
	}	
	
	
	const setValueSecond = ( ev) => {
		console.log( 'setValueSecond', ev.target.value);
		setState({
			...state,
			vlSecond: ev.target.value
		});	
	}
	
	
	const submitValue = ( vl) => {		
		if( props.data().first.fctOnChange) 	
			props.data().first.fctOnChange( 
				//props.data().first.sdataName,
				//state.vlFirst
				{ 'first':props.data().first.sdataName, 'second':(props.data().second ? props.data().second.sdataName : null)},
				{ 'first':state.vlFirst, 'second':state.vlSecond},
				props.data().first.idx
			);
		
		setOpenItemEditor( false);
		if( props.fctOnClose)
			props.fctOnClose();		
	}	

	
	// some consts
	const useStyles = makeStyles( theme => ({
		formLabel: {
			//fontSize: 32
		},
		/*
		dialog: {
			width: '50%',
			position: 'absolute',
			left: 10,
			top: 50,			
			//maxWidth: 1200,		 
		},
		newPosOfDialog: {
			minWidth: "800",
			transform: "translate(100%, 0%)",
		},
		*/
		newPosOfDialog: {		
			[theme.breakpoints.up('lg')]: {
				transform: "translate(100%, 0%)",
			},			
		},
		dialog: {
			width: '50%',
			[theme.breakpoints.down('md')]: {
				width: '100%'
			},						
			position: 'absolute',
			left: 10,
			top: 50,						
			//[theme.breakpoints.down('xs')]: {
			//	width: 390,
			//},									

		},	
		dialogStyle: {
			maxWidth: '50%',
			[theme.breakpoints.down('md')]: {
				maxWidth: '100%'
			},						
		},				
		appBar: {
			position: 'relative'
		},	
		button: {
			textTransform: 'none'
		}
	}));			
	const classes = useStyles( props);			
		

	/**
	*	start the first sdatas loading
	*/	
	useEffect( async () => {
		
		console.log( 'qeeRadioGroupEditor useEffect start the first: props.data()', props.data());

		if( !gstate.openItemEditor)
			return;
				
		setState({
			...state,
			isLoadingFirst: true
		})		
		
		var sdataName = props.data().first.sdataName;
		var sdatas;
		if( sdataName==='availableHeatingsTypes')
			sdatas = await getAvailableHeatingsTypes( gstate.objEdit.fac_energy.energyclass_plan, 'vallab');
		else	
			sdatas = await getSdatas( props.data().first.sdataName, 'type', '', 'vallab');
		console.log( 'sdatas-vallab first', sdatas);		
		setState({
			...state,
			sdatasFirst: sdatas,
			vlFirst: props.data().first.defaultValue,   // refresh the value geted initially from props			
		});
										
	}, [gstate.openItemEditor]);					

	
	/**
	*	for the transition first to second
	*/
	useEffect(() => {
				
		setState({
			...state,
			isLoadingFirst: false,
			//vlFirst: props.data().first.defaultValue,   // refresh the value geted initially from props			
			isLoadingSecond: true
		})				
					
	}, [JSON.stringify( state.sdatasFirst)]);						
	

	/**
	*	start the second loading
	*/
	useEffect( async () => {

		console.log( 'qeeRadioGroupEditor useEffect start the second: vlFirst', state.vlFirst);
		
		if( props.data().second && state.vlFirst) {						
			var sdatas = await getSdatas( props.data().second.sdataName, 'parent_type', state.vlFirst, 'vallab');
			console.log( 'sdatas-vallab second', sdatas);		
			setState({
				...state,
				sdatasSecond: sdatas,
				isLoadingSecond: false,
				vlSecond: props.data().second.defaultValue,   // refresh the value geted initially from props			
			});			
		}		
		
	}, [state.vlFirst])   //[state.isLoadingSecond, state.vlFirst])


	/**
	*	end the second loading
	*/
	useEffect(() => {	
	
		console.log( 'qeeRadioGroupEditor useEffect END the second: state', state);
	
		setState({
			...state,
			isLoadingSecond: false
		})
	}, [state.sdatasSecond]);							
	
		
	/**
	*	Component for showing the infos
	*/
	const getInfoViewer = (sdatas, vl) => {
		var data = [];
		if (sdatas.map) {
			sdatas.map((frag, i) => {
				console.log('FRSG', frag);
				if ('' + frag.value === '' + vl) {
					console.log('FOUND');
					// Check if the 'info' field exists before trying to access its properties
					if (frag.info) {
						Object.keys(frag.info).forEach(e => {
							var o = {};
							o.label = e;
							o.value = frag.info[e];
							data.push(o);
						});
					}
				}
			});
			data.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
			console.log('getInfoViewer data', data);
		}

		return (
			<Box style={{ padding: gstate.isXS ? 4 : 16 }}
				sx={{ width: gstate.isXS ? 350 : 'auto', maxWidth: 800, marginTop: 3, padding: 4, border: '1px solid #ededed' }}>
				{data.map((el, i) => {
					return (
						<Box key={i}>  {/* Added a key here for React element optimization */}
							<Typography sx={{ marginBottom: 1, fontSize: 14, fontWeight: 700 }}>{el.label}</Typography>
							<Typography sx={{ marginBottom: 2.5, fontSize: 12 }}>{el.value}</Typography>
						</Box>
					);
				})}
			</Box>
		)
	}

	
	const handleEditorClose = (event) => {
		setOpenItemEditor( false);
		setState({
			...state,
			vlFirst: null,
			vlSecond: null,
		})
		if( props.fctOnClose)
			props.fctOnClose();
	};		
	
	
    return (		
		<Dialog 
			fullScreen
			classes={{
				paper: classes.newPosOfDialog
			}}
			className={classes.dialog}
			open={gstate.openItemEditor} 
			onClose={handleEditorClose} 
			TransitionComponent={Transition}
		>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleEditorClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6">
						{'Typen und Qualitäten bearbeiten'}
					</Typography>
				</Toolbar>
			</AppBar>
			
			<DialogContent>


			{props.data().first ?			
				<>
				{state.isLoadingFirst ?
					<div class="form-group" style={{padding:gstate.isXS ? 0 : 40}}>
						<WaitProgressCircular />
					</div>
					:
					<>
					<div class="form-group" style={{padding:gstate.isXS ? 0 : 40}}>
						<h2>{props.data().first.title}</h2>
						<QeeRadioGroup
							formSublabel={props.data().first.formSublabel}
							defaultValue={state.vlFirst}
							fctOnChange={setValueFirst}
							sdata={state.sdatasFirst}
						/>
						{ validationErrors[0] ? ( <div style={{textAlign:"center"}}><small id="selError" className="form-text error">{validationErrors[0]}</small></div> ) :( <></> ) }	
					</div>
					</>
				}	
				</>
				:
				<></>
			}					
				
				
			{props.data().second ? 				
				<>
				{state.isLoadingSecond && state.vlFirst ?
					<div class="form-group" style={{padding:gstate.isXS ? 0 : 40}}>
						<WaitProgressCircular />
					</div>
					:
					<>
					<div class="form-group" style={{padding:gstate.isXS ? 0 : 40}}>
						<QeeRadioGroup
							formLabel={props.data().second.title}
							formSublabel={props.data().second.formSublabel}
							defaultValue={state.vlSecond} 
							fctOnChange={setValueSecond}
							sdata={state.sdatasSecond}
						/>
						{ validationErrors[0] ? ( <div style={{textAlign:"center"}}><small id="selError" className="form-text error">{validationErrors[0]}</small></div> ) :( <></> ) }	
						
						{getInfoViewer( state.sdatasSecond, state.vlSecond)}
						
					</div>
					</>
				}
				</>
				:
				<></>
			}	

			</DialogContent>
			
			<DialogActions>
				<QeeColorButtonSecondary 
					variant="text" 
					onClick={handleEditorClose}
					sx={{
						color: (theme) => theme.palette.primary.main,
						'&.Mui-hovered': {
							color: (theme) => theme.palette.primary.main,
						},
					}}
				>
					Abbrechen
				</QeeColorButtonSecondary> 
				<QeeColorButtonPrimary 
					variant="contained" 
					onClick={submitValue}
				>
					Speichern
				</QeeColorButtonPrimary>
			</DialogActions>			
		</Dialog>
    )
}
export default QeeRadioGroupEditor;
