import React, { useState, useEffect } from "react";
import { makeStyles }  from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import moment from 'moment';

import style from "./Invoice.module.scss";

import { useAuth } from "../../../context/provider";
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../../../util/auth';

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from '../../qeeColorButton';

import { getProductImage } from "../utils/helpers";
import { postRequest } from "../utils/api";

export default function Invoice( props) {
	
	const { gstate } = useAuth();							
	
	const [formData, setFormData] = useState({});

	// some consts	
	const useStyles = makeStyles({
		//...props,	
		title: {
			fontWeight: 600
		}
	});	
	const classes = useStyles();						
	
	
	function handleFormChange(e) {
		const { name, value } = e.target;
		setFormData((prev) => {
			return { ...prev, [name]: value };
		});
	}
	
	
	const getUserName = () => {
		return gstate.usr.per_lastname + ' ' + gstate.usr.per_firstname;
	}
	
	
	/**
	*	Component for async showing the lic details
	*/
	const LicenseDetails = (props) => {
		
		const classes = useStyles();
		const [isLoading, setIsLoading] = useState( false);			
		
		const [lic, setLic] = useState( '');

				
		useEffect(() => {			
			async function getLic() {
				setIsLoading( true);
				let options = {};
				let invnr = props.invnr;
				options = {
					url: `/api/lic/${invnr}`,
					method: 'get'
				};
				setAxiosAuthToken();
				axios( options)
					.then((data) => {
						console.log( 'Lic data', data);
						//setLocalStorageAuthToken( response);
						setIsLoading( false);						
						setLic( data.data);
					})
					.catch((error) => {
						console.error( 'LicenseDetails ERR', error);
						setIsLoading( false);
					});						
			}
			getLic();
		}, [])

		
		return (
			<div>
				<Grid container direction="column">
					<Grid item style={{fontSize:14, textAlign:'left'}}>
						{isLoading ? <CircularProgress />
						:
						<table style={{width:'100%'}}>
							<tr><td style={{fontSize:16}}><strong>1 Lizenz {lic.name}</strong></td></tr>
							{lic.price ?
								<tr><td><strong>{lic.price} {lic.currency} / {lic.type_text}</strong></td></tr>							
							:''}
							<tr><td>{lic.desc}</td></tr>
							<tr><td>{'Aktivierungsdatum: '+moment( new Date()).format( 'DD.MM.YYYY')}</td></tr>
						</table>
						}
					</Grid>						
				</Grid>

			</div>
		)
	}	
	
//				<div className={style.title}>Ausgewähltes Produkt</div>	

	return (
		<div className={style.wrapper}>
			<form onChange={handleFormChange} style={{width:'100%'}}>
				<table>
					<tr>
						<td></td>
						<td>
							<div className={style.row}>
								<table>
								<tr>
								{!gstate.isXS ?
									<td>
										<img src={getProductImage()} className={style.productImage}></img>
									</td>
								: ''}
								<td style={{paddingLeft:20}}>								
									<LicenseDetails invnr='100100' />								
								</td>
								</tr>
								</table>
							</div>
						</td>
					</tr>
				</table>
								
				<div className={style.btnContainer}>
					<QeeColorButtonPrimary disableElevation 
						className={classes.button} 
						onClick={props.handleInvoiceOk}>
						Zur Zahlung
					</QeeColorButtonPrimary>												
				</div>
			</form>
		</div>
	);
}
