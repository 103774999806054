// sshotFacChartNewbuildCosts.js

import react, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles }  from '@material-ui/core/styles';
import axios from 'axios';
import queryString from 'query-string';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import DiagramDonutChart from '../components/diagramDonutChart';


import { useAuth } from "../context/provider";


const styles = (theme) => ({

});


const SshotFacChartNewbuildCosts = ( props) => {

	const { gstate, setObjEdit, setObjErrors, setIsLoading } = useAuth();				
			
	const [uiLoading, setUiLoading] = useState( true);
	const [showHint, setShowHint] = useState( false);		
		
	const {
        title = '',
        data,
        showPercent = false // Default to false
    } = props;
			
	const useStyles = makeStyles({
		...props,	
	});	
	const classes = useStyles( props);		

	
	const loadFac = async (id) => {
		console.log('--> loadFac', id);
		
		const authToken = localStorage.getItem('AuthToken');
		console.log('SshotFacDiagram* authToken', authToken);
		
		if (authToken == null) {
			console.log('<-- loadFac ERR: No AuthToken!');
			return;
		}
		
		setUiLoading(true);
		
		const fetchData = async () => {
			try {
				const options = {
					url: `/api/fac/${id}`,
					method: 'get',
					timeout: 10000					
				};
				
				axios.defaults.headers.common = { Authorization: `${authToken}` };
				const response = await axios(options);
				
				return response.data;
			} catch (error) {
				throw error;
			}
		};
		
		const retryFetch = async (retryCount) => {
			try {
				return await fetchData();
			} catch (error) {
				if (retryCount <= 0) {
					throw error;
				}
				
				console.log(`Retrying data fetch. Retries left: ${retryCount}`);
				return retryFetch(retryCount - 1);
			}
		};
		
		try {
			const facData = await retryFetch(3); // Retry fetching data 3 times
			facData.step = 0;
			setObjEdit(facData);
			setUiLoading(false);
			console.log('<-- loadFac');
		} catch (error) {
			setObjErrors(error.response ? error.response.data : 'An error occurred while fetching data');
			console.error(error);
			setUiLoading(false);
			console.log('<-- loadFac ERR', error);
		}
	};
	
	
	const prepareDonutFields = () => {

		let fac = gstate.objEdit;

		// Filter objects based on the structure where name starts with 'has_renovation_' and value is '1'
		const filteredNewBuilds = fac.fac_plan_newbuilds.filter(obj => {
			return obj.name.startsWith('has_renovation_') && obj.value === '1';
		});
		const filteredNewBuilds2 = fac.fac_plan_newbuilds2.filter(obj => {
			return obj.name.startsWith('has_renovation_') && obj.value === '1';
		});

		// Extracting labels and costs from the filtered arrays
		const newBuildsCostData = filteredNewBuilds.map(obj => ({
			label: obj.label,
			value: obj.support ? Math.max(obj.cost-obj.support, 0) : obj.cost
		}));
		const newBuilds2CostData = filteredNewBuilds2.map(obj => ({
			label: obj.label,
			value: obj.support ? Math.max(obj.cost-obj.support, 0) : obj.cost
		}));

		// Manually defined list of fields with label and field name
		const otherFields = [
			{ label: 'Maklerkosten', field: 'fac_broker_fee' },
			{ label: 'Notarkosten', field: 'fac_notary_fee' },
		];
		//fac.fac_broker_fee = 1200;
		//fac.fac_notary_fee = 2000;
		// Create otherCostData array based on the manually defined list of fields
		const otherCostData = otherFields.reduce((acc, field) => {
			if (fac.hasOwnProperty(field.field)) {
				acc.push({
					label: field.label, // Use label from otherFields
					value: fac[field.field] // Get value from fac object
				});
			}
			return acc;
		}, []);
		
		let ret = newBuildsCostData.concat(newBuilds2CostData, otherCostData);
		console.log('getDonutFields: ', ret);

		return ret;
	}	
	
	
	useEffect( () => {	
	
		// this page will be called from sshotMain.html with the syntax
		// http://localhost:3000/sshotFacChartNewbuildCosts?id=qwSSfuYO3lrZZVjYUn8R&authToken=eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkMjNmMzc0MDI1ZWQzNTNmOTg0YjUxMWE3Y2NlNDlhMzFkMzFiZDIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdnJlZWRpbnNpZ2h0LTFkNTVhIiwiYXVkIjoidnJlZWRpbnNpZ2h0LTFkNTVhIiwiYXV0aF90aW1lIjoxNjYzNzgwNzYzLCJ1c2VyX2lkIjoiQnJHZXd4YVh6WlJEYnlhMkdWZlJSUnVORnU4MyIsInN1YiI6IkJyR2V3eGFYelpSRGJ5YTJHVmZSUlJ1TkZ1ODMiLCJpYXQiOjE2NjM3ODA3NjMsImV4cCI6MTY2Mzc4NDM2MywiZW1haWwiOiJhZGlAcWFsZ28uZGUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiYWRpQHFhbGdvLmRlIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.sZYOCm5naV71c--XYyMCmtwblsbZS9s_TT7uA2zp7o0BHxlmPYdjSN5-F4uV0HHBxmLS-5Fui5b37Jsbl2I48weRFUQurWFXuZP9L0w1E6DvONQ4xpT21PAdBq0W7bxDRdo3x4qSyISh2WzoRu2wMrq3Noc8TSti2MpIeaCeuCfWrGYLZqAc-rMhYATXa95aPkypK4xvmilgcOIRhSg2HmyycKUCzCwVqowIXz1NOm7KqjChjvqozuIJWE8KGAErFopj7SGwBQvwkmBnohh-miefFaUG3iAMD0AGgmIw4NzsIiuqMClNb-GaWXeMkQcuOtIu8MeRWJieSDWM4yCuaw
		const queryString = window.location.search;
		console.log( 'sshotFacChartNewbuildCosts queryString', queryString);		
		const urlParams = new URLSearchParams( queryString);	
		localStorage.setItem( 'AuthToken', 'Bearer '+urlParams.get( 'authToken'));
		
		setShowHint( urlParams.get( 'p1')=='true' ? true : false);				
		
		loadFac( urlParams.get( 'id'));

	}, []);	
		
	
	return (	
		<>		
		{uiLoading == false ? 
			<div id="sshotContainer">
				<DiagramDonutChart 
					title={title}
					data={prepareDonutFields()} 
					showPercent={true}
					showHint={showHint}
				/>
			</div> 
		: 
			'Loading...'
		}		
		</>
	);
	
}
export default withStyles(styles)(SshotFacChartNewbuildCosts);

