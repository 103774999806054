// fac.js

import React, { Component } from 'react';
import react, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles }  from '@material-ui/core/styles';
import axios from 'axios';
import queryString from 'query-string';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import DiagramEnergyClass from '../components/diagramEnergyClass';


import { useAuth } from "../context/provider";


const styles = (theme) => ({

});


const SshotFacDiagramEnergyClass = ( props) => {

	const {
		titleIst = 'aktuell',
		titlePlan = 'geplant'
	} = props

	const { gstate, setObjEdit, setObjErrors, setIsLoading } = useAuth();				
			
	const [uiLoading, setUiLoading] = useState( true);
	const [showPlan, setShowPlan] = useState( false);
			
			
	const useStyles = makeStyles({
		...props,	
	});	
	const classes = useStyles( props);		

	
	const loadFac = async (id) => {
		console.log('--> loadFac', id);
		
		const authToken = localStorage.getItem('AuthToken');
		console.log('SshotFacDiagram* authToken', authToken);
		
		if (authToken == null) {
			console.log('<-- loadFac ERR: No AuthToken!');
			return;
		}
		
		setUiLoading(true);
		
		const fetchData = async () => {
			try {
				const options = {
					url: `/api/fac/${id}`,
					method: 'get',
					timeout: 10000
				};
				
				axios.defaults.headers.common = { Authorization: `${authToken}` };
				const response = await axios(options);
				
				return response.data;
			} catch (error) {
				throw error;
			}
		};
		
		const retryFetch = async (retryCount) => {
			try {
				return await fetchData();
			} catch (error) {
				if (retryCount <= 0) {
					throw error;
				}
				
				console.log(`Retrying data fetch. Retries left: ${retryCount}`);
				return retryFetch(retryCount - 1);
			}
		};
		
		try {
			const facData = await retryFetch(3); // Retry fetching data 3 times
			facData.step = 0;
			setObjEdit(facData);
			setUiLoading(false);
			console.log('<-- loadFac');
		} catch (error) {
			setObjErrors(error.response ? error.response.data : 'An error occurred while fetching data');
			console.error(error);
			setUiLoading(false);
			console.log('<-- loadFac ERR', error);
		}
	};

		
	useEffect( () => {	
	
		/*
		window.onmessage = function(e) {
			var payload = JSON.parse(e.data);
			localStorage.setItem( payload.key, payload.data);
		};
		*/

		// this page will be called from sshotMain.html with the syntax
		// http://localhost:3000/sshotFacDiagramEnergyClass?id=qwSSfuYO3lrZZVjYUn8R&authToken=eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkMjNmMzc0MDI1ZWQzNTNmOTg0YjUxMWE3Y2NlNDlhMzFkMzFiZDIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdnJlZWRpbnNpZ2h0LTFkNTVhIiwiYXVkIjoidnJlZWRpbnNpZ2h0LTFkNTVhIiwiYXV0aF90aW1lIjoxNjYzNzgwNzYzLCJ1c2VyX2lkIjoiQnJHZXd4YVh6WlJEYnlhMkdWZlJSUnVORnU4MyIsInN1YiI6IkJyR2V3eGFYelpSRGJ5YTJHVmZSUlJ1TkZ1ODMiLCJpYXQiOjE2NjM3ODA3NjMsImV4cCI6MTY2Mzc4NDM2MywiZW1haWwiOiJhZGlAcWFsZ28uZGUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiYWRpQHFhbGdvLmRlIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.sZYOCm5naV71c--XYyMCmtwblsbZS9s_TT7uA2zp7o0BHxlmPYdjSN5-F4uV0HHBxmLS-5Fui5b37Jsbl2I48weRFUQurWFXuZP9L0w1E6DvONQ4xpT21PAdBq0W7bxDRdo3x4qSyISh2WzoRu2wMrq3Noc8TSti2MpIeaCeuCfWrGYLZqAc-rMhYATXa95aPkypK4xvmilgcOIRhSg2HmyycKUCzCwVqowIXz1NOm7KqjChjvqozuIJWE8KGAErFopj7SGwBQvwkmBnohh-miefFaUG3iAMD0AGgmIw4NzsIiuqMClNb-GaWXeMkQcuOtIu8MeRWJieSDWM4yCuaw
		const queryString = window.location.search;
		console.log( 'SshotFacDiagramEnergyClass queryString', queryString);		
		const urlParams = new URLSearchParams( queryString);	
		localStorage.setItem( 'AuthToken', 'Bearer '+urlParams.get( 'authToken'));
	
		setShowPlan( urlParams.get( 'p1')=='true' ? true : false);
	
		loadFac( urlParams.get( 'id'));

	}, []);	
		
	
	return (	
		<>		
		{uiLoading == false ? 
			<div id="sshotContainer">
				<DiagramEnergyClass 
					showEnergyPlan={showPlan}
					energyIst='end_ist' 
					energyPlan='end_plan' 
					energyIstLabel={titleIst} 
					energyPlanLabel={titlePlan} 								
				/>
			</div> 
		: 
			'Lade Daten...'
		}		
		</>
	);
	
}
export default withStyles(styles)(SshotFacDiagramEnergyClass);

