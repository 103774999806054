import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useAuth } from "../context/provider";
import QeeHintBox from './qeeHintBox';
import { formatNumber } from '../util/helpers';

const DiagramBenchNewbuildCost = (props) => {
    const {
        objEdit,
        valuationObjName,
        title = '',
        valueLabel = '',
        avgLabel = '',
        showHint = false
    } = props;

    const { gstate } = useAuth();

    const [markerIstPx, setMarkerIstPx] = useState(0);
    const [avg, setAvg] = useState(0);
    const [markerValuePx, setMarkerValuePx] = useState(0);
    const [value, setValue] = useState(0);

    const diagram = useRef(null);

    const getBackgroundColor = () => '#30b33e';

    const useStyles = makeStyles(theme => ({
        gridDiagram: {
            marginTop: 30
        },
        colorBox: {
            height: 20,
            fontWeight: 700
        },
        infoLabel: {
            fontSize: '0.9em',
            color: '#444'
        },
        markerIst: {
            height: 130,
            width: 3,
            paddingLeft: 5,
            backgroundColor: '#777',
            position: 'relative',
            top: 110,
            left: markerIstPx
        },
        markerValue: {
            height: 80,
            width: 3,
            paddingLeft: 5,
            backgroundColor: '#222',
            position: 'relative',
            top: 30,
            left: markerValuePx
        },
        markerLabelIst: {
            paddingLeft: 5,
            fontWeight: 600,
            color: '#555'
        },
        markerLabelValue: {
            paddingLeft: 5,
            fontWeight: 700,
        },
        markerLabelValueRed: {
            paddingLeft: 5,
            fontWeight: 700,
            color: '#a22'
        },
        markerLabelValueGreen: {
            paddingLeft: 5,
            fontWeight: 700,
            color: '#30b33e'
        },
        widget: {
            position: 'relative',
            top: title ? 0 : -60,
        }
    }));
    const classes = useStyles(props);

    useEffect(() => {
        if (objEdit && objEdit[valuationObjName] && objEdit[valuationObjName].valueRange) {
            console.log('DiagramValuation useEffect objEdit', objEdit);

            const { valueRange } = objEdit[valuationObjName];
            const w = diagram.current ? diagram.current.offsetWidth : 0;

            const { value, lower, upper } = valueRange;
            const avg = lower + (upper - lower) * 0.5;
            const pxIst = (avg - lower) * w / (upper - lower);
            const pxValue = (value - lower) * w / (upper - lower);

            setAvg(avg);
            setMarkerIstPx(pxIst);
            setValue(value);
            setMarkerValuePx(value > upper ? w : value < lower ? 0 : pxValue);
        }
    }, [diagram.current, objEdit, valuationObjName]);

    const getHint = () => objEdit[valuationObjName]?.hint;

    return (
        <div style={{ width: '100%', maxWidth: 1200 }}>
            {title && <h2>{title}</h2>}
            <Grid container direction="row">
                <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.widget}>
                        <div className={classes.markerIst}><div className={classes.markerLabelIst}>{avgLabel}</div><div className={classes.markerLabelIst}> {formatNumber(avg, 'EUR', 0)}</div></div>
                        <div className={classes.markerValue}>
                            {(() => {
                                if (value > avg)
                                    return <><div className={classes.markerLabelValueRed}>{valueLabel}</div><div className={classes.markerLabelValueRed}> {formatNumber(value, 'EUR', 0)}</div></>
                                else if (value < avg)
                                    return <><div className={classes.markerLabelValueGreen}>{valueLabel}</div><div className={classes.markerLabelValueGreen}> {formatNumber(value, 'EUR', 0)}</div></>
                                else
                                    return <><div className={classes.markerLabelValue}>{valueLabel}</div><div className={classes.markerLabelValue}> {formatNumber(value, 'EUR', 0)}</div></>
                            })()}
                        </div>
                        <Box style={{ maxWidth: 800 }}>
                            <Grid container direction="column" className={classes.gridDiagram} columns={12} ref={diagram}>
                                <Grid item>
                                    <Box className={classes.colorBox} style={{ backgroundColor: getBackgroundColor() }} />
                                </Grid>
                                <Grid item>
                                    <Box style={{ display: 'flex', width: '100%' }}>
                                        <div style={{ width: '50%' }}>
                                            <div className={classes.infoLabel}>Minimalwert</div>
                                            <div className={classes.infoLabel}>{formatNumber(objEdit[valuationObjName]?.valueRange?.lower, 'EUR', 0)}</div>
                                        </div>
                                        <div style={{ width: '50%', textAlign: 'right' }}>
                                            <div className={classes.infoLabel}>Maximalwert</div>
                                            <div className={classes.infoLabel}>{formatNumber(objEdit[valuationObjName]?.valueRange?.upper, 'EUR', 0)}</div>
                                        </div>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </Grid>
                {showHint && (
                    <Grid item xs={12} sm={12} md={6} style={{ padding: gstate.isXS ? 0 : 20 }}>
                        <QeeHintBox type="info" text={<React.Fragment><strong>Preisinformation:</strong> <span dangerouslySetInnerHTML={{ __html: getHint() }}></span></React.Fragment>} />
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

export default DiagramBenchNewbuildCost;
