// QeePromotionProvider
import React, { createContext, useMemo, useReducer, useContext, useRef } from 'react';

import PromotionDialog from './promotionDialog';
import { useAuth } from "../context/provider"; 


const PromotionContext = createContext();

const QeePromotionProvider = (props) => {
	
	const { gstate } = useAuth();					
	
	const refPromotionDialog = useRef(null);			
	
	const setOpenPromotionDialog = ( url) => {
		refPromotionDialog.current.handlePromotionOpen( url);
	};

	const value = useMemo(() => {   
		return { setOpenPromotionDialog };	  	  
	}, []);	
	
	return (
		<PromotionContext.Provider value={value}>
			<PromotionDialog 
				ref={refPromotionDialog} 
			/>	
			{props.children}	
		</PromotionContext.Provider>
	);

}

const usePromotion = () => useContext( PromotionContext );
export { PromotionContext, usePromotion }
export default QeePromotionProvider;
	

