import React from "react";
import react, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { useAuth } from "../context/provider";

const useStyles = makeStyles(theme => ({
	button: {
		color: theme.palette.primary.main,
		fontWeight: 700
	},
}));


const ButtonCounter = ( props) => {
	
	const classes = useStyles();
	
	const displayCounter = true;
	
	/**
	* 	Workaround to be able to use this comp from another app. 
	*	The ext app must provide initilaized elems gstate and setObjEdit as props (aen-20230331).
	*/
	function useAuthData() {
		
		var auth = useAuth();
		
		if( props.gstate) {
			const gstate = props.gstate;
			const setObjEdit = props.setObjEdit;		
			return { gstate, setObjEdit };			
		} else {
			const { gstate, setObjEdit } = auth;			
			return { gstate, setObjEdit };
		}
	}
	const { gstate, setObjEdit } = useAuthData();   // avoid hook limitations	
	
	
	//const [state, setState] = useState( {counter: gstate.objEdit[props.field] ? gstate.objEdit[props.field] : props.defaultValue});			
	const [state, setState] = useState( {counter: props.objData[props.field] ? props.objData[props.field] : props.defaultValue});			
	
	const fctIncrement = (cnt) => {		
		cnt += props.step;
		if( cnt > props.max)
			cnt = props.max;		
		return cnt;	
	}
	
	const fctDecrement = (cnt) => {		
		cnt -= props.step;
		if( cnt < props.min)
			cnt = props.min;		
		return cnt;	
	}	
	
	const handleIncrement = () => {
		console.log( 'handleIncrement');
		var incrValue = fctIncrement( state.counter)
		setState(state => ({ counter: incrValue }));
		console.log( '>>> field', props.field);
		/*
		setObjEdit({
			...gstate.objEdit,
			[props.field]: incrValue
		})
		*/
		props.setObjData({
			...props.objData,
			[props.field]: incrValue
		})	
		
		if( props.onChange)
			props.onChange( incrValue);
	};

	const handleDecrement = () => {
		console.log( 'handleDecrement');
		var decrValue = fctDecrement( state.counter)		
		setState(state => ({ counter: decrValue }));
		/*
		setObjEdit({
			...gstate.objEdit,
			[props.field]: decrValue
		})
		*/
		props.setObjData({
			...props.objData,
			[props.field]: decrValue
		})		
		
		if( props.onChange)
			props.onChange( decrValue);		
	};	
	
	/*
	useEffect(() => {
		//setObjData( "new data"); //Calling setter here to update

		props.setObjData({
			...props.objData,
			[props.field]: state.counter
		})				
	}, [props.setObjData, state.counter]);	
	*/
		
	return (
		<div style={{textAlign:'left'}}>	
		<div class="" style={{marginBottom:10}}>{props.label}</div>	
			<ButtonGroup size="big" aria-label="big outlined button group">
				<Button onClick={handleIncrement}><AddIcon className={classes.button}/></Button>
				{displayCounter && <Button  style={{width:100}}>{state.counter}</Button>}
				{displayCounter && <Button onClick={handleDecrement}><RemoveIcon className={classes.button}/></Button>}
			</ButtonGroup>
		</div>
    );
	
}
export default ButtonCounter;