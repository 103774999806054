import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread2";

class ChatController {
  constructor(option) {
    _defineProperty(this, "state", void 0);
    _defineProperty(this, "defaultOption", {
      delay: 300,
	  parentForm: null,  // Add this line
      parentId: null     // Add this line	  
    });
    _defineProperty(this, "emptyAction", {
      request: {
        type: 'empty'
      },
      responses: [],
      onResnponsed: [],
      response: {} // aen: Initialize the response object here	  
    });
    _defineProperty(this, "defaultActionRequest", {
      always: false,
      addMessage: true
    });
    this.state = {
      option: _objectSpread(_objectSpread({}, this.defaultOption), option),
      messages: [],
      action: this.emptyAction,
      actionHistory: [],
      onMessagesChanged: [],
      onActionChanged: []
    };
  }

  addMessage(message) {
    return new Promise(resolve => {
      setTimeout(() => {
        const len = this.state.messages.push(message);
        const idx = len - 1;
        this.state.messages[idx].createdAt = new Date();
        this.callOnMessagesChanged();
        resolve(idx);
      }, this.state.option.delay);
    });
  }

  updateMessage(index, message) {
    if (message !== this.state.messages[index]) {
      const { createdAt } = this.state.messages[index];
      this.state.messages[index] = message;
      this.state.messages[index].createdAt = createdAt;
    }

    this.state.messages[index].updatedAt = new Date();
    this.callOnMessagesChanged();
  }

  removeMessage(index) {
    this.state.messages[index].deletedAt = new Date();
    this.callOnMessagesChanged();
  }

  getMessages() {
    return this.state.messages;
  }

  setMessages(messages) {
    this.clearMessages();
    this.state.messages = [...messages];
    this.callOnMessagesChanged();
  }

  clearMessages() {
    this.state.messages = [];
    this.callOnMessagesChanged();
  }

  callOnMessagesChanged() {
    this.state.onMessagesChanged.forEach(h => h(this.state.messages));
  }

  addOnMessagesChanged(callback) {
    this.state.onMessagesChanged.push(callback);
  }

  removeOnMessagesChanged(callback) {
    const idx = this.state.onMessagesChanged.indexOf(callback);
    this.state.onActionChanged[idx] = () => {};
  }
  
  // aen-20240303: Method to set the entire response object
  setResponse(newResponse) {
    this.state.action.response = newResponse;
  }
  // Method to add a new field/value pair to the response object
  addResponseField(fieldName, value) {
	if (!this.state.action.response) {
	  this.state.action.response = {}; // Safeguard: Initialize if not present
	}
	this.state.action.response[fieldName] = value;
  }
  // Method to get the current response object
  getResponse() {
	let resp = {};
    if( this.state.action.response)	
		resp = this.state.action.response;
    return resp;
  }  

  setActionRequest(request, onResponse) {
    const action = {
      request: _objectSpread(_objectSpread({}, this.defaultActionRequest), request),
      responses: [],
      onResnponsed: []
    };

    return new Promise((resolve, reject) => {
      if (!request.always) {
        const returnResponse = response => {
          if (!response.error) {
            resolve(response);
          } else {
            reject(response.error);
          }
        };

        action.onResnponsed.push(returnResponse);
      }

      if (onResponse) {
        action.onResnponsed.push(onResponse);
      }

      this.state.action = action;
      this.state.actionHistory.push(action);
      this.callOnActionChanged(action.request);

      if (request.always) {
        resolve({
          type: 'text',
          value: 'dummy'
        });
      }
    });
  }

  cancelActionRequest() {
    this.state.action = this.emptyAction;
    this.callOnActionChanged(this.emptyAction.request);
  }

  getActionRequest() {
	  console.log( 'this.state.action', this.state.action);
    const { request, responses } = this.state.action;
    if (!request.always && responses.length > 0) {
      return undefined;
    }
    return request;
  }

  async setActionResponse(request, response) {
    const { request: origReq, responses, onResnponsed } = this.state.action;
console.log( '>>>>> request', request);
console.log( '>>>>> origReq', origReq);
console.log( '>>>>> response', response);
    if (request !== origReq) {
//      throw new Error('Invalid action.');   <-- aen-20240309 cmted out, is ok?
    }
    if (!request.always && onResnponsed.length === 0) {
      throw new Error('onResnponsed is not set.');
    }
    responses.push(response);
    this.callOnActionChanged(request, response);
    if (request.addMessage) {		
      await this.addMessage({
        //type: 'text',		  
		type: (response.type==='select' ? 'text' : response.type),   // aen-20240723 for p2p keept the type, for chatbot, always 'text'
        content: response.value,
        self: true,
		questionType: response.type,       // aen-20240220 for identifying the questions made through a select 
		answerType: response.answerType,   // aen-20240220 for the type (predefined, openai)
		answer: response.answer,		       // aen-20240220 for standard answer	
		quickQuestions: response.quickQuestions,
		uploadId: response.uploadId // Include the uploadId
      });
    }
    onResnponsed.forEach(h => h(response));
  }

  getActionResponses() {
    return this.state.action.responses;
  }

  callOnActionChanged(request, response) {
    this.state.onActionChanged.forEach(h => h(request, response));
  }

  addOnActionChanged(callback) {
    this.state.onActionChanged.push(callback);
  }

  removeOnActionChanged(callback) {
    const idx = this.state.onActionChanged.indexOf(callback);
    this.state.onActionChanged[idx] = () => {};
  }

  getOption() {
    return this.state.option;
  }
}

export { ChatController };
