// facType.js
import react, { useState, useEffect, useLayoutEffect } from 'react';
import { faHome, faBuilding, faMap, faIndustry } from '@fortawesome/fontawesome-free-solid'

import { useAuth } from "../context/provider";

import Box from './Box';

const FacType = ( props) => { 
	
	const thisStep = props.step;	
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();				
	
	const [validationErrors, setValidationErrors] = useState( []);	

	console.log( 'THIS STEP', thisStep);	
	console.log( 'objEdit.step', gstate.objEdit.step);		
		
		
	useEffect(()=> {  
		console.log( 'useEffect', thisStep);
		
		const nextBtn = document.getElementById( 'next-button')
		if( thisStep == gstate.objEdit.step) {
			nextBtn.onclick=null;
			nextBtn.onclick=(e) => {
				console.log( 'Set validation in FacType');								
				if( validate( e) > 0) {
					e.stopPropagation();
				} 
				console.log( 'objEdit: ', gstate.objEdit)				
			}    
		} 	

		const prevBtn = document.getElementById( 'prev-button')
		if( thisStep == gstate.objEdit.step) {
			prevBtn.onclick=null;
			prevBtn.onclick=(e) => {
				if( props.fctGotoPrevStep) {
					e.stopPropagation();
					props.jumpToStep( props.fctGotoPrevStep( thisStep));
				}
				console.log( 'objEdit: ', gstate.objEdit)				
			}    
		} 				
								
	}, [JSON.stringify( gstate.objEdit)]);		
		
		
	/**
	* validate() - 
	*/
	const validate = (e) => { 
		
		console.log( '--> validate for FacType in step: ' + gstate.objEdit.step)
					
		var errs = false;		
		const validationErrors= [null];
		if( gstate.objEdit.fac_type == '') 
			validationErrors[0] = 'Du musst einen Immobilientyp auswählen!';		

		if( validationErrors[0]) {
			//alert( validationErrors[0]);			
			errs = true;
			setValidationErrors( validationErrors); 			
		} 		
		
		console.log( '<-- validate: ' + errs)		
		return errs;
	}		
		
	/*
	<li><Box {...props} type="box" step="0" name="plot" title='Grundstück' icon={faMap} /></li>	
	<li><Box {...props} type="box" step="0" name="office" title='Gewerbe' icon={faIndustry} /></li>			
	*/
    return (
        <div class="form-group">
			<h1 class="title2" style={{marginTop: gstate.isXS ? 10 : 60}}>Um welche Art von Immobilie geht's?</h1>
			<div class="navi-horiz">
				<ul style={{padding:0}}>
					<li><Box {...props} type="box" step="0" name="fac_type" value="100" size="2x" title='Haus' icon={faHome} /></li>				
					<li><Box {...props} type="box" step="0" name="fac_type" value="200" size="2x" title='Wohnung' icon={faBuilding} /></li>							
				</ul>
			</div>
			{ 
				validationErrors[0] ? (
					<div style={{textAlign:"center"}}><small id="selError" className="form-text error">{validationErrors[0]}</small></div>					
				) :(
					<></>
				)
			}	
        </div>
    )
}
export default FacType;