import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemIcon, TextField, Avatar, CircularProgress, Box, Typography } from '@material-ui/core';
import Badge from '@mui/material/Badge';
import moment from 'moment';

import { 
	getUsrChats, 
	getUsrAsReceiverChats 
} from '../api/service';
import { useAuth } from '../context/provider';
import { formatDate } from '../util/helpers';
import ChatsEmpty from './chatsEmpty';

const useStyles = makeStyles((theme) => ({
    title: {
        paddingLeft: theme.spacing(0.5),
        marginTop: 0
    },
    searchBar: {
        marginBottom: theme.spacing(2),
    },
    list: {
        overflowY: 'auto',
        height: 'calc(100vh - 100px)', // Adjust height as needed
    },
    listItemText: {
        width: '100%', // Ensure the Box takes the full width
    },
    dateText: {
        marginLeft: 'auto', // Push the date to the far right
    },
    selectedItem: {
        backgroundColor: theme.palette.action.selected, // Use the selected background color from the theme
    }
}));

const UsrChats = forwardRef(({ onChatSelect, filters, title }, ref) => {
    const classes = useStyles();
    const { gstate } = useAuth();
    const [chats, setChats] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [selectedChatId, setSelectedChatId] = useState(null);

	const fetchChats = async (filter) => {
		console.log('--> fetchChats', filter);
		setLoading(true);
		try {
			const [response, receiverResponse] = await Promise.all([
				getUsrChats(filter),
				getUsrAsReceiverChats(filter)
			]);

			if (!response.err && !receiverResponse.err) {
				console.log('chats results', response, receiverResponse);
				// Combine both responses
				const combinedChats = [...(response ? response : []), ...(receiverResponse ? receiverResponse : [])];
				setChats(combinedChats);
			}
			console.log('<-- fetchChats', (response.length + receiverResponse.length));
		} catch (error) {
			console.error('<-- fetchChats ERR', error);
		} finally {
			setLoading(false);
		}
	};

    useEffect(() => {
        const usr = JSON.parse(sessionStorage.getItem('usr'));
        if (!usr) {
            console.error('No user data!');
            return;
        }
        fetchChats(filters);
    }, [filters]);

    useEffect(() => {
        if (chats.length > 0 && !selectedChatId) {
            setSelectedChatId(chats[0].id);
            onChatSelect(chats[0]);
        }
    }, [chats, onChatSelect]);

    const handleChatSelect = async (chat) => {
        setSelectedChatId(chat.id);
        await onChatSelect(chat);
        fetchChats(filters);
    };

    useImperativeHandle(ref, () => ({
        refreshChats: () => fetchChats(filters)
    }));

    const filteredChats = chats.filter(chat => {
        return chat.chat_receiver_org_name && chat.chat_receiver_org_name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const getFormattedDate = (isoDate) => {
        return formatDate(isoDate, moment(isoDate).isSame(moment(), 'day') ? 'synapcusTime' : 'shortTimeDate');
    };

    return (
        <Box>
            <Typography variant="h3" className={classes.title}>{title}</Typography>
            <TextField
                className={classes.searchBar}
                label="Suchen nach Name"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            ) : (
                filteredChats.length === 0 ? (
                    <ChatsEmpty />
                ) : (
                    <List className={classes.list}>
                        {filteredChats.map(chat => (
                            <ListItem
                                button
                                key={chat.id}
                                onClick={() => handleChatSelect(chat)}
                                className={chat.id === selectedChatId ? classes.selectedItem : ''}
                            >
                                <ListItemIcon>
                                    <Badge badgeContent={chat.chat_msgs_unread_count} color="newFeature">
                                        <Avatar>{chat.chat_receiver_org_name.charAt(0)}</Avatar>
                                    </Badge>
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Box className={classes.listItemText} display="flex" justifyContent="space-between" alignItems="center">
                                            <span>{chat.chat_receiver_org_name}</span>
                                            <Typography variant="body2" color="textSecondary" className={classes.dateText}>
                                                {getFormattedDate(chat.qee_creation_date)}
                                            </Typography>
                                        </Box>
                                    }
                                    secondary={`${chat.chat_fac_name} ${chat.chat_parent_name}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                )
            )}
        </Box>
    );
});

export default UsrChats;
