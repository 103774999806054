import React from "react";
import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

import QeeTooltip from './qeeTooltip';

import { useAuth } from "../context/provider";

// styling
const getBackground = ( props) => {
	if( props.type == 'info')
		return '#e1effd';
	if( props.type == 'help')
		return '#eef';		
	else if( props.type == 'warning')
		return '#fbde80';
	else if( props.type == 'license')
		return '#9c27b0';		
	else if( props.type == 'sponsor')
		return '#FFD8FF';				
}


const getColor = ( props) => {
	if( props.type == 'info')
		return '#444';
	else if( props.type == 'help')
		return '#444';		
	else if( props.type == 'warning')
		return '#444';
	else if( props.type == 'license')
		return '#fff';		
	else if( props.type == 'sponsor')
		return '#9c27b0';				
}	


const getMaxWidth = ( props) => {
	if( props.isXS)
		return 340;
	
	return 500;						
}	



// some consts
const useStyles = makeStyles( (theme) => ({
	container: {		
		backgroundColor: (props) => getBackground( props, theme),
		padding:10,
		minHeight: 100,
		//minWidth: 320,
		maxWidth: (props) => getMaxWidth( props),
		fontSize: theme.typography.fontSize,
		fontWeight: theme.typography.fontWeightMedium,		
		color: (props) => getColor( props, theme),
		borderRadius: 5
	},
	icon: {
		//color:theme.typography.success.color,   //theme.palette.primary.main,
		fontWeight: 800,
		color: theme.palette.primary.main
	},
	iconHelp: {
		color: theme.palette.primary.main,
		fontWeight: 800,
	},				
	iconWarning: {
		color:'#f00',   //theme.palette.primary.main,
		fontWeight: 800,
	},		
	iconLicense: {
		color:'#fff',   //theme.palette.primary.main,
		fontWeight: 800,
	},				
	iconSponsor: {
		color:'#9c27b0',  
		fontWeight: 800,
	},				
}));			


const QeeHintBox = ( props) => {
	
	console.log( 'QeeHintBox', props);
	
    const [expanded, setExpanded] = useState(false);

	let htmlText = props.text;
	let truncatedText = props.plainText;
	if( props.plainText) {		
		if (typeof props.plainText === 'string' && props.plainText.length > 128) {
			truncatedText = props.plainText.substring(0, 128) + '...';
		}
		htmlText = props.plainText;
	}

	const handleClick = (event) => {
        event.preventDefault(); // Prevents the default behavior of the link
        setExpanded(!expanded);
		console.log( '>>>> truncatedText', truncatedText)
    };
	
	// get the styling	
	const theme = useTheme();
	const classes = useStyles( props, theme); 
	console.log('>>> Theme:', theme);			
			
	return (
		<div className={classes.container} style={props.style}>
			<Grid container direction="row">
			<Grid item xs={1}>
				{props.type == 'warning' ?
					<WarningAmberOutlinedIcon className={classes.iconWarning} sx={{fontSize:24, marginRight:2}}/>
				: ''}
				{props.type == 'info' ?				
					<LightModeOutlinedIcon className={classes.iconHelp} sx={{fontSize:24, marginRight:2}}/>
				: ''}
				{props.type == 'help' ?				
					<HelpOutlinedIcon className={classes.icon} sx={{fontSize:24, marginRight:2}}/>
				: ''}				
				{props.type == 'license' ?				
					<UpgradeIcon className={classes.iconLicense} sx={{fontSize:32, marginRight:2}}/>
				: ''}				
				{props.type == 'sponsor' ?				
					<VolunteerActivismIcon className={classes.iconSponsor} sx={{fontSize:32, marginRight:2}}/>
				: ''}								
			</Grid>			
			<Grid item xs={11}>
				
				{props.plainText ? (
					<Typography variant="p">
						{expanded ? props.plainText : (props.showShortText ? truncatedText : props.plainText)}
						{props.showShortText && (
							<Link component="button" variant="body2" 
								onClick={handleClick} 
								style={{ fontWeight: 'bold', marginLeft: '5px' }}>
								{expanded ? '  Weniger' : '  Mehr...'}
							</Link>
						)}
					</Typography>
				) : (
					htmlText
				)}
				
            </Grid>
			</Grid>
		</div>
    );
	
}
export default QeeHintBox;