import react, { useState, useEffect, useLayoutEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonBase from '@material-ui/core/ButtonBase';

import { getDocs, getDocsNet, getSdatas } from "../api/service";

import imageMissing from '../assets/image-missing.jpg';
import house from '../assets/villa-sample.jpg';
import apartment from '../assets/apartment-sample.jpg';

import { useAuth } from "../context/provider";
import { usePicturesGallery } from "../components/qeePicturesGalleryProvider"; 

const FacImage = ( props) => {
	
	console.log( 'FacImage props', props.fac);
	
	const { setOpenPicturesGalleryDialog } = usePicturesGallery();																		
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();					
	const [isLoading, setIsLoading] = useState( false);
			
	
	// some consts
	const useStyles = makeStyles( theme => ({
		imgGrid: {
			backgroundImage:`url(${imageMissing})`,
			backgroundRepeat: 'no-repeat',
		},	
		pictureDiv: {
			width:'100%', 
			height:gstate.isXS ? 'auto' : 500, 
			borderRadius: 5, 
			backgroundColor: '#eee',
			marginRight: 10,			
		},		
		picture: {
			width:'100%', 
			height:gstate.isXS ? 'auto' : 500, 
			borderRadius: 5, 
			marginRight: 10,
			objectFit: 'cover'			
		},		
	}));			
	const classes = useStyles( props);	
	
	const [url, setUrl] = useState( '...');
	
	
	const showGallery = async () => {
		
		setIsLoading( true);
		
		var pcs = [];
					
		// get all the picture files from storage
		const docs = await getDocs( props.fac.id, 'sdoc_type', 200);	
		if( !docs.err) {
			console.log( 'FacForm showGallery getDocs', docs);			
			for( var i=0; i<docs.length; i++) {
				var pic = {};
				pic.url = docs[i].signedUrl;
				let nm = docs[i].metadata.metadata ? docs[i].metadata.metadata.sdoc_name : docs[i].name;	
				pic.caption = nm;

				pcs.push( pic);		
			}			
		}
		
		if( gstate.objEdit.fac_pictures)
			pcs = pcs.concat( gstate.objEdit.fac_pictures);
					
		setIsLoading( false);							
		
		setOpenPicturesGalleryDialog( pcs, 0);
	}	
	
	
	useEffect(() => {
		
		async function _getImage() {
			
			setIsLoading( true);
			
			var u;					
			
			// first try to get the first '100' picture files from storage
			const docs = await getDocs( props.fac.id, ['sdoc_type','sdoc_subtype'], [200, 100]);	
			console.log( 'FacImage getDocs', docs);			
			if( !docs.err && docs.length) {					
				u = docs[0].signedUrl;
				
			// if nothing found, try to show some internet pics	
			} else {								
					
				// has some internet pics
				if( props.fac.fac_pictures && props.fac.fac_pictures.length) {
					u = props.fac.fac_pictures[0].url;					
					
				// last choice - show the dummy pic	
				} else {
					if( props.fac.fac_type+'' == '100')
						u = house;
					else	
						u = apartment;
				}
			}	

			setUrl( u);									
			
			setIsLoading( false);
		}
		
		_getImage();
		
	}, [])

	
	return (
		<div className={classes.imgGridxxx}>
		{isLoading ?
			<>
			<div style={{textAlign:'center'}}>
				<CircularProgress style={{position: 'relative', width:50, zIndex: '1000', left:-250, top:70}}/>	
			</div>
			</>
		:	
			<ButtonBase onClick={showGallery} style={props.pictureDivStyle}>
				{/*<div style={props.pictureDivStyle}>*/}
				<img style={props.pictureStyle} 
					src={url}
					referrerPolicy='same-origin'
					alt="Immobilien Photo" 			
					onClick={() => {}} 
				/>
				{/*</div>*/}
			</ButtonBase>
		}
		</div>
	)
}
export default FacImage;


