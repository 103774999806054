import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { useFileUploader } from "../components/qeeFileUploaderProvider";
import { useConfluence } from "../components/qeeConfluenceProvider";
import { usrRight } from '../util/helpers';
import { useAuth } from "../context/provider";

import FacOrgsForm from './facOrgsForm';
import QeeToolsMenu from './qeeToolsMenu';
import QeeTooltip from './qeeTooltip';
import { QeeColorButtonPrimary, QeeColorButtonSecondary } from './qeeColorButton';
import FacRightInfo from './facRightInfo';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(0),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0.5)
		}
	},
	form: {
		marginTop: theme.spacing(0),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(0.2)
		}
	},
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	button: {
		textTransform: 'none',
		width: 250,
		height: 60
	},
	buttonLabel: {
		whiteSpace: 'nowrap',
		width: 240,
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	}
});

const useStyles = makeStyles(styles);

const FacOrgs = (props) => {
	const { setOpenConfluenceDialog } = useConfluence();
	const { setOpenFileUploaderDialog } = useFileUploader();
	const { gstate } = useAuth();

	const [state, setState] = useState({
		uiLoading: false,
		reloadData: false
	});

	const handleSetReloadData = (vl) => {
		console.log('FacOrgs.handleSetReloadData', vl);
		setState({
			...state,
			reloadData: vl
		});
	};

	useEffect(() => {
		// Hide the body scrollbar
		document.body.style.overflow = 'hidden';

		// Cleanup function to show the scrollbar again when the component is unmounted
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);
	
	const classes = useStyles(props);

	return (
		state.uiLoading ? (
			<div className={classes.root}>
				{state.uiLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>
		) : (
			<main className={classes.content}>
				<form className={classes.form} noValidate>
					{/*
					<Box
						margin={gstate.isXS ? 1 : 0}
						display="flex"
						justifyContent="flex-start"
						alignItems="flex-start"
						sx={12}
					>
						<FacRightInfo fac={gstate.objEdit} isXS={gstate.isXS} />
						<QeeToolsMenu />
						<QeeTooltip title="Wissensassistent starten">
							<QeeColorButtonSecondary
								disableElevation
								className={classes.button}
								style={{ width: 50 }}
								onClick={() => setOpenConfluenceDialog()}>
								<HelpOutlinedIcon />
							</QeeColorButtonSecondary>
						</QeeTooltip>
					</Box>
					*/}
					<FacOrgsForm
						fac={gstate.objEdit}
						reloadData={state.reloadData}
						handleSetReloadData={handleSetReloadData}
					/>
				</form>
			</main>
		)
	);
};

export default withStyles(styles)(FacOrgs);
