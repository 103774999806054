// QeeConfluenceProvider
import React, { createContext, useMemo, useReducer, useContext, useRef } from 'react';

import ConfluenceDialog from './confluenceDialog';
import { useAuth } from "../context/provider"; 


const ConfluenceContext = createContext();

const QeeConfluenceProvider = (props) => {
	
	const { gstate } = useAuth();					
	
	const refConfluenceDialog = useRef(null);			
	
	const setOpenConfluenceDialog = ( url) => {
		refConfluenceDialog.current.handleConfluenceOpen( url);
	};

	const value = useMemo(() => {   
		return { setOpenConfluenceDialog };	  	  
	}, []);	
	
	return (
		<ConfluenceContext.Provider value={value}>
			<ConfluenceDialog 
				ref={refConfluenceDialog} 
			/>	
			{props.children}	
		</ConfluenceContext.Provider>
	);

}

const useConfluence = () => useContext( ConfluenceContext );
export { ConfluenceContext, useConfluence }
export default QeeConfluenceProvider;
	

