// usrLicenseDialog.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { useImperativeHandle } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import QeeSlideDialog from './qeeSlideDialog';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';

import css from '../core/App.css';

import { useAuth } from "../context/provider";
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';



const styles = (theme) => ({
});


// main fct
const ConfluenceDialog = React.forwardRef((props, forwardedRef) => {	

	const refBrowserForm = useRef(null);	

	const { gstate, setOpenQeeDialog, setIsFetching } = useAuth();				
	
	const [state, setState] = useState({
		url: '',
		submitDisabled: true,
	});

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);
	
	
	const handleConfluenceOpen = ( url) => {
		
		if( !url)
			url = '';
		
		const baseUrl = 'https://propertec.scrollhelp.site/immobilien';
		console.log( 'handleConfluenceOpen', url);
		setState({
			url: baseUrl + url
		})	
		setOpenQeeDialog( {open:true, id:'dlgConfluence'});
	}	
	
	
	const handleConfluenceClose = () => {
		setOpenQeeDialog( false);	
	};

	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		ali: ()=>{alert(123)},
		handleConfluenceOpen: handleConfluenceOpen,						
		handleConfluenceClose: handleConfluenceClose
	}));	
	

	useEffect(() => {

		console.log( 'ConfluenceDialog.useEffect');
								
	}, [])	
			
		
	/*************************************************************
	*
	*	BrowserForm() - component for browser framing
	*
	*************************************************************/
	const BrowserForm = React.forwardRef((props, forwardedRef) => {		
	//const BrowserForm = (props) => {
		
		const offset = 80;
		const [url, setUrl] = useState( props.url);  
		const [height, setHeight] = useState( window.innerHeight>offset ? window.innerHeight-offset : offset);  
		
	
		function handleWindowSizeChange() {
			setHeight( window.innerHeight>offset ? window.innerHeight-offset : offset);
		}	
	
	
		useEffect( () => {	
			
			window.addEventListener( 'resize', handleWindowSizeChange);
			return () => {
				window.removeEventListener( 'resize', handleWindowSizeChange);
			}
													
		}, [height]);		

				
		// some consts
		const useStyles = makeStyles( theme => ({
			browserForm: {
				//padding: 10,				
			},
			locationText: {
				color: '#444'
			},

		}));			
		const classes = useStyles( props);
		console.log( 'BrowserForm classes', classes);
				
										
		return (
			<div style={{marginTop:0, border:'1px solid #eee', borderRadius:5}}>
				<iframe src={url} width={'100%'} height={height} frameBorder={'0'}></iframe>
			</div>
		)
		
	});

		
	return (		
		<>
		<div ref={forwardedRef}></div>	
		
			<QeeSlideDialog 
				id='dlgConfluence'
				title='Wissen' 
				formComponent={
					<BrowserForm 
						ref={refBrowserForm} 						
						url={state.url} 						
					/>
				}	
				hideDialogAppBar={true}
				hideDialogActions={true}
				submitDisabled={true}
				paddingContent={0}
			>
			</QeeSlideDialog>		
		</>	
	)
})	
export default withStyles(styles)(ConfluenceDialog);