import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, CardMedia, Typography, Button, CircularProgress, Grid } from '@mui/material';
import QeeArticleForm from './qeeArticleForm';
import { formatNumber } from '../util/helpers';

import { useAuth } from '../context/provider';

const QeeArticle = ({ artId, onOpenDialog }) => {
	const [articleData, setArticleData] = useState(null);
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		const fetchArticle = async () => {
			try {
				const response = await axios.get(`/api/art/${artId}`);
				setArticleData(response.data);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching article:', error);
				setLoading(false);
			}
		};

		fetchArticle();
	}, [artId]);

	
	const handleOpenArticleDialog = () => {
		onOpenDialog(artId);
	};

	
	return (
		<div>
			{loading ? (
				<CircularProgress />
			) : articleData ? (
				<Card style={{ height: 500, maxWidth: 500, margin: 'auto', backgroundColor:'#ffffef' }}>
					<CardMedia
						component="img"
						height={250}
						image={articleData.art_image_url}
						alt={articleData.art_name}
						style={{ objectFit: 'contain', cursor: 'pointer' }}
						onClick={handleOpenArticleDialog}
					/>
					<CardContent style={{ display: 'flex', flexDirection: 'column' }}>
						<Typography gutterBottom variant="h5" component="div">
							<a href="#" onClick={handleOpenArticleDialog}>
								{articleData.art_name}
							</a>
						</Typography>
						<Typography variant="body2" color="text.secondary"
								style={{
									display: '-webkit-box',
									WebkitLineClamp: 2,
									WebkitBoxOrient: 'vertical',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
						>
							{articleData.art_desc_short}
						</Typography>
						<Typography variant="body2" color="text.primary" fontWeight="bold" style={{ marginLeft: 'auto', marginTop: 5 }}>
							{!articleData.art_price || isNaN(articleData.art_price) ? (
								<>Preis: auf Anfrage</>
							) : articleData.art_price_is_min ? (
								<>Preis ab: {formatNumber(articleData.art_price, articleData.art_currency, 2)}</>
							) : (
								<>Preis: {formatNumber(articleData.art_price, articleData.art_currency, 2)}</>
							)}
						</Typography>					
						<Typography variant="caption" component="caption" style={{ marginLeft: 'auto'}}>
							{articleData.art_tax_included ? 
								articleData.art_tax_included_text ?? ''
								:'-'
							}						
						</Typography>


						<Button variant="contained" color="primary" onClick={handleOpenArticleDialog}  style={{ marginLeft: 'auto', marginTop:15 }}>
							Details
						</Button>
					</CardContent>
				</Card>
			) : (
				<p>Artikel nicht gefunden.</p>
			)}
		</div>
	);
};

export default QeeArticle;
