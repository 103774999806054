// fac.js in client REACT!

/**
* facDef - model definition
*/
export const facDef = () => {
	
	console.log( 'facDef');
	
	return [ 
	    { "name":"form", 										init:"fac" },	                      
       	{ "name":"qee_unid", 				"type":"string", 	init:"" },
	    { "name":"step", 					"type":"number",	init:0 },	        
       	{ "name":"fac_owner", 				"type":"string", 	init:"" },       			
       	{ "name":"fac_name", 				"type":"string", 	init:"" },       	
       	{ "name":"fac_desc", 				"type":"string", 	init:"" },       			
       	{ "name":"fac_start", 				"type":"date"}, 
       	{ "name":"fac_end", 				"type":"date"},
       	{ "name":"fac_ownership", 			"type":"string", 	init:"" },       					
		{ "name":"fac_type", 				"type":"string", 	init:"" },       									
		{ "name":"fac_house_type", 			"type":"string", 	init:"" },       									
		{ "name":"fac_apartment_type", 		"type":"string", 	init:"" },       							
		{ "name":"fac_prj_type", 			"type":"string", 	init:"" },       									
		{ "name":"fac_living_area", 		"type":"number", 	},       									
		{ "name":"fac_rooms_count", 		"type":"number", 	init:0 },       				
		{ "name":"fac_floors_count", 		"type":"number", 	init:0 },  		
		{ "name":"fac_plot_area", 			"type":"number", 	},  	
		{ "name":"fac_persons_count", 		"type":"number", 	init:0 },  		
       	{ "name":"fac_construction_year", 	"type":"string", 	init:'2007'},				
      	{ "name":"addr_geo_lat_o", 			"type":"number" }, 
       	{ "name":"addr_geo_lng_o", 			"type":"number" },
       	{ "name":"addr_street_o" },
       	{ "name":"addr_extra_o" },
       	{ "name":"addr_street_zip_o", 		"type":"string"},
       	{ "name":"addr_locality_o" },
       	{ "name":"addr_country_o" },
       	{ "name":"addr_country_o_text" },
       	{ "name":"addr_state_o" },
       	{ "name":"addr_hpc_o" },
       	{ "name":"addr_pobox_o" },
       	{ "name":"addr_pobox_zip_o" },
       	{ "name":"addr_pobox_city_o" },		
       	{ "name":"fac_floors_count", 		"type":"number",   init:1 },  
		{ "name":"fac_plan_newbuilds",   	"type":"array",    init:[]},
		{ "name":"fac_plan_newbuilds2",   	"type":"array",    init:[]},		
		{ "name":"fac_renovations",     	"type":"array",    init:[]},
		{ "name":"fac_plan_renovations",   	"type":"array",    init:[]},
		{ "name":"fac_plan_renovations2",  	"type":"array",    init:[]},		
		{ "name":"fac_energy",     			"type":"object",   init:{}},		
		{ "name":"fac_price",     			"type":"object",   init:{}},
		{ "name":"fac_valuation",       	"type":"object",   init:{}},
		{ "name":"fac_investment",       	"type":"object",   init:{}},		
	];		 

}	
//});


/**
* facInit - instantiate new empty fac object
*/
export const facInit = () => {
	
	console.log( 'facInit');	
	
	var fac = {};

	try {
	
		// get the model definition
		var facDefArr = facDef();
		//console.log( 'facDefArr', facDefArr);
		for( var i=0; i<facDefArr.length; i++) {
			//console.log( 'facDef item', facDefArr[i]);
			if( facDefArr[i].init != undefined)
				fac[ facDefArr[i].name] = facDefArr[i].init;
		}	
		
		// set some important fields
		fac.qee_creation_date = new Date().toISOString();		
	
	} catch( e) {
		console.error( 'facInit ERR', e)	
	}
	
	return fac;
	
}	



