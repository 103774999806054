// FacForm.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { Dimensions, Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import FacOwnership from './facOwnership';
import FacType from './facType';
import FacGAddress from './facGAddress';
import FacAreas from './facAreas';
import FacNameDesc from './facNameDesc';
import FacRenovationWiz from './facRenovationWiz';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';


import StepZilla from "react-stepzilla";

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	button: {
		textTransform: 'none'
	}
});


// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


// main fct
const FacRenovations = ( props) => {
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();			
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
		screenDims: {width:0, height:0},
		openRenovation: false,
		openValuation: false,		
	})

	
	useEffect( () => {	

		console.log( 'use effect for', gstate.objEdit);
												
	}, []);		

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);				
	
	
	// dialog operations
	const DialogTitle = withStyles(styles)((props) => {
		const { children, classes, onClose, ...other } = props;
		return (
			<MuiDialogTitle disableTypography className={classes.root} {...other}>
				<Typography variant="h6">{children}</Typography> 
				{onClose ? (
					<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
		);
	});

	
	const DialogContent = withStyles((theme) => ({
		viewRoot: {
			padding: theme.spacing(2)
		}
	}))(MuiDialogContent);	
	
	
	const handleRenovationOpen = () => {
		setState({
			...state,
			openRenovation: true
		});		
	}	
	
	const handleRenovationClose = () => {
		setState({ 
			...state,
			openRenovation: false 
		});
	};
		
	const handleRenovationSubmit = (event) => {
		
		console.log( 'handleRenovationSubmit', event);
		
		//authMiddleWare(this.props.history);
		const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login');	
		if( event && event.preventDefault)		
			event.preventDefault();
		
		const newFac = gstate.objEdit;
		console.log( 'submit obj', newFac);		
		let options = {};
		let id = gstate.objEdit.id;
		options = {
			url: `/api/fac/${id}`,
			method: 'put',
			data: newFac
		};
		
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios(options)
			.then((data) => {
				console.log( 'Submited data', data);
				//setObjEdit(data);
				/* do nothing
				setState({ 
					...state,
					open: false 
				});
				window.location.reload();
				*/
			})
			.catch((error) => {
				setState({ 
					...state,
					open: true, 
					errors: error.response.data 
				});
				setObjErrors( error.response.data);
				console.log(error);
			});
	};
	
	
	const handleRenovationCalculate = async (event) => {
		
		console.log( 'handleRenovationCalculate', event);
		
		//authMiddleWare(this.props.history);
		const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login');	
		if( event && event.preventDefault)		
			event.preventDefault();
		
		const newFac = gstate.objEdit;
		console.log( 'calculate obj', newFac);		
		let options = {};
		let id = gstate.objEdit.id;
		options = {
			url: `/api/fac/renovation/ist/${id}`,
			method: 'put',
			data: newFac
		};		
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		try {
			const retFac = await axios( options);
			console.log( 'Calculated fac', retFac.data);
			setObjEdit( retFac.data);
		} catch( error) {
			setState({ 
				...state,
				open: true, 
				errors: error.response.data 
			});
			setObjErrors( error.response.data);
			console.log(error);
		};		
		//	.then(() => {
				/* do nothing
				setState({ 
					...state,
					open: false 
				});
				window.location.reload();
				*/
		//	})
		/*
			.catch((error) => {
				setState({ 
					...state,
					open: true, 
					errors: error.response.data 
				});
				setObjErrors( error.response.data);
				console.log(error);
			});
		*/
	};	
	
	
	const handleRenovationSubmitEnd = (event) => {
		setState({ 
			...state,
			open: false 
		});
		window.location.reload();		
	}
	
	
	const handleValuationOpen = () => {
		setState({
			...state,
			openValuation: true
		});		
	}	
	
	const handleValuationClose = () => {
		setState({ 
			...state,
			openValuation: false 
		});
	};		
	
	
	return (	
		state.uiLoading ? (
			<div className={classes.root}>
				{state.uiLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (
			<main className={classes.content}>								
				<form className={classes.form} noValidate>
					<QeeColorButtonPrimary disableElevation 
						className={classes.button} 
						onClick={handleRenovationOpen}>
						Sanierungsassistent
					</QeeColorButtonPrimary>				
					<QeeColorButtonPrimary disableElevation 
						className={classes.button} 
						onClick={handleValuationOpen}>
						Wertermittlung
					</QeeColorButtonPrimary>									
					<FacNameDesc />
					<FacGAddress />
					<FacAreas />		
				</form>	

				<Dialog 
					fullScreen
					classes={{
						paper: classes.newPosOfDialog
					}}
					className={classes.dialog}
					open={state.openRenovation} 
					onClose={handleRenovationClose} 
					TransitionComponent={Transition}
				>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton edge="start" color="inherit" onClick={handleRenovationClose} aria-label="close">
								<CloseIcon />
							</IconButton>
							<Typography variant="h6" className={classes.fac_name}>
								{'Sanierungsassistent für ' + gstate.objEdit.fac_name}
							</Typography>
						</Toolbar>
					</AppBar>	
					<FacRenovationWiz 
						fctSubmit={handleRenovationSubmit} 
						fctRenovationCalculate={handleRenovationCalculate} 						
						fctSubmitEnd={handleRenovationSubmitEnd} />
				</Dialog>				
			</main>	
		)		
	)
}	
export default withStyles(styles)(FacRenovations);