import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardActions from '@material-ui/core/CardActions';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import LabelEnergyClass from './labelEnergyClass';
import {formatNumber} from '../util/helpers';


const useStyles = makeStyles( theme => ({
  resultCard: {
	display: 'flex',
	width: '100%',
	height: 120,
	padding: 10,
	marginBottom: 10,
	borderRadius: 5,
	border: `1px solid ${theme.palette.primary.main}`,	
	backgroundColor: theme.palette.primary.contrastText,
  },
  textIst: {
	color: theme.palette.primary.main,   //theme.typography.error.color,
	fontWeight: 800,
  },
  textPlan: {
	color: theme.typography.success?.color,
	fontWeight: 800,
  },
  iconGreen: {
	color: theme.palette.primary.main,
  },
  iconRed: {
	color: 'red',
  },
  iconGrey: {
	color: 'grey',
  },
  boxPercentage: {
	backgroundColor: theme.palette.secondary.main,
	color: theme.palette.secondary.dark,
	border: 'solid 1px ' + theme.palette.secondary.dark,
	padding: 5,
	margin: 10,
	width: 72,
	display: 'inline-block',
	textAlign: 'center',
	fontWeight: 900,
  },
  boxPercentageGreen: {
	backgroundColor: 'green',
	color: theme.palette.primary.contrastText,
	//border: 'solid 1px ' + theme.palette.secondary.main,
	padding: 5,
	margin: 10,
	width: 72,
	display: 'inline-block',
	textAlign: 'center',
	fontWeight: 900,
	borderRadius: 5,
  },
  boxPercentageRed: {
	backgroundColor: 'red',
	color: theme.palette.primary.contrastText,
	//border: 'solid 1px ' + theme.palette.secondary.main,
	padding: 5,
	margin: 10,
	width: 72,
	display: 'inline-block',
	textAlign: 'center',
	fontWeight: 900,
	borderRadius: 5,
  },
}));


const FacCompEnergyResultDiffCard = ( props) => {
	
	// get the styling	
	const theme = useTheme();
	const classes = useStyles( props); 
	console.log('>>> Theme:', theme);				
	

	const getIstPlanPercentage = ( ist, plan) => { 
		var ret = 0;
		if( ist)
			ret = (1 - plan/ist); 
		
		if( plan===0 && ist>0)
			ret = 1;
		
		ret = formatNumber( ret, '%', 1) + '%';
		
		return ret; //ret.toFixed(1) + '%'
	}	
	
	
  return (
    <Card variant="outlined" className={classes.resultCard}>
      <React.Fragment>
        <CardContent style={{ padding: props.objEdit.isXS ? 0 : '', paddingBottom: 20 }}>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }} color="text.primary" gutterBottom style={{ whiteSpace: !props.objEdit.isXS ? 'nowrap' : '' }}>
            {props.title}
          </Typography>
          <Grid container direction="row" style={{ width: props.objEdit.isXS ? 350 : '' }}>
            <Grid item xs={3} xl={4}>
              <Typography sx={{ fontSize: 12 }} className={classes.textIst} gutterBottom>
                vorher
              </Typography>
              <Grid container direction="row">
                <Grid item>
                  <Typography style={{ fontSize: 20, fontWeight: 800 }} className={classes.textIst}>
                    {props.objEdit.fac_energy[props.fieldIst]}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: 10, marginLeft: 5 }}>
                  {props.bShowEnergyclass && <LabelEnergyClass xs={true} gstate={props.gstate} obj={props.objEdit} energy={props.fieldIst} />}
                </Grid>
              </Grid>
            </Grid>
            {!props.objEdit.isXS && (
              <Grid item xs={2} xl={2}>
                <Box>
                  {props.objEdit.fac_energy[props.fieldIst] > props.objEdit.fac_energy[props.fieldPlan] ? (
                    <TrendingDownIcon sx={{ fontSize: 48 }} className={classes.iconGreen} />
                  ) : props.objEdit.fac_energy[props.fieldIst] < props.objEdit.fac_energy[props.fieldPlan] ? (
                    <TrendingUpIcon sx={{ fontSize: 48 }} className={classes.iconRed} />
                  ) : (
                    <TrendingFlatIcon sx={{ fontSize: 48 }} className={classes.iconGrey} />
                  )}
                </Box>
              </Grid>
            )}
            <Grid item xs={3} xl={4}>
              <Typography sx={{ fontSize: 12 }} className={classes.textPlan} gutterBottom>
                nachher
              </Typography>
              <Grid container direction="row">
                <Grid item>
                  <Typography style={{ fontSize: 20, fontWeight: 800 }} className={classes.textPlan}>
                    {props.objEdit.fac_energy[props.fieldPlan]}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: 10, marginLeft: 5 }}>
                  {props.bShowEnergyclass && <LabelEnergyClass xs={true} gstate={props.gstate} obj={props.objEdit} energy={props.fieldPlan} />}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} xl={2}>
              <Box>
                {props.objEdit.fac_energy[props.fieldIst] > props.objEdit.fac_energy[props.fieldPlan] ? (
                  <Box className={classes.boxPercentageGreen}>{getIstPlanPercentage(props.objEdit.fac_energy[props.fieldIst], props.objEdit.fac_energy[props.fieldPlan])}</Box>
                ) : props.objEdit.fac_energy[props.fieldIst] < props.objEdit.fac_energy[props.fieldPlan] ? (
                  <Box className={classes.boxPercentageRed}>{getIstPlanPercentage(props.objEdit.fac_energy[props.fieldIst], props.objEdit.fac_energy[props.fieldPlan])}</Box>
                ) : (
                  <Box className={classes.boxPercentage}>{getIstPlanPercentage(props.objEdit.fac_energy[props.fieldIst], props.objEdit.fac_energy[props.fieldPlan])}</Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions></CardActions>
      </React.Fragment>
    </Card>
  );
};

export default FacCompEnergyResultDiffCard;
