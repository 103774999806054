import React, { useState, useCallback } from 'react';
import { Box, Button, Icon } from '@mui/material';
import { AudioMediaRecorder } from '../audio-media-recorder';

export function MuiAudioInput({ chatController, actionRequest }) {
  const [audioRec] = useState(AudioMediaRecorder.getInstance());
  const [stopped, setStopped] = useState(true);
  const [audio, setAudio] = useState();

  const handleError = useCallback((error) => {
    const value = {
      type: 'audio',
      value: error.message,
      error
    };
    chatController.setActionResponse(actionRequest, value);
  }, [actionRequest, chatController]);

  const handleStart = useCallback(async () => {
    try {
      await audioRec.initialize();
      await audioRec.startRecord();
      setStopped(false);
    } catch (error) {
      handleError(error);
    }
  }, [audioRec, handleError]);

  const handleStop = useCallback(async () => {
    try {
      const a = await audioRec.stopRecord();
      setAudio(a);
      setStopped(true);
    } catch (error) {
      handleError(error);
    }
  }, [audioRec, handleError]);

  const sendResponse = useCallback(() => {
    if (audio) {
      const value = {
        type: 'audio',
        value: 'Audio',
        audio
      };
      chatController.setActionResponse(actionRequest, value);
      setAudio(undefined);
    }
  }, [actionRequest, audio, chatController]);

  const sendButtonText = actionRequest.sendButtonText ? actionRequest.sendButtonText : 'Send';

  return (
    <Box
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        '& > *': {
          flex: '1 1 auto',
          minWidth: 0
        },
        '& > * + *': {
          ml: 1
        }
      }}
    >
      {stopped && (
        <Button
          type="button"
          onClick={handleStart}
          disabled={!stopped}
          variant="contained"
          color="primary"
          startIcon={<Icon>keyboard_voice</Icon>}
        >
          Rec start
        </Button>
      )}
      {!stopped && (
        <Button
          type="button"
          onClick={handleStop}
          disabled={stopped}
          variant="contained"
          color="primary"
          startIcon={<Icon>stop</Icon>}
        >
          Rec stop
        </Button>
      )}
      <Button
        type="button"
        onClick={sendResponse}
        disabled={!audio}
        variant="contained"
        color="primary"
        startIcon={<Icon>send</Icon>}
      >
        {sendButtonText}
      </Button>
    </Box>
  );
}
