import React, { useState } from 'react';
import { makeStyles, useTheme, Tooltip, Box, Avatar, Typography, Paper, IconButton, Button, Table, TableBody, TableRow, TableCell, TableContainer, Toolbar, Menu, MenuItem, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChatIcon from '@material-ui/icons/Chat';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { SAC_STATE_COLORS, SAC_TYPE_SYMBOLS } from '../models/sacConfig';
import { format as formatFns, isValid, parseISO } from 'date-fns';
import { useAuth } from "../context/provider";
import { QeeColorButtonSecondary, QeeColorButtonPrimary } from './qeeColorButton';
import QeeSlideDialog from './qeeSlideDialog';
import QeeSingleFieldEditor from './qeeSingleFieldEditor';
import QeeTooltip from './qeeTooltip';
import { sacSave, sacDelete } from '../api/service';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import QeeArticleForm from './qeeArticleForm';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(2),
        flexWrap: 'wrap',
    },
    actionButton: {
        marginRight: theme.spacing(1),
        height: '36px', // Ensure consistent height
        width: '50px',
        color: theme.palette.primary.main, // Use the theme's primary color
        display: 'flex',
        alignItems: 'center', // Ensure vertical alignment
    },
    icon: {
        color: theme.palette.primary.main,
        width: 32,
    },  
    header: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    avatarContainer: {
        position: 'relative',
        marginRight: theme.spacing(2),
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    typeSymbol: {
        position: 'absolute',
        bottom: 10,
        right: 5,
        transform: 'translate(50%, 50%)',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        borderRadius: '50%',
        padding: theme.spacing(0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    details: {
        marginLeft: 20,
        flexDirection: 'column',
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    subTitle: {
        fontSize: 16,
        color: theme.palette.text.secondary,
    },
    stateText: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0.3, 1),
        marginTop: theme.spacing(0.5),
        color: theme.palette.common.white,
        display: 'inline-block',
        width: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxWidth: 1000,
    },
    fieldLabel: {
        fontWeight: 600,
        marginRight: theme.spacing(1),
    },
    fieldValue: {
        color: theme.palette.text.secondary,
    },
    buttonLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    descParagraph: {
        whiteSpace: 'pre-line',
    },
    sublabel: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    overdue: {
        color: theme.typography.error.color,
		marginLeft: 10,
		position: 'relative',
		top: 5
    },	
}));

const SacGeneral = () => {
    const classes = useStyles();
    const theme = useTheme();
    const confirm = useConfirm();
    const { gstate, setOpenFacShareDialog, setObjEdit, setOpenQeeDialog, setCurrSubpage } = useAuth();
    const sac = gstate.objEdit;
    const sacTypeIcon = SAC_TYPE_SYMBOLS[sac?.sac_type] || null;

    const { setOpenConfluenceDialog } = useConfluence();

    const [changesDialog, setChangesDialog] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingState, setLoadingState] = useState(null);
    const [selectedArtId, setSelectedArtId] = useState(null);
    
    const navigate = useNavigate();    

    const getStateValue = (state) => {
        if (!sac.sac_state_all) return '';
        const stateObj = sac.sac_state_all.find((s) => s.key === state);
        return stateObj ? stateObj.value : '';
    };

    const getStateStyle = (state) => {
        const backgroundColor = SAC_STATE_COLORS[state] || '#000';
        const textColor = '#fff';
        return { backgroundColor, color: textColor, width: 'auto' };
    };

	const getItemValue = (item, sac) => {
		try {
			const expression = item.replace(/['"]/g, ''); // Remove single or double quotes
			const value = eval(expression);
			return value;
		} catch (e) {
			console.error('getItemValue ERR', e);
			return '';
		}
	};

    const safeFormatDate = (date, formatStr) => {
        const parsedDate = parseISO(date);
        if (isValid(parsedDate)) {
            return formatFns(parsedDate, formatStr);
        }
        return formatFns(new Date(), formatStr);
    };

	const config = [
		{ 
			label: 'Erstellt von:', 
			sublabel: 'Initiator', 
			item_1: "`${sac.sac_sender_name}`" 
		},
		{ 
			label: 'An:', 
			sublabel: 'Empfänger', 
			item_1: "`${sac.sac_receiver_org_name}`",
			cond: "sac.sac_receiver_org_name",		// set only by a direct order through the map	
		},
		{ 
			label: 'Datum:', 
			sublabel: 'Ausführungsdatum', 
			item_1: "`${sac.sac_date_limit}`", 
			fctFormat_1: (value) => safeFormatDate(value, 'dd.MM.yyyy'), 
			btnEdit_1: "dlgDate" 
		},
		{ 
			label: 'Zeit:', 
			sublabel: 'Ausführungszeit', 
			item_1: "`${sac.sac_date_limit}`", 
			fctFormat_1: (value) => safeFormatDate(value, 'HH:mm'), 
			btnEdit_1: "dlgTime" 
		},
		{ 
			label: 'Dauer geschätzt:', 
			sublabel: 'Geschätzte Dauer in Stunden', 
			item_1: "`${sac.sac_duration}`" 
		},
		{ 
			label: 'Immobilie:', 
			sublabel: 'Referenz Immobilie für diese Aktivität', 
			item_1: "`${sac.sac_fac_id}`", 
			cond: "sac.sac_fac_id && sac.sac_fac_id !== '0'", 
			btnNavigate: true 
		},
		{ 
			label: 'Artikel:', 
			sublabel: 'Angefragter Artikel vom Shop', 
			item_1: "`${sac.sac_art_name} (Artikel-Nr. ${sac.sac_art_inventory_id})`", 
			cond: "sac.sac_art_id && sac.sac_art_id !== '0'",   // set only by direct order through a shop-article
			btnOpenDialog: "dlgArticle"
		}     
	];

    const handleOpenDialog = (dialogId) => {
        setOpenQeeDialog({ open: true, id: dialogId });
    };

    const handleChangesDialog = (changes) => {
        setChangesDialog(changes);
    };

    const handleSave = async () => {
        try {
            const updatedSac = await sacSave({ ...sac, ...changesDialog });
            setObjEdit(updatedSac);
            setChangesDialog({});
        } catch (error) {
            console.error('Error saving SAC:', error);
        }
    };

    const handleDelete = async () => {
        confirm({
            title: "Anfrage löschen",
            description: "Hierdurch werden alle Daten für diese Anfrage, einschließlich verschachtelter Daten, endgültig gelöscht.",
            confirmationText: "Löschen starten",
            cancellationText: "Abbrechen",
            confirmationButtonProps: { color: "error", variant: "contained", style: { textTransform: 'none' } },
            cancellationButtonProps: { color: "success", style: { textTransform: 'none' } }
        })
        .then(async () => {
            setIsLoading(true);
            setLoadingState('delete');
            
            await sacDelete(sac.id);
            navigate('/sacs');
        })
        .finally(() => {
            setIsLoading(false);
            setLoadingState(null);
        })
        .catch(() => {
            setIsLoading(false);
            setLoadingState(null);
        });
    };

    const handleStateChange = async (state) => {
        if (state === 10000) {
            confirm({
                title: "Aktivität stornieren",
                description: "Möchtest du diese Aktivität wirklich stornieren?",
                confirmationText: "Ja",
                cancellationText: "Nein",
                confirmationButtonProps: { color: "error", variant: "contained", style: { textTransform: 'none' } },
                cancellationButtonProps: { color: "success", style: { textTransform: 'none' } }
            })
            .then(() => {
                performStateChange(state);
            })
            .catch(() => {});
        } else {
            performStateChange(state);
        }
    };

    const performStateChange = async (state) => {
        setIsLoading(true);
        setLoadingState(state);
        try {
            const updatedSac = await sacSave({ ...sac, sac_state: state });
            setObjEdit(updatedSac);
        } catch (error) {
            console.error('Failed to update SAC state', error);
        } finally {
            setIsLoading(false);
            setLoadingState(null);
        }
    };

    const handleMenuClick = (event) => {
        event.stopPropagation(); // Prevent the card click from triggering
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleActionSelect = (stateKey) => {
        setLoadingState(stateKey);
        handleCloseMenu();
        handleStateChange(stateKey);
    };

    const handleOpenArticleDialog = (artId) => {
        setSelectedArtId(artId);
        handleOpenDialog("dlgArticle");
    };
    
    /**
    *   DlgNameComp() - to avoid rerenders in the parent
    */
    const DlgNameComp = React.memo((props) => {
        
        const [hasErrors, setHasErrors] = useState(true);                
        const [changes, setChanges] = useState({});        
                
        const handleHasErrors = (hasErrors) => {
            console.log('handleHasErrors hasErrors', hasErrors);
            setHasErrors(hasErrors);
        };                  
        const handleChanges = (changes) => {
            setChanges(changes);
        };

        return(
            <QeeSlideDialog 
                id='dlgName'
                title='Bezeichnung' 
                formComponent={
                    <QeeSingleFieldEditor
                        fieldType={'TextField'}
                        title={'Bezeichnung der Aktivität'}
                        label={'Bezeichnung'}
                        placeholder={'Kurze Bezeichnung für die Aktivität'}    
                        maxLength={42}                        
                        vl={sac.sac_name}
                        fctOnHasErrors={handleHasErrors}
                        fctOnChanges={handleChanges}
                    />                        
                }    
                submitDisabled={hasErrors}            
                fctOnSubmit={async () => {
                    let updatedSac = await sacSave( {...sac, sac_name: changes.text});
                    setObjEdit(updatedSac);
                    setOpenQeeDialog({ open: false, id: null });
                }}>
            </QeeSlideDialog>                                                
        );      
    });        


    /**
    *   DlgBodyComp() - to avoid rerenders in the parent
    */
    const DlgBodyComp = React.memo((props) => {
        
        const [hasErrors, setHasErrors] = useState(true);                
        const [changes, setChanges] = useState({});        
                
        const handleHasErrors = (hasErrors) => {
            console.log('handleHasErrors hasErrors', hasErrors);
            setHasErrors(hasErrors);
        };                  
        const handleChanges = (changes) => {
            setChanges(changes);
        };

        return(
            <QeeSlideDialog 
                id='dlgBody'
                title='Beschreibung' 
                formComponent={
                    <QeeSingleFieldEditor
                        fieldType={'TextFieldMultiline'}
                        title={'Ausführliche Beschreibung'}
                        label={'Beschreibung'}
                        placeholder={'Beschreibung für die Aktivität'}    
                        maxLength={1024}                        
                        vl={sac.sac_body}
                        fctOnHasErrors={handleHasErrors}
                        fctOnChanges={handleChanges}
                    />                        
                }    
                submitDisabled={hasErrors}            
                fctOnSubmit={async () => {
                    let updatedSac = await sacSave( {...sac, sac_body: changes.text});
                    setObjEdit(updatedSac);
                    setOpenQeeDialog({ open: false, id: null });
                }}>
            </QeeSlideDialog>                                                
        );      
    });        

	const isOverdue = (sac, dateString) => {
		return sac.sac_state < 300 && new Date() > new Date(dateString);
	};

    return (
        <div className={classes.root}>
            <Toolbar className={classes.toolbar}>
                {sac?.sac_state_available && sac.sac_state_available.length > 0 && (
                    <Tooltip title="Status der Aktivität ändern">
                        <QeeColorButtonPrimary
                            disableElevation 
                            className={classes.actionButton} 
                        >
                            <IconButton onClick={handleMenuClick} disabled={isLoading} className={classes.icon} style={{color:'#fff'}}>
                                {isLoading && loadingState === 'menu' ? <CircularProgress size={24} /> : <MoreHorizIcon />}
                            </IconButton>                        
                        </QeeColorButtonPrimary>
                    </Tooltip>
                )}
                
                <Tooltip title="Zugehörige Nachrichten für diese Aktivität öffnen">
                    <QeeColorButtonSecondary 
                        disableElevation 
                        className={classes.actionButton} 
                        onClick={() => setCurrSubpage('loadSacChat')}
                    >
                        <IconButton disabled={isLoading} className={classes.icon}>
                            {isLoading && loadingState === 'chat' ? <CircularProgress size={24} /> : <ChatIcon />} 
                        </IconButton>                        
                    </QeeColorButtonSecondary>                                
                </Tooltip>                          
            
                <Tooltip title="Aktivität löschen">
                    <QeeColorButtonSecondary 
                        disableElevation 
                        className={classes.actionButton} 
                        onClick={handleDelete}
                    >
                        <IconButton onClick={handleDelete} disabled={isLoading} className={classes.icon}>
                            {isLoading && loadingState === 'delete' ? <CircularProgress size={24} /> : <DeleteIcon />}
                        </IconButton>                        
                    </QeeColorButtonSecondary>                                
                </Tooltip>             
                
                <Tooltip title="Wissensassistent starten">
                    <QeeColorButtonSecondary 
                        disableElevation 
                        className={classes.actionButton} 
                        onClick={() => setOpenConfluenceDialog('/allgemein')}
                    >
                        <IconButton className={classes.icon}>
                            <HelpOutlinedIcon className={classes.icon}/>
                        </IconButton>
                    </QeeColorButtonSecondary>
                </Tooltip>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {sac?.sac_state_available ?
                    sac.sac_state_available.map((state) => (
                        <MenuItem key={state.key} onClick={(e) => { e.stopPropagation(); handleActionSelect(state.key); }}>
                            {state.value}
                        </MenuItem>
                    )) : ''}
                </Menu>
            </Toolbar>
            <Box className={classes.header}>
                <Tooltip title={`Initiator: ${sac.sac_sender_name}`}>
                    <Box className={classes.avatarContainer}>
                        <Avatar className={classes.avatar} src={`/path/to/profile/images/${sac.sac_sender_id}.jpg`} />
                        {sacTypeIcon && (
                            <Box className={classes.typeSymbol}>
                                {sacTypeIcon}
                            </Box>
                        )}
                    </Box>
                </Tooltip>
                <Box className={classes.details}>
                    <Typography className={classes.title}>
                        {sac.sac_name}
                        <IconButton size="small" onClick={() => handleOpenDialog('dlgName')}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Typography>
                    <Typography className={classes.subTitle}>{sac.sac_fac_name}</Typography>
                    <Box className={classes.stateText} style={getStateStyle(sac.sac_state)}>
                        {getStateValue(sac.sac_state)}
                    </Box>
						{isOverdue(sac, sac.sac_date_limit) && 
							<Tooltip title={'Diese Aktivität ist überfällig'}>
								<AccessTimeIcon className={`${classes.overdue}`} />
							</Tooltip>
						}					
                </Box>
            </Box>

            <TableContainer component={Paper} className={classes.paper}>
                <Table>
                    <TableBody>
                        {config.map((item, index) => (
                            (!item.cond || eval(item.cond)) && ( // Check if condition is met or not defined
                                <TableRow key={index}>
                                    {!gstate.isXS ? (
                                        <>
                                            <TableCell width="250px" component="th" scope="row" sx={{ padding: 2 }}>
                                                <Typography variant="lbl" style={{ fontWeight: 700 }}>{item.label}</Typography>
                                                {item.sublabel && <Typography className={classes.sublabel}>{item.sublabel}</Typography>}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 600 }}>
                                                <Typography variant="body2">
                                                    {item.fctFormat_1 ? item.fctFormat_1(getItemValue(item.item_1, sac)) : getItemValue(item.item_1, sac)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell width="50px">
                                                {item.btnEdit_1 && (
                                                    <Button variant="text" onClick={() => handleOpenDialog(item.btnEdit_1)}>
                                                        <EditIcon />
                                                    </Button>
                                                )}
                                                {item.btnNavigate && (
                                                    <Tooltip title="Immobilie anzeigen">
                                                        <IconButton
                                                            onClick={() => window.open(`/fac?id=${sac.sac_fac_id}`, '_blank')}
                                                        >
                                                            <OpenInNewIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {item.btnOpenDialog && (
                                                    <Tooltip title="Artikel anzeigen">
                                                        <IconButton
                                                            onClick={() => handleOpenArticleDialog(sac.sac_art_inventory_id)}
                                                        >
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {item.btnTooltip_1 && (
                                                    <QeeTooltip title={item.btnTooltip_1}>
                                                        <IconButton>
                                                            <InfoOutlinedIcon style={{ color: '#a1bda1' }} />
                                                        </IconButton>
                                                    </QeeTooltip>
                                                )}
                                            </TableCell>
                                        </>
                                    ) : (
                                        <TableCell width="250px" component="th" scope="row" sx={{ padding: 2 }}>
                                            <Typography style={{ fontWeight: 700 }}>{item.label}</Typography>
                                            {item.sublabel && <Typography className={classes.sublabel}>{item.sublabel}</Typography>}
                                            <Box sx={{ fontWeight: 600 }}>
                                                <Typography variant="val">
                                                    {item.fctFormat_1 ? item.fctFormat_1(getItemValue(item.item_1, sac)) : getItemValue(item.item_1, sac)}
                                                </Typography>
                                            </Box>
                                            {item.btnEdit_1 && (
                                                <Button variant="text" onClick={() => handleOpenDialog(item.btnEdit_1)}>
                                                    <EditIcon />
                                                </Button>
                                            )}
                                            {item.btnNavigate && (
                                                <Tooltip title="Immobilie anzeigen">
                                                    <IconButton
                                                        onClick={() => window.open(`/fac?id=${sac.sac_fac_id}`, '_blank')}
                                                    >
                                                        <OpenInNewIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {item.btnOpenDialog && (
                                                <Tooltip title="Artikel anzeigen">
                                                    <IconButton
                                                        onClick={() => handleOpenArticleDialog(sac.sac_art_inventory_id)}
                                                    >
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            )
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Paper className={classes.paper} style={{ padding: theme.spacing(4) }}>
                <Box display="flex" justifyContent="space-between">
                    <Typography className={classes.fieldLabel}>Beschreibung:</Typography>
                    <IconButton size="large" onClick={() => handleOpenDialog('dlgBody')}>
                        <EditIcon />
                    </IconButton>
                </Box>
                <Typography variant="body2" className={classes.descParagraph}>
					{getItemValue("`${sac.sac_body}`", sac)}
                </Typography>
            </Paper>

            {/* Dialogs for editing fields */}    
            <DlgNameComp />    
            <DlgBodyComp />
            <QeeSlideDialog
                id="dlgDate"
                title="Datum bearbeiten"
                formComponent={
                    <QeeSingleFieldEditor
                        fieldType={'DatePicker'}
                        title={'Ausführungsdatum der Aktivität'}
                        label={'Datum'}
                        placeholder={'Wann/bis wann findet die Aktivität statt'}
                        vl={sac.sac_date_limit}
                        fctOnChanges={(vl) => handleChangesDialog({ field: 'sac_date_limit', value: vl ? vl.date.toISOString() : '' })}
                        fctOnCancel={() => setOpenQeeDialog({ open: false, id: null })}
                    />
                }
                fctOnSubmit={async () => {
                    const updatedSac = await sacSave({ ...sac, sac_date_limit: changesDialog.value });
                    setObjEdit(updatedSac);
                    setOpenQeeDialog({ open: false, id: null });
                }}
            />
            <QeeSlideDialog
                id="dlgTime"
                title="Zeit bearbeiten"
                formComponent={
                    <QeeSingleFieldEditor
                        fieldType={'TimePicker'}
                        title={'Ausführungszeit der Aktivität'}
                        label={'Zeit'}
                        placeholder={'Wann/bis wann findet die Aktivität statt (Uhrzeit)'}
                        vl={sac.sac_date_limit}
                        fctOnChanges={(vl) => handleChangesDialog({ field: 'sac_date_limit', value: vl ? vl.date.toISOString() : '' })}
                        fctOnCancel={() => setOpenQeeDialog({ open: false, id: null })}
                    />
                }
                fctOnSubmit={async () => {
                    const updatedSac = await sacSave({ ...sac, sac_date_limit: changesDialog.value });
                    setObjEdit(updatedSac);
                    setOpenQeeDialog({ open: false, id: null });
                }}
            />
            <QeeSlideDialog
                id="dlgArticle"
                title="Artikel Details"
                onClose={() => setOpenQeeDialog({ open: false, id: 'dlgArticle' })}
                formComponent={
                    <div style={{ paddingTop: 50 }}>
                        <QeeArticleForm artId={selectedArtId} />
                    </div>
                }
                hideDialogActions={true}
                submitDisabled={true}
            />
        </div>
    );
};

export default SacGeneral;
