import react, { useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo } from 'react';
import React from 'react';
import { makeStyles }  from '@material-ui/core/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';

import QeeTooltip from './qeeTooltip';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import WaitProgressCircular from './waitProgressCircular';
//import { useLicense } from "./qeeUsrLicenseProvider";   // aen-20230331 moved from here to the parent qeeRenovationResultIst - recurs. imports problem in qeewidgets vite

import { GetSdataIconByName } from "../core/icons";
import { getSdatas, getSdatasNet, getAvailableHeatingsTypes } from "../api/service";

import { useAuth } from "../context/provider";


const QeeRadioGroup = ( props) => {
	
	//const { setOpenLicenseDialog } = useLicense();					

	/**
	* 	Workaround to be able to use this comp from another app. 
	*	The ext app must provide initilaized elems gstate and setObjEdit as props (aen-20230331).
	*/
	function useAuthData() {
		
		var auth = useAuth();
		
		if( props.gstate) {
			const gstate = props.gstate;
			const setObjEdit = props.setObjEdit;		
			return { gstate, setObjEdit };			
		} else {
			const { gstate, setObjEdit } = auth;			
			return { gstate, setObjEdit };
		}
	}
	const { gstate, setObjEdit } = useAuthData();   // avoid hook limitations	
	
	const [locState, setLocState] = useState({});	
	const [sd, setSd] = useState([]);		
	const [isLoading, setIsLoading] = useState( true);	
	
	//const isForcingXSBehavior = props.forceXSBehavior || gstate.isXS;
	const isForcingXSBehavior = useMemo(() => props.forceXSBehavior || gstate.isXS, [props.forceXSBehavior, gstate.isXS]);
	console.log( 'props.forceXSBehavior', props.forceXSBehavior);
	console.log( 'gstate.isXS', gstate.isXS);
	console.log( 'isForcingXSBehavior', isForcingXSBehavior);
		
	const sdataMemo = useMemo(() => props.sdata, [props.sdata]);		
		
	// some consts
	//const useStyles = makeStyles( theme => ({
	const useStyles = makeStyles((theme) => {
		
		const forceXSBreakpoint = props.forceXSBehavior ? 'xl' : 'xs';		
		
		return {
			formLabel: {
				//fontSize: 32
				fontWeight:700
			},
			formSublabel: {
				fontSize: 16,
				[theme.breakpoints.down('xs')]: {
					fontSize: 14,
				},
				[theme.breakpoints.down( forceXSBreakpoint)]: {
					fontSize: 16,
				},					
				paddingTop: 10,   // 20240130-aen changed for qeewidgets, it was padding, margin
				paddingBottom: 10,
				marginTop: 10,
				marginBottom: 10			
			},
			formControlLabel: {
				width: props.width ? props.width : 200,
				height: props.height ? props.height : 120,
				padding: 20, 
				margin: 20,
				marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin
				[theme.breakpoints.down('xs')]: {
					width: props.width ? props.width : '100%',
					height: props.height ? props.height : 60,				
					padding: 10, 
					margin: 10,
					marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin
				},
				[theme.breakpoints.down( forceXSBreakpoint)]: {
					width: props.width ? props.width : '100%',
					height: props.height ? props.height : 'auto', //60,	
					padding: 10, 
					margin: 10,
					marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin					
				},				
				border: 'solid 1px gray',
				color: theme.palette.primary.main,
			},
			formControlLabelSelected: {
				width: props.width ? props.width : 200,
				height: props.height ? props.height : 120,
				padding: 16, 
				margin: 20,			
				marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin
				[theme.breakpoints.down('xs')]: {
					width: props.width ? props.width : '100%',
					height: props.height ? props.height : 60,				
					padding: 10, 
					margin: 10,		
					marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin
				},
				[theme.breakpoints.down( forceXSBreakpoint)]: {
					width: props.width ? props.width : '100%',
					height: props.height ? props.height : 'auto', //60,				
					padding: 10, 
					margin: 10,		
					marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin					
				},
				border: `5px solid ${theme.palette.primary.main}`,
				borderRadius: 5,
				background: '#eee',
				color: theme.palette.primary.main,
			},
			formControlLabelIcon: {
				width:200, 
				maxHeight: 250,
				padding: 30, 
				margin: 20,
				marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin
				[theme.breakpoints.down('xs')]: {
					width:'100%', 
					maxHeight: 120,
					padding: 10, 
					margin: 10,		
					marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin
				},
				[theme.breakpoints.down( forceXSBreakpoint)]: {
					width:'100%', 
					maxHeight: 120,
					padding: 10, 
					margin: 10,		
					marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin	
				},				
				border: 'solid 1px gray',
				color: theme.palette.primary.main,
			},
			formControlLabelIconSelected: {
				width:200, 
				maxHeight: 250,
				padding: 26, 
				margin: 20,
				marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin
				[theme.breakpoints.down('xs')]: {			
					width:'100%', 
					maxHeight: 120,
					padding: 10, 
					margin: 10,		
					marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin
				},
				[theme.breakpoints.down( forceXSBreakpoint)]: {	
					width:'100%', 
					maxHeight: 120,
					padding: 10, 
					margin: 10,		
					marginLeft: 0,   // 20240130-aen changed for qeewidgets, it was padding, margin				
				},				
				border: `5px solid ${theme.palette.primary.main}`,
				borderRadius: 5,
				background: '#eee',
				color: theme.palette.primary.main,
			},		
			boxLabel: {
				fontSize: 16,
				[theme.breakpoints.down('xs')]: {
					fontSize: 13,
					marginRight: 10,
				},
				[theme.breakpoints.down( forceXSBreakpoint)]: {	
					fontSize: 16,
					marginRight: 10
				},					
			},
			boxIcon: {
				fontSize: 16,
				[theme.breakpoints.down('xs')]: {
					fontSize: 13,
					marginRight: 10,
				},
				[theme.breakpoints.down( forceXSBreakpoint)]: {	
					fontSize: 16,
					marginRight: 10,				
				},					
			},
			radio: {
				color: theme.palette.primary.main
			}
		}
	});			
	const classes = useStyles( props);			
		
	
	const getIconStyle = ( ico) => {		
	
		var st;	
	
		if( isForcingXSBehavior) {
			st = {marginLeft: 'auto'}
			return st; 
		}
	
		if( ico) {
			st={position: 'relative',left: 70,top: 20};
			if( isForcingXSBehavior)
				st={position: 'relative',left: 50,top: 93};			
		} else {
			st={position: 'relative',left: 80,top: 90};		
			if( isForcingXSBehavior)			
				st={position: 'relative',left: 50,top: 53};							
		}
		
		
		return st;		
	}	


	const rgIconStyle = () => {
		
		if( !isForcingXSBehavior)
			return { width:100, height:100 }
		else
			return { width:36, height:36 }
	}	
	
				
					
	useEffect( () => {
		/*
		async function fetchData() {
			setIsLoading( true);				
			var sd = [];
			if( props.sdataName) {
				sd = await getSd();   // get it from remote
			} else if( props.sdata) {	
				sd = props.sdata;	// get it from props	
			}
			setSd( sd);
			setIsLoading( false);				
		}
		*/
		fetchData();
		
	}, [props.sdataName, props.sdata, props.defaultValue]);	  // aen-20230331 added condition for use with qeewidgets		
	
	

    // fetchData memoized to prevent re-fetching if dependencies don't change
    const fetchData = useCallback(async () => {
	//const fetchData = async () => {	
        setIsLoading(true);
        let sd = [];
        if (props.sdataName) {
            sd = await getSd(); // get it from remote
			
        } else if (props.sdata) {
            sd = props.sdata; // get it from props
			if( sd && sd.length) {
				for( var i=0; i<sd.length; i++) {
					try {						
						// get the desc for tooltips
						if( sd[i].desc) {
							Object.keys( sd[i].desc).forEach( 
							e => {
								sd[i].descText = sd[i].desc[e]; 
							});
						}
													
						// if autoicons configured	
						if( props.hasAutoIcon) {
							sd[i].icon = GetSdataIconByName( props.iconSetName, sd[i].value, rgIconStyle());
						} 		
						
					} catch( e) {
						console.error( 'Cannot set info and icon for ' + i, e);
					}
				}
			}			
        }
        setSd(sd);
        setIsLoading(false);
	});
    //}, [props.sdataName, props.sdata]);

    useEffect(() => {
        fetchData();
    }, []);	
	
	
	//const getSd = async (row) => {
	const getSd = useCallback(async () => {
	//const getSd = async () => {		
		var sd = [];
		try {
			var sdata;
			if( props.sdataName==='availableHeatingsTypes')
				sdata = await getAvailableHeatingsTypes( gstate.objEdit.fac_energy.energyclass_plan, 'vallab');
			else
				sdata = await getSdatas( props.sdataName, 
					props.sdataKeyFld ? props.sdataKeyFld : '', 
					props.sdataKeyVal ? props.sdataKeyVal : '', 
					'vallab');	
			if( sdata && sdata.length) {
				for( var i=0; i<sdata.length; i++) {
					try {						
						// get the desc for tooltips
						if( sdata[i].desc) {
							Object.keys( sdata[i].desc).forEach( 
							e => {
								sdata[i].descText = sdata[i].desc[e]; 
							});
						}
													
						// if autoicons configured	
						if( props.hasAutoIcon) {
							sdata[i].icon = GetSdataIconByName( props.sdataName, sdata[i].value, rgIconStyle());
						} else {
							// complete with icon from props.data (old way)
							if( props.data)
								if( props.data[i].icon)
									sdata[i].icon = props.data[i].icon;							
						}		
						
					} catch( e) {
						console.error( 'Cannot set info and icon for ' + i, e);
					}
				}
				sd = sdata;
			}
			
		} catch( e) {
			console.error( 'getData ERR', e);
		} finally {
			return sd;			
		}
	})	
	
	
    return (	
		<>
			<FormControl>
				{props.formLabel && (
					<Typography variant="h5" mt={4} id="radio-buttons-group-label" className={classes.formLabel}>
						{props.formLabel}
					</Typography>
				)}
				{props.formSublabel && (
					<div id="radio-buttons-group-label" className={classes.formSublabel}>
						{props.formSublabel}
					</div>
				)}
				{isLoading ? (
					<div className="form-group" style={{ padding: 40 }}>
						<WaitProgressCircular />
					</div>
				) : (
				sd.map ? (
					<>
					<RadioGroup
					  style={{ padding: 0 }}
					  aria-labelledby="radio-buttons-group-label"
					  row
					  defaultValue={props.defaultValue}
					  name="radio-buttons-group"
					  onChange={props.fctOnChange}
					>
						{sd.map((r, i) => (
							<div style={{ marginLeft: isForcingXSBehavior ? 0 : 0, width: isForcingXSBehavior ? '90%' : '' }} key={i}>
								<FormControlLabel
									labelPlacement={isForcingXSBehavior ? 'right' : 'top'}
									value={r.value}
									disabled={r.licenseDisabled}
									control={
										<Radio
											sx={{
												height: isForcingXSBehavior ? 12 : null,
												color: (theme) => theme.palette.primary.main,
												'&.Mui-checked': {
													color: (theme) => theme.palette.primary.main,
												},
											}}
										/>
									}
									onClick={(ev) => {
										if (props.fctOnClick) props.fctOnClick(ev);
									}}
									label={
										r.licenseDisabled ? (
											<Badge badgeContent={'UPGRADE'} color="license">
												<QeeColorButtonPrimary disableElevation className={classes.button} onClick={() => r.setOpenLicenseDialog(true)}>
												Sanierungsplaner
												</QeeColorButtonPrimary>
											</Badge>
										) : (
												<div
													style={{
														textAlign: !isForcingXSBehavior ? 'center' : null,
														justifyContent: 'center',
														alignItems: !isForcingXSBehavior ? 'center' : null,
														display: isForcingXSBehavior ? 'flex' : '',
													}}
												>
												{!isForcingXSBehavior &&
												(props.hasDesc ? (
													<QeeTooltip title={r.descText} style={getIconStyle(r.icon)}>
														<IconButton>
															<InfoOutlinedIcon style={{ color: '#a1bda1' }} />
														</IconButton>
													</QeeTooltip>
													) : (
													''
												))}
												<div className={classes.boxIcon}>{r.icon}</div>
												<div className={classes.boxLabel}>{r.label}</div>
												<div>{r.formComponent}</div>
												{isForcingXSBehavior &&
													(props.hasDesc ? (
														<QeeTooltip title={r.descText} style={getIconStyle(r.icon)}>
															<IconButton>
																<InfoOutlinedIcon style={{ color: '#a1bda1' }} />
															</IconButton>
														</QeeTooltip>
													) : (
													''
												))}
												</div>
											)
										}
										className={props.defaultValue!=r.value ? 
											(r.icon ? classes.formControlLabelIcon : classes.formControlLabel) 
											: 
											(r.icon ? classes.formControlLabelIconSelected : classes.formControlLabelSelected)							
										}
								/>
							</div>
						))}
					</RadioGroup>
					</>
				) : (
					<>Keine Stammdaten!</>
				)
			  )}
			</FormControl>
		</>
    )
}
export default QeeRadioGroup;
