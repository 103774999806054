import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-free-solid';
import MarkerMapIconIcon from '@mui/icons-material/Room';
import { makeStyles } from '@material-ui/core/styles';
import config from '../util/config';
import { useAuth } from "../context/provider";

const QeeGmap = (props) => {

    const useStyles = makeStyles({
        map: {
            marginTop: props.marginTop ? props.marginTop : 0
        },
        mapContainer: {
            height: props.mapHeight ? props.mapHeight : `calc(100vh - 300px)`,
            width: '100%',
            backgroundColor: '#eee'
        }
    });

    const classes = useStyles(props);

    function useAuthData() {
        var auth = useAuth();
        if (props.gstate) {
            const gstate = props.gstate;
            const setObjEdit = props.setObjEdit;
            return { gstate, setObjEdit };
        } else {
            const { gstate, setObjEdit } = auth;
            return { gstate, setObjEdit };
        }
    }

    const { gstate, setObjEdit } = useAuthData();

    const initialMapData = {
        addr_gvalue_o: gstate.objEdit.addr_gvalue_o ? gstate.objEdit.addr_gvalue_o : '',
        addr_gvalue_text_o: gstate.objEdit.addr_gvalue_text_o ? gstate.objEdit.addr_gvalue_text_o : '',
        addr_street_zip_o: gstate.objEdit.addr_street_zip_o ? gstate.objEdit.addr_street_zip_o : '',
        addr_locality_o: gstate.objEdit.addr_locality_o ? gstate.objEdit.addr_locality_o : '',
        addr_geo_lat_o: gstate.objEdit.addr_geo_lat_o ? gstate.objEdit.addr_geo_lat_o : 52.5200, // Default to Berlin
        addr_geo_lon_o: gstate.objEdit.addr_geo_lon_o ? gstate.objEdit.addr_geo_lon_o : 13.4050, // Default to Berlin
        org_name: gstate.objEdit.org_name ? gstate.objEdit.org_name : '',
    };

    const [mapData, setMapData] = useState(initialMapData);
    const [mapActive1, setMapActive1] = useState(false);
    const [mapActive2, setMapActive2] = useState(false);
    const [showResult, setShowResult] = useState(mapData.addr_gvalue_o ? true : false);

    const LocationPin = ({ text }) => (
        <div className="pin">
            <MarkerMapIconIcon style={{ width: "48px", height: "48px", color: '#a44' }} />
            <p className="pin-text">{text}</p>
        </div>
    );

    const _setAddress = (gval) => {
        const companyName = gval.value?.structured_formatting?.main_text;
        var ret = {
            addr_gvalue_o: gval,
            addr_gvalue_text_o: gval.label,
            addr_street_zip_o: '',
            addr_locality_o: '',
            addr_geo_lat_o: 52.5200, // Default to Berlin
            addr_geo_lon_o: 13.4050, // Default to Berlin
            org_name: companyName,
        };

        geocodeByPlaceId(gval.value.place_id)
            .then(results => {
                results = results[0];
                for (var i = 0; i < results.address_components.length; i++) {
                    for (var j = 0; j < results.address_components[i].types.length; j++) {
                        if (results.address_components[i].types[j] === "postal_code") {
                            var z = results.address_components[i].long_name;
                            ret.addr_street_zip_o = z;
                        }
                        if (results.address_components[i].types[j] === "locality") {
                            var l = results.address_components[i].long_name;
                            ret.addr_locality_o = l;
                        }
                        if (results.address_components[i].types[j] === "route") {
                            var s = results.address_components[i].long_name;
                            ret.addr_street_o = s;
                        }
                        if (results.address_components[i].types[j] === "street_number") {
                            var hn = results.address_components[i].long_name;
                            ret.addr_housenumber_o = hn;
                        }
                    }
                }
                ret.addr_geo_lat_o = results.geometry.location.lat();
                ret.addr_geo_lon_o = results.geometry.location.lng();
                setMapData(ret);
                setShowResult(true);

                if (props.fctOnAddressChange) {
                    props.fctOnAddressChange(ret);
                }
            })
            .catch(error => console.error('error', error));
    }

    useEffect(() => {
        const t1 = setTimeout(() => {
            setMapActive1(true)
        }, 100);
        const t2 = setTimeout(() => {
            setMapActive2(true)
        }, 2000);
        return () => {
            window.clearTimeout(t2);
            window.clearTimeout(t1);
        };
    }, []);

    useEffect(() => {
					console.log( '>>> mapData', mapData)
        if (mapData.addr_gvalue_o && !showResult) {

            _setAddress(mapData.addr_gvalue_o);
        }
    }, [mapData.addr_gvalue_o]);

    const _closeResults = () => {
        setMapData({ ...mapData, addr_gvalue_o: null, showResult: false });
        if (props.fctOnCloseResult)
            props.fctOnCloseResult();
    }

    const apiIsLoaded = (map, maps) => {
		
		return;
		
        if (map && maps) {
            const bounds = new maps.LatLngBounds();
            bounds.extend(new maps.LatLng(mapData.addr_geo_lat_o, mapData.addr_geo_lon_o));
            map.fitBounds(bounds);
        }
    };

    return (
        <div className={classes.map}>
            <div style={{ width: '100%' }}>
                {props.showSearchBar ?
                    <GooglePlacesAutocomplete
                        apiKey={config.google.apiKey}
                        selectProps={{
                            defaultInputValue: mapData.addr_gvalue_o ? mapData.addr_gvalue_o.label : '',
                            onChange: _setAddress,
                            noOptionsMessage: () => 'Deutschland',
                            placeholder: 'Tippen Sie hier ein...',
                            loadingMessage: () => 'Adressen laden...'
                        }}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ['de'],
                            },
                            types: props.establishmentOnly ? ['establishment'] : []
                        }}
                    />
                    : ''}
            </div>
            {props.showResultBar && showResult ?
                <div className="form-result">
                    <div style={{ float: 'right' }}>
                        <FontAwesomeIcon icon={faTimes} style={{ fontSize: 48, padding: 10, color: '#444' }} onClick={() => _closeResults()} />
                    </div>
                    <div><span className="label">Postleitzahl:</span> <span className="result">{mapData.addr_street_zip_o}</span></div>
                    <div><span className="label">Stadt: </span><span className="result">{mapData.addr_locality_o}</span></div>
                    <div><span className="label">Straße: </span><span className="result">{mapData.addr_street_o} {mapData.addr_housenumber_o}</span></div>
                </div>
                : ''}
            {showResult ?
                <div className={classes.mapContainer}>
                    {mapActive2 && <GoogleMapReact
                        yesIWantToUseGoogleMapApiInternals
                        bootstrapURLKeys={{ key: config.google.apiKey }}
                        center={{ lat: mapData.addr_geo_lat_o, lng: mapData.addr_geo_lon_o }}
                        defaultZoom={17}
                        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
                    >
                        <LocationPin
                            lat={mapData.addr_geo_lat_o}
                            lng={mapData.addr_geo_lon_o}
                            text={mapData.addr_gvalue_o ? mapData.addr_gvalue_o.label : ''}
                        />
                    </GoogleMapReact>}
                </div>
                : ''}
        </div>
    )
}

export default QeeGmap;
