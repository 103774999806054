import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import OrgCard from './orgCard';
import FacOrgsMap from './facOrgsMap';
import OrgsEmpty from './orgsEmpty';
import OrgsFilterBar from './orgsFilterBar';
import { fetchOrgs, getSdatas } from '../api/service';
import { useOrg } from "../components/qeeOrgProvider";
import { useStepperDialog } from "../components/qeeStepperDialogProvider";

const styles = (theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)', // Adjust this value based on your header/footer height
        overflow: 'hidden'
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            overflow: 'auto'
        }
    },
    listContainer: {
        flex: 1,
        paddingRight: theme.spacing(1), // Add padding to avoid overlap with scrollbar
        boxSizing: 'border-box',
        overflowY: 'auto',
        [theme.breakpoints.down('md')]: {
            display: (props) => (props.showMap ? 'none' : 'block')
        },
        borderRight: `1px solid ${theme.palette.divider}`
    },
    cardsContainer: {
        [theme.breakpoints.up('md')]: {
            width: 'calc(45% - 160px)',
        },
    },
    mapWrapper: {
        top: '180px',
        width: '55%', // Fixed width for the right side
        padding: theme.spacing(0.5),
        position: 'fixed',
        right: '40px',
        height: 'calc(100% - 180px)',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('md')]: {
            position: 'sticky',
            display: (props) => (props.showMap ? 'block' : 'none'),
            flex: 1,
            width: '100%',
            overflow: 'hidden'
        }
    }
});

const useStyles = makeStyles(styles);

const initStateFromSessionStorage = (defaultState, sessionKey) => {
    const savedState = JSON.parse(sessionStorage.getItem(sessionKey));
    return savedState !== null ? savedState : defaultState;
};

const FacOrgsForm = (props) => {
    const { setOpenOrgDialog } = useOrg();
    const { setOpenStepperDialog } = useStepperDialog();

    const globalSessionKey = 'facOrgsForm-global';
    const defaultShowMap = false; // Default value for showMap
    const defaultState = {
        uiLoading: true,
        discs: [],
        orgs: [],
        filteredOrgs: [],
        discipline: 0,
        radius: 'lt100',
        error: null,
        mapKey: 0
    };
    const [showMap, setShowMap] = useState(() => initStateFromSessionStorage(defaultShowMap, `${globalSessionKey}-showMap`));
    const [state, setState] = useState(() => initStateFromSessionStorage(defaultState, globalSessionKey));
    const classes = useStyles({ showMap });
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    const { uiLoading, orgs, filteredOrgs, discipline, radius, error, mapKey } = state;

    const radii = [
        { label: 'bis 3km', value: 'lt3' },
        { label: 'bis 10km', value: 'lt10' },
        { label: 'bis 50km', value: 'lt50' },
        { label: 'bis 100km', value: 'lt100' },
        { label: 'über 100km', value: 'gt100,lt100' }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                let tpText = '';
                var arrRet = await getSdatas('sdata_facs_types', 'type', props.fac.fac_type);
                tpText = arrRet[0].name;
                if (props.fac.fac_type == 100)
                    arrRet = await getSdatas('sdata_houses_types', 'type', props.fac.fac_house_type);
                else if (props.fac.fac_type == 200)
                    arrRet = await getSdatas('sdata_apartments_types', 'type', props.fac.fac_apartment_type);
                tpText += ' | ' + arrRet[0].name;
                props.fac.fac_type_text = tpText;

                let arrDiscs = await getSdatas('sdata_perprj_roles_types');
                arrDiscs.push({ 'name': '-alle-', type: 0 });
                arrDiscs.sort((a, b) => a.name.localeCompare(b.name));
                const response = await fetchOrgs(
                    discipline,
                    props.fac.addr_geo_lat_o,
                    props.fac.addr_geo_lon_o,
                    radius
                );
                if (response.err) {
                    throw new Error(response.err);
                }
                setState((prevState) => ({
                    ...prevState,
                    discs: arrDiscs,
                    orgs: response.entries.results,
                    filteredOrgs: response.entries.results,
                    uiLoading: false,
                    error: null,
                }));
            } catch (error) {
                console.error('Serverfehler beim Lesen der Organisationsdaten: ', error);
                setState((prevState) => ({
                    ...prevState,
                    uiLoading: false,
                    error: error.message,
                }));
            }
        };
        fetchData();
    }, [discipline, radius]);

    const handleDisciplineChange = (event) => {
        const selectedDiscipline = event.target.value;
        const filteredList = selectedDiscipline == 0 ? orgs : orgs.filter(org => org.org_perprj_role == selectedDiscipline);
        const newState = {
            ...state,
            discipline: selectedDiscipline,
            filteredOrgs: filteredList,
            mapKey: state.mapKey + 1,
        };
        setState(newState);

        sessionStorage.setItem(globalSessionKey, JSON.stringify(newState));
    };

    const handleRadiusChange = (event) => {
        const selectedRadius = event.target.value;
        const newState = {
            ...state,
            radius: selectedRadius,
            mapKey: state.mapKey + 1
        };
        setState(newState);

        sessionStorage.setItem(globalSessionKey, JSON.stringify(newState));
    };

    const handleToggleMap = () => {
        setShowMap(prevShowMap => {
            const newShowMap = !prevShowMap;
            sessionStorage.setItem(`${globalSessionKey}-showMap`, JSON.stringify(newShowMap));

            setTimeout(() => {
                setState(prevState => ({
                    ...prevState,
                    mapKey: prevState.mapKey + 1 // Update the key to force re-rendering of the map component
                }));
            }, 100); // Delay to allow state to update and re-render map

            return newShowMap;
        });
    };

    const handleOrdSubmitEnd = (org) => {
    }

    const handleSupplierSacRfi = (ev) => {
        let { org } = ev;
        console.log('handleSupplierSacRfi', org);
        console.log('facOrgsForm: start order button clicked');

        let usr = JSON.parse(sessionStorage.getItem('usr'));
        let context = {
            usr_id: usr.usrId,
            per_firstname: usr.per_firstname,
            per_lastname: usr.per_lastname,
            cch_email_o: usr.cch_email_o,
            fac_id: props.fac.id,
            org: org,
        }

        let stepper = {
            instanceName: "qeeleaapp-supplier-order",
            wizReadyFieldName: "org_wiz_ready",
            fctSubmitEnd: (org) => handleOrdSubmitEnd(org),
            stepSubmitEnd: 7,
        }
        setOpenStepperDialog(context, 'Anfrage', stepper)
    };

    const handleOrgClick = (org, cbHandleOrgPrimaryAction) => {
        console.log('handleOrgClick', org);
        cbHandleOrgPrimaryAction = (e) => {
            e.org = org;
            handleSupplierSacRfi(e);
        };
        setOpenOrgDialog(org, false, cbHandleOrgPrimaryAction, 'Anfrage starten');
    };

    return (
        uiLoading ? (
            <div className={classes.root}>
                <CircularProgress size={50} className={classes.uiProgess} />
            </div>
        ) : (
            <main className={classes.content}>
                <Box className={classes.mainContent}>
                    {(!showMap || isMdUp) && (
                        <Box className={classes.listContainer}>
                            <OrgsFilterBar
                                fac={props.fac}
                                discipline={discipline}
                                radius={radius}
                                discs={state.discs}
                                radii={radii}
                                handleDisciplineChange={handleDisciplineChange}
                                handleRadiusChange={handleRadiusChange}
                                handleToggleMap={handleToggleMap}
                                isMdUp={isMdUp}
                                showMap={showMap}
                                error={error}
                            />
                            <Grid container spacing={1} direction={showMap ? 'column' : ''} className={showMap ? classes.cardsContainer : ''}>
                                {filteredOrgs.length > 0 ? (
                                    filteredOrgs.map((org) => (
                                        <Grid item xs={12} md={showMap ? 12 : 6} key={org.id}>
                                            <OrgCard org={org} onClick={() => handleOrgClick(org, handleSupplierSacRfi)} />
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                            <OrgsEmpty />
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    )}
                    {(showMap && isMdUp) && (
                        <Box className={classes.mapWrapper} key={mapKey}>
                            <FacOrgsMap
                                orgs={filteredOrgs}
                                onOrgClick={handleOrgClick}
                                {...props}
                            />
                        </Box>
                    )}
                    {showMap && !isMdUp && (
                        <>
                            <OrgsFilterBar
                                fac={props.fac}
                                discipline={discipline}
                                radius={radius}
                                discs={state.discs}
                                radii={radii}
                                handleDisciplineChange={handleDisciplineChange}
                                handleRadiusChange={handleRadiusChange}
                                handleToggleMap={handleToggleMap}
                                isMdUp={isMdUp}
                                showMap={showMap}
                                error={error}
                            />
                            <Box className={classes.mapWrapper} key={mapKey}>
                                <FacOrgsMap
                                    orgs={filteredOrgs}
                                    onOrgClick={handleOrgClick}
                                    {...props}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </main>
        )
    );
};

export default withStyles(styles)(FacOrgsForm);
