import React from "react";
import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import QeeTooltip from './qeeTooltip';

import { useAuth } from "../context/provider";


const DiagramEnergyClass = ( props) => {
	
	console.log( 'DiagramEnergyClass', props);
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();				
	
	const [markerIstPx, setMarkerIstPx] = useState( 0);	
	const [markerPlanPx, setMarkerPlanPx] = useState( 0);		
		
	// create a ref
	const diagram = useRef(null);
		
	const getBackgroundColor = (energy) => {		
	
		//console.log( '--> getBackgroundColor', energy);
	
		var col;
		if( energy < 30)
			col = '#25FE01'
		else if( energy >= 30 && energy < 50)			
			col = '#8EFF01'			
		else if( energy >= 50 && energy < 75)
			col = '#B9FF01'			
		else if( energy >= 75 && energy < 100)						
			col = '#E6FF01'			
		else if( energy >= 100 && energy < 130)									
			col = '#F9FF00'			
		else if( energy >= 130 && energy < 160)									
			col = '#FEF102'			
		else if( energy >= 160 && energy < 200)									
			col = '#FFD600'			
		else if( energy >= 200 && energy < 250)												
			col = '#FF7B00'			
		else
			col = '#FE3A00'			
		
		//console.log( '<-- getBackgroundColor', col);		
		
		return col;		
	}
	
	
	const getLetter = ( energy) => {			
	
		//console.log( '--> getLetter', energy);
		
		var ltr;
		if( energy < 30)
			ltr = 'A+'
		else if( energy >= 30 && energy < 50)			
			ltr = 'A'			
		else if( energy >= 50 && energy < 75)
			ltr = 'B'			
		else if( energy >= 75 && energy < 100)						
			ltr = 'C'			
		else if( energy >= 100 && energy < 130)									
			ltr = 'D'			
		else if( energy >= 130 && energy < 160)									
			ltr = 'E'			
		else if( energy >= 160 && energy < 200)									
			ltr = 'F'			
		else if( energy >= 200 && energy < 250)												
			ltr = 'G'			
		else
			ltr = 'H'					
				
		//console.log( '<-- getLetter', ltr);		
	
		return ltr;
	}
	
	
	const getIntervals = ( maxEnergy, w) => {			
	
		console.log( '--> getIntervals', {maxEnergy, w});
	
		var arr = [];
	
		var wItv = w / 9;		
	
		arr.push( {start:0, 	end:30, 			startPx:0, 		endPx:1*wItv}); 
		arr.push( {start:30, 	end:50, 			startPx:1*wItv, endPx:2*wItv}); 
		arr.push( {start:50, 	end:75, 			startPx:2*wItv, endPx:3*wItv});
		arr.push( {start:75, 	end:100, 			startPx:3*wItv, endPx:4*wItv});
		arr.push( {start:100, 	end:130, 			startPx:4*wItv, endPx:5*wItv});
		arr.push( {start:130, 	end:160, 			startPx:5*wItv, endPx:6*wItv});
		arr.push( {start:160, 	end:200, 			startPx:6*wItv, endPx:7*wItv});
		arr.push( {start:200, 	end:250, 			startPx:7*wItv, endPx:8*wItv});
		arr.push( {start:250, 	end:maxEnergy+1, 	startPx:8*wItv, endPx:9*wItv});
			
		console.log( '<-- getIntervals', arr);		
	
		return arr;
	}	
	
	
	const setMarkers = ( w) => {
				
		var maxEnergy = 300;
		var energyIst = gstate.objEdit.fac_energy[props.energyIst];
		var energyPlan = gstate.objEdit.fac_energy[props.energyPlan];		
		console.log( '>>> energyIst', energyIst);
		console.log( '>>> energyPlan', energyPlan);		
		if( energyIst > maxEnergy) energyIst = maxEnergy;
		if( energyPlan > maxEnergy) energyPlan = maxEnergy;		

		var arr = getIntervals( maxEnergy, w);
		var itvIst;
		var itvPlan;
		for( var i=0; i<9; i++) {
			//console.log( 'checking idx ' + i, arr[i]);
			if( energyIst >= arr[i].start && energyIst < arr[i].end) {
				itvIst = arr[i];
				console.log( 'Found '+i, itvIst);				
			}
			
			if( energyPlan >= arr[i].start && energyPlan < arr[i].end) {
				itvPlan = arr[i];
				console.log( 'Found '+i, itvPlan);				
			}
			
		}		
		var pxIst = 0;
		if( itvIst) {
			var start = itvIst.start;
			var end = itvIst.end;			
			var startPx = itvIst.startPx;
			var endPx = itvIst.endPx;
			//pxIst = startPx+(endPx-startPx)/2;
			pxIst = energyIst * endPx/end;
			setMarkerIstPx( pxIst);
		}		
		var pxPlan = 0;				
		if( itvPlan) {
			var start = itvPlan.start;
			var end = itvPlan.end;			
			var startPx = itvPlan.startPx;
			var endPx = itvPlan.endPx;			
			//pxPlan = startPx+(endPx-startPx)/2;
			pxPlan = energyPlan * endPx/end;
			setMarkerPlanPx( pxPlan);							
		}
				
		//var pxIst = energyIst * w/maxEnergy * (9/12);		
		//var pxPlan = energyPlan * w/maxEnergy * (9/12);		
		console.log( '>>> markerIstPx', pxIst);
		console.log( '>>> markerPlanPx', pxPlan);				


	}
	
	
	// some consts
	const useStyles = makeStyles( theme => ({
		gridDiagram: {
			marginTop: 30		
		},
		colorBox: {
			height:30, 
			fontWeight:700			
		},
		letterBox: {
			paddingLeft:10,
			color:'#222'
		},		
		markerIst: {
			height:90, 
			width:3, 
			paddingLeft:5,
			backgroundColor:'#222', 
			position:'relative', 
			top:(props.showEnergyPlan ? 210 : 60), 
			left:markerIstPx
		},
		markerPlan: {
			height:150, 
			width:3, 
			paddingLeft:5,			
			backgroundColor:'#0a0', 
			position:'relative', 
			top:60, 
			left:markerPlanPx
		},
		markerLabel: {
			paddingLeft: 5,
			fontWeight: 700,
		},
		widget: {
			position: 'relative',
			top: (props.showEnergyPlan ? -120 : (props.title ? 0 : -60)),
			height: (props.title ? 'auto' : 80), 
		}
	}));			
	const classes = useStyles( props);  		
	
			
	useEffect( () => {	

		console.log( 'diagramEnergyClass useEffect');
		
		console.log( "diagram..", diagram.current);
		
		var w = diagram.current ? diagram.current.offsetWidth : 0;
		console.log( '>>> width', w);
	
		setMarkers( w * (9/12));
						
	}, [diagram.current, 
		gstate.objEdit.fac_energy[props.energyIst], 
		gstate.objEdit.fac_energy[props.energyPlan]]);		
		
	
	return (
		<div id='diagramEnergyClass' className={classes.scala} style={{width:'100%'}}>
			{props.title ?
				<h4>{props.title}</h4>
				:
				''
			}	
			<div className={classes.widget}>
			<div className={classes.markerIst} ><div className={classes.markerLabel}> {props.energyIstLabel} {gstate.objEdit.fac_energy[props.energyIst]}</div></div>
			{props.showEnergyPlan ? 
				<div className={classes.markerPlan}>
					<div className={classes.markerLabel}> {props.energyPlanLabel} {gstate.objEdit.fac_energy[props.energyPlan]}</div>
				</div>	
				:
				<></>
			}
			<Box  style={{maxWidth:1200}}>	
			<Grid container direction="row" className={classes.gridDiagram} columns={9} ref={diagram} >
				<Grid item xs={1}>
					<QeeTooltip title="Energieklasse A+: Hocheffiziente Gebäudehülle, hocheffiziente Gebäudetechnik für Heizung und Warmwasser unter Einsatz erneuerbaren Energien.">
					<Box className={classes.colorBox} style={{backgroundColor:getBackgroundColor(0)}}>
						<Box className={classes.letterBox}>{getLetter(0)}</Box>
					</Box>
					</QeeTooltip>
				</Grid>
				<Grid item xs={1}>
					<QeeTooltip title="Energieklasse A: Effiziente Gebäudehülle, effiziente Gebäudetechnik für Heizung und Warmwasser unter Einsatz erneuerbaren Energien.">
					<Box className={classes.colorBox} style={{backgroundColor:getBackgroundColor(30)}}>					
						<Box className={classes.letterBox}>{getLetter(30)}</Box>						
					</Box>
					</QeeTooltip>
				</Grid>				
				<Grid item xs={1}>
					<QeeTooltip title="Energieklasse B: Gebäudehülle und Gebäudetechnik im Neubaustandard, Einsatz erneuerbarer Energien.">
					<Box className={classes.colorBox} style={{backgroundColor:getBackgroundColor(50)}}>
						<Box className={classes.letterBox}>{getLetter(50)}</Box>					
					</Box>
					</QeeTooltip>					
				</Grid>								
				<Grid item xs={1}>
					<QeeTooltip title="Energieklasse C: Umfassende Altbausanierung (Wärmedämmung und Gebäudetechnik).">				
					<Box className={classes.colorBox} style={{backgroundColor:getBackgroundColor(75)}}>
						<Box className={classes.letterBox}>{getLetter(75)}</Box>					
					</Box>
					</QeeTooltip>					
				</Grid>												
				<Grid item xs={1}>
					<QeeTooltip title="Energieklasse D: Weitgehende Altbausanierung, jedich mit deutlichen Lücken, oder ohne den Einsatz von erneuerbaren Energien.">				
					<Box className={classes.colorBox} style={{backgroundColor:getBackgroundColor(100)}}>
						<Box className={classes.letterBox}>{getLetter(100)}</Box>					
					</Box>
					</QeeTooltip>					
				</Grid>												
				<Grid item xs={1}>
					<QeeTooltip title="Energieklasse E: Teilsanierte Altbaueten z.B. neue Wärmeerzeugung und evtl. neue Geräte und Beleuchtung.">
					<Box className={classes.colorBox} style={{backgroundColor:getBackgroundColor(130)}}>
						<Box className={classes.letterBox}>{getLetter(130)}</Box>						
					</Box>
					</QeeTooltip>					
				</Grid>																
				<Grid item xs={1}>
					<QeeTooltip title="Energieklasse F: Bauten mit einzelne neuen Komponenten (z.B. nur Gebäudehülle, nur Gebäudetechnik).">				
					<Box className={classes.colorBox} style={{backgroundColor:getBackgroundColor(160)}}>
						<Box className={classes.letterBox}>{getLetter(160)}</Box>					
					</Box>
					</QeeTooltip>					
				</Grid>		
				<Grid item xs={1}>
					<QeeTooltip title="Energieklasse G: Altbauten mit veralteter Gebäudetechnik in gebrauchbarem Zustand.">				
					<Box className={classes.colorBox} style={{backgroundColor:getBackgroundColor(200)}}>
						<Box className={classes.letterBox}>{getLetter(200)}</Box>					
					</Box>
					</QeeTooltip>					
				</Grid>																
				<Grid item xs={1}>
					<QeeTooltip title="Energieklasse H: Altbauten mit sehr veralteter Gebäudetechnik in nicht gebrauchbarem Zustand.">				
					<Box className={classes.colorBox} style={{backgroundColor:getBackgroundColor(250)}}>
						<Box className={classes.letterBox}>{getLetter(250)}</Box>					
					</Box>
					</QeeTooltip>					
				</Grid>																				
			</Grid>
			</Box>
			</div>
		</div>

    );
	
}
export default DiagramEnergyClass;