import React, { useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import { QeeColorButtonPrimary } from './qeeColorButton';
import { getSdatas } from '../api/service';
import greenMarkerIcon from '../assets/app-ico.png';

const greenIcon = new L.Icon({
	iconUrl: greenMarkerIcon,
	iconSize: [25, 25],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const useStyles = makeStyles({
	root: {
		width: 250,
		cursor: 'pointer',
	},
	title: {
		fontSize: 14,
		color: 'textSecondary',
		marginTop: 0,
		marginBottom: 12,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	pos: {
		marginBottom: 12,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	role: {
		color: 'primary',
		marginBottom: 6,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	button: {
		marginTop: 12,
		width: '100%',
	},
	leafletPopupContentWrapper: {
		position: 'relative',
		'& .leaflet-popup-close-button': {
			display: 'none', // Hide the close button
		},
		'& .leaflet-popup-content': {
			margin: 0,
			padding: 10,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'stretch',
		},
		'& .leaflet-popup-content-wrapper': {
			padding: 0,
			borderRadius: 5,
		},
		'& .leaflet-popup-tip-container': {
			display: 'none', // Hide the popup tip
		}
	}	
});

const OrgMarker = ({ org, onOrgClick }) => {
	const classes = useStyles();
	const [roleText, setRoleText] = useState('');

	useEffect(() => {
		const fetchRoleText = async () => {
			try {
				if (Array.isArray(org.org_perprj_role)) {
					const roleNames = await Promise.all(
						org.org_perprj_role.map(async (role) => {
							const data = await getSdatas('sdata_perprj_roles_types', 'type', role);
							return data.length > 0 ? data[0].name : 'Keine Angaben';
						})
					);
					setRoleText(roleNames.join(', '));
				} else {
					const data = await getSdatas('sdata_perprj_roles_types', 'type', org.org_perprj_role);
					const roleName = data.length > 0 ? data[0].name : 'Keine Angaben';
					setRoleText(roleName);
				}
			} catch (error) {
				console.error('Error fetching role text:', error);
				setRoleText('Abfragefehler für Gewerke');
			}
		};

		fetchRoleText();
	}, [org.org_perprj_role]);

	const fullAddress = `${org.addr_street_o} ${org.addr_housenumber_o}, ${org.addr_locality_o}`;

	return (
		<Marker position={[org.addr_geo_lat_o, org.addr_geo_lon_o]} icon={greenIcon}>
			<Popup className={classes.leafletPopupContentWrapper}>
				<div className={classes.root} onClick={() => onOrgClick(org)}>
					<Typography variant="h5" component="h2" className={classes.title}>
						{org.org_name}
					</Typography>
					<Typography className={classes.pos} color="textSecondary">
						{fullAddress}
					</Typography>
					<Typography variant="body2" component="p" className={classes.role}>
						{roleText}
					</Typography>
					<QeeColorButtonPrimary
						disableElevation
						className={classes.button}
						onClick={(e) => {
							e.stopPropagation();
							onOrgClick(org);
						}}
					>
						<HelpOutlinedIcon style={{ marginRight: 10 }} />
						Details und Kontakt
					</QeeColorButtonPrimary>
				</div>
			</Popup>
		</Marker>
	);
};

export default OrgMarker;
