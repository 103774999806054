import React from "react";
import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { render } from 'react-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import QeeTooltip from './qeeTooltip';
import QeeHintBox from './qeeHintBox';
import {formatNumber} from '../util/helpers';

import { useAuth } from "../context/provider";


const DiagramValuation = ( props) => {
	
	console.log( 'DiagramValuation', props);

	/**
	* 	Workaround to be able to use this comp from another app. 
	*	The ext app must provide initilaized elems gstate and setObjEdit as props (aen-20230331).
	*/
	function useAuthData() {
		
		var auth = useAuth();
		
		if( props.gstate) {
			const gstate = props.gstate;
			const setObjEdit = props.setObjEdit;		
			return { gstate, setObjEdit };			
		} else {
			const { gstate, setObjEdit } = auth;			
			return { gstate, setObjEdit };
		}
	}
	const { gstate, setObjEdit } = useAuthData();   // avoid hook limitations	
			
	
	const [markerIstPx, setMarkerIstPx] = useState( 0);	
	const [valueIst, setValueIst] = useState( 0);		
	const [markerPricePx, setMarkerPricePx] = useState( 0);	
	const [price, setPrice] = useState( 0);			
		
	// create a ref
	const diagram = useRef(null);
		
	const getBackgroundColor = () => {		
		
		var col;
		col = '#30b33e'
				
		return col;		
	}
		
			
	// some consts
	const useStyles = makeStyles( theme => ({
		gridDiagram: {
			marginTop: 30		
		},
		colorBox: {
			height:20, 
			fontWeight:700			
		},
		infoLabel: {
			fontSize: '0.9em',
			color:'#444'
		},		
		markerIst: {
			height:130, 
			width:3, 
			paddingLeft:5,
			backgroundColor:'#777', 
			position:'relative', 
			top:135, 
			left:markerIstPx
		},
		markerPrice: {
			height:80, 
			width:3, 
			paddingLeft:5,
			backgroundColor:'#222', 
			position:'relative', 
			top:55, 
			left:markerPricePx
		},		
		markerLabelIst: {
			paddingLeft: 5,
			fontWeight: 600,
			color:'#555'			
		},
		markerLabelPrice: {
			paddingLeft: 5,
			fontWeight: 700,
		},		
		markerLabelPriceRed: {
			paddingLeft: 5,
			fontWeight: 700,
			color:'#a22'
		},				
		markerLabelPriceGreen: {
			paddingLeft: 5,
			fontWeight: 700,
			color: '#30b33e'
		},						
		widget: {
			position: 'relative',
			top: (props.title ? 0 : -60),
			//height: (props.title ? 'auto' : 80), 
		}
	}));			
	const classes = useStyles( props);  		
	
	
	useEffect( () => {	

		console.log( 'DiagramValuation useEffect');
		
		console.log( "diagram..", diagram.current);
		
		var w = diagram.current ? diagram.current.offsetWidth : 0;
		console.log( '>>> diagram width', w);
		
		var price = gstate.objEdit.fac_price.value;
		var low = gstate.objEdit.fac_valuation.valueRange.lower;
		var up = gstate.objEdit.fac_valuation.valueRange.upper;

		var valueIst = low + (up-low) * 0.5;		
		setValueIst( valueIst); 
		console.log( 'valueIst', valueIst);
		var pxIst = (valueIst-low) * w/(up-low);		
		console.log( '>>> markerIstPx', pxIst);
		setMarkerIstPx( pxIst);
		
		setPrice( price); 
		console.log( 'price', price);
		var pxPrice = (price-low) * w/(up-low);		
		console.log( '>>> markerPricePx', pxPrice);
		
		if( price > up)
			pxPrice = w;
		if( price < low)
			pxPrice = 0;			
		
		setMarkerPricePx( pxPrice);
						
	}, [diagram.current, 
		gstate.objEdit.fac_valuation.valueRange.lower,
		gstate.objEdit.fac_valuation.valueRange.upper]);		
	
	
	const getHint = () => {
		return gstate.objEdit.fac_valuation.hint;
	}	

	return (
		<div style={{width:'100%', maxWidth:1200}}>
			{props.title ?
				<h2>{props.title}</h2>
				:
				''
			}
			<Grid container direction="row">
				<Grid item xs={12} sm={12} md={6}>
					<div className={classes.widget}>
					<div className={classes.markerIst}><div className={classes.markerLabelIst}>Ermittelt</div><div className={classes.markerLabelIst}> {formatNumber( valueIst, 'EUR', 0)}</div></div>
					<div className={classes.markerPrice}>
						{(() => {
							if( price>valueIst)
								return <><div className={classes.markerLabelPriceRed}>Kaufpreis</div><div className={classes.markerLabelPriceRed}> {formatNumber( price, 'EUR', 0)}</div></>							
							else if( price<valueIst)		
								return <><div className={classes.markerLabelPriceGreen}>Kaufpreis</div><div className={classes.markerLabelPriceGreen}> {formatNumber( price, 'EUR', 0)}</div></>
							else
								return <><div className={classes.markerLabelPrice}>Kaufpreis</div><div className={classes.markerLabelPrice}> {formatNumber( price, 'EUR', 0)}</div></>
						})()}
					</div>					
					<Box  style={{maxWidth:800}}>	
						<Grid container direction="column" className={classes.gridDiagram} columns={12} ref={diagram} >
							<Grid item>
								<Box>
									<div className={classes.infoLabel} style={{display:'inline',width:'50%'}}>{formatNumber( gstate.objEdit.fac_valuation.valueRange.lower, 'EUR', 0)}</div>
									<div className={classes.infoLabel} style={{display:'inline',width:'50%',float:'right',textAlign:'right'}}>{formatNumber( gstate.objEdit.fac_valuation.valueRange.upper, 'EUR', 0)}</div>
								</Box>
							</Grid>
							<Grid item>
								<Box className={classes.colorBox} style={{backgroundColor:getBackgroundColor()}}>
								</Box>
							</Grid>
							<Grid item>
								<Box>
									<div className={classes.infoLabel} style={{display:'inline',width:'50%'}}>Minimalwert</div>
									<div className={classes.infoLabel} style={{display:'inline',width:'50%',float:'right',textAlign:'right'}}>Maximalwert</div>
								</Box>
							</Grid>							
						</Grid>
					</Box>
					</div>						
				</Grid>
				{props.showHint ?
					<Grid item xs={12} sm={12} md={6} style={{padding:gstate.isXS ? 0 : 20}}>
						<QeeHintBox type="info" text={<React.Fragment><strong>Preisinformation:</strong> <span dangerouslySetInnerHTML={{__html: getHint()}}></span></React.Fragment>} />
					</Grid>			
				: ''}	
			</Grid>
		</div>

    );
	
}
export default DiagramValuation;