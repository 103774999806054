import React, { useState, useEffect } from 'react';
import {
	Card,
	CardContent,
	CardMedia,
	Typography,
	Button,
	CircularProgress,
	Grid,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import { formatNumber } from '../util/helpers';

const CartItem = ({ item, onIncreaseQuantity, onDecreaseQuantity }) => {
	const { art_image_url, art_name, art_price, art_count } = item;

	return (
		<TableRow>
			<TableCell>
				<img src={art_image_url} alt={art_name} style={{ width: '50px', height: '50px' }} />
			</TableCell>
			<TableCell>{art_name}</TableCell>
			<TableCell>{formatNumber(art_price, 'EUR', 2)}</TableCell>
		</TableRow>
	);
};

const QeeCart = ({ cartItems, onCustomerInfo }) => {
	const [loading, setLoading] = useState(false);
	const subtotal = cartItems.reduce((total, item) => total + item.art_price, 0);

	const handleCustomerInfo = () => {
		setLoading(true);
		// Perform the order action here, for example, making an API call to place the order
		// Once the order is completed, setLoading(false);
		onCustomerInfo(); 
	};

	const handleIncreaseQuantity = (item) => {
		// Logic to increase the quantity of an item in the cart
	};

	const handleDecreaseQuantity = (item) => {
		// Logic to decrease the quantity of an item in the cart
	};

	return (
		<div style={{ padding: 20 }}>
			{loading ? (
				<CircularProgress />
			) : cartItems ? (
				<>
					<div>
						<Typography variant="h2">Deine Bestellung</Typography>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell>Produkt</TableCell>
									<TableCell>Preis</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{cartItems.map((item) => (
									<CartItem
										key={item.art_name}
										item={item}
										onIncreaseQuantity={handleIncreaseQuantity}
										onDecreaseQuantity={handleDecreaseQuantity}
									/>
								))}
							</TableBody>
						</Table>
						<Typography style={{marginTop:10}}>Total: {formatNumber(subtotal, 'EUR', 2)}</Typography>
						<Typography style={{marginTop:5}} variant="body3">Steuern und Versandkosten für Digitalprodukte inklusive.</Typography>
					</div>

					<Button
						variant="contained"
						color="primary"
						onClick={handleCustomerInfo}
						style={{ position: 'absolute', bottom: '20px', right: '20px' }}
						disabled={!cartItems.length} // Disable the button if cartItems array is empty
					>
						Bestellung ausfüllen
					</Button>
				</>
			) : (
				<p>Kein Artikel seletiert!.</p>
			)}
		</div>
	);
};

export default QeeCart;
