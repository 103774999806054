import React, { useState, useEffect, useRef } from "react";
import { makeStyles }  from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import style from "./AddPayMethod.module.scss";

import { useElements, useStripe } from "@stripe/react-stripe-js";

import { Country, State, City } from "country-state-city";
import Select from "react-select";
import CircularProgress from '@material-ui/core/CircularProgress';

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from '../../qeeColorButton';

import { postRequest } from "../utils/api";
import { CardElement } from "@stripe/react-stripe-js";

export default function AddPayMethod( props) {
	
	const stripe = useStripe();

	const elements = useElements();
	const card = useRef();
	
	const { enqueueSnackbar } = useSnackbar();		

	const [cardInfo, setCardInfo] = useState({
		name: "",
		expiry: "",
		number: "",
		address: {
			line: "",
			postalCode: "",
		},
	});

	const [isLoading, setIsLoading] = useState( false);			
	
	const [locations, setLocations] = useState({ countries: "", states: "", cities: "" });
	const [selectedLocation, setSelectedLocation] = useState({ country: {}, city: {}, state: {} });
	const [objErrors, setObjErrors] = useState( {});				
	const [errorMsg, setErrorMsg] = useState( '');			
	
	// some consts	
	const useStyles = makeStyles({
		//...props,	
		button: {
			width: 200,
			height: 48
		}		
	});	
	const classes = useStyles();			

	function handleChangeAddressLine(e) {
		console.log( 'CHANGE', e.target)
		const { value } = e.target;
		setCardInfo((prev) => {
			return { ...prev, address: { ...prev.address, line: value} };
		});
	}

	
	// function handleChangePostalCode(e) {
	//   const { value } = e.target;
	//   setCardInfo((prev) => {
	//     return { ...prev, address: { ...prev.address, postalCode: value } };
	//   });
	// }

	
	function handleChangeName(e) {
		const { value } = e.target;
		setCardInfo((prev) => {
			return { ...prev, name: value };
		});
	}

	
	function parseForSelect(arr) {
		return arr.map((item) => ({
			label: item.name,
			value: item.isoCode ? item.isoCode : item.name,
		}));
	}

	
	function handleSelectCountry(country) {
		const states = State.getStatesOfCountry(country.value);
		setSelectedLocation((prev) => {
			return { ...prev, country };
		});

		setLocations((prev) => ({ ...prev, states: parseForSelect(states) }));
	}

	
	function handleSelectState(state) {
		const cities = City.getCitiesOfState(selectedLocation.country.value, state.value);
		setSelectedLocation((prev) => {
			return { ...prev, state };
		});

		setLocations((prev) => ({ ...prev, cities: parseForSelect(cities) }));
	}

	
	function handleSelectCity(city) {
		setSelectedLocation((prev) => {
			return { ...prev, city };
		});
	}

	
	async function handleSubmit() {
		
		console.log( '--> handleSubmit', cardInfo);
		
		let usr = JSON.parse( sessionStorage.getItem( 'usr'));	
		
		if( !cardInfo.name) {
			setObjErrors( {name:'Du must den Karteninhaber eingeben!'});
			return;
		}
			
		
		const address = cardInfo.address;
		const billingDetails = {
			name: cardInfo.name,
			address: {
				country: address.country,
				state: address.state,
				city: address.city,
				line1: address.line,
			},
		};

		try {
			
			setIsLoading( true);
			
			stripe
				.createPaymentMethod({
					type: "card",
					billing_details: billingDetails,
					card: elements.getElement(CardElement),
				})
				.then((resp) => {
					console.log( 'paymentMethod', resp.paymentMethod);
					postRequest( "/api/payment/method/attach/"+usr.usrId, { paymentMethod: resp.paymentMethod })
					.then((resp) => {
						/* Handle success */
						console.log( '<-- handleSubmit', resp);						
						setIsLoading( false);
						enqueueSnackbar( 'Die Zahlungsmethode wurde erfolgreich hinzugefügt.', {variant: 'success'});								
						props.handleClose();
					})
					.catch((err) => {
						/*Handle Error */
						console.error( '<-- handleSubmit ERR', err);						
						setIsLoading( false);						
						enqueueSnackbar( 'Die Zahlungsmethode konnte nicht hinzugefügt werden!', {variant: 'error'});								
						setErrorMsg( 'Ungültige Eingaben! Korrigiere die Eingabefelder und prüfe erneut!');
					});
					console.log( 'createPayMethod', resp);
				});
		} catch (err) {
			/* Handle Error*/
			console.error( '<-- handleSubmit ERR', err);
			setIsLoading( false);			
		}
	}

	
	useEffect(() => {
		const allCountry = Country.getAllCountries();

		setLocations((prev) => {
			return { ...prev, countries: parseForSelect(allCountry) };
		});
	}, []);

	
	/* <div className={style.title}>Zahlungsmethode hinzufügen</div> */
	return (
		<div className={style.wrapper}>
			<div className={style.innerWrapper}>			
				<div className={style.row} style={{marginTop: 40, marginBottom:20}}>
					<label>Erfasse hier die Daten deiner Kreditkarte. Diese Daten werden für künftige Zahlungen in deinem Account gespeichert.</label>
				</div>
				<div className={style.row}>
					<label>Karteninhaber*</label>
					<input
						onChange={handleChangeName}
						type="text"
						name="name"
						placeholder="Name des Karteninhabers"
					/>
					{objErrors.name ? <div className={style.errorMsg}>{objErrors.name}</div> : ''}
				</div>
				<div className={style.rowPaymentInput}>
					<CardElement ref={card} />
				</div>

				<div className={style.addressWrapper}>
					<div className={style.row}>
						<label>Adresse</label>
						<input
							onChange={handleChangeAddressLine}
							type="text"
							name="Adresse"
							placeholder="Vollständige Rechnungsadresse"
							autocomplete="off"
							required							
						/>
					</div>
					
					<div className={style.rowSelect}>
						<div style={{width:'100%'}}>
							<label>Land</label>
							<Select
								isClearable={true}
								isSearchable={true}
								name="country"
								value={selectedLocation.country}
								options={locations.countries}
								onChange={handleSelectCountry}
								required								
							/>
						</div>	
					</div>

					<div className={style.rowSelect}>
						<div style={{width:'100%'}}>
							<label>Bundesland</label>
							<Select
								isClearable={true}
								isSearchable={true}
								name="state"
								value={selectedLocation.state}
								options={locations.states}
								onChange={handleSelectState}								
							/>
						</div>	
					</div>
			
					<div className={style.rowSelect}>
						<div style={{width:'100%'}}>
							<label>Stadt</label>
							<Select
								isClearable={true}
								isSearchable={true}
								name="city"
								value={selectedLocation.city}
								options={locations.cities}
								onChange={handleSelectCity}
							/>
						</div>

						{/* <div>
							<label>Postal Code</label>
							<input onChange={handleChangePostalCode} type="text" placeholder="Enter Zip Code" />
						</div> */}
					</div>
					
					{errorMsg ?
						<div className={style.errorMsg}>{errorMsg}</div>
					:''}

					<div className={style.btnContainer}>
						<QeeColorButtonPrimary disableElevation 
							className={classes.button} 
							onClick={handleSubmit}>
							{isLoading ?
								<CircularProgress style={{color:'#fff'}}/>
								:	
								<>Speichern</>
							}																							
						</QeeColorButtonPrimary>								
					</div>
				</div>
			</div>
		</div>
	);
}
