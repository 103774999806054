// qeeLoginSocialButtons.js
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
//import MicrosoftIcon from '@mui/icons-material/LinkedIn';
//import MicrosoftIcon from '@mui/icons-material/Dashboard';
import { FaMicrosoft } from "react-icons/fa";
import { makeStyles } from '@material-ui/core/styles';

import { useAuth } from "../context/provider";

const useStyles = makeStyles((theme) => ({
	roundedButton: {
		borderRadius: theme.spacing(1),
		borderColor: 'currentColor',
		borderWidth: 1,
		borderStyle: 'solid',
		minWidth: 120,
		whiteSpace: 'nowrap'
	},
	lineContainer: {
		marginTop: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	line: {
		flex: 1,
		borderTop: `1px solid ${theme.palette.grey[500]}`,
	},
	lineText: {
		margin: theme.spacing(0, 2),
		color: theme.palette.grey[500],
	},
}));


const GoogleButton = () => {
	  
	const classes = useStyles();	  
	  
    const { proxy } = require('../../package.json');
    const googleAuthUrl = `${proxy}/api/auth/google/start`;

    return (
		<Button
			href={googleAuthUrl}
			variant="contained"
			color="secondary"
			startIcon={<GoogleIcon />}
			fullWidth
			disableElevation
			className={classes.roundedButton}
		>
			Google
		</Button>
    );
};


const FacebookButton = () => {

	const classes = useStyles();  
	  
    const { proxy } = require('../../package.json');
    const googleAuthUrl = `${proxy}/api/auth/facebook/start`;

    const handleFacebookLogin = () => {
      // ...
    };

    return (
		<Button
			href={googleAuthUrl}
			variant="contained"
			color="secondary"
			startIcon={<FacebookIcon />}
			onClick={handleFacebookLogin}
			fullWidth
			disableElevation
			className={classes.roundedButton}
		>
			Facebook
		</Button>
    );
};


const AppleButton = () => {
	  
	const classes = useStyles();	  
	  
    const { proxy } = require('../../package.json');
    const googleAuthUrl = `${proxy}/api/auth/google/start`;

    const handleAppleLogin = () => {
      // ...
    };

    return (
		<Button
			href={googleAuthUrl}
			variant="contained"
			color="secondary"
			startIcon={<AppleIcon />}
			onClick={handleAppleLogin}
			disableElevation
			fullWidth
			className={classes.roundedButton}
		>
			Apple
		</Button>
    );
};


const MicrosoftButton = () => {
	const classes = useStyles();
	  
    const { proxy } = require('../../package.json');
    const googleAuthUrl = `${proxy}/api/auth/microsoft/start`;

    const handleMicrosoftLogin = () => {
      // ...
    };

    return (
		<Button
			href={googleAuthUrl}
			variant="contained"
			color="secondary"
			startIcon={<FaMicrosoft />}
			onClick={handleMicrosoftLogin}
			disableElevation
			fullWidth
			className={classes.roundedButton}
		>
			Microsoft
		</Button>
    );
};


const ButtonContainer = () => {
	const classes = useStyles();
	
	const { gstate } = useAuth();				

	return (
		<Grid container direction="row" spacing={2} justify="center">
			<Grid item lg={6} xl={6}>
				<GoogleButton />
			</Grid>
			<Grid item lg={6} xl={6}>
				<MicrosoftButton />
			</Grid>			
			{gstate.isBetaVer ?							
				<>
				<Grid item lg={6} xl={6}>
					<FacebookButton />
				</Grid>			
				<Grid item lg={6} xl={6}>
					<AppleButton />
				</Grid>
				</>
			:''}
		</Grid>
	);
};


const LoginSocialButtons = () => {
	const classes = useStyles();
	
	return (
		<>
			<div className={classes.lineContainer} style={{marginBottom:20}}>
				<div className={classes.line} />
				<div className={classes.lineText}>oder einloggen mit</div>
				<div className={classes.line} />
			</div>
			<Grid container direction="row" alignItems="stretch" justify="center">
				<ButtonContainer />
			</Grid>
		</>
  );
};
export default LoginSocialButtons;
