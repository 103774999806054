import React, { useCallback } from 'react';
import { Box, Button, useTheme } from '@mui/material';

import { themeV4, themeV5 } from '../../../../../core/theme.js';

export function MuiSelectInput({
  chatController,
  actionRequest,
  isQuickQuestion = false, // Accept an isQuickQuestion prop
}) {
  const theme = themeV4;
  
  const setResponse = useCallback((value) => {
    const option = actionRequest.options.find(o => o.value === value);

    if (!option) {
      throw new Error(`Unknown value: ${value}`);
    }

    const res = {
      type: 'select',
      value: option.text,
      answerType: option.answerType,
      answer: option.answer,
      quickQuestions: option.quickQuestions,
      option
    };
    chatController.setActionResponse(actionRequest, res);
  }, [actionRequest, chatController]);

  // Style customization based on isQuickQuestion
  const buttonSx = isQuickQuestion ? {
    borderColor: theme.palette.primary.main, // Apply primary color for border
    color: theme.palette.primary.main, // Apply primary color for text
    '&:hover': {
      backgroundColor: '#fff', // Lighten on hover for quick question buttons
    },
  } : {};

  return (
    <Box
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: isQuickQuestion ? 'row' : 'column',
        '& > *': {
          flex: '0 0 auto',
          maxWidth: '100%',
        },
        '& > * + *': isQuickQuestion ? {
          ml: 2, // Apply margin-left for horizontal layout
        } : {
          mt: 2, // Apply margin-top for vertical layout
        }
      }}
      className={`qeeChatButtonContainer ${isQuickQuestion ? 'qeeQuickQuestionContainer' : ''}`}
    >
      {actionRequest.options.map((o, index) => (
        <Button
          key={index}
          type="button"
          value={o.value}
          onClick={(e) => setResponse(e.currentTarget.value)}
          variant="contained"
          color="primary"
          sx={buttonSx} // Apply conditional styles here
		  className={`qeeChatButton ${isQuickQuestion ? 'qeeQuickQuestion' : ''}`}
        >
          {o.text}
        </Button>
      ))}
    </Box>
  );
}
