// qeeSelectRecipients.js

import react, { useState, useEffect, useLayoutEffect } from 'react';
import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { FormControl, FormControlLabel, Paper, Chip, Checkbox, InputLabel, Select, MenuItem, Typography } from "@material-ui/core";

import StepZilla from "react-stepzilla";

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	}
});


const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const QeeSelectRecipients = ( props) => {
	
	const thisStep = props.step;			
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();				
	
	const [validationErrors, setValidationErrors] = useState( []);		
	
	const [hasErrors, setHasErrors] = useState( true);				
	const [errors, setErrors] = useState( {});					
	const [recEmail, setRecEmail] 	= React.useState({ error: false, msg: '', value: ''});		
	const [chipData, setChipData] = React.useState([]);	
	const [showAdditionalMessage, setShowAdditionalMessage] = React.useState(false);
	const [recName, setRecName] 	= React.useState({ error: false, msg: '', value: ''});			
	const [emailText, setEmailText] = React.useState({ error: false, msg: '', value: ''});	
	const options = [
		{"label":"Mitbearbeiter", "value":"writer"},
		{"label":"Betrachter", "value":"reader"}
	]
	const [selectedRoleValue, setSelectedRoleValue] = useState( props.roleValue ? props.roleValue : options[0].value);

	interface ChipData {
		key: number;
		label: string;
	}

	const ListItem = styled('li')(({ theme }) => ({
	  margin: theme.spacing(0.5),
	}));	
	
	
	// field conditions
	const isValidEmail = ( vl) => {
		if( !vl)
			vl = recEmail.value;
		if( vl) {
			const value = vl.trim().toLowerCase();
			return( re.test( value));		
		} else {
			return true;
		}
	}
	const isValidChipData = ( vl) => {
		if( !vl)
			vl = chipData;		
		return vl.length ? true : false;		
	}	
	/*
	const isValidName = ( vl) => {
		if( !vl)
			vl = recName.value;
		const value = vl;
		return vl.length>=3 && vl.length<=48 ? true : false;		
	}
	*/	
	const isValidText = ( vl) => {
		if( !vl)
			vl = emailText.value;		
		return vl.length>=5 && vl.length<=4096 ? true : false;
	}	
	
		
	const handleChange = (event) => {
		//console.log( 'handleChange: ' + event.target.name, event.target.value);
		
		if( event.target.name==='recEmail') {					
			const value = event.target.value.trim().toLowerCase();			
			setRecEmail({ value, error: false, msg: ''});	
			setHasErrors( !isValidEmail( value));
		}
		
		if( showAdditionalMessage) {
			/*
			if( event.target.name==='recName') {					
				const value = event.target.value;
				setRecName({ value, error: false, msg: ''});	
				setHasErrors( !isValidName( value));
			}		
			*/
			if( event.target.name==='emailText') {
				//let value = event.target.value.replace( '\n', ' ');			
				const value = event.target.value;
				setEmailText({ value, error: false, msg: ''});							
				setHasErrors( !isValidText( value));									
			}
		}
	};
	
	const handleAddEmail = (event) => {
	  if (event.key === 'Enter' && event.target.value.trim()) {
		event.preventDefault();
		const email = event.target.value.trim();

		if( !isValidEmail( email) || !email)
			return;
		
		// Check if the email already exists in chipData
		const existingEmail = chipData.find((chip) => chip.label === email);
		if (existingEmail) {
			// Email already exists, handle duplicate logic here
			console.log('Email already exists:', email);
			return;
		}

		// Add the new chip with a unique key
		const newChip = { key: chipData.length + 1, label: email };
		setChipData([...chipData, newChip]);
		setRecEmail({value:'', error:false, msg:''})

		event.target.value = '';
	  }
	};

	
	const handleDelete = (chipToDelete: ChipData) => () => {
		setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
	};	
	

	const handleSelectionRoleChange = (event) => {
		const srv = event.target.value;
		setSelectedRoleValue( srv);
	};	
	
	
	const handleBlur = (event) => {
		//console.log( 'handleBlur: ' + event.target.name, event.target.value);
				
		if( event.target.name==='recEmail') {									
			const value = event.target.value.trim().toLowerCase();
			//setRecEmail({value, error: !isValidEmail( value), msg: 'Ungültige Email!'});	
			if( !isValidChipData( value)) { //!isValidEmail( value)) {
				setRecEmail({value, error: !isValidEmail( value), msg: 'Ungültige Email!'});
				setHasErrors( true);
			} else {
				if( !isValidEmail( value)) {
					setRecEmail({value, error: !isValidEmail( value), msg: 'Ungültige Email!'});	
					return;
				}
				
				if( !value)
					return;
		
				// Check if the email already exists in chipData
				const existingEmail = chipData.find((chip) => chip.label === value);
				if (existingEmail) {
					// Email already exists, handle duplicate logic here
					console.log('Email already exists:', value);
					return;
				}

				// Add the new chip with a unique key
				const newChip = { key: chipData.length + 1, label: value };
				setChipData([...chipData, newChip]);
				setRecEmail({value:'', error:false, msg:''})				
			}
				
		}
		
		if( showAdditionalMessage) {	
			/*
			if( event.target.name==='recName') {									
				const value = event.target.value.trim().toLowerCase();
				setRecName({value, error: !isValidName( value), msg: 'Ungültiger Name, mindestens 3 Zeichen erforderlich!'});	
				if( !isValidName( value))
					setHasErrors( true);
			}		
			*/
			if( event.target.name==='emailText') {
				const value = event.target.value;
				setEmailText({ value, error: !isValidText( value), msg: 'Der Text muss mindestens 5 Zeichen lang sein!'});							
				if( !isValidText( value))
					setHasErrors( true);			
			}
		}
			
	};
			
			
	const handleShowAdditionalMessage = (event) => {
		setShowAdditionalMessage(prevState => !prevState);
		//setShowAdditionalMessage( event.target.checked);
	};			
			
			
	useEffect(()=> {  	
		if( props.fctOnHasErrors) 
			props.fctOnHasErrors( true);	
	}, []);						
			
	
	useEffect(()=> {  	
		validate();	
	}, [chipData.length, recEmail.error, emailText.error, hasErrors, showAdditionalMessage]);			// recName.error,  hasErrors
	
	
	useEffect(()=> {  		
		if( props.fctOnChanges) {
			var o = {
				//recEmail: 	recEmail.value,
				recEmail: 	chipData.map(obj => obj.label),
				//recName: 	recName.value,
				recRole: 	selectedRoleValue,
				emailText: 	emailText.value
			};
			props.fctOnChanges( o);
		}	
	}, [chipData.length, selectedRoleValue, emailText.value]);   // recName.value, 

		
	/**
	* validate() - 
	*/
	const validate = () => { 
		
		console.log( '--> validate for QeeSelectRecipients');
				
		if( props.fctOnHasErrors) {
			// with additional message
			if( showAdditionalMessage) {
				if( !chipData.length || recEmail.error || emailText.error || hasErrors || !isValidEmail() || !isValidText())	// !isValidName() ||     recName.error || 	
					props.fctOnHasErrors( true);
				else
					props.fctOnHasErrors( false);
			// no additional message here	
			} else {
				if( !chipData.length || recEmail.error || hasErrors || !isValidEmail())		
					props.fctOnHasErrors( true);
				else
					props.fctOnHasErrors( false);				
			}
		}
		
		console.log( '<-- validate')		
	}
					
	/*   
	  <Grid item xs={12}>
		<TextField
		  variant="outlined"
		  required
		  fullWidth
		  id="recName"
		  label="Name"
		  placeholder="Name des Empfängers"
		  name="recName"
		  value={recName.value}
		  helperText={recName.error ? recName.msg : ''}
		  error={recName.error ? true : false}
		  onChange={handleChange}
		  onBlur={handleBlur}
		/>
	  </Grid>
	*/										
	return (
		<div className="form-group" style={{ marginTop: 60 }}>
		  <form noValidate>
			<Grid container spacing={2}>
			  <Grid item xs={12} style={{marginBottom:12}}>
				<Typography variant="h1">Wähle die Personen aus</Typography>
				<Typography variant="p">{props.label}</Typography>
			  </Grid>
			  <Grid item xs={12}>
				<TextField
				  variant="outlined"
				  required
				  fullWidth
				  id="recEmail"
				  label="Email Adresse"
				  placeholder="Email Adressen der Empfänger"
				  name="recEmail"
				  value={recEmail.value}
				  helperText={recEmail.error ? recEmail.msg : ""}
				  error={recEmail.error ? true : false}
				  onChange={handleChange}
				  onBlur={handleBlur}
				  onKeyDown={handleAddEmail}
				  InputProps={{
					startAdornment: (
						<Paper
							sx={{
								display: 'flex',
								justifyContent: 'center',
								flexWrap: 'wrap',
								listStyle: 'none',
								flexDirection: 'row',
								p: 0,
								m: 0,
								boxShadow: 'none',
							}}
							style={{ display: 'flex', listStyle: 'none', flexDirection: 'row', padding: 0 }}
							component="ul"
							elevation={0}	  
						>
							{chipData.map((data) => {
								return (
									<ListItem key={data.key}>
										<Chip
											label={data.label}
											onDelete={handleDelete(data)}
										/>
									</ListItem>
								);
							})}
						</Paper>				  			  		  		 
					)}}			  
				/>
			  </Grid>

			  <Grid item xs={12}>
				<FormControl variant="outlined" fullWidth>
				  <InputLabel>Rolle</InputLabel>
				  <Select
					label="Rolle"
					value={selectedRoleValue}
					disabled={props.roleDisabled}
					onChange={handleSelectionRoleChange}
				  >
					{options.map((option) => (
					  <MenuItem key={option.value} value={option.value}>
						{option.label}
					  </MenuItem>
					))}
				  </Select>
				</FormControl>
			  </Grid>

			  <Grid item xs={12}>
				<FormControlLabel
				  control={
					<Checkbox
						checked={showAdditionalMessage}
						onChange={handleShowAdditionalMessage}
						name="showAdditionalMessage"
						sx={{
							color: (theme) => theme.palette.primary.main,
							'&.Mui-checked': {
								color: (theme) => theme.palette.primary.main,
							},
						}}					  
					/>
				  }
				  label="Zusätzliche Nachricht"
				/>
			  </Grid>

			  {showAdditionalMessage && (
				<>
				  <Grid item xs={12}>
					<TextField
					  variant="outlined"
					  required
					  fullWidth
					  id="emailText"
					  label="Email Text"
					  placeholder="Schreibe hier eine kurze personalisierte Nachricht für den Empfänger"
					  name="emailText"
					  multiline
					  rows={7}
					  rowsMax={20}
					  value={emailText.value}
					  helperText={emailText.error ? emailText.msg : ''}
					  error={emailText.error ? true : false}
					  onChange={handleChange}
					  onBlur={handleBlur}
					/>
				  </Grid>
				</>
			  )}

			</Grid>
		  </form>
		</div>
	);
}	
export default withStyles(styles)(QeeSelectRecipients);