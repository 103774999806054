// qeeChatDialog.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react'; 
import React, { useImperativeHandle } from 'react';

import withStyles from '@material-ui/core/styles/withStyles'; 
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import {QeeChatController} from './qeeChatController.js';
import {QeeMuiChat} from './qeeMuiChat.js';
import Box from '@material-ui/core/Box';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

import axios from 'axios';

import QeeSlideDialog from './qeeSlideDialog';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';

import { useSnackbar } from 'notistack';

import { getOpenaiDesc, postOpenaiMessage } from "../api/service";

import css from '../core/App.css';

import { useAuth } from "../context/provider";
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';

const styles = (theme) => ({
});


// main fct
const qeeChatDialog = React.forwardRef((props, forwardedRef) => {	

	const theme = useTheme();

	const [chatCtl, setChatCtl] = React.useState( new QeeChatController({showDateTime:false}));

	const { enqueueSnackbar } = useSnackbar();	

	const isInitializingChatRef = useRef(false);
	const savedChat = sessionStorage.getItem('chat');
	const [chat, setChat] = useState(savedChat !== null ? JSON.parse( savedChat) : {});
	const [hasErrorsDlgChat, setHasErrorsDlgChat] = useState( true);				
	const [changesDlgChat, setChangesDlgChat] = useState( {});	

	const { gstate, setOpenQeeDialog, setIsFetching } = useAuth();				
	
	const [state, setState] = useState({
		url: '',
		submitDisabled: true,
	});
	
	const chatRef = useRef(chat);

	useEffect(() => {
		chatRef.current = chat;
	}, [chat.id]);

	
	// some consts
	const useStyles = makeStyles( theme => ({
		//...props
		accordionSummary: {
			backgroundColor: '#f00'
		},
		accordionBox: {
			fontWeight:'bold', 
			m:1, 
			lineHeight:1.2, 
			color:theme.palette.primary.contrastText,
		},
		icon:{
			color:theme.palette.primary.contrastText,
		}
	}));			
	const classes = useStyles( props);


	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		ali: ()=>{alert(123)},
		handleChatOpen: handleChatOpen,						
		handleChatClose: handleChatClose
	}));	
	
	
	const handleChatOpen = async (newChat) => {
		console.log('handleChatOpen', newChat);
				
		chatRef.current = newChat;
		isInitializingChatRef.current = true;
		
		// evtl. remove the chat which is empty
		if( !newChat) {
			sessionStorage.removeItem( 'chat');
		}
		
		// Clear all existing messages in the ChatController
		if (chatCtl) {
			chatCtl.clearMessages();
		}
		chatCtl.removeOnMessagesChanged(onMessagesChanged);
		chatCtl.addOnMessagesChanged(onMessagesChanged);

		// Add messages from newChat.msgs array to the ChatController
		if( newChat) {
			var msgs = [];
			for (const msg of newChat.chat_msgs) {
				msgs.push({
					type: msg.type,
					content: msg.content,
					self: msg.self,
					avatar: msg.self ? '' : <TipsAndUpdatesIcon />,
					//username: msg.self ? 'eu' : '',
				});
			}
			chatCtl.setMessages( msgs);			
			/*
			for (const msg of newChat.chat_msgs) {
				chatCtl.addMessage({
					type: msg.type,
					content: msg.content,
					self: msg.self,
					avatar: msg.self ? '' : <TipsAndUpdatesIcon />,
					//username: msg.self ? 'eu' : '',
				});
			}
			*/
		}

		// Set action request
		const name = await chatCtl.setActionRequest({ type: 'text', always: true, self:true });

		setChat((prevChat) => ({
			...prevChat,
			...newChat,
		}));
		
		isInitializingChatRef.current = false;

		setOpenQeeDialog({ open: true, id: 'dlgChat' });
	};

		
	const handleChatClose = () => {
		setOpenQeeDialog( false);	
	};
	
	
	const onMessagesChanged = async (msgs) => {
		
		console.log( '--> onMessagesChanged', msgs);
		
		// Skip processing messages during initialization
		if (isInitializingChatRef.current) {
			return;
		}
		
		const currentChat = chatRef.current;
		
		if (msgs.length === 0) {
			console.log( 'onMessagesChanged msgs.length==0');
			console.log( '<-- onMessagesChanged');
			return;
		}

		const lastMessage = msgs[msgs.length - 1];

		// Ensure the message isn't already an OpenAI response
		if (lastMessage.self && lastMessage.type === 'text') {
			
			const respMsg = await postOpenaiMessage(currentChat.id, lastMessage.content);
			
			console.log( 'onMessagesChanged respMsg', respMsg);

			// Insert OpenAI response into chat
			const openAIResponse = {
				content: respMsg,
				type: 'text',
				createdAt: new Date(),
				self: false,
				avatar: 'AVATAR',				
			};
			// Update chat messages and add the OpenAI response
			setChat((prevChat) => ({
				...prevChat,
				chat_msgs: Array.isArray(prevChat.chat_msgs) ? [...prevChat.chat_msgs, openAIResponse] : [openAIResponse],
			}));
			// Add OpenAI response to the chat controller
			chatCtl.addMessage(openAIResponse);

		} else {
			setChat((prevChat) => ({
				...prevChat,
				chat_msgs: msgs,
			}));	  
		}
		
		console.log( '<-- onMessagesChanged');		
	};

/*	
	useEffect(() => {
		
		// Add the onMessagesChanged handler when the component mounts
		chatCtl.addOnMessagesChanged(onMessagesChanged);

		// Remove the onMessagesChanged handler when the component unmounts
		return () => {
			chatCtl.removeOnMessagesChanged(onMessagesChanged);
		};
	}, []); 
*/

					
	const handleSubmitDlgChat = () => {
		console.log( 'handleSubmitDlgChat SUBMITTING >>>>', changesDlgChat);
		
		setOpenQeeDialog( {open:false, id:'dlgChat'});
	}
	
				
	return (		
		<QeeSlideDialog 
			id='dlgChat'
			title='GPT Expertenchat' 
			formComponent={
				<div style={{marginTop:70}}>
					<QeeMuiChat chatController={chatCtl} />
				</div>
			}					
			submitDisabled={hasErrorsDlgChat}
			fctOnSubmit={handleSubmitDlgChat}
			hideDialogActions={true}
		>
		</QeeSlideDialog>
	)
})	
export default withStyles(styles)(qeeChatDialog);