// theme.js

import { createMuiTheme as createThemeV4 } from '@material-ui/core/styles';
import { createTheme as createThemeV5 } from '@mui/material/styles';

const QEE_COLORS = {
    primary: process.env.QEE_PRIMARY_COLOR || '#004c3f',
    secondary: process.env.QEE_SECONDARY_COLOR || '#aaffff',
    tertiary: process.env.QEE_TERTIARY_COLOR || '#aaa',
    license: process.env.QEE_LICENSE_COLOR || '#9c27b0',
    newFeature: process.env.QEE_NEW_FEATURE_COLOR || '#c63843',
    facNewbuild: process.env.QEE_FAC_NEWBUILD_COLOR || '#c63843',	
    facRenovation: process.env.QEE_FAC_RENOVATION_COLOR || '#c63843'		
};

export const themeV4 = createThemeV4({ 
	'version': 'V4',
	'breakpoints': {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},	
	'@global': {
		'*::-webkit-scrollbar': {
			width: '0.4em'
		},
		'*::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
		},
		'*::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.1)',
			outline: '1px solid slategrey'
		}
	},	
	'palette': {
		primary: {
			light: '#33c9dc',
			main: QEE_COLORS.primary,
			dark: '#29a22d',
			contrastText: '#fff'
		},
		secondary: {
			light: '#fff',
			main: QEE_COLORS.secondary,
			dark: '#ddd',
			contrastText: QEE_COLORS.primary
		},
		tertiary: {
			light: '#ddd',
			main: QEE_COLORS.tertiary,
			dark: '#888',
			contrastText: '#000'      
		},
		license: {
			light: '#ddd',
			main: QEE_COLORS.license,
			dark: '#888',
			contrastText: '#fff'            
		},
		newFeature: {
			light: '#ff8e98',
			main: QEE_COLORS.newFeature,
			dark: '#861c26',
			contrastText: '#fff',
		},
		facNewbuild: {
			light: '#8e98ff',
			main: '#1c26ff',
			dark: '#001c86',
			contrastText: '#fff',
		},
		facRenovation: {
			light: '#98ff8e',
			main: '#004c3f',
			dark: '#004c3f',
			contrastText: '#fff',
		}
	},
	'typography': {
		fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
		fontSize: 14,		
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		success: {
			color: "green"
		},
		error: {
			color: "red"
		},
		body1: {
			fontSize: 16,
			fontWeight: 500,   // makes everything to bold with 700
		},		
		body2: {
			fontSize: 16,
			fontWeight: 500,   // makes everything to bold with 700
		},
		body3: {
			fontSize: 14,
			fontWeight: 300,  
		},		
		body4: {
			fontSize: 18,
			fontWeight: 500,  
		},				
		button: {
			textTransform: 'none',			
		},
		h1: {
			fontSize: 32,
			fontWeight: 700,
			color: '#444',
			marginBottom: 20,
			marginTop: 40,
			boxSizing: 'inherit',
			'@media (max-width:600px)': {
				fontSize: 22,
			},
		},	
		h2: {
			fontSize: 26,
			fontWeight: 500,
			color: '#444',
			marginBottom: 20,
			marginTop: 20,
			boxSizing: 'inherit',
			'@media (max-width:600px)': {
				fontSize: 20,
			},
		},
		h3: {
			fontSize: 22,
			fontWeight: 600,
			color: '#444',
			marginBottom: 20,
			marginTop: 20,
			boxSizing: 'inherit',
			'@media (max-width:600px)': {
				fontSize: 18,
			},
		},
		h4: {
			fontSize: 20,
			fontWeight: 500,
			color: '#444',
			marginBottom: 20,
			marginTop: 20,
			boxSizing: 'inherit',
			'@media (max-width:600px)': {
				fontSize: 17,
			},
		},		
		h5: {
			fontSize: 18,
			fontWeight: 700,
			color: '#444',
			marginBottom: 20,
			marginTop: 20,
			boxSizing: 'inherit',
			'@media (max-width:600px)': {
				fontSize: 16,
			},
		},		
		p: {
			color: '#444',			
		},
		subtitle2: {
			fontSize: 14,
		},	
		htmlFontSize: 16,
		// Rest of your typography...
	},
	'components': {
		MuiAppBar: {
			styleOverrides: {
				colorPrimary: {
					backgroundColor: "white"
				}
			}
		},		
		Switch: {
			switchTrack: {
				backgroundColor: "#f50057",
			},
			switchBase: {
				color: QEE_COLORS.primary,
				"&.Mui-disabled": {
					color: "#e886a9"
				},
				"&.Mui-checked": {
					color: QEE_COLORS.primary
				},
				"&.Mui-checked + .MuiSwitch-track": {
					backgroundColor: QEE_COLORS.primary,
				}
			},
			switchPrimary: {
				"&.Mui-checked": {
					color: QEE_COLORS.primary,
				},
				"&.Mui-checked + .MuiSwitch-track": {
					backgroundColor: QEE_COLORS.primary,
				},
			},					
		},
		MuiSlider: {
			styleOverrides: {
				disabled: {
					color: "green"
				},
				colorPrimary: {
					color: "green"
				},				
				colorSecondary: {
					color: "green"
				},								
			}
		},
		MuiStep: {
			styleOverrides: {
				root: {
					'&$active': {
						color: '#faa', // Set the color for the active step
						//color: (props) => props.theme.palette.primary.main,
					},
					'&$completed': {
						color: '#77f', // Set the color for the completed steps
						//color: (props) => props.theme.palette.secondary.main,
					},
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				active: {
					color: 'blue', // Set the color for the active step label
				},
				completed: {
					color: 'green', // Set the color for the completed step label
				},
			},
		},		
		MuiTypography: {
            variants: [
                {
                    props: { variant: 'lbl' },
                    style: {
                        fontWeight: 700,
                        color: '#444',
                    },
                },
            ],
        },
	},
	overrides: {
		MuiCheckbox: {
			colorSecondary: {
				'&$checked': {
					color: 'green'
				}
			}
		},
		MuiButton: {
			textPrimary: {
				backgroundColor: process.env.QEE_PRIMARY_COLOR, 
				color: '#fff',
				'&:hover': {
					backgroundColor: '#006c5f', 
				},
			},
		},
		MuiButton: {
			containedSecondary: {
				backgroundColor: process.env.QEE_PRIMARY_COLOR, 
				color: '#fff',
				'&:hover': {
					backgroundColor: '#229922', 
				},
			},
		},		
	}
});

export const themeV5 = createThemeV5({
	'version': 'V5',
	'palette': themeV4.palette,
	'typography': themeV4.typography,
	'components': themeV4.components,	
	'overrides': themeV4.overrides
});
