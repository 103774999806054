import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@mui/material/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import clsx from 'clsx';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/provider";
import QeeSingleImageInput from './qeeSingleImageInput';
import { useLicense } from "./qeeUsrLicenseProvider";
import { useOrg } from "../components/qeeOrgProvider";

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(2),
		maxWidth: 1000,
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1),
		},
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0,
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2),
		},
	},
	locationText: {
		paddingLeft: '15px',
		marginTop: 40,
		[theme.breakpoints.down('xs')]: {
			paddingLeft: 0,
			textAlign: 'center',
			marginTop: 0,
		},
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%',
	},
	progress: {
		position: 'absolute',
	},
	uploadButton: {
		marginLeft: '8px',
		margin: theme.spacing(1),
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10,
	},
	submitButton: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	cardActions: {
		justifyContent: 'flex-end',
		paddingRight: theme.spacing(1),
	},
	arrowButton: {
		marginLeft: theme.spacing(1),
	},
	card: {
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1),
		},
	},
	nameBox: {
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(3),
		},
	},
});

const Account = (props) => {
	const { setOpenLicenseDialog } = useLicense();
	const { gstate, setUsr, setOpenQeeDialog } = useAuth();
	const { setOpenOrgDialog } = useOrg();

	const [state, setState] = useState({
		per_firstname: '',
		per_lastname: '',
		cch_phone_o: '',
		addr_country_o: '',
		username: '',
		cch_email_o: '',
		profilePicture: '',
		uiLoading: true,
		buttonLoading: false,
		imageError: '',
		lic: {},
		per_org_id: null,
	});
	const { classes, ...rest } = props;
	const [uiLoading, setUiLoading] = useState(state.uiLoading);
	const [orgLoading, setOrgLoading] = useState(false);
	const [orgData, setOrgData] = useState(null);
	const [orgError, setOrgError] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		const authToken = localStorage.getItem('AuthToken');
		if (authToken == null) navigate('/home');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/usr')
			.then((response) => {
				var usr = {
					admin: response.data.userCredentials.admin,
					per_firstname: response.data.userCredentials.per_firstname,
					per_lastname: response.data.userCredentials.per_lastname,
					cch_email_o: response.data.userCredentials.cch_email_o,
					cch_phone_o: response.data.userCredentials.cch_phone_o,
					addr_country_o: response.data.userCredentials.addr_country_o,
					username: response.data.userCredentials.username,
					id: response.data.userCredentials.id,
					usrId: response.data.userCredentials.userId,
					per_org_id: response.data.userCredentials.per_org_id,
					usr_unread_chat_msgs_count: response.data.userCredentials.usr_unread_chat_msgs_count,	
					lic: JSON.parse(JSON.stringify(response.data.userCredentials.lic)),
				};
				setState({
					...state,
					...usr,
					uiLoading: false,
				});
				setUsr(usr);
			})
			.catch((error) => {
				if (error.response.status === 403) {
					navigate('/home');
				}
				setState({
					...state,
					errorMsg: 'Error in retrieving the data',
				});
			});
	}, []);

	const handleChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};

	const handleImageChange = (url) => {
		var usr = gstate.usr;
		usr.picture_url = url;
		setState({
			...state,
			...usr,
		});
		setUsr(usr);
	};

	const updateFormValues = (event) => {
		event.preventDefault();
		setState({
			...state,
			buttonLoading: true,
		});
		const authToken = localStorage.getItem('AuthToken');
		if (authToken == null) navigate('/home');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		const formRequest = {
			per_firstname: state.per_firstname,
			per_lastname: state.per_lastname,
			addr_country_o: state.addr_country_o,
		};
		let usr = JSON.parse(sessionStorage.getItem('usr'));
		axios
			.put('/api/usr/' + usr.id, formRequest)
			.then(() => {
				setState({
					...state,
					buttonLoading: false,
				});
			})
			.catch((error) => {
				if (error.response.status === 403) {
					navigate('/home');
				}
				setState({
					...state,
					buttonLoading: false,
				});
			});
	};

	const fetchOrgData = async () => {
		if (state.per_org_id) {
			setOrgLoading(true);
			setOrgError(null);
			const authToken = localStorage.getItem('AuthToken');
			if (authToken == null) navigate('/home');
			axios.defaults.headers.common = { Authorization: `${authToken}` };
			try {
				const response = await axios.get(`/api/org/${state.per_org_id}`);
				setOrgData(response.data);
				return response.data;
			} catch (error) {
				console.log(error);
				setOrgError('Error fetching organization data!');
			}
			setOrgLoading(false);
		}
	};

	useEffect(() => {
		if (state.per_org_id) {
			fetchOrgData();
		}
	}, [state.per_org_id]);

	const handleOrgOpen = async () => {
		let orgData = await fetchOrgData();
		setOpenOrgDialog(orgData, true, null); // isEditabel=true, primaryAction=null
	};

	const handlePrimaryAction = () => {
		console.log('Primary button clicked');
		// Add your logic here for the primary action
	};

	const renderEditableField = (label, value, handleClick) => (
		<Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
			<Typography variant="body1" style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
				{label}: {value}
			</Typography>
			<Button
				color="primary"
				variant="text"
				className={classes.arrowButton}
				onClick={handleClick}
			>
				<ArrowForwardIosIcon />
			</Button>
		</Box>
	);

	return state.uiLoading === true ? (
		<main className={classes.content}>
			{state.uiLoading && <CircularProgress size={gstate.isLG ? 100 : 50} className={classes.uiProgess} />}
		</main>
	) : (
		<main className={classes.content}>
			<Card {...rest} className={clsx(classes.root, classes.card)}>
				<CardContent>
					<Box className={classes.details}>
						<Box>
							<QeeSingleImageInput
								compId={'profilePicture'}
								username={state.username}
								fldId={'profile'}
								buttonText="Bild hochladen"
								keyItm={['sdoc_type', 'sdoc_subtype']}
								keyVal={[200, 100]}
								sdoc_type={200}
								sdoc_subtype={100}
								onSuccess={(s) => handleImageChange(s)}
							/>
						</Box>
						<Box className={classes.nameBox}>
							<Typography className={classes.locationText} gutterBottom variant="h2">
								{state.per_firstname} {state.per_lastname}
							</Typography>
							<Typography className={classes.locationText} gutterBottom variant="body1">
								{orgData ? orgData.org_name : ''}
							</Typography>
						</Box>
					</Box>
					<div className={classes.progress} />
				</CardContent>
			</Card>

			<Card {...rest} className={clsx(classes.root, classes.card)}>
				<form autoComplete="off" noValidate>
					<CardContent>
						<Typography variant='h2'>Meine Daten</Typography>
						<Grid container spacing={3}>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Vorname"
									name="per_firstname"
									variant="outlined"
									value={state.per_firstname}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Nachname"
									name="per_lastname"
									variant="outlined"
									value={state.per_lastname}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Email"
									name="cch_email_o"
									variant="outlined"
									disabled
									value={state.cch_email_o}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Kürzel"
									name="username"
									disabled
									variant="outlined"
									value={state.username}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
					</CardContent>
					<CardActions className={classes.cardActions}>
						<Button
							color="primary"
							variant="contained"
							type="submit"
							className={classes.submitButton}
							onClick={updateFormValues}
							disabled={state.buttonLoading || !state.per_firstname || !state.per_lastname}
						>
							Speichern
							{state.buttonLoading && <CircularProgress size={30} className={classes.progress} />}
						</Button>
					</CardActions>
				</form>
			</Card>

			<Card className={classes.card}>
				<CardContent>
					<Typography variant='h2'>Lizenz</Typography>
					<Grid container>
						<Grid item xs={10}>
							<div>Art der Lizenzierung:</div>
							<div>{gstate.usr.lic ? gstate.usr.lic.type : 'free'}</div>
						</Grid>
						<Grid item xs={2} style={{ textAlign: 'right' }}>
							<Button variant="text" onClick={() => setOpenLicenseDialog()}>
								<ArrowForwardIosIcon />
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			{state.per_org_id && (
				<Card className={classes.card}>
					<CardContent>
						<Typography variant='h2'>Mein Unternehmen</Typography>
						<Grid container>
							<Grid item xs={10}>
								<div>{orgData ? orgData.org_name : ''}</div>
							</Grid>
							<Grid item xs={2} style={{ textAlign: 'right' }}>
								<Button variant="text" onClick={handleOrgOpen}>
									<ArrowForwardIosIcon />
								</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			)}
		</main>
	);
};

export default withStyles(styles)(Account);
