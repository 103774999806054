import react, { useState, useEffect, useLayoutEffect } from 'react';

import { makeStyles }  from '@material-ui/core/styles';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { green } from '@mui/material/colors';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { useAuth } from "../context/provider";


const FacRenovationsCheckboxGroup = ( props) => {
		
	const { gstate, setObjEdit } = useAuth();	
	
	console.log( 'PROPS >>>', props);
	
	// some consts
	const useStyles = makeStyles( theme => ({
		formLabel: {
			//fontSize: 32
		},
		formSublabel: {
			fontSize: 16
		},
		formControlLabel: {
			width:200, 
			paddingLeft:10, 
			paddingRight:10, 			
			margin: '20px !important',
			border: 'solid 1px gray',
			color: theme.palette.primary.main,
		},
		formControlLabelSelected: {
			width:200, 
			paddingLeft:10, 
			paddingRight:10, 			
			margin: '20px !important',
			border: `5px solid ${theme.palette.primary.main}`,					
			borderRadius: 5,
			background: '#eee',
			color: theme.palette.primary.main,
		},
		checkbox: {
			color: theme.palette.primary.main
		}
	}));			
	const classes = useStyles( props);			
		
	// construct the dynamic handles in array	
	var createFunction = function( current_i) {
		console.log( 'creating function', current_i);
		return function( date) {
			console.log( 'Calling function', current_i );
			const year = moment(date).format("YYYY"); // add this
			var objEdit = JSON.parse( JSON.stringify( gstate.objEdit));
			objEdit.fac_renovations[current_i]['renovation'] = year;
			setObjEdit( objEdit);			
		};
	};	
	const handleChangeRenovationYearArr = [];	
	props.data.map((r, i) => {		
		handleChangeRenovationYearArr.push(
			createFunction( i)
		)
	})
			

	useEffect( () => {
		
		console.log( 'FacRenovationsCheckboxGroup useEffect with props', props);
				
	}, []);					
		
	
	
	const handleOnChange = (ev) => { 
		console.log( 'handleOnChange: ', ev);
		
		props.data.map((r, i) => {
			//console.log( 'elem', r.name + '-' + r.value);
			if( r.name === ev.target.name) {
				console.log( 'name', r.name);
				console.log( 'value', ev.target.value);
				console.log( 'checked', ev.target.checked);
				r.value = (ev.target.checked == true ? '1' : '0');
			} 
		})

		if( props.fctOnChange) {			
			props.fctOnChange( props.data);
		}
		
	}	
	
	
	const handleChangeRenovationYear = (date) => {
		console.log( 'handleChangeRenovationYear ARGS', date);
		const year = moment(date).format("YYYY"); // add this
		setObjEdit({
			...gstate.objEdit,
			fac_construction_year: year
		})
	};	
	
	
	/**
	*	isShowCond() - checks the configurated condition, if any
	*/
	const isShowCond = ( r) => {		
		var fac = gstate.objEdit;
		if( r.cond) {   // if some cond specified as string
			var code = "(function(){console.log( 'r.cond', '"+r.cond+"'); return " + r.cond + "})";
			//console.log( 'r.cond code string', code);				
			var f = eval( code);
			//console.log( 'r.cond evaluated code', f.call( r));
			return f.call( r);		
		} else {
			return true;   // if no cond specified
		}
	}			
	
	
	
    return (		
        <FormControl>
			{props.formLabel ?
				<h2 id="checkboxes-group-label" className={classes.formLabel}>{props.formLabel}</h2>
				:
				<></>
			}
			{props.formSublabel ? 
				<div id="checkboxes-group-label" className={classes.formSublabel}>{props.formSublabel}</div>
				:
				<></>
			}
			<FormGroup
				aria-labelledby="checkbox-group-label"
				row
				defaultValue={props.defaultValue}
				name="checkbox-group"
				onChange={handleOnChange}
			>
			{gstate.objEdit.fac_renovations ? gstate.objEdit.fac_renovations.map((r, i) => 
				isShowCond(r) ?
				<FormControlLabel 
					labelPlacement="top" 
					checked={r.value === '1' ? true : false} 
					name={r.name}
					control={
					  <Checkbox
						sx={{
						  color: (theme) => theme.palette.primary.main,
						  '&.Mui-checked': {
							color: (theme) => theme.palette.primary.dark,
						  },
						}}
					  />
					}
					label={
						<div style={{textAlign:'center'}}>
							<div>{r.icon}</div>
							<div style={{padding:10}}>{r.label}</div>
							{r.value == '1' ? 								
							<MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
								<DatePicker
									variant="outlined"
									InputProps={{
										disableOutline: false,
									}}
									views={['year']}
									label="Letzte Renovierung"
									required
									fullWidth
									minDate={''+gstate.objEdit.fac_construction_year}
									disableFuture
									value={gstate.objEdit.fac_renovations[i].renovation}
									onChange={handleChangeRenovationYearArr[i]}
									renderInput={(params) => <OutlinedInput {...params} helperText={null} />}
									container="inline"
									inputStyle={{ textAlign: 'center' }}
								/>							
							</MuiPickersUtilsProvider>							
							:
							<div style={{height:50}}></div>
							}
						</div>
					} 
					className={r.value === '1' ? classes.formControlLabelSelected : classes.formControlLabel}
				/>
				:''
			):''}										
			</FormGroup>
		</FormControl>
    )
}
export default FacRenovationsCheckboxGroup;
