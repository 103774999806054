// usrLicenseDialog.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { useImperativeHandle } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import QeeRadioGroup from './qeeRadioGroup';
import QeeSlideDialog from './qeeSlideDialog';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import QeeHintBox from './qeeHintBox';
import { useSnackbar } from 'notistack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Slider from '@mui/material/Slider';					
import AppStripe from './stripe/components/AppStripe';

import axios from 'axios';
import moment from 'moment';

//import css from '../core/App.css?inline';

import { useAuth } from "../context/provider";
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';
import { getLic } from "../api/service";
import { useConfirm } from "material-ui-confirm";


const styles = (theme) => ({
});


// main fct
const UsrLicenseDialog = React.forwardRef((props, forwardedRef) => {	

	const refLicenseForm = useRef(null);	

	const { gstate, setUsr, setObjEdit, setObjErrors, setOpenQeeDialog, setIsFetching } = useAuth();				
	
	const [state, setState] = useState({
		showFeatureHint: false,
		submitDisabled: true,
		changesDlgLicense: {},
		objErrors: {}
	});

	const { enqueueSnackbar } = useSnackbar();		
	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);
	
	
	const handleLicenseOpen = ( showFeatureHint) => {
		setState({
			...state,
			showFeatureHint: showFeatureHint
		})
		setOpenQeeDialog( {open:true, id:'dlgLicense'});
	}	
	
	
	const handleLicenseClose = () => {
		setOpenQeeDialog( false);	
	};


	useEffect(() => {

		console.log( 'UsrLicenseDialog.useEffect');
	
		//const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login');
		setAxiosAuthToken();
		axios
			.get( '/api/usr')
			.then((response) => {
				console.log( 'GET api/usr', response.data);
				var usr = {
					admin: 			response.data.userCredentials.admin,					
					per_firstname: 	response.data.userCredentials.per_firstname,
					per_lastname: 	response.data.userCredentials.per_lastname,
					cch_email_o: 	response.data.userCredentials.cch_email_o,
					cch_phone_o: 	response.data.userCredentials.cch_phone_o,
					addr_country_o: response.data.userCredentials.addr_country_o,
					username: 		response.data.userCredentials.username,
					id: 			response.data.userCredentials.id,
					usrId: 			response.data.userCredentials.userId,							
					per_org_id: 	response.data.userCredentials.per_org_id,										
					lic: 			JSON.parse( JSON.stringify( response.data.userCredentials.lic)),
				}
				setState({
					...state,
					...usr,
					lic_tmp:		response.data.userCredentials.lic,
				});
				setUsr( usr);
				sessionStorage.setItem( 'usr', JSON.stringify( usr));
			})
			.catch((error) => {
				console.log(error);
				if (error.response.status === 403) {
					console.error( 'Cannot get user', error.response.status);
				}				
				setState({ 
					...state,
					errorMsg: 'Error in retrieving the data' 
				});
			});
	
							
	}, [])	
	
		
	const setLic = ( lic)=>{	

		console.log( 'setLic', lic)
	
		setState({...state, 
			lic_tmp: lic,
			submitDisabled: !refLicenseForm.current.validate()
		})
				
	}	

	
	const handleLicenseFormChange = (o, hasErrorsDlgLicense)=>{	

		console.log( 'handleLicenseFormChange', {o, hasErrorsDlgLicense})
	
		setState({...state, 
			lic_tmp: {
				...state.lic_tmp,
				type: o.type
			},
			changesDlgLicense: o,
			submitDisabled: hasErrorsDlgLicense
		})
				
	}	
	
	
	const handleLicenseSubmit = async (event) => {
		
		console.log( 'handleLicenseSubmit', event);
		
		if( event && event.preventDefault)		
			event.preventDefault();
		
		let usr = JSON.parse( sessionStorage.getItem( 'usr'));		
		usr.lic = {
			...state.lic_tmp,
			liccus_start_date: new Date()
		};
		console.log( 'submit usr', usr);		
		let options = {};
		let id = usr.id;
		options = {
			url: `/api/usr/${id}`,
			method: 'put',
			data: usr
		};
		setAxiosAuthToken();				
		try {
			var data = await axios( options);
			console.log( 'Submited data', data);			
			setState({
				...state,					
				lic: usr.lic
			})					
			setUsr( {
				...gstate.usr,
				lic: usr.lic
			})		
			sessionStorage.setItem( 'usr', JSON.stringify( usr));			
			enqueueSnackbar( 'Die Lizenzdaten wurden aktualisiert!', {variant: 'success'});			
			return true;			
			
		} catch( error) {
			setObjErrors( error.response.data);
			console.log(error);
			return false;			
		}
		
		/*
		await axios( options)
			.then((data) => {
				console.log( 'Submited data', data);
				
				setState({
					...state,					
					lic: usr.lic
				})					
				setUsr( {
					...gstate.usr,
					lic: usr.lic
				})		
				sessionStorage.setItem( 'usr', JSON.stringify( usr));
				
				enqueueSnackbar( 'Die Lizenzdaten wurden aktualisiert!', {variant: 'success'});
				
				return true;
			})
			.catch((error) => {
				setObjErrors( error.response.data);
				console.log(error);
				return false;
			});
		*/
	};
	
	
	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		ali: ()=>{alert(123)},
		handleLicenseOpen: handleLicenseOpen,						
		handleLicenseClose: handleLicenseClose,	
		handleLicenseSubmit: handleLicenseSubmit
	}));
	

	
	/**
	*	Component for async showing the lic details
	*/
	const LicenseDetails = (props) => {
		
		const classes = useStyles();
		const [isLoading, setIsLoading] = useState( false);			
		
		const [lic, setLic] = useState( '');

				
		useEffect( async () => {	

			setIsLoading( true);
			var lic = await getLic( props.invnr);
			setIsLoading( false);			
			setLic( lic);
			
		}, [])

		
		return (
			<div>
				<Grid container direction="column">
					<Grid item style={{fontSize:14, textAlign:'center'}}>
						{isLoading ? <CircularProgress />
						:
						<table style={{width:'100%'}}>
							<tr><td style={{fontSize:18}}><strong>{lic.name}</strong></td></tr>
							{lic.price ?
								<tr><td><strong>{lic.price} {lic.currency} / {lic.type_text}</strong></td></tr>							
							:''}
							<tr><td><strong>{lic.desc}</strong></td></tr>							
							<tr><td style={{height:30}}></td></tr>
							{lic.features ?
								lic.features.map((r, idx) => (
									<tr><td style={{fontSize:12}}>
									- {r}
									</td></tr>
								))
							:'-'}
						</table>
						}
					</Grid>						
				</Grid>

			</div>
		)
	}	
	
	
	/*************************************************************
	*
	*	LicenseForm() - component for licensing
	*
	*************************************************************/
	const LicenseForm = React.forwardRef((props, forwardedRef) => {		
	//const LicenseForm = (props) => {
		
		const refLicenseFormFree = useRef(null);	
		const refLicenseFormPremium = useRef(null);	
		const refLicenseFormEnterprise = useRef(null);					
		const [lic, setLic] = useState( props.lic ? props.lic : {type:'free',liccus_id:'',liccus_key:''});   // init it for any case
		
		// some consts
		const useStyles = makeStyles( theme => ({
			licenseForm: {
				padding: 10,				
			},
			locationText: {
				color: '#444'
			},
			accordionBox: {
				fontWeight:'bold', 
				m:1, 
				lineHeight:1.2, 
				color:theme.palette.primary.contrastText,
			},				
			icon: {
				fontWeight:'bold', 
				m:1, 
				lineHeight:1.2, 
				color:theme.palette.primary.contrastText,
			},
			validText: {
				fontWeight: 600,
				color: '#090'
			},
			accrordionSummaryText: {
				fontWeight: 500,
				color: '#fff'
			},			
			boxLic: {
				padding: 10,
				border: `1px solid ${theme.palette.primary.main}`,				
				color: theme.palette.primary.main,
				borderRadius: 5,
				maxWidth: 400
			}	
		}));			
		const classes = useStyles( props);
		console.log( 'LicenseForm classes', classes);
		
		
		useEffect(() => {
						
			if( JSON.stringify( lic) != JSON.stringify( props.lic))
				props.setLic( lic);
								
		}, [JSON.stringify( lic)])
		
			
		const validate = () => {
			console.log( '--> LicenseForm.validate');
			
			if( lic.type=='free')
				return refLicenseFormFree.current.validate();
			else if( lic.type=='premium')
				return refLicenseFormPremium.current.validate();			
			else if( lic.type=='enterprise')
				return refLicenseFormEnterprise.current.validate();			
			
			console.log( '<-- LicenseForm.validate');						
		}
		
		
		/**
		*	expose it outside
		*/
		useImperativeHandle( forwardedRef, () => ({	
			ali: ()=>{alert(123)},
			validate: validate
		}))
		
		
		const getHintLicenseFeature = () => {
			return `Diese Funktionalität ist nur mit einem höheren Lizenzplan verfügbar. Wähle deinen neuen Plan aus oder erweitere deine Lizenzierung auch für diese Immobilie!` 
		}				
		
					
		return (
			<div style={{marginTop:70}}>
						
			{props.showFeatureHint ?
				<QeeHintBox type="license" text={<React.Fragment><strong>Tipp:</strong> <span dangerouslySetInnerHTML={{__html: getHintLicenseFeature()}}></span></React.Fragment>} style={{marginTop:70, marginBottom:10}}/>
			:''}												
						
			{!gstate.usr.lic.liccus_key ?
			<>			
			<Typography className={classes.locationText} gutterBottom variant="h5" style={{marginTop:80}}>
				<>Lizenztypen</>
			</Typography>
			<QeeRadioGroup
				height={350}
				width={250}
				formSublabel={<div style={{maxWidth:800}}>Wähle deinen bevorzugten Lizenztyp aus:</div>}						
				defaultValue={lic.type}
				fctOnChange={( ev)=>{
					setLic({
						type: ev.target.value
					});
				}}
				sdata={[
					{value:'free', formComponent:<LicenseDetails invnr='100010'/>},
					{value:'premium', formComponent:<LicenseDetails invnr='100100'/>},								
					{value:'enterprise', formComponent:<LicenseDetails invnr='100910'/>},								
				]}
			/>
			</>
			:
			<div style={{marginLeft:10, paddingTop:10}}>
				<p>Deine aktuelle Lizenz ist:</p>
				{(() => {
					if( lic.type=='free') 
						return <Box className={classes.boxLic}>
							<LicenseDetails invnr='100010'/>
						</Box>
					else if( lic.type=='premium')	
						return <Box className={classes.boxLic}>
							<LicenseDetails invnr='100100'/>
						</Box>
					else if( lic.type=='enterprise')	
						return <Box className={classes.boxLic}>
							<LicenseDetails invnr='100910'/>
						</Box>					
				})()}
			</div>			
			}
			<div>
				{(() => {
					if( lic.type=='free') 
						return <Box>
							<LicenseFormFree ref={refLicenseFormFree} lic={lic} setLic={setLic} classes={classes} />
						</Box>
					else if( lic.type=='premium')	
						return <Box>
							<LicenseFormPremium ref={refLicenseFormPremium} lic={lic} setLic={setLic} classes={classes} />
						</Box>
					else if( lic.type=='enterprise')	
						return <Box>
							<LicenseFormEnterprise ref={refLicenseFormEnterprise} lic={lic} setLic={setLic} classes={classes} />
						</Box>					
				})()}
			</div>
			</div>
		)
		
	});

	
	/*******************************************************************
	*
	*	LicenseFormFree() - component for free licensing
	*
	*******************************************************************/
	const LicenseFormFree = React.forwardRef((props, forwardedRef) => {			
	//const LicenseFormFree = (props) => {
		
		const [lic, setLic] = useState( props.lic);		
		
		const { classes, ...rest } = props;		

		
		useEffect(() => {
			
			if( JSON.stringify( lic) != JSON.stringify( props.lic))
				props.setLic( lic);
						
		}, [JSON.stringify( lic)])
		
				
		const validate = () => {
			console.log( '--> LicenseFormFree.validate');
			console.log( '<-- LicenseFormFree.validate');									
			return false;
		}	

		
		/**
		*	expose it outside
		*/
		useImperativeHandle( forwardedRef, () => ({	
			ali: ()=>{alert(123)},
			validate: validate
		}))		
				
				
		return (
			<form class={classes.licenseForm} autoComplete="off">
				<Typography className={classes.locationText} gutterBottom variant="h6">
					<>{lic.name}</>
				</Typography>								
				<Typography className={classes.locationText} gutterBottom variant="p">
					<>Die freie Basic-Lizenz erlaubt dir Immobilien anzulegen, diese zu analysieren und zu bewerten.</>
					<>Die Planung von energetischen Sanierungen, Kostenberechnungen als auch ausführliche Berichte sind in dieser Variante nicht enthalten.</>
				</Typography>											
			</form>
		)
		
	});
	
	
	/*****************************************************************
	*
	*	LicenseFormPremium() - component for premium licensing
	*
	*****************************************************************/
	const LicenseFormPremium = React.forwardRef((props, forwardedRef) => {				
	//const LicenseFormPremium = (props) => {
		
		const [lic, setLic] = useState( props.lic);		
		
		const { classes, ...rest } = props;		

		
		useEffect(() => {
			
			if( JSON.stringify( lic) != JSON.stringify( props.lic))
				props.setLic( lic);
						
		}, [JSON.stringify( lic)])		
		

		const validate = () => {
			console.log( '--> LicenseFormPremium.validate');
			console.log( '<-- LicenseFormPremium.validate');									
			return false;
		}	
		
		
		/**
		*	expose it outside
		*/
		useImperativeHandle( forwardedRef, () => ({	
			ali: ()=>{alert(123)},
			validate: validate
		}))
		
		
		const getHintShowLicenseKey = () => {
			return `Dein Lizenzschlüssel ist: ` + gstate.usr.lic.liccus_key
		}		
		
		
		const getHintShowBuyLicense = () => {
			return `Um eine Prämium-Lizenz zu aktivieren must du in der ausgewählten Immobilie ein Upgrade erwerben. Dieses Upgrade schaltet alle Premium Features für diese Immobilie frei.`
		}				
		
		
		const getUrlFacId = () => {	
			// try to get the fac_id, if any in the url
			const myURL = new URL( window.location);
			return myURL.searchParams.get( 'id');		
		}
		
					
		return (
			<form class={classes.licenseForm} autoComplete="off">
				<Typography className={classes.locationText} gutterBottom variant="h6">
					<>{lic.name}</>
				</Typography>								
				
				<Typography className={classes.locationText} gutterBottom variant="p">
					<>Mit der Premium-Lizenz kannst du die gewünschten Sanierungen für eine Immobilie energetisch berechnen, Kosten bewerten und den Energiebericht erstellen.</>
					<>Es können gleichzeitig mehrere Immobilien als Premium-Immobilien verwaltet werden.</>
					<br /><br />
					{gstate.usr.lic.liccus_key ? 
						<>
						<QeeHintBox type="info" text={<React.Fragment><strong>Tipp:</strong> <span dangerouslySetInnerHTML={{__html: getHintShowLicenseKey()}}></span></React.Fragment>} style={{marginTop:10, marginBottom:10}}/>						
						{gstate.usr.lic.liccus_facs ?
							<div className={classes.boxLicxxx} style={{marginTop:20, marginBottom:20}}>
							<span style={{fontWeight:600}}>Bisher erworbene Premium Lizenzen:</span>
							<table style={{marginTop:10, marginBottom:10, fontSize:14}}>	
								<colgroup>
									<col span="1" style={{width:250}} />
									<col span="1" style={{width:400}} />
								</colgroup>							
								<thead style={{fontWeight:600}}><tr><td>Eindeutige Projekt-ID</td><td>Datum</td></tr></thead>
								<tbody>
								{gstate.usr.lic.liccus_facs.map((r, idx) => (
									<tr>
									<td>
										<>{r.id}</>
									</td>
									<td>
										<>{moment( r.start_date).format( 'DD.MM.YYYY')}</>
									</td>								
									</tr>						
								))}
								</tbody>
							</table>
							</div>
						:''}
						<div style={{marginBottom:10}}>Weitere Lizenzen ermöglichen dir die Anzahl der verwalteten Immobilien zu erhöhen.</div>	
						</>
					:''
					}
					{getUrlFacId() ? 	
						<AppStripe />
					:
						<QeeHintBox type="info" text={<React.Fragment><strong>Tipp:</strong> <span dangerouslySetInnerHTML={{__html: getHintShowBuyLicense()}}></span></React.Fragment>} style={{marginTop:10, marginBottom:10}}/>						
					}	
				</Typography>									
			</form>
		)
		
	});
	
	
	/********************************************************************
	*
	*	LicenseFormEnterprise() - component for enterprise licensing
	*
	********************************************************************/
	const LicenseFormEnterprise = React.forwardRef((props, forwardedRef) => {					
	//const LicenseFormEnterprise = (props) => {
		
		const confirm = useConfirm();			
		
		const [lic, setLic] = useState( props.lic);				
		const [licValid, setLicValid] = useState( props.lic.valid);				
		const [isLoading, setIsLoading] = useState( false);	
		const [objErrors, setObjErrors] = useState( {});	
		//const [validationErrors, setValidationErrors] = useState( []);				

		const { classes, ...rest } = props;				
		

		useEffect(() => {
			
			if( !lic.liccus_valid && lic.liccus_key)
				setObjErrors({liccus_key: 'Ungültiger Schlüssel!'});
			else
				setObjErrors({});
			
			props.setLic( lic);	

			//let usr = JSON.parse( sessionStorage.getItem( 'usr'));			
			//usr.lic.liccus_valid = lic.liccus_valid;
			//sessionStorage.setItem( 'usr', JSON.stringify( usr));			
						
		}, [lic.liccus_valid])		


		useEffect(() => {
			
			if( JSON.stringify( lic) != JSON.stringify( props.lic)) {
				setLic( lic);
			}
						
		}, [JSON.stringify( lic)])		
		

		
		const validate = () => {
			console.log( '--> LicenseFormEnterprise.validate');
			
			var ret = true;
			
			if( !lic.liccus_key || lic.liccus_key=='') {
				objErrors.liccus_key = "Kein Lizenzschlüssel!"
				ret = false;
			}
			
			if( !lic.liccus_valid) {
				objErrors.liccus_key = "Der Lizenzschlüssel ist nicht gültig!"
				ret = false;
			}
			
			console.log( '<-- LicenseFormEnterprise.validate', ret);									
			return ret;
		}	


		/**
		*	expose it outside
		*/
		useImperativeHandle( forwardedRef, () => ({	
			ali: ()=>{alert(123)},
			validate: validate
		}))

				
		const handleChange = (event) => {
			console.log( 'handleChange', [event.target.name, event.target.value]);
			setLic({
				...lic,
				[event.target.name]: event.target.value
			});
		};		

		
		const getHintLicenseKey = () => {
			return `Den Lizenzschlüssel erhältst du von deiner Organisation und schaltet für dich alle vereinbarten App-Features ein.`
		}
		
				
		const getHintLicenseDetails = () => {
			return `Die Lizenz wurde am ` + moment( lic.liccus_start_date).format( 'DD.MM.YYYY HH:MM') +  ` aktiviert.`;
		}		
		
		
		/**
		*	searchLiccusKey() - gets/checks the license key
		*/
		const searchLiccusKey = () => { 	
		
			console.log( 'searchLiccusKey');
					
			setIsLoading( true);
			
			let usr = JSON.parse( sessionStorage.getItem( 'usr'));	
			let email = usr.cch_email_o;			
			let options = {};
			let ky = lic.liccus_key;
			let url = `/api/liccus/${ky}/${email}`;   // aen-20230306 extends check email allowed param for LBS
			console.log( 'url', url);
			options = {
				url: url,
				method: 'get'
			};
			setAxiosAuthToken();
			axios( options)
				.then((data) => {
					console.log( 'Liccus found', data);
					setLic( {
						...lic,
						liccus_valid: true
					});
					usr.lic.liccus_valid = true;
					sessionStorage.setItem( 'usr', JSON.stringify( usr));
					setIsLoading( false);	
				})
				.catch((error) => {
					console.log( 'Liccus NOT valid', error);
					setIsLoading( false);											
					confirm({ 
						title: "Prüfung Lizenzschlüssel", 
						description: "Der eingegebene Lizenzschlüssel ist ungültig oder du wurdest nicht in die Autorisierungsliste aufgenommen. Im letzteren Fall nehme bitte Kontakt mit dem Lizenzadministrator auf.",
						confirmationText: "Erneut probieren",				
						cancellationText: "Abbrechen",				
						confirmationButtonProps: {color:"success", variant:"contained", style:{textTransform: 'none'}},
						cancellationButtonProps: {color:"success", style:{textTransform: 'none'}}								
					})
					.then(() => {
					})
					.catch(() => {			
						window.location.reload();					
					});					
					

				});			
		}
			
			
		return (
			<form class={classes.licenseForm} autoComplete="off">
				<Typography className={classes.locationText} gutterBottom variant="h6">
					<>{lic.name}</>
				</Typography>								
				
				<Typography className={classes.locationText} gutterBottom variant="p">
					<>Für die Enterprise-Lizenz benötigst du einen Lizenzschlüssel von deiner Organisation. </>
					<>Nach Eingabe des Lizenzschlüssels hast du unbegrenzten Zugriff auf alle Funktionalitäten für wie vereinbart viele Immobilien.</>
					<br /><br />
				</Typography>	

				<Typography>Trage bitte deinen Lizenzschlüssel ein:</Typography>
				<br />	
				<Grid container className={classes.fieldsBox}  direction="row">
					<Grid item xs={10}>
						<TextField
							type="text"		
							placeholder="z.B. YAXCE-89435-LERPO-16121"																	
							variant="outlined"
							required
							fullWidth
							autoComplete='off'
							id="liccus_key"
							label="Lizenzschlüssel"
							name="liccus_key"
							value={lic.liccus_key}									
							helperText={objErrors.liccus_key}
							error={objErrors.liccus_key ? true : false}
							onChange={handleChange}	
							onKeyPress={e => e.key === 'Enter' && e.preventDefault()}							
						/>
						{lic.liccus_valid ?
						<>
						<div className={classes.validText}>Der Lizenzschlüssel ist gültig.</div> 
							{gstate.usr.lic.liccus_facs ?
								<div className={classes.boxLicxxx} style={{marginTop:20, marginBottom:20}}>
								{gstate.usr.lic.liccus_facs_premium_max ?
									<>
									<Slider defaultValue={gstate.usr.lic.liccus_facs.length} 
										step={1} 
										marks 
										min={0} 
										max={gstate.usr.lic.liccus_facs_premium_max} 
										disabled 
										color="primary"
									/>
									</>									
								:
									''
								}
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon className={classes.icon}/>}
										aria-controls="panel1a-content"
										id="panel1a-header"
										sx={{
											backgroundColor: (theme) => theme.palette.primary.main,
											color: (theme) => theme.palette.primary.contrastText,
										}}										
									>
										<Typography><Box className={classes.accordionBox}>
											{gstate.usr.lic.liccus_facs_premium_max ?
												<span className={classes.accrordionSummaryText}>Verbrauchte Premium Lizenzen: {gstate.usr.lic.liccus_facs.length} von {gstate.usr.lic.liccus_facs_premium_max}</span>																			
											:
												<span className={classes.accrordionSummaryText}>Gebuchte Premium Lizenzen: {gstate.usr.lic.liccus_facs.length}</span>																
											}											
										</Box></Typography>
									</AccordionSummary>				
									<AccordionDetails>									
										<table style={{marginTop:10, marginBottom:10, fontSize:14}}>	
											<colgroup>
												<col span="1" style={{width:250}} />
												<col span="1" style={{width:400}} />
											</colgroup>							
											<thead style={{fontWeight:600}}><tr><td>Propertec Projekt-ID</td><td>Datum</td></tr></thead>
											<tbody>
											{gstate.usr.lic.liccus_facs.map((r, idx) => (
												<tr>
												<td>
													<>{r.id}</>
												</td>
												<td>
													<>{moment( r.start_date).format( 'DD.MM.YYYY')}</>
												</td>								
												</tr>						
											))}
											</tbody>
										</table>
									</AccordionDetails>	
								</Accordion>		
								</div>
							:''}
						</>	
						:''}						
					</Grid>
					<Grid item xs={2}>				
						<QeeColorButtonPrimary 
							disabled={!lic.liccus_key || lic.liccus_key.length < 15 ? true : false}
							variant="contained" 
							onClick={searchLiccusKey}							
							style={{ textTransform: "none", padding: "14px 0px", marginLeft: 10, width: 100 }}
						>
							{isLoading ?
								<CircularProgress style={{color:"#fff"}} />
								:	
								<><FindInPageIcon /><>{gstate.isXS ? '' : 'Prüfen'}</></>
							}						
						</QeeColorButtonPrimary>				
					</Grid>
				</Grid>
				<QeeHintBox type="info" text={<React.Fragment><strong>Tipp:</strong> <span dangerouslySetInnerHTML={{__html: getHintLicenseKey()}}></span></React.Fragment>} style={{marginTop:40}}/>							
				{!lic.liccus_start_date ? (
						<div style={{marginBottom: 40}}>						
						</div>
					) : (
						<QeeHintBox type="info" text={<React.Fragment><strong>Tipp:</strong> <span dangerouslySetInnerHTML={{__html: getHintLicenseDetails()}}></span></React.Fragment>} style={{marginTop:40}}/>			
					)
				}
				
			</form>
		)
		
	});
	
		
	return (		
		<>
		<div ref={forwardedRef}></div>	
			<QeeSlideDialog 
				id='dlgLicense'
				title='Lizenzierung' 
				formComponent={
					<LicenseForm 
						ref={refLicenseForm} 						
						lic={state.lic_tmp} 
						setLic={setLic}
						showFeatureHint={state.showFeatureHint}
					/>
				}	
				submitDisabled={state.submitDisabled}
				fctOnSubmit={handleLicenseSubmit}
			>
			</QeeSlideDialog>		
		</>	
	)
})	
export default withStyles(styles)(UsrLicenseDialog);