// FacForm.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { Dimensions, Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@mui/material/Paper';
import Box from '@material-ui/core/Box';

import FacTitle from './facTitle';
import FacTaxonomyWizDialog from './facTaxonomyWizDialog';
import CalculateIcon from '@mui/icons-material/Calculate';
import ForestIcon from '@mui/icons-material/Forest';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useConfirm } from "material-ui-confirm";

import axios from 'axios';

import QeeTooltip from './qeeTooltip';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';

import QeeHintBox from './qeeHintBox';
import {formatNumber} from '../util/helpers';

import css from '../core/App.css';

import { useAuth } from "../context/provider";
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0.5)
		}		
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {		
			marginTop: theme.spacing(0.2),		
		}		
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	button: {
		textTransform: 'none'
	},
	box: {
		backgroundColor:theme.palette.primary.contrastText,
		border: 'solid 1px #E4EAE6',
		padding:20,
	},	
	resultCard: {
		display: 'flex', 
		//width:'100%',
		padding:10,
		marginBottom: 10,
		borderRadius: 5,
		border: `1px solid ${theme.palette.primary.main}`,				
		padding: 20,		
		[theme.breakpoints.down('xs')]: {
			padding: 5,		
		}
	},	
});


// main fct
const FacTaxonomyForm = ( props) => {
	
	const confirm = useConfirm();		
	
	const refTaxonomyDialog = useRef(null);	
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();			
	
	const [state, setState] = useState({	
		isLoading: false,   // set it to true
		isXL: false,
		width: window.innerWidth,		
	})

	
	function handleWindowSizeChange() {
		setState({
			...state,
			width: window.innerWidth
		});
	}	
	
	
	useEffect( () => {	

		setState( {
			...state,
			isXL: state.width>=1536 ? true : false
		});
		console.log( 'state', state);				
		
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
												
	}, [state.width]);		

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);				
	
			
	const formatCurrency = ( vl) => {
		return new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR'}).format( vl).replace(/\D00(?=\D*$)/, '')
	}
	
	
	const handleTaxonomyOpen = ( step) => {		
		refTaxonomyDialog.current.handleTaxonomyWizOpen( 0);		
	}		
	
	
	const handleTaxonomyClose = () => {		
		refTaxonomyDialog.current.handleTaxonomyWizClose();		
	}			
	
	
	const handleTaxonomyDelete = async () => {	

		confirm({ 
				title: "Taxonomiebericht löschen", 
				description: "Hierdurch werden alle Daten für diesen Taxonomiebericht, einschließlich verschachtelter Daten und Dateien, endgültig gelöscht.",
				confirmationText: "Löschen starten",				
				cancellationText: "Abbrechen",				
				confirmationButtonProps: {color:"error", variant:"contained", style:{textTransform: 'none'}},
				cancellationButtonProps: {color:"success", style:{textTransform: 'none'}}								
			})
		.then( async () => {
			setState({
				...state,
				isLoading: true
			})
				
			let options = {};
			let id = gstate.objEdit.id;
			options = {
				url: `/api/fac/taxonomy/${id}`,
				method: 'delete',
				//onUploadProgress : handleUploadProgress,			
			};		
			setAxiosAuthToken();
			try {
				const retFac = await axios( options);
				console.log( 'Calculated fac', retFac.data);
				setObjEdit( retFac.data);				
				setState({
					...state,
					isLoading: false
				})						
				handleTaxonomyClose();
				return true;
			} catch( error) {
				setObjErrors( error.response.data);
				console.log(error);
				setState({
					...state,
					isLoading: false
				})			
				return false;			
			}
		})		
	}			
	

	const TaxonomyResume = ( props) => {
				
		// create a ref
		const chartComponent = useRef( null);
		
		const opts = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie'
			},
			title: {
				text: 'Taxonomie Scoring'
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
			},
			accessibility: {
				point: {
					valueSuffix: '%'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: false
					},
					showInLegend: true,
					colors: [
						'#50B432', 
						'#ED561B', 
						'#DDDF00', 
						'#24CBE5', 
						'#64E572', 
						'#FF9655', 
						'#FFF263', 
						'#6AF9C4'
					],					
				}
			},
			series: [{
				name: 'Antworte',
				colorByPoint: true,
				data: [{
					name: 'Ja',
					y: 0,
					sliced: true,
					selected: true
				},  {
					name: 'Nein',
					y: 0
				},  {
					name: 'Weiß nicht',
					y: 0
				}]
			}]
		}	
		const [options, setOptions] = useState( opts);					
				
		// some consts
		const useStyles = makeStyles( theme => ({
			chartWrapper: {
				float: 'left',
				//paddingBottom: '40%',
				position: 'relative',
				width: '100%'
			}
		}));			
		const classes = useStyles( props); 


		useEffect( () => {	

			console.log( 'ChartPlanValuation useEffect');
			
			const chart = chartComponent.current.chart;		
			console.log( "chart..", chart.current);
							
			setResults( chart);	
								
		}, [JSON.stringify( gstate.objEdit.fac_taxonomy)]);		
		

		const setResults = ( chart) => {
			
			console.log( '--> setResults');
			
			try {
			
				var fac = gstate.objEdit;
				const vln = fac.fac_taxonomy;
				if( !vln) {
					console.log( '<-- setResults ERR no fac_taxonomy');
					return;
				}

				const results = fac.fac_taxonomy.results;
				console.log( 'results', results);
				if( !results) {
					console.log( '<-- setResults ERR no fac_taxonomy.results');
					return;
				}
				
								
				var opts = options;
				opts.series[0].data = [results.yes_count, results.no_count, results.notknown_count];
				
				chart.update( opts);
				
				setOptions( opts);
			
			} catch( e) {
				console.error( 'setResults ERR', e);	
			}
			
			console.log( '<-- setResults', options);
		}
		
				
		return (			
			<Grid container direction="row">							
				<Grid item xs={12} sm={12} md={6} style={{minWidth:!gstate.isXS ? 400 : ''}}>
					<div className={classes.chartWrapper}>
						<HighchartsReact 
							ref={chartComponent} 
							highcharts={Highcharts}
							options={options}
						/>
					</div>
				</Grid>	
				{props.showHint ?	
					<Grid item xs={12} sm={12} md={6} style={{padding:20}}>
						{gstate.objEdit.fac_plan_valuations ? 
							<>
							<QeeHintBox type="info" style={{marginTop:10}} text={<React.Fragment>
								<strong>Positive Antworte:</strong>
							</React.Fragment>} />
							</> : ''}
					</Grid>			
				: ''}
			</Grid>										
			
		)
	}	

	
	return (	
		gstate.isLoading ? (
			<div className={classes.root}>
				{gstate.isLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (			
			<main className={classes.content}>
				<FacTitle fac={gstate.objEdit} showPicture={true} />
				
				<Grid container 
					spacing={8} 
					direction="row" 
					justifyContent="left" 
					alignItems="left">																						
							
					<Grid item xs={12} style={{maxWidth:1200}}>						
						<h1>Taxonomie der Immobilie</h1>												
						<p>Das Taxonomie-Scoring zeigt den Nachhaltigkeitsgrad der Investition.</p>	
						<Box className={classes.resultCard}>
							<Grid container direction="column">
								{gstate.objEdit.fac_taxonomy.done ?
								<Grid item style={{marginBottom:20}}>
									<>
									<QeeTooltip title="Taxonomie-Bericht bearbeiten">					
									<QeeColorButtonPrimary disableElevation 
										className={classes.button} 
										onClick={handleTaxonomyOpen}>
											<div className={classes.buttonLabel}>{gstate.isXS ? <CalculateIcon fontSize='large' style={{paddingTop:5}} /> : 'Bearbeiten'}</div>
									</QeeColorButtonPrimary>	
									</QeeTooltip>						
									<QeeTooltip title="Taxonomie-Bericht löschen">					
									<QeeColorButtonSecondary disableElevation 
										className={classes.button} 
										onClick={handleTaxonomyDelete}>
											<div className={classes.buttonLabel}>{gstate.isXS ? <CalculateIcon fontSize='large' style={{paddingTop:5}} /> : 'Löschen'}</div>
									</QeeColorButtonSecondary>	
									</QeeTooltip>															
									</>
								</Grid>
								:''}								
								<Grid item>
									<Grid item className={classes.textContainer}>
										{!gstate.objEdit.fac_taxonomy.done ?
										<>
											<div className={classes.text}>
												<Grid container direction="row">
													<Grid item  style={{marginRight:10, paddingTop:10, color:'#788'}}>
														<ForestIcon fontsize="big" />
													</Grid>
													<Grid item>
														Du hast den Taxonomie-Assistent für diese Ausprägung noch nicht ausgeführt.<br/>
														Für die Erstellung des Taxonomie-Scorings benötigen wir noch weitere Informationen.<br/><br/>
													</Grid>													
												</Grid>
											</div>
											<div  style={{marginBottom:12}}>
												<a onClick={handleTaxonomyOpen} style={{fontWeight:700}} href='#'>Hier</a> kannst du den Taxonomie-Assistent starten!																																					
											</div>
											<QeeTooltip title="Taxonomie-Assistent starten">					
											<QeeColorButtonPrimary disableElevation 
												className={classes.button} 
												onClick={handleTaxonomyOpen}>
													<div className={classes.buttonLabel}>{gstate.isXS ? <CalculateIcon fontSize='large' style={{paddingTop:5}} /> : 'Taxonomie-Assistent'}</div>
											</QeeColorButtonPrimary>	
											</QeeTooltip>						
										</>
										:
										<TaxonomyResume taxo={gstate.objEdit.fac_taxonomy} />}
									</Grid>	
								</Grid>
							</Grid>							

						</Box>
					</Grid>
					
				</Grid>											
				
				<FacTaxonomyWizDialog 
					ref={refTaxonomyDialog} 
					subpageEnergy="loadFacEnergy"
					subpageValuation="loadFacValuation"
				/>						
			
			</main>				
		)		
	)
}	
export default withStyles(styles)(FacTaxonomyForm);