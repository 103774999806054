import react, { useState, useRef, useEffect } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles }  from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import ConstructionIcon from '@mui/icons-material/Construction';
import moment from 'moment';

import FacRenovationIst from './facRenovationIst';
import QeeRadioGroup from './qeeRadioGroup';
import FacRenovationsCheckboxGroup from './facRenovationsCheckboxGroup';
import LabelEnergyClass from './labelEnergyClass';
import DiagramEnergyClass from './diagramEnergyClass';

import { useAuth } from "../context/provider";
import { useLicense } from "./qeeUsrLicenseProvider";   // aen-20230331 moved here from qeeRadioGroup - recurs. imports problem in qeewidgets vite
import { checkLicense } from '../util/auth';

import { useValidation } from './qeeStepValidationProvider';

const FacRenovationResultIst = ( props) => { 
	
	const { setOpenLicenseDialog } = useLicense();
	const { registerValidation } = useValidation();	
	
	const errorRef = useRef(null);		
	
	const thisStep = props.step;		
	
	const { gstate, setObjEdit, setCurrSubpage, setOpenRenovationDialog } = useAuth();	
	//const { setObjEdit } = useAuth();	
	//const gstate = props.gstate;
	
	const [objChange, setObjChange] = useState({
		fac_use_plan_renovation:gstate.objEdit.fac_use_plan_renovation,
		fac_energy:gstate.objEdit.fac_energy
	});	
	const objChangeRef = useRef( objChange);
	useEffect(() => {
		objChangeRef.current = objChange;  // Update ref on state change
	}, [objChange]);											
	const [objErrors, setObjErrors] = useState({});	


	// some consts
	const useStyles = makeStyles( theme => ({
		//...props
		textPlan: {
			color:theme.typography.success?.color,   //theme.palette.primary.main,
			fontWeight: 800,			
		},
		validationContainer: {
			borderRadius: 5,
			padding: 5,
			backgroundColor: '#fff',
			[theme.breakpoints.down('xs')]: {
			padding: 0,
			},    
		}		
	}));			
	const classes = useStyles( props);
	

	const showError = (fieldId) => {

		const errorElement = document.getElementById(fieldId);
		console.log('showError Element ' + fieldId + ': ' + errorElement.id);
		if (errorElement) {
			errorRef.current = errorElement;
		}
		return fieldId;		
	};
	
	
	const resetErrors = ( id) => {				
		setObjErrors({});
		document.getElementById( id).style.backgroundColor = '#fff';				
	}	
	
	
	useEffect(() => {
		const errorElements = document.querySelectorAll('.selError');
		if (errorElements.length > 0) {
			// Defer the background color change
			setTimeout(() => {
				errorElements.forEach(element => {
					const parentElement = element.parentNode;
					if (parentElement) {
						parentElement.style.backgroundColor = '#fee';
						parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
					}
				});
			}, 0);
		}
	}, [errorRef.current]);
	
	
	useEffect(() => {
		registerValidation( thisStep, validate);

		// Clean up: unregister validation when the component unmounts or the step changes
		return () => {
			registerValidation( thisStep, null);
		};
	}, []);		
	
	
	/**
	* validate() - 
	*/	
	const validate = async () => {
		console.log('--> validate for FacRenovationResultIst in step: ', gstate.objEdit.step);
		let firstErrorShown = false; // Flag to track if the first error has been shown
		let errors = false;
		const objErrors = {};

		if (!objChangeRef.current.fac_use_plan_renovation && !firstErrorShown) {
			objErrors.fac_use_plan_renovation = 'Du musst eine Auswahl treffen!';
			showError('fac_use_plan_renovation');
			errors = true;
			firstErrorShown = true; // Set the flag to true after showing the first error
		}

		setObjErrors(objErrors);
		if (!errors) {
			const { fac_energy, ...restOfObjEdit } = gstate.objEdit;   // exclude the fac_energy
			let oe = {
				...gstate.objEdit,
				//...restOfObjEdit,
				//fac_energy: {},
				fac_use_plan_renovation: objChangeRef.current.fac_use_plan_renovation,
			}
			oe = await props.fctSubmit( oe)
			oe = await props.fctRenovationCalculate( oe);			
			//alert( 'after net: ' + oe.fac_energy.end_ist)
			setObjEdit( oe);
			
			if( objChangeRef.current.fac_use_plan_renovation !== '1') {
				if( props.subpageEnergy) {
					setCurrSubpage( props.subpageEnergy);
				}
				setOpenRenovationDialog( false);									
			}	
		}

		console.log('<-- validate: ' + errors);
		return !errors;
	};	
	
	
	  useEffect(() => {
        console.log (">>> gstate updated", gstate);
        // Perform any additional actions here if needed
    }, [gstate]);
											
		
	// {value:'1', label:'Ja', licenseDisabled:(checkLicense( gstate.usr.lic)=='insufficient'), setOpenLicenseDialog:setOpenLicenseDialog},		
    return (
        <div class="form-group">
			<form  noValidate>					
				<Grid container 
					spacing={gstate.isXS ? 0 : 2} 
					direction="column" 
					justifyContent="left" 
					alignItems="left">
					<Grid item xs={12}>
						<h1>Aktueller Zustand</h1>		
					</Grid>

					<Grid item xs={11}>	
						<FacRenovationIst />
					</Grid>
																				
					<Grid item xs={12}>
						<div style={{display:'flex'}}>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<ConstructionIcon className={classes.textPlan} sx={{fontSize:64, marginRight:2}}/>
							</div>
							<div>
								<div><h3>Sehr gut! Nachdem du nun den aktuellen Zustand der Immobilie kennst,</h3></div>
								<div><h3> geht’s jetzt weiter.</h3></div>
							</div>
						</div>
						<Grid container 
							spacing={gstate.isXS ? 0 : 2} 
							direction="column" 
							justifyContent="left" 
							alignItems="left">					
							<Grid item xs={12}>
							<div id='fac_use_plan_renovation' className={classes.validationContainer}>
							<QeeRadioGroup
								formSublabel={<div style={{maxWidth:800}}>Möchtest du erfahren durch welche konkreten Maßnahmen sich der Zustand der Immobilie verbessern kann?</div>}
								defaultValue={objChange.fac_use_plan_renovation}
								fctOnChange={( ev)=>{									
									console.log( 'onChange fac_use_plan_renovation', ev.target.value);
									var och = {
										...objChange,
										fac_use_plan_renovation: ev.target.value
									};
									setObjChange( och);
									resetErrors( 'fac_use_plan_renovation');
								}}
								sdata={[
									{value:'1', label:'Ja'},
									{value:'0', label:'Nein'}
								]}
								/>
								{ objErrors.fac_use_plan_renovation ? ( <div style={{textAlign:"center"}}><small   className="selError form-text error">{objErrors.fac_use_plan_renovation}</small></div> ) :( <></> ) }
							</div>
							</Grid>
						</Grid>
					</Grid>															
				</Grid>
			</form>
		</div>
    )
}
export default FacRenovationResultIst;