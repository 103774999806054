import React, { useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import redMarkerIcon from '../assets/red-pushpin.png';
import FacImage from './facImage';
import { getSdatas } from '../api/service';

const redIcon = new L.Icon({
	iconUrl: redMarkerIcon,
	iconSize: [32, 32],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const useStyles = makeStyles((theme) => ({
	root: {
		width: 250,
		cursor: 'pointer',
	},
	title: {
		fontSize: 14,
		color: 'textSecondary',
		marginBottom: 12,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	pos: {
		marginBottom: 12,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	role: {
		color: 'primary',
		marginBottom: 6,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	button: {
		marginTop: 12,
		width: '100%',
	},
	picture: {
		width: '100%',
		height: 'auto',
		borderRadius: 0,
		objectFit: 'cover',
	},
	text: {
		paddingLeft: 10,
		paddingRight: 10,		
	},	
	cardTitle: {
		marginBottom: theme.spacing(0.5),
		marginTop: 0,
		maxWidth: 320,
	},
	facName: {
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
		WebkitLineClamp: 2,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	leafletPopupContentWrapper: {
		position: 'relative',
		'& .leaflet-popup-close-button': {
			display: 'none', // Hide the close button
		},
		'& .leaflet-popup-content': {
			margin: 0,
			padding: 0,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'stretch',
		},
		'& .leaflet-popup-content-wrapper': {
			padding: 0,
			borderRadius: 5,
		},
		'& .leaflet-popup-tip-container': {
			display: 'none', // Hide the popup tip
		}
	}
}));

const FacMarker = ({ fac }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [typeText, setTypeText] = useState('');	
	
	// Intelligent address construction
	const addressParts = [
		fac.addr_street_o,
		fac.addr_housenumber_o,
		fac.addr_street_zip_o
	];
	const filteredAddressParts = addressParts.filter(Boolean);
	let fullAddress = filteredAddressParts.join(' ');
	if (fac.addr_locality_o) {
		fullAddress += (fullAddress ? ', ' : '') + fac.addr_locality_o;
	}
	
	useEffect(() => {
		const fetchFacType = async () => {
			try {
				// get house type/subtype
				let tpText = '';
				var arrRet = await getSdatas('sdata_facs_types', 'type', fac.fac_type);				
				tpText = arrRet[0].name;
				if (fac.fac_type == 100)   // allow int or string
					arrRet = await getSdatas('sdata_houses_types', 'type', fac.fac_house_type);				
				else if (fac.fac_type == 200)   // allow int or string
					arrRet = await getSdatas('sdata_apartments_types', 'type', fac.fac_apartment_type);				
				tpText += ' | ' + arrRet[0].name;
				setTypeText(tpText);

			} catch (error) {
				console.error('Error fetching role text:', error);
				setTypeText('Abfragefehler für Gewerke');
			}
		};

		fetchFacType();
	}, [fac.fac_type]);

	const handlePopupClick = (e) => {
		e.stopPropagation();
		window.open(`/fac?id=${fac.id}#loadFacDashboard`, '_blank');
	};

	return (
		<Marker position={[fac.addr_geo_lat_o, fac.addr_geo_lon_o]} icon={redIcon}>
			<Popup className={classes.leafletPopupContentWrapper}>
				<div className={classes.root} onClick={handlePopupClick}>
					<FacImage
						fac={fac}
						pictureDivStyle={{
							width: '100%',
							backgroundColor: '#eee',
						}}
						pictureStyle={{
							width: '100%',
							height: '120px',
							borderRadius: 0,
							objectFit: 'cover',
						}}
						onClick={handlePopupClick} // Ensure the click is handled here as well
					/>
					<Grid className={classes.text} container direction="column">
						<Grid item>
							<div className={classes.cardTitle}>
								<Typography variant='body1' className={classes.facName} style={{marginTop:0}}>{fac.fac_name}</Typography>
							</div>
							<div className={classes.cardTitle}>
								<Typography variant='body4'>{typeText}</Typography>
							</div>
							<div className={classes.cardTitle}>
								<Typography variant='body4'>{fullAddress}</Typography>
							</div>
						</Grid>
					</Grid>
				</div>
			</Popup>
		</Marker>
	);
};

export default FacMarker;
