import React from "react";
import react, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

import { useAuth } from "../context/provider";


const LabelEnergyClass = ( props) => {
	
	//console.log( 'LabelEnergyClass', props);
	
	/**
	* 	Workaround to be able to use this comp from another app. 
	*	The ext app must provide initilaized elems gstate and setObjEdit as props (aen-20230331).
	*/
	function useAuthData() {
		
		var auth = useAuth();
		
		if( props.gstate) {
			const gstate = props.gstate;
			const setObjEdit = props.setObjEdit;		
			return { gstate, setObjEdit };			
		} else {
			const { gstate, setObjEdit } = auth;			
			return { gstate, setObjEdit };
		}
	}
	const { gstate, setObjEdit } = useAuthData();   // avoid hook limitations			
				
	const getBackgroundColor = () => {		
	
		//console.log( '--> getBackgroundColor', props.energy);
			
		if( !props.obj)
			return '#fff';
	
		var energy = props.obj.fac_energy[props.energy];
		
		//console.log( 'energy', energy);
	
		var col;
		if( energy < 30)
			col = '#25FE01'
		else if( energy >= 30 && energy < 50)			
			col = '#8EFF01'			
		else if( energy >= 50 && energy < 75)
			col = '#B9FF01'			
		else if( energy >= 75 && energy < 100)						
			col = '#E6FF01'			
		else if( energy >= 100 && energy < 130)									
			col = '#F9FF00'			
		else if( energy >= 130 && energy < 160)									
			col = '#FEF102'			
		else if( energy >= 160 && energy < 200)									
			col = '#FFD600'			
		else if( energy >= 200 && energy < 250)												
			col = '#FF7B00'			
		else
			col = '#FE3A00'			
		
		//console.log( '<-- getBackgroundColor', col);		
		
		return col;		
	}
	
	
	const getLetter = () => {			
	
		//console.log( '--> getLetter');
		
		if( !props.obj) {
			console.error( '<-- getLetter ERR: no object');		
			return '-';			
		}
		
		var energy = props.obj.fac_energy[props.energy];		
		
		//console.log( 'getLetter energy', energy);		
		
		var ltr;
		if( energy < 30)
			ltr = 'A+'
		else if( energy >= 30 && energy < 50)			
			ltr = 'A'			
		else if( energy >= 50 && energy < 75)
			ltr = 'B'			
		else if( energy >= 75 && energy < 100)						
			ltr = 'C'			
		else if( energy >= 100 && energy < 130)									
			ltr = 'D'			
		else if( energy >= 130 && energy < 160)									
			ltr = 'E'			
		else if( energy >= 160 && energy < 200)									
			ltr = 'F'			
		else if( energy >= 200 && energy < 250)												
			ltr = 'G'			
		else
			ltr = 'H'					
				
		//console.log( '<-- getLetter', ltr);		
	
		return ltr;
	}
	
	
	// some consts
	const useStyles = makeStyles( theme => ({
		label: {
			//marginLeft: 5,
			borderRadius: 5,
			color: '#222', //theme.palette.primary.main,
			backgroundColor: getBackgroundColor(),
			fontWeight: 700,
			fontSize:props.xs ? 16 : 48, 
			fontWeight:700, 
			paddingLeft:props.xs ? 8 : 18, 
			paddingTop:props.xs ? 2 : 0, 
			height:props.xs ? 28 : 72, 
			width:(props.xs ? 
				getLetter().length == 1 ? 28 : 40
				:
				getLetter().length == 1 ? 72 : 96
			)
		},
	}));			
	const classes = useStyles( props);		
	
		
	return (
		<>
		{props.obj ?
		props.obj.fac_energy[props.energy] !== undefined ? 
			<div className={classes.label}>
				{getLetter()}
			</div>
			:
			<></>		 
		:''}
		</>
    );
	
}
export default LabelEnergyClass;