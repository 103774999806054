// facRenovationComponents.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles }  from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import QeeRadioGroup from './qeeRadioGroup';
import QeeFileInput from './qeeFileInput';

import { ReactComponent as FacTopceiling100Icon } from "../assets/fac_topceiling_100.svg";
import { ReactComponent as FacTopceiling200Icon } from "../assets/fac_topceiling_200.svg";
import { ReactComponent as FacTopceiling300Icon } from "../assets/fac_topceiling_300.svg";

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";

import { useValidation } from './qeeStepValidationProvider';


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0.5)
		}				
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
			marginTop: theme.spacing(1)
		},		
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	validationContainer: {
		borderRadius:5,
		padding:5,
		[theme.breakpoints.down('xs')]: {
			padding:0,
		},				
		backgroundColor: '#fff'
	},
	validationContainerActive: {
		borderRadius:5,
		padding:5,
		[theme.breakpoints.down('xs')]: {
			padding:0,
		},				
		backgroundColor: '#fee'
	}		
});


const FacRenovationComponents = ( props) => {
	
	const { registerValidation } = useValidation();		
	
	const formMain = useRef( null);
	const errorRef = useRef(null);	
	
	const thisStep = props.step;			
	
	const { gstate, setObjEdit } = useAuth();				
	
	const [validationErrors, setValidationErrors] = useState( []);
	const [objChange, setObjChange] = useState({
		fac_roof_material: 		gstate.objEdit.fac_roof_material,
		fac_topceiling_type: 	gstate.objEdit.fac_topceiling_type,
		fac_fassade_material: 	gstate.objEdit.fac_fassade_material,												
		fac_windows_type: 		gstate.objEdit.fac_windows_type,
		fac_heating_type: 		gstate.objEdit.fac_heating_type,
		fac_has_solar_thermic: 	gstate.objEdit.fac_has_solar_thermic,												
		fac_heating_distribution_type: 	gstate.objEdit.fac_heating_distribution_type,
		fac_has_pvroof: 		gstate.objEdit.fac_has_pvroof,												
	});						
	const objChangeRef = useRef( objChange);	
	useEffect(() => {
		objChangeRef.current = objChange;  // Update ref on state change
	}, [objChange]);											
	const [objErrors, setObjErrors] = useState({});	

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);		
			
	
	const resetErrors = ( id) => {				
		setObjErrors({});
		document.getElementById( id).style.backgroundColor = '#fff';				
	}
	
	
	const showError = (fieldId) => {

		const errorElement = document.getElementById(fieldId);
		console.log('showError Element ' + fieldId + ': ' + errorElement.id);
		if (errorElement) {
			errorRef.current = errorElement;
		}
		return fieldId;		
	};
	
	
	useEffect(() => {
		const errorElements = document.querySelectorAll('.selError');
		if (errorElements.length > 0) {
			// Defer the background color change
			setTimeout(() => {
				errorElements.forEach(element => {
					const parentElement = element.parentNode;
					if (parentElement) {
						parentElement.style.backgroundColor = '#fee';
						parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
					}
				});
			}, 0);
		}
	}, [errorRef.current]);
	
	
	/**
	* validate() - 
	*/
	const validate = async () => {
		console.log('--> validate for FacRenovationComponents in step: ', gstate.objEdit.step);
		let firstErrorShown = false; // Flag to track if the first error has been shown
		let errors = false;
		const objErrors = {};

		if (gstate.objEdit.fac_type === '100') {
			if (!objChangeRef.current.fac_topceiling_type && !firstErrorShown) {
				objErrors.fac_topceiling_type = 'Du musst die Art der Dachdämmung auswählen!';
				showError('fac_topceiling_type');
				errors = true;
				firstErrorShown = true; // Set the flag to true after showing the first error
			}

			if (!objChangeRef.current.fac_heating_type && !firstErrorShown) {
				objErrors.fac_heating_type = 'Du musst die Primärheizung auswählen!';
				showError('fac_heating_type');
				errors = true;
				firstErrorShown = true; // Set the flag to true after showing the first error
			}

			if (!objChangeRef.current.fac_has_solar_thermic && !firstErrorShown) {
				objErrors.fac_has_solar_thermic = 'Du musst eine Option auswählen!';
				showError('fac_has_solar_thermic');
				errors = true;
				firstErrorShown = true; // Set the flag to true after showing the first error
			}

			if (!objChangeRef.current.fac_has_pvroof && !firstErrorShown) {
				objErrors.fac_has_pvroof = 'Du musst eine Option auswählen!';
				showError('fac_has_pvroof');
				errors = true;
				firstErrorShown = true; // Set the flag to true after showing the first error
			}
		}
				
		setObjErrors(objErrors);
		if (!errors) {
			let oe = {
				...gstate.objEdit, 
				...objChangeRef.current
			};
			setObjEdit( await props.fctSubmit( oe));
		}	

		console.log('<-- validate: ' + errors);
		return !errors;
	};
	
		
	useEffect(()=> {  

		formMain.current.scrollIntoView() 		
	
	}, []);	


	useEffect(() => {
		registerValidation( thisStep, validate);

		// Clean up: unregister validation when the component unmounts or the step changes
		return () => {
			registerValidation( thisStep, null);
		};
	}, []);	

	
	const rgIconStyle = () => {
		if( !gstate.isXS)
			return { width:100, height:100 }
		else
			return { width:36, height:36 }
	}			
	
			
	return (
        <div class="form-group" ref={formMain}>
			<form  noValidate>					
				<Grid container 
					spacing={2} 
					direction="column" 
					justifyContent="left" 
					alignItems="left">
					<Grid item xs={12}>
						<h1>Diverse Bauteile</h1>		
					</Grid>

					{gstate.objEdit.fac_type == '100' ?
					<Grid item xs={12}>
						<div id='fac_roof_material' className={classes.validationContainer}>
						<QeeRadioGroup
							formLabel='Dach'
							formSublabel='Material der Dacheindeckung'
							defaultValue={objChange.fac_roof_material}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_roof_material', ev.target.value);
								var och = {
									...objChange,
									fac_roof_material: ev.target.value
								};
								setObjChange( och);
								resetErrors( 'fac_roof_material');
							}}																					
							sdataName='sdata_roofs_materials'
							hasDesc={true}
						/>
						{ objErrors.fac_roof_material ? ( <div style={{textAlign:"center"}}><small  className="selError form-text error">{objErrors.fac_roof_material}</small></div> ) :( <></> ) }						
						</div>		
					</Grid>	
					:''}	
					
					{gstate.objEdit.fac_type == '100' ?					
					<Grid item xs={12}>	
						<div id='fac_topceiling_type' className={classes.validationContainer}>					
						<QeeRadioGroup
							formSublabel='Art der Dachdämmung *'
							defaultValue={objChange.fac_topceiling_type}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_topceiling_type', ev.target.value);
								var och = {
									...objChange,
									fac_topceiling_type: ev.target.value
								};
								setObjChange( och);
								resetErrors( 'fac_topceiling_type');
							}}																												
							sdata={[
								{value:'100', label:'Dachfläche', 		icon:<FacTopceiling100Icon style={rgIconStyle()}/>},
								{value:'200', label:'Geschossdecke', 	icon:<FacTopceiling200Icon style={rgIconStyle()}/>},
								{value:'300', label:'keine', 			icon:<FacTopceiling300Icon style={rgIconStyle()}/>}																
							]}
						/>					
						{ objErrors.fac_topceiling_type ? ( <div style={{textAlign:"center"}}><small  className="selError form-text error">{objErrors.fac_topceiling_type}</small></div> ) :( <></> ) }						
						</div>		
					</Grid>
					:''}
					
					{gstate.objEdit.fac_type == '100' ?		
						<div style={{marginLeft:!gstate.isXS ? 30 : 10}}>					
						<QeeFileInput 
							compId="200"
							facId={gstate.objEdit.id}
							buttonText="Dach Fotos hochladen"
							keyItm={['sdoc_type','sdoc_subtype']}
							keyVal={[200,200]}
							sdoc_type={200}
							sdoc_subtype={200}
						/>
						</div>
					:''}
	
					{gstate.objEdit.fac_type == '100' ?					
					<Grid item xs={12}>
						<div id='fac_fassade_material' className={classes.validationContainer}>											
						<QeeRadioGroup
							formLabel='Fassade'
							formSublabel='Material der Fassadenbekleidung'
							defaultValue={objChange.fac_fassade_material}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_fassade_material', ev.target.value);
								var och = {
									...objChange,
									fac_fassade_material: ev.target.value
								};
								setObjChange( och);
								resetErrors( 'fac_fassade_material');
							}}																												
							sdataName='sdata_fassades_types'
							hasDesc={true}		
						/>
						{ objErrors.fac_fassade_material ? ( <div style={{textAlign:"center"}}><small  className="selError form-text error">{objErrors.fac_fassade_material}</small></div> ) :( <></> ) }						
						</div>
					</Grid>						
					:''}
					
					{gstate.objEdit.fac_type == '100' ?		
						<div style={{marginLeft:!gstate.isXS ? 30 : 10}}>					
						<QeeFileInput 
							compId="300"
							facId={gstate.objEdit.id}
							buttonText="Fassade Fotos hochladen"
							keyItm={['sdoc_type','sdoc_subtype']}
							keyVal={[200,300]}
							sdoc_type={200}
							sdoc_subtype={300}
						/>
						</div>
					:''}					
																			
					<Grid item xs={12}>
						<div id='fac_windows_type' className={classes.validationContainer}>																	
						<QeeRadioGroup
							formLabel='Fenster'
							formSublabel='Rahmenmaterial'
							defaultValue={objChange.fac_windows_type}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_windows_type', ev.target.value);
								var och = {
									...objChange,
									fac_windows_type: ev.target.value
								};
								setObjChange( och);
								resetErrors( 'fac_windows_type');
							}}																																			
							sdataName='sdata_windows_types'
							hasDesc={false}
						/>
						{ objErrors.fac_windows_type ? ( <div style={{textAlign:"center"}}><small  className="selError form-text error">{objErrors.fac_windows_type}</small></div> ) :( <></> ) }
						</div>	
					</Grid>	
					
					<div style={{marginLeft:!gstate.isXS ? 30 : 10}}>
					<QeeFileInput 
						compId="400"
						facId={gstate.objEdit.id}
						buttonText="Fenster Fotos hochladen"
						keyItm={['sdoc_type','sdoc_subtype']}
						keyVal={[200,400]}
						sdoc_type={200}
						sdoc_subtype={400}
					/>
					</div>

					<Grid item xs={12}>						
						<div id='fac_heating_type' className={classes.validationContainer}>																	
						<QeeRadioGroup
							formLabel='Primärheizung *'
							formSublabel='System'
							defaultValue={objChange.fac_heating_type}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_heating_type', ev.target.value);
								var och = {
									...objChange,
									fac_heating_type: ev.target.value
								};
								setObjChange( och);
								resetErrors( 'fac_heating_type');
							}}																																			
							sdataName='sdata_heatings_types'
							hasDesc={false}
						/>
						{ objErrors.fac_heating_type ? ( <div style={{textAlign:"center"}}><small  className="selError form-text error">{objErrors.fac_heating_type}</small></div> ) :( <></> ) }
						</div>

						{gstate.objEdit.fac_type == '100' ?					
							<div style={{marginLeft:!gstate.isXS ? 20 : 0}}>
							<QeeFileInput						
								compId="600"
								facId={gstate.objEdit.id}
								buttonText="Heizung Fotos hochladen"
								keyItm={['sdoc_type','sdoc_subtype']}
								keyVal={[200,600]}
								sdoc_type={200}
								sdoc_subtype={600}
							/>
							</div>
						:''}
						
					</Grid>	
					
					<Grid item xs={12}>
						<div id='fac_has_solar_thermic' className={classes.validationContainer}>						
						<QeeRadioGroup
							formLabel='Sekundärheizung *'
							formSublabel='Ist eine thermische Solaranlage vorhanden?'
							defaultValue={objChange.fac_has_solar_thermic}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_has_solar_thermic', ev.target.value);
								var och = {
									...objChange,
									fac_has_solar_thermic: ev.target.value
								};
								setObjChange( och);
								resetErrors( 'fac_has_solar_thermic');
							}}																																			
							hasDesc={false}
							sdata={[
								{value:'1', label:'Ja'},
								{value:'0', label:'Nein'}
							]}
						/>
						{ objErrors.fac_has_solar_thermic ? ( <div style={{textAlign:"center"}}><small  className="selError form-text error">{objErrors.fac_has_solar_thermic}</small></div> ) :( <></> ) }
						</div>
					</Grid>	
					
					<Grid item xs={12}>
						<div id='fac_heating_distribution_type' className={classes.validationContainer}>												
						<QeeRadioGroup
							formLabel='Wärmeverteilung'
							formSublabel='Art der Wärmeverteilung'
							defaultValue={objChange.fac_heating_distribution_type}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_heating_distribution_type', ev.target.value);
								var och = {
									...objChange,
									fac_heating_distribution_type: ev.target.value
								};
								setObjChange( och);
								resetErrors( 'fac_heating_distribution_type');
							}}																																										
							sdata={[
								{value:'100', label:'Heizkörper'},
								{value:'200', label:'Fussbodenheizung'},
								{value:'0', label:'Weiss nicht'},																
							]}
						/>
						{ objErrors.fac_heating_distribution_type ? ( <div style={{textAlign:"center"}}><small  className="selError form-text error">{objErrors.fac_heating_distribution_type}</small></div> ) :( <></> ) }
						</div>		
					</Grid>	

					<Grid item xs={12}>
						<div id='fac_has_pvroof' className={classes.validationContainer}>						
						<QeeRadioGroup
							formLabel='PV-Dach Anlage *'
							formSublabel='Ist eine Dach PV-Anlage vorhanden?'
							defaultValue={objChange.fac_has_pvroof}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_has_pvroof', ev.target.value);
								var och = {
									...objChange,
									fac_has_pvroof: ev.target.value
								};
								setObjChange( och);
								resetErrors( 'fac_has_pvroof');
							}}																																			
							hasDesc={false}
							sdata={[
								{value:'1', label:'Ja'},
								{value:'0', label:'Nein'}
							]}
						/>
						{ objErrors.fac_has_pvroof ? ( <div style={{textAlign:"center"}}><small  className="selError form-text error">{objErrors.fac_has_pvroof}</small></div> ) :( <></> ) }
						</div>
					</Grid>						
					
					<Grid item xs={12}>
											
					</Grid>						
					
				</Grid>
			</form>
		</div>
	);

}	
export default withStyles(styles)( FacRenovationComponents);