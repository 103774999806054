// facWiz.js

import react, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import React, { useImperativeHandle } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import FacRenovationWiz from './facRenovationWiz';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import QeeStepValidationContext from './qeeStepValidationProvider';

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	button: {
		textTransform: 'none'
	},
	/*
	newPosOfDialog: {		
		[theme.breakpoints.up('lg')]: {
			transform: "translate(100%, 0%)",
		},			
	},
	dialog: {
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},			
		position: 'absolute',
		left: 10,
		top: 50,	
	},	
	dialogStyle: {
		maxWidth: '50%',
		[theme.breakpoints.down('md')]: {
			maxWidth: '100%'
		},						
	},
	*/	
	appBarTitle: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},		
});



// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


// main fct
//const FacRenovationWizDialog = ( props) => {
const FacRenovationWizDialog = React.forwardRef((props, forwardedRef) => {	
	
	const { gstate, setObjEdit, setObjErrors, setOpenRenovationDialog, setIsFetching, setCurrSubpage } = useAuth();				
	
	const handleRenovationOpen = ( step) => {
		console.log( 'handleRenovationOpen', step);
		
		setOpenRenovationDialog( true);

		if( step == undefined)
			step = 0;	
		var oe = gstate.objEdit;
		oe.step = step;
		if( step >= 4)
			oe.fac_use_plan_renovation = '1';
		setObjEdit( oe);
	}	
	
	const handleRenovationClose = () => {
		setObjErrors({});
		setOpenRenovationDialog( false);
	};
		
	const handleRenovationSubmit = async (event) => {
		console.log('>>> handleRenovationSubmit', event);

		// Uncomment and adjust if middleware and navigation logic are needed
		// authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		// if (authToken == null) navigate('/login');
		// if (event && event.preventDefault) event.preventDefault();

		const newFac = JSON.parse( JSON.stringify( event));   // This might typically be gstate.objEdit;
		console.log('submit obj', newFac);

		const id = event.id;
		const options = {
			url: `/api/fac/${id}`,
			method: 'put',
			data: newFac
		};

		axios.defaults.headers.common = { Authorization: `${authToken}` };

		try {
			const response = await axios(options);
			console.log('Submitted data', response.data);
			const oe = JSON.parse(JSON.stringify(response.data));
			//setObjEdit(oe);
			return oe;
		} catch (error) {
			console.log( 'Submit ERR', error);
			// Assuming setObjErrors is a function to update errors in the state
			setObjErrors(error.response.data);
			console.log(error);
			return event;
		}
	};

	
	const handleRenovationCalculate = async (event) => {
		
		console.log( '>>> handleRenovationCalculate', event);

		//authMiddleWare(this.props.history);
		const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login');	
		//if( event && event.preventDefault)		
		//	event.preventDefault();
		
		setIsFetching( true);						
		
		const newFac = JSON.parse( JSON.stringify( event));   //gstate.objEdit;
		console.log( 'calculate obj', newFac);		
		let options = {};
		let id = event.id;
		options = {
			url: `/api/fac/renovation/ist/${id}`,
			method: 'put',
			data: newFac
		};		
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		try {
			const retFac = await axios( options);
			console.log( 'Calculated fac', retFac.data);
			let oe = JSON.parse( JSON.stringify( retFac.data));
			//setObjEdit( oe);
			return oe;
		} catch( error) {
			setObjErrors( error.response.data);
			console.log(error);
			return event;
		} finally {
			setIsFetching( false);									
		}
	};	
	
	
	const handleRenovationSubmitEnd = async (event) => {
		
		console.log( '>>> handleRenovationSubmitEnd', event);		
		
		setOpenRenovationDialog( false);
		
		setIsFetching( true);						
		
		const authToken = localStorage.getItem( 'AuthToken');
		
		const newFac = JSON.parse( JSON.stringify( event));   ///gstate.objEdit;		
		
		 // append the key for checking the license (enterprise)
		let usr = JSON.parse( sessionStorage.getItem( 'usr'));				
		if( usr.lic)
			newFac.liccus_key = usr.lic.liccus_key;  
		
		console.log( 'Calculate fac plan', newFac);		
		let options = {};
		let id = event.id;
		options = {
			url: `/api/fac/renovation/plan/${id}`,
			method: 'put',
			data: newFac
		};		
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		try {
			const retFac = await axios( options);
			console.log( 'Calculated fac', retFac.data);
			//setObjEdit( retFac.data);
			//if( props.subpageEnergy)
			//	setCurrSubpage( props.subpageEnergy)	
					
			return retFac.data;
					
		} catch( error) {
			setObjErrors( error.response.data);
			console.error( 'Calculated fac ERR', error);
			
			//if( props.subpageEnergy)
			//	setCurrSubpage( props.subpageEnergy)				
			
			return event;
		} finally {
			setIsFetching( false);			
		}

				
			
	}

	
	/**
	*	expose it outside
	*/
	useImperativeHandle( forwardedRef, () => ({	
		ali: ()=>{alert(123)},
		handleRenovationOpen: (step)=>{handleRenovationOpen(step)},						
		handleRenovationClose: handleRenovationClose,	
		handleRenovationSubmit: handleRenovationSubmit,
		handleRenovationCalculate: handleRenovationCalculate,
		handleRenovationSubmitEnd: handleRenovationSubmitEnd				
	 }));
	 
	//const ref = useRef();	 
	
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
		screenDims: {width:0, height:0},
		openRenovation: false		
	})
		
	useEffect( () => {	

						
	}, []);		

	
	// some consts	
	const useStyles = makeStyles(theme => ({
		//...props,	
		newPosOfDialog: {		
			[theme.breakpoints.up('lg')]: {
				transform: !props.forceFullWidth ? "translate(100%, 0%)" : "",
			},			
		},
		dialog: {
			width: props.forceFullWidth ? '100%' : '50%',
			[theme.breakpoints.down('md')]: {
				width: '100%'
			},			
			position: 'absolute',
			left: 10,
			top: 50,	
		},	
		dialogStyle: {
			maxWidth: props.forceFullWidth ? '100%' : '50%',
			[theme.breakpoints.down('md')]: {
				maxWidth: '100%'
			},						
		},			
	}));	
	const classes = useStyles( props);	
	
		
	return (	
	
		<>
		<div ref={forwardedRef}></div>
		<Dialog 			
			fullScreen
			classes={{
				paper: classes.newPosOfDialog,
				paperFullWidth: classes.dialogStyle						
			}}
			className={classes.dialog}
			open={gstate.openRenovationDialog} 
			onClose={handleRenovationClose} 
			TransitionComponent={Transition}
		>
			<AppBar className={classes.appBarTitle}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleRenovationClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{'Sanierungsassistent für ' + gstate.objEdit.fac_name}
					</Typography>
				</Toolbar>
			</AppBar>
			<QeeStepValidationContext>
				<FacRenovationWiz 
					{...props}
					fctSubmit={handleRenovationSubmit} 
					fctRenovationCalculate={handleRenovationCalculate} 						
					fctSubmitEnd={handleRenovationSubmitEnd} />
			</QeeStepValidationContext>
		</Dialog>	
		</>
	
	)
})	
export default withStyles(styles)(FacRenovationWizDialog);