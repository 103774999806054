import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { QeeColorButtonSecondary } from './qeeColorButton';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: 1000,
        padding: theme.spacing(1),
		paddingTop: theme.spacing(0),
        [theme.breakpoints.down('md')]: {
            //position: 'sticky',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    formControls: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    errorText: {
        color: theme.palette.error.main,
    },
    actionButton: {
        marginTop: theme.spacing(1),
        height: '36px',
        width: 'auto',
        color: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
    },
    buttonLabel: {
        fontSize: '0.875rem',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
    },
}));

const SacsFilterBar = React.memo(({ sacType, sacDateLimit, sacState, sacTypes, sacStates, handleSacTypeChange, handleSacDateLimitChange, handleSacStateChange, error, initialFilter }) => {
    const classes = useStyles();
    const [anchorElType, setAnchorElType] = useState(null);
    const [anchorElDate, setAnchorElDate] = useState(null);
    const [anchorElState, setAnchorElState] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingState, setLoadingState] = useState(null);

    const handleMenuClick = (event, setAnchorEl) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (setAnchorEl) => {
        setAnchorEl(null);
    };

    const handleActionSelect = (value, handleChange, setAnchorEl) => {
        setLoadingState(value);
        handleCloseMenu(setAnchorEl);

        if (typeof handleChange === 'function') {
            handleChange({ target: { value } });
        }
    };

    const getLabelForValue = (value, items) => {
        const item = items.find(item => item.value == value); // do not change this, it must compare evtl. string with integer '100'==100
        return item ? item.label : '...';
    };

    const renderFilterButton = (label, currentValue, items, handleChange, anchorEl, setAnchorEl) => (
        <FormControl className={classes.formControl}>
            <Typography className={classes.buttonLabel}>{label}</Typography>
            <QeeColorButtonSecondary
                disableElevation
                className={classes.actionButton}
                onClick={(e) => handleMenuClick(e, setAnchorEl)}
                fullWidth
            >
                {isLoading && loadingState === 'menu' ? <CircularProgress size={24} /> : getLabelForValue(currentValue, items)}
            </QeeColorButtonSecondary>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleCloseMenu(setAnchorEl)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {items.map((item, index) => (
                    <MenuItem key={index} onClick={(e) => { e.stopPropagation(); handleActionSelect(item.value, handleChange, setAnchorEl); }}>
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </FormControl>
    );

    return (
        <>
            {error && (
                <Typography className={classes.errorText}>
                    Fehler beim Filtern der Aktivitäten: {error}
                </Typography>
            )}
            <Box className={classes.toolbar}>
                <Box className={classes.formControls}>
                    {!initialFilter.sac_type && renderFilterButton("Typ", sacType, [{ value: '*', label: 'Alle' }, ...sacTypes], handleSacTypeChange, anchorElType, setAnchorElType)}
                    {!initialFilter.sac_date_limit && renderFilterButton("Datum", sacDateLimit, [
                        { value: '*', label: 'Alle' },
                        { value: 'today', label: 'Heute' },
                        { value: 'week', label: 'Eine Woche' },
                        { value: 'month', label: 'Ein Monat' },
                    ], handleSacDateLimitChange, anchorElDate, setAnchorElDate)}
                    {!initialFilter.sac_state && renderFilterButton("Status", sacState, [{ value: '*', label: 'Alle' }, ...sacStates], handleSacStateChange, anchorElState, setAnchorElState)}
                </Box>
            </Box>
        </>
    );
});

export default SacsFilterBar;
