// facNameDesc.js

import react, { useState, useEffect, useLayoutEffect } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import StepZilla from "react-stepzilla";

import ButtonCounter from './buttonCounter';

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	}
});


const FacAreas = ( props) => {
	
	const thisStep = props.step;	
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();				
	
	const [validationErrors, setValidationErrors] = useState( []);			
		
	console.log( 'THIS STEP', thisStep);	
	console.log( 'objEdit.step', gstate.objEdit.step);	
		
	const handleChange = (event) => {
		console.log( 'change: ' + event.target.name, event.target.value);
		
		var fac = gstate.objEdit;
		fac[event.target.name] = event.target.value;				
		setObjEdit( fac);		
	};
	
	
	useEffect(()=> {  
		console.log( 'FacAreas useEffect', thisStep);
				
		setValidationErrors( []);
		setObjErrors( {});
						
		const nextBtn = document.getElementById( 'next-button')
		if( nextBtn && thisStep == gstate.objEdit.step) {
			nextBtn.onclick=null;
			nextBtn.onclick=(e) => {
				console.log( 'here AREA');				
				if( validate( e) > 0) {
					console.log( 'CHECK AREA!');
					e.stopPropagation();
				} 
				setValidationErrors( []); 	
				console.log( 'objEdit', gstate.objEdit);
			}    
		} 

		const prevBtn = document.getElementById( 'prev-button')
		if( thisStep == gstate.objEdit.step) {
			prevBtn.onclick=null;
			prevBtn.onclick=(e) => {
				if( props.fctGotoPrevStep) {
					e.stopPropagation();
					props.jumpToStep( props.fctGotoPrevStep( thisStep));
				}
				console.log( 'objEdit: ', gstate.objEdit)				
			}    
		} 						
								
	}, [JSON.stringify( gstate.objEdit)]);		
	
	
	useEffect(()=> {  
	
		setValidationErrors( []); 			
	
	}, [gstate.objEdit.step]);		
		
		
	/**
	* validate() - 
	*/
	const validate = (e) => { 
		
		console.log( '--> validate for facAreas: ', gstate.objEdit.step);
		
		var errs = false;		
		const validationErrors = [];
		if( !gstate.objEdit.fac_living_area) {
			const msg = 'Du musst eine Wohnfläche eingeben!';
			validationErrors.push( msg);		
			//gstate.objErrors.fac_living_area = msg;
			setObjErrors({
				...gstate.objErrors,
				fac_living_area: msg
			});
		}
		if( gstate.objEdit.fac_type == '100' && !gstate.objEdit.fac_plot_area) {
			const msg = 'Du musst eine Grundstücksfläche eingeben!';
			validationErrors.push( msg);		
			//gstate.objErrors.fac_plot_area = msg;
			setObjErrors({
				...gstate.objErrors,
				fac_plot_area: msg
			});			
		}		

		if( validationErrors.length) {
			//alert( validationErrors[0]);			
			errs = true;
			setValidationErrors( validationErrors); 			
		} 		
		
		console.log( '<-- validate: ' + errs)		
		return errs;
	}
	
			
	return (
        <div class="form-group">
					<form  noValidate>					
						<Grid container 
							spacing={8} 
							direction="column" 
							justifyContent="left" 
							alignItems="left">
							<Grid item xs={12}>
								<h1>Wie groß ist die Immobilie?</h1>
							</Grid>
							<Grid item xs={12}>
								<TextField
									type="number"		
									placeholder="z.B. 120"																	
									InputProps={{ 
										startAdornment: <InputAdornment position="start">m2</InputAdornment>										
									}} 	
									variant="outlined"
									required
									fullWidth
									id="facLivingArea"
									label="Wohnfläche"
									name="fac_living_area"
									autoComplete="facLivingArea"
									value={gstate.objEdit.fac_living_area}									
									helperText={gstate.objErrors.fac_living_area}
									error={gstate.objErrors.fac_living_area ? true : false}
									onChange={handleChange}
								/>
							</Grid>				
							{gstate.objEdit.fac_type == '100' ?	
							<Grid item xs={12}>
								<TextField
									type="number"		
									placeholder="z.B. 350"																	
									InputProps={{ 
										startAdornment: <InputAdornment position="start">m2</InputAdornment>										
									}} 	
									variant="outlined"
									required
									fullWidth
									id="facPlotArea"
									label="Grundstücksfläche"
									name="fac_plot_area"
									autoComplete="facPlotArea"
									value={gstate.objEdit.fac_plot_area}									
									helperText={gstate.objErrors.fac_plot_area}
									error={gstate.objErrors.fac_plot_area ? true : false}
									onChange={handleChange}
								/>
							</Grid>	
							:''}
							<Grid item xs={12}>
								<ButtonCounter 
									objData={gstate.objEdit} setObjData={setObjEdit} 
									field={'fac_rooms_count'} defaultValue={1} min={1} max={16} step={0.5} label={'Anzahl Zimmer'}/>
							</Grid>	
							
						</Grid>
					</form>

		</div>
	);

}	
export default withStyles(styles)( FacAreas);