// windowDimensions.js

import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  
  var ret = { width, height };
  
  console.log( 'getWindowDimensions', ret);
  
  return ret;
}

export default function useWindowDimensions() {
	
  const [windowDimensions, setWindowDimensions] = useState( getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions( getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return windowDimensions;
}