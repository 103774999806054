import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { MuiAudioInput } from './MuiAudioInput';
import { MuiFileInput } from './MuiFileInput';
import { MuiMessage } from './MuiMessage';
import { MuiMultiSelectInput } from './MuiMultiSelectInput';
import { MuiSelectInput } from './MuiSelectInput';
import { MuiTextInput } from './MuiTextInput';

export function MuiChat({ chatController, height='90vh' }) {
	const chatCtl = chatController;

	const [messages, setMessages] = useState(chatCtl.getMessages());
	const [actReq, setActReq] = useState(chatCtl.getActionRequest());
	const msgRef = useRef(null);
	const [showQuickQuestions, setShowQuickQuestions] = useState(false);
	const [actReqQuickQuestions, setActReqQuickQuestions] = useState(null);
	
	const chatContainerRef = useRef(null);	

	const scroll = useCallback(() => {
		if (msgRef.current) {
		  msgRef.current.scrollTop = msgRef.current.scrollHeight;
		}
	}, [msgRef]);

	useEffect(() => {
		function handleMassagesChanged() {
		  setMessages([...chatCtl.getMessages()]);
		  scroll();
		}

		function handleActionChanged() {
		  setActReq(chatCtl.getActionRequest());
		  scroll();
		}

		chatCtl.addOnMessagesChanged(handleMassagesChanged);
		chatCtl.addOnActionChanged(handleActionChanged);
	}, [chatCtl, scroll]);
	
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);	

	const convertQAtoOptions = useCallback((qaArray) => {
		console.log('--> convertQAtoOptions', qaArray);
		var arrRet = qaArray.map((item, index) => ({
			value: index.toString(), // Convert index to string to use as value
			text: item.question, // Use the question as the option text
			answerType: item.answerType, // Retain the original answerType
			answer: item.answer, // Retain the original answer
			quickQuestions: item.quickQuestions // Retain the original quickQuestions
		}));
		console.log('<-- convertQAtoOptions', arrRet);
		return arrRet;
	}, []); 
  
  
	useEffect(() => {
		if (actReq && actReq.quickQuestions && actReq.quickQuestions.length > 0) {
			setShowQuickQuestions(true);
			// Create a new 'fake' action request for the quick questions
			const selectActReq = {
				type: 'select',
				always: true, 
				self: true,	
				addMessage: true,	
				options: convertQAtoOptions(actReq.quickQuestions),
			};
			setActReqQuickQuestions(selectActReq);
		} else {
			setShowQuickQuestions(false);
			setActReqQuickQuestions(null);
		}
	//}, [actReq, convertQAtoOptions]);
	}, [actReq]);
  
	const CustomComponent = useMemo(() => {
		if (!actReq || actReq.type !== 'custom') {
			return null;
		}	

		return actReq.Component;
	}, [actReq]);

	const unknownMsg = {
		type: 'text',
		content: 'Unknown message.',
		self: false
	};

	let prevDate = dayjs(0);
	let prevTime = dayjs(0);

	return (
		<Box
			sx={{
				height: height,
				width: '100%',
				p: 0,
				bgcolor: 'background.default',
				display: 'flex',
				flexDirection: 'column'
			}}
		>
			<Box
				sx={{
					flex: '1 1 auto',
					overflowY: 'auto',
					WebkitOverflowScrolling: 'touch',
					display: 'flex',
					flexDirection: 'column',
					'& > *': {
						maxWidth: '100%'
					},
					p: 1,
					mb: 2
				}}
				ref={msgRef}
			>
			
			<div ref={chatContainerRef} style={{ overflowY: 'auto', maxHeight: '100vh' }}>
			{messages.map((msg, index) => {
				let showDate = false;
				let showTime = !!chatCtl.getOption().showDateTime;

				if (!!chatCtl.getOption().showDateTime && !msg.deletedAt) {
					const current = dayjs(msg.updatedAt ? msg.updatedAt : msg.createdAt);

					if (current.format('YYYYMMDD') !== prevDate.format('YYYYMMDD')) {
						showDate = true;
					}

					prevDate = current;

					if (current.diff(prevTime) < 60000) {
						showTime = false;
					} else {
						prevTime = current;
					}
				}

				return (
					<React.Fragment key={index}>
						{msg.type === 'text' || msg.type === 'jsx' ? (
							<MuiMessage
								chatController={chatCtl}
								id={`cu-msg-${index + 1}`}
								message={msg}
								showDate={showDate}
								showTime={showTime}
							/>
						) : msg.type === 'file' ? (
							<MuiMessage
								chatController={chatCtl}							
								id={`cu-msg-${index + 1}`}
								message={{
									...msg,
									content: ''
								}}
								showDate={showDate}
								showTime={showTime}
							/>
						) : (
							<MuiMessage
								chatController={chatCtl}							
								id={`cu-msg-${index + 1}`}
								message={unknownMsg}
								showDate={showDate}
								showTime={showTime}
							/>
						)}					
						{index === messages.length - 1 && (
							<Box key="blind-message" sx={{ height: '20px' }}></Box>
						)}
					</React.Fragment>
				);
			})}
			</div>
			</Box>

			<Box
				sx={{
					position: 'sticky',
					bottom: 0,
					bgcolor: 'background.default',
					//p: 1,
					width: '100%',
					zIndex: 10,
				}}
			>
				{actReq && actReq.type === 'text' && <MuiTextInput chatController={chatCtl} actionRequest={actReq} />}				
				{showQuickQuestions && actReqQuickQuestions && (
					<MuiSelectInput chatController={chatCtl} actionRequest={actReqQuickQuestions} isQuickQuestion={true} />
				)}							
				{actReq && actReq.type === 'select' && <MuiSelectInput chatController={chatCtl} actionRequest={actReq} />}
				{actReq && actReq.type === 'multi-select' && <MuiMultiSelectInput chatController={chatCtl} actionRequest={actReq} />}
				{actReq && actReq.type === 'file' && <MuiFileInput chatController={chatCtl} actionRequest={actReq} />}
				{actReq && actReq.type === 'audio' && <MuiAudioInput chatController={chatCtl} actionRequest={actReq} />}
				{actReq && actReq.type === 'custom' && <CustomComponent chatController={chatCtl} actionRequest={actReq} />}
			</Box>
		</Box>
	);
}
