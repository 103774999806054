import React, { useState, useRef, useEffect } from 'react';
import {
	Button,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Box,
	Checkbox,
	FormControlLabel,
	Select,
	Grid,
	Typography,
	CircularProgress,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Country, State, City } from 'country-state-city';
import QeeInternationalPhone from './qeeInternationalPhone';
import { QeeColorButtonPrimary, QeeColorButtonSecondary } from './qeeColorButton';
import style from './stripe/components/AddPayMethod.module.scss';

const useStyles = makeStyles((theme) => ({
  confirmationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  formContainer: {
    marginTop: theme.spacing(2),
  },
  circularProgress: {
    margin: theme.spacing(2),
  },
}));

const QeeCustomerInfo = ({ usr, onOrderNow }) => {
	
	const emailInputRef = useRef(null);
	useEffect(() => {
		emailInputRef.current.focus();
	}, []);
	
	const classes = useStyles();
		
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		country: 'DE',
		email: usr.cch_email_o,
		newsAndOffers: false,
		firstName: '',
		lastName: '',
		company: '',
		address: '',
		postalCode: '',
		city: '',
		phone: '+49',
		saveInfoForNextTime: false,
	});

	const [errors, setErrors] = useState({
		email: '',
		lastName: '',
		address: '',
		postalCode: '',
		city: '',
		phone: '',
	});

	const handleSelectChange = (event) => {
		setFormData((prevData) => ({
			...prevData,
			country: event.target.value,
		}));
	};
	
	const handlePhoneChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			phone: value,
		}));
	};
	
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleCheckboxChange = (e) => {
		const { name, checked } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: checked,
		}));
	};

	const handleSubmit = (e) => {
		
		e.preventDefault();
				
		// Check for errors before submission
		const newErrors = {};
		if (!formData.email) {
			newErrors.email = 'Bitte gebe deine E-Mail-Adresse ein.';
		}
		if (!formData.lastName) {
			newErrors.lastName = 'Bitte gebe deinen Nachnamen ein.';
		}
		if (!formData.address) {
			newErrors.address = 'Bitte geben deine Adresse ein.';
		}
		if (!formData.postalCode) {
			newErrors.postalCode = 'Bitte gebe deine Postleitzahl ein.';
		}
		if (!formData.city) {
			newErrors.city = 'Bitte geben deinen Wohnort ein.';
		}
		if (formData.phone.length < 5) {
			newErrors.phone = 'Bitte gebe deine Telefonnummer ein.';
		}

		if (Object.keys(newErrors).length > 0) {
			setErrors( newErrors);
		} else {
			// Submit form data or perform any other action
			console.log('Form submitted:', formData);
		
			setIsLoading( true);
		
			onOrderNow( formData);
		}
	};

	// Data for react-select
	const countryOptions = Country.getAllCountries().map((country) => ({
		value: country.isoCode,
		label: country.name,
	}));

	return (
		<div style={{marginTop:20}}>
		{isLoading ? (
			<div className={classes.confirmationContainer}>
				<CircularProgress className={classes.circularProgress} />
				<Typography variant="body2" component="div" style={{marginTop:20}}>Deine Bestellung wird versendet...</Typography>
			</div>			
		) : (
		<form>
			<Box p={2} mt={2}>
				<Typography variant='body3'>Durch das Abschicken dieses Formulars erklärst du dich damit einverstanden, dass unsere Shop-Mitarbeiter dich bezüglich deiner Bestellung kontaktieren dürfen. Deine Daten werden vertraulich behandelt und nicht an Dritte weitergegeben. Die Bestellung bezieht sich auf die Immobiliendaten aus unserer App.</Typography>
			</Box>
			<Box p={2}>
				<TextField
					inputRef={emailInputRef}
					label="Deine E-Mail"
					name="email"
					value={formData.email}
					onChange={handleChange}
					fullWidth
					variant="outlined"
					error={!!errors.email}
					helperText={errors.email}
					required
				/>
			</Box>
			<Box p={2}>
				<FormControl fullWidth variant="outlined">
					<InputLabel htmlFor="country-select">Rechnungsadresse Land</InputLabel>
					<Select
						id="country-select"
						value={formData.country}
						onChange={handleSelectChange}
						label="Rechnungsadresse Land"
					>
						{countryOptions.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box p={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<TextField
							label="Vorname (optional)"
							name="firstName"
							value={formData.firstName}
							onChange={handleChange}
							fullWidth
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Nachname"
							name="lastName"
							value={formData.lastName}
							onChange={handleChange}
							fullWidth
							variant="outlined"
							error={!!errors.lastName}
							helperText={errors.lastName}
							required
						/>
					</Grid>
				</Grid>
			</Box>
			<Box p={2}>
				<TextField
					label="Firma (optional)"
					name="company"
					value={formData.company}
					onChange={handleChange}
					fullWidth
					variant="outlined"
				/>
			</Box>
			<Box p={2}>
				<TextField
					label="Adresse"
					name="address"
					value={formData.address}
					onChange={handleChange}
					fullWidth
					variant="outlined"
					error={!!errors.address}
					helperText={errors.address}
					required
				/>
			</Box>
			<Box p={2}>
				<TextField
					label="Postleitzahl"
					name="postalCode"
					value={formData.postalCode}
					onChange={handleChange}
					fullWidth
					variant="outlined"
					error={!!errors.postalCode}
					helperText={errors.postalCode}
					required
				/>
			</Box>
			<Box p={2}>
				<TextField
					label="Wohnort"
					name="city"
					value={formData.city}
					onChange={handleChange}
					fullWidth
					variant="outlined"
					error={!!errors.city}
					helperText={errors.city}
					required
				/>
			</Box>
			<Box p={2}>
				<FormControl fullWidth>
					<Typography variant="body3" style={{marginBottom:10}}>Deine Telefonnummer für Rückfragen:</Typography>			
					<QeeInternationalPhone
						placeholder="Telefon"
						defaultCountry="de"
						value={formData.phone}
						onChange={handlePhoneChange}
						error={!!errors.phone}
						required
					/>							
				</FormControl>
			</Box>
			<Box p={2}>
				<FormControlLabel
					control={
						<Checkbox
							checked={formData.saveInfoForNextTime}
							onChange={handleCheckboxChange}
							name="saveInfoForNextTime"
							color="primary"
						/>
					}
					label={
						<Typography variant="body3">
							Diese Informationen für das nächste Mal speichern
						</Typography>
					}
				/>
			</Box>
			<Box p={2}>
				<FormControlLabel
					control={
						<Checkbox
							checked={formData.newsAndOffers}
							onChange={handleCheckboxChange}
							name="newsAndOffers"
							color="primary"
						/>
					}
					label={
						<Typography variant="body3">
							E-Mail mit interessante Neuigkeiten und Angeboten rund um Immobilien senden
						</Typography>
					}					
				/>
			</Box>			
			<Box p={2}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSubmit}
					style={{ position: 'absolute', bottom: '20px', right: '20px' }}
				>
					Bestellung absenden
				</Button>
			</Box>
		</form>
		)}
		</div>
	);
};

export default QeeCustomerInfo;
