import React from "react";
import react, { useState, useEffect, useLayoutEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-free-solid';
import config from '../util/config';

import { useAuth } from "../context/provider";

const FacsSelectLocality = ( props) => {
			
	const { gstate, setFacsSearchFilter } = useAuth();	
	
	const [validationErrors, setValidationErrors] = useState( []);								
	const [gvalue, setGvalue] 			= useState( gstate.facsSearchFilter.addr_gvalue_o ? gstate.facsSearchFilter.addr_gvalue_o : '');	
	const [zip, setZip]       			= useState( gstate.facsSearchFilter.addr_street_zip_o ? gstate.facsSearchFilter.addr_street_zip_o : '');
	const [locality, setLocality] 		= useState( gstate.facsSearchFilter.addr_locality_o ? gstate.facsSearchFilter.addr_locality_o : '');	
	const [lat, setLat] 				= useState( gstate.facsSearchFilter.addr_geo_lat_o ? gstate.facsSearchFilter.addr_geo_lat_o : 0);	
	const [lon, setLon] 				= useState( gstate.facsSearchFilter.addr_geo_lon_o ? gstate.facsSearchFilter.addr_geo_lon_o : 0);	
	const [externId, setExternId] 		= useState( gstate.facsSearchFilter.fac_extern_id ? gstate.facsSearchFilter.fac_extern_id : '');		
			
	const [gvalueText, setGvalueText] = React.useState( gstate.facsSearchFilter.addr_gvalue_text_o);				

	
	const makeText = () => {
		var txt = 'alle';
		
		if( gvalue)
			txt = gvalue.label;
		else {
			if( externId && externId.length)
				txt = externId;
		}
		
		console.log( 'localityText', txt);
		return txt; 
	}	
	
	
	useEffect( () => {		
		console.log( 'facsSelectLocality useEffect');
		
		setGvalueText( makeText());
		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			addr_gvalue_text_o: makeText()
		});	
	}, [ JSON.stringify( gvalue), externId]);			
	
	
	
	const handleChange = event => {				
		setFacsSearchFilter( {
			...gstate.facsSearchFilter,
			addr_gvalue_text_o: makeText()			
		});
	};	
	
	
	/**
	*  _setAddress() - 
	*/
	const _setAddress = ( gval) => { 	
		console.log( '_setAddress', gval);
		console.log( 'local states', [gvalue, zip, locality, lat, lon, externId]);
		setGvalue( gval);
		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			addr_gvalue_o: gval,
			fac_extern_id: ''
		})																
		setZip( '');
		setLocality( '');
		setLat( 0);
		setLon( 0);
		setExternId( '');
		
		var ret = { 
			addr_gvalue_o: gval,
			addr_gvalue_text_o: gval.label,
			addr_street_zip_o:'', 
			addr_locality_o:'', 
			addr_geo_lat_o:0, 
			addr_geo_lon_o:0
		};
		console.log( 'geocoding place_id: ' + gval.value.place_id);
		geocodeByPlaceId( gval.value.place_id)
			.then( results => {
				results = results[0];
				console.log( 'results', results);
				for( var i = 0; i < results.address_components.length; i++) {
					for( var j = 0; j < results.address_components[i].types.length; j++) {
						if( results.address_components[i].types[j] == "postal_code") {
							var z = results.address_components[i].long_name;
							console.log( '>>> zip', z);							
							setZip( z);
							ret = { ...ret, addr_street_zip_o: z }							
						}
						if( results.address_components[i].types[j] == "locality") {							
							var l = results.address_components[i].long_name;
							console.log( '>>> locality', l);
							setLocality( l);
							ret = { ...ret, addr_locality_o: l }														
						}		
						/*	
						if( results.address_components[i].types[j] == "route") {
							setStreet( results.address_components[i].long_name);
							_saveAddress();												
						}												
						if( results.address_components[i].types[j] == "street_number") {
							setHouseNumber( results.address_components[i].long_name);
							_saveAddress();												
						}
						*/
						setLat( results.geometry.location.lat());						
						ret = { ...ret, addr_geo_lat_o: results.geometry.location.lat()}													
						setLon( results.geometry.location.lng());			
						ret = { ...ret, addr_geo_lon_o: results.geometry.location.lng()}																			
					}				
				}
				setFacsSearchFilter({
					...gstate.facsSearchFilter,
					...ret
				})											

			})
			.catch( error => console.error( 'error', error));		
		

	}
	
	
	/**
	* handleChangeExternId() - if the extern id is given
	*/
	const handleChangeExternId = event => {		
		const { value } = event.target;
		if ( value.length > 8) {
			return;
		}
				
		console.log( 'change externId: ' + event.target.name, externId);
		setExternId( event.target.value);
		
		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			fac_extern_id: externId,
		});
	};				
	
	
	/**
	*  _closeResults() - 
	*/
	const _closeResults = () => { 
		setGvalue( null);
				
		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			addr_gvalue_o: null,		
			addr_gvalue_text_o: '',		
		});				
	}
	
	let styles = {
		label: {
			fontSize: 18,
			fontWeight: 'bold',
			padding: 10
		},		
		icon: {
			fontSize: 48,
			padding: 10,
			color: '#444',
			position: 'relative',
			top: -20,
			right: -10,			
		},
		cancel: {
			float: 'right'
		}
	}		
		
	
	return (
		<Grid container 
			spacing={4} 
			direction="column" 
			justifyContent="center" 
			alignItems="center"
			style={{height:320, padding:10}}>
			<Grid item xs={12}>	
				<form>
                <div className="form-group">
					<label htmlFor="gvalue" style={{marginBottom: 30}}>Suche nach <b>Ort</b>, nach <b>PLZ</b> oder nach <b>Ortsviertel</b>, wie z.B. Südweststadt Karlsruhe</label>
					<GooglePlacesAutocomplete
						apiKey={config.google.apiKey}  
						selectProps={{
							defaultInputValue: gvalue ? gvalue.label : '',
							//gvalue,
							onChange: _setAddress,
							noOptionsMessage: () => 'Deutschland',
							placeholder: () => 'Typen Sie ein...',
							loadingMessage: () => 'Adressen laden...'
						}}
						autocompletionRequest={{
							//bounds: [
							//	{ lat: 50, lng: 50 },
							//	{ lat: 100, lng: 100 }
							//],
							componentRestrictions: {
								country: ['de'],
							},
							types: ['(regions)']
						}}
					/>															
					{
					!gvalue ? (
							<div>
							{
								<div style={{marginTop: 60}}>
									Alternativ kannst du direkt eine Immoscout24 ID eingeben:
									<TextField
										placeholder="z.B. 62234117"																	
										variant="outlined"
										fullWidth
										id="externId"
										label=""
										name="externId"
										value={externId}									
										onChange={handleChangeExternId}
									/>									
								</div>
							}						
							</div>
						) : (
							<div class="form-result">	
								<div style={styles.cancel}><FontAwesomeIcon icon={faTimes} style={styles.icon} onClick={() => _closeResults()} /></div>
								{zip ? <div><span class="label">Postleitzahl:</span> <span class="result">{zip}</span></div> : <div></div>}						 
								<div><span class="label">Stadt: </span><span class="result">{locality}</span></div>		
							</div>
					)
					}				
                </div>
				</form>
			</Grid>
		</Grid>	
    );
	
}
export default FacsSelectLocality;