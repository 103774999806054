import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { fetchCmts } from '../api/service'; 

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        //overflowY: 'auto',
        //height: 'calc(100vh - 64px)', // Adjust based on your layout
    },
    myComment: {
        textAlign: 'right',
        '& .MuiListItem-root': {
            justifyContent: 'flex-end',
        },
        '& .MuiListItemText-root': {
            textAlign: 'right',
        },
    },
    otherComment: {
        textAlign: 'left',
        '& .MuiListItem-root': {
            justifyContent: 'flex-start',
        },
        '& .MuiListItemText-root': {
            textAlign: 'left',
        },
    },
    avatar: {
        margin: theme.spacing(1),
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
    },
    listItemText: {
        maxWidth: '75%',
        wordBreak: 'break-word',
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.grey[200],
    },
    myListItemText: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
    },
    timestamp: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
    },
}));

const Cmts = ({ parentId }) => {
    const classes = useStyles();
    const [cmts, setCmts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const usr = JSON.parse(sessionStorage.getItem('usr'));

    useEffect(() => {
        const _fetchCmts = async () => {
            try {
                setLoading(true);
                const filter = parentId ? { parentId } : { cmt_sender_id: usr.usrId };
				console.log( 'fetchCmts', filter);
                const response = await fetchCmts(filter);
                if (response.err) {
                    throw new Error(response.err);
                }
                setCmts(response.entries.results);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        _fetchCmts();
    }, [parentId, usr.usrId]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">Error: {error}</Typography>;
    }

    return (
        <Box className={classes.root}>
			<Typography variant="h1" className={classes.title}>Nachrichten</Typography>
            <List>
                {cmts.map((cmt) => (
                    <ListItem key={cmt.id} className={cmt.cmt_sender_id === usr.usrId ? classes.myComment : classes.otherComment}>
                        <ListItemAvatar>
                            <Avatar src={cmt.cmt_sender_avatar} alt={cmt.cmt_sender_name} className={classes.avatar} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <span className={`${classes.listItemText} ${cmt.cmt_sender_id === usr.usrId ? classes.myListItemText : ''}`}>
                                    {cmt.cmt_body}
                                </span>
                            }
                            secondary={
                                <span className={classes.timestamp}>
                                    {new Date(cmt.qee_creation_date).toLocaleString()}
                                </span>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default Cmts;
