// appbar.js

import * as React from 'react';
import react, { useState, useEffect, useLayoutEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import ContentCopy from '@mui/icons-material/ContentCopy';
import CssBaseline from '@mui/material/CssBaseline';
import Badge from '@mui/material/Badge';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles }  from '@material-ui/core/styles';

import logo from '../assets/app-logo.png';

import { useNavigate } from "react-router-dom";

import useWindowDimensions from '../util/windowDimensions';
import { useAuth } from "../context/provider";
import { getApplicationScopeNet } from "../api/service";

import { getDocs, getDocsNet, postStorageDocs } from "../api/service";


/**
*	ResponsiveAppBar - props: pages, settings
*/
const ResponsiveAppBar = (props) => {
	
	const { gstate, setIsXS, setIsSM, setIsMD, setIsLG, setIsXL } = useAuth();									
	
	const [anchorElNav, setAnchorElNav] = React.useState( null); //React.useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = React.useState( null); //React.useState<null | HTMLElement>(null);
	const [isMobile, setIsMobile] = React.useState( false);
	const [drawerWidth, setDrawerWidth] = React.useState(props.drawerWidth || 240);
	const [drawerTop, setDrawerTop] = React.useState(props.drawerTop || 70);
	const [width, setWidth] = useState( window.innerWidth);
	const [userInitials, setUserInitials] = useState( 'DE');			
	const [userPictureUrl, setUserPictureUrl] = useState( '');	
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);	
	//const isMobile = width <= 768;			
	
	function handleWindowSizeChange() {
		setWidth( window.innerWidth);
	}
	
	useEffect(() => {
		setIsXS( width<600);		
		setIsSM( width>=600 && width<900);		
		setIsMD( width>=900 && width<1200);		
		setIsLG( width>=1200 && width<1536);		
		setIsXL( width>=1536);		
		setIsMobile( width<=1200);
		setDrawerWidth(width <= 1200 ? 0 : (props.drawerWidth !== undefined ? props.drawerWidth : 240));
        setDrawerTop(width <= 1200 ? 0 : (props.drawerTop !== undefined ? props.drawerTop : 70));
		console.log( 'width', width);				
		console.log( 'isMobile', isMobile);		
		console.log( 'setDrawerWidth', drawerWidth);
		console.log( 'setDrawerTop', drawerTop);		
		
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
				
	}, [width]);

	
	const useStyles = makeStyles( theme => ({
		appBar : {
			backgroundColor: theme.palette.primary.main + ' !important',
			zIndex: (theme.zIndex.drawer + 1) + ' !important',
			marginBottom: 0
		},
		drawer: {
			width: drawerWidth,
			top: drawerTop,
			height: '100vh'
		},
		drawerPaper: {
			width: drawerWidth,
			position: 'relative',
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3)
		}		
	}));		
	//const { classes } = props;		
	const classes = useStyles( props);		
	
	const navigate = useNavigate();	
	
	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};
	
	const handleClickUserMenu = ( pg) => {
		console.log( 'Goto page');
		setAnchorElUser(null);
		navigate( pg);
	};
	
		
	useEffect(()=> { 

		const fetchApplicationScopeNet = async () => {
			const applicationScope = await getApplicationScopeNet();
			sessionStorage.setItem( 'scope', JSON.stringify( applicationScope));
		};	
	
		var usr = sessionStorage.getItem( 'usr');
		if( usr) {
			console.log( 'sessionStorage usr', usr);
			var oUsr = JSON.parse( usr);
			setUserInitials( (oUsr.per_firstname ? oUsr.per_firstname.charAt(0).toUpperCase() : '') + 
				'' + 
				(oUsr.per_lastname ? oUsr.per_lastname.charAt(0).toUpperCase() : ''));
				
			// set the unread badge		
			if (oUsr && oUsr.usr_unread_chat_msgs_count > 0) {
				setUnreadMessagesCount( oUsr.usr_unread_chat_msgs_count);
			}						
		}
				
		fetchApplicationScopeNet();
		

		 	
	}, [gstate.usr.per_lastname, gstate.usr.per_firstname]);			
  
  
 	useEffect(()=> { 

		const getDcs = async () => {			
						
			const dcs = await getDocsNet( 
				'profile', 
				['sdoc_type','sdoc_subtype'], 
				[200,100]);	
			if( !dcs.err) {				
				if( dcs.length) {
					setUserPictureUrl( dcs[0].signedUrl)					
				} else {
					setUserPictureUrl( '')					
				} 			
			} else {
				setUserPictureUrl( '')					
			}		
		}
		getDcs();
					 	
	}, [gstate.usr.picture_url]);			 
  
    
	return (
		<>	
		<AppBar position="sticky" className={classes.appBar}>
			<Container maxWidth="xxl">
			<Toolbar disableGutters>
				<Typography
					variant="h6"
					noWrap
					component="div"
					sx={{ mr: 2, display: { xs: 'none', lg: 'flex' } }}
				>  
					<img style={{height:40, cursor:'pointer', position:'relative', top:0, padding:5, borderRadius:5, backgroundColor:'#fff'}} src={ logo } alt="logo" onClick={() => navigate( '/home')} />
				</Typography>

			  <Box sx={{ flexGrow: 1, display: { md: 'flex', lg: 'none' } }}>
				<IconButton
					size="large"
					aria-label="account of current user"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					onClick={handleOpenNavMenu}
					color="inherit"
				>
					<MenuIcon />
				</IconButton>
				<Menu
					id="menu-appbar"
					anchorEl={anchorElNav}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					open={Boolean(anchorElNav)}
					onClose={handleCloseNavMenu}
					onClick={handleCloseNavMenu}
					sx={{
						display: { xs: 'block', lg: 'none' },
					}}
				>
					 {props.drawer ? props.drawer() : ''}
				</Menu>
			  </Box>
			  <Typography
					variant="h6"
					noWrap
					component="div"
					sx={{ flexGrow: 1, display: { md: 'flex', lg: 'none' } }}
			  >
					<img style={{height:40, cursor:'pointer', position:'relative', top:0, padding:5, borderRadius:5, backgroundColor:'#fff'}} src={ logo } alt="logo" onClick={() => navigate( '/home')} />			  
			  </Typography>
			  <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
					{props.pages.map((page) => (
						<Button
							key={page.nm}
							onClick={page.fct}
							sx={{ my: 2, color: 'white', display: 'block' }}
						>
							{page.nm}
						</Button>
					))}
			  </Box>
			  <Box sx={{ flexGrow: 0 }}>
					<Tooltip title="Meine Einstellungen">
						<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
							<Badge badgeContent={unreadMessagesCount} color="newFeature">
								<Avatar alt="Mein Bild" src={userPictureUrl.length ? userPictureUrl : ''}>
									{userPictureUrl.length>0 ? '' : userInitials}
								</Avatar>	
							</Badge>
						</IconButton>
					</Tooltip>
					<Menu
						sx={{ mt: '45px' }}
						id="menu-appbar"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
					>
					{props.settings.map((setting) => (
						<MenuItem key={setting.nm} onClick={()=>{handleCloseUserMenu(); setting.fct()}}>
							<ListItemIcon>{' '}{setting.ico}{' '}</ListItemIcon>
							<Typography textAlign="center">{setting.nm}</Typography>
						</MenuItem>
					))}
				</Menu>
			  </Box>
			</Toolbar>
			</Container>	  	  
		</AppBar>
		
		<div style={{position:'relative',
			left:drawerWidth, 
			width:width-drawerWidth-0}}
		>
			{props.switchSubpage()}
		</div>
		
		<div className={classes.root}>
			<CssBaseline />
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, top: drawerTop },
				}}
				open
				className={classes.drawer}
				classes={{
					paper: classes.drawerPaper
				}}			
			>
			  {props.drawer ? props.drawer() : ''}
			</Drawer>
		</div>			  

		</>	
	);
};
//export default withStyles(styles)(ResponsiveAppBar);
export default ResponsiveAppBar;
