import React, { createContext, useMemo, useRef, useContext } from 'react';

import SacDialog from './sacDialog';
import { useAuth } from "../context/provider"; 

const SacDialogContext = createContext();

const QeeSacDialogProvider = (props) => {
	const { gstate } = useAuth();					
	const refSacDialog = useRef(null);			
	
	const setOpenSacDialog = (sac) => {
		refSacDialog.current.handleSacOpen(sac);
	};

	const value = useMemo(() => {   
		return { setOpenSacDialog };	  	  
	}, []);	
	
	return (
		<SacDialogContext.Provider value={value}>
			<SacDialog ref={refSacDialog} />	
			{props.children}	
		</SacDialogContext.Provider>
	);
}

const useSacDialog = () => useContext(SacDialogContext);
export { SacDialogContext, useSacDialog }
export default QeeSacDialogProvider;
