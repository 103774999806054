import react, { useState, useEffect, useLayoutEffect } from 'react';
import { faHome, faBuilding, faMap, faIndustry, faMapPin, faToolbox, faUniversity } from '@fortawesome/fontawesome-free-solid';
import { faLocationPin, faMapMarker, faHouse, faHouseChimney, faHouseFlag } from '@fortawesome/free-solid-svg-icons'

import { useAuth } from "../context/provider";

import Box from './Box';

const FacPrjType = ( props) => { 
	
	const thisStep = props.step;	
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();				
	
	const [validationErrors, setValidationErrors] = useState( []);	

	console.log( 'THIS STEP', thisStep);	
	console.log( 'objEdit.step', gstate.objEdit.step);		
		
		
	useEffect(()=> {  
		console.log( 'useEffect', thisStep);
		
		const nextBtn = document.getElementById( 'next-button')
		if( thisStep == gstate.objEdit.step) {
			nextBtn.onclick=null;
			nextBtn.onclick=(e) => {
				console.log( 'Set validation in FacPrjType');								
				if( validate( e) > 0) {
					e.stopPropagation();
				} 
				console.log( 'objEdit: ', gstate.objEdit)				
			}    
		} 	

		const prevBtn = document.getElementById( 'prev-button')
		if( thisStep == gstate.objEdit.step) {
			prevBtn.onclick=null;
			prevBtn.onclick=(e) => {
				if( props.fctGotoPrevStep) {
					e.stopPropagation();
					props.jumpToStep( props.fctGotoPrevStep( thisStep));
				}
				console.log( 'objEdit: ', gstate.objEdit)				
			}    
		} 				
								
	}, [JSON.stringify( gstate.objEdit)]);		
		
		
	/**
	* validate() - 
	*/
	const validate = (e) => { 
		
		console.log( '--> validate for FacPrjType in step: ' + gstate.objEdit.step)
					
		var errs = false;		
		const validationErrors= [null];
		if( gstate.objEdit.fac_type == '') 
			validationErrors[0] = 'Du musst ein Projekt auswählen!';		

		if( validationErrors[0]) {
			//alert( validationErrors[0]);			
			errs = true;
			setValidationErrors( validationErrors); 			
		} 		
		
		console.log( '<-- validate: ' + errs)		
		return errs;
	}		
		
	/*
	<li><Box {...props} type="box" step="0" name="plot" title='Grundstück' icon={faMap} /></li>	
	<li><Box {...props} type="box" step="0" name="office" title='Gewerbe' icon={faIndustry} /></li>			
	*/
    return (	
		<div className="form-group">
            <h1 className="title2" style={{ marginTop: gstate.isXS ? 10 : 60 }}>Was planst du mit der Immobilie?</h1>
            <div className="navi-horiz" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <ul style={{ padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <li style={{ margin: '10px 0', width: '100%' }}>
                        <Box {...props} gstate={gstate} width={500} maxWidth={300} type="box" step="6" name="fac_prj_type" value="" size="2x" title='Nur Speichern' icon={faLocationPin} />
                    </li>
                    <li style={{ margin: '10px 0', width: '100%' }}>
                        <Box {...props} gstate={gstate} width={500} maxWidth={300} type="box" step="6" name="fac_prj_type" value="newbuild" size="2x" title='Neubauprojekt' icon={faHouseFlag} />
                    </li>
                    <li style={{ margin: '10px 0', width: '100%' }}>
                        <Box {...props} gstate={gstate} width={500} maxWidth={300} type="box" step="6" name="fac_prj_type" value="renovation" size="2x" title='Sanierungsprojekt' icon={faToolbox} />
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default FacPrjType;