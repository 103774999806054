// buy.js

import React, { Component } from 'react';
import react, { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';

import Account from '../components/account';
import Docs from '../components/docs';
import Lea from '../components/lea';
import FacsSearch from '../components/facsSearch';
import FacsFav from '../components/facsFav';
import ResponsiveAppBar from '../components/appbar';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchIcon from '@material-ui/icons/Search';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NotesIcon from '@material-ui/icons/Notes';
import Avatar from '@material-ui/core/avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AccountCircle, GridOnOutlined, Logout } from "@material-ui/icons";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import { useNavigate } from "react-router-dom";

import { authMiddleWare } from '../util/auth'


const styles = (theme) => ({
	root: {
		display: 'flex'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	toolbar: theme.mixins.toolbar
});


const Buy = ( props) => {
	const [state, setState] = useState({
		render: 'Account',
		per_firstname: '',
		per_lastname: '',
		profilePicture: '',
		uiLoading: true,
		imageLoading: false		
	});	
	const [windowDimension, detectHW] = useState({
		winWidth: window.innerWidth,
		winHeight: window.innerHeight,
	})	
	const navigate = useNavigate();
	const { classes } = props;
	

	const detectSize = () => {
		detectHW({
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		})
	}	

		
	// home actions
	const loadAccount = (event) => {
		navigate( '/home?account')
	};	
	
	const logoutHandler = (event) => {
		localStorage.removeItem( 'AuthToken');
		navigate( '/home');
	};	
	

	// page actions
	const loadBuyPage = (event) => {
		console.log( 'loadBuyPage');
		navigate( '/buy');		
	};		
	const loadOwnPage = (event) => {
		console.log( 'loadOwnPage');
		navigate( '/own');				
	};		
	const loadSellPage = (event) => {
		console.log( 'loadSellPage');
		navigate( '/sell');				
	};		

		
	// subpage actions
	const switchSubpage = () => {
		console.log( 'switchSubpage render', state.render)		
		switch( state.render) {
			case 'FacsSearch':  return <FacsSearch />;			
			case 'FacsFav':    return <FacsFav />;			
			default: 
				return <FacsSearch />; 
		}
	}	
	const loadFacsSearch = (event) => {
		setState({ render: 'FacsSearch' });
	};		
	const loadFacsFav = (event) => {
		setState({ render: 'FacsFav' });
	};		
	
		
	
	/*
	useEffect(() => {
		window.addEventListener( 'resize', detectSize)
		//alert( windowDimension.winHeight);
		return () => {
			window.removeEventListener( 'resize', detectSize)
		}
	}, [windowDimension])	
	*/
	
	
    useEffect( () => {			
		//authMiddleWare( props.history);
		const authToken = localStorage.getItem( 'AuthToken');
		//const authToken = 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjY5N2Q3ZmI1ZGNkZThjZDA0OGQzYzkxNThiNjIwYjY5MTA1MjJiNGQiLCJ0eXAiOiJKV1QifQ';
		if( authToken == null) navigate( '/home');		
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		console.log( 'GET /api/usr');
		axios
			.get( '/api/usr')
			.then((response) => {
				console.log( 'response', response);							
				setState({
					admin: 			response.data.userCredentials.admin,					
					per_firstname: 	response.data.userCredentials.per_firstname,
					per_lastname: 	response.data.userCredentials.per_lastname,
					cch_email_o: 	response.data.userCredentials.cch_email_o,
					cch_phone_o: 	response.data.userCredentials.cch_phone_o,
					addr_country_o: response.data.userCredentials.addr_country_o,
					username: 		response.data.userCredentials.username,
					id: 			response.data.userCredentials.id,	
					usrId: 			response.data.userCredentials.userId,		
					per_org_id: 	response.data.userCredentials.per_org_id,					
					profilePicture: response.data.userCredentials.imageUrl,
					uiLoading: 		false					
				});
			})
			.catch(( error) => {
				console.log( error);
				if(error.response.status === 403) {
					//this.props.history.push('/login')
					//const navigate = useNavigate();
					navigate( '/home');
				}
				setState({ errorMsg: 'Error in retrieving the data' });
			});
    }, []);	
	
	
	const drawer = () => {		
		return( 		
			<>
			<div className={classes.toolbar} />
			
			<List>
				<ListItem button key="FacsSearch" onClick={loadFacsSearch}>
					<ListItemIcon>
						{' '}
						<SearchIcon />{' '}
					</ListItemIcon>
					<ListItemText primary="Immobiliensuche" />
				</ListItem>						
			
				<ListItem button key="FacsFav" onClick={loadFacsFav}>
					<ListItemIcon>
						{' '}
						<FavoriteBorderIcon />{' '}
					</ListItemIcon>
					<ListItemText primary="Favoriten" />
				</ListItem>												
			</List>
			</>
		)
	}
	
	
	return (	
		state.uiLoading ? (
			<div className={classes.root}>
				{state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
			</div>		
		) : (
			<div>
				<ResponsiveAppBar 
					className={classes.appBar} 
					drawer={drawer}
					switchSubpage={switchSubpage}										
					pages={[/*
						{ nm:'Kaufen',    fct:loadBuyPage},
						{ nm:'Wohnen',    fct:loadOwnPage},
						{ nm:'Verkaufen', fct:loadSellPage}						
						*/
					]}
					settings={[
						{ nm:'Account',   fct:loadAccount,    ico:<AccountBoxIcon />},
						{ nm:'Logout', 	  fct:logoutHandler,  ico:<ExitToAppIcon />}
					]}					
				/>
			</div>		
		)	
	);
	
}
export default withStyles(styles)(Buy);

