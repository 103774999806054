import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import ResponsiveAppBar from '../components/appbar';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@mui/material/Divider';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CircularProgress from '@mui/material/CircularProgress';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChatIcon from '@mui/icons-material/Chat';
import Typography from '@mui/material/Typography';
import SacGeneral from '../components/sacGeneral';
import SacChat from '../components/sacChat';
import Cmts from '../components/cmts';
import SacDocs from '../components/sacDocs';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/provider";
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import QeeNavigator from '../context/navigator';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    toolbar: theme.mixins.toolbar,
    listItem: {
        color: '#788',
        fontWeight: 600,
        fontSize: 16
    },
}));

const Sac = () => {
	const classes = useStyles(); // Call useStyles inside the functional component
    const theme = useTheme();	
	
    const { loadHome, loadFacs, loadAccount, loadSacs, loadSacsSender, loadSacsReceiver } = QeeNavigator();
    const { setOpenConfluenceDialog } = useConfluence();
    const { gstate, setCurrSubpage, setObjEdit, setObjErrors, setIsLoading, setOpenQeeDialog } = useAuth();
    const [username, setUsername] = useState(null);
    const [state, setState] = useState({
        uiLoading: true,
    });
    const navigate = useNavigate();
	


    const loadSac = async () => {
        console.log('--> loadSac');
        const authToken = localStorage.getItem('AuthToken');
        if (!authToken) {
            navigate('/home');
            return;
        }

        setIsLoading(true);
        setCurrSubpage('loadSacGeneral');

        const myURL = new URL(window.location);
        const id = myURL.searchParams.get('id');
        var sbpg = myURL.hash;
        if (sbpg) sbpg = sbpg.replace('#', '');
        if (sbpg === '') sbpg = 'loadSacGeneral';

        const options = {
            url: `/api/sac/${id}`,
            method: 'get',
        };
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        try {
            const { data } = await axios(options);
            const sac = data;
            console.log('data', sac);
            sac.step = 0;
            setObjEdit(sac);
            setCurrSubpage(sbpg);
            setIsLoading(false);
            console.log('<-- loadSac');
        } catch (error) {
            setState({
                ...state,
                open: true,
                errors: error.response.data,
            });
            setObjErrors(error.response.data);
            console.log(error);
            setIsLoading(false);
            window.location.href = window.location.origin + '/home';
            console.log('<-- loadSac ERR', error);
        }
    };

    useEffect(() => {
        var usr = JSON.parse(sessionStorage.getItem('usr'));
        if (!usr) {
            console.error('No user data!');
        }
        setUsername(usr.username);
        loadSac();
    }, []);

    useEffect(() => {
        console.log('Sac page use effect for', gstate.currSubpage);

        if (gstate.currSubpage === 'loadSacGeneral') loadSacGeneral();
        if (gstate.currSubpage === 'loadSacChat') loadSacChat();                
        if (gstate.currSubpage === 'loadSacCmts') loadSacCmts();
        if (gstate.currSubpage === 'loadSacDocs') loadSacDocs();
        if (gstate.currSubpage === 'loadAccount') loadAccount();

        const myURL = new URL(window.location);
        myURL.hash = gstate.currSubpage;
        window.history.replaceState('data-pass', 'title', myURL);
    }, [gstate.currSubpage]);

    const loadSacGeneral = () => {
        var sbpg = 'SacGeneral';
        setCurrSubpage('load' + sbpg);
        setState({ render: sbpg });
        window.scrollTo(0, 0);
    };
    
    const loadSacChat = () => {
        var sbpg = 'SacChat';
        setCurrSubpage('load' + sbpg);
        setState({ render: sbpg });
        window.scrollTo(0, 0);
    };  

    const loadSacCmts = () => {
        var sbpg = 'SacCmts';
        setCurrSubpage('load' + sbpg);
        setState({ render: sbpg });
        window.scrollTo(0, 0);
    };

    const loadSacDocs = () => {
        var sbpg = 'SacDocs';
        setCurrSubpage('load' + sbpg);
        setState({ render: sbpg });
        window.scrollTo(0, 0);
    };

    const showConfluenceHome = () => {
        setOpenConfluenceDialog();
    };

    const showVersionInfo = () => {
        setOpenQeeDialog({ open: true, id: 'dlgVersionInfo' });
    };

    const logoutHandler = () => {
        localStorage.removeItem('AuthToken');
        navigate('/login');
    };

    const drawer = () => {
        return (
            <>
                <div className={classes.toolbarxxx} />
                <List>
                    <ListItem button key="Sacs" onClick={loadSacs}>
                        <ListItemIcon>
                            <ChecklistIcon />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body2" className={classes.listItem}>Aktivitäten</Typography>}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button key="SacGeneral" onClick={loadSacGeneral}>
                        <ListItemIcon>
                            <ViewComfyIcon />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body2" className={classes.listItem}>Überblick</Typography>}
                        />
                    </ListItem>
                    <ListItem button key="SacChat" onClick={loadSacChat}>
                        <ListItemIcon>
                            <ChatIcon />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body2" className={classes.listItem}>Nachrichten</Typography>}
                        />
                    </ListItem>    
                    <ListItem button key="SacDocs" onClick={loadSacDocs}>
                        <ListItemIcon>
                            <TextSnippetIcon />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body2" className={classes.listItem}>Dokumente</Typography>}
                        />
                    </ListItem>
                </List>
            </>
        );
    };

    const switchSubpage = () => {
        switch (gstate.currSubpage) {
            case 'loadSacGeneral': return <SacGeneral />;
            case 'loadSacChat': return <SacChat sac={gstate.objEdit} />;
            case 'loadSacCmts': return <Cmts username={username} parentId={gstate.objEdit.id} />;
            case 'loadSacDocs': return <SacDocs />;
            default: return <SacGeneral />;
        }
    };

    const settings = [
        { nm: 'Mein Konto', fct: loadAccount, ico: <AccountBoxIcon /> },
        { nm: 'Aktivitäten', fct: loadSacs, ico: <ChecklistIcon /> },
        { nm: 'Wissensassistent', fct: showConfluenceHome, ico: <HelpOutlinedIcon /> },
        { nm: 'Logout', fct: logoutHandler, ico: <ExitToAppIcon /> },
    ];

    return (
        state.uiLoading ? (
            <div className={classes.root}>
                {state.uiLoading && <CircularProgress size={50} className={classes.uiProgess} />}
            </div>
        ) : (
            <div>
                <ResponsiveAppBar
                    className={classes.appBar}
                    drawer={drawer}
                    switchSubpage={switchSubpage}
                    pages={[]}
                    settings={settings}
                />
            </div>
        )
    );
}

export default Sac;
