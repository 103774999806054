import React, { useState, useCallback, useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import { themeV4 } from '../../../../../core/theme.js';
import { QeeColorButtonPrimary, QeeColorButtonSecondary } from '../../../../qeeColorButton';
import QeeFileInput from '../../../../qeeFileInput';

export function MuiTextInput({
	chatController,
	actionRequest,
}) {
	const maxLength = 1024;
	const showLengthHint = 128;
	const chatCtl = chatController;
	const initialDefaultValue = actionRequest.defaultValue || '';
	const [value, setValue] = useState(initialDefaultValue);
	const [charCount, setCharCount] = useState(initialDefaultValue.length);
	const [files, setFiles] = useState([]);
	const [uploadId, setUploadId] = useState('');

	const customTheme = useTheme() || themeV4;

	const setResponse = useCallback(() => {
		if (value) {
			const res = {
				type: 'text',
				value,
			};
			chatCtl.setActionResponse(actionRequest, res);
			setValue('');
			setCharCount(0);
		}
	}, [actionRequest, chatCtl, value]);

	const handleKeyDown = useCallback((e) => {
		if (chatController.chatConfig?.isP2P) return;

		if (e.nativeEvent.isComposing) {
			return;
		}

		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			setResponse();
		}
	}, [setResponse]);

	const handleChange = (e) => {
		if (e.target.value.length <= maxLength) {
			setValue(e.target.value);
			setCharCount(e.target.value.length);
		}
	};

	const handleFileListReady = (fileArray, newUploadId) => {
		if (fileArray.length > 0) {
			const value = fileArray.map((f) => f.name).toString();
			const res = {
				type: 'file',
				value,
				files: fileArray,
				uploadId: newUploadId // Add uploadId to the response
			};
			chatCtl.setActionResponse(actionRequest, res);
			setFiles([]);
		}
	};

	const initiateFileUpload = () => {
		//const newUploadId = `${Math.floor(Date.now() / 2000)}`; // Generate uploadId with 2 seconds precision
		const newUploadId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
		setUploadId(newUploadId);
		return newUploadId;
	};

	useEffect(() => {
		if (chatController.chatConfig?.isP2P) {
			initiateFileUpload();
		}
	}, [chatController.chatConfig?.isP2P, chatCtl.state.messages.length]);

	const sendButtonText = actionRequest.sendButtonText ? actionRequest.sendButtonText : 'Senden';

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
				borderRadius: '14px',
				'& > *': {
					minWidth: 0,
				},
				'& > * + *': {
					ml: 1,
				},
			}}
		>
			{chatController.chatConfig?.isP2P && (
				<>
					<QeeFileInput 
						facId={chatCtl.parentForm + '-' + chatCtl.parentId}
						buttonText=""
						sdoc_type={'chat'}
						sdoc_subtype={'msg'}
						upload_id={uploadId} // Pass the generated uploadId here
						hideUploadButton={false}
						hideFilesList={true}
						onSuccess={(fileArray) => handleFileListReady(fileArray, uploadId)} // Pass the same uploadId to handleFileListReady
					/>
				</>
			)}
			<TextField
				placeholder={actionRequest.placeholder}
				value={value}
				onChange={handleChange}
				autoFocus
				multiline
				inputProps={{ onKeyDown: handleKeyDown }}
				variant="outlined"
				maxRows={10}
				sx={{ flexGrow: 1 }}
			/>
			{chatController.chatConfig?.isP2P && charCount > showLengthHint &&
				<Typography
					variant="caption"
					sx={{
						position: 'absolute',
						top: 0,
						left: 10,
						color: 'text.secondary',
					}}
				>
					{`${charCount}/${maxLength}`}
				</Typography>
			}
			{value || files.length > 0 ? (
				<QeeColorButtonPrimary
					className="QeeColorButtonPrimary"
					disableElevation
					onClick={files.length > 0 ? () => handleFileListReady(files, uploadId) : setResponse}
					style={{ textTransform: "none", padding: "14px 0px", width: 60 }}
				>
					<SendIcon className="iconPrimary"/>
				</QeeColorButtonPrimary>
			) : (
				<QeeColorButtonSecondary
					className="QeeColorButtonSecondary"
					disableElevation
					disabled={true}
					style={{ textTransform: "none", padding: "14px 0px", width: 60, color: 'lightgrey' }}
				>
					<SendIcon className="iconSecondary"/>
				</QeeColorButtonSecondary>
			)}
		</Box>
	);
}
