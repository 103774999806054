import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, Button, Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import QeeSlideDialog from './qeeSlideDialog';
import { QeeColorButtonPrimary, QeeColorButtonSecondary } from './qeeColorButton';
import { useAuth } from "../context/provider";

const useStyles = makeStyles((theme) => ({
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2),
		boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
		backgroundColor: theme.palette.background.paper,
		position: 'fixed',
		width: '100%',
		bottom: 0,
		left: 0,
	},
	formContainer: {
		padding: 20,
		paddingTop: 70,
	},
}));

const styles = (theme) => ({});

const SacDialog = forwardRef((props, forwardedRef) => {
	const classes = useStyles();
	const theme = useTheme();
	const { setOpenQeeDialog } = useAuth();
	const [state, setState] = useState({
		sac: {},
		submitDisabled: true,
	});

	const handleSacOpen = (sac) => {
		if (!sac) {
			console.log('No SAC specified!');
			return;
		}
		setState({
			...state,
			sac: sac,
		});
		setOpenQeeDialog({ open: true, id: 'dlgSac' });
	};

	const handleSacClose = () => {
		setOpenQeeDialog(false);
	};

	useImperativeHandle(forwardedRef, () => ({
		handleSacOpen: handleSacOpen,
		handleSacClose: handleSacClose,
	}));

	const { sac } = state;

	return (
		<>
			<div ref={forwardedRef}></div>
			<QeeSlideDialog
				id='dlgSac'
				title='SAC Details'
				formComponent={
					<>
						<Box className={classes.formContainer}>
							<Typography variant="h5" component="h2">
								{sac.name}
							</Typography>
							<Typography className={classes.pos} color="textSecondary">
								{sac.description}
							</Typography>
							<Typography variant="body2" component="p">
								{'Type: ' + sac.sac_type}
							</Typography>
						</Box>
						<div className={classes.buttonsContainer}>
							<QeeColorButtonPrimary disableElevation variant="contained" onClick={handleSacClose}>
								Close
							</QeeColorButtonPrimary>
						</div>
					</>
				}
				hideDialogAppBar={false}
				hideDialogActions={true}
				submitDisabled={true}
				paddingContent={0}
			/>
		</>
	);
});
export default withStyles(styles)(SacDialog);
