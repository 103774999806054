import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import QeeArticle from './qeeArticle';
import QeeSlideDialog from './qeeSlideDialog';
import QeeArticleForm from './qeeArticleForm';
import QeeCart from './qeeCart';
import QeeCustomerInfo from './qeeCustomerInfo';
import QeeOrderConfirmation from './qeeOrderConfirmation';
import { useAuth } from "../context/provider";

const useStyles = makeStyles((theme) => ({
	gridShopItem: {
		height: '100%', // Set a fixed height for the grid item
		//padding: theme.spacing(2), // Padding between grid items
		//display: 'flex', // Center the article vertically
		//alignItems: 'center', // Center the article vertically
		//justifyContent: 'center', // Center the article horizontally
		backgroundColor: 'white',
		borderRadius: '10px',
	},
	parentContainer: {
		margin: '20px', // Apply a 20px margin to the parent container
	},
}));


const QeeShop = () => {
	const classes = useStyles();
	const { gstate, setOpenQeeDialog } = useAuth();
	const [articles, setArticles] = useState([]);
	const [selectedArtId, setSelectedArtId] = useState(null);
	const [selectedKey, setSelectedKey] = useState(null);	
	const [dialogKey, setDialogKey] = useState(0); // Add a state for the key
	const [currentStep, setCurrentStep] = useState(0);

	
	useEffect(() => {
		const fetchArticles = async () => {
			try {
				const response = await axios.get('/api/arts?categories=100,200');
				setArticles( response.data.entries.results);
			} catch (error) {
				console.error('Error fetching articles:', error);
			}
		};

		fetchArticles();
	}, []);

	
	useEffect(() => {
		if (selectedArtId !== null) {
			setCurrentStep( 0)
			setOpenQeeDialog({ open: true, id: 'dlgArticle' });
			setDialogKey((prevKey) => prevKey + 1); // Increment the key to force re-render
		}
	}, [selectedArtId, selectedKey]);

	
	const getSelectedArticleData = () => {
		if (!selectedArtId) {
			return [];
		}
		const selectedArticle = articles.find((article) => article.art_id === selectedArtId);
		console.log( '>>> selectedArticle',selectedArticle);
		return selectedArticle ? [selectedArticle] : [];
	};
	
	
	const handleOpenArticleDialog = (artId) => {
		setSelectedKey((prevKey) => prevKey + 1); // Increment the key to force re-render		
		setSelectedArtId( artId);		
	};
	
	
	const handleOnCartNow = (artId) => {
		setDialogKey((prevKey) => prevKey + 1); // Increment the key to force re-render		
		setCurrentStep((prevStep) => prevStep + 1); 
	};	
	

	const handleOnCustomerInfo = (artId) => {
		setDialogKey((prevKey) => prevKey + 1); // Increment the key to force re-render		
		setCurrentStep((prevStep) => prevStep + 1); 
	};		
	
	
	const handleOnOrderNow = async (billingDetails) => {
		console.log( 'billingDetails', billingDetails);
		try {
			var usr = JSON.parse( sessionStorage.getItem( 'usr'));
			if (usr) {
				console.log('sessionStorage usr', usr);
				const facId = gstate.objEdit.id;
				const ordData = {};
				ordData.billingDetails = billingDetails;
				const response = await axios.post( 
					'/api/ord/'+usr.usrId +'/'+selectedArtId + '?facId='+facId, 
					ordData
				);

				// Handle the response data here, if needed
				console.log('>>> Order response:', response.data);


				setDialogKey((prevKey) => prevKey + 1); // Increment the key to force re-render
				setCurrentStep((prevStep) => prevStep + 1);
			} else {
				console.error( 'No user data!');
				return { err: 'No user data!' };
			}
		} catch (error) {
			console.error('Error placing order:', error);
			// You can handle the error here, such as displaying an error message to the user
		}
	};

		
	const handleOnOrderDone = (artId) => {
		setCurrentStep(0); 
		setOpenQeeDialog({ open: false, id: 'dlgArticle' });		
	};			
	
	
	const renderFormComponent = () => {
		var usr = JSON.parse(sessionStorage.getItem('usr'));
		switch (currentStep) {
		case 0:
			return (
				<div style={{ paddingTop: 50 }}>
					<QeeArticleForm artId={selectedArtId} onCartNow={handleOnCartNow} />
				</div>
			);
		case 1:
			return (
				<div style={{ paddingTop: 50 }}>
					<QeeCart cartItems={getSelectedArticleData()} onCustomerInfo={handleOnCustomerInfo} />
				</div>
			);
		case 2:
			return (
				<div style={{ paddingTop: 50 }}>
					<QeeCustomerInfo usr={usr} onOrderNow={handleOnOrderNow} />
				</div>
			);			
		case 3:
			return (
				<div style={{ paddingTop: 50 }}>
					<QeeOrderConfirmation artId={selectedArtId} onOrderDone={handleOnOrderDone} />
				</div>
			);
		default:
			return null;
		}
	};	

	
	return (
		<div className={classes.parentContainer}>
			<Grid container spacing={0}>
				{articles.map((article) => (
					<Grid key={article.art_id} item xs={12} md={4} lg={3} className={classes.gridShopItem} style={{padding:10}}>
						<QeeArticle artId={article.art_id} onOpenDialog={handleOpenArticleDialog} />
					</Grid>
				))}
			</Grid>

			{/* Use the key prop to force re-render the QeeSlideDialog */}
			<QeeSlideDialog
				key={dialogKey}
				id="dlgArticle"
				title="Artikel bestellen"
				onClose={() => setOpenQeeDialog({ open: false, id: 'dlgArticle' })}
				formComponent={renderFormComponent()}
				hideDialogActions={true}
				submitDisabled={true}
			/>
		</div>
	);
};

export default QeeShop;
