import react, { useState, useEffect, useLayoutEffect } from 'react';
import { faHome, faBuilding, faMap, faIndustry, faFileArchive, faKey } from '@fortawesome/fontawesome-free-solid'

import FacCard from './facCard';

import { useAuth } from "../context/provider";

import Box from './Box';

const FacOwnership = ( props) => { 
	
	const thisStep = props.step;		
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();				
	
	const [validationErrors, setValidationErrors] = useState( []);					
	
	console.log( 'THIS STEP', thisStep);	
	console.log( 'objEdit.step', gstate.objEdit.step);		
		
		
	useEffect(()=> {  
		console.log( 'useEffect', thisStep);
		
		const nextBtn = document.getElementById( 'next-button')
		if( thisStep == gstate.objEdit.step) {
			nextBtn.onclick=null;
			nextBtn.onclick=(e) => {
				console.log( 'Set validation in FacOwnership');
				if( validate( e) > 0) {
					e.stopPropagation();
				} else {
					if( props.fctGotoNextStep) {
						e.stopPropagation();
						props.jumpToStep( props.fctGotoNextStep( thisStep));
					}
				}
				console.log( 'objEdit: ', gstate.objEdit)				
			}    
		} 		
								
	}, [JSON.stringify( gstate.objEdit)]);		
		
		
	/**
	* validate() - 
	*/
	const validate = (e) => { 
		
		console.log( '--> validate for FacOwnership in step: ', gstate.objEdit.step)
					
		var errs = false;		
		const validationErrors= [null];
		if( gstate.objEdit.fac_ownership == '') 
			validationErrors[0] = 'Du musst dein Verhältnis zum Objekt auswählen!';		

		if( validationErrors[0]) {
			//alert( validationErrors[0]);			
			errs = true;
			setValidationErrors( validationErrors); 			
		} 		
		
		console.log( '<-- validate: ' + errs)		
		return errs;
	}		
		
	/*
	<li><Box {...props} type="box" step="0" name="plot" title='Grundstück' icon={faMap} /></li>	
	<li><Box {...props} type="box" step="0" name="office" title='Gewerbe' icon={faIndustry} /></li>			
	*/
    return (
        <div class="form-group">
			{
				gstate.objEdit.fac_extern_id ? (
					<div style={{marginBottom: 40}}>
					<h2 class="title2" style={{marginBottom: 40}}>Dein ausgewähltes Inserat</h2>
					<FacCard 
						fac={gstate.objEdit} 
					/>
					</div>
				) : (
					<></>
				)
			}
			<h1 class="title2" style={{marginTop: gstate.isXS ? 10 : 60}}>Dein Verhältnis zum Objekt. Du bist...</h1>
			<div class="navi-horiz">
				<ul style={{padding:0}}>
					<li><Box {...props} type="box" step="0" name="fac_ownership" value="owner" size="2x" title='Besitzer' icon={faFileArchive} /></li>							
					<li><Box {...props} type="box" step="0" name="fac_ownership" value="buyer" size="2x" title='Käufer' icon={faKey} /></li>
				</ul>
			</div>
			{ 
				validationErrors[0] ? (
					<div style={{textAlign:"center"}}><small id="selError" className="form-text error">{validationErrors[0]}</small></div>					
				) :(
					<></>
				)
			}	
        </div>
    )
}
export default FacOwnership;