import react from 'react';

import Grid from "@material-ui/core/Grid";
import AddIcon from "@mui/icons-material/Add";

import { makeStyles } from "@material-ui/core/styles";

import notFoundImg from '../assets/favs-empty.png';

import { useAuth } from "../context/provider";


const useStyles = makeStyles(theme => ({
	container: {
		padding:100,
		textAlign: 'center',
	},
	img: {
		color: theme.palette.primary.main,
		height: 250
	},
	text: {
		color: '#444',
		fontSize: 16,
	},
	textContainer: {
		padding: 50
	}
}));


const SearchNotFound = ( props) => {
			
	const classes = useStyles();			
			
    return (		
        <div className={classes.container}>
			<Grid container direction='column'>
			<Grid item>
				<img className={classes.img} src={ notFoundImg } alt="Not found" />		
			</Grid>
			<Grid item className={classes.textContainer}>			
				<span className={classes.text}>Du hast noch keine Favoriten hinzugefügt.
				<br/>
				<br/>
				Um das zu tun, gehe bitte in der Immobiliensuche und markiere dort deine Favoriten!
				</span>
			</Grid>			
			</Grid>			
        </div>
    )
}

export default SearchNotFound;



