import react from 'react';

import Grid from "@material-ui/core/Grid";
import AddIcon from "@mui/icons-material/Add";

import { makeStyles } from "@material-ui/core/styles";

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import notFoundImg from '../assets/facs-shared-empty.jpg';
import useWindowDimensions from '../util/windowDimensions';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { useAuth } from "../context/provider";


const useStyles = makeStyles(theme => ({
	container: {
		padding:40,
		textAlign: 'center'
	},
	img: {
		color: theme.palette.primary.main,
		[theme.breakpoints.down('xs')]: {
			width:250,
		},
	},
	icon: {
		color: theme.palette.primary.main,
		position: 'relative',
		top: 5
	},
	text: {
		color: '#444',
		fontSize: 16
	},
	textContainer: {
		padding: 50,
		[theme.breakpoints.down('xs')]: {
			padding: 0,
			paddingTop: 40,
			paddingBottom: 40,
		}
	}
}));


/*
	<br/>
	<span className={classes.text}>oder dir eine <a onClick={() => props.fctSearch()} href='#'>aussuchen</a>!</span>
*/

const FacsSharedEmpty = ( props) => {
			
	const { gstate } = useAuth();			
			
	const classes = useStyles();		
			
    return (		
        <div className={classes.container}> 
			<Grid container direction='column'>
			<Grid item>
				<img className={classes.img} src={ notFoundImg } alt="Not found" />	
			</Grid>
			<Grid item className={classes.textContainer}>			
				<span className={classes.text}>Es wurde noch keine Immobilie mit dir geteilt.</span>
				<br/>
				<br/>
				<span className={classes.text}>Andere Benutzer können dir Zugriff auf ihren Immobiliendaten gewähren, als Betrachter oder als Mitbearbeiter der Daten.</span>
			</Grid>			
			</Grid>			
        </div>
    )
}

export default FacsSharedEmpty;



