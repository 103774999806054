import React from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DoneIcon from '@mui/icons-material/Done';

export const SAC_TYPES = {
    'rfi': 'Anfrage',
    'todo': 'Aufgabe',
    '100': 'Type 100'
};

export const SAC_STATES = {
    'rfi': {
        100: 'angefragt',
        200: 'bestätigt',
        300: 'erledigt',
        10000: 'storniert'
    },
    'todo': {
        100: 'geplant',
        200: 'in Arbeit',
        300: 'eledigt',
        10000: 'storniert'
    },
    'default': {
        100: 'geplant',
        200: 'in Arbeit',
        300: 'eledigt',
        10000: 'storniert'
    }
};

export const SAC_PRIORITIES = {
    1: 'hoch',
    2: 'mittel',
    3: 'niedrig'
};

export const SAC_TYPE_SYMBOLS = {
    'rfi': <HelpOutlineIcon fontSize="small" />,
    'todo': <AssignmentIcon fontSize="small" />,
    '100': <DoneIcon fontSize="small" />
};

export const SAC_STATE_COLORS = {
    100: '#FF8C00', // Orange
    200: '#008000', // Green
    300: '#0000FF', // Blue
};
