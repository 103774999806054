import react from 'react';

import Grid from "@material-ui/core/Grid";
import AddIcon from "@mui/icons-material/Add";

import { makeStyles } from "@material-ui/core/styles";

import notFoundImg from '../assets/fac-valuation-empty.png';
import useWindowDimensions from '../util/windowDimensions';

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';

import { useAuth } from "../context/provider";


const useStyles = makeStyles(theme => ({
	container: {
		padding:40,
		[theme.breakpoints.down('xs')]: {
			padding:10,
		},		
		textAlign: 'center',
	},
	img: {
		color: theme.palette.primary.main,
		width:200,
		[theme.breakpoints.down('xs')]: {
			width:120,
		},				
	},
	text: {
		color: '#444',
		fontSize: 16,
		[theme.breakpoints.down('xs')]: {
			fontSize: 14,
		},
	},
	textContainer: {
		padding: 50,
		[theme.breakpoints.down('xs')]: {
			padding:10,
		},				
	}
}));


const FacValuationEmpty = ( props) => {
			
	const classes = useStyles();			

	
    return (		
        <div className={classes.container}>
			<Grid container direction='column'>
			<Grid item>
				<img className={classes.img} src={ notFoundImg } alt="Not found" />		
			</Grid>
			<Grid item className={classes.textContainer}>
				<>
					<div className={classes.text} style={{marginBottom:12}}>
						Du hast noch keine Wertermittlung der Immobilie ausgeführt.<br/>
						Für die Erstellung der Wertermittlung benötigen wir noch weitere Informationen.<br/>
						<a onClick={props.fctOpenValuationWiz} style={{fontWeight:700}} href='#'>Hier</a> kannst du deinen Erfassungsassistent starten!
					</div>
					<QeeColorButtonPrimary disableElevation 
						className={classes.button} 
						onClick={props.fctOpenValuationWiz}>
						Erfassungsassistent
					</QeeColorButtonPrimary>									
				</>
			</Grid>			
			</Grid>			
        </div>
    )
}

export default FacValuationEmpty;



