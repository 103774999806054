// FacForm.js

import react, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import React, { Dimensions, Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@mui/icons-material/Warning';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import CheckIcon from '@mui/icons-material/Check';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MuiTableCell from "@material-ui/core/TableCell";
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import ButtonBase from '@material-ui/core/ButtonBase';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ChatIcon from '@mui/icons-material/Chat';

import { useNavigate } from "react-router-dom";
import axios from 'axios';

import FacOwnership from './facOwnership';
import FacType from './facType';
import FacAreas from './facAreas';
import FacNameDesc from './facNameDesc';
import FacImage from './facImage';
import FacTitle from './facTitle';
import SwipeablePictures from './swipeablePictures';
import QeeFileInput from './qeeFileInput';
import QeeTooltip from './qeeTooltip';
import QeeSlideDialog from './qeeSlideDialog';
import QeeOpenaiCompletions from './qeeOpenaiCompletions';
import QeeSingleFieldEditor from './qeeSingleFieldEditor';
import QeeHintBox from './qeeHintBox';
import FacRenovationIst from './facRenovationIst';
import { getFacExternUrl } from '../util/helpers';

import roofIcon from '../assets/roof.svg';
import fassadeIcon from '../assets/fassade.svg';
import windowIcon from '../assets/window.svg';
import basementIcon from '../assets/basement.svg';
import heatingIcon from '../assets/heating.svg';
import pvroofIcon from '../assets/pvroof.svg';

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import { usePicturesGallery } from "../components/qeePicturesGalleryProvider"; 
import {usrRight} from '../util/helpers';
import { useChat } from "./qeeChatProvider";

import { getDocs, getDocsNet, getSdatas, facSave } from "../api/service";

import css from '../core/App.css';

import { useAuth } from "../context/provider";
import { checkLicense } from '../util/auth';


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		width: '99%',
		//padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			padding: theme.spacing(0.2)
		}
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {		
			marginTop: theme.spacing(0.2),		
		}
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	button: {
		textTransform: 'none',
		//width: 280,
		height: 48
	},
	badge: {
		backgroundColor: '#dda'
	},
	floatingButton: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		zIndex: 2
	},
});


const sxTableCell = {
	fontSize: 16,
	fontWeight: 700,
	color: '#444',
	verticalAlign: 'top',	
}


// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


// main fct
const FacForm = ( props) => {
	
	const navigate = useNavigate();	
	
	const { gstate, setObjEdit, setObjErrors, setOpenQeeDialog, setQeeSnackbar } = useAuth();	
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
		//isXL: false,
		width: window.innerWidth,		
		openRenovation: false,
		openValuation: false,		
	})

	const { handleClickOpenChat } = useChat();	
	
	const [hasErrorsDlgOpenai, setHasErrorsDlgOpenai] = useState( true);				
	const [changesDlgOpenai, setChangesDlgOpenai] = useState( {});		
	
	/*
	function handleWindowSizeChange() {
		setState({
			...state,
			width: window.innerWidth
		});
	}	
	*/
/*
	useEffect( () => {	

		setState( {
			...state,
			isXL: state.width>=1536 ? true : false
		});
		console.log( 'state', state);				
		
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
												
	}, [state.width]);		
*/
	
	// some consts
	const useStyles = makeStyles({
		//...props
		descParagraph: {
			fontSize: 14,
			color: '#444',
			whiteSpace: 'pre-line'
		}		
	});			
	const classes = useStyles( props);				
	
	
	// dialog operations
	const DialogTitle = withStyles(styles)((props) => {
		const { children, classes, onClose, ...other } = props;
		return (
			<MuiDialogTitle disableTypography className={classes.root} {...other}>
				<Typography variant="h6">{children}</Typography>
				{onClose ? (
					<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
		);
	});
	
	
	/**
	*	getItemValue() - general getter for the column values
	*/
	const getItemValue = ( col, p, fac) => {
		
		try {
		
			if( p['fct_'+col])
				return p['fct_'+col]( fac);
			else if( p['item_'+col]) {
				var vl = '';
				try {
					vl = eval( 'fac' + p['item_'+col]);
					if( p['fctFormat_'+col])					
						vl = p['fctFormat_'+col]( vl);
					return vl+' '+(p['unit_'+col] ? p['unit_'+col] : '')
				} catch( e) {
					console.error( 'getItemValue ERR', e);
					return vl;
				}
			}
		
		} catch( e) {
			console.error( 'getItemValue ERR', e);
		}
	}
	
		
	const formatCurrency = ( vl) => {
		return new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR'}).format( vl).replace(/\D00(?=\D*$)/, '')
	}
	
	
	/**
	*	isShowCond() - checks the configurated condition, if any
	*/
	const isShowCond = ( r) => {		
		var fac = gstate.objEdit;
		if( r.cond) {   // if some cond specified as string
			var code = "(function(){/*console.log( 'r.cond', '"+r.cond+"');*/ return " + r.cond + "})";
			//console.log( 'r.cond code string', code);				
			var f = eval( code);
			//console.log( 'r.cond evaluated code', f.call( r));
			return f.call( r);		
		} else {
			return true;   // if no cond specified
		}		
	}			
			
				
	/**
	*	TableGeneralData - component for showing the fac basic data
	*/
	const TableGeneralData = React.memo((props) => {
					
		const useStyles = makeStyles( theme => ({
		}));			
		const classes = useStyles( props);	
		
		const [facTypeName, setFacTypeName] = useState( '');				
		const [houseTypeName, setHouseTypeName] = useState( '');		
		const [apartmentTypeName, setApartmentTypeName] = useState( '');		
		const [properties, setProperties] = useState( []);		

		useEffect(() => {
			async function getData() {
				const pps = await getProperties( props.fac);
				setProperties( pps);
				//setData( props.fac);									
			}
			getData();
		}, [facTypeName, houseTypeName, apartmentTypeName, JSON.stringify( gstate.objEdit.addr_gvalue_o)]);
				
		const getFacTypeName = async ( fac) => {			
			var arrRet = await getSdatas( 'sdata_facs_types', 'type', fac.fac_type);	
			if( arrRet.length)
				setFacTypeName( arrRet[0].name);
		}		
				
		
		const getHouseTypeName = async ( fac) => {			
			var arrRet = await getSdatas( 'sdata_houses_types', 'type', fac.fac_house_type);	
			if( arrRet.length)
				setHouseTypeName( arrRet[0].name);
		}		
		
		
		const getApartmentTypeName = async ( fac) => {			
			if( fac.fac_apartment_type) {
				var arrRet = await getSdatas( 'sdata_apartments_types', 'type', fac.fac_apartment_type);	
				if( arrRet.length)
					setApartmentTypeName( arrRet[0].name);
			}
		}				
		

		const getOwnerText = ( fac) => {			
			var txt = '';		
			if( fac.fac_ownership === 'buyer')
				txt = 'Käufer';
			else if( fac.fac_ownership === 'owner')
				txt = 'Besitzer';					
			return txt;
		}
			
			
		const getProperties = async ( fac) => {	
		
			console.log( 'getProperties', fac);
		
			getFacTypeName( fac);				
			if( fac.fac_type == '100')
				await getHouseTypeName( fac);
			else if( fac.fac_type == '200')
				await getApartmentTypeName( fac);	
		
			var pps = [];
			if( fac.fac_ownership === 'buyer')
				pps = getPropertiesBuyer();
			else if( fac.fac_ownership === 'owner')
				pps = getPropertiesOwner();	
			
			return pps;	
		}

		
		const getPropertiesOwner = () => {
			var arr = [
				{label:'Bezeichnung', 				item_1:"['fac_name']", btnEdit_1:"dlgName"},								
				{label:'Verhältnis zur Immobilie', 	fct_1:getOwnerText},
				{label:'Bundesland', 				item_1:"['addr_administrative_area_level_long_1']", cond:'fac.addr_administrative_area_level_long_1!==undefined', btnTooltip_1:'Je nach Bundesland können unterschiedliche Förderprogramme für Sanierungen gelten.'},					
				{label:'Stadt/Kreis', 				item_1:"['addr_political_locality']", cond:'fac.addr_administrative_area_level_long_3!==undefined', btnTooltip_1:'Je nach Stadt/Kreis können unterschiedliche Förderprogramme für Sanierungen gelten. Auch die Arbeitspreise sind regional abhängig, diese Aspekte werden in der Kalkulationen berücksichtigt.'},									
				{label:'Stadtteil', 				item_1:"['addr_political_sublocality']", cond:'fac.addr_political_sublocality!==undefined'},									
				{label:'Immobilientyp', 			fct_1:()=>{return facTypeName}},
				{label:'Haustyp', 					fct_1:()=>{return houseTypeName}, cond:'fac.fac_type=="100"'},				
				{label:'Wohnungstyp', 				fct_1:()=>{return apartmentTypeName}, cond:'fac.fac_type=="200"'},								
				{label:'Baujahr', 					item_1:"['fac_construction_year']"},	
				{label:'Wohnfläche',				item_1:"['fac_living_area']",  unit_1:'m²'},	
				{label:'Anzahl Zimmer', 			item_1:"['fac_rooms_count']"},	
				{label:'Anzahl Stockwerke',			item_1:"['fac_floors_count']"},	
				{label:'Grundstücksfläche', 		item_1:"['fac_plot_area']",  unit_1:'m²', cond:'fac.fac_type=="100"'},					
				{label:'Personen im Haushalt', 		item_1:"['fac_persons_count']", btnEdit_1:"dlgPersonsCount"},	
			];
			return arr;
		}
		
		
		const getPropertiesBuyer = () => {
			var arr = [
				{label:'Bezeichnung', 				item_1:"['fac_name']", btnEdit_1:"dlgName"},				
				{label:'Verhältnis zur Immobilie', 	fct_1:getOwnerText},
				{label:'Bundesland', 				item_1:"['addr_administrative_area_level_long_1']", cond:'fac.addr_administrative_area_level_long_1!==undefined', btnTooltip_1:'Je nach Bundesland können unterschiedliche Förderprogramme für Sanierungen gelten.'},					
				{label:'Stadt/Kreis', 				item_1:"['addr_political_locality']", cond:'fac.addr_administrative_area_level_long_3!==undefined', btnTooltip_1:'Je nach Stadt/Kreis können unterschiedliche Förderprogramme für Sanierungen gelten. Auch die Arbeitspreise sind regional abhängig, diese Aspekte werden in der Kalkulationen berücksichtigt.'},									
				{label:'Stadtteil', 				item_1:"['addr_political_sublocality']", cond:'fac.addr_political_sublocality!==undefined'},													
				{label:'Immobilientyp', 			fct_1:()=>{return facTypeName}},
				{label:'Haustyp', 					fct_1:()=>{return houseTypeName}, cond:'fac.fac_type=="100"'},
				{label:'Wohnungstyp', 				fct_1:()=>{return apartmentTypeName}, cond:'fac.fac_type=="200"'},								
				{label:'Baujahr', 					item_1:"['fac_construction_year']"},	
				{label:'Wohnfläche',				item_1:"['fac_living_area']",  unit_1:'m²'},	
				{label:'Anzahl Zimmer', 			item_1:"['fac_rooms_count']"},	
				{label:'Anzahl Stockwerke',			item_1:"['fac_floors_count']"},	
				{label:'Grundstücksfläche', 		item_1:"['fac_plot_area']",  unit_1:'m²', cond:'fac.fac_type=="100"'},					
				{label:'Personen im Haushalt', 		item_1:"['fac_persons_count']", btnEdit_1:"dlgPersonsCount"},									
				{label:'Kaufpreis', 				item_1:"['fac_price']['value']", fctFormat_1:formatCurrency},													
				{label:'Kaufnebenkosten', 			item_1:"['fac_price']['additional']", fctFormat_1:formatCurrency},																	
				{label:'Preis pro qm', 				item_1:"['fac_price']['value_m2']", fctFormat_1:formatCurrency},	
				{label:'Propertec Projekt-ID', 		item_1:"['id']"},													
			];
			return arr;
		}		
		
	
		return (
			<TableContainer component={Paper} sx={{ maxWidth: 1000 }}>
				<Table sx={{ minWidth: 150 }} aria-label="general data table">
					<TableBody>
						{properties.map((p) => (
							isShowCond( p) ?												
							<TableRow key={p.item} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								{!gstate.isXS ?
									<>
									<TableCell width="250px" component="th" scope="row" sx={{padding:2, "& td": { border: 0 }}}><Typography variant="lbl" style={{fontWeight:700}}>{p.label}</Typography></TableCell>
									<TableCell sx={{...sxTableCell, fontWeight:600}}><Typography variant="body2">{getItemValue( 1, p, props.fac)}</Typography></TableCell>
									<TableCell width="50px">
										{p.btnEdit_1 ? 
											(usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?										
												<Button variant="text" onClick={() => {setOpenQeeDialog( {open:true, id:p.btnEdit_1})}}><EditIcon /></Button>
											:'')
										:''}
										{p.btnTooltip_1 ? 																			
											<QeeTooltip title={p.btnTooltip_1}>
											<IconButton>
												<InfoOutlinedIcon style={{color:'#a1bda1'}}/>
											</IconButton>									
											</QeeTooltip>
										:''}										
									</TableCell>																		
									</>
								:
									<TableCell width="250px" component="th" scope="row" sx={{padding:2, "& td": { border: 0 }}}>
										<Typography style={{fontWeight:700}}>{p.label}</Typography>
										<Box sx={{...sxTableCell}}><Typography variant="val">{getItemValue( 1, p, props.fac)}</Typography></Box>
										{p.btnEdit_1 ? 
											<Button variant="text" onClick={() => {setOpenQeeDialog( {open:true, id:p.btnEdit_1})}}><EditIcon /></Button>				
										:''}
									</TableCell>
								}
							</TableRow>												
							:''
						))}
					</TableBody>
				</Table>
			</TableContainer>		
		)
	})		


	/**
	*	TableComponentsData - component for showing the fac construction components data
	*/
	const TableComponentsData = React.memo((props) => {
					
		const useStyles = makeStyles( theme => ({
			miniLabel: {
				whiteSpace: 'nowrap',
				fontSize: 12,			
				color: '#888',
			},					
			miniLabelRed: {
				//whiteSpace: 'nowrap',
				fontSize: 12,			
				color: 'red',
				padding:10				
			},		
			miniLabelOrange: {
				//whiteSpace: 'nowrap',
				fontSize: 12,			
				color: 'orange',
				padding:10				
			},					
			miniLabelGreen: {
				//whiteSpace: 'nowrap',
				fontSize: 12,						
				color: 'green',
				padding:10
			},					
		}));			
		const classes = useStyles( props);	
		
		const [data, setData] = useState( '');
		const [roofName, setRoofName] = useState( '');		
		const [fassadeName, setFassadeName] = useState( '');		
		const [windowName, setWindowName] = useState( '');		
		const [basementName, setBasementName] = useState( '');		
		const [heatingName, setHeatingName] = useState( '');
		const [pvRoofName, setPvRoofName] = useState( '');				
		const [structureName, setStructureName] = useState( '');						
		
		useEffect(() => {
			async function getData() {
				getRoofTypeName( props.fac);
				getFassadeTypeName( props.fac);
				getWindowTypeName( props.fac);
				getBasementTypeName( props.fac);
				getHeatingTypeName( props.fac);			
				getPvRoofTypeName( props.fac);				
				setData( props.fac);
			}
			getData();
		}, []);

		const getStructureTypeName = async ( fac) => {			
			var arrRet = await getSdatas( 'sdata_structures_types', 'type', fac.fac_structure_material);
			if( arrRet.length)
				setStructureName( arrRet[0].name);
		}		
		const getRoofTypeName = async ( fac) => {			
			var arrRet = await getSdatas( 'sdata_roofs_materials', 'type', fac.fac_roof_material);	
			if( arrRet.length)
				setRoofName( arrRet[0].name);
		}				
		const getFassadeTypeName = async ( fac) => {			
			var arrRet = await getSdatas( 'sdata_fassades_types', 'type', fac.fac_fassade_material);
			if( arrRet.length)
				setFassadeName( arrRet[0].name);
		}		
		const getWindowTypeName = async ( fac) => {	
			var arrRet = await getSdatas( 'sdata_windows_types', 'type', fac.fac_windows_type);	
			if( arrRet.length)
				setWindowName( arrRet[0].name);
		}
		const getBasementTypeName = async ( fac) => {			
			var arrRet = await getSdatas( 'sdata_basements_types', 'type', fac.fac_basement_type);	
			if( arrRet.length)
				setBasementName( arrRet[0].name);
		}			
		const getHeatingTypeName = async ( fac) => {			
			var arrRet = await getSdatas( 'sdata_heatings_types', 'type', fac.fac_heating_type);	
			if( arrRet.length)
				setHeatingName( arrRet[0].name);
		}
		const getPvRoofTypeName = async ( fac) => {			
			var arrRet = await getSdatas( 'sdata_pvroofs_types', 'type', fac.fac_pvroof_type);	
			if( arrRet.length)
				setPvRoofName( arrRet[0].name);
		}						

		const getLabelClass	= ( reno) => {
			try {				
				if( reno.oldness > reno.lifetime)	
					return classes.miniLabelRed;
				else
					return classes.miniLabelGreen;				
			} catch( e) {
				return classes.miniLabelRed;
			}
		}		
		const getRenoIcon = ( reno) => {
			try {				
				if( reno.oldness > reno.lifetime)	
					return <FmdBadIcon sx={{fontSize:32, fontWeight:800, color:'red'}}/>
				else
					return <CheckIcon sx={{fontSize:32, fontWeight:800, color:'green'}}/>			
			} catch( e) {
				return '';
			}			
		}
		const RenoInfo = ( props) => {
			return (
				<>
				{props.reno.value == '1' ?
					<div style={{fontWeight:600}}>{props.reno.renovation}</div>
					:
					<div style={{fontWeight:400}}>keine Sanierung</div>
				}						
						
				{props.renoPlan ?				
					<>
					{props.renoPlan.oldness ?		
						<>
						<div className={classes.miniLabel}>{'Alter '+props.renoPlan.oldness+' Jahre'}</div>							
						<div style={{display:'inline-flex'}}>
							<div>{getRenoIcon( props.renoPlan)}</div>
							<div className={getLabelClass( props.renoPlan)}>{'Eine Sanierung wird nach '+props.renoPlan.lifetime+" Jahren empfohlen."}</div>
						</div>
						</>
						:
						<div style={{display:'inline-flex'}}>
							<WarningIcon sx={{fontSize:32, fontWeight:800, color:'orange'}}/>
							<div className={classes.miniLabelOrange}>Erfassung vervollständigen!</div>
						</div>
					}
					</>
					:''
				}
				</>
			)
		}
				
		const getRenovationRoof		= ( fac) => { return <RenoInfo reno={fac.fac_renovations[0]} renoPlan={fac.fac_plan_renovations.length ? fac.fac_plan_renovations[0] : undefined} />}
		const getRenovationFassade	= ( fac) => { return <RenoInfo reno={fac.fac_renovations[1]} renoPlan={fac.fac_plan_renovations.length ? fac.fac_plan_renovations[1] : undefined} />}
		const getRenovationWindows	= ( fac) => { return <RenoInfo reno={fac.fac_renovations[2]} renoPlan={fac.fac_plan_renovations.length ? fac.fac_plan_renovations[2] : undefined} />}
		const getRenovationBasement	= ( fac) => { return <RenoInfo reno={fac.fac_renovations[3]} renoPlan={fac.fac_plan_renovations.length ? fac.fac_plan_renovations[3] : undefined} />}
		const getRenovationHeating	= ( fac) => { return <RenoInfo reno={fac.fac_renovations[4]} renoPlan={fac.fac_plan_renovations.length ? fac.fac_plan_renovations[4] : undefined} />}		
		const getRenovationPvRoof	= ( fac) => { return <RenoInfo reno={fac.fac_renovations[5]} renoPlan={fac.fac_plan_renovations.length ? fac.fac_plan_renovations[5] : undefined} />}		
						
		const getProperties = () => {
			var arr = [
				{label:'Dach', 					ico:roofIcon, 		fct_1:()=>{return roofName},   		fct_2:getRenovationRoof, cond:'fac.fac_type=="100"', 		sdoc_type:200, sdoc_subtype:200},	
				{label:'Fassade', 				ico:fassadeIcon, 	fct_1:()=>{return fassadeName},		fct_2:getRenovationFassade, cond:'fac.fac_type=="100"', 	sdoc_type:200, sdoc_subtype:300},				
				{label:'Fenster', 				ico:windowIcon, 	fct_1:()=>{return windowName},		fct_2:getRenovationWindows, 								sdoc_type:200, sdoc_subtype:400},													
				{label:'Keller/Bodenplatte', 	ico:basementIcon, 	fct_1:()=>{return basementName},	fct_2:getRenovationBasement, cond:'fac.fac_type=="100" && fac.fac_has_basement=="1"', sdoc_type:200, sdoc_subtype:500},											
				{label:'Heizung', 				ico:heatingIcon, 	fct_1:()=>{return heatingName},		fct_2:getRenovationHeating, cond:'fac.fac_type=="100"', 	sdoc_type:200, sdoc_subtype:600},	
				{label:'PV-Anlage', 			ico:pvroofIcon, 	fct_1:()=>{return pvRoofName},  	fct_2:getRenovationPvRoof, cond:'fac.fac_type=="100" && fac.fac_has_pvroof=="1"', sdoc_type:200, sdoc_subtype:700},					
			];
			return arr;
		}		
		
		
		//const TableCell = withStyles({
		//	root: {
		//		borderBottom: "none",
		//	}
		//})(MuiTableCell);
		const TableCell2 = withStyles({
		})(MuiTableCell);		
		
		
		return (
			<TableContainer component={Paper}  sx={{ maxWidth: 1000 }}>
				<Table sx={{ minWidth: 150}} aria-label="invest table">
					<TableBody>
						<TableRow>
							{!gstate.isXS ?
								<>
								<TableCell width="50px"></TableCell>													
								<TableCell width="250px" sx={sxTableCell} align="left"><Typography variant="body2">Bauteil</Typography></TableCell>
								<TableCell sx={{...sxTableCell}} align="left"><Typography variant="body2">Typ/Material</Typography></TableCell>
								<TableCell sx={{...sxTableCell}} align="left"><Typography variant="body2">Letzte Sanierung</Typography></TableCell>
								</>
							:''}
						</TableRow>
						{getProperties().map((p, idx) => (	
							isShowCond( p) ?
							<>		
							<TableRow key={p.item} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								{!gstate.isXS ?
									<>
									<TableCell sx={{...sxTableCell, borderBottom: 'none'}} align="left"><img src={p.ico} style={{width:50}}></img></TableCell>
									<TableCell component="th" scope="row" sx={{padding:2, "& td": { border: 0 }, ...sxTableCell, fontWeight:600, borderBottom: 'none'}}><Typography variant="h3" style={{fontWeight:700, marginTop: 0}}>{p.label}</Typography></TableCell>
									<TableCell sx={{...sxTableCell, borderBottom: 'none'}}><Typography variant="body2">{getItemValue( 1, p, props.fac)}</Typography></TableCell>
									<TableCell sx={{...sxTableCell, borderBottom: 'none'}}><Typography>{getItemValue( 2, p, props.fac)}</Typography></TableCell>
									</>
								:
									<TableCell component="th" scope="row" sx={{padding:2, "& td": { border: 0 }, borderBottom: 'none' }}>
										<Grid container direction="row">
											<Grid item style={{marginRight:20}}>
												<img src={p.ico} style={{width:50}}></img>
											</Grid>
											<Grid item>
												<Box sx={{...sxTableCell}}><Typography style={{fontWeight:700}}>{p.label}</Typography></Box>
												<Box sx={{...sxTableCell}}><Typography>{getItemValue( 1, p, props.fac)}</Typography></Box>
												<Typography>{getItemValue( 2, p, props.fac) ?
													<Box sx={{...sxTableCell, fontWeight:600}}>Letzte Sanierung: {getItemValue( 2, p, props.fac)}</Box>
												: ''}		
												</Typography>			
											</Grid>
										</Grid>
									</TableCell>
								}
							</TableRow>		
							{gstate.objEdit.fac_type == '100' ?										
							<TableRow key={p.item} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell2 colSpan={4}>	
									<div style={{marginLeft:10, width:gstate.isXS ? 350 : 600}}>
										<QeeFileInput 
											compId={idx}
											facId={gstate.objEdit.id}
											buttonText="Fotos hochladen"
											keyItm={['sdoc_type','sdoc_subtype']}
											keyVal={[p.sdoc_type,p.sdoc_subtype]}
											sdoc_type={p.sdoc_type}
											sdoc_subtype={p.sdoc_subtype}
											hideUploadButton={false}
										/>
									</div>
								</TableCell2>
							</TableRow>		
							:''}																
							</>
							:''	
						))}
					</TableBody>
				</Table>
			</TableContainer>		
		)		
	})
	
		
	const gotoPortalExternId = () => {
		const externUrlInfo = gstate.objEdit.fac_extern_id ? 
			getFacExternUrl( gstate.objEdit.fac_extern_portal, gstate.objEdit.fac_extern_id) :
			null;	
				
		window.open( externUrlInfo.url, "_blank");		
	}


	const getWidth = () => {
		var w = '100%';
		if( gstate.isXL) w = 700;	
		return w;
	}
	
	
	const FacImageComp = React.memo(( props) => {
		
		const { setOpenPicturesGalleryDialog } = usePicturesGallery();																	
		
		const [isLoading, setIsLoading] = useState( false);
	
		// local function
		const showGallery = async () => {
			
			setIsLoading( true);
			
			var pcs = [];
						
			// get all the picture files from storage
			const docs = await getDocsNet( gstate.objEdit.id, 'sdoc_type', 200);	
			if( !docs.err) {
				console.log( 'FacForm showGallery getDocs', docs);			
				for( var i=0; i<docs.length; i++) {
					var pic = {};
					pic.url = docs[i].signedUrl;
					let nm = docs[i].metadata.metadata ? docs[i].metadata.metadata.sdoc_name : docs[i].name;	
					pic.caption = nm;

					pcs.push( pic);		
				}			
			}
			
			if( gstate.objEdit.fac_pictures)
				pcs = pcs.concat( gstate.objEdit.fac_pictures);
						
			setIsLoading( false);							
			
			setOpenPicturesGalleryDialog( pcs, 0);
		}
		
				
		const handleReloadFac = () => {
			window.location.reload();
		}

	
		const externUrlInfo = gstate.objEdit.fac_extern_id ? 
			getFacExternUrl( gstate.objEdit.fac_extern_portal, gstate.objEdit.fac_extern_id) :
			null;	

	
		return (
			<div style={{ textAlign: 'right' }}>
				<div style={{
					minHeight: gstate.isXS ? 300 : 'auto',
					height: gstate.isXS ? 'auto' : 500,
					width: '100%',
				}}>
					<FacImage fac={gstate.objEdit}
						style={{ maxWidth: getWidth() }}
						pictureDivStyle={{
							width: '100%',
							height: gstate.isXS ? 'auto' : 500,
							borderRadius: 5,
							backgroundColor: '#eee',
							marginRight: 10,
						}}
						pictureStyle={{
							width: '100%',
							height: gstate.isXS ? 'auto' : 500,
							borderRadius: 5,
							//marginRight: 10,
							objectFit: 'cover'
						}}
					/>
				</div>

				<Grid container direction="row" spacing={0} justify="flex-end" style={{marginTop:10}}>
					<Grid item xs={12} sm="auto">
						<div style={{ width: gstate.isXS ? '100%' : 'auto', textAlign: gstate.isXS ? 'center' : 'right' }}>				
							{usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?
								<QeeFileInput
									compId="100"
									facId={gstate.objEdit.id}
									buttonText="Hochladen"
									tooltipTitle="Hausansichten hochladen. Bauteile Bilder (Dach, Fenster, Heizung usw.) können im Sanierungsassistent hochgeladen werden."
									keyItm={['sdoc_type', 'sdoc_subtype']}
									keyVal={[200, 100]}
									sdoc_type={200}
									sdoc_subtype={100}
									buttonWidth={120}
									buttonHeight={48}
									hideButtonText={true}
									iconFontSize="small"
									hideFilesList={true}
									onSuccess={handleReloadFac}
								/>
								: ''}
						</div>
					</Grid>
					{/*
					<Grid item>
						<div>
							<QeeTooltip title="Bildergalerie öffnen">
								<QeeColorButtonSecondary disableElevation
									onClick={showGallery}
									className={classes.button}
									style={{ marginTop: 10, marginRight: 0 }}>
									<>
										<ViewCarouselIcon style={{ marginRight: 10 }} />
										<>Bilder</>
									</>
									{isLoading ?
										<CircularProgress style={{ width: 30, marginLeft: 10 }} />
										: ''}
								</QeeColorButtonSecondary>
							</QeeTooltip>
						</div>
					</Grid>
					<Grid item>
						{gstate.objEdit.fac_extern_id && externUrlInfo ?
							<div>
								<QeeTooltip title={`Ursprungsanzeige im ${externUrlInfo.name} öffnen`}>
									<QeeColorButtonSecondary disableElevation
										onClick={gotoPortalExternId}
										className={classes.button}
										style={{ marginTop: 10, marginRight: 0 }}>
										<OpenInNewIcon style={{ marginRight: 10 }} />
										<>{gstate.isXS ? externUrlInfo.name : `${externUrlInfo.name} Anzeige ${gstate.objEdit.fac_extern_id}`}</>
									</QeeColorButtonSecondary>
								</QeeTooltip>
							</div>
							:
							<></>
						}
					</Grid>
					*/}
				</Grid>
			</div>
		);
	})
	
	
	const getExternDescription = ( json) => {
		
		for( var i=0; i<json.length; i++) {
			if( json[i].type == 'object')	
				return json[i].text;
		}
	}
	
	
	const getExternEquipment = ( json) => {
		
		for( var i=0; i<json.length; i++) {
			if( json[i].type == 'equipment')	
				return json[i].text;
		}
	}	
	
	
	const getExternOther = ( json) => {
		
		var ret = '';
		
		for( var i=0; i<json.length; i++) {
			if( json[i].type == 'other') {	 
				ret += json[i].text;
				ret += '<br/><br/>'
			}
		}
		
		ret = ret.replace(/&amp;/g, "").replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/ &a;#8364;/g, '<br />');
		
		return ret;
	}

	
	/**
	*	DlgNameComp() - to avoid rerenders in the parent
	*/
	const DlgNameComp = React.memo(( props) => {
		
		const [hasErrors, setHasErrors] = useState( true);				
		const [changes, setChanges] = useState( {});		
				
		const handleHasErrors = ( hasErrors) => {
			console.log( 'handleHasErrors hasErrors', hasErrors);
			setHasErrors( hasErrors);
		}					
		const handleChanges = ( changes) => {
			//console.log( 'handleChanges results', changes);
			setChanges( changes);
		}

		return(
			<QeeSlideDialog 
				id='dlgName'
				title='Immobilienbezeichnung' 
				formComponent={
					<QeeSingleFieldEditor
						fieldType={'TextField'}
						title={'Gib eine eindeutige Bezeichnung ein'}
						label={'Bezeichnung'}
						placeholder={'Bezeichnung für die Immobilie'}	
						maxLength={64}						
						vl={gstate.objEdit.fac_name}
						fctOnHasErrors={handleHasErrors}
						fctOnChanges={handleChanges}
					/>						
				}	
				submitDisabled={hasErrors}			
				fctOnSubmit={async ()=>{
					var ret = await facSave( gstate.objEdit, {
						fac_name: changes.text
					});
					if( !ret.err) {
						setObjEdit( ret);
						return true;
					}
				}}>
			</QeeSlideDialog>												
		)		
	})			
	

	/**
	*	DlgPersonsCountComp() - to avoid rerenders in the parent
	*/
	const DlgPersonsCountComp = React.memo(( props) => {
		
		const [hasErrors, setHasErrors] = useState( true);				
		const [changes, setChanges] = useState( {});		
				
		const handleHasErrors = ( hasErrs) => {
			console.log( 'handleHasErrors hasErrors', hasErrors);
			setHasErrors( hasErrs);
		}					
		const handleChanges = ( changes) => {
			console.log( 'handleChanges results', changes);
			setChanges( changes);
		}

		const fieldName = 'fac_persons_count';

		return(
			<QeeSlideDialog 
				id='dlgPersonsCount'
				title='Personen im Haushalt' 
				formComponent={
					<QeeSingleFieldEditor
						fieldType={'ButtonCounter'}
						title={'Wie viele Personen sind im Haushalt?'}
						label={'Personen'}
						objData={gstate.objEdit}
						fctOnChanges={(chg)=>handleChanges(chg)}
						fctOnHasErrors={handleHasErrors}						
						fieldName={fieldName}
						defaultValue={1} 
						min={1} 
						max={12} 
						step={1} 
						label={'Personen im Haushalt'}						
					/>						
				}	
				submitDisabled={hasErrors}			
				fctOnSubmit={async ()=>{
					var ret = await facSave( gstate.objEdit, {
						[fieldName]: changes[fieldName]
					});
					if( !ret.err) {
						setObjEdit( ret);
						return true;
					}
				}}>
			</QeeSlideDialog>												
		)		
	})		

		
	/**
	*	DlgDescComp() - to avoid rerenders in the parent
	*/
	const DlgDescComp = React.memo(( props) => {
		
		const [hasErrorsDlgDesc, setHasErrorsDlgDesc] = useState( true);				
		const [changesDlgDesc, setChangesDlgDesc] = useState( {});		
				
		const handleHasErrorsDlgDesc = ( hasErrors) => {
			console.log( 'handleHasErrorsDlgDesc hasErrors', hasErrors);
			setHasErrorsDlgDesc( hasErrors);
		}					
		const handleChangesDlgDesc = ( changes) => {
			//console.log( 'handleChangesDlgDesc results', changes);
			setChangesDlgDesc( changes);
		}

		return(
			<QeeSlideDialog 
				id='dlgDesc'
				title='Beschreibung der Immobilie' 
				formComponent={
					<QeeSingleFieldEditor
						fieldType={'TextFieldMultiline'}
						title={'Beschreibe ausführlich die Immobilie'}
						label={'Textbeschreibung'}
						placeholder={'Deine Beschreibung'}
						maxLength={256}
						vl={gstate.objEdit.fac_desc}
						fctOnHasErrors={handleHasErrorsDlgDesc}
						fctOnChanges={handleChangesDlgDesc}
					/>						
				}	
				submitDisabled={hasErrorsDlgDesc}			
				fctOnSubmit={async ()=>{
					var ret = await facSave( gstate.objEdit, {
						fac_desc: changesDlgDesc.text
					});
					if( !ret.err) {
						setObjEdit( ret);
						return true;
					}
				}}>
			</QeeSlideDialog>												
		)		
	})		
	
	
	/**
	*	DlgNotesComp() - to avoid rerenders in the parent
	*/
	const DlgNotesComp = React.memo(( props) => {
		
		const [hasErrorsDlgNotes, setHasErrorsDlgNotes] = useState( true);				
		const [changesDlgNotes, setChangesDlgNotes] = useState( {});		
				
		const handleHasErrorsDlgNotes = ( hasErrors) => {
			console.log( 'handleHasErrorsDlgNotes hasErrors', hasErrors);
			setHasErrorsDlgNotes( hasErrors);
		}					
		const handleChangesDlgNotes = ( changes) => {
			//console.log( 'handleChangesDlgNotes results', changes);
			setChangesDlgNotes( changes);
		}

		return(
			<QeeSlideDialog 
				id='dlgNotes'
				title='Notizen' 
				formComponent={
					<QeeSingleFieldEditor
						fieldType={'TextFieldMultiline'}
						title={'Ergänze hier deine Notizen'}
						label={'Notizentext'}
						placeholder={'Deine Notizen'}
						maxLength={1024}
						vl={gstate.objEdit.fac_notes}
						fctOnHasErrors={handleHasErrorsDlgNotes}
						fctOnChanges={handleChangesDlgNotes}
					/>						
				}	
				submitDisabled={hasErrorsDlgNotes}			
				fctOnSubmit={async ()=>{
					var ret = await facSave( gstate.objEdit, {
						fac_notes: changesDlgNotes.text
					});
					if( !ret.err) {
						setObjEdit( ret);
						return true;
					}
				}}>
			</QeeSlideDialog>												
		)		
	})			
	
	
	/**
	*	DlgOpenaiComp() - to avoid rerenders in the parent
	*/
	const DlgOpenaiComp = React.memo(( props) => {
		
		const [hasErrorsDlgOpenai, setHasErrorsDlgOpenai] = useState( true);				
		const [changesDlgOpenai, setChangesDlgOpenai] = useState( {});		
				
		const handleHasErrorsDlgOpenai = ( hasErrors) => {
			console.log( 'handleHasErrorsDlgOpenai hasErrors', hasErrors);
			setHasErrorsDlgOpenai( hasErrors);
		}					
		const handleChangesDlgOpenai = ( changes) => {
			//console.log( 'handleChangesDlgOpenai results', changes);
			setChangesDlgOpenai( changes);
		}

		return(
			<QeeSlideDialog 
				id='dlgOpenaiDesc'
				title='Textanzeige generieren' 
				formComponent={
					<QeeOpenaiCompletions
						fctOnHasErrors={handleHasErrorsDlgOpenai}
						fctOnChanges={handleChangesDlgOpenai}
					/>				
				}	
				submitDisabled={hasErrorsDlgOpenai}			
				fctOnSubmit={async ()=>{
					var ret = await facSave( gstate.objEdit, {
						fac_desc_prompt_openai: changesDlgOpenai.prompt,			
						fac_desc_openai: changesDlgOpenai.text
					});
					if( !ret.err) {
						setObjEdit( ret);
						return true;
					}
				}}>
			</QeeSlideDialog>				
		)		
	})				
	
	
	const getHintSponsor = () => {
		return gstate.objEdit.sponsor_liccus_desc;
	}	
	
	
	const TableGeneralDataSponsor = React.memo(( props) => {
		return( 
			<>
			<Typography variant="h1">
				Allgemeine Daten
				{checkLicense(gstate.usr.lic) === 'premium' && (
					<Badge badgeContent={'PREMIUM'} color="license">
						<div style={{ width: 50 }} />
					</Badge>
				)}
			</Typography>
			<div></div>
			{checkLicense(gstate.usr.lic) === 'premium' && gstate.objEdit.sponsor_liccus_desc && (
				<QeeHintBox
					type="sponsor"
					text={
						<React.Fragment>
							<strong>Sponsoring Information:</strong> <span dangerouslySetInnerHTML={{ __html: getHintSponsor() }}></span>
						</React.Fragment>
					}
					style={{ marginTop: 20 }}
				/>
			)}
			<TableGeneralData fac={gstate.objEdit} />	
			</>
		);						
	})
	
	
	const TextInformations = React.memo(( props) => {
		return( 
			<>
			<Typography variant="h1">Text Informationen</Typography>
			<Grid container direction="column" style={{ marginTop: 20 }}>
				{gstate.objEdit.fac_desc_json && (
					<React.Fragment>
						<Paper style={{ padding: 20 }} sx={{ maxWidth: 1000 }}>
							<Box sx={{ display: 'inline-flex', width: '100%' }}>
								<Box><Typography variant="h5">Anzeigetext</Typography></Box>
							<Box>
								<QeeTooltip title={'Automatisch übernommener Anzeigetext aus der Internetanzeige. Die Text Formatierung kann u.U. verlorengegangen sein.'}>
									<IconButton>
										<InfoOutlinedIcon style={{ color: '#a1bda1' }} />
									</IconButton>
								</QeeTooltip>
							</Box>
						</Box>
						<Typography variant="h6">Beschreibung</Typography>
						<br />
						<Typography variant="p" className={classes.descParagraph}>{getExternDescription(gstate.objEdit.fac_desc_json)}</Typography>
						<br /><br />
						{getExternEquipment(gstate.objEdit.fac_desc_json) && (
							<>
								<Typography variant="h6">Austattung</Typography><br />
								<Typography variant="p" className={classes.descParagraph}>{getExternEquipment(gstate.objEdit.fac_desc_json)}</Typography>
							</>
						)}
						<br /><br />
						{getExternOther(gstate.objEdit.fac_desc_json) && (
							<>
								<Typography variant="h6">Weitere Details</Typography><br />
								<Typography variant="p" className={classes.descParagraph}><React.Fragment><span dangerouslySetInnerHTML={{ __html: getExternOther(gstate.objEdit.fac_desc_json) }}></span></React.Fragment></Typography>
							</>
						)}
					</Paper>
				</React.Fragment>
			)}
									
			<Paper style={{ padding: 10, marginTop: 20, marginTop: 20 }} sx={{ maxWidth: 1000 }}>
				<Box sx={{ display: 'inline-flex', width: '100%' }}>
					<Box sx={{ whiteSpace: 'nowrap' }}><Typography variant="h3">Meine Beschreibung</Typography></Box>
					<Box>
						<QeeTooltip title={'Beschreibe kurz deine Immobilie wie z.B. "Wunderschönes Altbauhaus mit Flair, ideal für die junge Familie".'}>
							<IconButton><InfoOutlinedIcon style={{ color: '#a1bda1' }} /></IconButton>
						</QeeTooltip>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
						<Button variant="text" onClick={() => { setOpenQeeDialog({ open: true, id: 'dlgDesc' }) }}>
							<EditIcon />
						</Button>
					</Box>
				</Box>
				<Typography variant="p" className={classes.descParagraph}>{gstate.objEdit.fac_desc}</Typography>
			</Paper>
		
			<Paper style={{ padding: 10, marginTop: 20, marginTop: 20 }} sx={{ maxWidth: 1000 }}>
				<Box sx={{ display: 'inline-flex', width: '100%' }}>
					<Box sx={{ whiteSpace: 'nowrap' }}><Typography variant="h3">Meine Notizen</Typography></Box>
					<Box>
						<QeeTooltip title={'Trage hier deine Notizen ein, wie z.B. Besprechungsergebnisse mit der Bank, Sanierungstermine usw.'}>
							<IconButton><InfoOutlinedIcon style={{ color: '#a1bda1' }} /></IconButton>
						</QeeTooltip>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
						<Button variant="text" onClick={() => { setOpenQeeDialog({ open: true, id: 'dlgNotes' }) }}>
							<EditIcon />
						</Button>
					</Box>
				</Box>
				<Typography variant="p" className={classes.descParagraph}>{gstate.objEdit.fac_notes}</Typography>
			</Paper>
		

			{gstate.objEdit.fac_desc_openai && (               
				<Paper style={{ padding: 10, marginTop: 20, marginTop: 20 }} sx={{ maxWidth: 1000 }}>
					<Box sx={{ display: 'inline-flex', width: '100%' }}>
						<Box sx={{ whiteSpace: 'nowrap' }}><Typography variant="h3">Automatisch generierte Anzeigetext</Typography></Box>
						<Box>
							<QeeTooltip title={'Automatisch generierte Textanzeige die durch unser KI-Modul basierend auf deine Anzeige erstellt wurde.'}>
								<IconButton><InfoOutlinedIcon style={{ color: '#a1bda1' }} /></IconButton>
							</QeeTooltip>
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
							<Button variant="text" onClick={() => { setOpenQeeDialog({ open: true, id: 'dlgOpenaiDesc' }) }}>
								<EditIcon />
							</Button>
						</Box>
					</Box>
					<Typography variant="p" className={classes.descParagraph}>{gstate.objEdit.fac_desc_openai}</Typography>
				</Paper>               
			)}
			</Grid>
			</>
		)
	})
	

	return (
		gstate.isLoading ? (
			<div className={classes.root}>
				{gstate.isLoading && <CircularProgress size={gstate.isLG ? 100 : 50} className={classes.uiProgess} />}
			</div>		
		) : (			
			<>
			<main className={classes.content}>
				<FacTitle fac={gstate.objEdit} showPinPoint={true}/>
								
				<IconButton
					className={classes.floatingButton}
					color="primary"
					aria-label="Open Chat"
					onClick={()=>handleClickOpenChat( gstate.objEdit.id)}
				>
					<ChatIcon style={{ fontSize: 60 }} />
				</IconButton>								
								
				<Grid container direction="column" spacing={1} style={{ marginTop: 20 }}>
					<Grid item>
						<Grid container direction={gstate.isXL ? "row" : "column"} spacing={gstate.isXS ? 1 : 4} style={{ marginTop: 2 }}>
							{!gstate.isXL && (
								<Grid item xs={12} style={{ maxWidth: 1000, width: gstate.isXS ? '95%' : '100%' }}>
									<FacImageComp />
									<TableGeneralDataSponsor />
									{gstate.objEdit?.fac_energy?.end_ist && <FacRenovationIst />}
									<Box style={{ marginTop:80 }}><TextInformations /></Box>
								</Grid>
							)}

							{gstate.isXL && (
								<>													
								<Grid item xl={6} md={12} sm={12} xs={12}>
									<TableGeneralDataSponsor />
									<Box style={{ marginTop: 80 }}><TextInformations /></Box>	
								</Grid>	
								<Grid item xl={6}>
									<FacImageComp />
									{gstate.objEdit?.fac_energy?.end_ist && <FacRenovationIst />}
								</Grid>							                       
								</>						
							)}
							
							{gstate.objEdit.fac_prj_type === 'renovation' && (
								<Grid item item xl={12} md={12} sm={12} xs={12} style={{ marginTop: 20 }}>
									<Typography variant="h1">Bauteile</Typography>
									<TableComponentsData fac={gstate.objEdit} />
								</Grid>
							)}
								
						</Grid>
					</Grid>
			 
				</Grid>
			</main>		
			
			<DlgNameComp />
			<DlgPersonsCountComp />
			<DlgDescComp />
			<DlgNotesComp />
			<DlgOpenaiComp />
						
			</>				
		)		
	)
}	
export default withStyles(styles)(FacForm);