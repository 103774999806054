import React from "react";
import react, { useState, useEffect, useLayoutEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import SendIcon from '@mui/icons-material/Send';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../context/provider";

const useStyles = makeStyles(theme => ({
	selectButton: {
		boxShadow: 'none',
		border: `1px solid ${theme.palette.primary.main}`,				
	},	
}));

const FacsSelect = ( props) => {
		
	const classes = useStyles();
		
	const { gstate, setFacsSearching, setFacsSearchFilter } = useAuth();					
		
	const [sel, setSel] = React.useState( props.label);				
	const [anchorEl, setAnchorEl] = React.useState(null);		
	
	const handleSearch = event => {		
		console.log( 'FacsSelect handleSearch', event);	

		setFacsSearching( true);
		
		//if( props.fctSearch)
		//	props.fctSearch();
		
		setAnchorEl( null);
	}
	
	const handleClick = event => {
		setAnchorEl( event.currentTarget);
	};
	const handleClose = () => {		
		setAnchorEl( null);
	};
	const open = Boolean( anchorEl);  	
	
	return (
		<Grid item style={{padding:10}}>	
			<Grid container direction="column"  spacing={1}>
				<Grid item>
					<>{props.label}</>	
				</Grid>
				<Grid item>	
					<ButtonGroup color="secondary" aria-label="medium secondary button group">				
						<Button variant="contained" color="secondary"
							className={classes.selectButton}
							style={{width:props.width ? props.width: 200, textTransform: 'none', justifyContent: 'flex-start', fontWeight: 700, whiteSpace: 'nowrap'}} 
							onClick={handleClick}>
								{gstate.facsSearchFilter[props.field] ? gstate.facsSearchFilter[props.field] : (props.value=='*' ? 'alle' : props.value)}
								<IconButton aria-label="delete" color="primary">
									{anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>							
						</Button>
					</ButtonGroup>
					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{vertical: "bottom", horizontal: "left" }}
						transformOrigin={{ vertical: "top", horizontal: "left" }}
					>
						<form>
						<Grid container
							direction="column"
							justifyContent="center"
							alignItems="center"																				
							spacing={16} 
							style={{ marginTop: 10, padding: 10}}>
							{props.formComponent}
							<Button variant="contained" color="primary" 
								style={{width:'100%', textTransform: 'none'}} 
								onClick={handleSearch}>
								<div>Objekte anzeigen</div>
							</Button>									
						</Grid>
						</form>
					</Popover>
				</Grid>
			</Grid>	
		</Grid>
    );
	
}
export default FacsSelect;