import react, { useState, useEffect, useLayoutEffect } from 'react';
import React from 'react';
import { makeStyles }  from '@material-ui/core/styles';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
//import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { green } from '@mui/material/colors';

import WaitProgressCircular from './waitProgressCircular';

import { GetSdataIconByName } from "../core/icons";
import { getSdatas, getSdatasNet } from "../api/service";

import { useAuth } from "../context/provider";


const QeeCheckboxGroup = ( props) => {
		
	/**
	* 	Workaround to be able to use this comp from another app. 
	*	The ext app must provide initilaized elems gstate and setObjEdit as props (aen-20230331).
	*/
	function useAuthData() {
		
		var auth = useAuth();
		
		if( props.gstate) {
			const gstate = props.gstate;
			const setObjEdit = props.setObjEdit;		
			return { gstate, setObjEdit };			
		} else {
			const { gstate, setObjEdit } = auth;			
			return { gstate, setObjEdit };
		}
	}
	const { gstate, setObjEdit } = useAuthData();   // avoid hook limitations	
	
	const [sd, setSd] = useState([]);		
	const [chks, setChks] = useState([]);		
	const [isLoading, setIsLoading] = useState( true);	
		
	// some consts
	const useStyles = makeStyles( theme => ({
		formLabel: {
			//fontSize: 32
		},
		formSublabel: {
			fontSize: 16
		},
		formControlLabel: {
			width:200, 
			padding:30, 
			margin: '20px !important',
			marginLeft: '0px !important',   // aen-20240130
			border: 'solid 1px gray',
			color: `1px solid ${theme.palette.primary.main}`,					
		},
		formControlLabelSelected: {
			width:200, 
			padding:26, 
			margin: '20px !important',
			marginLeft: '0px !important',	// aen-20240130		
			border: `5px solid ${theme.palette.primary.main}`,
			background: '#eee',
			borderRadius: 5,
			color: theme.palette.primary.main
		},
		checkbox: {
			color: theme.palette.primary.main
		}
	}));			
	const classes = useStyles( props);			
		

	useEffect( () => {
		async function fetchData() {
			setIsLoading( true);				
			var sd = [];
			if( props.sdataName) {
				sd = await getSd();   // get it from remote
			} else if( props.sdata) {	
				sd = props.sdata;	// get it from props	
			}
			setSd( sd);
			if( !props.defaultValue) {
				for( var i=0; i<sd.length; i++) {
					chks.push({'name':sd[i].value, 'value':'0'})
				}
				setChks( chks);				
			} else {
				setChks( props.defaultValue);				
			}
			setIsLoading( false);				
		}
		fetchData();
		
	}, [props.sdataName]);	  // aen-20230331 added condition for use with qeewidgets		
	
	
	const getSd = async (row) => {
		var sd = [];
		try {
			var sdata = await getSdatas( props.sdataName, '', '', 'vallab');	
			if( sdata && sdata.length) {
				for( var i=0; i<sdata.length; i++) {
					try {						
						// get the desc for tooltips
						if( sdata[i].desc) {
							Object.keys( sdata[i].desc).forEach( 
							e => {
								sdata[i].descText = sdata[i].desc[e]; 
							});
						}
													
						// if autoicons configured	
						if( props.hasAutoIcon) {
							sdata[i].icon = GetSdataIconByName( props.sdataName, sdata[i].value, rgIconStyle());
						} else {
							// complete with icon from props.data (old way)
							if( props.data)
								if( props.data[i].icon)
									sdata[i].icon = props.data[i].icon;							
						}		
						
					} catch( e) {
						console.error( 'Cannot set info and icon for ' + i, e);
					}
				}
				sd = sdata;
			}
			
		} catch( e) {
			console.error( 'getData ERR', e);
		} finally {
			return sd;			
		}
	}	
	
	
	useEffect( () => {
		console.log( '>>> chks', chks)		
		console.log( '>>> sd', sd)		
	}, [JSON.stringify( chks), sd.length]);	
			
	
	const handleOnChange = (ev) => { 
		console.log( 'handleOnChange: ',  ev.target.name);
		
		let checks = [];
		sd.map((r, i) => {
			
			//console.log( 'elem', r.name + '-' + r.value);
			if( r.value === ev.target.name) {
				console.log( 'name', r.label);
				console.log( 'value', ev.target.value);
				console.log( 'checked', ev.target.checked);
				var chk = (ev.target.checked == true ? '1' : '0');
				
				var idx = chks.findIndex( item => item.name === ev.target.name);
				if( idx !== -1) {
					var chks2 = chks;
					chks2[idx].value = chk
					setChks( chks2)
				}
			} 	
		})
		
		//const checkes = sd.filter(r => r.value === "1").map(r => r.value);
		console.log("Checked values:", chks);

		if( props.fctOnChange) {			
			props.fctOnChange( chks);
		}
		
	}	
		

	const rgIconStyle = () => {
		if( !gstate.isXS)
			return { width:100, height:100 }
		else
			return { width:36, height:36 }
	}

	
    return (		
		<FormControl>
			{props.formLabel ? (
				<h2 id="checkboxes-group-label" className={classes.formLabel}>{props.formLabel}</h2>
			) : (
				<></>
			)}
			{props.formSublabel ? (
				<div id="checkboxes-group-label" className={classes.formSublabel}>{props.formSublabel}</div>
			) : (
				<></>
			)}

			{isLoading ? (
				<div className="form-group" style={{ padding: 40 }}>
					<WaitProgressCircular />
				</div>
			) : (
				sd.map ? (
					<FormGroup
						aria-labelledby="checkbox-group-label"
						row
						defaultValue={props.defaultValue}
						name="checkbox-group"
						onChange={(ev) => {
							handleOnChange(ev)
						}}
					>
						{sd.map((r, i) => (
							<FormControlLabel
								key={i}
								labelPlacement="top"
								checked={chks[i].value === '1'}
								name={r.value}
								control={
									<Checkbox
										sx={{
											color: theme => theme.palette.primary.main,
											'&.Mui-checked': {
												color: theme => theme.palette.primary.main,
											},
										}}
									/>
								}
								label={
									<div style={{ textAlign: 'center' }}>
										<div>{r.icon}</div>
										<div>{r.label}</div>
									</div>
								}
								className={chks[i].value === '1' ? classes.formControlLabelSelected : classes.formControlLabel}
							/>
						))}
					</FormGroup>
				) : (
					<></>
				)
			)}
		</FormControl>
    )
}
export default QeeCheckboxGroup;
