import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

export default function WaitProgressCircular() {
			
	return (
		<CircularProgress
			sx={{
				color: (theme) => theme.palette.primary.main,
				'&.Mui-checked': {
					color: (theme) => theme.palette.primary.dark,
				},
			}}
		/>
	);
}
