import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '@mui/material/Divider';
import { useNavigate, useLocation } from 'react-router-dom';
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import { useAuth } from "../context/provider";
import QeeNavigator from '../context/navigator';
import ResponsiveAppBar from '../components/appbar';
import UsrChats from '../components/usrChats';
import UsrChat from '../components/usrChat';

const useStyles = makeStyles((theme) => ({
    appBar: {
        overflow: 'hidden',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        height: '90vh',
        overflow: 'hidden',
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'row',
        height: '90vh',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        }
    },
    listContainer: {
        flex: 1,
        paddingRight: theme.spacing(1),
        boxSizing: 'border-box',
        borderRight: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'visible',
    },
    chatContainer: {
        flex: 2,
        paddingLeft: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        paddingLeft: theme.spacing(0.5),
        marginTop: 0
    }
}));

const Chats = () => {
    const globalSessionKey = 'chats-global';
    const { setOpenConfluenceDialog } = useConfluence();
    const { setOpenQeeDialog, gstate, setObjEdit } = useAuth();    
    const navigate = useNavigate();
    const location = useLocation();
    
    const { loadHome, loadFacs, loadAccount, loadChats } = QeeNavigator();    

    const initialState = {
        initialFilter: { isP2P: true }, // Initial filter state
        render: 'Chats', // Initial render state
        title: 'Chats', // Initial title
        filterReady: false // Track if filter is set
    };

    const [state, setState] = useState(() => initStateFromSessionStorage(globalSessionKey, initialState));
    const [filters, setFilters] = useState(() => initStateFromSessionStorage(globalSessionKey, initialState.initialFilter, 'filters'));
    const [initialFilter, setInitialFilter] = useState(state.initialFilter);
    const [uiLoading, setUiLoading] = useState(true);
    const [selectedChat, setSelectedChat] = useState(null);

    const classes = useStyles();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const { render, title, filterReady } = state;

    const chatUpdateRef = useRef(null);

    const mergeFilters = (localFilter) => {
        let mergedFilters = { ...filters, ...localFilter };
        console.log('mergedFilters', mergedFilters);
        return mergedFilters;
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let initialFilter = { isP2P: true };        
        const filter = {};
        if (params.has('filter')) {
            const filterParams = new URLSearchParams(params.get('filter'));
            filterParams.forEach((value, key) => {
                filter[key] = value;
            });
        }
        const title = 'Nachrichten';
        const mergedFilters = mergeFilters(filter);
        
        setInitialFilter(initialFilter);        
        setFilters(mergedFilters);
        setState(prevState => ({
            ...prevState,
            title,
            filterReady: true // Indicate that the filter is ready
        }));
    }, [location.search]);

    useEffect(() => {
        setUiLoading(false);
        
        try {
            // kill the body overflow    
            // Save the original overflow value
            const originalOverflow = document.body.style.overflow;
            document.body.style.overflow = 'hidden';
            return () => {
              document.body.style.overflow = originalOverflow;
            };    
        } catch( e) {
            console.error( 'Error set/reset body overflow', e)
        }        
    }, []);
    
    const handleChatSelect = (chat) => {
        console.log('Selected chat:', chat);
        setSelectedChat(chat);
    };

    const handleChatUpdate = () => {
        if (chatUpdateRef.current) {
            chatUpdateRef.current();
        }
    };

    const showConfluenceHome = () => {
        setOpenConfluenceDialog();
    };

    const showVersionInfo = () => {
        setOpenQeeDialog({ open: true, id: 'dlgVersionInfo' });
    };

    const logoutHandler = () => {
        localStorage.removeItem('AuthToken');
        navigate('/login');
    };

    const switchSubpage = () => {
        switch (render) {
            case 'Facs':
                return loadFacs();            
            case 'Account':
                return loadAccount();
            case 'Chats':
            default:
                return loadChatsContent();
        }
    };

    const settings = [
        { nm: 'Mein Konto', fct: () => setState({ ...state, render: 'Account' }), ico: <AccountBoxIcon /> },
        { nm: 'Wissensassistent', fct: showConfluenceHome, ico: <HelpOutlinedIcon /> },
        { nm: 'Versionsinfo', fct: showVersionInfo, ico: <PrivacyTipIcon /> },
        { nm: 'Logout', fct: logoutHandler, ico: <ExitToAppIcon /> }
    ];

    const loadChatsContent = () => {
        return (
            <main className={classes.content}>
                <Box className={classes.mainContent}>
                    <Box className={classes.listContainer}>
                        <UsrChats 
                            title={title} 
                            onChatSelect={handleChatSelect} 
                            filters={filters || {}} 
                            onChatUpdate={chatUpdateRef}
                        />
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box className={classes.chatContainer}>
                        {selectedChat ? (
                            <UsrChat key={selectedChat.id} chat={selectedChat} onChatUpdate={handleChatUpdate} />
                        ) : (
                            <Typography>Wähle einen Chat zur Ansicht aus</Typography>
                        )}
                    </Box>
                </Box>
            </main>
        );
    };

    return (
        <>
            {uiLoading ? (
                <CircularProgress />
            ) : (
                <ResponsiveAppBar
                    className={classes.appBar}
                    drawerWidth={0}
                    switchSubpage={switchSubpage}
                    pages={[]}
                    settings={settings}
                />
            )}
        </>
    );
};

function initStateFromSessionStorage(globalKey, defaultState, subKey = null) {
    const savedState = sessionStorage.getItem(globalKey);
    const parsedState = savedState ? JSON.parse(savedState) : defaultState;

    if (subKey) {
        return parsedState[subKey] !== undefined ? parsedState[subKey] : defaultState;
    }

    return parsedState;
}

function updateSessionStorage(globalKey, subKey, value) {
    const savedState = sessionStorage.getItem(globalKey) ? JSON.parse(sessionStorage.getItem(globalKey)) : {};
    savedState[subKey] = value;
    sessionStorage.setItem(globalKey, JSON.stringify(savedState));
}

export default Chats;
