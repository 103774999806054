import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Button, Checkbox, Typography, FormControlLabel, useMediaQuery, useTheme } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import axios from 'axios';

import { useAuth } from "../context/provider";

const PromotionDialog = React.forwardRef((props, forwardedRef) => {
    const [open, setOpen] = useState(false);
    const [promoData, setPromoData] = useState({ prm_name: "" });
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);
    const theme = useTheme();
    const { gstate, setCurrSubpage } = useAuth();

    // Fetch promotional data from the server
    const fetchPromotionalData = async () => {
        try {
            const prmid = '100';
            const response = await axios.get(`/api/prm/${prmid}`);
            const promoResponse = response.data;

            // Check if prm_show_condition matches the anchor in the URL
            if( promoResponse.prm_show_on_subpage === gstate.currSubpage) {
                console.log( '>>> promoResponse', promoResponse)
                setPromoData( promoResponse);
            }
        } catch (error) {
            console.error("Error fetching promotional data:", error);
        }
    };

	useEffect(() => {
        if( promoData.prm_name !== '') {
            setOpen(true);	
		}
	}, [promoData.prm_name]);		
	
	
    const handleOpen = () => {
        if (sessionStorage.getItem('usr') && JSON.parse(sessionStorage.getItem('usr')).usr_prm_block) {
            // If the user has blocked promotions, do not show the dialog
            return;
        }

        // Show the dialog with a 2-second delay
        setTimeout(() => {
			setPromoData({ prm_name: "" });
			fetchPromotionalData();
        }, 2000);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRedirect = () => {
        handleClose();
        setCurrSubpage('loadFacShop');
    };

    const handleCheckboxChange = (event) => {
        setDoNotShowAgain(event.target.checked);
    };

    useImperativeHandle(forwardedRef, () => ({
        handlePromotionOpen: handleOpen,
        handlePromotionClose: handleClose,
    }));

    const handleSubmit = async () => {
        if (doNotShowAgain) {
            try {
                let usr = JSON.parse(sessionStorage.getItem('usr'));
                const response = await axios.put(`/api/usr/${usr.id}`, {
                    usr_prm_block: true,
                });
                console.log('>>> Submited usr data', response.data);
                if (response.data.message) {
                    console.log('Checkbox state submitted successfully!');
                    usr.usr_prm_block = true;
                    sessionStorage.setItem('usr', JSON.stringify(usr));
                } else {
                    console.error('Failed to submit the checkbox state. Please try again later.');
                }

            } catch (error) {
                console.error('An error occurred while submitting the checkbox state. Please try again later.');
                // Add error handling if needed
            }
        }

        handleRedirect();
    };


    return (
        <>
            <div ref={forwardedRef}></div>

            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm" transitionDuration={500}>
                <DialogTitle>

                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: 0, right: 12 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: promoData.prm_html }}></div>
                    <div>
                        <FormControlLabel
                            control={<Checkbox checked={doNotShowAgain} onChange={handleCheckboxChange} />}
                            label={<Typography variant="body3" style={{ fontSize: 14 }}>Diese Werbung nicht mehr anzeigen</Typography>}
                        />
                    </div>
                    <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: 20, marginBottom: 20 }}>
                        Zum Shop
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
});

export default PromotionDialog;
