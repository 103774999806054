// Box.js
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../context/provider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    container: (props) => ({
        flex: 1,
        backgroundColor: '#eee',
        height: 'auto', // Adjust height automatically based on content
        minWidth: 160,  // Minimum width
        maxHeight: 120,  // Maximum height
        width: props.width || 'auto',  // Default to auto if width is not provided
        maxWidth: props.maxWidth || 'none',  // Set maximum width from props, default to none
        margin: 'auto',
        padding: props.type === 'box' ? theme.spacing(3.75) : theme.spacing(1.25),
        display: 'flex',
        flexDirection: 'column', // Align items vertically
        alignItems: 'center', // Center items horizontally
        justifyContent: 'center', // Center items vertically
        boxSizing: 'border-box', // Include padding in width and height
        border: '1px solid #eee',
        borderRadius: 8,
        overflow: 'hidden',  // Ensure content does not overflow
        '&.selected': {
            border: `5px solid ${theme.palette.primary.main}`,
            borderRadius: 8,
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%', // Adjust width for small screens
            borderRadius: 5,
            '&.selected': {
                borderRadius: 5,
            },
        },
    }),
    label: {
        padding: 5,
        fontSize: (props) => theme.typography.pxToRem(props.gstate.isXS ? 18 : 16),
        fontWeight: 'bold',
        textAlign: 'center',  // Center text horizontally
    },
    icon: {
        fontSize: 48,  // Adjust icon size to fit within max height
        margin: 10,
        color: theme.palette.primary.main,
    },
}));

const Box = (props) => {
    const { gstate, setObjEdit } = useAuth();
    const theme = useTheme();
    const classes = useStyles({ ...props, gstate, theme });

    useEffect(() => {
        getClass();
    }, []);

    const getClass = () => {
        let cls = props.type ? props.type : 'box';
        try {
            if ('' + gstate.objEdit[props.name] === '' + props.value) cls += ' selected';
        } catch (e) {
            console.log('Box getClass ERR: ' + e);
        }
        return cls;
    };

    const setSelection = () => {
        let oe = gstate.objEdit;
        oe[props.name] = props.value;
        setObjEdit(oe);
    };

    return (
        <div onClick={setSelection} className={clsx(getClass(), classes.container)}>
            <FontAwesomeIcon icon={props.icon} size={props.size} className={classes.icon} />
            <div className={classes.label}>{props.title}</div>
        </div>
    );
};

export default Box;
