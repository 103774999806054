import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import QeeSlideDialog from './qeeSlideDialog';
import QeeStepper from './qeeStepper';
import { ObjChangeProvider } from "./qeeObjChangeProvider";
import { useAuth } from "../context/provider";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: 20,
        paddingTop: 70,
    },
}));
const styles = (theme) => ({});

const StepperDialog = forwardRef((props, forwardedRef) => {
    const classes = useStyles();
    const theme = useTheme();
    const { setOpenQeeDialog } = useAuth();
    const [state, setState] = useState({
        context: props.context || {},
        title: '',
        stepper: null,
    });

    const handleStepperDialogOpen = (context, title, stepper) => {
		console.log( 'handleStepperDialogOpen', context, title, stepper);
        if (!context || !stepper) {
            console.log( 'ERR: No context or stepper specified!');
            return;
        }
        setState({
            context,
            title,
            stepper,
        });
        setOpenQeeDialog({ open: true, id: 'dlgStepper' });
    };

    const handleStepperDialogClose = () => {
        setOpenQeeDialog(false);
    };

    useImperativeHandle(forwardedRef, () => ({
        handleStepperDialogOpen: handleStepperDialogOpen,
        handleStepperDialogClose: handleStepperDialogClose,
    }));

    const { context, title, stepper } = state;

    return (
        <>
            <div ref={forwardedRef}></div>
            <QeeSlideDialog
                id='dlgStepper'
                title={title}
                formComponent={
                    <div className={classes.formContainer}>
						<ObjChangeProvider>
							<Box>
								{stepper && (
									<QeeStepper
										context={context}   // objs context, like fac, org, etc.
										instanceName={stepper.instanceName} // "qeeleaapp-newbuild"
										wizReadyFieldName={stepper.wizReadyFieldName} // "fac_newbuild_wiz_ready"
										fctSubmitEnd={stepper.fctSubmitEnd} // {(fac) => handleNewbuildSubmitEnd(fac)}
										stepSubmitEnd={stepper.stepSubmitEnd} // {7}
									/>
								)}
							</Box>
						</ObjChangeProvider>
                    </div>
                }
                hideDialogAppBar={false}
                hideDialogActions={true}
                submitDisabled={true}
                paddingContent={0}
            />
        </>
    );
});
export default withStyles(styles)(StepperDialog);
