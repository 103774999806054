import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';

import { useAuth } from "../context/provider";
import FacCard from './facCard';
import { QeeColorButtonPrimary } from './qeeColorButton';
import QeeHintBox from './qeeHintBox';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const useStyles = makeStyles(theme => ({
    checkButton: {
        position: 'relative',
        marginLeft: 10,
        top: 0,
        padding: 0,
        width: 120
    },
    fieldsBox: {
        marginTop: 40,
        display: 'inline'
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: 120,
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    whiteLoader: {
        color: 'white'
    }
}));

const FacPortalId = (props) => {
    const thisStep = props.step;
    const { gstate, setObjEdit, setObjErrors } = useAuth();
    const [validationErrors, setValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [externId, setExternId] = useState( gstate.objEdit.fac_extern_id);
    const [selectedPortal, setSelectedPortal] = useState( gstate.objEdit.fac_extern_portal || 'scrapfly.immowelt');
    const classes = useStyles();
	const [isExposeIdValidated, setIsExposeIdValidated] = useState(false); 	
	const [isExposeLoaded, setIsExposeLoaded] = useState(false); 		

	/*
    // Effect to enable/disable "Weiter" button based on validation
    useEffect(() => {
        const nextBtn = document.getElementById('next-button');
        if (thisStep === gstate.objEdit.step) {
            nextBtn.disabled = !isExposeIdValidated; // Disable if expose ID is not validated
        }
    }, [isExposeIdValidated, gstate.objEdit.step, thisStep]);
	*/

    useEffect(() => {
        const nextBtn = document.getElementById('next-button');
        if (thisStep === gstate.objEdit.step) {
            nextBtn.onclick = (e) => {
                if (validate(e)) {
                    e.stopPropagation();
                }
            };
        }

        const prevBtn = document.getElementById('prev-button');
        if (thisStep === gstate.objEdit.step) {
            prevBtn.onclick = (e) => {
                if (props.fctGotoPrevStep) {
                    e.stopPropagation();
                    props.jumpToStep(props.fctGotoPrevStep(thisStep));
                }
            };
        }
    }, [JSON.stringify(gstate.objEdit)]);


    const handleChange = (event) => {
        setObjErrors({ fac_extern_id: null });
        setExternId(event.target.value);
    };


    const handlePortalChange = (event) => {
        setSelectedPortal(event.target.value);
		setExternId(''); // Reset the expose ID when portal selection changes
    };


	const validateExternId = (externId, selectedPortal) => {
		
		if (!externId || externId.trim() === '') {
			return 'Bitte gib eine Portal-ID ein oder wähle "manuell eingeben" aus.';
		}
		
		let idPattern;
		let errorMessage;
		switch (selectedPortal) {
			case 'scrapfly.is24':
				idPattern = /^[0-9]+$/;
				errorMessage = 'Du musst eine gültige Immoscout24 ID eingeben (nur Zahlen)!';
				break;
			case 'scrapfly.immowelt':
				idPattern = /^[a-zA-Z0-9]+$/;
				errorMessage = 'Du musst eine gültige Portal ID eingeben (Buchstaben und Zahlen)!';
				break;
			case 'scrapfly.immonet':
				idPattern = /^[a-zA-Z0-9-]+$/;
				errorMessage = 'Du musst eine gültige Portal ID eingeben (Buchstaben, Zahlen und Bindestriche)!';
				break;
			default:
				idPattern = /^[a-zA-Z0-9-]+$/; // Default pattern, can be adjusted based on requirements
				errorMessage = 'Du musst eine gültige Portal ID eingeben!';
		}
		return idPattern.test(externId) ? null : errorMessage;
	};


	const validate = (e) => {
		let errs = false;
		const validationErrors = [];

		// Validation logic for portal ID if present
		if (!externId || (externId === '' && !gstate.objEdit.fac_extern_id)) {
			errs = true;
			validationErrors.push('Bitte gib eine Portal-ID ein oder wähle "manuell eingeben" aus.');
			setIsExposeIdValidated(false); // Reset validation status
		} else if (externId === 'manuell eingeben') {
			// Check if the Expose ID is set to "manuell eingeben"
			setIsExposeIdValidated(true); // Set validation status
		} else {
			const errorMessage = validateExternId(externId, selectedPortal);
			if (errorMessage) {
				validationErrors.push(errorMessage);
				gstate.objErrors.fac_extern_id = errorMessage;
				setIsExposeIdValidated(false); // Reset validation status
			} else {
				setIsExposeIdValidated(true); // Set validation status
			}
		}

		if (validationErrors.length > 0) {
			errs = true;
			setValidationErrors(validationErrors);
		}

		return errs;
	};


	const searchExternId = (event) => {
		if (!validateExternId( externId, selectedPortal)) {
			setIsLoading(true);
			const authToken = localStorage.getItem('AuthToken');
			axios.defaults.headers.common = { Authorization: `${authToken}` };
			const url = `/api/facExternByPortalId?portal=${selectedPortal}&externId=${externId}`;

			axios.get(url)
				.then((response) => {
					setObjEdit({
						...gstate.objEdit,
						fac_extern_id: externId,
						...response.data.data
					});
					setIsExposeLoaded( true);
					setIsLoading(false);
				})
				.catch((err) => {
					setObjErrors({ fac_extern_id: 'Die eingegebene ID konnte nicht aufgelöst werden!' });
					setObjEdit({ ...gstate.objEdit, fac_extern_id: '' });
					setIsLoading(false);
				});
		} else {
			// Show validation error
			setValidationErrors(['Bitte gib eine gültige Portal-ID ein.']);
		}
	};


	useEffect(() => {
		const nextBtn = document.getElementById('next-button');
		if (thisStep === gstate.objEdit.step) {
			nextBtn.onclick = (e) => {
				if (validate(e)) {
					e.stopPropagation();
				}
			};
		}
	}, [JSON.stringify(gstate.objEdit), externId, selectedPortal]);

		
	// Button click handler for "manuell eingeben"
    const handleManualInput = () => {
        setValidationErrors([]);
		setExternId('');
		setSelectedPortal('');		
        props.jumpToStep( props.fctGotoNextStep( thisStep));
    };	
	

    const getHint = () => {
        switch (selectedPortal) {
            case 'scrapfly.is24':
                return `Du findest die Expose-ID direkt in der Browseradresse des Exposes, unmittelbar nach <b>https://www.immobilienscout24.de/expose/</b>. <br/><br/>Zum Immobileinscout24 Deutschland Portal gelangst du hier: <strong><a href="https://www.immobilienscout24.de" target="_blank">Link</a></strong>.`;
            case 'scrapfly.immowelt':
                return `Du findest die Expose-ID direkt in der Browseradresse des Exposes, unmittelbar nach <b>https://www.immowelt.de/expose/</b>. <br/><br/>Zum Immowelt Deutschland Portal gelangst du hier: <strong><a href="https://www.immowelt.de" target="_blank">Link</a></strong>.`;
            case 'scrapfly.immonet':
                return `Du findest die Expose-ID direkt in der Browseradresse des Exposes, unmittelbar nach <b>https://www.immonet.de/expose/</b>. <br/><br/>Zum Immonet Deutschland Portal gelangst du hier:  <strong><a href="https://www.immonet.de" target="_blank">Link</a></strong>.`;
            default:
                return '';
        }
    };

    return (
        <div className="form-group">
            <h1 className="title2">Erfassung</h1>
            <Typography>Du kannst den Prozess der Immobilienerfassung beschleunigen. Propertec extrahiert die wichtigsten Daten für dich direkt aus Online-Portalen. Dafür benötigst du lediglich die Immobilien-ID.</Typography>
            <br/>
            <Grid container className={classes.fieldsBox} direction="row" spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth className={classes.formControl}>
                        <InputLabel>Internet-Portal auswählen</InputLabel>
                        <Select
                            value={selectedPortal}
                            onChange={handlePortalChange}
                            label="Internet-Portal auswählen"
                        >
                            <MenuItem value="scrapfly.immowelt">Immowelt</MenuItem>                      
                            <MenuItem value="scrapfly.is24">Immobilienscout24</MenuItem>
                            <MenuItem value="scrapfly.immonet">Immonet</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        placeholder="z.B. 129279160"
                        variant="outlined"
                        required
                        fullWidth
                        autoComplete='off'
                        id="facExternId"
                        label={`Expose-ID`}
                        name="externId"
                        value={externId}
                        helperText={gstate.objErrors.fac_extern_id}
                        error={gstate.objErrors.fac_extern_id ? true : false}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={2} className={classes.buttonWrapper}>
                    <QeeColorButtonPrimary
                        variant="contained"
                        onClick={searchExternId}
                        style={{ textTransform: "none", padding: 15, marginLeft: 10 }}
                    >
                        {isLoading ? <CircularProgress className={classes.whiteLoader} /> : <><FindInPageIcon />{gstate.isXS || gstate.isSM ? '' : 'Importieren'}</>}
                    </QeeColorButtonPrimary>
                </Grid>
            </Grid>
            {validationErrors[0] && (
                <div style={{ textAlign: "center" }}>
                    <small id="selError" className="form-text error">{validationErrors[0]}</small>
                </div>
            )}
            {gstate.objEdit.fac_extern_id ? (
                <div style={{ marginBottom: 40 }}>
                    <h2 className="title2" style={{ marginBottom: 40 }}>Dein ausgewähltes Inserat</h2>
                    <FacCard fac={gstate.objEdit} />
                </div>
            ) : (
                <QeeHintBox type="info" text={<React.Fragment><strong>Tipp:</strong> <span dangerouslySetInnerHTML={{ __html: getHint() }}></span></React.Fragment>} style={{ marginTop: 40 }} />
            )}
			{!isExposeLoaded && (
				<Grid container justifyContent="flex-end" style={{ marginTop: '10px' }}>
					<QeeColorButtonPrimary
						variant="contained"
						onClick={handleManualInput}
						style={{ textTransform: "none", padding: 15, marginLeft: 10 }}
					>
						{!gstate.isXS && <ArrowCircleRightIcon/>}
						{'...oder manuell eingeben'}
					</QeeColorButtonPrimary>
				</Grid>
			)}
        </div>
    );
};

export default FacPortalId;
