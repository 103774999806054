// fac.js

import React, { Component } from 'react';
import react, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles }  from '@material-ui/core/styles';
import axios from 'axios';
import queryString from 'query-string';

import ResponsiveAppBar from '../components/appbar';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NotesIcon from '@material-ui/icons/Notes';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AccountCircle, GridOnOutlined, Logout } from "@material-ui/icons";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import SellIcon from '@mui/icons-material/Sell';
import ForestIcon from '@mui/icons-material/Forest';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Groups3Icon from '@mui/icons-material/Groups3';
import Badge from '@mui/material/Badge';
import ChatIcon from '@mui/icons-material/Chat';
import ChecklistIcon from '@mui/icons-material/Checklist';

import FacDashboard from '../components/facDashboard';
import FacValuation from '../components/facValuation';
import FacNewbuild from '../components/facNewbuild';
import FacEnergyIst from '../components/facEnergyIst';
import FacEnergy from '../components/facEnergy';
import FacShop from '../components/qeeShop';
import FacFinancing from '../components/facFinancing';
import FacTaxonomy from '../components/facTaxonomy';
import FacInsurance from '../components/facInsurance';
import FacDocs from '../components/facDocs';
import FacOrgsSupplier from '../components/facOrgsSupplier';
import FacRenovations from '../components/facRenovations';
import { usePromotion } from "../components/qeePromotionProvider"; 
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import { useLicense } from "../components/qeeUsrLicenseProvider"; 
import QeeNavigator from '../context/navigator';

import { useNavigate } from "react-router-dom";

import { authMiddleWare } from '../util/auth'
import { useAuth } from "../context/provider";
import { checkLicense } from '../util/auth';


const styles = (theme) => ({
	root: {
		display: 'flex'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	toolbar: theme.mixins.toolbar,
});


const Fac = ( props) => {

	const { loadChats, loadSacs, loadAccount } = QeeNavigator();
	
	const { setOpenPromotionDialog } = usePromotion();									
	const { setOpenConfluenceDialog } = useConfluence();
	const { setOpenLicenseDialog } = useLicense();	
	
	const { gstate, setCurrPage, setCurrSubpage, setObjEdit, setObjErrors, setIsLoading } = useAuth();				
		
	const [state, setState] = useState({
		uiLoading: false,   // must be true
	});	


	const useStyles = makeStyles({
		...props,	
		listItem: {
			color: '#788',
			fontWeight: 600,
			fontSize: 16
		},
	});	
	const classes = useStyles( props);		

	
	const [windowDimension, detectHW] = useState({
		winWidth: window.innerWidth,
		winHeight: window.innerHeight,
	})	
	const navigate = useNavigate();
	//const { classes } = props;
	
	const detectSize = () => {
		detectHW({
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		})
	}	

	
	const loadFac = async () => {
		
		console.log( '--> loadFac');
		
		const authToken = localStorage.getItem( 'AuthToken');
		if( authToken == null) navigate( '/home');
		
		setIsLoading( true);
		
		setCurrSubpage( 'loadFacDashborad');
		
		const myURL = new URL( window.location);
		const id = myURL.searchParams.get( 'id');
		var sbpg = myURL.hash;
		if( sbpg)
			sbpg = sbpg.replace( '#', '');
		if( sbpg == '')
			sbpg = 'loadFacDashboard';
		
		var options = {
			url: `/api/fac/${id}`,
			method: 'get',
		};		
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios(options)
			.then((data) => {
				var fac = data.data;
				console.log( 'data', fac);
				fac.step = 0;
				setObjEdit( fac);
				setCurrSubpage( sbpg);
				setIsLoading( false);
				
				//setOpenPromotionDialog();
				
				console.log( '<-- loadFac');				
			})
			.catch((error) => {
				setState({ 
					...state,
					open: true, 
					errors: error.response.data 
				});
				setObjErrors( error.response.data);
				console.log(error);
				//setCurrSubpage( sbpg);				
				setIsLoading( false);				
				
				// navigate to home, there will auto-login again
				window.location.href = window.location.origin + '/home';				
				
				console.log( '<-- loadFac ERR', error);								
			});
	};
	
	
	useEffect( () => {	
		
		//if( !gstate.objEdit.id) {
			loadFac();
		//}

	}, []);	
	
	
	useEffect( () => {	

		console.log( 'Fac page use effect for', gstate.currSubpage);

		/*
		const parsed = queryString.parse( window.location.search);
		const parsedHash = queryString.parse( window.location.hash);
		parsed.currSubpage = gstate.currSubpage;
		const stringified = queryString.stringify( parsed);
		*/

		if( gstate.currSubpage == 'loadFacNewbuild')
			loadFacNewbuild();	
		if( gstate.currSubpage == 'loadFacEnergyIst')
			loadFacEnergyIst();		
		if( gstate.currSubpage == 'loadFacEnergy')
			loadFacEnergy();
		if( gstate.currSubpage == 'loadFacDashboard')
			loadFacDashboard();	
		if( gstate.currSubpage == 'loadFacValuation')
			loadFacValuation();			
		if( gstate.currSubpage == 'loadFacShop')
			loadFacShop();									
		if( gstate.currSubpage == 'loadFacFinancing')
			loadFacFinancing();							
		if( gstate.currSubpage == 'loadFacTaxonomy')
			loadFacTaxonomy();									
		if( gstate.currSubpage == 'loadFacInsurance')
			loadFacInsurance();							
		if( gstate.currSubpage == 'loadFacDocs')
			loadFacDocs();									
		if( gstate.currSubpage == 'loadFacOrgsSupplier')
			loadFacOrgsSupplier();											

		var myURL = new URL( window.location);
		myURL.hash = gstate.currSubpage;	
		window.history.replaceState( 'data-pass', 'title', myURL);		
				
	}, [JSON.stringify( gstate.currSubpage)]);		
	
		
	// fac actions	
	const showConfluenceHome = (event) => {
		setOpenConfluenceDialog();
	};		
	const logoutHandler = (event) => {
		localStorage.removeItem( 'AuthToken');
		navigate( '/login');
	};	
	

	// page actions
	const loadBuyPage = (event) => {
		console.log( 'loadBuyPage');
		navigate( '/buy');		
	};		
	const loadOwnPage = (event) => {
		console.log( 'loadOwnPage');
		navigate( '/own');				
	};		
	const loadSellPage = (event) => {
		console.log( 'loadSellPage');
		navigate( '/sell');				
	};		

		
	// subpage actions
	const switchSubpage = () => {
		console.log( 'switchSubpage render', state.render)		
		switch( state.render) {
			case 'FacDashboard':  	return <FacDashboard  subpageEnergy="loadFacEnergy"/>;						
			case 'FacValuation':  	return <FacValuation subpageEnergy="loadFacEnergy" subpageFinancing="loadFacFinancing" subpageInsurance="loadFacInsurance"/>;									
			case 'FacNewbuild':  	return <FacNewbuild subpageNewbuild="loadFacNewbuild"/>;
			case 'FacEnergyIst':  	return <FacEnergyIst subpageEnergy="loadFacEnergy"/>;			
			case 'FacEnergy':  		return <FacEnergy subpageEnergy="loadFacEnergy"/>;									
			case 'FacShop':  		return <FacShop />;																		
			case 'FacFinancing':  	return <FacFinancing />;															
			case 'FacTaxonomy':  	return <FacTaxonomy />;																		
			case 'FacInsurance':  	return <FacInsurance />;																		
			case 'FacDocs':  		return <FacDocs />;																					
			case 'FacOrgsSupplier': return <FacOrgsSupplier />;																								
			case 'FacRenovations':  return <FacRenovations />;															
			default: 
				return <FacDashboard subpageEnergy="loadFacEnergy"/>; 
		}
	}	
	const loadHome = (event) => {
		navigate( '/home');
	};			
	const loadFacDashboard = (event) => {
		var sbpg = 'FacDashboard';
		setCurrSubpage( 'load'+sbpg);
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};		
	const loadFacValuation = (event) => {
		var sbpg = 'FacValuation';
		setCurrSubpage( 'load'+sbpg);		
		setState({ render: sbpg });
		window.scrollTo(0, 0);		
	};	
	const loadFacNewbuild = (event) => {
		var sbpg = 'FacNewbuild';
		setCurrSubpage( 'load'+sbpg);		
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};	
	const loadFacEnergyIst = (event) => {
		var sbpg = 'FacEnergyIst';
		setCurrSubpage( 'load'+sbpg);		
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};	
	const loadFacEnergy = (event) => {
		var sbpg = 'FacEnergy';
		setCurrSubpage( 'load'+sbpg);		
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};	
	const loadFacShop = (event) => {
		var sbpg = 'FacShop';
		setCurrSubpage( 'load'+sbpg);		
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};		
	const loadFacFinancing = (event) => {
		var sbpg = 'FacFinancing';
		setCurrSubpage( 'load'+sbpg);		
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};	
	const loadFacTaxonomy = (event) => {
		var sbpg = 'FacTaxonomy';
		setCurrSubpage( 'load'+sbpg);		
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};				
	const loadFacInsurance = (event) => {
		var sbpg = 'FacInsurance';
		setCurrSubpage( 'load'+sbpg);		
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};		

	const loadFacDocs = (event) => {
		var sbpg = 'FacDocs';
		setCurrSubpage( 'load'+sbpg);		
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};			
	
	const loadFacOrgsSupplier = (event) => {
		var sbpg = 'FacOrgsSupplier';
		setCurrSubpage( 'load'+sbpg);		
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};				
	
	const loadFacRenovations = (event) => {
		var sbpg = 'FacRenovations';
		setCurrSubpage( 'load'+sbpg);				
		setState({ render: sbpg });
		window.scrollTo(0, 0);				
	};			
		
	
    useEffect( () => {			
		const authToken = localStorage.getItem( 'AuthToken');
		if( authToken == null) navigate( '/home');	

		state.uiLoading = false;		
    }, []);	
	
	
	const drawer = () => {		
		return(
			<>
			<div className={classes.toolbarxxx} />
			
			<List>
				<ListItem button key="Home" onClick={loadHome}>
					<ListItemIcon>
						{' '}
						<HomeIcon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Home</Typography>} />
				</ListItem>			

				<Divider />							
			
				<ListItem button key="FacDashboard" onClick={loadFacDashboard}>
					<ListItemIcon>
						{' '}
						<ViewComfyIcon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Überblick</Typography>} />	
				</ListItem>						

				{(gstate.objEdit.fac_prj_type && gstate.objEdit.fac_prj_type === 'newbuild') ?
				<ListItem button key="FacNewbuild" onClick={loadFacNewbuild}>
					<ListItemIcon>
						{' '}
						<ViewInArIcon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Neubau</Typography>} />													
				</ListItem>	
				:''}

				{(gstate.objEdit.fac_prj_type && gstate.objEdit.fac_prj_type === 'renovation') ?
				<>
				<ListItem button key="FacEnergyIst" onClick={loadFacEnergyIst}>
					<ListItemIcon>
						{' '}
						<QueryStatsIcon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Energetischer Zustand</Typography>} />													
				</ListItem>	
								
				<ListItem button key="FacEnergy" onClick={loadFacEnergy}>
					<ListItemIcon>
						{' '}
						<EnergySavingsLeafIcon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Sanierung</Typography>} />													
				</ListItem>
				</>
				:''}				

				<ListItem button key="FacValuation" onClick={checkLicense(gstate.usr.lic) === 'insufficient' ? () => setOpenLicenseDialog(true) : loadFacValuation}>
					<ListItemIcon>
						<EuroSymbolIcon />
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={						
							checkLicense(gstate.usr.lic) === 'insufficient' ?
								<Badge badgeContent={'UPGRADE'} color="license">
									<Typography variant="body2" className={classes.listItem}>
										Wertermittlung
									</Typography>
								</Badge>							
							: 
								<Typography variant="body2" className={classes.listItem}>
									Wertermittlung
								</Typography>
						} 
					/>								
				</ListItem>				
				
				<Divider />															
				
				<ListItem button key="FacShop" onClick={loadFacShop}>
					<ListItemIcon>
						{' '}
						<SellIcon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Shop</Typography>} />												
				</ListItem>																				
				
				<Divider />											
				
				{/*
				{gstate.isBetaVer ?
				<ListItem button key="FacFinancing" onClick={loadFacFinancing}>
					<ListItemIcon>
						{' '}
						<AccountBalanceIcon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Finanzierungen</Typography>} />
				</ListItem>	
				: ''}
				
				<ListItem button key="FacTaxonomy" onClick={loadFacTaxonomy}>
					<ListItemIcon>
						{' '}
						<ForestIcon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Taxonomie</Typography>} />
				</ListItem>																												
				
				<ListItem button key="FacInsurance" onClick={loadFacInsurance}>
					<ListItemIcon>
						{' '}
						<BeachAccessIcon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Versicherungen</Typography>} />
				</ListItem>		
				
				<Divider />															
				*/}
				
				<ListItem button key="FacDocs" onClick={loadFacDocs}>
					<ListItemIcon>
						{' '}
						<TextSnippetIcon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Dokumente</Typography>} />
				</ListItem>

				<Divider />		
				<ListItem button key="FacOrgsSupplier" onClick={loadFacOrgsSupplier}>
					<ListItemIcon>
						{' '}
						<Groups3Icon />{' '}
					</ListItemIcon>
					<ListItemText 
						disableTypography
						primary={<Typography variant="body2" className={classes.listItem}>Dienstleister</Typography>} />
				</ListItem>																																

			</List>
			</>
		);
	}
	
	
	return (	
		state.uiLoading ? (
			<div className={classes.root}>
				{state.uiLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (
			<div>
				<ResponsiveAppBar 
					className={classes.appBar} 
					drawer={drawer}
					switchSubpage={switchSubpage}					
					pages={[/*
						{ nm:'Kaufen',    fct:loadBuyPage},
						{ nm:'Wohnen',    fct:loadOwnPage},
						{ nm:'Verkaufen', fct:loadSellPage}						
						*/
					]}
					settings={[
						{ nm:'Mein Konto', 			fct: loadAccount, 		ico:<AccountBoxIcon />},
						{ nm:'Nachrichten', 		fct: loadChats, 		ico:<ChatIcon />},												
						{ nm:'Aktivitäten', 		fct: loadSacs, 			ico:<ChecklistIcon />},						
						/*{ nm:'Dashboard', 			fct: loadSacs,       	ico:<NotesIcon />},*/
						{ nm:'Wissensassistent',	fct: showConfluenceHome, ico:<HelpOutlinedIcon />},						
						{ nm:'Logout', 	  			fct: logoutHandler,  	ico:<ExitToAppIcon />}
					]}					
				/>
			</div>		
		)	
	);
	
}
export default withStyles(styles)(Fac);

