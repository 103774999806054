import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/app-logo.png';
import { useAuth } from '../context/provider';
import { getTpls } from '../api/service';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: 70,
		padding: 40,
		textAlign: 'center',
		height: 800,
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},  
}));

const VersionInfo = (props) => {
	const classes = useStyles();
	const { gstate } = useAuth();			
	const [template, setTemplate] = useState('');
	const [uiLoading, setUiLoading] = useState( true);

	useEffect(() => {
		fetchTemplate();
	}, []);

	const fetchTemplate = async () => {
		try {
			const tplName = 'qeeVersionSite.hbs'; 
			var lv = localStorage.getItem('ver');
			if( !lv)
				lv = '-'
			const data = {'qeeversion':lv};
			const templateContent = await getTpls( tplName, data);
			setTemplate( templateContent);
		} catch (error) {
			console.error('Error fetching the template:', error);
			// Handle error (e.g., display an error message)
		} finally {
			setUiLoading( false);
		}
	};

	return (
		<div className={classes.container}>
			{uiLoading ? (
				<div className={classes.root}>
					{uiLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
				</div>		
			) : (	
				<>
					<img src={logo} alt="App Logo" style={{ maxHeight: 36, marginBottom: 48 }} />
					<div dangerouslySetInnerHTML={{ __html: template }} />
				</>
			)}
		</div>
	);
};

export default VersionInfo;
