// signup.js

import React, { Component } from 'react';
import react, { useState, useEffect, useLayoutEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate } from "react-router-dom";
import LoginSocialButtons from '../components/qeeLoginSocialButtons';
import Footer from '../components/footer';
import logo from '../assets/app-logo.png';
import backgroundImg from "../assets/house-landscape.jpg";

import { useAuth } from "../context/provider";

import axios from 'axios';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		borderRadius: theme.spacing(1),		
	},
	progess: {
		position: 'absolute'
	}
});


const Signup = ( props) => {
	
	const { gstate } = useAuth();			
	
	const [state, setState] = useState({
		per_firstname: '',
		per_lastname: '',
		//cch_phone_o: '',
		//addr_country_o: '',
		username: '',
		cch_email_o: '',
		password: '',
		confirmPassword: '',
		errors: [],
		loading: false,
		isMDUpper: false,
		width: window.innerWidth,
		doneSignup: false	
	});
	const { classes } = props;
	const [errors, setErrors] = useState( state.errors);
	//const [loading, setLoading] = useState( state.loading);		
	const [firstname, setFirstname] = useState( '');	
	const [lastname, setLastname] = useState( '');		
	const [username, setUsername] = useState( '');		
	const [email, setEmail] = useState( '');			
	const [password, setPassword] = useState( '');				
	const [confirmPassword, setConfirmPassword] = useState( '');					
		
	const navigate = useNavigate();						
	

	function handleWindowSizeChange() {
		setState({
			...state,
			width: window.innerWidth
		});
	}		
	useEffect( () => {	

		document.title = process.env.QEE_APP_NAME;    //'Vreed.INSIGHT';
	
		setState( {
			...state,
			isMDUpper: state.width>=1536 ? true : false
		});
		console.log( 'state', state);				
		
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
												
	}, [state.width]);			
	
	/*
	const handleChange = (event) => {
		setState({
			...state,
			loading: false,
			errors: [],
			[event.target.name]: event.target.value
		});
	};
	*/	
	
	const handleChangeFirstname = (event) => {
		setFirstname( event.target.value);
		setState({
			...state,					
			errors: []
		});				
	};		
	const handleChangeLastname = (event) => {
		setLastname( event.target.value);
		setState({
			...state,					
			errors: []
		});				
	};		
	const handleChangeUsername = (event) => {
		setUsername( event.target.value);
		setState({
			...state,					
			errors: []
		});				
	};		
	const handleChangeEmail = (event) => {
		setEmail( event.target.value);
		setState({
			...state,					
			errors: []
		});		
	};	
	const handleChangePassword = (event) => {
		setPassword( event.target.value);
		setState({
			...state,					
			errors: []
		});				
	};	
	const handleChangeConfirmPassword = (event) => {
		setConfirmPassword( event.target.value);
		setState({
			...state,					
			errors: []
		});				
	};
	
	const decryptError = (msg) => {
		var ret ='Interner Serverfehler!';
		if( msg.indexOf( '400') > 0)
			ret = 'Diese Email Adresse wurde schon benutzt!';
		else if( msg.indexOf( '500') > 0)
			ret = 'Passwort zu schwach oder Passwort und bestätigtes Passwort stimmen nicht überein!';			
		return ret;
	};	
	
	
	const handleSubmit = (event) => {
		event.preventDefault();
		setState({ 
			...state,
			loading: true 
		});

		const newUserData = {
			per_firstname: 		firstname,
			per_lastname: 		lastname,
			/* cch_phone_o: 		state.cch_phone_o, */
			/* addr_country_o: 	state.addr_country_o, */
			username: 			username,
			cch_email_o: 		email,
			password: 			password,
			confirmPassword: 	confirmPassword
		};
		axios
			.post( '/api/usr/signup', newUserData)
			.then((response) => {
				console.log( 'Signup response', response);
				localStorage.setItem( 'AuthToken', `${response.data.token}`);
				setState({ 
					...state,
					loading: false,
					doneSignup: true
				});	
				//this.props.history.push('/');
				//const navigate = useNavigate();				
				//navigate( '/home');
			})
			.catch((error) => {
				console.log( 'Signup error', error);				
				var resp = {};
				resp.respError = error;
				setState({
					...state,					
					//errors: error.response.data,
					errors: resp,
					loading: false
				});
				//setErrors( error.response.data);
			});
	};
	
	
/*
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="cch_phone_o"
								label="Telefon"
								name="cch_phone_o"
								autoComplete="cch_phone_o"
								pattern="[7-9]{1}[0-9]{9}"
								helperText={errors.cch_phone_o}
								error={errors.cch_phone_o ? true : false}
								onChange={handleChange}
							/>
						</Grid>
						
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="addr_country_o"
								label="Bundesland"
								name="addr_country_o"
								autoComplete="addr_country_o"
								helperText={errors.addr_country_o}
								error={errors.addr_country_o ? true : false}
								onChange={handleChange}
							/>
						</Grid>
						

*/
	
	
	return (	
	
		<Grid container direction="row">	
	
		<Grid item style={{width: state.isMDUpper ? '70%' : '0%'}}>
		
			<div style={{
				backgroundColor:'#FBFDFC',				
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				width: '100%',
				height: '100vh',												
				backgroundImage:`url(${backgroundImg})`
			}} />
		
		</Grid>
		
		<Grid item style={{width: state.isMDUpper ? '30%' : '100%'}}>			
	
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			{!state.doneSignup ?
			<div className={classes.paper}>
				
				<img src={logo} alt='App Logo' style={{maxHeight:36,marginBottom:8}}/>										
				<Typography component="h3" variant="h3">
					{process.env.QEE_APP_NAME}
				</Typography>										
				<Typography component="h4" variant="h4">
					Registrierung
				</Typography>
				
				<form className={classes.form} noValidate>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="firstname"
								label="Vorname"
								name="firstname"
								autoComplete="firstname"
								helperText={state.errors.firstname}
								error={state.errors.firstname ? true : false}
								onChange={handleChangeFirstname}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="lastname"
								label="Nachname"
								name="lastname"
								autoComplete="lastname"
								helperText={state.errors.lastname}
								error={state.errors.lastname ? true : false}
								onChange={handleChangeLastname}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="username"
								label="Benutzername"
								name="username"
								autoComplete="username"
								helperText={state.errors.username}
								error={state.errors.username ? true : false}
								onChange={handleChangeUsername}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="email"
								label="Email Addresse"
								name="email"
								autoComplete="email"
								helperText={state.errors.email}
								error={state.errors.email ? true : false}
								onChange={handleChangeEmail}
							/>
						</Grid>
						
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								name="password"
								label="Passwort"
								type="password"
								id="password"
								autoComplete="current-password"
								helperText={state.errors.password}
								error={state.errors.password ? true : false}
								onChange={handleChangePassword}
							/>
						</Grid>
						
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								name="confirmPassword"
								label="Passwort bestätigen"
								type="password"
								id="confirmPassword"
								autoComplete="current-password"
								onChange={handleChangeConfirmPassword}
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick={handleSubmit}
						disabled={state.loading || 
							email=='' ||
							password=='' ||
							confirmPassword=='' ||
							firstname=='' ||
							lastname=='' ||
							username=='' ||
							password!=confirmPassword}
					>
						Registrieren
						{state.loading && <CircularProgress size={30} className={classes.progess} />}
					</Button>
										
					{state.errors.respError ?
						<div style={{color:'#f00', marginTop:20, marginBottom:20}}>Serverfehler: {decryptError( state.errors.respError.message)}</div>
						:''
					}
					
					<Grid container direction="column" justify="flex-end" spacing={5}>
						<Grid item>
							<LoginSocialButtons />							
						</Grid>					
						<Grid item>
							<Link href="login" variant="body2">
								Schon registriert? Einloggen
							</Link>
						</Grid>
						<Grid item>
							<Link href="https://qalgo.shop/pages/private-policy" target="blank" variant="body2">
								{"Datenschutzerklärung"}
							</Link>
						</Grid>													
					</Grid>
				</form>
			</div> 
			:
			<div>
				<div className={classes.paper}>
				
				<img src={logo} alt='App Logo' style={{maxHeight:36,marginBottom:48}}/>					
				
				<Typography component="h1" variant="h5">
					Vielen Dank!
				</Typography>
				<br/><br/>
				<Typography>
					Wir haben dir eine E-Mail mit einem Aktivierungslink geschickt. Bestätige bitte den Link in der E-Mail um dich anzumelden!
				</Typography>
				<br/><br/>
				<Typography>
					Solltest du keine E-Mail erhalten haben, prüfe bitte deinen Spamordner.
				</Typography>
				
				</div>	
			</div>
			}
			<Footer left={state.isMDUpper ? '70%' : '0'} width={state.isMDUpper ? '30%' : '100%'}/>													
		</Container>
		</Grid>
		</Grid>		
	);
	
}
export default withStyles(styles)(Signup);