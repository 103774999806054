import React, { useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import QeeChatEmbedded from './qeeChatEmbedded';
import { markChatRead } from '../api/service';

const UsrChat = ({ chat, onChatUpdate }) => {
    const theme = useTheme(); 
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        const usr = JSON.parse(sessionStorage.getItem('usr'));
        if (!usr) {
            console.error('No user data!');
            return;
        }

        // Mark the chat as read
        const markRead = async () => {
            let ret = await markChatRead(usr.usrId, chat.id);
			if( !ret.err) {
				if (onChatUpdate) {
					onChatUpdate();
				}
			}
        };

        markRead();
    }, [chat, onChatUpdate]);

    // Decode the chat_fac_id
    const [form, parentId] = chat.chat_fac_id.split('-');

    // Determine props based on form type
    const commonProps = {
        instanceName: `qeeleaapp-chat-${form.toLowerCase()}`,
        isP2P: chat.isP2P,
        showDateTime: true,
        showDate: true,
        showTime: true,
        height: isXs ? "calc(100svh - 60px)" : "90vh",
        style: { height: isXs ? "calc(100svh - 60px)" : "90vh" }
    };

    const parentProps = form !== 'SESS' ? { parentForm: form, parentId } : {};

    return (
        <QeeChatEmbedded 
            {...commonProps}
            {...parentProps}
        />
    );
};

export default UsrChat;
