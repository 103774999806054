// QeeToolsMenu
import * as React from 'react';
import ReactDOM from 'react-dom';
import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import { makeStyles }  from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import { Divider, ListItemIcon } from "@material-ui/core";

import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { pdf }  from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Page, Text, View, Document, StyleSheet, BlobProvider } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ConstructionIcon from '@mui/icons-material/Construction';
import CalculateIcon from '@mui/icons-material/Calculate';

import axios from 'axios';
import moment from 'moment';
import fileDownload from 'js-file-download';

import QeeSlideDialog from './qeeSlideDialog';
import QeeGmap from './qeeGmap';
import QeeSelectRecipients from './qeeSelectRecipients';
import QeeOpenaiCompletions from './qeeOpenaiCompletions';
import QeeSnackbar from './qeeSnackbar';
import QeeTooltip from './qeeTooltip';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import FacNewbuildWizDialog from './facNewbuildWizDialog';
import FacRenovationWizDialog from './facRenovationWizDialog';
import FacValuationWizDialog from './facValuationWizDialog';
import { useLicense } from "./qeeUsrLicenseProvider"; 	
import { useChat } from "./qeeChatProvider"; 
import { useConfluence } from "./qeeConfluenceProvider"; 	
import { usrRight } from '../util/helpers';
import { getFacExternUrl } from '../util/helpers';

import { useAuth } from "../context/provider"; 
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken, checkLicense } from '../util/auth';
import { facSave, getFacChat } from "../api/service";


const QeeToolsMenu = (props) => {
	
	console.log( 'QeeToolsMenu props', props);
	
	const navigate = useNavigate();
	
	const { setOpenLicenseDialog } = useLicense();
	const { handleClickOpenChat, setOpenChatDialog } = useChat();		
	const { setOpenConfluenceDialog } = useConfluence();
	const refNewbuildDialog = useRef(null);	
	const refRenovationDialog = useRef(null);
	const refValuationDialog = useRef(null);	
	
	const { gstate, setObjEdit, setObjErrors, setOpenQeeDialog, setQeeSnackbar } = useAuth();					
	
	const { enqueueSnackbar } = useSnackbar();	

	const [hasErrorsDlgOpenai, setHasErrorsDlgOpenai] = useState( true);				
	const [changesDlgOpenai, setChangesDlgOpenai] = useState( {});	
	
	const [hasErrorsDlgReportShare, setHasErrorsDlgReportShare] = useState( true);				
	const [changesDlgReportShare, setChangesDlgReportShare] = useState( {});	

	const [addr, setAddr] = useState({addr_gvalue_o: gstate.objEdit.addr_gvalue_o,
										addr_gvalue_text_o: gstate.objEdit.addr_gvalue_o ? gstate.objEdit.addr_gvalue_o.label : '',
										addr_locality_o: gstate.objEdit.addr_locality_zip_o, 										
										addr_street_zip_o: gstate.objEdit.addr_street_zip_o, 
										addr_geo_lat_o: gstate.objEdit.addr_lat_o, 
										addr_geo_lon_o: gstate.objEdit.addr_lon_o});		
			
	// some consts	
	const useStyles = makeStyles({
		//...props,	
		button: {
			textTransform: 'none',
			height: 60
		},
		upgradeIcon: {
			color: '#9c27b0',
			fontSize: 32
		}	
	});	
	const classes = useStyles( props);	

		
	const handleLicensedFeature = ( fct, popupState) => { 							
		var tp = checkLicense( gstate.usr.lic);
		if( tp != 'insufficient') {
			fct();  // YES!
		} else {
			setOpenLicenseDialog( true);
			popupState.close();						
		}	
	}
	
	
	/**
	*	openLicenseDialog()  - opens license
	*/
	const openLicenseDialog = ( popupState) => {
		console.log( 'openLicenseDialog');
		setOpenLicenseDialog();
		popupState.close();						
	}
	
	
	
	/**
	*	getPdfDocument() - calls the server side
	*/
	const getPdfDocument = async () => {
		
		enqueueSnackbar( 'Der Auftrag wurde gestartet. Die Generierung kann einige Zeit in Anspruch nehmen. Den Bericht findest du in deinem Download-Korb.', {variant: 'default'});		
		
		//const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login')		
		var url = `/api/pdfFacReport/` + gstate.objEdit.id + '?mode=download';
		let opts = {
			url: url,
			method: 'get',
			responseType: 'blob'
		};
		console.log( 'axios', opts);
		//axios.defaults.headers.common = { Authorization: `${authToken}` };
		setAxiosAuthToken();
		try {
			const file = await axios( opts);			
			console.log( 'file', file);		

			const url = window.URL.createObjectURL(new Blob([file.data]));
			const link = document.createElement('a');
			link.href = url;
			var dt = moment(new Date()).format("YYYYMMDDHHMMSS");
			link.setAttribute('download', 'immorep_'+gstate.objEdit.id+'.pdf'); //or any other extension
			document.body.appendChild( link);
			link.click();			
			
			enqueueSnackbar( 'Der Bericht wurde heruntergeladen!', {variant: 'success'});					
									
		} catch( err) {
			//const err = error as AxiosError;
			if( err.response) {
				console.log( 'ERR status', err.response.status)
				console.log( 'ERR response', err.response)
			}
			console.log( 'getPdfDocument ERR', err);
			enqueueSnackbar( 'Der Bericht konnte nicht generiert werden. Fehler vom Server: ' + err, {variant: 'error'});					
		} 				
	};	


	/**
	*	sharePdfDocument() - calls the server side
	*/
	const sharePdfDocument = async ( emailData) => {
		
		enqueueSnackbar( 'Der Auftrag wurde gestartet...', {variant: 'default'});		
		
		const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login')		
		var url = `/api/pdfFacReport/` + gstate.objEdit.id + '?mode=share';
		let opts = {
			url: url,
			method: 'post',
			data: emailData
		};
		console.log( 'axios', opts);
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		try {
			const file = await axios( opts);			
			console.log( 'file', file);		
			
			enqueueSnackbar( 'Der Bericht wurde gesendet!', {variant: 'success'});					
									
		} catch( error) {
			console.log( 'sharePdfDocument ERR', error);
			enqueueSnackbar( 'Der Bericht konnte nicht gesendet werden. Fehler vom Server: ' + error, {variant: 'error'});					
		} 				
	};	
	
		
	/**
	*	openQeeGmapDialog()  - opens gmaps
	*/
	const openQeeGmapDialog = ( popupState) => {
		console.log( 'openQeeGmapDialog');
		setOpenQeeDialog( {open:true, id:'dlgGmap'});
		popupState.close();		
	}
	
	
	/**
	*	openAiDescDialog()  - opens openai generator
	*/
	const openAiDescDialog = ( popupState) => {
		console.log( 'openAiDescDialog');
		setOpenQeeDialog( {open:true, id:'dlgOpenaiDesc'});
		popupState.close();		
	}	
	
	
	/**
	*	openChatDialog()  - opens chat dialog
	*/
	const openChatDialog = async ( popupState) => {		
		console.log( 'openChatDialog');		
		handleClickOpenChat( gstate.objEdit.id);		
		popupState.close();		
	}		
		
	
	/**
	*	downloadPdfReport() -
	*/
	const downloadPdfReport = ( popupState) => {
		
		getPdfDocument( 'download');
		
		popupState.close();				
	}
	
	
	/**
	*	sharePdfReport() - 
	*/
	const sharePdfReport = ( popupState) => {
		
		setOpenQeeDialog( {open:true, id:'dlgReportShare'});
				
		popupState.close();				
	}	
	
	
	const handleAddressChange = ( oAddr) => {
		console.log( 'handleAddressChange SUBMITTING >>>>', oAddr);
		setAddr( oAddr);		
	}			
	const handleSubmitDlgGmap = async () => {
		console.log( 'handleSubmitDlgGmap');
		const authToken = localStorage.getItem( 'AuthToken');
		if( authToken == null) navigate( '/home');	
		
		const newFac = {
			...gstate.objEdit,
			...addr
		};
		console.log( 'submit obj', newFac);		
		//setObjEdit( newFac);		
				
		let options = {};
		let id = gstate.objEdit.id;
		options = {
			url: `/api/fac/${id}`,
			method: 'put',
			data: newFac
		};
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		try {
			var data = await axios(options);
			console.log( 'data', data.data);
			
			setObjEdit( data.data);			
			
			return true;						
		} catch( error) {
			setObjErrors( error.response.data);
			console.log(error);			
			return false;						
		}	
	}	
	
	
	const handleHasErrorsDlgOpenai = ( hasErrors) => {
		console.log( 'handleHasErrorsDlgOpenai hasErrors', hasErrors);
		setHasErrorsDlgOpenai( hasErrors);
	}					
	const handleChangesDlgOpenai = ( changes) => {
		//console.log( 'handleChangesDlgOpenai results', changes);
		setChangesDlgOpenai( changes);
	}	

	
	const handleHasErrorsDlgReportShare = ( hasErrors) => {
		console.log( 'handleHasErrorsDlgReportShare hasErrors', hasErrors);
		setHasErrorsDlgReportShare( hasErrors);
	}					
	const handleChangesDlgReportShare = ( changes) => {
		console.log( 'handleChangesDlgReportShare results', changes);
		setChangesDlgReportShare( changes);
	}							
	const handleSubmitDlgReportShare = () => {
		console.log( 'handleSubmitDlgReportShare SUBMITTING >>>>', changesDlgReportShare);
		sharePdfDocument( changesDlgReportShare);
		setOpenQeeDialog( {open:false, id:'dlgReportShare'});
	}				

	const handleNewbuildOpen = (popupState) => {		
		refNewbuildDialog.current.handleNewbuildOpen();
		popupState.close();		
	}	
	const handleRenovationOpen = (popupState) => {		
		refRenovationDialog.current.handleRenovationOpen();	
		popupState.close();			
	}		
	const handleValuationOpen = (popupState) => {		
		refValuationDialog.current.handleValuationOpen();		
		popupState.close();		
	}	
	
	const externUrlInfo = gstate.objEdit.fac_extern_id ? 
		getFacExternUrl( gstate.objEdit.fac_extern_portal, gstate.objEdit.fac_extern_id) :
		null;	
		
	return (
		<>
		<PopupState variant="popover" popupId="qeeToolsMenu">
			{(popupState) => (
				<React.Fragment>
				
					<>
					<QeeTooltip title="Erweiterungen, Tools und Apps">
					<QeeColorButtonPrimary disableElevation 
						className={classes.button} 
						{...bindTrigger(popupState)}>
							<QeeTooltip title="Erweiterungen, Tools und Apps">
							<Typography variant='button'><div>{gstate.isXS ? <AutoAwesomeIcon fontSize="large"  style={{paddingTop:5}} /> : 'Tools'}</div></Typography>
							</QeeTooltip>
					</QeeColorButtonPrimary>					
					</QeeTooltip>
					</>
					
					<Menu {...bindMenu(popupState)}>
						{checkLicense( gstate.usr.lic)=='insufficient' ?
						<>
						<MenuItem  onClick={() => openLicenseDialog( popupState)} >
							<ListItemIcon><UpgradeIcon fontSize="big" /></ListItemIcon>
							<Typography variant='button'>Konto Upgrade</Typography>
						</MenuItem>		
						<Divider />								
						</>
						:''}
						
						{usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?
							<>
							{!(gstate.objEdit.fac_prj_type && gstate.objEdit.fac_prj_type !== 'newbuild') ?
							<MenuItem  onClick={() => handleNewbuildOpen( popupState)} >
								<ListItemIcon><ViewInArIcon fontSize="small" /></ListItemIcon>
								<Typography>Neubauassistent</Typography>
							</MenuItem>	
							:''}
							{!(gstate.objEdit.fac_prj_type && gstate.objEdit.fac_prj_type !== 'renovation') ?
							<MenuItem  onClick={() => handleRenovationOpen( popupState)} >
								<ListItemIcon><ConstructionIcon fontSize="small" /></ListItemIcon>
								<Typography>Sanierungsassistent</Typography>
							</MenuItem>	
							:''}							
							<MenuItem onClick={() => handleLicensedFeature( (()=>handleValuationOpen( popupState)), popupState)}>
								<ListItemIcon><CalculateIcon fontSize="small" /></ListItemIcon>
								<Typography>Wertermittlungsassistent</Typography>
								{checkLicense( gstate.usr.lic)=='insufficient' ?
									<Tooltip title="Konto Upgrade erforderlich">
										<ListItemIcon><UpgradeIcon fontSize="big" className={classes.upgradeIcon}/></ListItemIcon>
									</Tooltip>	
								:''}							
							</MenuItem>								
							<Divider />
							</>
						:''}						
												
						<MenuItem onClick={() => openQeeGmapDialog(popupState)}>
							<ListItemIcon><RoomOutlinedIcon fontSize="small" /></ListItemIcon>
							<Typography>Karte</Typography>
						</MenuItem>
						{gstate.objEdit.fac_extern_id && externUrlInfo ? (
							<>
								<Divider />
								<MenuItem onClick={() => window.open(externUrlInfo.url, "_blank")}>
									<ListItemIcon><OpenInNewOutlinedIcon fontSize="small" /></ListItemIcon>
									<Typography>{externUrlInfo.name} Anzeige</Typography>
								</MenuItem>
							</>
						) : null}					
												
						<Divider />	
						<MenuItem  onClick={() => openChatDialog( popupState)} >
							<ListItemIcon><ForumOutlinedIcon><RoomOutlinedIcon fontSize="small" /></ForumOutlinedIcon></ListItemIcon>
							<Typography>Expertenchat</Typography>
						</MenuItem>	
						<MenuItem  onClick={() => openAiDescDialog( popupState)} >
							<ListItemIcon><ChatOutlinedIcon><RoomOutlinedIcon fontSize="small" /></ChatOutlinedIcon></ListItemIcon>
							<Typography>Textanzeige generieren</Typography>
						</MenuItem>	
						<Divider />						
						<MenuItem onClick={() => handleLicensedFeature( (()=>downloadPdfReport( popupState)), popupState)}>
							<ListItemIcon><CloudDownloadOutlinedIcon fontSize="small" /></ListItemIcon>
							<Typography>Bericht generieren</Typography>
							{checkLicense( gstate.usr.lic)=='insufficient' ?
								<Tooltip title="Konto Upgrade erforderlich">
									<ListItemIcon><UpgradeIcon fontSize="big" className={classes.upgradeIcon}/></ListItemIcon>
								</Tooltip>	
							:''}
						</MenuItem>												
						<MenuItem onClick={() => handleLicensedFeature( (()=>sharePdfReport( popupState)), popupState)}>
							<ListItemIcon><SendOutlinedIcon fontSize="small" /></ListItemIcon>
							<Typography>Bericht senden</Typography>
							{checkLicense( gstate.usr.lic)=='insufficient' ?
								<Tooltip title="Konto Upgrade erforderlich">
									<ListItemIcon><UpgradeIcon fontSize="big" className={classes.upgradeIcon}/></ListItemIcon>
								</Tooltip>	
							:''}							
						</MenuItem>												
					</Menu>
				</React.Fragment>
			)}
		</PopupState>
		
		<QeeSnackbar />
		
		<FacNewbuildWizDialog 
			ref={refNewbuildDialog} 
			subpageNewbuild="loadFacNewbuild"
			subpageEnergy="loadFacEnergy"
			subpageValuation="loadFacValuation"
		/>			
		
		<FacRenovationWizDialog 
			ref={refRenovationDialog} 
			subpageEnergy="loadFacNewbuild"
			subpageValuation="loadFacValuation"
		/>				

		<FacValuationWizDialog 
			ref={refValuationDialog} 
			subpageEnergy="loadFacEner"
			subpageValuation="loadFacValuation"
		/>				
		
		<QeeSlideDialog 
			id='dlgGmap'
			title='Geographische Position' 
			formComponent={
				<QeeGmap 
					showSearchBar={true}
					showResultBar={true}					
					gvalue={gstate.objEdit.addr_gvalue_o} 
					lat={gstate.objEdit.addr_geo_lat_o} 
					lon={gstate.objEdit.addr_geo_lon_o}
					fctOnAddressChange={handleAddressChange}
					marginTop={70}
				/>
			}	
			submitDisabled={false}			
			fctOnSubmit={handleSubmitDlgGmap}>
		</QeeSlideDialog>
		
		<QeeSlideDialog 
			id='dlgOpenaiDesc'
			title='Textanzeige generieren' 
			formComponent={
				<QeeOpenaiCompletions
					fctOnHasErrors={handleHasErrorsDlgOpenai}
					fctOnChanges={handleChangesDlgOpenai}
				/>				
			}	
			submitDisabled={hasErrorsDlgOpenai}			
			fctOnSubmit={async ()=>{
				var ret = await facSave( gstate.objEdit, {
					fac_desc_prompt_openai: changesDlgOpenai.prompt,			
					fac_desc_openai: changesDlgOpenai.text
				});
				if( !ret.err) {
					setObjEdit( ret);
					return true;
				}
			}}>
		</QeeSlideDialog>		
		
		<QeeSlideDialog 
			id='dlgReportShare'
			title='Bericht teilen' 
			formComponent={
				<QeeSelectRecipients
					label="Die ausgewählten Empfänger werden per E-Mail benachrichtigt und erhalten einen Link zum Herunterladen des Immobilienberichts."
					fctOnHasErrors={handleHasErrorsDlgReportShare}
					fctOnChanges={handleChangesDlgReportShare}
					roleValue="reader"
					roleDisabled={true}
				/>
			}	
			submitDisabled={hasErrorsDlgReportShare}
			fctOnSubmit={handleSubmitDlgReportShare}>
		</QeeSlideDialog>	
		</>	
	);
	
}
export default QeeToolsMenu;


