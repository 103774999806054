// customFunctions.js

import axios from 'axios';
import moment from 'moment';

import { 
	getSdatas, 
	getSdatasNet
} from '../api/service';


/**
*	usrSignUp() - 
*/
export const usrSignUp = async (firstname, lastname, username, email, pass, fac) => {

	console.log( '--> customFunctions.usrSignUp', {firstname, lastname, username, email, pass, fac});
		
	if( !username) {	
		username = email;
		console.log( 'WARN: No username, replacing with email!');
	}
			
	const newUserData = {
		per_firstname: 		firstname,
		per_lastname: 		lastname,
		username: 			username,
		cch_email_o: 		email,
		password: 			pass,
		confirmPassword: 	pass,
		fac:				fac   // optional, only for leads
	};
	
	var url = '/api/usr/signup';
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;
	}
	return await axios
		.post( url, newUserData)
		.then(( resp) => {
			console.log( 'customFunctions.signup response', resp);
			console.log( '<-- customFunctions.usrSignUp');					
			return resp;
		})
		.catch((error) => {
			console.log( 'customFunctions.signup ERR', error);				
			var resp = {};
			resp.respError = error;
			console.log( '<-- customFunctions.usrSignUp ERR', error);					
			return resp;
		});
}


/**
*	usrRegisterAndOrder() - 
*/
export const usrRegisterAndOrder = async (firstname, lastname, username, email, phone, fac) => {
    console.log('--> customFunctions.usrRegisterAndOrder', { firstname, lastname, username, email, phone, fac });

    if (!username) {
        username = email;
        console.log('WARN: No username, replacing with email!');
    }

	var fac2 = {
		billingDetails:fac
	}
    const newUserData = {
        per_firstname: firstname,
        per_lastname: lastname,
        username: username,
        cch_email_o: email,
        cch_phone_o: phone,        
        fac: fac  // Optional, only for leads
    };

    let url = '/api/usr/ord/registerUsrAndOrder/'+fac.lea_artId;  // new endpoint that handles both actions
    if (window.QEE_API_URL) {
        console.log('>>> Using global window.QEE_API_URL', window.QEE_API_URL);
        url = window.QEE_API_URL + url;
    }

    try {
        const response = await axios.post(url, newUserData);
        console.log('customFunctions.registerUsrAndOrder response', response);
        console.log('<-- customFunctions.usrRegisterAndOrder');
        return response.data; // Response data should now include order details
    } catch (error) {
        console.error('customFunctions.usrRegisterAndOrder ERR', error);
        return { 
            error: true, 
            message: error.message || 'An error occurred', 
            details: error.response || {} 
        };
    }
};


/**
*	resolveQuizAnswers() - 
*/
export const resolveQuizAnswers = async (obj) => {

	console.log( '--> customFunctions.resolveQuizAnswers', obj);
	
	var ret = {};
	ret.questionsCount = 0;	
    ret.matchesCount = 0;	
	ret.percent = 0;		
	
    if (!obj || !obj.steps || !Array.isArray(obj.steps)) {
		ret.respError = 400;
        return ret; // Return 0 if the object or steps array is missing or not an array
    }

    obj.steps.forEach((step, index) => {
        const answerField = `answer_given_${index}`;

		console.log( index, step.text.des_data.field);
		console.log( index, answerField);
		console.log( index, step.text.des_data.answer);
		console.log( index, obj[answerField]);

        if( step.text &&
            step.text.des_data &&
            step.text.des_data.field &&
            step.text.des_data.field === answerField 
        ) {
            ret.questionsCount++;
        }		
		
        // Check if the step has the answer_given_* field and if it matches the answer value
        if( step.text &&
            step.text.des_data &&
            step.text.des_data.field &&
            step.text.des_data.field === answerField &&
            step.text.des_data.answer === obj[answerField]
        ) {
            ret.matchesCount++;
        }
    });
	
	ret.percent = ret.questionsCount ? ret.matchesCount / ret.questionsCount : 0;
	ret.percentText = ''+ (ret.percent*100) + '%';

	console.log( '<-- customFunctions.resolveQuizAnswers', ret);
	
    return ret;
}

/*
function calculatePeriodFee(annualFee, paymentPeriod, discountOption) {
    const monthsInPeriod = {
        monthly: 1,
        quarterly: 3,
        semiannually: 6,
        annually: 12
    };

    let monthlyFeeBeforeDiscount = annualFee / 12;

    // Adjustments based on the payment period
    const adjustmentRates = {
		semiannually: 0.03,
        quarterly: 0.05,     
        monthly: 0.07
    };

    // Apply discount adjustments if discountOption is "yes" and paymentPeriod is specified
    if (discountOption === "yes" && paymentPeriod !== 'annually' && paymentPeriod !== 'Unbekannt' && adjustmentRates[paymentPeriod]) {
        const adjustmentRate = adjustmentRates[paymentPeriod];
        monthlyFeeBeforeDiscount /= (1 - adjustmentRate);
    }

    const paymentMultiplier = monthsInPeriod[paymentPeriod] || 12;
    return monthlyFeeBeforeDiscount * paymentMultiplier;
}
*/
function calculatePeriodFee(annualFee, paymentPeriod, discountOption) {
    const paymentsPerYear = {
        monthly: 12,        // 12 payments per year
        quarterly: 4,       // 4 payments per year
        semiannually: 2,    // 2 payments per year
        annually: 1         // 1 payment per year
    };

    const surchargeRates = {
        semiannually: 0.03, // 3% surcharge
        quarterly: 0.05,    // 5% surcharge
        monthly: 0.07       // 7% surcharge
    };

    // Assume the annualFee represents the lowest possible cost with the maximum discount.
    let baseFee = annualFee;

    // Calculate the surcharge amount if applicable
    if (paymentPeriod !== 'annually' && surchargeRates[paymentPeriod]) {
        const surcharge = 1 + surchargeRates[paymentPeriod];
        baseFee = annualFee * surcharge; // Apply the surcharge to the base annual fee
    }

    // Determine the number of payments per year for the specified period
    const paymentsPerYearCount = paymentsPerYear[paymentPeriod] || 1; // Default to annually if undefined

    // Calculate the fee per payment period by dividing the adjusted annual fee
    // by the number of payments per year
    let feePerPeriod = baseFee / paymentsPerYearCount;

    return feePerPeriod;
}


function getPaymentPeriodText(paymentPeriod) {
    const paymentPeriodTexts = {
        monthly: 'Monatlich',
        quarterly: 'Vierteljährlich',
        semiannually: 'Halbjährlich',
        annually: 'Jährlich',
        Unbekannt: 'Unbekannt'
    };
    return paymentPeriodTexts[paymentPeriod] || 'Unbekannt';
}


/**
*	insuranceCalcMonthlyFee() - 
*/
export const insuranceCalcMonthlyFee = async (obj) => {
    console.log('--> customFunctions.insuranceCalcMonthlyFee', obj);

    const leaArtId = obj[obj.form].lea_artId;
    const functionName = `insuranceCalcMonthlyFee${leaArtId}`;

    if (typeof insuranceCalculators[functionName] === 'function') {
        const ret = await insuranceCalculators[functionName](obj);
        console.log('<-- customFunctions.insuranceCalcMonthlyFee', ret);
        return ret;
    } else {
        console.log(`No specific function found for lea_artId: ${leaArtId}`);
        return { error: `Unsupported lea_artId: ${leaArtId}` };
    }
};
const insuranceCalculators = {
	
	// --- 200050
	async insuranceCalcMonthlyFee200050(obj) {  
		console.log('--> customFunctions.insuranceCalcMonthlyFee200050', obj);

		var ret = {};

		const roleType = obj[obj.form].lea_roleType;
		const revenue = obj[obj.form].lea_annualRevenue;
		const tarifType = obj[obj.form].lea_tarifType; // "basic" or "premium"
		const discountOption = obj[obj.form].lea_discountOption; // Could be "yes" or "no"
		let paymentPeriod = obj[obj.form].lea_paymentPeriod; // Can be "monthly", "quarterly", "semiannually", "annually", or "Unbekannt"

		// If no discount is selected, default to annually
		if (discountOption === "no") {
			paymentPeriod = "annually";
		}

		try {
			// Important: also the field art_id must be of type integer, take care with artId's use only numbers in the arts collection
			var response = await getSdatasNet( 'sdata_insurance_premiums', ['art_id', 'role_type'], ['200050', roleType]);
			if (response.err) {
				console.error('Error fetching premiums data:', response.err);
				return { err: response.err };
			}

			let premiumsData = response.data.entries.results[0].premium_ranges;

			let annualFee = null;
			premiumsData.forEach(premiumRange => {
				if (revenue >= premiumRange.revenue_range_min && revenue <= premiumRange.revenue_range_max) {
					annualFee = tarifType === "basic" ? premiumRange.basic_annual : premiumRange.premium_annual;
				}
			});

			let periodFee = calculatePeriodFee(annualFee, paymentPeriod, discountOption);

			if (periodFee !== null) {
				ret.lea_periodFee = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(periodFee);
				ret.lea_paymentPeriodText = getPaymentPeriodText(paymentPeriod);
			} else {
				ret.err = 'No matching premium range found for the given revenue.';
			}
		} catch (error) {
			ret.err = error.message || 'An error occurred during the calculation.';
		}
		
		console.log( '<-- customFunctions.insuranceCalcMonthlyFee200050', ret);
		return ret;
	},

		
	// --- 200060	
	async insuranceCalcMonthlyFee200060(obj) {	
		console.log('--> customFunctions.insuranceCalcMonthlyFee200060', obj);

		var ret = {};

		const machinesType = obj[obj.form].lea_machinesType;   // 100-Druckmchinen, 200-CNCMachinen, 300-Baugeräte, 400-LandwitschaflicheMaschinen
		const machinesCount = obj[obj.form].lea_machinesCount;
		const tarifType = obj[obj.form].lea_tarifType; // "basic" or "premium"
		const discountOption = obj[obj.form].lea_discountOption; // Could be "yes" or "no"
		let paymentPeriod = obj[obj.form].lea_paymentPeriod; // Can be "monthly", "quarterly", "semiannually", "annually", or "Unbekannt"

		// If no discount is selected, default to annually
		if (discountOption === "no") {
			paymentPeriod = "annually";
		}

		try {
			var response = await getSdatasNet( 'sdata_insurance_premiums', ['art_id', 'machines_type'], ['200060', machinesType]);
			if (response.err) {
				console.error('Error fetching premiums data:', response.err);
				return { err: response.err };
			}

			let premiumsData = response.data.entries.results[0].premium_ranges;

			let annualFee = null;
			premiumsData.forEach(premiumRange => {
				if (machinesCount >= premiumRange.machines_count_range_min && machinesCount <= premiumRange.machines_count_range_max) {
					annualFee = tarifType === "basic" ? premiumRange.basic_annual : premiumRange.premium_annual;
				}
			});

			// Calculate the period fee using the helper function
			let periodFee = calculatePeriodFee(annualFee, paymentPeriod, discountOption);

			if (periodFee !== null) {
				ret.lea_periodFee = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(periodFee);
				// Get the payment period text using the helper function
				ret.lea_paymentPeriodText = getPaymentPeriodText(paymentPeriod);
			} else {
				ret.err = 'No matching premium range found for the given machines count.';
			}
		} catch (error) {
			ret.err = error.message || 'An error occurred during the calculation.';
		}
		
		console.log( '<-- customFunctions.insuranceCalcMonthlyFee200060', ret);
		return ret;
	},	

	
	// --- 200070
	async insuranceCalcMonthlyFee200070(obj) {	
		console.log('--> customFunctions.insuranceCalcMonthlyFee200070', obj);

		var ret = {};

		const machinesType = obj[obj.form].lea_machinesType;   // 100-Druckmchinen, 200-CNCMachinen, 300-Baugeräte, 400-LandwitschaflicheMaschinen
		const machinesCount = obj[obj.form].lea_machinesCount;
		const tarifType = obj[obj.form].lea_tarifType; // "basic" or "premium"
		const discountOption = obj[obj.form].lea_discountOption; // Could be "yes" or "no"
		let paymentPeriod = obj[obj.form].lea_paymentPeriod; // Can be "monthly", "quarterly", "semiannually", "annually", or "Unbekannt"

		// If no discount is selected, default to annually
		if (discountOption === "no") {
			paymentPeriod = "annually";
		}

		try {
			var response = await getSdatasNet( 'sdata_insurance_premiums', ['art_id', 'machines_type'], ['200060', machinesType]);
			if (response.err) {
				console.error('Error fetching premiums data:', response.err);
				return { err: response.err };
			}

			let premiumsData = response.data.entries.results[0].premium_ranges;

			let annualFee = null;
			premiumsData.forEach(premiumRange => {
				if (machinesCount >= premiumRange.machines_count_range_min && machinesCount <= premiumRange.machines_count_range_max) {
					annualFee = tarifType === "basic" ? premiumRange.basic_annual : premiumRange.premium_annual;
				}
			});

			// Calculate the period fee using the helper function
			let periodFee = calculatePeriodFee(annualFee, paymentPeriod, discountOption);

			if (periodFee !== null) {
				ret.lea_periodFee = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(periodFee);
				// Get the payment period text using the helper function
				ret.lea_paymentPeriodText = getPaymentPeriodText(paymentPeriod);
			} else {
				ret.err = 'No matching premium range found for the given machines count.';
			}
		} catch (error) {
			ret.err = error.message || 'An error occurred during the calculation.';
		}
		
		console.log( '<-- customFunctions.insuranceCalcMonthlyFee200070', ret);
		return ret;
	},			
	
	
	// --- 200080
	async insuranceCalcMonthlyFee200080(obj) {	
		console.log('--> customFunctions.insuranceCalcMonthlyFee200080', obj);

		var ret = {};

		const machinesType = obj[obj.form].lea_machinesType;   // 100-Druckmchinen, 200-CNCMachinen, 300-Baugeräte, 400-LandwitschaflicheMaschinen
		const machinesCount = obj[obj.form].lea_machinesCount;
		const tarifType = obj[obj.form].lea_tarifType; // "basic" or "premium"
		const discountOption = obj[obj.form].lea_discountOption; // Could be "yes" or "no"
		let paymentPeriod = obj[obj.form].lea_paymentPeriod; // Can be "monthly", "quarterly", "semiannually", "annually", or "Unbekannt"

		// If no discount is selected, default to annually
		if (discountOption === "no") {
			paymentPeriod = "annually";
		}

		try {
			var response = await getSdatasNet( 'sdata_insurance_premiums', ['art_id', 'machines_type'], ['200060', machinesType]);
			if (response.err) {
				console.error('Error fetching premiums data:', response.err);
				return { err: response.err };
			}

			let premiumsData = response.data.entries.results[0].premium_ranges;

			let annualFee = null;
			premiumsData.forEach(premiumRange => {
				if (machinesCount >= premiumRange.machines_count_range_min && machinesCount <= premiumRange.machines_count_range_max) {
					annualFee = tarifType === "basic" ? premiumRange.basic_annual : premiumRange.premium_annual;
				}
			});

			// Calculate the period fee using the helper function
			let periodFee = calculatePeriodFee(annualFee, paymentPeriod, discountOption);

			if (periodFee !== null) {
				ret.lea_periodFee = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(periodFee);
				// Get the payment period text using the helper function
				ret.lea_paymentPeriodText = getPaymentPeriodText(paymentPeriod);
			} else {
				ret.err = 'No matching premium range found for the given machines count.';
			}
		} catch (error) {
			ret.err = error.message || 'An error occurred during the calculation.';
		}
		
		console.log( '<-- customFunctions.insuranceCalcMonthlyFee200080', ret);
		return ret;
	},
	
	
	// --- 200090
	async insuranceCalcMonthlyFee200090(obj) {
		console.log('--> customFunctions.insuranceCalcMonthlyFee200090', obj);

		var ret = {};

		const lineLimit = obj[obj.form].lea_lineLimit; // e.g., '5000,5000'
		const tarifType = obj[obj.form].lea_tarifType; // e.g., "start", "standard", "special"
		const discountOption = obj[obj.form].lea_discountOption; // Could be "yes" or "no"
		let paymentPeriod = obj[obj.form].lea_paymentPeriod; // Can be "monthly", "quarterly", "semiannually", "annually", or "Unbekannt"

		// If no discount is selected, default to annually
		if (discountOption === "no") {
			paymentPeriod = "annually";
		}

		try {
			var response = await getSdatasNet('sdata_insurance_premiums', ['art_id'], ['200090']);
			if (response.err) {
				console.error('Error fetching premiums data:', response.err);
				return { err: response.err };
			}

			let premiumsData = response.data.entries.results[0].premium_ranges;
			let selectedPremium = premiumsData.find(premium => premium.lea_singleLimit === lineLimit);

			let annualFee;
			switch (tarifType) {
				case 'start':
					annualFee = selectedPremium.start_annual;
					break;
				case 'standard':
					annualFee = selectedPremium.standard_annual;
					break;
				case 'special':
					annualFee = selectedPremium.special_annual;
					break;
				default:
					annualFee = null;
			}

			if (annualFee !== null) {
				// Calculate the period fee considering the discount option and payment period
				let periodFee = calculatePeriodFee(annualFee, paymentPeriod, discountOption);

				ret.lea_periodFee = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(periodFee);
				ret.lea_paymentPeriodText = getPaymentPeriodText(paymentPeriod);
			} else {
				ret.err = 'Keine passende Prämienstufe für die angegebene Bürgschaftslinie und Einzellimit gefunden.';
			}
		} catch (error) {
			ret.err = error.message || 'Ein Fehler ist während der Berechnung aufgetreten.';
		}

		console.log('<-- customFunctions.insuranceCalcMonthlyFee200090', ret);
		return ret;
	}


}


/**
*	approximateFacValuation() - 
*/
export const approximateFacValuation = async ( fac) => {

	console.log( '--> approximateFacValuation');
			
	var url = '/api/fac/valuationApproximate';
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		
	let opts = {
		url: url,
		method: 'post',
		data: fac		
	};
	console.log( 'axios', opts);
	try {
		const ret = await axios( opts);
		const retFac = ret.data;
		console.log( '<-- approximateFacValuation', retFac);
		return retFac;
			
	} catch( error) {
		console.log( '<-- approximateFacValuation ERR', error);
		return { err:error};		
	}			
}


/**
*	aproximatePlanRenovationFacLight
*/
export const approximateFacPlanRenovation = async (fac) => {
  console.log('--> approximateFacPlanRenovation', fac);

  var ret = 0;

  const constructionYear = parseInt(fac.fac_construction_year);
  const heatingType = parseInt(fac.fac_heating_type);
  const originalHeatingType = heatingType || 0;  

  // Apply energy savings considerations for buildings constructed before 1960
  if (constructionYear < 1960) {
    ret += 0.2; // Add 20% savings for buildings with any original heating system
    switch (originalHeatingType) {
      case 700: // Fernwärme
      case 800: // Nahwärme
      case 900: // Luft-Wärme-Pumpe
      case 1000: // Erd-Wärme-Pumpe
        ret -= 0.2; // Subtract the previous savings for the original heating system
      case 100: // Öl
      case 200: // Erdgas
      case 300: // Flüssiggas
      case 400: // Holz-Pellet
      case 500: // Hackschnitzel
      case 600: // Brennholz
      case 1100: // Strom
      case 1200: // Braunkohle
        ret += 0.3; // Add 30% savings for buildings with district heating system (Fernwärme)
        break;
      default:
        break;
    }
  }
  // Apply energy savings considerations for buildings constructed from 1960 onwards
  else if (constructionYear < 1980) {
    ret += 0.1; // Add 10% savings for buildings with any original heating system
    switch (originalHeatingType) {
      case 700: // Fernwärme
      case 800: // Nahwärme
      case 900: // Luft-Wärme-Pumpe
      case 1000: // Erd-Wärme-Pumpe
        ret -= 0.1; // Subtract the previous savings for the original heating system
      case 100: // Öl
      case 200: // Erdgas
      case 300: // Flüssiggas
      case 400: // Holz-Pellet
      case 500: // Hackschnitzel
      case 600: // Brennholz
      case 1100: // Strom
      case 1200: // Braunkohle
        ret += 0.4; // Add 40% savings for buildings with district heating system (Fernwärme)
        break;
      default:
        break;
    }
  }
  // Apply energy savings considerations for buildings constructed from 1980 onwards
  else if (constructionYear < 2000) {
    ret += 0.05; // Add 5% savings for buildings with any original heating system
    switch (originalHeatingType) {
      case 700: // Fernwärme
      case 800: // Nahwärme
      case 900: // Luft-Wärme-Pumpe
      case 1000: // Erd-Wärme-Pumpe
        ret -= 0.05; // Subtract the previous savings for the original heating system
      case 100: // Öl
      case 200: // Erdgas
      case 300: // Flüssiggas
      case 400: // Holz-Pellet
      case 500: // Hackschnitzel
      case 600: // Brennholz
      case 1100: // Strom
      case 1200: // Braunkohle
        ret += 0.5; // Add 50% savings for buildings with district heating system (Fernwärme)
        break;
      default:
        break;
    }
  }
  // Apply energy savings considerations for buildings constructed from 2000 onwards
  else if (constructionYear < 2010) {
    ret += 0.02; // Add 2% savings for buildings with any original heating system
    switch (originalHeatingType) {
      case 700: // Fernwärme
      case 800: // Nahwärme
      case 900: // Luft-Wärme-Pumpe
      case 1000: // Erd-Wärme-Pumpe
        ret -= 0.02; // Subtract the previous savings for the original heating system
      case 100: // Öl
      case 200: // Erdgas
      case 300: // Flüssiggas
      case 400: // Holz-Pellet
      case 500: // Hackschnitzel
      case 600: // Brennholz
      case 1100: // Strom
      case 1200: // Braunkohle
        ret += 0.6; // Add 60% savings for buildings with district heating system (Fernwärme)
        break;
      default:
        break;
    }
  }
  
  // Considerations for windows, fassade, roof, and basement renovations
  ret += 0.3; // Add 10% savings for other general renovations 

  console.log('<-- approximateFacPlanRenovation', ret*100);
  return Math.floor(ret * 100);
};


/**
*	approximateFacNewbuildCost() - 
*/
export const approximateFacNewbuildCost = async ( fac) => {

	console.log( '--> approximateFacNewbuildCost', fac);
	
	fac.fac_newbuild_wiz_ready = true;    // set the wiz as ready
	
	var url = '/api/fac/newbuild/approximateCost';
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		
	let opts = {
		url: url,
		method: 'post',
		data: fac		
	};
	console.log( 'axios', opts);
	try {
		const ret = await axios( opts);
		const retFac = ret.data;
		console.log( '<-- approximateFacNewbuildCost', retFac);
		return retFac;
			
	} catch( error) {
		console.log( '<-- approximateFacNewbuildCost ERR', error);
		return { err:error};		
	}			
}


/**
*	getCompanyPublicData() - 
*/
export const getCompanyPublicData = async ( org) => {

	console.log( '--> getCompanyPublicData', org);
	
	var url = '/api/org/getCompanyPublicData';
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		
	let opts = {
		url: url,
		method: 'post',
		data: org		
	};
	console.log( 'axios', opts);
	try {
		const ret = await axios( opts);
		const retOrg = ret.data.data;
		console.log( '<-- getCompanyPublicData', retOrg);
		return retOrg;
			
	} catch( error) {
		console.log( '<-- getCompanyPublicData ERR', error);
		return { err:error};		
	}			
}


/**
*	supplierSignUp() - 
*/
export const supplierSignUp = async ( objSend) => {

	console.log( '--> customFunctions.supplierSignUp', objSend);
		
	if( !objSend.per_name) {	
		objSend.per_name = objSend.cch_email_o;
		console.log( 'WARN: No username, replacing with email!');
	}
	
	let org = objSend[objSend.form];
			
	const newUserData = {
		per_firstname: 		objSend.per_firstname,
		per_lastname: 		objSend.per_lastname,
		username: 			objSend.per_name,
		cch_email_o: 		objSend.cch_email_o,
		cch_phone_o: 		objSend.cch_phone_o,
		password: 			objSend.pass,
		confirmPassword: 	objSend.pass,
		fac:				org   // optional, only for leads
	};
	
	var url = '/api/usr/signupSupplier';
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;
	}
	return await axios
		.post( url, newUserData)
		.then(( resp) => {
			console.log( 'customFunctions.signup response', resp);
			console.log( '<-- customFunctions.usrSignUp');					
			return resp;
		})
		.catch((error) => {
			console.log( 'customFunctions.signup ERR', error);				
			var resp = {};
			resp.respError = error;
			console.log( '<-- customFunctions.usrSignUp ERR', error);					
			return resp;
		});
}


/**
*	supplierCreateSacRfi() - 
*/
export const supplierCreateSacRfi = async ( obj, context) => {

	console.log( '--> supplierCreateSacRfi', obj, context);
	
	obj.sac.sac_sender_id = [context.usr_id];
	obj.sac.sac_receiver_org_id = [context.org.orgId];
	obj.sac.sac_fac_id = context.fac_id;
	
	var url = '/api/sac';
	if( window.QEE_API_URL) {  // for use from external apps like qeewidgets
		console.log( '>>> Using global window.QEE_API_URL', window.QEE_API_URL);
		url = window.QEE_API_URL + url;	
	}
	console.log( 'Using URL', url);		
	let opts = {
		url: url,
		method: 'post',
		data: obj.sac   //, context}		
	};
	console.log( 'axios', opts);
	try {
		const ret = await axios( opts);
		const retSac = ret.data;
		console.log( '<-- supplierCreateSacRfi', retSac);
		return retSac;
			
	} catch( error) {
		console.log( '<-- supplierCreateSacRfi ERR', error);
		var resp = {};
		resp.respError = error;
		return resp;		
	}			
}