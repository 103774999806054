import react, { useState, useEffect, useRef } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles }  from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import QeeRadioGroup from './qeeRadioGroup';

import { ReactComponent as FacKfw100Icon } from "../assets/fac_kfw100.svg";
import { ReactComponent as FacKfw85Icon } from "../assets/fac_kfw85.svg";
import { ReactComponent as FacKfw70Icon } from "../assets/fac_kfw70.svg";
import { ReactComponent as FacKfw55Icon } from "../assets/fac_kfw55.svg";
import { ReactComponent as FacKfw40Icon } from "../assets/fac_kfw40.svg";

import { useAuth } from "../context/provider";
import { useValidation } from './qeeStepValidationProvider';

const FacRenovationEnergyclass = ( props) => { 

    const { registerValidation } = useValidation();
	
	const errorRef = useRef(null);
	const thisStep = props.step;		
	
	const { gstate, setObjEdit } = useAuth();				
	
	const [objChange, setObjChange] = useState({
		energyclass_plan: gstate.objEdit.fac_energy.energyclass_plan
    });	
    const objChangeRef = useRef(objChange);
    useEffect(() => {
        objChangeRef.current = objChange;
    }, [objChange]);
    const [objErrors, setObjErrors] = useState({});
	
	
	// some consts
	const useStyles = makeStyles( theme => ({
		//...props
		textPlan: {
			color:theme.typography.success?.color,   //theme.palette.primary.main,
			fontWeight: 800,			
		},
		validationContainer: {
			borderRadius: 5,
			padding: 5,
			backgroundColor: '#fff',
			[theme.breakpoints.down('xs')]: {
			padding: 0,
			},    
		}		
	}));			
	const classes = useStyles( props);	
			
		
    const showError = (fieldId) => {
        const errorElement = document.getElementById(fieldId);
        console.log('showError Element ' + fieldId + ': ' + errorElement.id);
        if (errorElement) {
            errorRef.current = errorElement;
        }
        return fieldId;
    };	
    const resetErrors = (id) => {
        setObjErrors({});
        document.getElementById(id).style.backgroundColor = '#fff';
    };
    useEffect(() => {
        const errorElements = document.querySelectorAll('.selError');
        if (errorElements.length > 0) {
            setTimeout(() => {
                errorElements.forEach(element => {
                    const parentElement = element.parentNode;
                    if (parentElement) {
                        parentElement.style.backgroundColor = '#fee';
                        parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }, 0);
        }
    }, [errorRef.current]);
    useEffect(() => {
        registerValidation(thisStep, validate);

        return () => {
            registerValidation(thisStep, null);
        };
    }, []);
				
		
	/**
	* validate() - 
	*/
    const validate = async () => {
        console.log('--> validate for FacPlanRenovationEnergyclass in step: ', gstate.objEdit.step);
        let firstErrorShown = false;
        let errors = false;
        const objErrors = {};

        if (!objChangeRef.current.energyclass_plan && !firstErrorShown) {
            objErrors.energyclass_plan = 'Du musst eine Auswahl treffen!';
            showError('energyclass_plan');
            errors = true;
            firstErrorShown = true;
        }

		setObjErrors(objErrors);
		if (!errors) {
			let oe = {
				...gstate.objEdit,
				fac_energy: {
					...gstate.objEdit.fac_energy,
					energyclass_plan: objChangeRef.current.energyclass_plan // Corrected this line
				}
			};
			console.log( '>>> OE', oe);
			setObjEdit( await props.fctSubmit( JSON.parse( JSON.stringify( oe))));
		}

        console.log('<-- validate: ' + errors);
        return !errors;
    };	
				
		
	const rgIconStyle = () => {
		if( !gstate.isXS)
			return { width:100, height:100 }
		else
			return { width:36, height:36 }
	}				
		
		
    return (
        <div class="form-group">
			<form  noValidate>					
				<Grid container 
					spacing={2} 
					direction="column" 
					justifyContent="left" 
					alignItems="left">
					<Grid item xs={9}>
						<h1>Welcher KfW Standard soll verwendet werden?</h1>		
					</Grid>	
	
					<Grid item xs={9}>
						<div id='energyclass_plan' className={classes.validationContainer}>
						<QeeRadioGroup
							formSublabel={<div style={{maxWidth:800}}>Je nach gewähltem KfW Standard werden unterschiedliche Zielwerte für die Immobilie berechnet. Eine bessere Energieklasse ist mit höheren Sanierungskosten, aber auch mit höheren Förderungen verbunden</div>}						
							defaultValue={gstate.objEdit.fac_energy.energyclass_plan}
							fctOnChange={( ev)=>{
								console.log('onChange energyclass_plan', ev.target.value);
								//alert(ev.target.value)
								var och = {
									...objChange,
									energyclass_plan: ev.target.value
								};
								setObjChange(och);
								resetErrors('energyclass_plan');
							}}
							sdata={[
								{value:'kfw40', label:'kfW40', icon:<FacKfw40Icon style={rgIconStyle()}/>},
								{value:'kfw55', label:'kfW55', icon:<FacKfw55Icon style={rgIconStyle()}/>},								
								{value:'kfw70', label:'kfW70', icon:<FacKfw70Icon style={rgIconStyle()}/>},								
								{value:'kfw85', label:'kfW85', icon:<FacKfw85Icon style={rgIconStyle()}/>},								
							]}
						/>
						{objErrors.energyclass_plan ? (<div style={{ textAlign: "center" }}><small className="selError form-text error">{objErrors.energyclass_plan}</small></div>) : (<></>)}							
						</div>
					</Grid>
				</Grid>
			</form>
		</div>
    )
}
export default FacRenovationEnergyclass;