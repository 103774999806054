// facNameDesc.js

import react, { useState, useEffect, useLayoutEffect } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import StepZilla from "react-stepzilla";

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	}
});


const FacNameDesc = ( props) => {
	
	const thisStep = props.step;			
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();				
	
	const [validationErrors, setValidationErrors] = useState( []);		

	console.log( 'THIS STEP', thisStep);	
	console.log( 'objEdit.step', gstate.objEdit.step);			
	
		
	const handleChange = (event) => {
		console.log( 'change: ' + event.target.name, event.target.value);
		
		var fac = gstate.objEdit;
		fac[event.target.name] = event.target.value;				
		setObjEdit( fac);		
	};
	
	
	useEffect(()=> {   
		console.log( 'FacNameDesc useEffect', thisStep);
		
		//setObjErrors( []);
		
		const nextBtn = document.getElementById( 'next-button')
		if( thisStep == gstate.objEdit.step) {
			console.log( 'here ND');
			nextBtn.onclick=null;
			nextBtn.onclick=(e) => {
				console.log( 'Set validation in FacNameDesc');
				if( validate( e) > 0) {
					e.stopPropagation();
				} 
				setValidationErrors( []); 					
				console.log( 'objEdit', gstate.objEdit)				
			}    
		} 

		const prevBtn = document.getElementById( 'prev-button')
		if( thisStep == gstate.objEdit.step) {
			prevBtn.onclick=null;
			prevBtn.onclick=(e) => {
				if( props.fctGotoPrevStep) {
					e.stopPropagation();
					props.jumpToStep( props.fctGotoPrevStep( thisStep));
				}
				console.log( 'objEdit: ', gstate.objEdit)				
			}    
		} 						
								
	}, [JSON.stringify( gstate.objEdit.step)]);		
		
	
	useEffect(()=> {  
	
		setValidationErrors( []); 			
	
	}, [gstate.objEdit.step]);			

		
	/**
	* validate() - 
	*/
	const validate = (e) => { 
		
		console.log( '--> validate for facNameDesc: ', gstate.objEdit.step);
		
		var errs = false;		
		const validationErrors= [];
		if( !gstate.objEdit.fac_name || gstate.objEdit.fac_name==='') {
			const msg = 'Du musst eine Bezeichnung für die Immobilie eingeben!';
			validationErrors.push( msg);		
			//gstate.objErrors.fac_name = msg;
			setObjErrors({
				...gstate.objErrors,
				fac_name: msg
			});			
		}

		if( validationErrors.length) {
			//alert( validationErrors[0]);			
			errs = true;
			setValidationErrors( validationErrors); 			
		} 		
		
		console.log( '<-- validate: ' + errs)		
		return errs;
	}
	
			
	return (
        <div class="form-group">
			<form  noValidate>					
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<h1>Beschreibe die Immobilie</h1>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							required
							fullWidth
							id="facTitle"
							label="Meine Bezeichnung"
							placeholder="z.B. Mein Traumhaus am See"
							name="fac_name"
							value={gstate.objEdit.fac_name}									
							helperText={gstate.objErrors.fac_name}
							error={gstate.objErrors.fac_name ? true : false}
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							fullWidth
							id="facDetails"
							label="Beschreibung der Immobilie"
							placeholder="Die ausführliche Beschreibung deiner Immobilie"
							name="fac_desc"
							multiline
							rows={7}
							rowsMax={20}
							value={gstate.objEdit.fac_desc}									
							helperText={gstate.objErrors.fac_desc}
							error={gstate.objErrors.fac_desc ? true : false}
							onChange={handleChange}									
						/>
					</Grid>
				</Grid>
			</form>
		</div>
	);

}	
export default withStyles(styles)(FacNameDesc);