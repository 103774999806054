// QeeFacShareProvider
import React, { createContext, useMemo, useReducer, useContext, useRef } from 'react';

import FacShareDialog from './facShareDialog';
import { useAuth } from "../context/provider"; 


const FacShareContext = createContext();

const QeeFacShareProvider = (props) => {
	
	const { gstate } = useAuth();					
	
	const refFacShareDialog = useRef(null);			
	
	const setOpenFacShareDialog = ( fac) => {
		refFacShareDialog.current.handleFacShareOpen( fac);
	};

	const value = useMemo(() => {   
		return { setOpenFacShareDialog };	  	  
	}, []);	
	
	return (
		<FacShareContext.Provider value={value}>
			<FacShareDialog 
				ref={refFacShareDialog} 
			/>	
			{props.children}	
		</FacShareContext.Provider>
	);

}

const useFacShare = () => useContext( FacShareContext );
export { FacShareContext, useFacShare }
export default QeeFacShareProvider;
	

