import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Functional component to wrap the navigation functions
 */
const QeeNavigator = () => {
    const navigate = useNavigate();

    const loadHome = (event) => {
        navigate('/home');
    };

	const loadFacs = () => {
		navigate( '/home')
    };

    const loadAccount = () => {
		navigate( '/home?subpage=account')
    };

    const loadChats = (event) => {
        const usr = JSON.parse(sessionStorage.getItem('usr'));
        if (usr) {
            console.log('sessionStorage usr', usr);
            const usrId = usr.usrId;
            
            let initialFilter = {
                chat_usr_id: usrId
            };
            const queryParams = new URLSearchParams(initialFilter).toString();
            navigate(`/chats?filter=${queryParams}`, { replace: true });
        } else {
            return { err: 'No user data!' };
        }
    };	

    const loadSacs = (event) => {		
        const usr = JSON.parse(sessionStorage.getItem('usr'));
        if (usr) {
            console.log('sessionStorage usr', usr);
            if (usr.per_org_id) {
                loadSacsReceiver(event);
            } else {
                loadSacsSender(event);
            }
        } else {
            return { err: 'No user data!' };
        }		
    };

    const loadSacsSender = (event) => {
        const usr = JSON.parse(sessionStorage.getItem('usr'));
        if (usr) {
            console.log('sessionStorage usr', usr);
            const usrId = usr.usrId;
            
            let initialFilter = {
                sac_sender_id: usrId,
                sac_type: 'rfi'
            };
            const queryParams = new URLSearchParams(initialFilter).toString();
            navigate(`/sacs?filter=${queryParams}`, { replace: true });
        } else {
            return { err: 'No user data!' };
        }
    };	

    const loadSacsReceiver = (event) => {
        const usr = JSON.parse(sessionStorage.getItem('usr'));
        if (usr) {
            console.log('sessionStorage usr', usr);
            const usrId = usr.usrId;
            const orgId = usr.per_org_id;
            
            let initialFilter = {
                sac_receiver_org_id: orgId,
                sac_type: 'rfi'
            };
            const queryParams = new URLSearchParams(initialFilter).toString();
            navigate(`/sacs?filter=${queryParams}`, { replace: true });
        } else {
            return { err: 'No user data!' };
        }
    };

    return {
        loadHome,		
		loadFacs,
		loadAccount,
        loadChats,		
        loadSacs,
        loadSacsSender,
        loadSacsReceiver
    };
};

export default QeeNavigator;
