import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from 'axios';

import Account from '../components/account';
import FacsSearch from '../components/facsSearch';
import Facs from '../components/facs';
import Docs from '../components/docs';
import Lea from '../components/lea';
import ResponsiveAppBar from '../components/appbar';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import MapsHomeIcon from '@material-ui/icons/Home';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NotesIcon from '@material-ui/icons/Notes';
import Avatar from '@material-ui/core/Avatar';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ChatIcon from '@mui/icons-material/Chat';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@mui/material/Badge';
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import QeeNavigator from '../context/navigator';

import { resetVersion } from "../api/service";
import { useNavigate } from "react-router-dom";

import { usrIsAdmin } from "../util/helpers";

import { authMiddleWare, setLocalStorageAuthToken } from '../util/auth';
import { useAuth } from "../context/provider";

const styles = (theme) => ({
	root: {
		display: 'flex'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	toolbar: theme.mixins.toolbar
});

const Home = (props) => {
	const { setOpenConfluenceDialog } = useConfluence();							
	const { gstate, setUsr, setOpenQeeDialog, setFacsType, setIsFetching } = useAuth();								
	const [state, setState] = useState({
		render: 'Account',
		per_firstname: '',
		per_lastname: '',
		profilePicture: '',
		uiLoading: true,
		imageLoading: false,
		facsType: 'myFacs'
	});	
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
	const [windowDimension, detectHW] = useState({
		winWidth: window.innerWidth,
		winHeight: window.innerHeight,
	});	
	const navigate = useNavigate();
	const { loadChats, loadSacs } = QeeNavigator();

	const useStyles = makeStyles({
		...props,	
		listItem: {
			color: '#788',
			fontWeight: 600,
			fontSize: 16
		}
	});	
	const classes = useStyles(props);		
		
	const detectSize = () => {
		detectHW({
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		});
	};	

	const logoutHandler = (event) => {
		localStorage.removeItem('AuthToken');
		navigate('/login');
	};

	// pages
	const loadFacsSearchPage = (event) => {
		console.log('loadFacsSearchPage');
		navigate('/facssearch');		
	};			
	const loadBuyPage = (event) => {
		console.log('loadBuyPage');
		navigate('/buy');		
	};		
	const loadOwnPage = (event) => {
		console.log('loadOwnPage');
		navigate('/own');				
	};		
	const loadSellPage = (event) => {
		console.log('loadSellPage');
		navigate('/sell');				
	};		
	const loadFacs = (event) => {
		setFacsType('myFacs');			
		setState({ render: 'Facs', facsType: 'myFacs' });
	};

	const loadFacsShared = (event) => {
		setFacsType('sharedFacsAll');					
		setState({ render: 'Facs', facsType: 'sharedFacsAll' });
	};

	const loadDocs = (event) => {
		setState({ render: 'Docs' });
	};

	const loadLea = (event) => {
		setState({ render: 'Lea' });
	};

	const loadAccount = (event) => {
		setState({ render: 'Account' });
	};
	
	const loadAdmin = (event) => {
		navigate('/admin');
	};

	const showConfluenceHome = (event) => {
		setOpenConfluenceDialog();
	};

	const showVersionInfo = (event) => {
		setOpenQeeDialog({ open: true, id: 'dlgVersionInfo' });
	};

	useEffect(() => {		
		setIsFetching(true);
		
		if (props.location) {
			const urlParams = new URLSearchParams(props.location.search);
			const authToken = decodeURIComponent(urlParams.get('access_token'));
			const refreshToken = decodeURIComponent(urlParams.get('refresh_token'));
			
			if (authToken && authToken !== 'null' && authToken !== '') {
				localStorage.setItem('AuthToken', 'Bearer ' + authToken);
				localStorage.setItem('method', 'AuthToken set from url');
			}
			if (refreshToken && refreshToken !== 'null' && refreshToken !== '') {
				localStorage.setItem('RefreshToken', refreshToken);
				localStorage.setItem('method', 'RefreshToken set from url');
			}
		}

		resetVersion();
		
		const authToken = localStorage.getItem('AuthToken');
		if (authToken == null) navigate('/login');		
		const refreshToken = localStorage.getItem('RefreshToken');
		axios.defaults.headers.common = { Authorization: `${authToken}`, RefreshToken: refreshToken };				
		console.log('GET /api/usr');
		axios.get('/api/usr')
			.then((response) => {
				console.log('response', response);	
				let usr = {
					admin: response.data.userCredentials.admin,					
					per_firstname: response.data.userCredentials.per_firstname,
					per_lastname: response.data.userCredentials.per_lastname,
					cch_email_o: response.data.userCredentials.cch_email_o,
					cch_phone_o: response.data.userCredentials.cch_phone_o,
					addr_country_o: response.data.userCredentials.addr_country_o,
					username: response.data.userCredentials.username,
					id: response.data.userCredentials.id,															
					usrId: response.data.userCredentials.userId,							
					per_org_id: response.data.userCredentials.per_org_id,					
					profilePicture: response.data.userCredentials.imageUrl,					
					usr_unread_chat_msgs_count: response.data.userCredentials.usr_unread_chat_msgs_count,					
					usr_prm_block: response.data.userCredentials.usr_prm_block,										
					lic: JSON.parse(JSON.stringify(response.data.userCredentials.lic)),					
					uiLoading: false
				}; 	
				setLocalStorageAuthToken(response);
				setState(usr);
				setUsr(usr);
				setUnreadMessagesCount( usr.usr_unread_chat_msgs_count);
				sessionStorage.setItem('usr', JSON.stringify(usr));
				setIsFetching(false);
				
				const queryParams = new URLSearchParams(window.location.search);
				const subpage = queryParams.get('subpage');
				if (subpage) {
					const subpageMap = {
						account: loadAccount,
						facs: loadFacs,
						docs: loadDocs,
						lea: loadLea
					};
					const loadFunction = subpageMap[subpage.toLowerCase()];
					if (loadFunction) {
						loadFunction();
					}
				}		
								
			})
			.catch((error) => {
				console.log('GET /api/usr ERR', error);
				if (error.response.status === 403) {
					navigate('/login');
				}
				setIsFetching(false);
				setState({ errorMsg: 'Error in retrieving the data' });
			});
    }, []);	
	
	const drawer = () => {	
		return (
			<>
				<div className={classes.toolbarxxx} />
				<List>
					<ListItem button key="Facs" onClick={loadFacs}>
						<ListItemIcon>
							<MapsHomeIcon />
						</ListItemIcon>
						<ListItemText 
							disableTypography
							primary={<Typography variant="body2" className={classes.listItem}>Meine Immobilien</Typography>} 
						/>						
					</ListItem>						
					
					<ListItem button key="FacsShared" onClick={loadFacsShared}>
						<ListItemIcon>
							<PersonAddIcon />
						</ListItemIcon>
						<ListItemText 
							disableTypography
							primary={<Typography variant="body2" className={classes.listItem}>Für mich freigegeben</Typography>} 
						/>						
					</ListItem>											
										
					<Divider />
					
					<ListItem button key="Chats" onClick={loadChats}>
						<ListItemIcon>
							<Badge badgeContent={unreadMessagesCount} color="newFeature">
								<ChatIcon />
							</Badge>
						</ListItemIcon>
						<ListItemText 
							disableTypography
							primary={<Typography variant="body2" className={classes.listItem}>Nachrichten</Typography>} 
						/>												
					</ListItem>					
				
					<ListItem button key="Sacs" onClick={loadSacs}>
						<ListItemIcon>
							<ChecklistIcon />
						</ListItemIcon>
						<ListItemText 
							disableTypography
							primary={<Typography variant="body2" className={classes.listItem}>Aktivitäten</Typography>} 
						/>												
					</ListItem>

					{/*					
					<ListItem button key="Account" onClick={loadAccount}>
						<ListItemIcon>
							<AccountBoxIcon />
						</ListItemIcon>
						<ListItemText 
							disableTypography
							primary={<Typography variant="body2" className={classes.listItem}>Mein Konto</Typography>} 
						/>												
					</ListItem>

					<ListItem button key="Logout" onClick={logoutHandler}>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText 
							disableTypography
							primary={<Typography variant="body2" className={classes.listItem}>Logout</Typography>} 
						/>																								
					</ListItem>
					*/}
				</List>
			</>
		);
	}

	const switchSubpage = () => {
		console.log('>>> window.location1', window.location);
		console.log('switchSubpage render', state.render);
		console.log('switchSubpage facsType', state.facsType);
		switch (state.render) {
			case 'Facs':
				return <Facs />;
			case 'Docs':
				return <Docs />;
			case 'Lea':
				return <Lea />;
			case 'Account':
				return <Account />;
			default:
				return <Facs />;
		}
	};
		
	const settings = [
		{ nm:'Mein Konto', fct:loadAccount, ico:<AccountBoxIcon />},
		{ nm:'Nachrichten', fct:loadChats, ico:<ChatIcon />},				
		{ nm:'Aktivitäten', fct:loadSacs, ico:<ChecklistIcon />},		
		{ nm:'Administration', fct:loadAdmin, ico:<AdminPanelSettingsIcon />},
		{ nm:'Wissensassistent', fct:showConfluenceHome, ico:<HelpOutlinedIcon />},
		{ nm:'Versionsinfo', fct:showVersionInfo, ico:<PrivacyTipIcon />},
		{ nm:'Logout', fct:logoutHandler, ico:<ExitToAppIcon />}
	];

	if (!usrIsAdmin()) {
		const adminIndex = settings.findIndex((item) => item.nm === 'Administration');
		if (adminIndex !== -1) {
			settings.splice(adminIndex, 1);
		}
	}	
	
	return (	
		gstate.isFetching ? (
			<div className={classes.root}>
				{/* Loading indicator */}
			</div>		
		) : (
			<>
				<div>
					<ResponsiveAppBar 
						className={classes.appBar} 
						drawer={drawer}
						switchSubpage={switchSubpage}
						pages={[]}
						settings={settings}					
					/>
				</div>
			</>
		)	
	);
}
export default withStyles(styles)(Home);
