// QeeStepperDialogProvider
import React, { createContext, useMemo, useReducer, useContext, useRef } from 'react';

import StepperDialog from './stepperDialog';
import { useAuth } from "../context/provider"; 


const StepperDialogContext = createContext();

const QeeStepperDialogProvider = (props) => {
	
	const { gstate } = useAuth();					
	
	const refStepperDialog = useRef(null);			
	
	const setOpenStepperDialog = ( context, title, stepper) => {
		refStepperDialog.current.handleStepperDialogOpen( context, title, stepper);
	};

	const value = useMemo(() => {   
		return { setOpenStepperDialog };	  	  
	}, []);	
	
	return (
		<StepperDialogContext.Provider value={value}>
			<StepperDialog 
				ref={refStepperDialog} 
			/>	
			{props.children}	
		</StepperDialogContext.Provider>
	);

}

const useStepperDialog = () => useContext( StepperDialogContext );
export { StepperDialogContext, useStepperDialog }
export default QeeStepperDialogProvider;
	

