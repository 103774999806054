// QeeSlideDialog

import react, { useState, useEffect, useLayoutEffect } from 'react';
import React, { Dimensions, Component } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog  from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';

import { makeStyles }  from '@material-ui/core/styles';
import { green } from '@mui/material/colors';
import { grey } from '@mui/material/colors';

import { useAuth } from "../context/provider";

// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


//const QeeSlideDialog = (props) => {
const QeeSlideDialog = React.forwardRef((props, forwardedRef) => {	

	//console.log( 'QeeSlideDialog props', props);
	
	const { gstate, setObjEdit, setObjErrors, setOpenQeeDialog } = useAuth();					
	
	// some consts	
	const useStyles = makeStyles(theme => ({
		//...props,	
		newPosOfDialog: {		
			[theme.breakpoints.up('lg')]: {
				transform: !props.forceFullWidth ? "translate(100%, 0%)" : "",
			},			
		},
		dialog: {
			width: props.forceFullWidth ? '100%' : '50%',
			[theme.breakpoints.down('md')]: {
				width: '100%'
			},			
			position: 'absolute',
			left: 10,
			top: 50,	
		},	
		dialogStyle: {
			maxWidth: props.forceFullWidth ? '100%' : '50%',
			[theme.breakpoints.down('md')]: {
				maxWidth: '100%'
			},						
		},			
	}));	
	const classes = useStyles( props);	
	

	const handleClose = () => {
		if( props.fctOnClose)
			props.fctOnclose();		
		setOpenQeeDialog( {open:false, id:null});
	};	
	
	const handleSubmit = async () => {
		if( props.fctOnSubmit) {
			var ret = false;
			ret = await props.fctOnSubmit();		
			if( ret)
				setOpenQeeDialog( {open:false, id:null});
		}
	};		

		
	useEffect(() => {
	}, [])

	
	return (		
		<Dialog 
			fullScreen
			classes={{
				paper: classes.newPosOfDialog,
				paperFullWidth: classes.dialogStyle						
			}}
			className={classes.dialog}
			open={gstate.openQeeDialog.open && props.id===gstate.openQeeDialog.id} 
			onClose={handleClose} 
			TransitionComponent={Transition}
		>
			{!props.hideDialogAppBar ?
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6" className={classes.fac_name}>
						{props.title}
					</Typography>
				</Toolbar>
			</AppBar>
			:
			<div style={{textAlign:'left'}}>	
			<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{width:90,padding:5}}>
				<CloseIcon />
			</IconButton>			
			</div>
			}
			
			<DialogContent style={{padding:props.paddingContent!=undefined ? props.paddingContent : 10, backgroundColor:props.darkMode ? '#222' : '#fff'}}>
				{props.formComponent}	
			</DialogContent>
			
			{!props.hideDialogActions ?
			<DialogActions>
				<QeeColorButtonSecondary 
					variant="text" 
					onClick={handleClose}
					sx={{
						color: (theme) => theme.palette.primary.main,
						'&.Mui-hovered': {
							color: (theme) => theme.palette.primary.main,
						},
					}}
				>
					Abbrechen
				</QeeColorButtonSecondary> 
				<QeeColorButtonPrimary 
					variant="contained" 
					onClick={handleSubmit}
					disabled={props.submitDisabled}
				>
					Speichern
				</QeeColorButtonPrimary>
			</DialogActions>						
			:''}
			
		</Dialog>		
	)
})
export default QeeSlideDialog;


