import React from "react";
import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { render } from 'react-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import QeeHintBox from './qeeHintBox';
import QeeTooltip from './qeeTooltip';
import {formatNumber} from '../util/helpers';

import { useAuth } from "../context/provider";


const ChartPlanValuation = ( props) => {
	
	console.log( 'ChartPlanValuation', props);
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();	

	const opts = {
		chart: {
			type: 'column'
		},
		
		title: {
			//text: 'Marktwertsteigerung durch Sanierungsmaßnahmen'
			text: ''
		},

		xAxis: {
			categories: ['Aktueller Zustand', 'Mit Teilsanierung', 'Mit Kernsanierung']
		},

		yAxis: {
			allowDecimals: false,
			min: 0,
			title: {
				text: 'Euro'
			}
		},

		tooltip: {
			formatter: function () {
				return '<b>' + this.x + '</b><br/><br/>' +
					this.series.name + ': ' + formatNumber( this.y, 'EUR', 0) + '<br/><br/>' +
					'Total: ' + formatNumber( this.point.stackTotal, 'EUR', 0);
			}
		},

		plotOptions: {
			column: {
				stacking: 'normal'
			}
		},

		series: [
		{
			name: 'Wertsteigerung',
			data: [0, 0, 0],   //data: [0, 150000, 280000],
			stack: 'value-increase'
		},				
		{
			name: 'Initialer Marktwert',
			data: [0, 0, 0],   //data: [500000, 500000, 500000],
			stack: 'value-increase'
		},
		{
			name: 'Sanierungskosten',
			data: [0, 0, 0],   // data: [0, 40000, 300000],
			stack: 'price-cost'
		},		
		{
			name: 'Preis',
			data: [0, 0, 0],   //data: [450000, 450000, 450000],
			stack: 'price-cost'
		}]
	}	
	const [options, setOptions] = useState( opts);			
	
		
	// create a ref
	const chartComponent = useRef( null);
			
	
	// some consts
	const useStyles = makeStyles( theme => ({
		chartWrapper: {
			float: 'left',
			//paddingBottom: '40%',
			position: 'relative',
			width: '100%'
		}
	}));			
	const classes = useStyles( props);  		
	
	
	useEffect( () => {	

		console.log( 'ChartPlanValuation useEffect');
		
		const chart = chartComponent.current.chart;		
		console.log( "chart..", chart.current);
						
		setValuations( chart);	
		
						
	}, [JSON.stringify( gstate.objEdit.fac_valuation)]);		
	

	const setValuations = ( chart) => {
		
		console.log( '--> setValuations');
		
		try {
		
			var fac = gstate.objEdit;
			const vln = fac.fac_valuation;
			if( !vln) {
				console.log( '<-- setValuations ERR no fac_valuations');
				return;
			}

			const vlnsPlan = fac.fac_plan_valuations;
			if( !vlnsPlan) {
				console.log( '<-- setValuations ERR no fac_plan_valuations');
				return;
			}
			
			
			const initVln = vln.valueRange.val;
			const renoVln = vlnsPlan.renovated.valueRange.val;   
			const coreRenoVln = vlnsPlan.coreRenovated.valueRange.val;
			//const coreRenoVln = initVln*1.5;			
			var cost = fac.fac_plan_renovations_cost ? fac.fac_plan_renovations_cost : 0;
			var cost2 = fac.fac_plan_renovations_cost2 ? fac.fac_plan_renovations_cost2 : 0;
			var supp = fac.fac_plan_renovations_support ? fac.fac_plan_renovations_support : 0;
			const renoCost = cost + cost2 - supp;
			const coreRenoCost = initVln * 0.7;
			const price = fac.fac_price.value;
			
			var opts = options;
			opts.series[0].data = [0, renoVln-initVln, coreRenoVln-initVln];
			opts.series[1].data = [initVln, initVln, initVln];
			opts.series[2].data = [0, renoCost, coreRenoCost];
			opts.series[3].data = [price, price, price];
			
			//chart.current.update( opts);
			chart.update( opts);
			
			setOptions( opts);
			/*
			setOptions({
				...options,
				series.[0]: {
					...series.[0],
					data: {0, renoValn-price, renoBigVln-price}
				}
				
				options.series[0].data: {0, renoValn-price, renoBigVln-price},
				options.series[1].data: {initVln, initVln, initVln},
				options.series[2].data: {0, renoCost, renoBigCost},
				options.series[3].data: {price, price, price},			
				
			});
			*/
		
		} catch( e) {
			console.error( 'setValuations ERR', e);	
		}
		
		console.log( '<-- setValuations', options);
	}

	
	const getHint = () => {
		return gstate.objEdit.fac_valuation.hint;
	}
	const getRenovatedHint = () => {
		return gstate.objEdit.fac_plan_valuations.renovated.hint;
	}	
	const getCoreRenovatedHint = () => {
		return gstate.objEdit.fac_plan_valuations.coreRenovated.hint;
	}		
	
	
	return (
		<div style={{width:'100%', maxWidth:1200}}>
			{props.title ?
				<h2>{props.title}</h2>
				:
				''
			}				
			<Grid container direction="row">							
				<Grid item xs={12} sm={12} md={6} style={{minWidth:!gstate.isXS ? 400 : ''}}>
					<div className={classes.chartWrapper}>
						<HighchartsReact 
							ref={chartComponent} 
							highcharts={Highcharts}
							options={options}
						/>
					</div>
				</Grid>	
				{props.showHint ?	
					<Grid item xs={12} sm={12} md={6} style={{padding:20}}>
						<QeeHintBox type="info" text={<React.Fragment><strong>Aktuelle Marktwertinfo:</strong> <span dangerouslySetInnerHTML={{__html: getHint()}}></span></React.Fragment>} />
						{gstate.objEdit.fac_plan_valuations ? 
							<>
							<QeeHintBox type="info" style={{marginTop:10}} text={<React.Fragment><strong>Bei Teilsanierung:</strong> <span dangerouslySetInnerHTML={{__html: getRenovatedHint()}}></span></React.Fragment>} />
							<QeeHintBox type="info" style={{marginTop:10}} text={<React.Fragment><strong>Bei Kernsanierung:</strong> <span dangerouslySetInnerHTML={{__html: getCoreRenovatedHint()}}></span></React.Fragment>} />
							</> : ''}
					</Grid>			
				: ''}
			</Grid>							
			
		</div>

    );
	
}
export default ChartPlanValuation;