import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography, Button, CircularProgress, Grid } from '@mui/material';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	confirmationContainer: {
		textAlign: 'center',
		marginTop: theme.spacing(8),
	},
	confirmationIcon: {
		color: theme.palette.primary.main,		
		fontSize: 64, 
		marginBottom: 30,		
	},
	confirmationText: {
		fontSize: '1.2rem',
		fontWeight: 'bold',
		marginBottom: theme.spacing(4), 
	},
	contactText: {
		fontSize: '1rem',
		marginTop: theme.spacing(6),
	},
}));	

const QeeOrderConfirmation = ({ artId, onOrderDone }) => {
	const [articleData, setArticleData] = useState(null);
	const [loading, setLoading] = useState(false);

	const classes = useStyles();
	
	const handleOrderDone = () => {
		setLoading(true);
		// Perform the order action here, for example, making an API call to place the order
		// Once the order is completed, setLoading(false);
		onOrderDone(); // Replace this with the actual order logic
	};

	useEffect(() => {
		const fetchArticle = async () => {
			try {
				const response = await axios.get(`/api/art/${artId}`);
				console.log( 'articleData', response.data);
				setArticleData( response.data);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching article:', error);
				setLoading(false);
			}
		};

		fetchArticle();
	}, [artId]);

	return (
		<div style={{padding:20}}>
			<>
			<div className={classes.confirmationContainer}>
				<CheckCircleIcon className={classes.confirmationIcon} />
				<Typography variant="body4" component="div">
					Herzlichen Dank für deine Bestellung!
				</Typography>
				<Typography variant="body4" component="div">
					Die Bestellung wurde erfolgreich aufgenommen.
				</Typography>
				<Typography variant="body2" component="div" style={{marginTop:40}}>
					Unser Shop-Personal wird dich in Kürze kontaktieren.
				</Typography>
			</div>				
			<Button
				variant="contained"
				color="primary"
				onClick={handleOrderDone}
				style={{ position: 'absolute', bottom: '20px', right: '20px' }}
			>
				Zurück zum Shop
			</Button>							
			</>
		</div>
	);
};

export default QeeOrderConfirmation;
