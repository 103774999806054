// facWiz.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import FacOwnership from './facOwnership';
import FacPortalId from './facPortalId';
import FacType from './facType';
import FacGAddress from './facGAddress';
import FacAreas from './facAreas';
import FacNameDesc from './facNameDesc';
import FacPrjType from './facPrjType';
import WaitProgressLinear from "./waitProgressLinear";
import step1 from '../assets/worker-discipline-carpenter1.png';
import step2 from '../assets/worker-discipline-carpenter2.png';
import step3 from '../assets/worker-discipline-carpenter3.png';
import step4 from '../assets/worker-discipline-carpenter4.png';
import step5 from '../assets/worker-discipline-carpenter5.png';

import StepZilla from "react-stepzilla";

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
});


const StepEnd = (props) => {
	
	const steps = [
		{ text: 'Daten werden gespeichert...', image: step1 },
		{ text: 'Die Immobilie wird analysiert...', image: step2 },
		{ text: 'Bereite die Benutzeroberfläche vor...', image: step3 },
		{ text: 'Noch einen Moment, bitte...', image: step4 },
		{ text: 'Fast geschafft – Der Vorgang wird beendet!', image: step5 },
	];	
	
    // Function to remove the element with class "progtrckr"
    const removeProgtrckr = () => {
        const progtrckrElement = document.querySelector('.progtrckr');
        if (progtrckrElement) {
            progtrckrElement.remove();
        }
    };

    // Call the function to remove progtrckr when the component mounts
    useEffect(() => {
        removeProgtrckr();
    }, []);

    return (
        <div class="form-group">
            <WaitProgressLinear steps={steps}/>
        </div>
    );  
}


// main fct
const FacWiz = ( props) => {

	const { gstate, setObjEdit, setObjErrors } = useAuth();			
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
	})


	// create a ref
	const stepzilla = useRef(null);					
	const step1 = useRef(null);						

	
	/**
	*	handleGotoNextStep() - evtl. overrides the next step
	*/
	const handleGotoNextStep = ( step) => {
		console.log( '--> FacWiz.handleGotoNextStep', step);
		
		var nextStep = step + 1;
		
		// FacOwnership
		if( step===0) {
			if( gstate.objEdit.fac_ownership==='buyer')
				nextStep = 1;
			else
				nextStep = 2;
			
		}
		setStep( nextStep);		
						
		console.log( '<-- FacWiz.handleGotoNextStep', nextStep);
		
		return nextStep;
	}
	
	
	/**
	*	handleGotoPrevStep() - evtl. overrides the prev step
	*/
	const handleGotoPrevStep = ( step) => {
		console.log( '--> FacWiz.handleGotoPrevStep', step);
		
		var prevStep = step ? step - 1 : step;
						
		// FacType
		if( step===2) {
			if( gstate.objEdit.fac_ownership==='buyer')
				prevStep = 1;
			else
				prevStep = 0;
			
		}
		setStep( prevStep);
						
		console.log( '<-- FacWiz.handleGotoPrevStep', prevStep);
		
		return prevStep;
	}	
		
	
	const steps = [
        { name: 'Step0', component: <FacOwnership 	step={0} fctGotoPrevStep={handleGotoPrevStep} fctGotoNextStep={handleGotoNextStep}/> },	
        { name: 'Step1', component: <FacPortalId 	step={1} fctGotoPrevStep={handleGotoPrevStep} fctGotoNextStep={handleGotoNextStep}/> },			
        { name: 'Step2', component: <FacType      	step={2} fctGotoPrevStep={handleGotoPrevStep} fctGotoNextStep={handleGotoNextStep}/> },			
        { name: 'Step3', component: <FacGAddress  	step={3} fctGotoPrevStep={handleGotoPrevStep} fctGotoNextStep={handleGotoNextStep}/> },
		{ name: 'Step4', component: <FacAreas     	step={4} fctGotoPrevStep={handleGotoPrevStep} fctGotoNextStep={handleGotoNextStep}/> },
        { name: 'Step5', component: <FacNameDesc  	step={5} fctGotoPrevStep={handleGotoPrevStep} fctGotoNextStep={handleGotoNextStep}/> },
        { name: 'Step6', component: <FacPrjType  	step={6} fctGotoPrevStep={handleGotoPrevStep} fctGotoNextStep={handleGotoNextStep}/> },		
        { name: 'StepEnd', component: <StepEnd    	step={7} /> },						
    ]

		
	useEffect( () => {	

		console.log( 'use effect for', gstate.objEdit);
		
		// evtl. correct the obj step
		if( !gstate.objEdit.step)
			gstate.objEdit.step	= 0;
												
	}, []);		

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);


	const setStep = async ( step) => {
		console.log( '--> FacWiz.setStep', step);	
						
		setObjEdit({
			...gstate.objEdit,
			step: step
		})
						
		//props.jumpToStep( 0);		
								
		if( step === 7) {			
			if( props.fctSubmit) {				
				console.log( 'submitting...');				
				try {
					await props.fctSubmit();					
				} catch (error) {
					console.error('Submission failed', error);
				}
			}
		}
		
		console.log( '<-- FacWiz.setStep');				
	};
	
	
	return (	
		state.uiLoading ? (
			<div className={classes.root}>
				{state.uiLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (
			<>	
			<main className={classes.content}>								
				<form className={classes.form} noValidate>					
					<StepZilla 
						steps={steps} 
						stepsNavigation={false}
						preventEnterSubmission={true}
						nextButtonText="Weiter"
						nextButtonCls="btn btn-prev btn-primary btn-lg pull-right"
						backButtonText="Zurück"				
						backButtonCls="btn btn-next btn-secondary btn-lg pull-left"	
						onStepChange={(step) => {
							setStep( step)
						}}
						ref={stepzilla}						
					/>
				</form>				
			</main>				
			</>	
		)		
	)
}	
export default withStyles(styles)(FacWiz);