import React from "react";
import react, { useState, useEffect, useLayoutEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

import { useAuth } from "../context/provider";

const FacsSelectRoomsCount = ( props) => {
			
	const { gstate, setFacsSearchFilter } = useAuth();			

	console.log( '>>> FacsSelectRoomsCount gstate: ', gstate);	
			
	const [roomsCountMin, setRoomsCountMin] = React.useState( gstate.facsSearchFilter.fac_rooms_count_min);				
	const [roomsCountMax, setRoomsCountMax] = React.useState( gstate.facsSearchFilter.fac_rooms_count_max);
	const [roomsCountText, setRoomsCountText] = React.useState( gstate.facsSearchFilter.fac_rooms_count_text);					
	
	const makeText = () => {
				
		var txt = 'alle';
		if( roomsCountMin && roomsCountMax)
			txt = ''+roomsCountMin+' - '+roomsCountMax;
		else if( roomsCountMin)
			txt = 'von '+roomsCountMin;
		else if( roomsCountMax)
			txt = 'bis '+roomsCountMax;
		
		console.log( 'roomsCountText', txt);
		return txt; 
	}	
	
	
	useEffect( () => {
		setRoomsCountText( makeText());
		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			fac_rooms_count_text: makeText()
		});	
					
	}, [roomsCountMin, roomsCountMax]);	
		
	
	const handleChangeMin = event => {
		
		const { value } = event.target;
		if ( value.length > 2) {
			return;
		}		
		
		let rcMin = parseFloat( event.target.value);
		console.log( 'change: ' + event.target.name, rcMin);
		setRoomsCountMin( rcMin);
		
		setFacsSearchFilter( {
			...gstate.facsSearchFilter,
			fac_rooms_count_min: rcMin,
			fac_rooms_count_text: makeText()			
		});
	};			
	
	
	const handleChangeMax = event => {
		
		const { value } = event.target;
		if ( value.length > 2) {
			return;
		}		
				
		let rcMax = parseFloat( event.target.value);
		console.log( 'change: ' + event.target.name, rcMax);
		setRoomsCountMax( rcMax);

		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			fac_rooms_count_max: rcMax,
			fac_rooms_count_text: makeText()						
		});
	};				

	
	return (
		<Grid container 
			spacing={4} 
			direction="column" 
			justifyContent="center" 
			alignItems="center"
			style={{padding:10}}>
			<Grid item xs={12}>
				<TextField
					type="number"		
					placeholder="z.B. 2"																	
					variant="outlined"
					fullWidth
					id="roomsCountMin"
					label="von"
					name="roomsCountMin"
					value={roomsCountMin}									
					onChange={handleChangeMin}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					type="number"		
					placeholder="z.B. 4"																	
					variant="outlined"
					fullWidth
					id="roomsCountMax"
					label="bis"
					name="roomsCountMax"
					value={roomsCountMax}									
					onChange={handleChangeMax}
				/>
			</Grid>																
		</Grid>	
    );
	
}
export default FacsSelectRoomsCount;