// facRenovationGeneral.js

import react, { useState, useEffect, useLayoutEffect } from 'react';
import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles }  from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as FacHouseType100Icon } from "../assets/fac_house_type_100.svg";
import { ReactComponent as FacHouseType200Icon } from "../assets/fac_house_type_200.svg";
import { ReactComponent as FacHouseType300Icon } from "../assets/fac_house_type_300.svg";
import { ReactComponent as FacHouseType400Icon } from "../assets/fac_house_type_400.svg";
import { ReactComponent as FacHouseType500Icon } from "../assets/fac_house_type_500.svg";
import { ReactComponent as FacHouseType600Icon } from "../assets/fac_house_type_600.svg";

import { ReactComponent as FacOutlineShape100Icon } from "../assets/fac_outline_shape_100.svg";
import { ReactComponent as FacOutlineShape200Icon } from "../assets/fac_outline_shape_200.svg";
import { ReactComponent as FacOutlineShape300Icon } from "../assets/fac_outline_shape_300.svg";
import { ReactComponent as FacOutlineShape400Icon } from "../assets/fac_outline_shape_400.svg";
import { ReactComponent as FacOutlineShape500Icon } from "../assets/fac_outline_shape_500.svg";

import { ReactComponent as FacRoofShape100Icon } from "../assets/fac_roof_shape_100.svg";
import { ReactComponent as FacRoofShape200Icon } from "../assets/fac_roof_shape_200.svg";
import { ReactComponent as FacRoofShape300Icon } from "../assets/fac_roof_shape_300.svg";
import { ReactComponent as FacRoofShape400Icon } from "../assets/fac_roof_shape_400.svg";

import Crop169Icon from '@mui/icons-material/Crop169';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';

import MomentUtils from "@date-io/moment";
import moment from 'moment';
//import { DatePicker } from '@mui/x-date-pickers';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { faHome, faBuilding, faMap, faIndustry, faHouse, faKey } from '@fortawesome/fontawesome-free-solid'
import Box from './Box';

import StepZilla from "react-stepzilla"; 

import ButtonCounter from './buttonCounter';
import QeeRadioGroup from './qeeRadioGroup';
import QeeHintBox from './qeeHintBox';

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		width: '98%',
		marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	formControlLabel: {
		width:200, 
		padding:30, 
		margin: 20,
		border: 'solid 1px gray',
		border: `1px solid ${theme.palette.primary.main}`
	},
	radio: {
		color: theme.palette.primary.main
	}
});


const FacValuationGeneral = ( props) => {
	
	const thisStep = props.step;			
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();				
	
	const [validationErrors, setValidationErrors] = useState( []);			
		
	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);	
	
	
	const handleChange = (event) => {
		console.log( 'change: ' + event.target.name, event.target.value);
		
		var fac = gstate.objEdit;
		fac[event.target.name] = event.target.value;				
		setObjEdit( fac);		
	};
	
	
	const handleChangeConstructionYear = (date) => {
		const year = moment(date).format("YYYY"); // add this
		setObjEdit({
			...gstate.objEdit,
			fac_construction_year: year
		})
	};
	
	
	useEffect(()=> {  
		console.log( 'FacRenovationGeneral useEffect', thisStep);
		
		const nextBtn = document.getElementById( 'next-button');
		console.log( 'STEP compare: ' + thisStep, gstate.objEdit.step);
		if( thisStep == gstate.objEdit.step+1) {
			nextBtn.onclick=null;
			nextBtn.onclick=(e) => {
				console.log( 'reno gen');				
				if( validate( e) > 0) {
					console.log( 'CHECK reno gen!');
					e.stopPropagation();
				} 
				setValidationErrors( []); 	
				console.log( 'objEdit', gstate.objEdit);
			}    
		} 		
								
	}, [JSON.stringify( gstate.objEdit)]);	


	useEffect(()=> {  	
		if( gstate.objEdit.step > 0)
			props.jumpToStep( gstate.objEdit.step);	
	}, []);			
		
		
	/**
	* validate() - 
	*/
	const validate = (e) => { 
		
		console.log( '--> validate for FacRenovationGeneral: ', gstate.objEdit.step);
		
		var errs = false;		
		const validationErrors= [null];
		
		//console.log( 'objErrors', gstate.objErrors);
		
		if( gstate.objEdit.fac_type == '100' && gstate.objEdit.fac_house_type === undefined) {
			const msg = 'Du musst einen Haustyp auswählen!';
			validationErrors[0] = msg;
			gstate.objErrors.fac_house_type = msg;			
		}
		if( gstate.objEdit.fac_type == '200' && gstate.objEdit.fac_apartment_type === undefined) {
			const msg = 'Du musst einen wohnungstyp auswählen!';
			validationErrors[0] = msg;
			gstate.objErrors.fac_apartment_type = msg;			
		}		
		
		if( gstate.objEdit.fac_plot_form === undefined) {
			const msg = 'Du musst eine Grundstücksform eingeben!';
			validationErrors[0] = msg;
			gstate.objErrors.fac_plot_form = msg;			
		}
		
		if( !gstate.objEdit.fac_living_area) {
			const msg = 'Du musst eine Wohnfläche eingeben!';
			validationErrors[0] = msg;		
			gstate.objErrors.fac_living_area = msg;
		}
		if( gstate.objEdit.fac_type == '100' && !gstate.objEdit.fac_plot_area) {
			const msg = 'Du musst eine Grundstücksfläche eingeben!';
			validationErrors[0] = msg;		
			gstate.objErrors.fac_plot_area = msg;
		}		

		if( validationErrors[0]) {
			//alert( validationErrors[0]);			
			errs = true;
			setValidationErrors( validationErrors); 			
		} 		
		
		console.log( '<-- validate: ' + errs)		
		return errs;
	}
	
	
	const rgIconStyle = () => {
		if( !gstate.isXS)
			return { width:100, height:100 }
		else
			return { width:36, height:36 }
	}		
	
				
	return (
        <div class="form-group">
			<form  noValidate>					
				<Grid container 
					spacing={2} 
					direction="column" 
					justifyContent="left" 
					alignItems="left">
					<Grid item xs={12}>
						<h1>Allgemeine Daten</h1>		
					</Grid>
					
					<Grid item xs={12} md={6}>
						<Typography variant='h5' component='h5' style={{ fontWeight: 'bold' }}>
							In welchem Jahr wurde die Immobilie erbaut?
						</Typography>
						<MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}> 							
							<DatePicker
								variant="outlined"
								InputProps={{
									disableOutline: false,
								}}
								views={['year']}
								label="Baujahr"
								required
								fullWidth
								minDate={new Date('1900')}
								disableFuture
								value={''+gstate.objEdit.fac_construction_year}
								onChange={handleChangeConstructionYear}
								renderInput={(params) => <OutlinedInput {...params} helperText={null} />}
							/>							
						</MuiPickersUtilsProvider>
						<QeeHintBox type="info" text={<React.Fragment>
							<span dangerouslySetInnerHTML={{__html: 'Das Baujahr der Immobilie ist für alle Berechnungen besonders wichtig. Unsere Algorithmen basieren auf statischen Daten, und das Baujahr ermöglicht es, soweit wie möglich präzise Ergebnisse zu liefern.'}}></span></React.Fragment>} style={{marginTop:20}}
						/>
					</Grid>					

					
					
					<Grid item xs={12}>
						{gstate.objEdit.fac_type == '100' ?									
						<QeeRadioGroup
							formLabel='Haustyp'
							defaultValue={gstate.objEdit.fac_house_type}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_house_type', ev.target.value);
								setObjEdit({
									...gstate.objEdit,
									fac_house_type: ev.target.value
								})
								console.log( 'objEdit', gstate.objEdit)
							}}
							sdataName='sdata_houses_types'
							hasDesc={true}
							data={[
								{value:'100', icon:<FacHouseType100Icon style={rgIconStyle()}/>},
								{value:'200', icon:<FacHouseType200Icon style={rgIconStyle()}/>},
								{value:'300', icon:<FacHouseType300Icon style={rgIconStyle()}/>},
								{value:'400', icon:<FacHouseType400Icon style={rgIconStyle()}/>},								
								{value:'500', icon:<FacHouseType500Icon style={rgIconStyle()}/>},								
								{value:'600', icon:<FacHouseType600Icon style={rgIconStyle()}/>},																
							]}
						/>
						:
						<QeeRadioGroup
							formLabel='Wohnungstyp'
							defaultValue={gstate.objEdit.fac_apartment_type}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_apartment_type', ev.target.value);
								setObjEdit({
									...gstate.objEdit,
									fac_apartment_type: ev.target.value
								})
								console.log( 'objEdit', gstate.objEdit)
							}}
							sdataName='sdata_apartments_types'
							hasDesc={true}
						/>						
						}
						{ validationErrors[0] ? ( <div style={{textAlign:"center"}}><small id="selError" className="form-text error">{validationErrors[0]}</small></div> ) :( <></> ) }					
					</Grid>					
							
										
					<Grid item xs={12}>
					<Typography variant="h2">Flächen</Typography>
					<Grid container
						spacing={2} 
						direction="row" 
						justifyContent="left" 
						alignItems="left">
						{gstate.objEdit.fac_type == '100' ?				
						<Grid item xs={10} md={4} lg={4} xl={4}>
							<TextField
								type="number"		
								placeholder="z.B. 350"																	
								InputProps={{ 
									startAdornment: <InputAdornment position="start">m2</InputAdornment>										
								}} 	
								variant="outlined"
								required
								fullWidth
								id="facPlotArea"
								label="Grundstücksfläche"
								name="fac_plot_area"
								autoComplete="facPlotArea"
								value={gstate.objEdit.fac_plot_area}									
								helperText={gstate.objErrors.fac_plot_area}
								error={gstate.objErrors.fac_plot_area ? true : false}
								onChange={handleChange}
							/>
						</Grid>
						:''}
						
						<Grid item xs={10} md={4} lg={4} xl={4}>
							<TextField
								type="number"		
								placeholder="z.B. 120"																	
								InputProps={{ 
									startAdornment: <InputAdornment position="start">m2</InputAdornment>										
								}} 	
								variant="outlined"
								required
								fullWidth
								id="facLivingArea"
								label="Wohnfläche (beheizt)"
								name="fac_living_area"
								autoComplete="facLivingArea"
								value={gstate.objEdit.fac_living_area}									
								helperText={gstate.objErrors.fac_living_area}
								error={gstate.objErrors.fac_living_area ? true : false}
								onChange={handleChange}
							/>
						</Grid>	

						<Grid item xs={10} md={4} lg={4} xl={4}>
							<TextField
								type="number"		
								placeholder="z.B. 50"																	
								InputProps={{ 
									startAdornment: <InputAdornment position="start">m2</InputAdornment>										
								}} 	
								variant="outlined"
								required
								fullWidth
								id="facLivingArea"
								label="Nutzfläche (unbeheizt)"
								name="fac_usable_area"
								autoComplete="facLivingArea"
								value={gstate.objEdit.fac_usable_area}									
								helperText={gstate.objErrors.fac_usable_area}
								error={gstate.objErrors.fac_usable_area ? true : false}
								onChange={handleChange}
							/>
						</Grid>	
					</Grid>
					</Grid>
					
					<Grid item xs={12}>
						<Typography variant="h2">Geschosse</Typography>
						<ButtonCounter 
							objData={gstate.objEdit} setObjData={setObjEdit} 
							field={'fac_floors_count'} defaultValue={1} min={1} max={80} step={1}  label={'Anzahl Geschosse'}
						/>
					</Grid>
					
					<Grid item xs={12}>
						<Typography variant="h2">Zimmer</Typography>
						<ButtonCounter 
							objData={gstate.objEdit} setObjData={setObjEdit} 
							field={'fac_rooms_count'} defaultValue={1} min={1} max={16} step={0.5}  label={'Anzahl Zimmer'}
						/>
					</Grid>	
					
					<Grid item xs={12}>
						<Typography variant="h2">Badezimmer</Typography>
						<ButtonCounter 
							objData={gstate.objEdit} setObjData={setObjEdit} 
							field={'fac_bathrooms_count'} defaultValue={1} min={1} max={5} step={1} label={'Anzahl Badezimmer'}
						/>
					</Grid>	

					
				</Grid>
			</form>
		</div>
	);

}	
export default withStyles(styles)( FacValuationGeneral);