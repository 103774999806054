// facRenovationGeneral.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles }  from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DateFnsUtils from '@date-io/date-fns';

import { ReactComponent as FacHouseType100Icon } from "../assets/fac_house_type_100.svg";
import { ReactComponent as FacHouseType200Icon } from "../assets/fac_house_type_200.svg";
import { ReactComponent as FacHouseType300Icon } from "../assets/fac_house_type_300.svg";
import { ReactComponent as FacHouseType400Icon } from "../assets/fac_house_type_400.svg";
import { ReactComponent as FacHouseType500Icon } from "../assets/fac_house_type_500.svg";
import { ReactComponent as FacHouseType600Icon } from "../assets/fac_house_type_600.svg";

import { ReactComponent as FacOutlineShape100Icon } from "../assets/fac_outline_shape_100.svg";
import { ReactComponent as FacOutlineShape200Icon } from "../assets/fac_outline_shape_200.svg";
import { ReactComponent as FacOutlineShape300Icon } from "../assets/fac_outline_shape_300.svg";
import { ReactComponent as FacOutlineShape400Icon } from "../assets/fac_outline_shape_400.svg";
import { ReactComponent as FacOutlineShape500Icon } from "../assets/fac_outline_shape_500.svg";

import { ReactComponent as FacRoofShape100Icon } from "../assets/fac_roof_shape_100.svg";
import { ReactComponent as FacRoofShape200Icon } from "../assets/fac_roof_shape_200.svg";
import { ReactComponent as FacRoofShape300Icon } from "../assets/fac_roof_shape_300.svg";
import { ReactComponent as FacRoofShape400Icon } from "../assets/fac_roof_shape_400.svg";

import Crop169Icon from '@mui/icons-material/Crop169';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';

import MomentUtils from "@date-io/moment";
import moment from 'moment';
//import { DatePicker } from '@mui/x-date-pickers';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { faHome, faBuilding, faMap, faIndustry, faHouse, faKey } from '@fortawesome/fontawesome-free-solid'
import Box from './Box';
import QeeHintBox from './qeeHintBox';

import StepZilla from "react-stepzilla"; 

import ButtonCounter from './buttonCounter';
import QeeRadioGroup from './qeeRadioGroup';

import { useValidation } from './qeeStepValidationProvider';

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0.5)
		}		
	},
	form: {
		marginTop: theme.spacing(3),		
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
			marginTop: theme.spacing(1)
		},
	},
	toolbar: theme.mixins.toolbar,
	validationContainer: {
		borderRadius:5,
		padding:5,
		[theme.breakpoints.down('xs')]: {
			padding:0,
		},				
		backgroundColor: '#fff'
	},
	validationContainerActive: {
		borderRadius:5,
		padding:5,
		[theme.breakpoints.down('xs')]: {
			padding:0,
		},		
		backgroundColor: '#fee'
	}	
});


const FacRenovationGeneral = ( props) => {

	console.log( '>>> FacRenovationGeneral props: ', props);

	const { registerValidation } = useValidation();	
	
	const formMain = useRef( null);	
	const errorRef = useRef(null);
	
	const thisStep = props.step;			
	
	const { gstate, setObjEdit } = useAuth();				
	
	const [validationErrors, setValidationErrors] = useState( []);	
	const [objChange, setObjChange] = useState({
												fac_construction_year: 	gstate.objEdit.fac_construction_year,
												fac_house_type: 		gstate.objEdit.fac_house_type,
												fac_apartment_type: 	gstate.objEdit.fac_apartment_type,
												fac_outline_shape: 		gstate.objEdit.fac_outline_shape,
												fac_roof_shape: 		gstate.objEdit.fac_roof_shape,
												fac_plot_area: 			gstate.objEdit.fac_plot_area,											
												fac_living_area: 		gstate.objEdit.fac_living_area,
												fac_usable_area: 		gstate.objEdit.fac_usable_area,
												fac_has_basement: 		gstate.objEdit.fac_has_basement,
												fac_floors_count: 		gstate.objEdit.fac_floors_count,
												fac_rooms_count: 		gstate.objEdit.fac_rooms_count,
												fac_bathrooms_count: 	gstate.objEdit.fac_bathrooms_count
											});						
	const objChangeRef = useRef( objChange);
	useEffect(() => {
		objChangeRef.current = objChange;  // Update ref on state change
		console.log( 'objChange.current', objChange)
	}, [objChange]);											
	const [objErrors, setObjErrors] = useState({});	

																							
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);	
	
	
	const handleChange = (event) => {
		console.log( 'change: ' + event.target.name, event.target.value);
		
		/*
		var fac = gstate.objEdit;
		fac[event.target.name] = event.target.value;				
		setObjEdit( fac);		
		*/	
		var och = {
			...objChange,
			[event.target.name]: event.target.value
		};
		setObjChange( och);								
	};
	
	
	const handleChangeFacPlotArea = ( ev) => { 
	
		var vl = ev.target.value;	
		console.log( 'handleChangeFacPlotArea: ', vl)			
		//setObjErrors({});
		var och = {
			...objChange,
			fac_plot_area: vl
		};
		setObjChange( och);						
	}		
	
	
	const handleChangeFacLivingArea = ( ev) => { 
	
		var vl = ev.target.value;	
		console.log( 'handleChangeFacLivingArea: ', vl)			
		//setObjErrors({});
		var och = {
			...objChange,
			fac_living_area: vl
		};
		setObjChange( och);						
	}			
	
	
	const handleChangeFacUsableArea = ( ev) => { 
	
		var vl = ev.target.value;	
		console.log( 'handleChangeFacUsableArea: ', vl)			
		//setObjErrors({});
		var och = {
			...objChange,
			fac_usable_area: vl
		};
		setObjChange( och);						
	}				
	
	
	const handleObjChange = ( och) => { 
		console.log( 'handleObjChange: ', och)			
		//setObjErrors({});
		setObjChange( och);						
	}					
	
	
	const handleChangeConstructionYear = (date) => {
		const year = moment(date).format("YYYY"); // add this
		/*
		setObjEdit({
			...gstate.objEdit,
			fac_construction_year: year
		})
		*/
		var och = {
			...objChange,
			fac_construction_year: year
		};		
		setObjChange( och);						
	};
		
	
	const resetErrors = ( id) => {				
		setObjErrors({});
		document.getElementById( id).style.backgroundColor = '#fff';				
	}

					
	const handleUsePlanRenovation = (oChange) => { 
		console.log( 'handleUsePlanRenovation: ', oChange)	
		setValidationErrors( [null]);
		setObjChange( oChange);		
	}		
		
		
	const showError = (fieldId) => {

		const errorElement = document.getElementById(fieldId);
		console.log('showError Element ' + fieldId + ': ' + errorElement.id);
		if (errorElement) {
			errorRef.current = errorElement;
		}
		return fieldId;		
	};
	
	
	useEffect(() => {
		const errorElements = document.querySelectorAll('.selError');
		if (errorElements.length > 0) {
			// Defer the background color change
			setTimeout(() => {
				errorElements.forEach(element => {
					const parentElement = element.parentNode;
					if (parentElement) {
						parentElement.style.backgroundColor = '#fee';
						parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
					}
				});
			}, 0);
		}
	}, [errorRef.current]);


	const validate = async () => {
		console.log('--> validate for FacRenovationGeneral in step: ', gstate.objEdit.step);
		let firstErrorShown = false; // Flag to track if the first error has been shown
		let errors = false;
		const objErrors = {};

		if (gstate.objEdit.fac_type === '100') {
			if (!objChangeRef.current.fac_house_type && !firstErrorShown) {
				objErrors.fac_house_type = 'Du musst einen Haustyp auswählen!';
				showError('fac_house_type');
				errors = true;
				firstErrorShown = true; // Set the flag to true after showing the first error
			}

			if (!objChangeRef.current.fac_outline_shape && !firstErrorShown) {
				objErrors.fac_outline_shape = 'Du musst eine Grundrisform auswählen!';
				showError('fac_outline_shape');
				errors = true;
				firstErrorShown = true; // Set the flag to true after showing the first error
			}

			if (!objChangeRef.current.fac_roof_shape && !firstErrorShown) {
				objErrors.fac_roof_shape = 'Du musst eine Dachform auswählen!';
				showError('fac_roof_shape');
				errors = true;
				firstErrorShown = true; // Set the flag to true after showing the first error
			}

			if (!objChangeRef.current.fac_plot_area && !firstErrorShown) {
				objErrors.fac_plot_area = 'Du musst eine Grundstücksfläche eingeben!';
				showError('fac_plot_area');
				errors = true;
				firstErrorShown = true; // Set the flag to true after showing the first error
			}
			
		} else if (gstate.objEdit.fac_type === '200') {
			if (!objChangeRef.current.fac_apartment_type && !firstErrorShown) {
				objErrors.fac_apartment_type = 'Du musst einen Wohnungstyp auswählen!';
				showError('fac_apartment_type');
				errors = true;
				firstErrorShown = true; // Set the flag to true after showing the first error
			}
		}

		if (!objChangeRef.current.fac_living_area && !firstErrorShown) {
			objErrors.fac_living_area = 'Du musst eine Wohnfläche eingeben!';
			showError('fac_living_area');
			errors = true;
			firstErrorShown = true; // Set the flag to true after showing the first error
		}
		if (!objChangeRef.current.fac_has_basement && !firstErrorShown) {
			objErrors.fac_has_basement = 'Du musst eine Auswahl treffen!';
			showError('fac_has_basement');
			errors = true;
			firstErrorShown = true; // Set the flag to true after showing the first error
		}

		setObjErrors(objErrors);
		if (!errors) {
			let oe = {
				...gstate.objEdit, 
				...objChangeRef.current,
				fac_prj_type: 'renovation'   // set here the prj type
			};
			setObjEdit( await props.fctSubmit( oe));			
		}

		console.log('<-- validate: ' + errors);
		return !errors;
	};

	
	useEffect(()=> {  		 
		//if( gstate.objEdit.step > 0)
			//props.jumpToStep( gstate.objEdit.step);	
		
		formMain.current.scrollIntoView() 
	
	}, []);	

	
	useEffect(() => {
		registerValidation( thisStep, validate);

		// Clean up: unregister validation when the component unmounts or the step changes
		return () => {
			registerValidation( thisStep, null);
		};
	}, []);
	
		
	const rgIconStyle = () => {
		if( !gstate.isXS)
			return { width:100, height:100 }
		else
			return { width:36, height:36 }
	}		

		
	return (
        <div class="form-group">
			<form  noValidate ref={formMain}>					
				<Grid container 
					spacing={gstate.isXS ? 2 : 6} 
					direction="column" 
					justifyContent="left" 
					alignItems="left">
					<Grid item xs={12}>
						<h1>Allgemeine Daten</h1>		
					</Grid>
					
					<Grid item xs={12} md={6}>
						<Typography variant='h5' component='h5' style={{ fontWeight: 'bold' }}>
							In welchem Jahr wurde die Immobilie erbaut?
						</Typography>
						<MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}> 							
							<DatePicker
								variant="outlined"
								InputProps={{
									disableOutline: false,
								}}
								views={['year']}
								label="Baujahr"
								required
								fullWidth
								minDate={new Date('1900')}
								disableFuture
								value={''+objChange.fac_construction_year}
								onChange={handleChangeConstructionYear}
								renderInput={(params) => <OutlinedInput {...params} helperText={null} />}
							/>							
						</MuiPickersUtilsProvider>
						<QeeHintBox type="info" text={<React.Fragment>
							<span dangerouslySetInnerHTML={{__html: 'Das Baujahr der Immobilie ist für alle Berechnungen besonders wichtig. Unsere Algorithmen basieren auf statischen Daten, und das Baujahr ermöglicht es, soweit wie möglich präzise Ergebnisse zu liefern.'}}></span></React.Fragment>} style={{marginTop:20}}
						/>
					</Grid>					
					
					<Grid item xs={12}>
						{gstate.objEdit.fac_type == '100' ?
							<div id='fac_house_type' className={classes.validationContainer}>
							<QeeRadioGroup								
								formLabel='Haustyp *'
								defaultValue={objChange.fac_house_type}
								fctOnChange={( ev)=>{
									console.log( 'onChange fac_house_type', ev.target.value);
									var och = {
										...objChange,
										fac_house_type: ev.target.value
									};
									setObjChange( och);
									resetErrors( 'fac_house_type');
								}}
								sdataName='sdata_houses_types'
								hasDesc={true}								
								data={[
									{value:'100', icon:<FacHouseType100Icon style={rgIconStyle()}/>},
									{value:'200', icon:<FacHouseType200Icon style={rgIconStyle()}/>},
									{value:'300', icon:<FacHouseType300Icon style={rgIconStyle()}/>},
									{value:'400', icon:<FacHouseType400Icon style={rgIconStyle()}/>},								
									{value:'500', icon:<FacHouseType500Icon style={rgIconStyle()}/>},								
									{value:'600', icon:<FacHouseType600Icon style={rgIconStyle()}/>},																
								]}
							/>
							{ objErrors.fac_house_type ? ( <div style={{textAlign:"center"}}><small   className="selError form-text error">{objErrors.fac_house_type}</small></div> ) :( <></> ) }
							</div>
						:
							<div id='fac_apartment_type' className={classes.validationContainer}>
							<QeeRadioGroup
								formLabel='Wohnungstyp *'
								defaultValue={objChange.fac_apartment_type}
								fctOnChange={( ev)=>{
									console.log( 'onChange fac_apartment_type', ev.target.value);
									var och = {
										...objChange,
										fac_apartment_type: ev.target.value
									};
									setObjChange( och);
									resetErrors( 'fac_apartment_type');
								}}
								sdataName='sdata_apartments_types'
								hasDesc={true}
							/>
							</div>
						}						
						{ objErrors.fac_apartment_type ? ( <div style={{textAlign:"center"}}><small   className="selError form-text error">{objErrors.fac_apartment_type}</small></div> ) :( <></> ) }
					</Grid>					
							
					{gstate.objEdit.fac_type == '100' ?				
					<Grid item xs={12}>
						<div id='fac_outline_shape' className={classes.validationContainer}>
						<QeeRadioGroup
							formLabel='Grundrissform *'
							defaultValue={objChange.fac_outline_shape}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_outline_shape', ev.target.value);
									var och = {
										...objChange,
										fac_outline_shape: ev.target.value
									};
									setObjChange( och);
									resetErrors( 'fac_outline_shape');
							}}
							sdataName='sdata_outlines_shapes'
							data={[
								{value:'100', icon:<FacOutlineShape100Icon style={rgIconStyle()} />},							
								{value:'200', icon:<FacOutlineShape200Icon style={rgIconStyle()} />},
								{value:'300', icon:<FacOutlineShape300Icon style={rgIconStyle()} />},								
								{value:'400', icon:<FacOutlineShape400Icon style={rgIconStyle()} />},	
								{value:'500', icon:<FacOutlineShape500Icon style={rgIconStyle()} />},	
							]}
						/>
						{ objErrors.fac_outline_shape ? ( <div style={{textAlign:"center"}}><small   className="selError form-text error">{objErrors.fac_outline_shape}</small></div> ) :( <></> ) }
						</div>
					</Grid>
					:''}
					
					{gstate.objEdit.fac_type == '100' ?
					<Grid item xs={12}>
						<div id='fac_roof_shape' className={classes.validationContainer}>
						<QeeRadioGroup
							formLabel='Dachform *'
							defaultValue={objChange.fac_roof_shape}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_roof_shape', ev.target.value);
								var och = {
									...objChange,
									fac_roof_shape: ev.target.value
								};
								setObjChange( och);
								resetErrors( 'fac_roof_shape');
							}}
							sdataName='sdata_roofs_shapes'
							hasDesc={true}
							data={[
								{value:'100', icon:<FacRoofShape100Icon style={rgIconStyle()}/>},
								{value:'200', icon:<FacRoofShape200Icon style={rgIconStyle()}/>},
								{value:'300', icon:<FacRoofShape300Icon style={rgIconStyle()}/>},
								{value:'400', icon:<FacRoofShape400Icon style={rgIconStyle()}/>},
							]}
						/>
						{ objErrors.fac_roof_shape ? ( <div style={{textAlign:"center"}}><small   className="selError form-text error">{objErrors.fac_roof_shape}</small></div> ) :( <></> ) }
						</div>
					</Grid>			
					:''}
					
					<Grid item xs={12}>
					<Typography variant="h2">Flächen</Typography>
					<Grid container
						spacing={gstate.isXS ? 2 : 6} 
						direction="row" 
						justifyContent="left" 
						alignItems="left">
						{gstate.objEdit.fac_type == '100' ?
						<Grid item xs={10} md={4} lg={4} xl={4}>
							<div id='fac_plot_area'>
							<TextField
								type="number"		
								placeholder="z.B. 350"																	
								InputProps={{ 
									startAdornment: <InputAdornment position="start">m2</InputAdornment>										
								}} 	
								variant="outlined"
								required
								fullWidth
								id="facPlotArea"
								label="Grundstücksfläche"
								name="fac_plot_area"
								autoComplete="facPlotArea"
								value={objChange.fac_plot_area}									
								helperText={objErrors.fac_plot_area}
								error={objErrors.fac_plot_area ? true : false}
								onChange={handleChangeFacPlotArea}
							/>
							</div>
						</Grid>
						:''}
						
						<Grid item xs={10} md={4} lg={4} xl={4}>
							<div id='fac_living_area'>
							<TextField
								type="number"		
								placeholder="z.B. 120"																	
								InputProps={{ 
									startAdornment: <InputAdornment position="start">m2</InputAdornment>										
								}} 	
								variant="outlined"
								required
								fullWidth
								id="facLivingArea"
								label="Wohnfläche (beheizt)"
								name="fac_living_area"
								autoComplete="facLivingArea"
								value={objChange.fac_living_area}									
								helperText={objErrors.fac_living_area}
								error={objErrors.fac_living_area ? true : false}
								onChange={handleChangeFacLivingArea}
							/>
							</div>
						</Grid>	

						<Grid item xs={10} md={4} lg={4} xl={4}>
							<TextField
								type="number"		
								placeholder="z.B. 50"																	
								InputProps={{ 
									startAdornment: <InputAdornment position="start">m2</InputAdornment>										
								}} 	
								variant="outlined"
								fullWidth
								id="facUsableArea"
								label="Nutzfläche (unbeheizt)"
								name="fac_usable_area"
								autoComplete="facUsableArea"
								value={objChange.fac_usable_area}									
								helperText={objErrors.fac_usable_area}
								error={objErrors.fac_usable_area ? true : false}
								onChange={handleChangeFacUsableArea}
							/>
						</Grid>	
					</Grid>
					</Grid>
					
					<Grid item xs={12}>
						<Typography variant="h2">Geschosse</Typography>
						<ButtonCounter 
							objData={objChange} setObjData={handleObjChange} 
							field={'fac_floors_count'} defaultValue={1} min={1} max={80} step={1}  label={'Anzahl Geschosse'}/>
					</Grid>
					
					<Grid item xs={12}>
						<Typography variant="h2">Zimmer</Typography>
						<ButtonCounter 
							objData={objChange} setObjData={handleObjChange} 
							field={'fac_rooms_count'} defaultValue={1} min={1} max={16} step={0.5}  label={'Anzahl Zimmer'}/>
					</Grid>	
					
					<Grid item xs={12}>
						<Typography variant="h2">Badezimmer</Typography>
						<ButtonCounter 
							objData={objChange} setObjData={handleObjChange} 
							field={'fac_bathrooms_count'} defaultValue={1} min={1} max={5} step={1} label={'Anzahl Badezimmer'}/>
					</Grid>	

					<Grid item xs={12}>
						<div id='fac_has_basement' className={classes.validationContainer}>
						<QeeRadioGroup
							formLabel='Keller *'
							defaultValue={objChange.fac_has_basement}
							fctOnChange={( ev)=>{
								console.log( 'onChange fac_has_basement', ev.target.value);
								var och = {
									...objChange,
									fac_has_basement: ev.target.value
								};
								setObjChange( och);
								resetErrors( 'fac_has_basement');
							}}
							sdata={[
								{value:'1', label:'Ja'},
								{value:'0', label:'Nein'},
							]}
						/>
						{ objErrors.fac_has_basement ? ( <div style={{textAlign:"center"}}><small   className="selError form-text error">{objErrors.fac_has_basement}</small></div> ) :( <></> ) }
						</div>		
					</Grid>					
					
				</Grid>
			</form>
		</div>
	);

}	
//export default withStyles(styles)( FacRenovationGeneral);
const MemoizedFacRenovationGeneral = React.memo(FacRenovationGeneral);
export default withStyles(styles)(MemoizedFacRenovationGeneral);