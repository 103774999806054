import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { makeStyles } from '@material-ui/core/styles';
import OrgMarker from './orgMarker';
import FacMarker from './facMarker';

const useStyles = makeStyles({
	mapContainer: {
		height: '100%',
		width: '100%',
		borderRadius: 10,
		overflow: 'hidden', // Ensures the rounded corners are visible
	}
});

const SetBounds = ({ bounds }) => {
	const map = useMap();
	useEffect(() => {
		if (bounds.length > 0) {
			map.fitBounds(bounds);
		}
	}, [bounds]);
	return null;
};

const FacOrgsMap = ({ orgs, onOrgClick, ...props }) => {
	const classes = useStyles();
	const mapRef = useRef();

	const bounds = orgs.map(org => [org.addr_geo_lat_o, org.addr_geo_lon_o]);
	
	const handleMarkerClick = (org) => {
        if (onOrgClick) {
            onOrgClick(org);
        }
    };	

	useEffect(() => {
		const handleMouseDown = (event) => {
			L.DomEvent.stopPropagation(event);
		};

		if (mapRef.current) {
			mapRef.current.leafletElement.on('mousedown', handleMouseDown);
		}

		return () => {
			if (mapRef.current) {
				mapRef.current.leafletElement.off('mousedown', handleMouseDown);
			}
		};
	}, [mapRef]);

	const fac = props.fac;

	const allBounds = [...bounds, [fac.addr_geo_lat_o, fac.addr_geo_lon_o]];

	return (
		<MapContainer
			center={[fac.addr_geo_lat_o, fac.addr_geo_lon_o]}
			zoom={10}
			className={classes.mapContainer}
			whenCreated={(map) => (mapRef.current = map)}
		>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			/>
			<SetBounds bounds={allBounds} />
			{orgs.map((org) => (
				<OrgMarker key={org.id} org={org} onOrgClick={() => handleMarkerClick(org)} />
			))}
			<FacMarker fac={fac} onFacClick={onOrgClick} />
		</MapContainer>
	);
};

export default FacOrgsMap;
