import react, { useState, useEffect, useLayoutEffect } from 'react';
import React from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles }  from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import ConstructionIcon from '@mui/icons-material/Construction';
import WindPowerIcon from '@mui/icons-material/WindPower';
import Co2Icon from '@mui/icons-material/Co2';
import SavingsIcon from '@mui/icons-material/Savings';
import GavelIcon from '@mui/icons-material/Gavel';
import moment from 'moment';

import LabelEnergyClass from './labelEnergyClass';
import DiagramEnergyClass from './diagramEnergyClass';
import QeeHintBox from './qeeHintBox';
import {formatNumber} from '../util/helpers';

import { useAuth } from "../context/provider";

const FacRenovationIst = ( props) => { 
	
	const thisStep = props.step;		
	
	const { gstate } = useAuth();				
	
	// some consts
	const useStyles = makeStyles( theme => ({
		//...props
		accordionSummary: {
			backgroundColor: '#f00'
		},
		accordionBox: {
			fontWeight:'bold', 
			m:1, 
			lineHeight:1.2, 
			color:theme.palette.primary.contrastText,
		},
		icon:{
			color:theme.palette.primary.contrastText,
		},
		iconDark:{
			color:theme.palette.primary.main,
		},
		iconH2:{
			color:theme.typography.success?.color,   //theme.palette.primary.main,
		},		
		textOutput:{
			margin:20,
		},
		resize:{
			fontWeight:800,
		},		
		subTitle: {
			color: '#444',
			fontWeight: 'bold'
		},
		box: {
			//width:500, 
			//height:300,
			backgroundColor:theme.palette.primary.contrastText,
			border: 'solid 1px green',  // #E4EAE6
			padding:20,
			margin:20,
			borderRadius: 5,
		},
		switchTrack: theme.components.Switch.switchTrack,
		switchBase: theme.components.Switch.switchBase,
		switchPrimary: theme.components.Switch.switchPrimary,
		resultCard: {
			display: 'flex', 
			width:'100%',
			height:120,
			padding:10,
			marginBottom: 10
		},
		textIst: {
			color:theme.typography.error.color,
			fontWeight: 800,
		},
		textPlan: {
			color:theme.typography.success?.color,
			fontWeight: 800,			
		},
		h2: {
			whiteSpace: 'nowrap',
		}
	}));			
	const classes = useStyles( props);
	
	
	const sxIconH2 = {
		fontSize:36, 
		marginRight:1,
	}
	
	
	const evalItem = ( field, unit) =>  {
		var vl = 0;
		try {
			vl = eval( 'gstate.objEdit' + field);
			vl = formatNumber( vl, unit);
		} catch( e) {
			console.error( e);
		}
		return vl;
	}


	const getHintEnergy = () => {
		return `Im Durchschnitt hat in Deutschland ein Einfamilienhaus mit <strong>150m²</strong> Wohnfläche einen Endenergieverbrauch von <strong>212 kWh/m²*Jahr</strong>.`
	}
	
	const getHintCo2 = () => {
		return `Somit betragen bei einem Einfamilienhaus mit <strong>150m²</strong> Wohnfläche mit einer Standard-Ölheizung die CO2-Emissionen im bundesweiten Durchschnitt ca. <strong>8,4to/Jahr</strong>.`
	}	
	
	const getHintHeatingRenovation = () => {
		return `Bei berechneten Endenergiebedarfen von über <strong>500 kWh/qm*Jahr</strong> besteht eine sehr hohe Wahrscheinlichkeit, dass durchgeführte Sanierungsmaßnahmen entweder nicht benannt oder nicht eingegeben wurden. Bitte prüfe noch einmal den Sanierungsstand der Immobilie.`
	}		
		
	const EnergyComp = () => {
		return(
		<>		
		<Typography variant='h2'><WindPowerIcon className={classes.iconH2} sx={sxIconH2}/>Energiebedarf</Typography>				
		<Grid container direction="column">
			<Grid item xs={12}>
			<Grid container 
				spacing={2} 
				direction="row" 
				justifyContent="left" 
				alignItems="left"
				style={{maxWidth:1000}}>
				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>	
					<Box className={classes.box} style={{marginLeft:0, marginTop:0, width:'100%', maxWidth:800}}>						
						<DiagramEnergyClass 
							showEnergyPlan={false}
							energyIst='end_ist'
							energyIstLabel='aktuell'		
						/>		
					</Box>
				</Grid>					
				{!gstate.isXS ?
					<Grid item xl={3} lg={4} md={4} sm={6} style={{display: 'flex'}}>
						<Box style={{maxWidth:150}}>
							<Box className={classes.box} style={{margin:0, padding:10}}>
								<div style={{backgroundColor:'#fff', fontSize:36, fontWeight:700, height:64, textAlign:'center'}}>
									{evalItem("['fac_energy']['end_ist']")}
								</div>								
								<div style={{backgroundColor:'#fff', fontSize:12, fontWeight:300, textAlign:'center'}}>
									Endenergie (kWh/m² Jahr)
								</div>											
							</Box>								
						</Box>																	
						<Box style={{display: 'flex', marginLeft:5}}>
							<LabelEnergyClass obj={gstate.objEdit} energy='end_ist'/>																				
						</Box>																		
					</Grid>						
				:
					<Grid item xs={6}>
						<Box style={{width:'100%'}}>
							<Box className={classes.box} style={{margin:0, padding:10}}>
								<div style={{backgroundColor:'#fff', fontSize:36, fontWeight:700, height:64, textAlign:'center'}}>
									{evalItem("['fac_energy']['end_ist']")}
								</div>								
								<div style={{backgroundColor:'#fff', fontSize:12, fontWeight:300, textAlign:'center'}}>
									<div>Endenergie</div>
									<div>(kWh/m² Jahr)</div>
								</div>											
								<Box style={{display: 'flex', marginLeft:5}} alignItems="center" justifyContent="center">
									<LabelEnergyClass obj={gstate.objEdit} energy='end_ist'/>																				
								</Box>																										
							</Box>								
						</Box>																	
					</Grid>										
				}
				{!gstate.isXS ?
					<Grid item xl={3} lg={4} md={4} sm={6} style={{display: 'flex'}}>
						<Box style={{maxWidth:150}}>
							<Box className={classes.box} style={{margin:0, padding:10}}>
								<div style={{backgroundColor:'#fff', fontSize:36, fontWeight:700, height:64, textAlign:'center'}}>
									{evalItem("['fac_energy']['primary_ist']")}
								</div>								
								<div style={{backgroundColor:'#fff', fontSize:12, fontWeight:300, textAlign:'center'}}>
									Primärenergie (kWh/m² Jahr)
								</div>											
							</Box>								
						</Box>																	
						<Box style={{display: 'flex', marginLeft:5}}>
							<LabelEnergyClass  obj={gstate.objEdit} energy='primary_ist'/>																				
						</Box>																		
					</Grid>
				:
					<Grid item xs={6} style={{display: 'flex'}}>
						<Box style={{width:'100%'}}>
							<Box className={classes.box} style={{margin:0, padding:10}}>
								<div style={{backgroundColor:'#fff', fontSize:36, fontWeight:700, height:64, textAlign:'center'}}>
									{evalItem("['fac_energy']['primary_ist']")}
								</div>								
								<div style={{backgroundColor:'#fff', fontSize:12, fontWeight:300, textAlign:'center'}}>
									<div>Primärenergie</div>
									<div>(kWh/m² Jahr)</div>
								</div>							
								<Box style={{display: 'flex', marginLeft:5}} alignItems="center" justifyContent="center">
									<LabelEnergyClass obj={gstate.objEdit} energy='primary_ist' />																				
								</Box>																										
							</Box>								
						</Box>																	
					</Grid>				
				}

								
			</Grid>	
			
			{gstate.objEdit.fac_energy.end_ist > 500 ?
			<Grid item xs={12}>	
				<QeeHintBox type="warning" text={<React.Fragment><strong>Unvollständige Eingabe:</strong> <span dangerouslySetInnerHTML={{__html: getHintHeatingRenovation()}}></span></React.Fragment>} style={{marginTop:20}}/>
			</Grid>					
			: ''}			
			
			<Grid item xs={12}>	
				<QeeHintBox type="info" text={<React.Fragment><strong>Statistische Information:</strong> <span dangerouslySetInnerHTML={{__html: getHintEnergy()}}></span></React.Fragment>} style={{marginTop:20}}/>
			</Grid>	
			</Grid>				
		</Grid>
		</>			
		);
	}
		
	
	const Co2Comp = () => {
		return(
		<>
			<Typography variant='h2'><Co2Icon className={classes.iconH2} sx={sxIconH2}/>CO2-Emisionen</Typography>					
			
			<Grid container direction="column">
				<Grid item xs={12}>
					<Grid container 
						spacing={2} 
						direction="row" 
						justifyContent="left" 
						alignItems="left"
						style={{width:360}}>	
						<Grid item xs={6}>
							<Box className={classes.box} style={{margin:0, padding:10}}>
								<div style={{backgroundColor:'#fff', fontSize:36, fontWeight:700, height:64, textAlign:'center'}}>
									{evalItem( "['fac_energy']['co2_ist']")}
								</div>								
								<div style={{backgroundColor:'#fff', fontSize:12, fontWeight:300, width:120, textAlign:'center'}}>
									<div>CO2 Ausstoß</div><div>(to/Jahr)</div>
								</div>											
							</Box>								
						</Grid>										
						<Grid item xs={6}>
							<Box className={classes.box} style={{margin:0, padding:10}}>
								<div style={{backgroundColor:'#fff', fontSize:36, fontWeight:700, height:64, textAlign:'center'}}>
									{evalItem( "['fac_energy']['co2_monthlycost_ist']")}
								</div>								
								<div style={{backgroundColor:'#fff', fontSize:12, fontWeight:300, width:120, textAlign:'center'}}>
									<div>CO2 Steuer</div><div>(Euro/Jahr)</div>
								</div>											
							</Box>								
						</Grid>																
					</Grid>				
				</Grid>
				<Grid item xs={12}>	
					<QeeHintBox type="info" text={<React.Fragment><strong>Statistische Information:</strong> <span dangerouslySetInnerHTML={{__html: getHintCo2()}}></span></React.Fragment>} style={{marginTop:20}}/>
				</Grid>	
			</Grid>
		</>				
		);
	}	
	
	
	const MonthlyCostComp = () => {
		return(
		<>
			<Typography variant='h2'><SavingsIcon className={classes.iconH2} sx={sxIconH2}/>Monatliche Heizkosten</Typography>										
			<Grid container 
				spacing={2} 
				direction="row" 
				justifyContent="left" 
				alignItems="left"
				style={{width:360}}>	
				<Grid item xs={6}>
					<Box className={classes.box} style={{width:150, margin:0, padding:10}}>
						<div style={{backgroundColor:'#fff', fontSize:36, fontWeight:700, height:64, width:120, textAlign:'center'}}>
							{evalItem("['fac_energy']['monthlycost_ist']")}
						</div>								
						<div style={{backgroundColor:'#fff', fontSize:12, fontWeight:300, width:120, textAlign:'center'}}>
							<div>Monatskosten</div><div>(Euro)</div>
						</div>											
					</Box>								
				</Grid>	
				
				{gstate.objEdit.fac_investment.savings_ratio ?
					<Grid item xs={6}>
						<Box className={classes.box} style={{width:150, margin:0, padding:10}}>
							<div style={{backgroundColor:'#fff', fontSize:36, fontWeight:700, height:64, width:120, textAlign:'center'}}>
								{gstate.objEdit.fac_investment ? evalItem( "['fac_investment']['savings_ratio']", '%') : 0}
							</div>								
							<div style={{backgroundColor:'#fff', fontSize:12, fontWeight:300, width:120, textAlign:'center'}}>
								<div>Einsparpotenzial</div><div>(%)</div>
							</div>											
						</Box>								
					</Grid>	: ''
				}						
			</Grid>			
		</>
		);
	}	
	
	
	const KfWComp = () => {
		return(
		<>
			<Typography variant='h2'><GavelIcon className={classes.iconH2} sx={sxIconH2}/>KfW Klassifizierung</Typography>										
			<Grid container 
				spacing={2} 
				direction="row" 
				justifyContent="left" 
				alignItems="left"
				style={{maxWidth:1000}}>														
				<Grid item lg={2} md={3} sm={3} xs={12}>
					<Box className={classes.box} style={{width:150, margin:0, padding:10}}>
						<div style={{backgroundColor:'#fff', fontSize:36, fontWeight:700, height:64, width:120, textAlign:'center'}}>
							{gstate.objEdit.fac_energy.kfw_text_ist}
						</div>								
						<div style={{backgroundColor:'#fff', fontSize:12, fontWeight:300, width:120, textAlign:'center'}}>
							<div>KfW</div><div>Standard</div>
						</div>											
					</Box>
				</Grid>							
			</Grid>			
		</>
		);
	}	
	
											
    return (
        <div class="form-groupxxx">
			<Grid container 
				spacing={1} 
				direction="column" 
				justifyContent="left" 
				alignItems="left">			
					<Grid item xs={12}>
						<EnergyComp />		
					</Grid>		
					<Grid item xs={12}>					
						<Grid container direction="row">					
							<Grid item lg={4}  md={6} style={{minWidth:400}}> 
								<Co2Comp />
							</Grid>								
							<Grid item lg={4} md={6} style={{minWidth:400}}>
								<MonthlyCostComp />			
							</Grid>											
							<Grid item lg={4} md={6} style={{minWidth:400}}>
								<KfWComp />																		
							</Grid>										
						</Grid>	
					</Grid>
				
			</Grid>		
		</div>
    )
}
export default FacRenovationIst;