// facWiz.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import FacValuationGeneral from './facValuationGeneral';

import StepZilla from "react-stepzilla";

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	fac_name: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
});


const StepEnd = ( props) => {
	return (
        <div class="form-group">
		Speichere Daten...
		</div>
	);	
}


// main fct
const FacValuationWiz = ( props) => {

	const steps = [
        { name: 'Step0', component: <FacValuationGeneral	 	step={0} /> },	
        { name: 'StepEnd', component: <StepEnd    step={1} /> },						
    ]

	
	const { gstate, setObjEdit, setObjErrors } = useAuth();			
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
	})

	
	useEffect( () => {	

		console.log( 'facValuationWiz useEffect for obj', gstate.objEdit);
		
		// evtl. correct the obj step
		if( !gstate.objEdit.step)
			gstate.objEdit.step	= 0;
						
	}, []);		

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);
	
	// create a ref
	const stepzilla = useRef(null);					
	
	const setStep = ( step) => {
		console.log( '--> FacValuationWiz.setStep', step);	
						
		gstate.objEdit.step = step;
						
		//props.jumpToStep( 0);		
				
		if( step >= 1) {	// == 4		
			if( props.fctSubmit) {
				console.log( 'submitting...');				
				props.fctSubmit();
			}
		}
		
		if( step == 1) {		
			if( props.fctValuationCalculate) {
				console.log( 'Calculating the valuation...');				
				props.fctValuationCalculate();
				/*
				if( props.fctSubmitEnd) {
					console.log( 'End submitting...');				
					props.fctSubmitEnd();					
				}
				*/
			}
		}				
		
		if( step == 1) {		
			if( props.fctSubmitEnd) {
				console.log( 'End submitting...');				
				props.fctSubmitEnd();
			}
		}		
		
		console.log( '<-- FacValuationWiz.setStep');				
	};
	
					
	return (	
		state.uiLoading ? (
			<div className={classes.root}>
				{state.uiLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (
			<main className={classes.content}>								
				<form className={classes.form} noValidate>					
					<StepZilla 
						steps={steps} 
						stepsNavigation={false}
						preventEnterSubmission={true}
						nextButtonText="Weiter"
						nextButtonCls="btn btn-prev btn-primary btn-lg pull-right"
						backButtonText="Zurück"				
						backButtonCls="btn btn-next btn-secondary btn-lg pull-left"	
						onStepChange={(step) => {
							setStep( step)
						}}
						ref={stepzilla}						
					/>
				</form>				
			</main>	
		)		
	)
}	
export default withStyles(styles)(FacValuationWiz);