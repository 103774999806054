import React, { useState } from "react";
import { makeStyles }  from '@material-ui/core/styles';

import style from "./PaymentScreen.module.scss";

import { postRequest } from "../utils/api";

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from '../../qeeColorButton';

import Register from "./Register";
import Invoice from "./Invoice";
import ListPaymentMethods from "./ListPaymentMethods";
import PaymentForm from "./PaymentForm";


export default function PaymentScreen() {
	const [selectedMethod, setSelectedMethod] = useState({});
	const [paymentIntent, setPaymentIntent] = useState(null);
	const [activeScreen, setActiveScreen] = useState({
		prePayment: true,
		showInvoice: false,		
		paymentMethods: false,
		paymentForm: false,
	});
	
	
	// some consts	
	const useStyles = makeStyles({
		//...props,
		button: {
			width: 200,
			height: 48
		}		
	});	
	const classes = useStyles();					

	
	function handleClickShowInvoice() {
		changeActiveScreen("showInvoice");
	}		
	
	
	function handleInvoiceOk() {
		changeActiveScreen("paymentMethods");
	}	
	
	
	function handleSelectCard(method) {
		setSelectedMethod(method);
		createPaymentIntent(method.id);
	}

	function createPaymentIntent( selectedPaymentMethodId) {
		
		let usr = JSON.parse( sessionStorage.getItem( 'usr'));	
		
		// try to get the fac_id, if any in the url
		const myURL = new URL( window.location);
		const facId = myURL.searchParams.get( 'id');		
		
		postRequest(`/api/payment/create/`+usr.usrId+'/'+facId, {
			paymentMethod: selectedPaymentMethodId,
		})
		.then((resp) => {
			setPaymentIntent(resp.data);
			setActiveScreen({ paymentForm: false, paymentMethods: true });
			changeActiveScreen("paymentForm");
		})
		.catch((err) => {
			console.log(err);
		});
	}

	function changeActiveScreen(screen) {
		let toUpdate = { prePayment: false, showInvoice: false, paymentMethods: false, paymentForm: false };
		toUpdate[screen] = true;
		setActiveScreen(toUpdate);
	}

	function handleClickMakePayment() {
		changeActiveScreen("paymentMethods");
	}

	return (
		<div className={style.wrapper}>
			{activeScreen.prePayment && <QeeColorButtonPrimary disableElevation 
											className={classes.button} 
											onClick={handleClickShowInvoice}>
											Lizenz kaufen
										</QeeColorButtonPrimary>}		
		
			{activeScreen.showInvoice && <Invoice handleInvoiceOk={handleInvoiceOk}/>}

			{activeScreen.paymentMethods && <ListPaymentMethods handleSelectCard={handleSelectCard} />}

			{activeScreen.paymentForm && paymentIntent && (
				<PaymentForm paymentIntent={paymentIntent} paymentMethod={selectedMethod} />
			)}
		</div>
	);
}
