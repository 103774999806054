// Import the ChatController class from 'chat-ui-react'
import { ChatController } from './chatUiReact/qeedist/cjs/chat-controller.js';

// Create a subclass QeeChatController that extends ChatController
class QeeChatController extends ChatController {
    constructor(option) {
        super(option); // Call the constructor of the parent class
        this.parentForm = option.parentForm;  // Add this line
        this.parentId = option.parentId;      // Add this line
    }

    // Override the addOnMessagesChanged method
    addOnMessagesChanged(callback) {
        // Your custom implementation here
        super.addOnMessagesChanged(callback); // Call the parent method if desired
    }

    // Override the removeOnMessagesChanged method
    removeOnMessagesChanged(callback) {
        // Remove the callback from the array if it exists
        const idx = this.state.onMessagesChanged.indexOf(callback);
        if (idx !== -1) {
            this.state.onMessagesChanged.splice(idx, 1);
        }
      
        // Clear the whole callback array
        this.state.onMessagesChanged = [];
    }
}

export { QeeChatController };
