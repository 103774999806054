import React, { useState, useEffect } from 'react';
import { CardMedia, Typography, Button, CircularProgress, Grid } from '@mui/material';
import axios from 'axios';
import { formatNumber } from '../util/helpers';

const QeeArticleForm = ({ artId, onCartNow }) => {
	const [articleData, setArticleData] = useState(null);
	const [loading, setLoading] = useState(true);

	const handleCartNow = () => {
		if (onCartNow) {
			setLoading(true);
			// Perform the order action here, for example, making an API call to place the order
			// Once the order is completed, setLoading(false);
			onCartNow(); // Replace this with the actual order logic
		}
	};

	useEffect(() => {
		const fetchArticle = async () => {
			try {
				const response = await axios.get(`/api/art/${artId}`);
				console.log('articleData', response.data);
				setArticleData(response.data);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching article:', error);
				setLoading(false);
			}
		};

		fetchArticle();
	}, [artId]);

	return (
		<div style={{ padding: 20 }}>
			{loading ? (
				<CircularProgress />
			) : articleData ? (
				<>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
							<Typography gutterBottom variant="h3" component="div">
								{articleData.art_name}
							</Typography>
							<CardMedia
								component="img"
								height="300"
								style={{ display: 'block', margin: '10 auto', objectFit: 'contain', margin: 10 }}
								image={articleData.art_image_url}
								alt={articleData.art_name}
							/>
							<div>
								<Typography gutterBottom variant="body3" component="div">
									Beschreibung: {articleData.art_desc_short}
								</Typography>
							</div>
							<div>
								<Typography variant="body3" color="text.primary">
									Verfügbarkeit:{' '}
									{articleData.art_in_stock ? (
										<span style={{ color: 'green' }}>verfügbar</span>
									) : (
										<span style={{ color: 'red' }}>nicht verfügbar</span>
									)}
								</Typography>
							</div>
							<div>
								<Typography variant="body3" color="text.primary">
									Lieferzeit: {articleData.art_supply_time ?? ''} {articleData.art_supply_time_desc ?? ''}
								</Typography>
							</div>
							<div>
								<Typography variant="body2" color="text.primary" fontWeight="bold" style={{ marginLeft: 'auto', marginTop: 5 }}>
									{!articleData.art_price || isNaN(articleData.art_price) ? (
										<>Preis: auf Anfrage</>
									) : articleData.art_price_is_min ? (
										<>Preis ab: {formatNumber(articleData.art_price, articleData.art_currency, 2)}</>
									) : (
										<>Preis: {formatNumber(articleData.art_price, articleData.art_currency, 2)}</>
									)}
								</Typography>
							</div>
							{articleData.art_tax_included ? (
								<div>
									<Typography variant="body3" color="text.primary">
										{articleData.art_tax_included_text ?? ''}
									</Typography>
								</div>
							) : null}

							{/* Add other form fields here */}
							<div style={{ textAlign: 'left', marginTop: 40 }}>
								<Typography variant="body2" color="text.secondary" style={{ marginTop: 10 }}>
									<>
										<div dangerouslySetInnerHTML={{ __html: articleData.art_desc }} />
									</>
								</Typography>
							</div>
						</Grid>
					</Grid>
					{onCartNow && (
						<Button
							variant="contained"
							color="primary"
							onClick={handleCartNow}
							style={{ position: 'absolute', bottom: '20px', right: '20px' }}
							disabled={!articleData.art_in_stock} // Disable the button if art_in_stock is false
						>
							Zur Bestellung
						</Button>
					)}
				</>
			) : (
				<p>Artikel nicht gefunden.</p>
			)}
		</div>
	);
};

export default QeeArticleForm;
