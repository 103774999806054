import React, { useState, useEffect, useRef } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles }  from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import QeeRadioGroup from './qeeRadioGroup';

import { useAuth } from "../context/provider";
import { useValidation } from './qeeStepValidationProvider';

const FacPlanRenovationQuestion = (props) => {
	
    const { registerValidation } = useValidation();
	
    const errorRef = useRef(null);
    const thisStep = props.step;
    const { gstate, setObjEdit, setOpenRenovationDialog, setCurrSubpage } = useAuth(); 
	const [objChange, setObjChange] = useState({
		fac_energy:gstate.objEdit.fac_energy
		/*
        fac_energy: {
            energyclass_method: gstate.objEdit.fac_energy.energyclass_method,
			energyclass_plan: gstate.objEdit.fac_energy.energyclass_plan
        }
		*/
    });
    const objChangeRef = useRef(objChange);
    useEffect(() => {
        objChangeRef.current = objChange;
    }, [objChange]);
    const [objErrors, setObjErrors] = useState({});
	

	
	// some consts
	const useStyles = makeStyles(theme => ({
		validationContainer: {
			borderRadius: 5,
			padding: 5,
			backgroundColor: '#fff',
			[theme.breakpoints.down('xs')]: {
			padding: 0,
			},    
		}
	}));	
	const classes = useStyles( props);			
	
	
    const showError = (fieldId) => {
        const errorElement = document.getElementById(fieldId);
        console.log('showError Element ' + fieldId + ': ' + errorElement.id);
        if (errorElement) {
            errorRef.current = errorElement;
        }
        return fieldId;
    };

	
    const resetErrors = (id) => {
        setObjErrors({});
        document.getElementById(id).style.backgroundColor = '#fff';
    };

	
    useEffect(() => {
        const errorElements = document.querySelectorAll('.selError');
        if (errorElements.length > 0) {
            setTimeout(() => {
                errorElements.forEach(element => {
                    const parentElement = element.parentNode;
                    if (parentElement) {
                        parentElement.style.backgroundColor = '#fee';
                        parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }, 0);
        }
    }, [errorRef.current]);

	
    useEffect(() => {
        registerValidation(thisStep, validate);

        return () => {
            registerValidation(thisStep, null);
        };
    }, []);

	
    const validate = async () => {
        console.log('--> validate for FacPlanRenovationQuestion in step: ', gstate.objEdit.step);
        let firstErrorShown = false;
        let errors = false;
        const objErrors = {};

        if (!objChangeRef.current.fac_energy.energyclass_method && !firstErrorShown) {
            objErrors.energyclass_method = 'Du musst eine Auswahl treffen!';
            showError('energyclass_method');
            errors = true;
            firstErrorShown = true;
        }
		


		setObjErrors(objErrors);
		if (!errors) {
			let oe = {
				...gstate.objEdit,
				fac_energy: objChangeRef.current.fac_energy
			};
			oe = await props.fctSubmit( oe);
			if ( oe.fac_energy.energyclass_method === 'singles') {
				oe = await props.fctSubmitEnd(oe);
				setObjEdit( oe);
				if( props.subpageEnergy) {
					setCurrSubpage( props.subpageEnergy);
				}
				setOpenRenovationDialog( false);																
			} else {
				setObjEdit( oe);
			}
		}

        console.log('<-- validate: ' + errors);
        return !errors;
    };

    return (
        <div className="form-group">
            <form noValidate>
                <Grid container spacing={2} direction="column" justifyContent="left" alignItems="left">
                    <Grid item xs={11}>
                        <h1>Nach welcher Methode möchtest du sanieren?</h1>
                    </Grid>
                    <Grid item xs={11}>
                        <Grid container spacing={8} direction="column" justifyContent="left" alignItems="left">
                            <Grid item xs={12}>
								<div id='energyclass_method' className={classes.validationContainer}>
                                <QeeRadioGroup
                                    formSublabel={<div style={{ maxWidth: 800 }}>Durch eine Komplettsanierung zum Effizienzhaus nach einem KfW Standard erhältst du von der KfW Bank neben Tilgungszuschüssen auch zinsgünstige Annuitätendarlehen oder ein zinsgünstiges Endgültiges Darlehen.
                                    Bei Einzelmaßnahmen fördert die BAFA einzelne Sanierungsmaßnahmen.</div>}
                                    defaultValue={objChange.fac_energy.energyclass_method}
                                    fctOnChange={(ev) => {
                                        console.log('onChange energyclass_method', ev.target.value);
										const newValue = ev.target.value;
										var och = {
											...objChange,
											fac_energy: {
												...objChange.fac_energy,
												energyclass_method: newValue, // Directly set new value
												energyclass_plan: objChangeRef.current.fac_energy.energyclass_plan === undefined && newValue === 'singles'
													? newValue // Set plan to new value if it's undefined and newValue is 'singles'
													: undefined // Otherwise, keep the existing plan
											}
										};
										setObjChange(och);
										resetErrors('energyclass_method');
                                    }}
                                    sdata={[
                                        { value: 'kfw', label: 'Komplettsanierung' },
                                        { value: 'singles', label: 'Einzelmaßnahmen' }
                                    ]}
                                />
                                {objErrors.energyclass_method ? (<div style={{ textAlign: "center" }}><small className="selError form-text error">{objErrors.energyclass_method}</small></div>) : (<></>)}
								</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
export default FacPlanRenovationQuestion;
