// icons.js
import * as React from 'react';
//import * as Muicon from "@mui/icons-material";

// -
import Sdata_facs_types_100_icon 		from '@mui/icons-material/House';
import Sdata_facs_types_200_icon 		from '@mui/icons-material/Apartment';
// -
import { ReactComponent as Sdata_houses_types_100_icon } from "../assets/fac_house_type_100.svg";
import { ReactComponent as Sdata_houses_types_200_icon } from "../assets/fac_house_type_200.svg";
import { ReactComponent as Sdata_houses_types_300_icon } from "../assets/fac_house_type_300.svg";
import { ReactComponent as Sdata_houses_types_400_icon } from "../assets/fac_house_type_400.svg";
import { ReactComponent as Sdata_houses_types_500_icon } from "../assets/fac_house_type_500.svg";
import { ReactComponent as Sdata_houses_types_600_icon } from "../assets/fac_house_type_600.svg";

import { ReactComponent as Sdata_outlines_shapes_100_icon } from "../assets/fac_outline_shape_100.svg";
import { ReactComponent as Sdata_outlines_shapes_200_icon } from "../assets/fac_outline_shape_200.svg";
import { ReactComponent as Sdata_outlines_shapes_300_icon } from "../assets/fac_outline_shape_300.svg";
import { ReactComponent as Sdata_outlines_shapes_400_icon } from "../assets/fac_outline_shape_400.svg";
import { ReactComponent as Sdata_outlines_shapes_500_icon } from "../assets/fac_outline_shape_500.svg";

import { ReactComponent as Sdata_roofs_shapes_100_icon } from "../assets/fac_roof_shape_100.svg";
import { ReactComponent as Sdata_roofs_shapes_200_icon } from "../assets/fac_roof_shape_200.svg";
import { ReactComponent as Sdata_roofs_shapes_300_icon } from "../assets/fac_roof_shape_300.svg";
import { ReactComponent as Sdata_roofs_shapes_400_icon } from "../assets/fac_roof_shape_400.svg";

import { ReactComponent as FacBasements_0_icon } from "../assets/fac_basement_type_0.svg";
import { ReactComponent as FacBasements_1_icon } from "../assets/fac_basement_type_1.svg";

// -
import Sdata_org_roles_types_100_icon 	from '@mui/icons-material/Engineering';  // AccountTree
import Sdata_org_roles_types_200_icon 	from '@mui/icons-material/Handyman';   // Engineering
import Sdata_org_roles_types_300_icon 	from '@mui/icons-material/VpnKey';
import Sdata_org_roles_types_400_icon 	from '@mui/icons-material/Fort';


/*
const GetSdataIconByName = ( tbl, key, st) => {
	
	const IconComponent = ( iconName) => {
		const icons = {};

		function importAll(r) {
			r.keys().forEach((filename) => {
				const iconKey = filename.replace('./', '').replace('.svg', '');
				icons[iconKey] = r(filename).default;
			});
		}
		importAll( require.context('../assets', true, /\.svg$/));

		const Icon = icons[iconName];
		if (!Icon) {
			return null; // Icon not found
		}
		
		return (
			<Icon />
		);
	}	
	
	return <IconComponent iconName=tbl+'_'+key />
}
*/


export const GetSdataIconByName = ( tbl, key, st) => {
	
	const icos = {
		sdata_facs_types_100: 	<Sdata_facs_types_100_icon 		style={st} />,		
		sdata_facs_types_200: 	<Sdata_facs_types_200_icon 		style={st} />,	
		
		sdata_houses_types_100: <Sdata_houses_types_100_icon 	style={st} />,
		sdata_houses_types_200: <Sdata_houses_types_200_icon 	style={st} />,
		sdata_houses_types_300: <Sdata_houses_types_300_icon 	style={st} />,
		sdata_houses_types_400: <Sdata_houses_types_400_icon 	style={st} />,
		sdata_houses_types_500: <Sdata_houses_types_500_icon 	style={st} />,
		sdata_houses_types_600: <Sdata_houses_types_600_icon 	style={st} />,

		sdata_outlines_shapes_100: <Sdata_outlines_shapes_100_icon 	style={st} />,
		sdata_outlines_shapes_200: <Sdata_outlines_shapes_200_icon 	style={st} />,
		sdata_outlines_shapes_300: <Sdata_outlines_shapes_300_icon 	style={st} />,
		sdata_outlines_shapes_400: <Sdata_outlines_shapes_400_icon 	style={st} />,
		sdata_outlines_shapes_500: <Sdata_outlines_shapes_500_icon 	style={st} />,		

		sdata_roofs_shapes_100: <Sdata_roofs_shapes_100_icon 	style={st} />,
		sdata_roofs_shapes_200: <Sdata_roofs_shapes_200_icon 	style={st} />,
		sdata_roofs_shapes_300: <Sdata_roofs_shapes_300_icon 	style={st} />,
		sdata_roofs_shapes_400: <Sdata_roofs_shapes_400_icon 	style={st} />,

		fac_basements_0:		    <FacBasements_0_icon	style={st} />,								
		fac_basements_1:		    <FacBasements_1_icon	style={st} />,						
		
		sdata_org_roles_types_100: 	<Sdata_org_roles_types_100_icon	style={st} />,		
		sdata_org_roles_types_200: 	<Sdata_org_roles_types_200_icon	style={st} />,
		sdata_org_roles_types_300: 	<Sdata_org_roles_types_300_icon	style={st} />,		
		sdata_org_roles_types_400: 	<Sdata_org_roles_types_400_icon	style={st} />,	
	};	
	console.log( 'GetSdataIconByName key', tbl+'_'+key);
		
	const Retx = () => {		
		return ( 
			<>
			{icos[tbl+'_'+key]}
			</>
		)	
	}	
		
	return <Retx />;	
	//return <FacHouseType100Icon style={style}/>
}

/*
export const GetIconByName = ( nm) => {
	const Icon = Muicon[nm];
	return <Icon fontSize="large" sx={{ px: 1 }}/>
}
*/



