// StarRating.js
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	starRating: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: theme.spacing(2),
	},
	starIcon: {
		marginRight: theme.spacing(0.5),
	},
	filledStar: {
		color: '#FFD700',
	},
	emptyStar: {
		color: '#E0E0E0',
	},
}));

const StarRating = ({ rating, totalRatings }) => {
	const classes = useStyles();
	const renderStars = () => {
		const stars = [];
		for (let i = 0; i < 5; i++) {
			if (rating >= i + 1) {
				stars.push(<StarIcon key={i} className={`${classes.starIcon} ${classes.filledStar}`} />);
			} else if (rating >= i + 0.5) {
				stars.push(<StarHalfIcon key={i} className={`${classes.starIcon} ${classes.filledStar}`} />);
			} else {
				stars.push(<StarBorderIcon key={i} className={`${classes.starIcon} ${classes.emptyStar}`} />);
			}
		}
		return stars;
	};

	return (
		<div className={classes.starRating}>
			{renderStars()}
			<Typography variant="body2" color="textSecondary">
				({totalRatings} Bewertungen)
			</Typography>
		</div>
	);
};

export default StarRating;
