import react from 'react';

import Grid from "@material-ui/core/Grid";
import AddIcon from "@mui/icons-material/Add";

import { makeStyles } from "@material-ui/core/styles";

import notFoundImg from '../assets/facs-empty.png';
import useWindowDimensions from '../util/windowDimensions';
import { useAuth } from "../context/provider";


const useStyles = makeStyles(theme => ({
	container: {
		padding:100,
		textAlign: 'center',
	},
	img: {
		color: theme.palette.primary.main,
	},
	text: {
		color: '#444',
		fontSize: 16,
		[theme.breakpoints.down('xs')]: {
			fontSize: 14,
		},		
	},
	textContainer: {
		padding: 50
	}
}));


const FacEmptyReport = ( props) => {
			
	const classes = useStyles();			
			
    return (		
        <div className={classes.container} style={{width:useWindowDimensions().width-320}}>
			<Grid container direction='column'>
			<Grid item>
				<img className={classes.img} src={ notFoundImg } alt="Not found" />		
			</Grid>
			<Grid item className={classes.textContainer}>			
				<div className={classes.text} style={{marginBottom:20}}>
					{props.text}Du hast diese Kalkulation noch nicht ausgeführt.<br/>
					<a onClick={() => props.fctNew(props.fctNew)}>{props.link}</a>
				</div>
			</Grid>			
			</Grid>			
        </div>
    )
}

export default FacEmptyReport;



