import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import * as React from 'react';
import { useNavigate } from "react-router-dom";

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
/*
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckIcon from '@mui/icons-material/Check';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@mui/material/CardContent';
*/
/*
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NumberFormat from 'react-number-format';
import Button from '@mui/material/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
*/
import Badge from '@mui/material/Badge';
import ChatIcon from '@mui/icons-material/Chat';
import IconButton from '@material-ui/core/IconButton';


import { green } from '@mui/material/colors';

import axios from 'axios';
import moment from 'moment';

import FacRenovationIst from './facRenovationIst';
import QeeRadioGroup from './qeeRadioGroup';
import LabelEnergyClass from './labelEnergyClass';
import DiagramEnergyClass from './diagramEnergyClass';
import QeeRadioGroupEditor from './qeeRadioGroupEditor';
import useScrollBlock from '../util/useScrollBlock';
import FacTitle from './facTitle';
import FacNewbuildEmpty from './facNewbuildEmpty';
import QeeTooltip from './qeeTooltip';
import QeeHintBox from './qeeHintBox';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import FacCompEnergyResultDiffCard from './facCompEnergyResultDiffCard';
import FacPlanNewbuildsSelect from './facPlanNewbuildsSelect'
import { useLicense } from "./qeeUsrLicenseProvider"; 
import {formatNumber} from '../util/helpers';
import { authMiddleWare, setAxiosAuthToken, setLocalStorageAuthToken } from '../util/auth';
import {usrRight} from '../util/helpers';

import { ReactComponent as FacKfw100Icon } from "../assets/fac_kfw100.svg";
import { ReactComponent as FacKfw85Icon } from "../assets/fac_kfw85.svg";
import { ReactComponent as FacKfw70Icon } from "../assets/fac_kfw70.svg";
import { ReactComponent as FacKfw55Icon } from "../assets/fac_kfw55.svg";
import { ReactComponent as FacKfw40Icon } from "../assets/fac_kfw40.svg";
import { ReactComponent as FacKfwSinglesIcon } from "../assets/fac_kfw_singles.svg";

/*
import roofIcon from '../assets/roof.svg';
import fassadeIcon from '../assets/fassade.svg';
import windowIcon from '../assets/window.svg';
import basementIcon from '../assets/basement.svg';
import heatingIcon from '../assets/heating.svg';
import pvroofIcon from '../assets/pvroof.svg';
import electricIcon from '../assets/electric.svg';
import sanitarieIcon from '../assets/sanitarie.svg';
import bathIcon from '../assets/bath.svg';
import restroomIcon from '../assets/restroom.svg';
import exteriorpaintingIcon from '../assets/exteriorpainting.svg';
import sunblindeIcon from '../assets/sunblinde.svg';
import terraceIcon from '../assets/terrace.svg';
import landscapingIcon from '../assets/landscaping.svg';
import garageIcon from '../assets/garage.svg';
import carportIcon from '../assets/carport.svg';

import roofIconDisabled from '../assets/roof_disabled.svg';
import fassadeIconDisabled from '../assets/fassade_disabled.svg';
import windowIconDisabled from '../assets/window_disabled.svg';
import basementIconDisabled from '../assets/basement_disabled.svg';
import heatingIconDisabled from '../assets/heating_disabled.svg';
import pvroofIconDisabled from '../assets/pvroof_disabled.svg';
import electricIconDisabled from '../assets/electric_disabled.svg';
import sanitarieIconDisabled from '../assets/sanitarie_disabled.svg';
import bathIconDisabled from '../assets/bath_disabled.svg';
import restroomIconDisabled from '../assets/restroom_disabled.svg';
import exteriorpaintingIconDisabled from '../assets/exteriorpainting_disabled.svg';
import sunblindeIconDisabled from '../assets/sunblinde_disabled.svg';
import terraceIconDisabled from '../assets/terrace_disabled.svg';
import landscapingIconDisabled from '../assets/landscaping_disabled.svg';
import garageIconDisabled from '../assets/garage_disabled.svg';
import carportIconDisabled from '../assets/carport_disabled.svg';
*/

import { 
	getRoofType, 
	getHeatingType, 
	getSdatas, 
	facNewbuildPlanCalculate 
} from "../api/service";
import { checkLicense } from '../util/auth';

import { useAuth } from "../context/provider";
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import { useChat } from "./qeeChatProvider";


const FacNewbuildReport = ( props) => { 
	
	const thisStep = props.step;		
	
	const [ blockScroll, allowScroll ] = useScrollBlock();					
	
	const { gstate, setObjEdit, setObjErrors, setIsFetching, setOpenItemEditor } = useAuth();				
		
	/*	
	const [itemEditorData, setItemEditorData] = useState({
		first:{
			title:'', 
			formSublabel:'',
			defaultValue: '',
			fctOnChange: null,
			sdataName: ''
		}
	});	
	*/					
	const [validationErrors, setValidationErrors] = useState( []);	
	/*
	const [state, setState] = useState({	
		screenDims: {width:0, height:0},
		openEditor: false,
	})
	*/	
	
	const { handleClickOpenChat } = useChat();		

	const navigate = useNavigate();								
		
	// some consts
	const useStyles = makeStyles( theme => ({		
		//...props
		accordionSummary: {
			backgroundColor: '#f00'
		},
		accordionBox: {
			fontWeight:'bold', 
			m:1, 
			lineHeight:1.2, 
			color:theme.palette.primary.contrastText,
		},
		/*
		icon:{
			color:theme.palette.primary.contrastText,
		},
		iconDark:{
			color:theme.palette.primary.main,
		},
		iconGreen:{
			color:theme.palette.primary.main,
		},		
		iconRed:{
			color:'red',
		},	
		iconGrey:{
			color:'grey',
		},		
		textOutput:{
			margin:20,
			minWidth: 80
		},
		resize:{
			fontWeight:800,
		},		
		subTitle: {
			color: '#444',
			fontWeight: 'bold'
		},
		miniLabelRed: {
			whiteSpace: 'nowrap',
			fontSize: 12,			
			color: 'red',
		},		
		miniLabelGreen: {
			whiteSpace: 'nowrap',
			fontSize: 12,						
			color: 'green',
		},				
		box: {
			//width:500, 
			//height:300,
			backgroundColor:theme.palette.primary.contrastText,
			border: 'solid 1px #E4EAE6',
			padding:20,
			//margin:20,
			[theme.breakpoints.down('xs')]: {
				padding:5,
			},
		},
		boxPercentage: {
			backgroundColor:theme.palette.secondary.main,
			color:theme.palette.secondary.dark,
			border: 'solid 1px ' + theme.palette.secondary.dark,
			padding:5,
			margin:10,
			width:72,
			display: 'inlineBlock',
			textAlign: 'center',
			fontWeight: 900
		},				
		boxPercentageGreen: {
			backgroundColor:'green',
			color:theme.palette.primary.contrastText,
			//border: 'solid 1px ' + theme.palette.secondary.main,
			padding:5,
			margin:10,
			width:72,
			display: 'inlineBlock',
			textAlign: 'center',
			fontWeight: 900,
			borderRadius: 5,
		},		
		boxPercentageRed: {
			backgroundColor:'red',
			color:theme.palette.primary.contrastText,
			//border: 'solid 1px ' + theme.palette.secondary.main,
			padding:5,
			margin:10,
			width:72,
			display: 'inlineBlock',
			textAlign: 'center',
			fontWeight: 900,
			borderRadius: 5,			
		},				
		switchTrack: theme.components.Switch.switchTrack,
		switchBase: theme.components.Switch.switchBase,
		switchPrimary: theme.components.Switch.switchPrimary,
		tableContainer: {
			[theme.breakpoints.down('xs')]: {
				width:380,
			},			
		},
		resultCard: {
			display: 'flex', 
			width:'100%',
			[theme.breakpoints.down('xs')]: {
				width:340,
			},
			height:120,
			padding:10,
			marginBottom: 10,
			borderRadius: 5,
			border: `1px solid ${theme.palette.primary.main}`,					
		},
		resultBox: {
			padding:25,
			[theme.breakpoints.down('xs')]: {
				padding:10,
			},
			marginBottom: 10,			
			borderRadius: 5,
			border: `1px solid ${theme.palette.primary.main}`,		
		},
		textIst: {
			color:theme.typography.error.color,
			fontWeight: 800,
		},
		textPlan: {
			color:theme.typography.success?.color,
			fontWeight: 800,			
		},
		appBar: {
			position: 'relative'
		},
		editButton: {
			color:theme.palette.secondary.contrastText,			
		},
		containerUpgrade: {
			position: 'relative'
		},
		boxUpgrade: {
			position: 'absolute',
			top: 0,
			left: 0,
			opacity: 0.8	
		},	
		overlayUpgrade: {
			zIndex: 9,
			//margin: 20			
		},
		*/
		floatingButton: {
			position: 'fixed',
			bottom: 0,
			right: 0,
			zIndex: 2
		},	
	}));			
	const classes = useStyles( props);
	
	/*
	const sxTableCell = gstate.isXS ? 
	{
		fontSize: 16,
		fontWeight: 700,
		color: '#444'
	} 
		:
	{
		fontSize: 16,
		fontWeight: 800,
		color: '#444'
	}
	*/	
	
	
	// init the objEdit with subobjects	
	if( !gstate.objEdit.fac_plan_newbuilds || gstate.objEdit.fac_plan_newbuilds.length==0) {
		var y = moment( new Date()).format("YYYY");
		y = parseInt( y);
		y = ''+y;
		y='2024';   // fixed because of the data
		setObjEdit({
			...gstate.objEdit,
			fac_plan_newbuilds:[
				{label:'Dach',    name:'has_renovation_roof',      type:"100",   quality:"100", 		value:false,  renovation:y},				
				{label:'Fassade', name:'has_renovation_fassade',   type:"100", 	 quality:"100", 		value:false,  renovation:y},					
				{label:'Fenster', name:'has_renovation_windows',   type:"100", 	 quality:"100", 		value:false,  renovation:y},					
				{label:'Keller',  name:'has_renovation_basement',  type:"100", 	 quality:"100", 		value:false,  renovation:y},					
				{label:'Heizung', name:'has_renovation_heating',   type:"100", 	 quality:"100", 		value:false,  renovation:y},					
				{label:'PV-Dach', name:'has_renovation_pvroof',    type:"100", 	 quality:"100", 		value:false,  renovation:y},									
				{label:'Rohbau',  name:'has_renovation_structure', type:"100",   quality:"100", 		value:false,  renovation:y},				
			]
		});			
	}
	
	
	/**
	*	getSelectedNewbuildsCount() - observer function for changes only in the newbuilds selection
	*/
	/*
	const getSelectedNewbuildsCount = ( newbuilds) => {	

		var cnt = 0;	
		if( gstate.objEdit.fac_energy.energyclass_plan === 'singles') {	
			newbuilds.map( (r, i)=>{
				if( r.value === '1')
					cnt++;
			})
		}
	
		console.log( 'getSelectedNewbuildsCount', cnt);
	
		return cnt;
	}
	*/
	

	useEffect(async ()=> {  
		
		if( !gstate.isFetching)	{		
			setTimeout( async () => {			
				await handlePlanNewbuildCalculate( gstate.objEdit);
			}, 100);					
		}
		//allowScroll();			
		
	}, [gstate.objEdit.fac_energy.energyclass_plan, 
		gstate.objEdit.fac_energy.energyclass_method,
		JSON.stringify( gstate.objEdit.fac_plan_newbuilds),
		JSON.stringify( gstate.objEdit.fac_plan_newbuilds2)]);					


		
	/**
	* validate() - 
	*/
	const validate = (e) => { 
		
		console.log( '--> validate for FacNewbuildReport in step: ', gstate.objEdit.step)
					
		var errs = false;		
		const validationErrors= [null];

		if( validationErrors[0]) {
			//alert( validationErrors[0]);			
			errs = true;
			setValidationErrors( validationErrors); 			
		} 		
		
		console.log( '<-- validate: ' + errs)		
		return errs;
	}


	/*
	const handleEnergySubmit = (event) => {
		
		console.log( 'handleEnergySubmit', event);
		
		//const authToken = localStorage.getItem( 'AuthToken');
		//if( authToken == null) navigate( '/login');	
		if( event && event.preventDefault)		
			event.preventDefault();
		
		const newFac = gstate.objEdit;
		console.log( 'submit fac', newFac);		
		let options = {};
		let id = gstate.objEdit.id;
		options = {
			url: `/api/fac/${id}`,
			method: 'put',
			data: newFac
		};
		
		//axios.defaults.headers.common = { Authorization: `${authToken}` };
		setAxiosAuthToken();
		
		axios(options)
			.then((data) => {
				console.log( 'Submited data', data);
				setLocalStorageAuthToken( data);
			})
			.catch((error) => {
				setObjErrors( error.response.data);
				console.log(error);
			});
	};	
	
	const handleUploadProgress = (event) => {
		console.log( 'handleUploadProgress', event);	
	}
	*/
	
	
	/**
	*	handlePlanNewbuildCalculate() - starts the plan calculation on the server, saves the energy result
	*/
	const handlePlanNewbuildCalculate = async (fac) => {
		console.log('handlePlanNewbuildCalculate', fac);

		const authToken = localStorage.getItem('AuthToken');
		if (!authToken) {
			navigate('/home');
			return;
		}
		setAxiosAuthToken(authToken); // Function to set the auth token in axios headers globally

		setIsFetching(true);
		
		const result = await facNewbuildPlanCalculate( fac);
		if (result.err) {
			setObjErrors(result.err);
			console.error('Cannot calculate fac', result.err);
		} else {
			console.log('Calculated fac', result.data);
			setObjEdit( result.data);
		}

		setIsFetching(false);
		
		document.body.style.overflow = "auto";
		document.body.style.overflowY = "auto";
	};		
	
	
	const handleChangeEnergyclassPlan = (ev) => {				
		console.log( 'handleChangeEnergyclassPlan', ev.target.value);		
		setObjEdit({
			...gstate.objEdit,
			fac_energy: {
				...gstate.objEdit.fac_energy, 
				energyclass_plan: ev.target.value,
				energyclass_method: ev.target.value === 'singles' ? ev.target.value : 'kfw',
			}
		});
	}
	
		
	/**
	*	CompAccordionDetails() - local component for the accordion table
	*/
	const CompAccordionDetails = ( props) => { 
				
		const { setOpenLicenseDialog } = useLicense();							
						
		return (
			<>		
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className={classes.icon}/>}
					aria-controls="panel1a-content"
					id="panel1a-header"	
					sx={{
						backgroundColor: (theme) => theme.palette.primary.main,
						color: (theme) => theme.palette.primary.contrastText,
					}}
				>
					<Typography><Box className={classes.accordionBox}>Detaillierte Berechnung</Box></Typography>
				</AccordionSummary>
				
				<AccordionDetails>				
					{checkLicense(gstate.usr.lic)=='insufficient' ?
					<div style={{margin:20}}>
						<Badge badgeContent={'UPGRADE'} color="license">
							<QeeColorButtonPrimary disableElevation 
								className={classes.button} 
								onClick={()=>setOpenLicenseDialog( true)}>
								Werte anzeigen
							</QeeColorButtonPrimary>										
						</Badge>				
					</div>
					:
					<>	
					<Grid xs={12}>
						<Box className={classes.subTitle}><Typography variant='h2'>Aktueller energetischer Zustand</Typography></Box>
					</Grid>						
					<Grid container
						direction='row'>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="floor_area"
								label="Geschossfläche"
								name="floor_area"
								value={gstate.objEdit.fac_energy.floor_area}	
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="building_length"
								label="Gebäudelänge"
								name="building_length"
								value={gstate.objEdit.fac_energy.building_length}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="building_width"
								label="Gebäudebreite"
								name="building_width"
								value={gstate.objEdit.fac_energy.building_width}
								className={classes.textOutput}											
							/>
						</Grid>									
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="building_height"
								label="Gebäudehöhe"
								name="building_height"
								value={gstate.objEdit.fac_energy.building_height}
								className={classes.textOutput}											
							/>
						</Grid>																		
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="building_fassade_area"
								label="Fassadenfläche"
								name="building_fassade_area"
								value={gstate.objEdit.fac_energy.building_fassade_area}									
								className={classes.textOutput}											
							/>
						</Grid>									
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="building_roof_area"
								label="Dachfläche"
								name="building_roof_area"
								value={gstate.objEdit.fac_energy.building_roof_area}									
								className={classes.textOutput}
							/>
						</Grid>									
					</Grid>
					
					<Grid container
						direction='row'>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">W/K</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="transmission_lost"
								label="Transmissionswärmeverlust"
								name="transmission_lost"
								value={gstate.objEdit.fac_energy.transmission_lost}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">W/K</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="ventilation_lost"
								label="Lüftungswärmeverlust"
								name="ventilation_lost"
								value={gstate.objEdit.fac_energy.ventilation_lost}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">KWh/a*m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="internal_gain"
								label="Interne Wärmegewinne"
								name="internal_gain"
								value={gstate.objEdit.fac_energy.internal_gain}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">KWh/a*m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="solar_gain"
								label="Solare Wärmegewinne"
								name="solar_gain"
								value={gstate.objEdit.fac_energy.solar_gain}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">KWh/a*m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="annual_heating_demand"
								label="Jahres Heizwärmebedarf"
								name="annual_heating_demand"
								value={gstate.objEdit.fac_energy.annual_heating_demand}									
								className={classes.textOutput}											
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<TextField
								type="number"		
								readOnly
								InputProps={{ 
									endAdornment: <InputAdornment position="start">KWh/a*m2</InputAdornment>,										
									classes: {input: classes.resize}																			
								}} 	
								id="warm_water_demand"
								label="Warmwasserleistung"
								name="warm_water_demand"
								value={gstate.objEdit.fac_energy.warm_water_demand}									
								className={classes.textOutput}											
							/>
						</Grid>									
					</Grid>	
					</>
					}
				</AccordionDetails>

			</Accordion>
			</>	
		)
	}

	
	const startNewbuildWiz = () => {
		alert( 'start')
	}
	
	
	const rgIconStyle = () => {
		if( !gstate.isXS)
			return { width:100, height:100 }
		else
			return { width:36, height:36 }
	}			
	
	
	// general render
    return (
        <div class="form-group">
			<form  noValidate>			
				<FacTitle fac={gstate.objEdit} showPicture={true} />

				<IconButton
					className={classes.floatingButton}
					color="primary"
					aria-label="Open Chat"
					onClick={()=>handleClickOpenChat( gstate.objEdit.id)}
				>
					<ChatIcon style={{ fontSize: 60 }} />
				</IconButton>								
				
									
				{gstate.objEdit.fac_newbuild_wiz_ready ? 
				<Grid container 
					spacing={2} 
					direction="column" 
					justifyContent="left" 
					alignItems="left">					
					<Grid item xs={12}>
						<Typography variant='h1'>Neubauplaner</Typography>
						<Grid container 
							spacing={2} 
							direction="row" 
							justifyContent="left" 
							alignItems="left">
							{gstate.objEdit.fac_type == '100' ? 
							<Grid item md={12} xs={12}>
								<Typography>Welcher KfW-Standard möchtest du mit deinem Neubau erreichen?</Typography>
								<QeeRadioGroup
									defaultValue={gstate.objEdit.fac_energy.energyclass_plan}
									fctOnChange={handleChangeEnergyclassPlan}
									sdata={[
										{value:'kfw40', label:'kfW40', icon:<FacKfw40Icon style={rgIconStyle()}/>},
										{value:'kfw55', label:'kfW55', icon:<FacKfw55Icon style={rgIconStyle()}/>},		
										{value:'singles', label:'Kein Standard', icon:<FacKfwSinglesIcon style={rgIconStyle()}/>},													
									]}
								/>
								{ validationErrors[0] ? ( <div style={{textAlign:"center"}}><small id="selError" className="form-text error">{validationErrors[0]}</small></div> ) :( <></> ) }							
							</Grid>	
							:''}
							<Grid item md={12} xs={12}> 
								<div style={{maxWidth:1000}}>
								<Typography>Basierend auf den <strong>ausgewählten Neubaumaßnahmen</strong> erhältst du einen direkten Überblick 
								über die Kosten, den Komfort und die energetischen Aspekte. Diese Informationen stehen im Zusammenhang mit 
								möglichen Förderungen und den erforderlichen Investitionen.
								</Typography>
								</div>
								<FacPlanNewbuildsSelect {...props} />								
							</Grid>
							}							
						</Grid>
					</Grid>											
															
				</Grid>
				:
				<FacNewbuildEmpty {...props} fctOpenNewbuildIst={props.fctOpenNewbuildWiz}/>													
				}	
			</form>
									
		</div>
    )
}
export default FacNewbuildReport;