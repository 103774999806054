import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MapsHomeIcon from '@material-ui/icons/Home';
import ChecklistIcon from '@mui/icons-material/Checklist';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { useNavigate, useLocation } from 'react-router-dom';
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import QeeNavigator from '../context/navigator';

import SacsSkeleton from './sacsSkeleton';
import ResponsiveAppBar from '../components/appbar';
import SacCard from '../components/sacCard';
import SacsFilterBar from '../components/sacsFilterBar';
import SacsEmpty from '../components/sacsEmpty'
import { fetchSacs, sacSave, sacDelete } from '../api/service';
import { SAC_TYPES, SAC_STATES } from '../models/sacConfig';

import { useAuth } from "../context/provider";

const useStyles = makeStyles((theme) => ({
    appBar: {
        overflow: 'hidden',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        }
    },
    listContainer: {
        flex: 1,
        paddingRight: theme.spacing(1),
        boxSizing: 'border-box',
        borderRight: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'visible',
    },
    title: {
        paddingLeft: theme.spacing(0.5),
        marginTop: 0
    },
    listItem: {
        color: '#788',
        fontWeight: 600,
        fontSize: 16
    }
}));

const Sacs = () => {
    const globalSessionKey = 'sacs-global';
    const { setOpenConfluenceDialog } = useConfluence();
    const { setOpenQeeDialog, gstate, setObjEdit } = useAuth();    
    const navigate = useNavigate();
    const location = useLocation();
    
    const { loadHome, loadFacs, loadAccount, loadChats, loadSacs, loadSacsSender, loadSacsReceiver } = QeeNavigator();    

    const initialState = {
        sacs: [],
        filteredSacs: [],
        error: null,
        initialFilter: {},
        render: 'Sacs', // Initial render state
        title: 'Aktivitäten', // Initial title
        filterReady: false // Track if filter is set
    };
    const [state, setState] = useState(() => initStateFromSessionStorage(globalSessionKey, initialState));
    const [filters, setFilters] = useState(() => initStateFromSessionStorage(globalSessionKey, {}, 'filters'));
    const [sacType, setSacType] = useState(() => initStateFromSessionStorage(globalSessionKey, '*', 'sacType'));
    const [sacDateLimit, setSacDateLimit] = useState(() => initStateFromSessionStorage(globalSessionKey, '*', 'sacDateLimit'));
    const [sacState, setSacState] = useState(() => initStateFromSessionStorage(globalSessionKey, '*', 'sacState'));
    const [initialFilter, setInitialFilter] = useState({});
    const [uiLoading, setUiLoading] = useState(true);
    const [listLoading, setListLoading] = useState(false); // Add a separate loading state for the list

    const classes = useStyles(); // Call useStyles inside the functional component
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const { sacs, filteredSacs = [], error, render, title, filterReady } = state;

    const sacTypes = Object.entries(SAC_TYPES).map(([key, value]) => ({ value: key, label: value }));
    const sacStates = Object.entries(SAC_STATES.default).map(([key, value]) => ({ value: key, label: value }));

    const mergeFilters = (localFilter) => {
        let mergedFilters = { ...filters, ...localFilter };
        console.log('mergedFilters', mergedFilters);
        return mergedFilters;
    };

    const fetchData = async (filter) => {
        console.log('--> fetchData', filter);
        setListLoading(true); // Set list loading to true
        try {
            const response = await fetchSacs(filter);
            if (response.err) {
                console.log('<-- fetchData ERR', response.err);
                throw new Error(response.err);
            }
            let filteredSacs = response.entries.results;

            // Apply local date limit filter
            if (filter.sac_date_limit !== '*') {
                const now = new Date();
                filteredSacs = filteredSacs.filter(sac => {
                    const sacDateLimitDate = new Date(sac.sac_date_limit);
                    switch (filter.sac_date_limit) {
                        case 'today':
                            return sacDateLimitDate.toDateString() === now.toDateString();
                        case 'week':
                            const weekFromNow = new Date();
                            weekFromNow.setDate(now.getDate() + 7);
                            return sacDateLimitDate <= weekFromNow;
                        case 'month':
                            const monthFromNow = new Date();
                            monthFromNow.setMonth(now.getMonth() + 1);
                            return sacDateLimitDate <= monthFromNow;
                        default:
                            return true;
                    }
                });
            }

            // Sort sacs by sac_date_limit
            filteredSacs.sort((a, b) => new Date(a.sac_date_limit) - new Date(b.sac_date_limit));

            setState((prevState) => ({
                ...prevState,
                sacs: response.entries.results,
                filteredSacs,
                error: null
            }));
            
            console.log('<-- fetchData', response.entries.results.length);
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                error: error.message
            }));
        } finally {
            setListLoading(false); // Set list loading to false
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let initialFilter = {};        
        const filter = {};
        if (params.has('filter')) {
            const filterParams = new URLSearchParams(params.get('filter'));
            filter.sac_sender_id = filterParams.get('sac_sender_id') || '';
            filter.sac_receiver_org_id = filterParams.get('sac_receiver_org_id') || '';
            filter.sac_type = filterParams.get('sac_type') || 'rfi';
            initialFilter = filter;
        }
        const title = filter.sac_sender_id ? 'Gesendete Aktivitäten' : filter.sac_receiver_org_id ? 'Empfangene Aktivitäten' : 'Aktivitäten';
        
        const mergedFilters = mergeFilters(filter);
        
        setInitialFilter(initialFilter);        
        setFilters(mergedFilters);
        setState(prevState => ({
            ...prevState,
            sacType: filter.sac_type || '*',
            title,
            filterReady: true // Indicate that the filter is ready
        }));
    }, [location.search]);

    useEffect(() => {
        if (filterReady) {
            fetchData(filters);
        }
    }, [filterReady, filters]); // Fetch data when filters are ready or updated

    const handleFilterSacTypeChange = (event) => {
        const selectedType = event.target.value;
        setSacType(selectedType);
        updateSessionStorage(globalSessionKey, 'sacType', selectedType);
        const updatedFilters = mergeFilters({ sac_type: selectedType });
        setFilters(updatedFilters);
        updateSessionStorage(globalSessionKey, 'filters', updatedFilters);
    };

    const handleFilterSacDateLimitChange = (event) => {
        const selectedDateLimit = event.target.value;
        setSacDateLimit(selectedDateLimit);
        updateSessionStorage(globalSessionKey, 'sacDateLimit', selectedDateLimit);
        const updatedFilters = mergeFilters({ sac_date_limit: selectedDateLimit });
        setFilters(updatedFilters);
        updateSessionStorage(globalSessionKey, 'filters', updatedFilters);
    };

    const handleFilterSacStateChange = (event) => {
        const selectedState = event.target.value === "*" ? "*" : parseInt(event.target.value, 10); // Handle "Alle" and convert other values to integers
        setSacState(selectedState);
        updateSessionStorage(globalSessionKey, 'sacState', selectedState);
        const updatedFilters = mergeFilters({ sac_state: selectedState });
        setFilters(updatedFilters);
        updateSessionStorage(globalSessionKey, 'filters', updatedFilters);
    };

    useEffect(() => {
        fetchData(filters);
        setUiLoading(false);
    }, [filters]);

    const handleSacClick = (sac) => {
        setObjEdit(sac);
        navigate(`/sac?id=${sac.id}`);
    };

    const handleSacDelete = async (sac) => {
        try {
            await sacDelete(sac.id);
            setState((prevState) => ({
                ...prevState,
                filteredSacs: prevState.filteredSacs.filter((s) => s.id !== sac.id)
            }));
        } catch (error) {
            console.error('Failed to delete SAC', error);
        }
    };

    const handleSacStateChange = async (sac, newState) => {
        try {
            const updatedSac = await sacSave({ ...sac, sac_state: newState }); // Change this line
            // Update the list of SACs and reapply filters
            const newSacs = sacs.map((s) => (s.id === sac.id ? updatedSac : s));
            const newFilteredSacs = applyFilters(newSacs, filters);
            console.log('newSacs', newSacs);
            setState((prevState) => ({
                ...prevState,
                sacs: newSacs,
                filteredSacs: newFilteredSacs
            }));
            return updatedSac;
        } catch (error) {
            console.error('Failed to update SAC state', error);
            throw error;
        }
    };

    const applyFilters = (sacs, filters) => {
        console.log('applyFilters filters', filters);
        const { sac_state } = filters;

        let filteredSacs = sacs;
        if (sac_state !== undefined && sac_state !== '*') {
            filteredSacs = filteredSacs.filter((sac) => sac.sac_state == sac_state);
        }

        return filteredSacs;
    };

    const loadSacsContent = () => {
        return (
            <main className={classes.content}>
                <Typography variant="h1" className={classes.title}>{title}</Typography>
                <Box className={classes.mainContent}>
                    <Box className={classes.listContainer}>
                        <SacsFilterBar
                            sacType={sacType}
                            sacDateLimit={sacDateLimit}
                            sacState={sacState}
                            sacTypes={sacTypes}
                            sacStates={sacStates}
                            handleSacTypeChange={handleFilterSacTypeChange}
                            handleSacDateLimitChange={handleFilterSacDateLimitChange}
                            handleSacStateChange={handleFilterSacStateChange}
                            error={error}
                            initialFilter={initialFilter}
                        />
                        {listLoading ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={1}>
                                {filteredSacs.length > 0 ? (
                                    filteredSacs.map((sac) => (
                                        <Grid item xs={12} key={sac.id}>
                                            <SacCard
                                                sac={sac}
                                                onClick={() => handleSacClick(sac)}
                                                onStateChange={handleSacStateChange}
                                                onDelete={handleSacDelete}
                                            />
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                            <SacsEmpty />
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Box>
                </Box>
            </main>
        );
    };

    const showConfluenceHome = () => {
        setOpenConfluenceDialog();
    };

    const showVersionInfo = () => {
        setOpenQeeDialog({ open: true, id: 'dlgVersionInfo' });
    };

    const logoutHandler = () => {
        localStorage.removeItem('AuthToken');
        navigate('/login');
    };

    const switchSubpage = () => {
        switch (render) {
            case 'Facs':
                return loadFacs();            
            case 'Account':
                return loadAccount();
            case 'Sacs':
            default:
                return loadSacsContent();
        }
    };

    const settings = [
        { nm: 'Mein Konto', fct: () => setState({ ...state, render: 'Account' }), ico: <AccountBoxIcon /> },
        { nm: 'Wissensassistent', fct: showConfluenceHome, ico: <HelpOutlinedIcon /> },
        { nm: 'Versionsinfo', fct: showVersionInfo, ico: <PrivacyTipIcon /> },
        { nm: 'Logout', fct: logoutHandler, ico: <ExitToAppIcon /> }
    ];

    const drawer = () => {
        return (
            <>
                <div className={classes.toolbarxxx} />
                <List>
                    <ListItem button key="Facs" onClick={() => setState({ ...state, render: 'Facs' })}>
                        <ListItemIcon>
                            <MapsHomeIcon />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body2" className={classes.listItem}>Home</Typography>}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button key="SacsSender" onClick={loadSacsSender}>
                        <ListItemIcon>
                            <ChecklistIcon />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body2" className={classes.listItem}>Gesendete Aktivitäten</Typography>}
                        />
                    </ListItem>
                    <ListItem button key="SacsReceiver" onClick={loadSacsReceiver}>
                        <ListItemIcon>
                            <ChecklistIcon />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body2" className={classes.listItem}>Empfangene Aktivitäten</Typography>}
                        />
                    </ListItem>
                </List>
            </>
        );
    };

    return (
        <>
            {uiLoading ? (
                <SacsSkeleton />
            ) : (
                <ResponsiveAppBar
                    className={classes.appBar}
                    drawer={drawer}
                    switchSubpage={switchSubpage}
                    pages={[]}
                    settings={settings}
                />
            )}
        </>
    );
};

function initStateFromSessionStorage(globalKey, defaultState, subKey = null) {
    const savedState = sessionStorage.getItem(globalKey);
    const parsedState = savedState ? JSON.parse(savedState) : defaultState;

    if (subKey) {
        return parsedState[subKey] !== undefined ? parsedState[subKey] : defaultState;
    }

    return parsedState;
}

function updateSessionStorage(globalKey, subKey, value) {
    const savedState = sessionStorage.getItem(globalKey) ? JSON.parse(sessionStorage.getItem(globalKey)) : {};
    savedState[subKey] = value;
    sessionStorage.setItem(globalKey, JSON.stringify(savedState));
}

export default Sacs;
