// qeeOpenaiCompletions.js

import react, { useState, useEffect, useLayoutEffect } from 'react';
import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getOpenaiDesc } from "../api/service";

import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	}
});


const QeeOpenaiCompletions = ( props) => {
	
	// some consts
	const useStyles = makeStyles( theme => ({
		checkButton: {
			position: 'relative',
			marginLeft: 10,
			top: 0,
			padding: 0,
			width: 120
		},
		fieldsBox: {
			marginTop: 40,
			display:'inline'
		}
	}));			
	const classes = useStyles( props);	
	
	const { gstate, setObjEdit, setObjErrors } = useAuth();				
	
	const [validationErrors, setValidationErrors] = useState( []);		
	
	const [hasErrors, setHasErrors] = useState( true);				
	const [errors, setErrors] = useState( {});					
	const [prompt, setPrompt] = React.useState({ error: false, msg: '', value: gstate.objEdit.fac_desc_prompt_openai});		
	const [text, setText] = React.useState({ error: false, msg: '', value: gstate.objEdit.fac_desc_openai});		
		
	const [isLoading, setIsLoading] = useState( false);		
	
	// field conditions
	const isValidPrompt = ( vl) => {
		if( !vl)
			vl = prompt.value;
		return true;
	}	
	const isValidText = ( vl) => {
		if( !vl)
			vl = text.value;
		if( !vl)
			vl = '';			
		return vl.length>12 ? true : false;
	}		
		
	const handleChange = (event) => {
		//console.log( 'handleChange: ' + event.target.name, event.target.value);

		if( event.target.name==='prompt') {					
			const value = event.target.value;
			setPrompt({ value, error: false, msg: ''});	
			setHasErrors( !isValidPrompt( value));
		}
		
		if( event.target.name==='text') {			
			let value = event.target.value;   //.replace( '\n', ' ');			
			setText({ value, error: false, msg: ''});							
			setHasErrors( !isValidText( value));									
		}

	};
	
	
	const handleBlur = (event) => {
		//console.log( 'handleBlur: ' + event.target.name, event.target.value);
				
		if( event.target.name==='prompt') {									
			const value = event.target.value.trim().toLowerCase();
			setPrompt({value, error: !isValidPrompt( value), msg: 'Ungültige Texteingabe!'});	
			if( !isValidPrompt( value))
				setHasErrors( true);
		}
		
		if( event.target.name==='text') {
			const value = event.target.value;
			setText({ value, error: !isValidText( value), msg: 'Der Text muss mindestens 12 Zeichen beinhalten!'});							
			if( !isValidText( value))
				setHasErrors( true);			
		}
			
	};
			
			
	useEffect(()=> {  	
		if( props.fctOnHasErrors) 
			props.fctOnHasErrors( true);	
	}, []);						
			
	
	useEffect(()=> {  	
		validate();	
	}, [prompt.error, text.error, hasErrors]);			// hasErrors
	
	
	useEffect(()=> {  		
		if( props.fctOnChanges) {
			var o = {
				prompt: 	prompt.value,
				text: 	text.value
			};
			props.fctOnChanges( o);
		}	
	}, [prompt.value, text.value]);

		
	/**
	* validate() - 
	*/
	const validate = () => { 
		
		console.log( '--> validate for QeeOpenAiCompletions');
				
		if( props.fctOnHasErrors) {
			if( prompt.error || text.error || hasErrors || !isValidPrompt() || !isValidText())		
				props.fctOnHasErrors( true);
			else
				props.fctOnHasErrors( false);
		}
		
		console.log( '<-- validate')		
	}
	
	
	/**
	*	generateOpenaiDesc() - generates an openai description
	*/
	const generateOpenaiDesc = async () => { 					
		setIsLoading( true);		
		var vl = await getOpenaiDesc( gstate.objEdit.id, prompt.value);
		setText( { error: false, msg: '', value: vl});
		setIsLoading( false);
	}	
	
			
	return (
        <div class="form-group" style={{marginTop: 70}}>
			<form  noValidate>					
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<h1>Erstelle eine automatisierte Textanzeige</h1>
					</Grid>
					<Grid item xs={12}>
						<Grid container direction="row">
							<Grid item xs={10}>					
								<TextField
									variant="outlined"
									fullWidth
									id="prompt"
									label="Füge ein paar Stichworte ein, wie z.B. verkaufe, top Lage, guter Preis o.ä."
									placeholder="Deine persönliche Note für die Komposition"
									name="prompt"
									value={prompt.value}									
									helperText={prompt.error ? prompt.msg : ''}
									error={prompt.error ? true : false}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</Grid>
							<Grid item xs={2}>				
								<QeeColorButtonPrimary 
									variant="contained" 
									onClick={generateOpenaiDesc}
									style={{ textTransform: "none", padding: 15, marginLeft: 10}}
								>
									{isLoading ?
										<CircularProgress style={{color:'#fff'}} />
										:	
										<>{gstate.isXS ? '' : 'Komponieren'}</>
									}						
								</QeeColorButtonPrimary>				
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							required
							disabled={isLoading ? true : false}
							fullWidth
							id="text"
							label="Textergebnis"
							placeholder="Automatisch generierter Text"
							name="text"
							multiline
							rows={14}
							rowsMax={50}
							value={text.value}									
							helperText={text.error ? text.msg : ''}
							error={text.error ? true : false}
							onChange={handleChange}									
							onBlur={handleBlur}							
						/>
					</Grid>
				</Grid>
			</form>
		</div>
	);

}	
export default withStyles(styles)(QeeOpenaiCompletions);