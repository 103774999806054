// facsSearch.js

import React, { Component } from 'react';
import react, { useState, useEffect, useLayoutEffect } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import SearchIcon from "@mui/icons-material/Search";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { authMiddleWare } from '../util/auth';
import { useNavigate } from "react-router-dom";

import useWindowDimensions from '../util/windowDimensions';
import { useAuth } from "../context/provider";

import FacsSelect from './facsSelect';
import FacsSelectLocality from './facsSelectLocality';
import FacsSelectType from './facsSelectType';
import FacsSelectPrice from './facsSelectPrice';
import FacsSelectRoomsCount from './facsSelectRoomsCount';
import SearchNotFound from './searchNotFound';
import FacCard from './facCard';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	gridContent: {
		maxWidth: 1200
	},
	dialog: {
		width: '100%',
		//maxWidth: 1200,		 
	},
	form: {
		marginTop: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	sortingToolbar: {
		marginTop: 20,
		maxWidth: 1200,
	},
	filterToolbar: {
		maxWidth: 1200,
	},	
	results: {
		maxWidth: 1200,
		marginTop: 20
	},	
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	dialogeStyle: {
		maxWidth: '50%'
	},
	viewRoot: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	selectButton: {
		boxShadow: 'none',
		border: `1px solid ${theme.palette.primary.main}`,				
	},
	icon: {
		color: 'red',
	},
	select: {
		padding: 20
	},
	label: {
		paddingRight: 10,
		margin: 'auto',
		marginRight: 10
	}
});


const SearchBar = ({setSearchQuery}) => (
	<form>
		<TextField
			id="search-bar"
			className="text"
			onInput={(e) => {
				setSearchQuery(e.target.value);
			}}
			label="Enter a city name"
			variant="outlined"
			placeholder="Search..."
			size="small"
		/>
		<IconButton aria-label="search">
			<SearchIcon style={{ fill: "blue" }} />
		</IconButton>
	</form>
);


const FacsSearch = ( props) => {
	
	const navigate = useNavigate();								
	
	const { classes, ...rest } = props;
		
	const { gstate, setFacsSearching, setFacsSearchFilter, setFacsExternSorting, setFacsExtern, setSearchErrors } = useAuth();					
		
	const [uiLoading, setUiLoading] = useState( true);		
	
	const [locality, setLocality] = React.useState( 'alle');					
	const [type, setType] = React.useState( 'alle');						
	const [price, setPrice] = React.useState( 'alle');					
	const [roomsCount, setRoomsCount] = React.useState( 'alle');						
				
				
	useEffect( () => {
		
		setUiLoading( true);		
		
		handleSearch();
		
		setUiLoading( false);
		
	}, [gstate.facsSearching, gstate.facsExternSorting]);		
		
	
	const handleSearch = async () => {	
	
		console.log( '>>> facsSearch handleSearch', gstate);
		
		//authMiddleWare(this.props.history);
		const authToken = localStorage.getItem( 'AuthToken');
		if( authToken == null) navigate( '/home');					

		axios.defaults.headers.common = { Authorization: `${authToken}` };
		console.log( 'flt1', gstate.facsSearchFilter);
		var flt2 = cleanFilter( JSON.parse( JSON.stringify( gstate.facsSearchFilter)), '_text');
		flt2 = {
			...flt2,
			sorting: gstate.facsExternSorting
		}
		console.log( 'flt2', flt2);
		var ret = await axios.get( '/api/searchFacsExtern', { params: flt2});
		console.log( 'ret', ret);
		setFacsExtern( ret.data.data);
		
		setFacsSearching( false);
	}
	
	
	const handleDeleteFilter = async () => {		
		setFacsSearchFilter({});

		setFacsSearching( true);	
	}
		

	const cleanFilter = ( obj, target) => {
		var tmpobj = JSON.parse( JSON.stringify( obj));
		for( var key in tmpobj) {
			if( key.includes( target)) {  // && obj[key]=='alle') {
				delete obj[key];
			} else if (typeof obj[key] === "object") {
				obj[key] = cleanFilter( obj[key], target); 
			}
		}
		return obj;
	}
	
		
	const hasResults = () => {
		
		console.log( 'hasResults', gstate.facsExtern);
		try {
		
			if( gstate.facsExtern.entries) {
				console.log( 'gstate.facsExtern.entries', gstate.facsExtern.entries);
				let ents = gstate.facsExtern.entries;
				
				if( ents.hits > 0) {
					console.log( 'hasResults: ', ents.hits);
					return true;
				}
			}
			
			return false;			
		
		} catch( err) {
			console.error( 'hasResults ERR', err);
			return false;	
		}
	}
		

	const handleChangeSorting = event => {

		console.log( 'handleChangeSorting', event.target.value);
	
		setFacsExternSorting( event.target.value);
	
		handleSearch();
	}
		
	
	const toggleFavFacHandler = (event, data) => {	
		
		console.log( 'toggleFavFacHandler', data);	

		// put it in the UI (optimistic)
		var facChanged;
		var facs = gstate.facsExtern;		
		facs.entries.results.map((fac) => {
			if( fac.fac_extern_id === data.fac_extern_id) {
				if( !fac.fac_fav)					
					fac.fac_fav = true;
				else
					fac.fac_fav = false;					
				facChanged = JSON.parse( JSON.stringify( fac));
			}
			return fac;
		});	
		setFacsExtern( facs);

		// save it on the server for this user
		const authToken = localStorage.getItem( 'AuthToken');
		if( authToken == null) navigate( '/home');
		axios.defaults.headers.common = { Authorization: `${authToken}` };	
		
		var opts;
		console.log( 'facChanged', facChanged);
		if( facChanged.fac_fav) {   // this is the value after change
			console.log( 'Post new fav');
			var opts = {
				url: '/api/facFav',
				method: 'post',
				data: facChanged
			};					
		} else {
			console.log( 'Delete fav');			
			var opts = {
				url: '/api/facFav/' + facChanged.fac_extern_id,
				method: 'delete'
			};											
		}
		console.log( 'Calling axios', opts);
		axios( opts);
		
		console.log( 'facsExtern', gstate.facsExtern);
	}	
	
	
	return (
			<main className={classes.content} style={{width:useWindowDimensions().width-320}}>
				<div className={classes.toolbar} /> 
				{uiLoading === true ? (
					<>
						<CircularProgress size={150} className={classes.uiProgess} />
					</>
				) : (	
					<div>
						<Grid
							container
							direction="row"
							justifyContent="left"
							alignItems="center"																				
							spacing={4}
							className={classes.filterToolbar}>
							
							<FacsSelect label={'Wo'} field={'addr_gvalue_text_o'} value={locality} width={400} fctSearch={handleSearch} formComponent={<FacsSelectLocality />}/>
							{
								!gstate.facsSearchFilter.fac_extern_id || gstate.facsSearchFilter.fac_extern_id === '' ? (
									<>
									<FacsSelect label={'Was'} field={'fac_types_text'}  value={type} fctSearch={handleSearch} formComponent={<FacsSelectType />} />						
									<FacsSelect label={'Preis'} field={'fac_price_text'}  value={price} width={180}  fctSearch={handleSearch} formComponent={<FacsSelectPrice />} />
									<FacsSelect label={'Zimmer'} field={'fac_rooms_count_text'}  value={roomsCount} width={120} fctSearch={handleSearch} formComponent={<FacsSelectRoomsCount />} />
									</>
								) : (
									<div></div>
								)
							}
							{ Object.keys( gstate.facsSearchFilter).length !== 0 ?
								(<Grid item style={{padding:10, marginLeft:'auto'}}>
									<ButtonGroup color="secondary" aria-label="medium secondary button group">		
										<Button variant="contained" color="secondary" className={classes.selectButton} style={{top:12}} onClick={handleDeleteFilter}>
											<IconButton aria-label="search" color="primary">
												<DeleteForeverIcon />
											</IconButton>														
										</Button>	
									</ButtonGroup>						
								</Grid>
								) :( 
									<></>
								)
							}						
						</Grid>	

						{!hasResults() ? (
							<SearchNotFound />
						) : (
							<>
							<Grid 
								container 
								direction="row"  
								className={classes.sortingToolbar}								
							>
								<Paper direction="row" justifyContent="left" style={{width:'100%', boxShadow: 'none'}}>														
									<Grid item sm={12}>
										<Grid container direction='row'>
											<Grid item sm={6} direction="column" justifyContent="center" style={{margin:'auto', padding:10}}>
												{gstate.facsExtern.entries.hits + ' Objekte gefunden'}
											</Grid>
											<Grid item sm={6}>									
												<Box display="flex" justifyContent="flex-end" style={{padding:10}}>
														<span id="selectSortingLabel" className={classes.label}>Sortierung</span>
														<Select
															style={{width:220}}
															variant="outlined" 
															labelId="selectSorting"
															id="selectSorting"
															value={gstate.facsExternSorting}
															onChange={handleChangeSorting}
														  >
															<MenuItem value={'fac_data_creation@desc'}>neueste zuerst</MenuItem>
															<MenuItem value={'fac_price@desc'}>höchster Preis</MenuItem>
															<MenuItem value={'fac_price@asc'}>tiefster Preis</MenuItem>
															<MenuItem value={'fac_rooms_count@asc'}>weniger Zimmer</MenuItem>															
															<MenuItem value={'fac_rooms_count@asc'}>mehr Zimmer</MenuItem>															
														</Select>
												</Box>		
											</Grid>	
										</Grid>									
									</Grid>
								</Paper>																								
							</Grid>	
							
							<Grid 
								container 
								spacing={2}
								className={classes.results}
							>
								{gstate.facsExtern.entries.results.map((fac) => ( 
									<Grid item xs={16} sm={16}>							
										<FacCard 
											fac={fac} 
											toggleFavFacHandler={toggleFavFacHandler} 
										/>																																		
									</Grid>
								))}
							</Grid>
						</>
						)
						}
					</div>
				)
				}
			</main>		
	);
	
}
export default withStyles(styles)(FacsSearch);	
