import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import EditOffIcon from '@mui/icons-material/EditOff';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import QeeTooltip from './qeeTooltip';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import {usrRight} from '../util/helpers';

const useStyles = makeStyles((theme) => ({
	button: {
		marginRight: theme.spacing(2),
		textTransform: 'none',
		width: 250,
		height: 60			
	},
	buttonLabel: {
		whiteSpace: 'nowrap', 
		overflow: 'hidden', 
		textOverflow: 'ellipsis'		
	},
}));


const RightIcon = ({ role, isXS }) => {
	return role === 'reader' ? 
		<EditOffIcon fontSize={isXS ? 'large' : 'small'} style={{marginTop:1, marginRight:5}} /> : 
		<ModeEditOutlineIcon fontSize={isXS ? 'large' : 'small'} style={{marginTop:1, marginRight:5}} />;
}


const FacRightInfo = ({ fac, isXS }) => {
  const classes = useStyles();
  const right = usrRight( fac);
  const isOwner = right === 'owner';
  const isReader = right === 'reader';

  const tooltipTitle = `Gastzugang als ${
    isReader ? 'Betrachter' : 'Mitbearbeiter'
  }`;

  const shouldRenderButton = !isOwner;

  return shouldRenderButton ? (
	<QeeTooltip title={tooltipTitle}>
		<QeeColorButtonSecondary disableElevation className={classes.button} style={{ backgroundColor: 'lightgreen' }}>
        <Typography variant="button">
			<div className={classes.buttonLabel}>
            <RightIcon role={right} isXS={isXS} fontSize="small" style={{ paddingTop: 5 }} />
            {!isXS && (
				<>
					Gastzugang <br />als {isReader ? 'Betrachter' : 'Mitbearbeiter'}
				</>
            )}
			</div>
		</Typography>
		</QeeColorButtonSecondary>
	</QeeTooltip>
	) : null;
};

export default FacRightInfo;
