// FacForm.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { Dimensions, Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@mui/material/Paper';
import Box from '@material-ui/core/Box';

import FacTitle from './facTitle';
import FacValuationEmpty from './facValuationEmpty';
import DiagramValuation from './diagramValuation';
import ChartPlanValuation from './chartPlanValuation';
import FacEnergyclassEmpty from './facEnergyclassEmpty';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({	
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0.5)
		}
	},
	form: {
		//width: '98%',
		//marginLeft: 13,
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {		
			marginTop: theme.spacing(0.2),		
		}
	},		
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	button: {
		textTransform: 'none'
	},
	box: {
		backgroundColor:theme.palette.primary.contrastText,
		border: 'solid 1px #E4EAE6',
		padding:20,
	},	
	resultCard: {
		display: 'flex', 
		//width:'100%',
		padding:10,
		marginBottom: 10,
		borderRadius: 5,
		border: `1px solid ${theme.palette.primary.main}`,				
		padding: 20
	},	
});


// main fct
const FacValuationForm = ( props) => {
	
	const { gstate, setObjEdit, setObjErrors, setCurrSubpage } = useAuth();			
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
		isXL: false,
		width: window.innerWidth,		
	})

	
	function handleWindowSizeChange() {
		setState({
			...state,
			width: window.innerWidth
		});
	}	
	
	
	useEffect( () => {	

		setState( {
			...state,
			isXL: state.width>=1536 ? true : false
		});
		console.log( 'state', state);				
		
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
												
	}, [state.width]);		

	
	// some consts
	const useStyles = makeStyles({
		//...props
	});			
	const classes = useStyles( props);				
	
			
	const formatCurrency = ( vl) => {
		return new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR'}).format( vl).replace(/\D00(?=\D*$)/, '')
	}
		
	
	return (	
		gstate.isLoading ? (
			<div className={classes.root}>
				{gstate.isLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (			
			<main className={classes.content}>
				<FacTitle fac={gstate.objEdit} showPicture={true} />
				
				{gstate.objEdit.fac_valuation.valueRange ? 

					<Grid container 
						spacing={8} 
						direction="row" 
						justifyContent="left" 
						alignItems="left">																						
								
						<Grid item xs={12} style={{maxWidth:1200}}>						
							<h1>Aktueller Marktwert</h1>												
							<p>Auf Grundlage der vorhandenen Daten zur Immobilie haben wir eine erste Wertermittlung erstellt.
							Die dargestellte Ermittlung zeigt einen Minimalwert, einen Maximalwert und unsere Einschätzung über den aktuellen Immobilienwert.</p>	
							<Box className={classes.resultCard}>
								<DiagramValuation showHint={true}/>
							</Box>
						</Grid>
						
						{gstate.objEdit.fac_prj_type == 'renovation' && gstate.objEdit.fac_type == '100' ?
						<Grid item xs={12} style={{maxWidth:1200}}>						
							<h1>Marktwert nach Sanierung</h1>							
							<p>Der Marktwert nach Sanierung zeigt den möglichen Wertzuwachs nach einer Teil- oder Kernsanierung.</p>
							{!gstate.objEdit.fac_energy.end_ist || !gstate.objEdit.fac_energy.end_plan ? 												
								(!gstate.objEdit.fac_energy.end_ist ?
									<FacEnergyclassEmpty energyIstEmpty={true} fctOpenRenovationIst={props.fctOpenRenovationIst}/>								
								: 
									(!gstate.objEdit.fac_energy.end_plan ?
										<FacEnergyclassEmpty energyPlanEmpty={true} fctOpenRenovationPlan={props.fctOpenRenovationPlan}/>
										:								
										''
									)
								)
							:
								<>
								<Box className={classes.resultCard}>
									<ChartPlanValuation showHint={true} />
								</Box>							
								</>
							}						
						</Grid>
						:''}
						
						{gstate.isBetaVer ?
						<Grid item xs={12} style={{maxWidth:1200}}>	
							<Box className={classes.resultCard}>
								<Grid container direction="column">
								<Grid item>
								<h4>Weitere Aktionen</h4>
								<p>Diese Themen könnten dich auch interresieren</p>
								</Grid>
								<Grid item>
								<QeeColorButtonPrimary disableElevation 
									className={classes.button} 
									onClick={() => {if( props.subpageFinancing) setCurrSubpage( props.subpageFinancing)}}>
										Finanzierungen
								</QeeColorButtonPrimary>													
								<QeeColorButtonPrimary disableElevation 
									className={classes.button} 
									onClick={() => {if( props.subpageInsurance) setCurrSubpage( props.subpageInsurance)}}>
										Versicherungen
								</QeeColorButtonPrimary>																						
								</Grid>
								</Grid>
							</Box>													
						</Grid>
						:''}
						
					</Grid>											
						: 	
					<FacValuationEmpty fctOpenValuationWiz={props.fctOpenValuationWiz}/>}							
				

			</main>				
		)		
	)
}	
export default withStyles(styles)(FacValuationForm);