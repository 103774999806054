import React from "react";
import react, { useState, useEffect, useLayoutEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

import { useAuth } from "../context/provider";

const FacsSelectPrice = ( props) => {
			
	const { gstate, setFacsSearchFilter } = useAuth();			

	console.log( '>>> FacsSelectPrice gstate: ', gstate);	
			
	const [priceMin, setPriceMin] = React.useState( gstate.facsSearchFilter.fac_price_min);				
	const [priceMax, setPriceMax] = React.useState( gstate.facsSearchFilter.fac_price_max);				
	const [priceText, setPriceText] = React.useState( gstate.facsSearchFilter.fac_price_text);					
	
	
	const kFormat = (num) => {
		return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
	}
	
	
	const makeText = () => {
		var txt = 'alle';
		if( priceMin && priceMax)
			txt = kFormat( priceMin)+' - '+kFormat( priceMax);
		else if( priceMin)
			txt = 'von '+kFormat( priceMin);
		else if( priceMax)
			txt = 'bis '+kFormat( priceMax);
		
		console.log( 'priceText', txt);
		return txt; 				
	}
	
	
	useEffect( () => {
		
		console.log( 'facsSelectPrice useEffect');
		
		setPriceText( makeText());
		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			fac_price_text: makeText()
		});	
	}, [priceMin, priceMax]);			
	
	
	
	const handleChangeMin = event => {
		
		const { value } = event.target;
		if ( value.length > 8) {
			return;
		}
				
		let pmin = parseFloat( event.target.value);
		console.log( 'change: ' + event.target.name, pmin);
		setPriceMin( pmin);
		
		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			fac_price_min: pmin,
			fac_price_text: makeText()
		});
	};			
	
	
	const handleChangeMax = event => {
		
		const { value } = event.target;
		if ( value.length > 8) {
			return;
		}
		
		let pmax = parseFloat( event.target.value);
		console.log( 'change: ' + event.target.name, pmax);
		setPriceMax( pmax);

		setFacsSearchFilter({
			...gstate.facsSearchFilter,
			fac_price_max: pmax,
			fac_price_text: makeText()			
		});
	};				

	
	return (
		<Grid container 
			spacing={4} 
			direction="column" 
			justifyContent="center" 
			alignItems="center"
			style={{padding:10}}>
			<Grid item xs={12}>
				<TextField
					type="number"		
					placeholder="z.B. 120000"																	
					InputProps={{ 
						startAdornment: <InputAdornment position="start">EUR</InputAdornment>										
					}} 	
					variant="outlined"
					fullWidth
					id="priceMin"
					label="von"
					name="priceMin"
					value={priceMin}									
					onChange={handleChangeMin}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					type="number"		
					placeholder="z.B. 120000"																	
					InputProps={{ 
						startAdornment: <InputAdornment position="start">EUR</InputAdornment>										
					}} 	
					variant="outlined"
					fullWidth
					id="priceMax"
					label="bis"
					name="priceMax"
					value={priceMax}									
					onChange={handleChangeMax}
				/>
			</Grid>																
		</Grid>	
    );
	
}
export default FacsSelectPrice;