import React from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import notFoundImg from '../assets/orgs-empty.jpg';
import { useAuth } from "../context/provider";

const useStyles = makeStyles(theme => ({
	container: {
		padding: 40,
		textAlign: 'center',
	},
	img: {
		color: theme.palette.primary.main,
		width: 350,
		[theme.breakpoints.down('xs')]: {
			width: 250,
		},
	},
	text: {
		color: '#444',
		fontSize: 16,
	},
	textContainer: {
		padding: 50,
		[theme.breakpoints.down('xs')]: {
			padding: 0,
			paddingTop: 40,
			paddingBottom: 40,
		},
		textAlign: 'center', // Center the text within the container
	}
}));

const OrgsEmpty = (props) => {
	const { gstate } = useAuth();
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Grid container direction='column' alignItems='center'>
				<Grid item>
					<img className={classes.img} src={notFoundImg} alt="Not found" />
				</Grid>
				<Grid item className={classes.textContainer}>
					<span className={classes.text}>
						Es wurden keine passenden Dienstleister<br />
						für deine Eingabedaten gefunden. Bitte erweitere<br />
						den Suchradius oder wähle eine andere Spezialisierung.
					</span>
				</Grid>
			</Grid>
		</div>
	);
}

export default OrgsEmpty;
