// FacForm.js

import react, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import React, { Dimensions, Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from '@mui/material/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import ConstructionIcon from '@mui/icons-material/Construction';
import CalculateIcon from '@mui/icons-material/Calculate';

import FacOwnership from './facOwnership';
import FacType from './facType';
import FacAreas from './facAreas';
import FacNameDesc from './facNameDesc';
import FacRenovationWizDialog from './facRenovationWizDialog';
import FacValuationWizDialog from './facValuationWizDialog';
import FacRenovationReport from './facRenovationReport';
import FacEmptyReport from './facEmptyReport';
import QeeToolsMenu from './qeeToolsMenu';
import QeeTooltip from './qeeTooltip';
import {QeeColorButtonPrimary, QeeColorButtonSecondary} from './qeeColorButton';
import FacRightInfo from './facRightInfo';
import { useConfluence } from "../components/qeeConfluenceProvider"; 
import {usrRight} from '../util/helpers';

import StepZilla from "react-stepzilla";

import axios from 'axios';

import css from '../core/App.css';

import { useAuth } from "../context/provider";


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {		
			padding: theme.spacing(1),
		}
	},
	form: {
		//width: '98%',
		marginLeft: 13,
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {				
			marginLeft: 0,
			marginTop: theme.spacing(1),		
		}
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 470
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	button: {
		textTransform: 'none',
		width: 250,
		height: 60	
	},
	buttonLabel: {
		whiteSpace: 'nowrap', 
		width:240, 
		overflow: 'hidden', 
		textOverflow: 'ellipsis'
	},	
	dialog: {
		width: '50%',
		position: 'absolute',
		left: 10,
		top: 50,			
		//maxWidth: 1200,		 
	},	
	dialogStyle: {
		maxWidth: '50%'
	},	
});


// define a transition for the dialog
const Transition = React.forwardRef( function Transition( props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});


// main fct
const FacEnergy = ( props) => {
				
	const { setOpenConfluenceDialog } = useConfluence();											
				
	const refRenovationDialog = useRef(null);
	const refValuationDialog = useRef(null);				
				
	const { gstate, setCurrSubpage, setObjEdit, setObjErrors, setOpenRenovationDialog, setOpenValuationDialog, setIsFetching } = useAuth();			
	
	const [state, setState] = useState({	
		uiLoading: false,   // set it to true
		screenDims: {width:0, height:0},
		openRenovation: false,
		openValuation: false,		
	})

	
	useEffect( () => {	

		console.log( 'use effect for', gstate.objEdit);
												
	}, []);		

	
	// reposition the dialog (must not be fullScreen)
	const useStyles = makeStyles({
		...props,	
	});	
	const classes = useStyles( props);	
		
	
	const handleRenovationOpen = ( step) => {
		console.log( 'handleRenovationOpen', step);
		
		refRenovationDialog.current.handleRenovationOpen( step);				
	}	
	
	
	const handleValuationOpen = ( step) => {
		console.log( 'handleValuationOpen', step);
		
		refValuationDialog.current.handleValuationOpen( step);				
	}		
	
		
	return (	
		state.uiLoading ? (
			<div className={classes.root}>
				{state.uiLoading && <CircularProgress size={gstate.isLG ? 150 : 50} className={classes.uiProgess} />}
			</div>		
		) : (
			<main className={classes.content}>								
				
			<form className={classes.form} noValidate>
				<Box
				  margin={gstate.isXS ? 1 : 0}
				  display="flex"
				  justifyContent="flex-start"
				  alignItems="flex-start"
				  sx={12}
				>		
				
					<FacRightInfo fac={gstate.objEdit} isXS={gstate.isXS} />				
										
					{usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?				
						<QeeTooltip title="Sanierungsassistent starten">
						<QeeColorButtonPrimary disableElevation 
							className={classes.button} 
							onClick={()=>handleRenovationOpen(0)}>
							<div className={classes.buttonLabel}>{gstate.isXS ? <ConstructionIcon fontSize="large"  style={{paddingTop:5}} /> : 'Sanierungsassistent'}</div>
						</QeeColorButtonPrimary>
						</QeeTooltip>
					:''}
					{/*
					{usrRight(gstate.objEdit) === 'owner' || usrRight(gstate.objEdit) === 'writer' ?					
						<QeeTooltip title="Wertermittlungsassistent starten">					
						<QeeColorButtonSecondary disableElevation 
							className={classes.button} 
							sx={{width:250}}
							onClick={()=>handleValuationOpen(0)}>
							<div className={classes.buttonLabel}>{gstate.isXS ? <CalculateIcon fontSize='large'  style={{paddingTop:5}} /> : 'Wertermittlungsassistent'}</div>
						</QeeColorButtonSecondary>				
						</QeeTooltip>
					:''}
					*/}
					
					<QeeToolsMenu />					
					
					<QeeTooltip title="Wissensassistent starten">
					<QeeColorButtonSecondary disableElevation 
						className={classes.button} 
						style={{width:50}}
						onClick={()=>setOpenConfluenceDialog('/sanierung')}>
						<HelpOutlinedIcon />
					</QeeColorButtonSecondary>					
					</QeeTooltip>					
				</Box>

				<FacRenovationReport 
					fctOpenRenovationIst={()=>handleRenovationOpen(0)}
					fctOpenRenovationPlan={()=>handleRenovationOpen(4)} />	
			</form>					
											
			<FacRenovationWizDialog 
				ref={refRenovationDialog} 
				subpageEnergy="loadFacEnergy"
				subpageValuation="loadFacValuation"
			/>				

			<FacValuationWizDialog 
				ref={refValuationDialog} 
				subpageEnergy="loadFacEnergy"
				subpageValuation="loadFacValuation"
			/>		
							
			</main>	
		)		
	)
}	
export default withStyles(styles)(FacEnergy);